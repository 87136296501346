import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
//import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
//import timeGridPlugin from "@fullcalendar/timegrid";

import allLocales from '@fullcalendar/core/locales-all'
import { RepeatOnRounded } from "@mui/icons-material";
import { alignPropType } from "react-bootstrap/esm/types";

import ToggleSwitch from "./ToggleSwitch";
import Autocomplete from '@mui/material/Autocomplete';
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
    ListItem,
    ListItemText,
    useTheme,
} from "@mui/material";


const Module_Agenda = (props) => {

    const myconntected_employee_id = props.conntected_employee_id;

    const [selected_employee_id, setselected_employee_id] = useState(props.conntected_employee_id);

    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const [p_filtre1, setp_filtre1] = useState("ressource_humaine");
    const [p_filtre1_label, setp_filtre1_label] = useState("Employe");
    const [p_filtre1_value, setp_filtre1_value] = useState("ressource_humaine");

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [mysy_events, setmysy_events] = useState();

    const [Get_List_Agenda_Events_api, setGet_List_Agenda_Events_api] = useState();
    const [Get_List_Agenda_Events_message, setGet_List_Agenda_Events_message] = useState();
    const [Get_List_Agenda_Events_result, setGet_List_Agenda_Events_result] = useState();
    function Get_List_Agenda_Events(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", "ressource_humaine");
        form.append("related_collection_recid", selected_employee_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Agenda_Events  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_api("true");
                setGet_List_Agenda_Events_result(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {};

                    var local_comment = "";
                    if (JSON.parse(obj).comment) {
                        local_comment = String(JSON.parse(obj).comment);
                    }


                    var bg_color = "";
                    var local_sequence_session_id = "";
                    if (JSON.parse(obj).sequence_session_id) {
                        local_sequence_session_id = String(JSON.parse(obj).sequence_session_id);
                        bg_color = "Olive"
                    }

                    local_val = {
                        'title': String(JSON.parse(obj).event_title),
                        'start': String(JSON.parse(obj).event_start),
                        'end': String(JSON.parse(obj).event_end),
                        'id': String(JSON.parse(obj)._id),
                        'comment': String(local_comment),
                        'sequence_session_id': String(local_sequence_session_id),
                        'backgroundColor': bg_color,
                        'type': String(JSON.parse(obj).event_type),
                        'justified': String(JSON.parse(obj).justified),

                    }


                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_api("false");
                setGet_List_Agenda_Events_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Agenda_Events = ', error);
            setGet_List_Agenda_Events_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Connected_User_List_Agenda_Events_api, setGet_Connected_User_List_Agenda_Events_api] = useState();
    const [Get_Connected_User_List_Agenda_Events_message, setGet_Connected_User_List_Agenda_Events_message] = useState();
    const [Get_Connected_User_List_Agenda_Events_result, setGet_Connected_User_List_Agenda_Events_result] = useState();
    function Get_Connected_User_List_Agenda_Events(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", "ressource_humaine");
        form.append("related_collection_recid", myconntected_employee_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Connected_User_List_Agenda_Events  res.data.status = " + res.data.status);
            //console.log(" In Get_Connected_User_List_Agenda_Events  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_Connected_User_List_Agenda_Events_api("true");
                setGet_Connected_User_List_Agenda_Events_result(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {};

                    var local_comment = "";
                    if (JSON.parse(obj).comment) {
                        local_comment = String(JSON.parse(obj).comment);
                    }


                    var bg_color = "";
                    var local_sequence_session_id = "";
                    if (JSON.parse(obj).sequence_session_id) {
                        local_sequence_session_id = String(JSON.parse(obj).sequence_session_id);
                        bg_color = "Olive"
                    }

                    local_val = {
                        'title': String(JSON.parse(obj).event_title),
                        'start': String(JSON.parse(obj).event_start),
                        'end': String(JSON.parse(obj).event_end),
                        'id': String(JSON.parse(obj)._id),
                        'comment': String(local_comment),
                        'sequence_session_id': String(local_sequence_session_id),
                        'backgroundColor': bg_color,
                        'type': String(JSON.parse(obj).event_type),
                        'justified': String(JSON.parse(obj).justified),

                    }

                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Connected_User_List_Agenda_Events_api("false");
                setGet_Connected_User_List_Agenda_Events_message(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_Connected_User_List_Agenda_Events = ', error);
            setGet_Connected_User_List_Agenda_Events_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_List_Agenda_Events_For_Given_User_api, setGet_List_Agenda_Events_For_Given_User_api] = useState();
    const [Get_List_Agenda_Events_For_Given_User_message, setGet_List_Agenda_Events_For_Given_User_message] = useState();
    const [Get_List_Agenda_Events_For_Given_User_result, setGet_List_Agenda_Events_For_Given_User_result] = useState();
    function Get_List_Agenda_Events_For_Given_User(local_selected_id) {

        var local_related_collection = p_filtre1;


        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", local_related_collection);
        form.append("related_collection_recid", local_selected_id);




        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events_For_Given_User  res.data.status = " + res.data.status);
            console.log(" In Get_List_Agenda_Events_For_Given_User  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_For_Given_User_api("true");
                setGet_List_Agenda_Events_For_Given_User_result(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {};

                    var local_comment = "";
                    if (JSON.parse(obj).comment) {
                        local_comment = String(JSON.parse(obj).comment);
                    }


                    var bg_color = "";
                    var local_sequence_session_id = "";
                    if (JSON.parse(obj).sequence_session_id) {
                        local_sequence_session_id = String(JSON.parse(obj).sequence_session_id);
                        bg_color = "Olive"
                    }

                    local_val = {
                        'title': String(JSON.parse(obj).event_title),
                        'start': String(JSON.parse(obj).event_start),
                        'end': String(JSON.parse(obj).event_end),
                        'id': String(JSON.parse(obj)._id),
                        'comment': String(local_comment),
                        'sequence_session_id': String(local_sequence_session_id),
                        'backgroundColor': bg_color,
                        'type': String(JSON.parse(obj).event_type),
                        'justified': String(JSON.parse(obj).justified),

                    }

                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);
                console.log(" ### tab_event = ", tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_For_Given_User_api("false");
                setGet_List_Agenda_Events_For_Given_User_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Agenda_Events_For_Given_User = ', error);
            setGet_List_Agenda_Events_For_Given_User_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_List_Agenda_Events_For_Me_api, setGet_List_Agenda_Events_For_Me_api] = useState();
    const [Get_List_Agenda_Events_For_Me_message, setGet_List_Agenda_Events_For_Me_message] = useState();
    const [Get_List_Agenda_Events_For_Me_result, setGet_List_Agenda_Events_For_Me_result] = useState();
    function Get_List_Agenda_Events_For_Me(local_selected_id) {

        var local_related_collection = p_filtre1;


        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", "ressource_humaine");
        form.append("related_collection_recid", local_selected_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events_For_Me  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Agenda_Events_For_Me  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_For_Me_api("true");
                setGet_List_Agenda_Events_For_Me_result(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {};

                    var local_comment = "";
                    if (JSON.parse(obj).comment) {
                        local_comment = String(JSON.parse(obj).comment);
                    }


                    var bg_color = "";
                    var local_sequence_session_id = "";
                    if (JSON.parse(obj).sequence_session_id) {
                        local_sequence_session_id = String(JSON.parse(obj).sequence_session_id);
                        bg_color = "Olive"
                    }

                    local_val = {
                        'title': String(JSON.parse(obj).event_title),
                        'start': String(JSON.parse(obj).event_start),
                        'end': String(JSON.parse(obj).event_end),
                        'id': String(JSON.parse(obj)._id),
                        'comment': String(local_comment),
                        'sequence_session_id': String(local_sequence_session_id),
                        'backgroundColor': bg_color,
                        'type': String(JSON.parse(obj).event_type),
                        'justified': String(JSON.parse(obj).justified),

                    }

                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_For_Me_api("false");
                setGet_List_Agenda_Events_For_Me_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Agenda_Events_For_Me = ', error);
            setGet_List_Agenda_Events_For_Me_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Ressource_Data(local_selected_id) {

        var local_related_collection = p_filtre1;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", local_related_collection);
        form.append("related_collection_recid", local_selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Ressource_Info_Data/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Ressource_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Ressource_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);
                var nom_prenom = "";


                if (res.data.message.length > 0) {
                    var mylocal_employe = JSON.parse(res.data.message);


                    if (JSON.parse(res.data.message).nom) {
                        nom_prenom = JSON.parse(res.data.message).nom;
                    }

                    if (JSON.parse(res.data.message).prenom) {
                        nom_prenom = String(nom_prenom) + " " + String(JSON.parse(res.data.message).prenom);
                    }


                }
                setagenda_owner_name(nom_prenom);

                if (String(p_filtre1) === "ressource_humaine")
                    setagenda_owner_type("Employé");
                else if (String(p_filtre1) === "ressource_materielle")
                    setagenda_owner_type("Materiel");

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Ressource_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    const myEventsList = []
    const [events, setEvents] = useState([]);
    const handleSelect = (info) => {

        if (String(selected_employee_id).length < 2) {
            alert(" Vous devez choisir un employé ou un materiel");
            return;
        }
        if (String(props.write_access) !== "1") {
            // Pas d'acces en ecriture.
            return;
        }




        setp_detail_title("Nouvelle")
        setp_detail_start(info.start)
        setp_detail_end(info.end)
        setp_detail_comment("");
        setp_detail_sequence_session_id("");
        setp_detail_event_id("");

        setStartDate(info.start);
        setendDate(info.end);
        setDialog_1_open(true);


        /*const { start, end } = info;
        const eventNamePrompt = prompt("Enter, Nom evenment");
        if (eventNamePrompt) {
    
            setEvents([
                ...events,
                {
                    start,
                    end,
                    title: eventNamePrompt,
                    id: uuid(),
                },
            ]);
        }*/
    };

    const renderEventContent = (eventInfo) => {
        //console.log(eventInfo);
        return (
            <>
                <b>{eventInfo.timeText}</b><br />
                <i>{eventInfo.event.title}</i>
            </>
        )
    };

    const handleEventClick = (arg) => {

        if (String(selected_employee_id).length < 2) {
            alert(" Vous devez choisir un employé ou un materiel");
            return;
        }

        setp_detail_title(arg.event.title)
        setp_detail_start(arg.event.start)
        setp_detail_end(arg.event.end)
        setp_detail_event_id(arg.event.id);

        if (arg.event.extendedProps.comment) {
            setp_detail_comment(arg.event.extendedProps.comment);

        }
        else
            setp_detail_comment("");

        if (arg.event.extendedProps.sequence_session_id) {
            setp_detail_sequence_session_id(arg.event.extendedProps.sequence_session_id);

        }
        else
            setp_detail_sequence_session_id("");


        if (arg.event.extendedProps.justified) {
            if (String(arg.event.extendedProps.justified) === "true")
                setp_detail_justified(true);

            else
                setp_detail_justified(false);


        }
        else
            setp_detail_justified(false);



        if (arg.event.extendedProps.type) {
            setp_detail_event_type(arg.event.extendedProps.type);
        }
        else
            setp_detail_event_type("");

        setStartDate(arg.event.start);
        setendDate(arg.event.end);
        setDialog_1_open(true);
    }


    function Close_Dialog_Detail_Event_open() {
        setDialog_1_open(false);
    }


    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);


            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }



    const [Partner_Debut_Journee, setPartner_Debut_Journee] = useState("7:00");
    const [Partner_Fin_Journee, setPartner_Fin_Journee] = useState("20:00");


    const [Get_Partner_Debut_Fin_Journee_api, setGet_Partner_Debut_Fin_Journee_api] = useState();
    const [Get_Partner_Debut_Fin_Journee_message, setGet_Partner_Debut_Fin_Journee_message] = useState();
    const [Get_Partner_Debut_Fin_Journee_result, setGet_Partner_Debut_Fin_Journee_result] = useState();
    function Get_Partner_Debut_Fin_Journee(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Default_Debut_Fin_journee_Modele_Journee/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Partner_Debut_Fin_Journee  res.data.status = " + res.data.status);
            //console.log(" In Get_Partner_Debut_Fin_Journee  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_Partner_Debut_Fin_Journee_api("true");
                setGet_Partner_Debut_Fin_Journee_result(res.data.message);


                var val_json = JSON.parse(res.data.message);

                if (JSON.parse(res.data.message).planning_debut_journee)
                    setPartner_Debut_Journee(String(JSON.parse(res.data.message).planning_debut_journee))

                if (JSON.parse(res.data.message).planning_fin_journee)
                    setPartner_Fin_Journee(String(JSON.parse(res.data.message).planning_fin_journee))




            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Partner_Debut_Fin_Journee_api("false");
                setGet_Partner_Debut_Fin_Journee_message(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_Partner_Debut_Fin_Journee = ', error);
            setGet_Partner_Debut_Fin_Journee_api("false");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Get_Connected_User_List_Agenda_Events();
        setselected_employee_id(myconntected_employee_id);
        Getall_Training_Employee_No_Filter();
        Getall_Training_Materiel_No_Filter();
        Get_Partner_Debut_Fin_Journee();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [myconntected_employee_id])



    const [p_detail_event_id, setp_detail_event_id] = useState();
    const [p_detail_title, setp_detail_title] = useState();
    const [p_detail_start, setp_detail_start] = useState();
    const [p_detail_end, setp_detail_end] = useState();
    const [p_detail_comment, setp_detail_comment] = useState();
    const [p_detail_sequence_session_id, setp_detail_sequence_session_id] = useState("");

    const [p_detail_justified, setp_detail_justified] = useState(false);
    const [p_detail_event_type, setp_detail_event_type] = useState();


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());


    const [selected_related_collection, setselected_related_collection] = useState();


    const [Update_One_Agenda_Event_api, setUpdate_One_Agenda_Event_api] = useState();
    const [Update_One_Agenda_Event_message, setUpdate_One_Agenda_Event_message] = useState();
    const [Update_One_Agenda_Event_result, setUpdate_One_Agenda_Event_result] = useState();
    const Update_One_Agenda_Event = event => {

        var local_related_collection = p_filtre1;

        if (String(selected_employee_id).length < 2) {
            alert(" Vous devez choisir un employé ou un materiel");
            return;
        }

        if (moment(endDate) <= moment(startDate)) {
            alert(" Les dates de début et de fin sont incohérentes");
            return;
        }
        var new_title = p_detail_title;
        var new_start_date = moment(startDate).format();
        var new_end_date = moment(endDate).format();
        var event_id = p_detail_event_id

        //console.log(" ### new event = ", new_title, " -- ", new_start_date, " --- ", new_end_date, " : setp_detail_event_id = ", p_detail_event_id);


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_event_id);
        form.append("related_collection", local_related_collection);

        form.append("event_title", new_title);
        form.append("event_start", new_start_date);
        form.append("event_end", new_end_date);
        form.append("related_collection_recid", selected_employee_id);
        form.append("comment", p_detail_comment);

        form.append("event_type", p_detail_event_type);
        form.append("justified", p_detail_justified);
        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Agenda_Event/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Agenda_Event  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Agenda_Event  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_One_Agenda_Event_api("true");
                setUpdate_One_Agenda_Event_result(res.data.message);

                Get_List_Agenda_Events_For_Given_User(selected_employee_id);

                setp_detail_event_id();
                setp_detail_title();
                setp_detail_start();
                setp_detail_end();
                setp_detail_comment();
                setp_detail_sequence_session_id("");
                setp_detail_justified(false);
                setp_detail_event_type();

                alert(res.data.message);
                Close_Dialog_Detail_Event_open();

            }
            else {
                setUpdate_One_Agenda_Event_api("false");
                setUpdate_One_Agenda_Event_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Agenda_Event = ' + error);
            setUpdate_One_Agenda_Event_api("false");
            alert(" Impossible de mettre à jour l'événement ");

        })
    }


    const [Delete_One_Agenda_Event_api, setDelete_One_Agenda_Event_api] = useState();
    const [Delete_One_Agenda_Event_message, setDelete_One_Agenda_Event_message] = useState();
    const [Delete_One_Agenda_Event_result, setDelete_One_Agenda_Event_result] = useState();
    function Delete_One_Agenda_Event() {


        var event_id = p_detail_event_id

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_event_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Agenda_Event/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_One_Agenda_Event  res.data.status = " + res.data.status);
            //console.log(" In Delete_One_Agenda_Event  res.data.message r_class = " + res.data.message);


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_One_Agenda_Event_api("true");
                setDelete_One_Agenda_Event_result(res.data.message);

                Get_List_Agenda_Events_For_Given_User(selected_employee_id);

                setp_detail_event_id();
                setp_detail_title();
                setp_detail_start();
                setp_detail_end();
                setp_detail_comment();
                setp_detail_sequence_session_id("");

                setp_detail_justified(false);
                setp_detail_event_type();

                alert(res.data.message);
                Close_Dialog_Detail_Event_open();

            }
            else {
                setDelete_One_Agenda_Event_api("false");
                setDelete_One_Agenda_Event_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);

            console.warn('UpdateStagiaireData : Not good man :( Delete_One_Agenda_Event = ' + error);
            setDelete_One_Agenda_Event_api("false");
            alert(" Impossible de supprimer l'événement ");

        })
    }

    const [New_Getall_Training_Materiel_No_Filter_result, setNew_Getall_Training_Materiel_No_Filter_result] = useState([]);

    const [Getall_Training_Materiel_No_Filter_api, setGetall_Training_Materiel_No_Filter_api] = useState();
    const [Getall_Training_Materiel_No_Filter_message, setGetall_Training_Materiel_No_Filter_message] = useState();
    const [Getall_Training_Materiel_No_Filter_result, setGetall_Training_Materiel_No_Filter_result] = useState();
    function Getall_Training_Materiel_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_No_Filter_api("true");
                setGetall_Training_Materiel_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_ref_interne = JSON.parse(x).ref_interne;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "ref_interne": local_ref_interne,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Materiel_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Materiel_No_Filter_api("false");
                setGetall_Training_Materiel_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Training_Materiel_No_Filter = ', error);
            setGetall_Training_Materiel_No_Filter_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [display_other_agenda, setdisplay_other_agenda] = useState();

    const [agenda_owner_name, setagenda_owner_name] = useState("Mon Planning");
    const [agenda_owner_type, setagenda_owner_type] = useState("Employe");


    function show_other_agenda() {
        setdisplay_other_agenda("1");
    }

    function show_my_agenda() {
        setp_filtre1("ressource_humaine");
        setagenda_owner_name("Mon Planning");
        setselected_employee_id(myconntected_employee_id);
        setdisplay_other_agenda();
        Get_List_Agenda_Events_For_Me(myconntected_employee_id);
    }

    const New_Option_Type_Event = [

        { "id": "0", "label": "Absence", "value": "absence" },
        { "id": "1", "label": "Planning", "value": "planning" },
        { "id": "2", "label": "Autre", "value": "autre" },
    ]

    const New_Option_Type_Ressource = [
        { "id": "0", "label": "Employe", "value": "ressource_humaine" },
        { "id": "1", "label": "Materiel", "value": "ressource_materielle" },
    ]

    return (
        <div className="module_agenda">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

                className="module_agenda"

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" >

                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Type </nav>


                        <nav style={{ "float": "right", "width": "65%" }}>
                            <Autocomplete
                                disablePortal
                                name="event_dialog_type"
                                id="event_dialog_type"
                                value={p_detail_event_type}
                                fullWidth
                                options={New_Option_Type_Event}
                                onChange={(event, value) => {
                                    if (String(props.write_access) === "1") {

                                        if (value && value.value) {
                                            setp_detail_event_type(value.value);

                                        }
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" />
                                }
                            />
                            {/*<TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_type"
                                id="event_dialog_type"

                                select
                                fullWidth

                                value={p_detail_event_type}
                                onChange={(e) => {
                                    if (String(props.write_access) === "1") {
                                        setp_detail_event_type(e.target.value);

                                    }
                                }
                                }

                            >
                                <MenuItem value="absence" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Absence &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="planning" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Planning &nbsp;</MenuItem>
                                <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Autre &nbsp;</MenuItem>

                            </TextField>*/}

                        </nav>
                    </div>


                    {String(p_detail_event_type) !== "planning" && <div className="session_caract_Dialog" >
                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Justifié ? </nav>

                        <nav style={{ "float": "right", "width": "65%" }}
                            onClick={(e) => {
                                if (p_detail_justified === true)
                                    setp_detail_justified(false);
                                else
                                    setp_detail_justified(true);

                            }
                            }

                            id="toggleSwitchNav" name="toggleSwitchNav">
                            <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={p_detail_justified}
                            />
                        </nav>
                    </div>}

                    <div className="session_caract_Dialog" > Titre
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={p_detail_title}
                            onChange={(e) => {
                                if (String(props.write_access) === "1" && String(p_detail_sequence_session_id) === "")
                                    setp_detail_title(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > Debut
                        <DatePicker
                            name="event_dialog_start"
                            id="event_dialog_start"
                            selected={startDate}
                            onChange={(date) => {
                                if (String(props.write_access) === "1" && String(p_detail_sequence_session_id) === "")
                                    return setStartDate(date);
                            }
                            }


                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>


                    <div className="session_caract_Dialog" > Fin
                        <DatePicker
                            name="event_dialog_end"
                            id="event_dialog_end"
                            selected={endDate}

                            onChange={(date) => {
                                if (String(props.write_access) === "1" && String(p_detail_sequence_session_id) === "")
                                    return setendDate(date);
                            }
                            }


                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_comment"
                            id="event_dialog_comment"

                            fullWidth

                            value={p_detail_comment}
                            onChange={(e) => {

                                console.log(" dans comment  p_detail_sequence_session_id = ", p_detail_sequence_session_id);
                                if (String(props.write_access) === "1" && String(p_detail_sequence_session_id) === "")
                                    setp_detail_comment(e.target.value);
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        {String(props.write_access) === "1" && String(p_detail_sequence_session_id) === "" && <div className="div_row_gauche">
                            {p_detail_event_id && String(p_detail_event_id) !== "" && <Button onClick={Update_One_Agenda_Event} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {(!p_detail_event_id || String(p_detail_event_id) === "") && <Button onClick={Update_One_Agenda_Event} className="bton_enreg_dialog">Ajouter</Button>}



                        </div>}
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Event_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>




                {String(props.write_access) === "1" && String(p_detail_sequence_session_id) === "" && p_detail_event_id && String(p_detail_event_id) !== "" && <DialogActions>
                    <div className="div_row" style={{ "textAlign": "center" }}>
                        <Button onClick={(e) => {
                            const response = window.confirm("Confirmez-vous la suppression de l'événement ?");
                            if (!response) {
                                return;
                            }
                            Delete_One_Agenda_Event();
                        }
                        } className="bton_supprime_dialog">Supprimer l'événement</Button>
                    </div>


                </DialogActions>}

            </Dialog>

            <h3> Planning </h3>


            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                    &nbsp;
                </div>

                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                    {(!display_other_agenda || String(display_other_agenda) !== "1") && <Button variant="contained" className="bton_enreg" onClick={show_other_agenda}>Voir autre planning
                    </Button>}

                    {display_other_agenda && String(display_other_agenda) === "1" && <Button variant="contained" className="bton_enreg" onClick={show_my_agenda}>Voir mon planning
                    </Button>}


                </div>
            </div>

            {display_other_agenda && String(display_other_agenda) === "1" && <div className="div_row" style={{ "margin": "5px" }}>
                <div className="div_row_gauche texte_area_filter_value" >
                    <Autocomplete
                        disablePortal
                        name="filtre_related_collection"
                        id="filtre_related_collection"
                        className="disabled_style"
                        value={p_filtre1_label}
                        options={New_Option_Type_Ressource}
                        onChange={(event, value) => {
                            if (value && value.value) {

                                setp_filtre1(value.value);
                                setselected_employee_id("");
                                setselected_related_collection(value.value);
                                setp_filtre1_label(value.label)

                            }

                        }}

                        renderInput={(params) => <TextField {...params} label="" />
                        }
                    />

                    {/*<TextField
                        name="filtre_related_collection"
                        id="filtre_related_collection"
                        label="Choisir un champ"
                        select
                        sx={{ m: 1, width: '100%' }}
                        value={p_filtre1}
                        onChange={(e) => {

                            setp_filtre1(e.target.value);
                            setselected_employee_id("");
                            setselected_related_collection(e.target.value);

                        }}
                    >
                        <MenuItem value="ressource_humaine" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Employé &nbsp;</MenuItem>
                        <MenuItem value="ressource_materielle" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Materiel &nbsp;</MenuItem>


                    </TextField>*/}
                </div>



                {p_filtre1 && String(p_filtre1) === "ressource_humaine" && <div className="div_row_droite texte_area_filter_value" >

                    <Autocomplete
                        disablePortal


                        name="filtre1_value"
                        id="filtre1_value"
                        className="disabled_style"
                        options={New_Getall_Training_Employee_No_Filter_result}
                        //value={selected_employee_id}
                        onChange={(event, value) => {
                            if (value && value._id) {

                                Get_Ressource_Data(value._id)
                                setselected_employee_id(value._id);
                                Get_List_Agenda_Events_For_Given_User(value._id);

                            }
                        }}

                        renderInput={(params) => <TextField {...params} label="Cible" />
                        }
                    />

                    {/*<TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="filtre1_value"
                        id="filtre1_value"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={selected_employee_id}
                        onChange={(e) => {

                            Get_Ressource_Data(e.target.value)
                            setselected_employee_id(e.target.value);
                            Get_List_Agenda_Events_For_Given_User(e.target.value);


                        }}
                    >
                        {Getall_Training_Employee_No_Filter_result &&
                            Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id} nom={JSON.parse(myclient).nom}
                                    style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} id="filtre1_value_list">
                                    {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                </MenuItem>
                            ))}

                    </TextField>*/}


                </div>}

                {p_filtre1 && String(p_filtre1) === "ressource_materielle" && <div className="div_row_droite texte_area_filter_value" >


                    <Autocomplete
                        disablePortal
                        name="filtre1_value"
                        id="filtre1_value"
                        className="disabled_style"
                        options={New_Getall_Training_Materiel_No_Filter_result}

                        onChange={(event, value) => {
                            if (value && value._id) {

                                Get_Ressource_Data(value._id)
                                setselected_employee_id(value._id);
                                Get_List_Agenda_Events_For_Given_User(value._id);

                            }
                        }}

                        renderInput={(params) => <TextField {...params} label="" />
                        }
                    />


                    {/*} <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="filtre1_value"
                        id="filtre1_value"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={selected_employee_id}
                        onChange={(e) => {
                            Get_Ressource_Data(e.target.value)
                            setselected_employee_id(e.target.value);
                            Get_List_Agenda_Events_For_Given_User(e.target.value);
                        }}
                    >
                        {Getall_Training_Materiel_No_Filter_result &&
                            Getall_Training_Materiel_No_Filter_result.map((myclient) => (
                                <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                    style={{ "paddingLeft": "25px", "textAlign": "left", "width": "100%" }}>
                                    {JSON.parse(myclient).nom}&nbsp;

                                </MenuItem>
                            ))}

                    </TextField>*/}


                </div>}



            </div>}
            <div className="div_row" style={{ "border": "None" }}>
                &nbsp;
            </div>

            <div className="div_row" style={{ "border": "None" }}>
                {agenda_owner_name && String(agenda_owner_name) === "Mon Planning" && <h5> <i> {agenda_owner_name} </i> </h5>}
                {agenda_owner_name && String(agenda_owner_name) !== "Mon Planning" && <h5> Vous êtes sur le planning de <i> {agenda_owner_name}  - ({agenda_owner_type}) </i> </h5>}
            </div>

            <div style={{ "width": "98%", "marginLeft": "5px" }}>
                &nbsp;

                <FullCalendar
                    locales={allLocales}
                    locale={'fr'}
                    editable
                    selectable
                    //events={events}
                    events={mysy_events}

                    select={handleSelect}
                    headerToolbar={{
                        start: "today prev next",
                        center: "title",
                        end: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    //eventContent={(info) => <EventItem info={info} />}
                    //plugins={[timeGridPlugin]}
                    plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                    slotMinTime={Partner_Debut_Journee}
                    slotMaxTime={Partner_Fin_Journee}
                    scrollTime='08:00'
                    displayEventEnd={true}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    eventColor='#378006'


                />



            </div>

        </div>
    )
}


export default Module_Agenda;