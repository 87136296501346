import Footer   from "../components/Fotter";
import Navigation from "../components/Navigation";


const acceuil = () => {


  return (
            <div>
             
            <Footer/>
            
        </div>

  )
}


export default acceuil;
