import React, { useState } from "react";
import { Button, } from "reactstrap";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import happyimg from "../mysy_img/happy.png";
import unhappyimg from "../mysy_img/nothappy.png";
import middleimg from "../mysy_img/middle.png";
import parse from 'html-react-parser'
import img_met_autre from "../mysy_img/met_autre.jpg";
import img_met_digital from "../mysy_img/met_digital.jpg";
import { Rating } from 'react-simple-star-rating'

const Article = (props) => {
  const [rating, setRating] = useState(props.article.note * 20)
  //console.log(props.article.note * 10);
  // Catch Rating value

  function handleRating(rate) {
    alert(" rate =" + rate);
    setRating(40);
  }

  return (

    <div className="article">

      <div className="div_row">


        <div className="div_row_gauche"  onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }}>
          <div style={{ "width": "100%", "height": "8rem" }}>
            <div className="gauche_img">
              <img src={img_met_digital} alt="Formation gestion projets" className="img_categorie" id="projets" />
            </div>
            <div className="droite_txt">
              <p className="met_text" id="projets" > {(String(props.article.title_formation)).substring(0, 50)} </p>

              <p className="met_text_detail" >

                <div className="art_criteres">
                  Avis :
                </div>
                <div className="art_criteres_value">
                 
                  {parse( String(props.article.avis).replace(/(<([^>]+)>)/ig, '') )}
                  
                </div>

              </p>

              <p className="met_text_detail" >

                <div className="art_criteres">
                 &nbsp; </div>
                <div className="art_criteres_value_left">
                 &nbsp;
                </div>
                <div className="art_criteres_value_right">
                {(String(props.article.date_avis)).substring(0, 10)}

                 </div>

              </p>

            </div>
          </div>


        </div>



      {/*  <div className="div_row_droite"  onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }}>
        <div style={{ "width": "100%", "height": "8rem" }}>
            <div style={{ "width": "30%", "float": "left" }}>
              <img src={img_met_digital} alt="Formation gestion projets" className="img_categorie" id="projets" />
            </div>
            <div style={{ "width": "70%", "float": "right" }}>
              <p className="met_text" id="projets" >{props.article.title_formation}  </p>

              <p className="met_text_detail" >

                <div className="art_criteres">
                  Avis :
                </div>
                <div className="art_criteres_value">
                  {parse(String(props.article.avis))}
                </div>

              </p>

              <p className="met_text_detail" >

                <div className="art_criteres">
                  Note :</div>
                <div className="art_criteres_value">
                  <Rating onClick={handleRating} 
                  ratingValue={rating} 
                  readonly={true} 
                  size={20} />

                </div>

              </p>

            </div>
          </div>
        </div> */}

      </div>
            

      {/* <div className="forma_rightbox"  onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }}>

        <div className="p_forma">
          <div>
            <div style={{ 'float': "left", "width": "50%", marginBottom: '0.1rem', textAlign:'justify' }}>
              <b>{props.article.title_formation} </b><br />
            </div>
            <div style={{ 'float': "left", "width": "20%", marginBottom: '0.1rem', textAlign:'center',
             marginLeft:'auto', marginRight:'auto', fontWeight:'bolder',  }}>
              {(props.article).note}

              {props.article.note > "4" &&
                <img class="img_note_class" src={happyimg} align="right" alt="MySy Training profile image" />
              }

              {props.article.note <= "2" &&
                <img class="img_note_class" src={unhappyimg} align="right" alt="MySy Training profile image" />
              }

              {props.article.note > "2" && props.article.note <= "4" &&
                <img class="img_note_class" src={middleimg} align="right" alt="MySy Training profile image" />
              }


            </div>
            <div style={{ 'float': "right", "width": "30%", marginBottom: '0.1rem', textAlign: "right", paddingRight: "0.3rem" }}>
              {(String(props.article.date_avis)).substring(0, 10)} <br />
            </div>
          </div>


          <div  style={{ 'float': "left", "width": "98%", marginBottom: '0.1rem', textAlign:'justify' }}>

          {parse(String(props.article.avis))} <br />
            
           
          </div>

        </div>
        <div style={{ "width": "100%", "margin-top": "0.2rem" }}>

          <div style={{ 'float': "right", "width": "30%", textAlign: "right", marginTop: "0.6rem", }}>
            <Button variant="contained" color="success"
              style={{
                'borderRadius': "10rem", "width": "100%",
                "width": "60% !important", background: "#104277"
              }}
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }}
            >Voir detail</Button>
          </div>

          <div style={{ 'float': "left", "width": "30%", marginTop: "0.6rem", }}>
            <div className="media_sociaux">
              <FacebookShareButton
                url={"https://www.mysy-training.com/Display-Detail-Article/" + props.article.internal_url}

                quote={props.article.title_formation + " -- " + props.article.avis}
                hashtag={"#MySyTraining"}
              >
                <SocialIcon network="facebook" className="SocialIcon" />
              </FacebookShareButton>
              &nbsp;

              <TwitterShareButton
                url={"https://www.mysy-training.com/Display-Detail-Article/" + props.article.internal_url}
                hashtag={"#MySyTraining"}
              >
                <SocialIcon network="twitter" className="SocialIcon" />
              </TwitterShareButton>

              &nbsp;
              <LinkedinShareButton
                url={"https://www.mysy-training.com/Display-Detail-Article/" + props.article.internal_url}
                title={" Un avis sur  : " + props.article.title_formation}
                summary={props.article.title_formation + " -- " + props.article.avis}
                source={"https://www.mysy-training.com/"}

              >
                <SocialIcon network="linkedin" className="SocialIcon" />
              </LinkedinShareButton>


            </div>

          </div>
        </div>
      </div>*/}
    </div>
  )
}


export default Article;