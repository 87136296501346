import React, { useRef, useState, useEffect } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { tokens } from "../theme";
import { MdDeleteForever } from "react-icons/md";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import {
    useTheme,
} from "@mui/material";

import { ResponsiveBar } from "@nivo/bar";

import { RiFileExcel2Line } from "react-icons/ri";

const Dashbord_Ressources_Humaines_Tache_Couts = (props) => {
    const [gridline_id, setgridline_id] = useState("");
    const [isLoading, setLoading] = useState();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [user_dashbord_id, setuser_dashbord_id] = useState(props.user_dashbord_id);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);

    const [selectionModel_task_cost, setSelectionModel_task_cost] = React.useState([]);
    const columns_task_cost = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'rh_civilite', headerName: 'Civ.', hide: false, minWidth: 50, flex: 1, maxWidth: 60 },
        { field: 'rh_nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 20 },
        { field: 'rh_prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200 },
        { field: 'rh_email', headerName: 'Email', hide: true, flex: 1, minWidth: 200 },
        { field: 'rh_ismanager', headerName: 'Manager', hide: true, minWidth: 50, flex: 1, maxWidth: 100 },
        { field: 'rh_fonction', headerName: 'Fonction', hide: false, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_telephone_mobile', headerName: 'Mobile', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_adr_adresse', headerName: 'Adesse', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_adr_code_postal', headerName: 'CP', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_adr_ville', headerName: 'Ville', hide: false, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_adr_pays', headerName: 'Pays', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },


        { field: 'rh_contrat_date_debut', headerName: 'Ctrat Du', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_date_fin', headerName: 'Ctrat Au', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_type_contrat', headerName: 'Ctrat Type', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_type_employe', headerName: 'Ctrat Empl.', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_cout', headerName: 'Ctrat Cout', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_periodicite', headerName: 'Ctrat Périodicité', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_quantite', headerName: 'Ctrat Qte', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_groupe_prix_achat_id', headerName: 'Grp Prix ID', hide: true, Width: 0, },
        { field: 'rh_contrat_groupe_prix_achat_code', headerName: 'Grp Prix Code', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_groupe_prix_achat_cout', headerName: 'Grp Prix Cout', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_contrat_groupe_prix_achat_periodicite', headerName: 'Grp Prix Périodicité', hide: true, minWidth: 100, flex: 1, maxWidth: 150 },


        { field: 'rh_event_planning_event_title', headerName: 'Planning Titre', hide: false, minWidth: 200, flex: 1, },
        { field: 'rh_event_planning_even_comment', headerName: 'Planning Comment', hide: false, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_event_planning_event_type', headerName: 'Planning Type', hide: false, minWidth: 100, flex: 1, maxWidth: 100 },
        { field: 'rh_event_planning_event_duration_hour', headerName: 'Planning Durée (h)', hide: false, minWidth: 50, flex: 1, maxWidth: 100 },
        { field: 'rh_event_planning_agenda_date_jour', headerName: 'Planning Date', hide: false, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'rh_event_planning_event_start', headerName: 'Planning Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200 },
        { field: 'rh_event_planning_event_end', headerName: 'Planning Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200 },
        { field: 'rh_event_planning_event_cost', headerName: 'Planning Cout Total (HT)', hide: false, minWidth: 150, flex: 1, maxWidth: 150 },

    ]

    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));



    const [event_type_filter, setevent_type_filter] = useState(props.user_dashbord_filter_type_envent);

    const [filter_value, setfilter_value] = useState("");

    const [Getall_Qry_RH_Task_Cost_Data_result_JSON, setGetall_Qry_RH_Task_Cost_Data_result_JSON] = useState([]);
    const [Getall_Qry_RH_Task_Cost_Data_api, setGetall_Qry_RH_Task_Cost_Data_api] = useState();
    const [Getall_Qry_RH_Task_Cost_Data_message, setGetall_Qry_RH_Task_Cost_Data_message] = useState();
    const [Getall_Qry_RH_Task_Cost_Data_result, setGetall_Qry_RH_Task_Cost_Data_result] = useState([]);
    function Getall_Qry_RH_Task_Cost_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("tab_rh_id", "all");

        form.append("event_type", event_type_filter);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Humain_Ressource_With_Planning_And_Cost/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_RH_Task_Cost_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_RH_Task_Cost_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_RH_Task_Cost_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_RH_Task_Cost_Data_api("true");
                setGetall_Qry_RH_Task_Cost_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_RH_Task_Cost_Data_api("false");
                setGetall_Qry_RH_Task_Cost_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_RH_Task_Cost_Data = ', error);
            setGetall_Qry_RH_Task_Cost_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }



    const [Remove_From_User_TBD_api, setRemove_From_User_TBD_api] = useState();
    const [Remove_From_User_TBD_message, setRemove_From_User_TBD_message] = useState();
    const [Remove_From_User_TBD_result, setRemove_From_User_TBD_result] = useState();
    function Remove_From_User_TBD() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');



        form.append("token", stored_cookie);
        form.append("user_dashbord_id", user_dashbord_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Remove_From_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Remove_From_User_TBD  res.data.message r_class = " + res.data.message);

                setRemove_From_User_TBD_api("true");
                setRemove_From_User_TBD_result(res.data.message);
                Getall_Qry_RH_Task_Cost_Data();
                alert(res.data.message);
                window.location.reload();


            }
            else {
                setRemove_From_User_TBD_api("false");
                setRemove_From_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Remove_From_User_TBD = ', error);
            setRemove_From_User_TBD_api("false");
            alert("Impossible de supprimer le tableau de bord de votre liste");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {

        setp_filter_date_from(props.user_dashbord_filter_start);
        setp_filter_date_to(props.user_dashbord_filter_end);
        setevent_type_filter(props.user_dashbord_filter_type_envent);


        Getall_Qry_RH_Task_Cost_Data();


        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [p_filter_date_from, p_filter_date_to])


    function Export_Dashbord_to_CSV(local_dashbord_internal_id) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_csv.csv";
        var nom_fichier_cmd_excel = "export_excel.xlsx";
        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/TBD_RH_01_Export_Dashbord_To_Excel/" + stored_cookie + "/" + local_dashbord_internal_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les données (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd_excel)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les données ")

            });

    }

    return (
        <div className="dashbord_session" style={{ "marginLeft": "1px", "marginRight": "2px" }}>
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <div style={{ "height": "40rem" }}>

                <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                    <nav style={{ "width": "80%", "float": "left", "textAlign": "center" }}>  TBD : {props.user_dashbord_title} - Du : {props.user_dashbord_filter_start} au : {props.user_dashbord_filter_end}</nav>
                    <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer de ma liste de TBD">
                        <Popup
                            trigger={
                                <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                    <MdDeleteForever style={{ "cursor": "pointer", }} />
                                </nav>
                            }
                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        En confirmant cette opération, le tableau sera supprimé de votre tableau de bord global.<br />
                                        Vous pourrez le rajouter à nouveau si besoin. <br />

                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                Remove_From_User_TBD();
                                                //console.log('modal closed ');
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </a>

                    <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip03" data-tooltip-html="Export Csv/Excel">
                        <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                            <RiFileExcel2Line style={{ "cursor": "pointer", }} onClick={(event) => {
                                Export_Dashbord_to_CSV(props.user_dashbord_id);

                            }} />
                        </nav>
                    </a>

                </div>

                <Box style={{ "color": "black", "height": "30rem" }}>
                    &nbsp;

                    <div className="session_data">
                          <div style={{ "border": "None" }}>

                              <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                  &nbsp;
                                  <Box
                                      sx={{
                                          height: 500,
                                          width: '100%',
                                          paddingRight: '1px',

                                          "& .MuiDataGrid-columnHeaders": {
                                              backgroundColor: "#c8cfd5",
                                              color: "black",
                                              fontSize: 14
                                          },

                                          '& .line--statut--selected': {
                                              backgroundColor: '#FBF2EF',
                                              color: 'black',
                                          },
                                          '& .line--statut--pair': {
                                              backgroundColor: 'rgba(235, 235, 235, .7)',
                                              color: 'black',
                                          },
                                          '& .line--statut--impair': {
                                              backgroundColor: '#FFFFFF',
                                              color: 'black',
                                          },

                                      }}
                                  >
                                      <DataGrid
                                          checkboxSelection

                                          onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel_task_cost(newSelectionModel);
                                              /*if (newSelectionModel.length === 1)
                                                  handleClick_edit_session_From_Line(newSelectionModel);*/
                                              if (newSelectionModel.length !== 1) {
                                                  /*  setaddOneParticipant();
                                                    setdisplay_detail_insc();
                                                    setselected_code_session();
                                                    setsubmenu();*/
                                              }
                                          }}
                                          selectionModel={selectionModel_task_cost}

                                          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                          rows={Getall_Qry_RH_Task_Cost_Data_result.map((item, index) => (
                                              {
                                                  id: index,
                                                  _id: JSON.parse(item)._id,
                                                  rh_civilite: JSON.parse(item).rh_civilite,
                                                  rh_nom: JSON.parse(item).rh_nom,
                                                  rh_prenom: JSON.parse(item).rh_prenom,
                                                  rh_email: JSON.parse(item).rh_email,
                                                  rh_ismanager: JSON.parse(item).rh_ismanager,
                                                  rh_fonction: JSON.parse(item).rh_fonction,
                                                  rh_telephone_mobile: JSON.parse(item).rh_telephone_mobile,
                                                  rh_adr_adresse: JSON.parse(item).rh_adr_adresse,
                                                  rh_adr_code_postal: JSON.parse(item).rh_adr_code_postal,
                                                  rh_adr_ville: JSON.parse(item).rh_adr_ville,
                                                  rh_adr_pays: JSON.parse(item).rh_adr_pays,
                                                  rh_contrat_date_debut: JSON.parse(item).rh_contrat_date_debut,
                                                  rh_contrat_date_fin: JSON.parse(item).rh_contrat_date_fin,
                                                  rh_contrat_type_contrat: JSON.parse(item).rh_contrat_type_contrat,
                                                  rh_contrat_type_employe: JSON.parse(item).rh_contrat_type_employe,
                                                  rh_contrat_cout: JSON.parse(item).rh_contrat_cout,
                                                  rh_contrat_periodicite: JSON.parse(item).rh_contrat_periodicite,
                                                  rh_contrat_quantite: JSON.parse(item).rh_contrat_quantite,
                                                  rh_contrat_groupe_prix_achat_id: JSON.parse(item).rh_contrat_groupe_prix_achat_id,

                                                  rh_contrat_groupe_prix_achat_code: JSON.parse(item).rh_contrat_groupe_prix_achat_code,
                                                  rh_contrat_groupe_prix_achat_cout: JSON.parse(item).rh_contrat_groupe_prix_achat_cout,
                                                  rh_contrat_groupe_prix_achat_periodicite: JSON.parse(item).rh_contrat_groupe_prix_achat_periodicite,
                                                  rh_event_planning_event_title: JSON.parse(item).rh_event_planning_event_title,
                                                  rh_event_planning_even_comment: JSON.parse(item).rh_event_planning_even_comment,
                                                  rh_event_planning_event_type: JSON.parse(item).rh_event_planning_event_type,
                                                  rh_event_planning_event_duration_hour: JSON.parse(item).rh_event_planning_event_duration_hour,
                                                  rh_event_planning_agenda_date_jour: JSON.parse(item).rh_event_planning_agenda_date_jour,
                                                  rh_event_planning_event_start: JSON.parse(item).rh_event_planning_event_start,
                                                  rh_event_planning_event_end: JSON.parse(item).rh_event_planning_event_end,
                                                  rh_event_planning_event_cost: JSON.parse(item).rh_event_planning_event_cost,

                                              }
                                          ))}

                                          columns={columns_task_cost}
                                          pageSize={10}
                                          className="datagridclass"

                                          onRowDoubleClick={(newSelectionModel) => {
                                             
                                          }}



                                          rowsPerPageOptions={[10]}
                                          disableSelectionOnClick
                                          components={{
                                              Toolbar: GridToolbar,
                                          }}
                                          //sx={datagridSx}
                                          getCellClassName={(params) => {

                                              // Pour la gestion de la couleur de zone double cliquée
                                              if (String(params.row.id) === String(gridline_id)) {
                                                  return 'line--statut--selected';
                                              }
                                              else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                  return 'line--statut--pair';
                                              }
                                              else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                  return 'line--statut--impair';
                                              }

                                          }}

                                          getRowClassName={(params) => {

                                              // Pour la gestion de la couleur de zone double cliquée
                                              if (String(params.row.id) === String(gridline_id)) {

                                                  return 'line--statut--selected';
                                              }
                                              else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                  return 'line--statut--pair';
                                              }
                                              else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                  return 'line--statut--impair';
                                              }

                                          }}


                                      />
                                  </Box>
                                  <br />

                              </div>

                          </div>

                      </div>


                </Box>

            </div>

        </div>
    )
}


export default Dashbord_Ressources_Humaines_Tache_Couts;