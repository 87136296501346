import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import SideBar from "./sidebar";
import ReactPlayer from 'react-player'
import { Slide } from 'react-slideshow-image';
import { Zoom } from 'react-slideshow-image';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import 'react-slideshow-image/dist/styles.css'
import bannerimg from "../mysy_img/MYSY_banner_compte.png";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import { useParams } from 'react-router-dom'
import Select, { StylesConfig } from 'react-select';
import IconButton from '@mui/material/IconButton';
import { Fade } from 'react-slideshow-image';
import { getCookie, setCookie } from 'react-use-cookie';
import Button from '@mui/material/Button';
import { FcAddColumn, FcApproval } from "react-icons/fc";
import { FaHandPointRight } from "react-icons/fa";

import Header from "./Header";
import { useLocation } from "react-router-dom";
import fileDownload from 'js-file-download'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { WorkRounded } from "@mui/icons-material";

import * as V from 'victory';
import { VictoryBar, VictoryLine, VictoryScatter, VictoryChart } from 'victory';
import Canvas from 'react-canvas-js'

import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Legend } from 'recharts';

import Chart from 'react-apexcharts'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Editor } from '@tinymce/tinymce-react';
import { ImMobile2, ImWhatsapp, ImHome, ImOffice } from "react-icons/im";
import { BsMailbox } from "react-icons/bs";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Formation from "./Formation";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

import { Toolbar } from "@mui/material/Toolbar";

import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { DataGrid, GridRowsProp, GridColDef, GridToolbar, frFR } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import { Container, Row, Col } from 'react-grid-system';



// pour le payement stripe : https://stripe.com/docs/payments/quickstart?client=react&lang=python
const stripePromise = loadStripe('pk_test_51LUUfAAbmaEugrFTI25uZBD3IFjbtaL6jUfRV83diDf7nco8worna4NGKhMHbPP71WCwT5EHFRdDNatxPrJWwgZ300kgH5EO4p');

const datagridSx = {
  borderRadius: 2,
  "& .MuiDataGrid-main": { borderRadius: 2 },
  /*'& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
    color: "blue",
    fontSize: 18,
    //risky
    minHeight: "60px !important",
    height: 60,
    "& div": {
      minHeight: "60px !important",
      height: 60,
      lineHeight: "59px !important"
    }
  },*/
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-row": {
      "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
    }
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "rgba(0,0,255,0.6)",
    color: "rgba(255,0,0,0.7)",
    fontSize: 16
  }
};

const handleCellClick = (param, event) => {
  console.log(param);
  console.log(event);
  if (param.colIndex === 2) {
    event.stopPropagation();
  }
};

const handleRowClick = (param, event) => {
  console.log("Row:");
  console.log(param);
  console.log(event);
};

function ExpandableCell_50({ value }) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <nav style={{wordBreak:"break-all"}}>
      {expanded ? value : value.slice(0, 10)}&nbsp;
      {value.length > 10 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          type="button"
          component="button"
          sx={{ fontSize: 'inherit' }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Réduire' : 'Voir plus'}
        </Link>
      )}
    </nav>
  );
}


const columns21 = [
  {
    field: "id",
    headerName: "ID",
    width: 70
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 130,
    renderCell: (cellValues) => {
      return (
        <div
          style={{
            color: "blue",
            fontSize: 18,
            width: "100%",
            textAlign: "right"
          }}
        >
          {cellValues.value}
        </div>
      );
    }
  },
  { field: "lastName", headerName: "Last Name", width: 100, renderCell: (params) => <ExpandableCell_50 {...params} />, },
  {
    field: "age",
    headerName: "Age",
    headerAlign: "left",
    type: "number",
    //width: 90,
    minWidth: 90,
    flex: 1,
    align: "left"
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    minWidth: 160,
    flex: 2,
    //width: 160,
    valueGetter: (params) => {
      return `${params.getValue(params.id, "firstName") || ""} ${params.getValue(params.id, "lastName") || ""
        }`;
    }
  }
];

const rows21 = [
  { id: 1, lastName: "Aliquam dapibus, lorem vel mattis aliquet, purus lorem tincidunt mauris, in blandit quam risus sed ipsum.", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Amy", age: 42 },
  {
    id: 3,
    lastName: "IGOTAREALLyLONGNAME!!!!!!!us, lorem vel mattis aliquet, purus lorem tincidunt mauris, in blandit quam risus sed ipsum",
    firstName: "Jaime",
    age: 45
  },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: 12 },
  { id: 6, lastName: "Melisandre", firstName: "Jane", age: 15 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 }
];

const TestUrl = (props) => {


  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
  };





  const [interpolation, setinterpolation] = useState("linear");
  const [polar, setpolar] = useState(false);



  const [suggestedwords, setsuggestedwords] = useState([]);
  const [texte, settext] = useState("");

  const [phoneNumber, setphoneNumber] = useState("")

  const { classId } = useParams();
  const [pokemon, setPokemon] = useState();

  const location = useLocation();
  useEffect(() => {

    fetch("https://jsonplaceholder.typicode.com/posts")
      .then((data) => data.json())
      .then((data) => {
        settabledata(
          data.map((x, index) => ({
            ...x,
            rowid: index,
            confirmed: false
          }))
        );
      });

  }, []);


  const [suggestion2, setsuggestion2] = useState([]);


  const [data_global, setdata_global] = useState([]);
  const stored_part = getCookie('tokenmysypart');

  /*
Cette fonction recuperer les stats des TOP 5 formations sur la periode saisie par l'utilisateur
  */

  const [detail_view_categories, setdetail_view_categories] = useState();
  const detail_series = detail_view_categories;

  const [my_tabb, setmy_tabb] = useState([]);



  const [topX_formation, settopX_formation] = useState([]);

  const [detail_view_categorie, setdetail_view_categorie] = useState([]);

  const [detail_view_data1, setdetail_view_data1] = useState([]);
  const [detail_view_data1_name, setdetail_view_data1_name] = useState([]);

  const [detail_view_data2, setdetail_view_data2] = useState([]);
  const [detail_view_data2_name, setdetail_view_data2_name] = useState([]);

  const [detail_view_data3, setdetail_view_data3] = useState([]);
  const [detail_view_data3_name, setdetail_view_data3_name] = useState([]);

  const [detail_view_data4, setdetail_view_data4] = useState([]);
  const [detail_view_data4_name, setdetail_view_data4_name] = useState([]);



  var series_detail = [
    {
      name: detail_view_data1_name,
      data: detail_view_data1
    },

    {
      name: detail_view_data2_name,
      data: detail_view_data2
    },

    {
      name: detail_view_data3_name,
      data: detail_view_data3
    },

    {
      name: detail_view_data4_name,
      data: detail_view_data4
    },
  ]

  const options_detail = {
    chart: {
      id: 'apexchart-example'
    },
    xaxis: {
      categories: detail_view_categorie //['2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07', '2022-09-06']
    },
    colors: ['#CB4335', '#1A5276', '#28B463', '#6E2C00', '#1B2631']
  }






  // categories = aux dates
  //



  const [global_view_categories, setglobal_view_categories] = useState([]);
  const options_global_view = {
    chart: {
      id: 'apexchart-example'
    },
    xaxis: {
      categories: global_view_categories
    },
    colors: ['#17A8F5', '#A93226']
  }


  const [global_view_data, setglobal_view_data] = useState([]);
  const [global_view_data_v2, setglobal_view_data_v2] = useState([]);

  const series_Gobal = [
    {
      name: 'global_view',
      data: global_view_data
    },


  ]

  // -- Detail series 

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }


  const today = new Date();
  const tomorrow = new Date(today);
  const [state, setState] = useState([
    {
      startDate: today.setDate(tomorrow.getDate() - 8),
      endDate: today.setDate(tomorrow.getDate() - 1),
      key: "selection"
    }
  ]);


  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      //console.log(editorRef.current.getContent());
      alert(editorRef.current.getContent());
    }

    setgrr(" vvvv ");
  };

  const [grr, setgrr] = useState(" icic ");
  function DataUpdated() {
    alert(" change ");
  }

  function toto() {
    alert(" TOTO ");
  }

  const [price_ch, setprice_ch] = useState("price_1LpyndAbmaEugrFTKZAAkm72");
  const [customer_ch, setcustomer_ch] = useState("cus_MZjO0MIKiy2t6l");
  const [subscriptionData, setSubscriptionData] = useState(null);

  const [payement_id, setpayement_id] = useState();
  const [clientSecret_id, setclientSecret_id] = useState();
  const [paymentIntent, setPaymentIntent] = useState();


  function initpayement_front() {
    alert("debut initpayement_front price_ch = " + price_ch);
    createSubscription(price_ch);
    //console.log("55");
  }

  const createSubscription = async (priceId) => {

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/create-subscription/";
    var form = new FormData();
    form.append("customerid", "cus_MZjO0MIKiy2t6l");
    form.append("priceid", price_ch);

    fetch(myurl, {
      method: 'POST',
      body: form,
    }).then((data) => data.json())
      .then((data) => {
        //console.log(' #### subscription_id:', data['subscription_id'], 'payment_intent_client_secret:', data['payment_intent_client_secret'],  "data['Status'] = ", data['status'], );

        if (String(data['status']) === String("true")) {
          //console.log(" subscriptionId = " + subscriptionId + " -- et clientSecret = " + clientSecret);
          //console.log("1");
          setclientSecret_id(data['payment_intent_client_secret']);
          //console.log("2");
          setpayement_id(data['subscription_id']);
          //console.log("3");
          //setSubscriptionData(data['subscription_id'], data['payment_intent_client_secret'] );
          //alert(" subscriptionId = " + data['subscription_id'] + " -- et clientSecret = " + data['payment_intent_client_secret']);

        }
        //console.log("44");

      }).catch((error) => {
        alert("4");
        //console.log('Error:', error.message);


      });

  };

  function finalize_abonnement() {
    alert("Finalization de l'abonnement ");
    // Initialize an instance of stripe.


    const handleSubmit_ch = async (e) => {


      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use card Element to tokenize payment details
      alert(' clientSecret_id = ' + clientSecret_id)
      let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret_id, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: "cherif test MYSY",
          }
        }
      });

      if (error) {
        // show error and collect new card details.
        alert(error.message);
        //console.log(error.message);
        return;
      }
      else {
        alert(" OKK paymentIntent = " + paymentIntent);
        //console.log(paymentIntent);
      }
      setPaymentIntent(paymentIntent);
    }

    handleSubmit_ch();

  }





  const [cherif3DS, setcherif3DS] = useState("ok");
  window.top.postMessage('3DS-authentication-complete');

  function fermer() {
    window.close();
  }

  function finaliser() {
    window.open(
      "/",
      '_self'
    );
  }

  const [isLoading, setLoading] = useState();


  function loading() {

    setLoading(true);
    setTimeout(() => { // simulate a delay
      axios.get("https://pokeapi.co/api/v2/pokemon/1")
        .then((response) => {

          // Get pokemon data
          setPokemon(response.data); //set pokemon state
          setLoading(false); //set loading state
        });
    }, 500000);


  }

  const columns = ["Name", "Company", "City", "State"];

  const data11 = [
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
  ];

  function hellofoo() {
    alert(" hellooooooooo");
  }

  const options11 = {
    filterType: 'checkbox',
    selectableRows: 'single',
    selectableRowsOnClick: true,
    customToolbar: () => {
      return (
        <IconButton style={{ order: -1 }} onClick={hellofoo}>
          <FaHandPointRight />
        </IconButton>
      );
    },

    selectedRows: {
      text: "ligne(s) selectionée(s)",
      delete: "Supprimer",
      deleteAria: "Delete Selected Rows",
    },
    toolbar: {
      search: "Recherche",
      downloadCsv: "Download CSV",
      print: "Imprimer",
      viewColumns: "Voir Columns",
      filterTable: "Filtrer Table",
    },
    pagination: {
      next: "Prochaine Page",
      previous: "Page Precedente",
      rowsPerPage: "ligne par page:",
      displayRows: "of",
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {

        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: '8px',
              //backgroundColor: '#CDCAC6',
            }
          }
        },

        MUIDataTableToolbar: {
          actions: {
            display: 'flex',
            flexDirection: 'row',
            flex: 'initial',
          },
        },

        MuiToolbar: {
          styleOverrides: {
            regular: {
              minHeight: '8px',
            }
          }
        },


        MuiTableRow: {
          root: {
            "&.MuiTableRow-hover": {
              "&:hover": {
                backgroundColor: "yellow",
              }
            }
          }
        }

      }
    });


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    alert(" Utiliser le bouton 'fermer' ");
    //setOpen(false);
  };

  const handleClose_buton = () => {
    setOpen(false);
  };




  const rowss = [
    { id: 1, col1: 'Hello', col2: 'World', col3: '12', col4: true },
    { id: 2, col1: 'DataGridPro', col2: 'is Awesome', col3: '12', col4: true },
    { id: 3, col1: 'MUI', col2: 'is Amazing', col3: '123', col4: false },
    { id: 4, col1: 'MUI2', col2: 'is Amazing2', col3: '02', col4: true },
    { id: 5, col1: 'DataGridPro 5', col2: 'is Awesome 5', col3: '17', col4: false },
  ];

  const columnss = [
    { field: 'col1', headerName: 'Column 1', width: 150 },
    { field: 'col2', headerName: 'Column 2', width: 150 },
    { field: 'col3', headerName: 'montant', width: 150 },
    { field: 'col4', headerName: 'statut', width: 150 },
  ];


  const columnsz = [
    { field: 'city' },
    { field: 'oct', type: 'number', valueFormatter: ({ value }) => `${value} °C` },
    { field: 'nov', type: 'number', valueFormatter: ({ value }) => `${value} °C` },
    { field: 'dec', type: 'number', valueFormatter: ({ value }) => `${value} °C` },
    { field: 'dec', type: 'number', valueFormatter: ({ value }) => `${value} °C` },
    { field: 'status', headerName: 'statut', width: 150, type: 'boolean', editable: true, },
    { field: 'stape', headerName: 'étape', width: 150, type: 'string', editable: true, },
  ];

  const rowsz = [
    { id: 1, city: 'Amsterdam', oct: 7.1, nov: 4, dec: 10.2, status: false, stape: 'planifie' },
    { id: 2, city: 'Barcelona', oct: 14.9, nov: 12.3, dec: 18.2, status: true, stape: 'facturé' },
    { id: 3, city: 'Paris', oct: 8.1, nov: 5.4, dec: 12.3, status: false, stape: 'encours' },
    { id: 4, city: 'São Paulo', oct: 20.2, nov: 21.1, dec: 19.2, status: true, stape: 'planifie' },
  ];

  const useStyles = makeStyles((theme) =>
  ({
    root: {
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary[300] : theme.palette.secondary[900]
      },
    },
  }),
  );

  function handleconfirmchange(clickedrow) {
    const updateddata = tabledata.map((x) => {
      if (x.rowid === clickedrow) {
        return {
          ...x,
          confirmed: true
        };
      }
      return x;
    });
    settabledata(updateddata);
  }


  const paymentOptions = [
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "Un Paid" },
  ];

  const columns_bibi = [
    { field: "title", headername: "title", width: 200 },
    { field: "body", headername: "body", width: 400 },
    {
      field: "payment_status",
      headerName: "Payment Status",
      type: 'singleSelect',
      valueOptions: paymentOptions,

      valueFormatter: ({ id, value, field }) => {
        const option = paymentOptions.find(
          ({ value: optionValue }) => optionValue === value
        );

        return option.label;
      },
    },
    {
      field: "confirmed",
      headername: "confirmed",
      rendercell: (params) => (
        <Checkbox
          checked={params.rows?.confirmed}
          onchange={() => handleconfirmchange(params.row.rowid)}
        />
      )
    }
  ];
  const [tabledata, settabledata] = useState([]);


  return (
    <div className="test_page2">
      <Helmet>
        <title>hihihihihi</title>
        <meta name="description" content="{this.metaDetails.description}" />
        <meta name="keywords" content="{this.metaDetails.keywords}" />
      </Helmet>

      <Header />

      <div className="div_mobile">

        &nbsp;

        <div style={{ height: 400, width: "70%" }}>
          <Container>
            <Row>
              <Col sm={4}>
                One of three columns
              </Col>
              <Col sm={4}>
                One of three columns
              </Col>
              <Col sm={4}>
                One of three columns
              </Col>
            </Row>
          </Container>
        </div>



        <Box
          sx={{
            height: 300,
            width: '100%',
            '& .cold': {
              backgroundColor: '#b9d5ff91',
              color: '#1a3e72',
            },
            '& .hot': {
              backgroundColor: '#ff943975',
              color: '#1a3e72',
            },
            '& .true': {
              backgroundColor: '#2EFE2E',
              color: '#1a3e72',
            },
            '& .planifie': {
              backgroundColor: '#FA5882',
              color: 'black',
            },
            '& .facturé': {
              backgroundColor: 'black',
              color: 'white',
            },
            '& .line--true': {
              backgroundColor: 'red',
              color: 'white',
            },
            '& .line--false': {
              backgroundColor: 'yellow',
              color: 'white',
            },
          }}
        >
          <DataGrid
            rows={rowsz}
            columns={columnsz}
            getCellClassName={(params) => {
              if (params.field === 'city' || params.value == null) {
                return '';
              }

              if (params.field === 'status' && params.value == true) {
                return 'true';
              }

              if (params.field === "stape" && String(params.value) === "planifie") {
                return 'planifie'
              }

              if (params.field === "stape" && String(params.value) === "facturé") {
                return 'facturé'
              }

              return params.value >= 15 ? 'hot' : 'cold';
            }}

            //getRowClassName={(params) => `line--${params.row.status}`}
            getRowClassName={(params) => {
              if (params.row.status === true) {
                return 'line--true';
              }
            }}

          />
        </Box>

        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows21}
            columns={columns21}
            getEstimatedRowHeight={() => 100}
            getRowHeight={() => 'auto'}
            sx={{
              "& .MuiDataGrid-cellContent": {
                  minHeight: 40,
                  maxHeight: 1000
              },[`& .${gridClasses.cell}`]: {
                py: 1,
              },
          }}
          />
        </div>


        iciiii
        {<div style={{ height: 500, width: "100%" }}>
          <DataGrid
            //toolBar={<Toolbar sx={{ backgroundColor: "blue" }} />}
            headerHeight={60}
            rowHeight={120}

            rows={rows21}
            columns={columns21}
            pageSize={5}
            checkboxSelection
            onCellClick={handleCellClick}
            onRowClick={handleRowClick}
            getRowHeight={() => 'auto'}
            sx={{
              "& .MuiDataGrid-cellContent": {
                  minHeight: 40,
                  maxHeight: 1000
              },[`& .${gridClasses.cell}`]: {
                py: 1,
              },
          }}

           
          />
          <div style={{ margin: "40px", textAlign: "center" }}>
            <a
              target="_blank"
              href="https://smartdevpreneur.com/the-ultimate-guide-to-customizing-material-ui-datagrid/"
            >
              How do you customize the DataGrid styling?
            </a>
          </div>
        </div>}


        <div style={{ height: 500, width: '100%' }}>
          &nbsp;
          <Box sx={{
            height: 300,
            width: '100%',
            '& .cold': {
              backgroundColor: '#b9d5ff91',
              color: '#1a3e72',
            },
            '& .hot': {
              backgroundColor: '#ff943975',
              color: '#1a3e72',
            },
          }}>

            <DataGrid
              rows={rowss}
              columns={columnss}
              pageSizeOptions={[5, 10]}
              //checkboxSelection
              sx={datagridSx}
            />
          </Box>
        </div>

        <div>
          <Button variant="outlined" onClick={handleClickOpen}>
            Open form dialog
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address here. We
                will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                variant="standard"
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose_buton}>Cancel</Button>
              <Button onClick={handleClose_buton}>Subscribe</Button>
            </DialogActions>
          </Dialog>

        </div>
        <Timeline position="right">
          <TimelineItem>

            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              9:30 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot>
                <FastfoodIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                Eat
              </Typography>
              <Typography>Because you need strength</Typography>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              variant="body2"
              color="text.secondary"
            >
              10:00 am
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot color="primary">
                <LaptopMacIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography variant="h6" component="span">
                Code
              </Typography>
              <Typography>Because it&apos;s awesome!</Typography>
            </TimelineContent>
          </TimelineItem>

        </Timeline>


        <h2> cartouche formaion </h2>

        <div style={{ "width": "100%", }}>

          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"Employee List"}
              data={data11}
              columns={columns}
              options={options11}
            />
            <IconButton style={{ order: -1 }} onClick={hellofoo}>
              <FaHandPointRight /> Ajouter une ligne
            </IconButton>
          </ThemeProvider>

        </div>
        <Tooltip className="tooltip_css" id="my-tooltip" />

        <a data-tooltip-id="my-tooltip" data-tooltip-html="Hello world!">
          info bull infobulle  ◕‿‿◕
        </a>

        <button onClick={loading} id="finalise"> loading .....</button>
        <div>

          {isLoading && <div className="loader-container">

            <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>

          </div>}


        </div>
        <h2>payement strip</h2>

        <button onClick={finaliser} id="finalise"> Finaliser la commande</button>


        <button onClick={fermer}> Commande validé, fermer </button>

        <br />
        <h4>Popup - Scaler Academy</h4>

        <br />


        <Popup
          trigger={<button className="button">  Open Modal simplifié </button>}
          modal
          nested
          position="center center"

        >
          modall simple
        </Popup>
        <br />
        <br />
        <Popup
          trigger={<button className="button"> Open Modal 2 1</button>}
          modal
          nested
          position="center center"

        >
          {(close) => (
            <div>
              <button className="ch_close" onClick={close}> aaaa
                &times;
              </button>
              <div className="ch_header"> MySy Info </div>
              <div className="ch_content">
                {' '}
                Saisir les informatons pour valider la formation*
                <br />
                <input type="number" id="prix" name="prix" min="0" /><br />

                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
                commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
                explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
              </div>
              <div className="ch_actions">
                <div style={{ "width": "45%", "float": "left" }}>
                  <Popup
                    trigger={<button className="ch_bton_popup"> Valider </button>}
                    position="top center"
                    nested
                  >
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
                      magni omnis delectus nemo, maxime molestiae dolorem numquam
                      mollitia, voluptate ea, accusamus excepturi deleniti ratione
                      sapiente! Laudantium, aperiam doloribus. Odit, aut.
                    </span>
                  </Popup>
                </div>
                <div style={{ "width": "45%", "float": "right" }}>
                  <button
                    className="ch_bton_popup"
                    onClick={() => {
                      //console.log('modal closed ');
                      close();
                    }}
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          )}
        </Popup>
        <br />



        <br />

        <button onClick={initpayement_front}>
          Init Payement
        </button>

        {clientSecret_id && <div>  <button onClick={initpayement_front}>
          Valide payement {clientSecret_id}
        </button>
        </div>}

        Ss
        <div style={{ "width": "30%", "marginLeft": "auto", "marginRight": "auto" }}>
          <h1>Subscribe</h1>

          <p>
            Try the successful test card: <span>4242424242424242</span>.
          </p>

          <p>
            Try the test card that requires SCA: <span>4000002500003155</span>.
          </p>

          <p>
            Use any <i>future</i> expiry date, CVC,5 digit postal code
          </p>

          <hr />

          <div>
            <label>
              Full name
              <input type="text" id="name" value="cherif balde" />
            </label>

            <CardElement />

            <button onClick={finalize_abonnement}>
              Finalise Subscribe
            </button>


          </div>
        </div>

        <div> <br />
        </div>

        <div className="carte_visite">
          <div className="main_div">
            <div className="ch_div_gauche" style={{ "borderRight": "5px solid gray", "borderRadius": "0px" }}>
              <br />
              <img classname="img" src="http://88.170.110.220/img/MYSY-LOGO-BLUE.png" alt="Mysy Training Logo"
                style={{ "maxWidth": "30%", "marginTop": "2rem" }} />
              <br />

              <div className="div_nom">
                Cherif BALDE<br />
              </div>
              <div className="div_function">
                Directeur
              </div>
            </div>
            <div className="ch_div_centre">
              <hr className="myhr" />


            </div>

            <div className="ch_div_droite div_coordonnees" style={{ "paddingRight": "3rem" }}>
              <div> <ImMobile2 /> &nbsp;+337 69 20 39 45</div>
              <div> <ImOffice /> &nbsp;+331 77 00 38 57</div>
              <div> <BsMailbox /> &nbsp;cbalde@mysy-training.com</div>
              <div> <ImHome /> &nbsp;https://www.mysy-training.com/</div>
              <div> <ImWhatsapp /> &nbsp;+337 69 20 39 45</div>

            </div>
          </div>

        </div>



        {/* 
        
         <div>
          Description: <br />
          <Editor
            onInit={(evt, editor) => editorRef.current = editor}

            initialValue="<p>Votre description.</p>"

            value={grr}
            onKeyUp={toto}
            onEditorChange={(newValue, editor) => {
              //console.log(" newValue = "+newValue);
             // alert(" newValue = "+newValue);
              //setText(editor.getContent({format: 'text'}));
            }}
            init={{
              resize: false,
              height: 300,
              width: 800,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;line-height: 0.6rem;  }'
            }}
          />

          <button onClick={log}>Log editor content</button>

        </div>
        
        <div>
         Description: <br />
        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue="<p>Votre description.</p>"
          init={{
            resize: false,
            height: 300,
            width: 800,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
              'alignleft aligncenter alignright alignjustify | ' +
              'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;line-height: 0.6rem;  }'
          }}
        />

        <button onClick={log}>Log editor content</button>


        <h1>react-date-range Example</h1>
        <DateRange
          onChange={item => setState([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={state}
        />

        <Button onClick={getStatData}> Recup Stat info</Button>

        <Button onClick={Get_Top5_Stat}> Recup Stat top 5</Button>




        {global_view_data && global_view_data.length > 3 && <div className="row">


          <div className="col-md-12">
            <h2>Tout compilé cherif </h2>
          </div>

          <div className="section col-md-6">
            <h3 className="section-title">Line Chart a (global)</h3>
            <div className="section-content">
              <Chart options={options_global_view} series={series_Gobal} type="line" />
            </div>
          </div>

          <div className="section col-md-6">
            <h3 className="section-title">Bar Chart b (global) </h3>
            <div className="section-content">
              <Chart options={options_global_view} series={series_Gobal} type="bar" />
            </div>
          </div>

        </div>
        }



        detail_view_categorie =  {detail_view_categorie &&
          detail_view_categorie.map((val) => (
            <li style={{ "textAlign": "left", "marginLeft": "5rem" }}>
              {val}

            </li>

          ))}



        {topX_formation && topX_formation.length > 3 && <div>

          <h3>Le top 5  de vos formationes de la semaine est : </h3>


          {topX_formation &&
            topX_formation.map((val) => (
              <li style={{ "textAlign": "left", "marginLeft": "5rem" }}>
                {val}

              </li>

            ))}



        </div>}


        <div className="row">


          <div className="col-md-12">
            <h2>Top 5 cherif </h2>
          </div>


          <div className="section col-md-6">
            <h3>Le top 5  de vos formationes de la semaine est : </h3>


            {topX_formation &&
              topX_formation.map((val) => (
                <li style={{ "textAlign": "left", "marginLeft": "5rem" }}>
                  {val}

                </li>

              ))}

          </div>

          <div className="section col-md-6">
            <h3 className="section-title">Line Chart a - la </h3>
            <div className="section-content">
              <Chart options={options_detail} series={series_detail} type="line" />
            </div>
          </div>


        </div>



        <h2>payement strip</h2>

        <div style={{ "width": "100%", "height": "15rem", "margin": "2rem" }}>
          <InterpolationSelect
            currentValue={interpolation}
            values={polar ? polarInterpolations : cartesianInterpolations}
            onChange={(event) => setinterpolation(event.target.value)}
          />

          <br />     <input
            type="checkbox"
            id="polar"
            value={polar}
            onChange={
              (event) => setpolar(event.target.checked)

            }
            style={{ marginLeft: 25, marginRight: 5 }}
          />

          <label htmlFor="polar">polar</label>
          <VictoryChart polar={polar} height={590} width={600} backgroundColor={"pink"}>
            <VictoryLine
              interpolation={interpolation} data={data}
              style={{ data: { stroke: "#c43a31" } }}
            />
            <VictoryScatter data={data}
              size={5}
              style={{ data: { fill: "#c43a31" } }}
            />
          </VictoryChart>



          <br />
        </div>


        {<div className="container" style={{ "textAlign": "left" }}>
          test 2
          <input className="col-md-12" type={"text"} onChange={changech}
            value={texte} onBlur={OnblurHandle} />
          {suggestion2 && suggestion2.slice(0, 5).map((suggestion, id) =>
            <div key={id} style={{ "cursor": "pointer", "color": "gray", }}
              onClick={() => OnSuggestionHandler(JSON.parse(suggestion).name)}> {JSON.parse(suggestion).name}</div>
          )}
        </div>}



        <br />

      

         
      
        <div style={{ width: 1200, "top": "30rem", "position": "fixed" }}>
          <ReactSearchAutocomplete
            items={suggestedwords}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
          />

        </div>
        </div> 
      </div>
      <div className="carte_visite">
        <div className="main_div">
          <div className="ch_div_gauche">
            <br />
            <img classname="img" src="http://88.170.110.220/img/MYSY-LOGO-BLUE.png" alt="Mysy Training Logo" style={{ "maxWidth": "30%" }} />
            <br />

            <div className="div_nom">
              Cherif BALDE<br />
            </div>
            <div className="div_function">
              Directeur
            </div>
          </div>
          <div className="ch_div_centre">
            <hr className="myhr" />


          </div>

          <div className="ch_div_droite div_coordonnees">
            <div> <ImMobile2 /> &nbsp;+337 69 20 39 45</div>
            <div> <ImMobile2 /> &nbsp;+337 69 20 39 45</div>
            <div> <BsMailbox /> &nbsp;cbalde@mysy-training.com</div>
            <div> <ImHome /> &nbsp;https://www.mysy-training.com/</div>
            <div> <ImWhatsapp /> &nbsp;+337 69 20 39 45</div>

          </div>
        </div>

      </div>


      <div className="carte_visite">
        <div className="main_div">
          <div className="ch_div_gauche">
            <br />
            <img classname="img" src="http://88.170.110.220/img/MYSY-LOGO-BLUE.png" alt="Mysy Training Logo" style={{ "maxWidth": "30%" }} />
            <br />

            <div className="div_nom">
              Gnanki DOKOTO<br />
            </div>
            <div className="div_function">
              Managing Partner | Directrice Associée
            </div>
          </div>
          <div className="ch_div_centre">
            <hr className="myhr" />


          </div>

          <div className="ch_div_droite div_coordonnees">
            <div> <ImMobile2 /> &nbsp;+336 68 54 36 66</div>
            <div> <BsMailbox /> &nbsp;gdokoto@mysy-training.com</div>
            <div> <ImHome /> &nbsp;https://www.mysy-training.com/</div>
            <div> <ImWhatsapp /> &nbsp;+337 69 20 39 45</div>

          </div>
        </div>

      </div>
      <div
        style={{
          marginLeft: "40%",
        }}
      >


      </div>
*/}
      </div>
    </div >

  )
}


export default TestUrl;
