import React, { useState, useEffect } from "react";

import Button from '@mui/material/Button';

import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'

import { useHistory } from "react-router-dom";

import 'react-tooltip/dist/react-tooltip.css'
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";

function Partner_Invoicing_Data(props) {

    const [p_nom, setp_nom] = useState("");
    const [p_vat_num, setp_vat_num] = useState("");
    const [p_taux_vat_value, setp_taux_vat_value] = useState("20");
    const [p_telephone, setp_telephone] = useState("");
    const [p_email, setp_email] = useState("");
    const [p_adr_street, setp_adr_street] = useState("");
    const [p_adr_zip, setp_adr_zip] = useState("");
    const [p_adr_city, setp_adr_city] = useState("");
    const [p_adr_country, setp_adr_country] = useState("");



    const [fields1desabled, setfields1desabled] = useState("");

    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }

    const [RecordData_api, setRecordData_api] = useState();
    const [RecordData_result, setRecordData_result] = useState();
    const [RecordData_message, setRecordData_message] = useState();
    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";

        var nom = document.getElementsByName("nom")[0].value;
        var vat_num = document.getElementsByName("vat_num")[0].value;
        var taux_vat = document.getElementsByName("taux_tva_value")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;

        formData.append('token', stored_part);
        formData.append('invoice_vat_num', vat_num);
        formData.append('invoice_taux_vat', taux_vat);
        formData.append('invoice_nom', nom);
        formData.append('invoice_adr_street', adr_street);
        formData.append('invoice_adr_city', adr_city);
        formData.append('invoice_adr_zip', adr_zip);
        formData.append('invoice_adr_country', adr_country);
        formData.append('invoice_email', email);
        formData.append('invoice_telephone', telephone);
        formData.append('partner_account_id', props.partner_account_id);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);



                if (String(result['status']) === String("true")) {

                    setRecordData_api("true");
                    setRecordData_result(result['message']);

                    alert("Les données de facturation ont été mises à jour");
                    desablefield1();
                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setRecordData_api("true");
                    setRecordData_message(result['message']);
                    alert(result['message']);
                }


            })
            .catch((error) => {
                console.error('RecordData Error:', error);
                setRecordData_api("false");
                setRecordData_message(" Impossible de mettre à jour les données du partenaire");
            });


    };


    const [Client_end_date_abonnement, setClient_end_date_abonnement] = useState();

    const [getCurrentUserData_api, setgetCurrentUserData_api] = useState([]);
    const [getCurrentUserData_result, setgetCurrentUserData_result] = useState([]);
    const [getCurrentUserData_message, setgetCurrentUserData_message] = useState([]);

    function getCurrentUserData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
        formData.append('token', stored_part);

        axios.post(url, formData).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In getCurrentUserData  res.data.status = " + res.data.status);
                //console.log(" In getCurrentUserData  res.data.message = " + res.data.message);

                setgetCurrentUserData_api("true");

                var mylocaltraining = JSON.parse(res.data.message);
                if (mylocaltraining) {


                    if (mylocaltraining.end_date_abonnement && String(mylocaltraining.end_date_abonnement).length > 3) {

                        setClient_end_date_abonnement(mylocaltraining.end_date_abonnement);
                    }


                    if (mylocaltraining.invoice_vat_num) {
                        setp_vat_num(mylocaltraining.invoice_vat_num);
                        document.getElementsByName("vat_num")[0].disabled = true;
                        document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_taux_vat) {
                        setp_taux_vat_value(mylocaltraining.invoice_taux_vat);
                        document.getElementsByName("taux_tva_value")[0].disabled = true;
                        document.getElementsByName("taux_tva_value")[0].style.backgroundColor = "#ECEFF1";
                    }


                    if (mylocaltraining.invoice_nom) {
                        setp_nom(mylocaltraining.invoice_nom);
                        document.getElementsByName("nom")[0].disabled = true;
                        document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_street) {
                        setp_adr_street(mylocaltraining.invoice_adr_street);
                        document.getElementsByName("adr_street")[0].disabled = true;
                        document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_city) {
                        setp_adr_city(mylocaltraining.invoice_adr_city);
                        document.getElementsByName("adr_city")[0].disabled = true;
                        document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_zip) {
                        setp_adr_zip(mylocaltraining.invoice_adr_zip);
                        document.getElementsByName("adr_zip")[0].disabled = true;
                        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_country) {
                        setp_adr_country(mylocaltraining.invoice_adr_country);
                        document.getElementsByName("adr_country")[0].disabled = true;
                        document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_email) {
                        setp_email(mylocaltraining.invoice_email);
                        document.getElementsByName("email")[0].disabled = true;
                        document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_telephone) {

                        setp_telephone(mylocaltraining.invoice_telephone);
                        document.getElementsByName("telephone")[0].disabled = true;
                        document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";
                    }


                    desablefield1();

                }
            }
            else {
                setgetCurrentUserData_api("false");
                setgetCurrentUserData_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( getCurrentUserData = ', error);
            setgetCurrentUserData_api("false");
            setgetCurrentUserData_message(" Impossible de recuperer les données du partenaire");

        })
    }

    function enablefieds1() {
        setfields1desabled(false);
        document.getElementsByName("nom")[0].disabled = false;
        document.getElementsByName("nom")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("telephone")[0].disabled = false;
        document.getElementsByName("telephone")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("email")[0].disabled = false;
        document.getElementsByName("email")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_street")[0].disabled = false;
        document.getElementsByName("adr_street")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_zip")[0].disabled = false;
        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_city")[0].disabled = false;
        document.getElementsByName("adr_city")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_country")[0].disabled = false;
        document.getElementsByName("adr_country")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("vat_num")[0].disabled = false;
        document.getElementsByName("vat_num")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("taux_tva_value")[0].disabled = false;
        document.getElementsByName("taux_tva_value")[0].style.backgroundColor = "#FFFFFF";
    }

    function desablefield1() {
        setfields1desabled(true);
        document.getElementsByName("nom")[0].disabled = true;
        document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("telephone")[0].disabled = true;
        document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("email")[0].disabled = true;
        document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_street")[0].disabled = true;
        document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_zip")[0].disabled = true;
        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_city")[0].disabled = true;
        document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_country")[0].disabled = true;
        document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("vat_num")[0].disabled = true;
        document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("taux_tva_value")[0].disabled = true;
        document.getElementsByName("taux_tva_value")[0].style.backgroundColor = "#ECEFF1";
    }

    useEffect(() => {

        getCurrentUserData();

    }, []);

    return (
        <div className="partnerinvoicingdata">
            <div className="div_row">

                <div className="titre1"> Detail de Facturation </div>
                {String(RecordData_api) === String("true") && <div className="okUpdateData"> La modification a ete prise en compte </div>}

                {String(RecordData_api) === String("false") && <div className="koUpdateData"> Impossible de modifier les informations </div>}

                &nbsp;
                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                    <TextField
                        required

                        label="Raison sociale"
                        name="nom"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        value={p_nom}
                        onChange={(e) => setp_nom(e.target.value)}

                    />

                    <TextField
                        label="num TVA"
                        name="vat_num"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        value={p_vat_num}
                        onChange={(e) => setp_vat_num(e.target.value)}

                    />

                    <TextField
                        label="Taux TVA"
                        name="taux_tva_value"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        value={p_taux_vat_value}
                        onChange={(e) => setp_taux_vat_value(e.target.value)}

                    />


                    <TextField

                        name="telephone"
                        label="Téléphone"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={p_telephone}
                        onChange={(e) => setp_telephone(e.target.value)}

                    />


                    <TextField
                        name="email"

                        label="Adresse mail"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={p_email}
                        onChange={(e) => setp_email(e.target.value)}

                    />


                    <TextField
                        name="adr_street"

                        label="Adresse"
                        className="texte_area_adress"
                        sx={{ m: 1, width: '96%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={p_adr_street}
                        onChange={(e) => setp_adr_street(e.target.value)}

                    />

                    <TextField
                        name="adr_zip"

                        label="Code Postal"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={p_adr_zip}
                        onChange={(e) => setp_adr_zip(e.target.value)}

                    />

                    <TextField
                        name="adr_city"

                        label="Ville"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={p_adr_city}
                        onChange={(e) => setp_adr_city(e.target.value)}

                    />
                    <br />
                    <TextField
                        name="adr_country"
                        label="Pays"

                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={p_adr_country}
                        onChange={(e) => setp_adr_country(e.target.value)}

                    />

                </Box>

                <div className="div_row">
                    &nbsp;
                </div>

                <div className="div_row">

                    <div className="div_row22">
                        {fields1desabled == false && <div className="div_row">
                            <div className="div_row_gauche">
                                <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
                            </div>
                            <div className="div_row_droite">
                                <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={getCurrentUserData}>Annuler</Button>
                            </div>
                        </div>

                        }
                        {fields1desabled == true &&
                            <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablefieds1}>Editer</Button>
                        }

                    </div>
                </div>
            </div>

        </div>
    );

}

export default Partner_Invoicing_Data;