import React, { useRef, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Formation from "./Formation_Cartouche_Com";
import { FaSearch, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from 'react-icons/fa';
import { BsFillXCircleFill } from "react-icons/bs";

import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";


import img_met_bureautic from "../mysy_img/met_burautic.jpg";
import img_met_vente from "../mysy_img/met_vente.jpg";


import Pagination from "./TestPagination";
import data from "./data/mock-data.json";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';
import { Helmet } from "react-helmet";

import AideRecherche from "./AideRecherche"
import { useParams } from 'react-router-dom'
import Footer from "./Fotter";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Header from "./Header";

import connect_white from "../mysy_img/connect_white_v2.png";
import connect_green from "../mysy_img/connect_green.png";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import tick_ko from "../mysy_img/tick_KO.png";


let PageSize = 10;
const Recherche_new = () => {
    const { type, categorie } = useParams();
    const { NewSearchText } = useParams();
    const [v11, setv11] = useState("");
    const [userconnected, setuserconnected] = useState("0");
    const [TrainingSupport, setTrainingSupport] = useState("");
    const [TrainingType, setTrainingType] = useState("");
    const [TrainingCertif, setTrainingCertif] = useState("");
    const [TrainingCPF, setTrainingCPF] = useState("");
    const [TrainingLang, setTrainingLang] = useState("");
    const [TrainingPrice, setTrainingPrice] = useState("");
    const [SearchText, setSearchText] = useState("");
    const [SearchcitiesText, setSearchcitiesText] = useState("");
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("false");
    const [displayAllClasses, setdisplayAllClasses] = useState("0");
    const [showelement, setshowelement] = useState("init");
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [fin, setfin] = useState(0);
    const [ismobilephone, setismobilephone] = useState();
    const [TrainingDistanciel, setTrainingDistanciel] = useState();
    const textInput = useRef(null);
    const scrollDiv = useRef(null);


    const [textplaceholder, settextplaceholder] = useState("");
    const [empltysearch, setempltysearch] = useState("1");
    const [empltycitiessearch, setempltycitiessearch] = useState("1");

    const [filtredistance, setfiltredistance] = useState("0");
    const continent = ['oui', 'non'];
    const [selectedcontinent, setselectedcontinent] = useState("");
    const [newresult, setnewresult] = useState();
    const stored_user = getCookie('tokenmysych');
    const history = useHistory();
    const [besoinaiderecherche, setbesoinaiderecherche] = useState("");
    const [istrier, setistrier] = useState();
    const [selectmetier, setselectmetier] = useState();
    const [initAllfiter, setinitAllfiter] = useState();
    const testDivRef = useRef(null);
    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);





    const url = process.env.REACT_APP_API_URL + "myclass/api/add_user_account/";
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm();
    const pwd = useRef({});
    pwd.current = watch("pwd", "");



    const onSubmit = async (data) => {

        searchFunction();
    };

    const [Trainingdistance, setTrainingdistance] = useState(0);
    const handleInput = (e) => {
        setTrainingdistance(e.target.value);
    }

    const [Trainingduration, setTrainingduration] = useState(0);
    const handleInputDuration = (e) => {
        setTrainingduration(e.target.value);
    }


    function showdisplayfilter(event) {

        if (showelement == "true") {
            setshowelement("false");
        }
        else if (showelement == "false") {
            setshowelement("true");
        }


    }

    function clearsearchtext(event) {
        setSearchText("");
        setResult([]);
        document.getElementById("SearchText").value = "";
        setempltysearch("1")
        setbesoinaiderecherche("");
    }

    const [suggestion2, setsuggestion2] = useState([]);
    const [suggestioncities, setsuggestioncities] = useState([]);

    function SearchTextHandle(event) {
        setempltysearch("0");
        setSearchText(event.target.value);

        var val = String(event.target.value).toLocaleLowerCase();
        let matches = [];
        if (String(val).length > 2) {
            matches = suggestedwords.filter(usr => {
                //alert(JSON.parse(usr).name );
                const regex = new RegExp(`${val}`, "gi");
                return JSON.parse(usr).name.match(regex);
            }

            )

        }

        //console.log("matches = " + matches);
        setsuggestion2(matches);
        settext(event.target.value);

    }

    function SearchcitiesHandle(event) {
        setempltycitiessearch("0");
        setSearchcitiesText(event.target.value);

        var val = String(event.target.value).toLocaleLowerCase().trimStart();
        const regex = new RegExp(`^${val}`, "gi");

        let matches = [];

        if (String(val).length > 2) {
            matches = suggestedcities.filter(usr => {
                return JSON.parse(usr).ville.match(regex);
            })
        }

        //console.log("matches = " + matches);
        setsuggestioncities(matches);
        setcities(event.target.value);

    }


    const [v1, setv1] = useState();
    const [v2, setv2] = useState();


    function TrainingSupportHandle(event) {
        setsupportchecked("1");
        var toto = "";
        var filtre1 = "";
        var filtre2 = "";
        var filtre3 = "";

        var crit_TrainingSupport = "";



        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value;
            if (crit_TrainingSupport.length > 0) {
                filtre1 = result.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);
                setv11(filtre1);
            }
            else if (crit_TrainingSupport.length <= 0 || String(crit_TrainingSupport) === String("NA")) {
                filtre1 = result;
                setv11(filtre1);
            }

        }

        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value;
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);

        }

        // recuperation du filtre  du type (cours, tuto, etc)
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value;
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value;
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }
        }
        // Recup du filtre de prix
        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        if (range_price.min && range_price.max) {
            filtre1 = filtre1.filter((maformation) => Number(JSON.parse(maformation).price) >= Number(range_price.min) &&
                Number(JSON.parse(maformation).price) <= Number(range_price.max)
            );

            setv11(filtre1);
        }


        setnewresult(filtre1);

        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);

    }



    function TrainingCertifHandle(event) {

        var ele = document.querySelector('input[name="TrainingCertif"]:checked').value

        var toto = "";
        var filtre1 = "";
        var filtre2 = "";
        var filtre3 = "";

        setcertifchecked("");
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            setcertifchecked("1");
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value
            if (crit_TrainingCertif.length > 0) {
                //alert(" laa = " + crit_TrainingCertif);
                //console.log(result)
                filtre1 = result.filter((maformation) => String(JSON.parse(maformation).certif) === String(crit_TrainingCertif));
                setv11(filtre1);
            }

        } else {

            filtre1 = result;
            setv11(filtre1);
        }


        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);

        }


        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
            crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }

        
        // recuperation du filtre  du type
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingType);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }

        // Recup du filtre de prix
        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        if (range_price.min && range_price.max) {
            filtre1 = filtre1.filter((maformation) => Number(JSON.parse(maformation).price) >= Number(range_price.min) &&
                Number(JSON.parse(maformation).price) <= Number(range_price.max)
            );

            setv11(filtre1);
        }


        setnewresult(filtre1);
        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);


        return;
    }



    // Pour le CPF
    const [cpfchecked, setcpfchecked] = useState();
    function TrainingCPFHandle(event) {

        var ele = document.querySelector('input[name="TrainingCPF"]:checked').value

        var toto = "";
        var filtre1 = "";
        var filtre2 = "";
        var filtre3 = "";

        setcpfchecked("");
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
            setcpfchecked("1");
            crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value
            if (crit_TrainingCPF.length > 0) {
                //alert(" crit_TrainingCPF = " + crit_TrainingCPF);
                
                filtre1 = result.filter((maformation) => String(JSON.parse(maformation).cpf) === String(crit_TrainingCPF));
                setv11(filtre1);
                //console.log(" #### ",filtre1);
            }

        } else {

            filtre1 = result;
            setv11(filtre1);
        }

        // recuperation du filtre  du certificat
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);

        }


        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);

        }


        // recuperation du filtre  du type
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingType);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }

        // Recup du filtre de prix
        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        if (range_price.min && range_price.max) {
            filtre1 = filtre1.filter((maformation) => Number(JSON.parse(maformation).price) >= Number(range_price.min) &&
                Number(JSON.parse(maformation).price) <= Number(range_price.max)
            );

            setv11(filtre1);
        }


        setnewresult(filtre1);
        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);


        return;
    }


    // END PR CPF
    function TrainingTypeHandle(event) {

        settypechecked("1");
        var toto = "";
        var filtre1 = "";
        var filtre2 = "";
        var filtre3 = "";



        var crit_TrainingPrice = "";

        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value
            if (crit_TrainingType.length > 0) {
                filtre1 = result.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);
                setv11(filtre1);
            }

        } else {

            filtre1 = result;
            setv11(filtre1);
        }

        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);

        }


        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
            crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }

        // recuperation du filtre  du certificat
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }

        // Recup du filtre de prix
        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        if (range_price.min && range_price.max) {
            filtre1 = filtre1.filter((maformation) => Number(JSON.parse(maformation).price) >= Number(range_price.min) &&
                Number(JSON.parse(maformation).price) <= Number(range_price.max)
            );

            setv11(filtre1);
        }


        setnewresult(filtre1);
        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);

        return;

        /*// recuperation du filtre  du type (cours, tuto, etc)
        if (document.getElementById("TrainingType")) {
            crit_TrainingType = document.getElementById("TrainingType").value;
            if (crit_TrainingType.length > 0) {
                filtre1 = result.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);
                setv11(filtre1);
            }
            else if (crit_TrainingType.length <= 0 || String(crit_TrainingType) === String("NA")) {
                filtre1 = result;
                setv11(filtre1);
            }
        }*/



    }


    function TrainingLangHandle(event) {
        setTrainingLang(event.target.value);
        alert("newresult newresultnewresultnewresult = " + newresult);
    }


    function TrainingPriceHandle(event) {


        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        setTrainingPrice(event.target.value);
        var toto = "";
        var filtre1 = "";


        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        var myprices = crit_TrainingPrice.split(':');
        if (range_price.min && range_price.max) {
            filtre1 = result.filter((maformation) => Number(JSON.parse(maformation).price) >= Number(range_price.min) &&
                Number(JSON.parse(maformation).price) <= Number(range_price.max)
            );
            //console.log(" PRICEEEEEEEEEEEEEEEE = "+filtre1);
            setv11(filtre1);
        }
        else {
            //alert(" Annulation du filtre prix");
            filtre1 = result;
            setv11(filtre1);
        }



        // Recuperation des autres filtres.
        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value;
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);
        }

        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
            crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }


        // recuperation du filtre  du certification
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value;
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);
        }

        // recuperation du filtre  du type (cours, tuto, etc)
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value;
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);
        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value;
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }


        setnewresult(filtre1);
        setCurrentPage2(1);

    }


    // Traitement du filtre presentiel / distantiel
    function TrainingDistancielHandle(event) {


        isonsite();
        setlieuchecked("1");
        var toto = "";
        var filtre1 = "";
        var filtre0 = [];
        var filtre01 = [];
        var my_ville = "";
        var my_cp = "";

        if (document.getElementById("ville")) {
            my_ville = crit_TrainingSupport = document.getElementById("ville").value;
            my_ville = my_ville.trim();

        }

        if (document.getElementById("zip_code")) {
            my_cp = crit_TrainingSupport = document.getElementById("zip_code").value;
            my_cp = my_cp.trim();

        }

        var crit_Trainingdistance = "";
        // Recuperation du filtre principale modifié dans ce event (distance)
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value;
            //alert("crit_Trainingdistance = " + crit_Trainingdistance);
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1

                if (String(crit_Trainingdistance) === String("1")) {

                    filtre1 = result.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = result.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");

                    // Traitement du nom de la ville
                    if (my_ville.length > 3) {

                        var filtre2 = filtre1.map(obj => {

                            if (JSON.parse(obj).datelieu) {

                                var tmp = JSON.parse(obj).datelieu.length
                                var i = 0;
                                while (i < tmp) {
                                    //console.log(JSON.parse(obj).datelieu[i]);
                                    //console.log(" ville =" + JSON.parse(obj).datelieu[i].ville)
                                    if (JSON.parse(obj).datelieu[i].ville === my_ville) {
                                        return obj
                                    }
                                    i++;
                                }
                            }

                        });

                        var filtre3 = filtre2.filter(function (element) {
                            return element !== undefined;
                        });


                        filtre1 = filtre3;
                    }

                    // Traitement du zip code
                    if (my_cp.length > 2) {

                        var filtre2 = filtre1.map(obj => {

                            if (JSON.parse(obj).datelieu) {

                                var tmp = JSON.parse(obj).datelieu.length
                                var i = 0;
                                while (i < tmp) {
                                    //console.log(JSON.parse(obj).datelieu[i]);
                                    //console.log(" ville =" + JSON.parse(obj).datelieu[i].ville)
                                    if (JSON.parse(obj).datelieu[i].code_postal.startsWith(my_cp)) {
                                        return obj
                                    }
                                    i++;
                                }
                            }

                        });

                        var filtre3 = filtre2.filter(function (element) {
                            return element !== undefined;
                        });


                        filtre1 = filtre3;
                    }

                    setv11(filtre1);


                }
                else if (String(crit_Trainingdistance) === String("NA")) {

                    filtre1 = result;
                    setv11(filtre1);
                }
            }
            else if (crit_Trainingdistance.length <= 0) {

                filtre1 = result;
                setv11(filtre1);
            }
        }


        /* Recuperation des autres filtres */
        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value;
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);
        }

        // recuperation du filtre  du certification
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value;
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);
        }

        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
            crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }


        // recuperation du filtre  du type (cours, tuto, etc)
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value;
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);
        }

        // Recup du filtre de prix
        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        if (range_price.min && range_price.max) {
            filtre1 = filtre1.filter((maformation) => Number(JSON.parse(maformation).price) >= Number(range_price.min) &&
                Number(JSON.parse(maformation).price) <= Number(range_price.max)
            );

            setv11(filtre1);
        }


        setnewresult(filtre1);
        setCurrentPage2(1);
    }


    function displayAllClassesHandle(event) {
        setdisplayAllClasses(event.target.checked);
        if (event.target.checked == true)
            document.getElementById("SearchText").disabled = true;
        else
            document.getElementById("SearchText").disabled = false;
    }


    const [suggestedwords, setsuggestedwords] = useState([]);
    const [texte, settext] = useState("");

    const [cities, setcities] = useState("");

    const [suggestedcities, setsuggestedcities] = useState([]);

    useEffect(() => {

        var ip = "";
        async function fetchData() {
            const result = await axios('https://geolocation-db.com/json/',);

            setuserIp(result.data.IPv4);
            setcountry_code(result.data.country_code);
            setcountry_name(result.data.country_name);
            setcity(result.data.city);
            setpostal(result.data.postal);
            setlatitude(result.data.latitude);
            setlongitude(result.data.longitude);
            setIPv4(result.data.IPv4);
            setstate(result.data.state);
            setuserIp(result.data.IPv4);

            let windowWidth = window.innerWidth;

            if (showelement === "init") {

                if (windowWidth < 601) {
                    setismobilephone(true);
                    setshowelement("false");
                    settextplaceholder("Plus de 10 000 formations (gratuites, payantes...)");

                } else if (windowWidth >= 601) {
                    setismobilephone(false);
                    setshowelement("true");
                    //settextplaceholder("Ex : graphiste     ...sur plus de 10 000 formations (gratuites, payantes,..)");
                    settextplaceholder("Recherche par Titre, Description, Contenu video    ...     sur plus de 10 000 formations (gratuites, payantes,..)");
                }
            }


            if (categorie && String(categorie).length > 0) {
                getClassByMetier_categorie(categorie);
            }
            else {
                DisplayXfirstClas(result.data);
            }
        }
        fetchData();
        async function loadSuggestedWord() {
            var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Suggested_Word/";
            //alert(" URL = "+process.env.REACT_APP_API_URL + "myclass/api/Get_Suggested_Word/");
            //console.log("  ### URL ##### = "+process.env.REACT_APP_API_URL + "myclass/api/Get_Suggested_Word/");
            
            var form = new FormData();

            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Get_Suggested_Word/",
                {
                    method: 'POST',

                }
            ).then((response) => response.json())
                .then((result) => {
                    //console.log('Success:', result['message']);
                    setsuggestedwords(result['message']);


                })
                .catch((error) => {
                    console.error('Error:');
                });

        }
        loadSuggestedWord();

        async function loadSuggestedFrCities() {

            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Get_Suggested_Fr_Cities/",
                {
                    method: 'POST',

                }
            ).then((response) => response.json())
                .then((result) => {
                    //console.log(' loadSuggestedFrCities CITIES = Success:', result['message']);
                    setsuggestedcities(result['message']);
                    //console.log(" CITIES = " + result['message']);

                })
                .catch((error) => {
                    console.error('Error:');
                });

        }
        loadSuggestedFrCities();


        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                searchFunction();
            }
        };
        document.addEventListener("keydown", listener);


        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
        } else {
            setuserconnected("1");
        }

        setinitAllfiter();

        whosisconnected();

    }, [initAllfiter, categorie]);


    // Cette fonction reinitialise les filtres
    function InitAllfilters() {
        //document.getElementById("TrainingCertif").selectedv = 0;
        const $select = document.querySelector('#TrainingCertif');
        $select.value = ''

        const $select2 = document.querySelector('#TrainingSupport');
        $select2.value = ''

        const $select3 = document.querySelector('#TrainingType');
        $select3.value = ''

        const $select4 = document.querySelector('#TrainingPresentiel');
        $select4.value = 'NA'

        const $select5 = document.querySelector('#TrainingPrice');
        $select5.value = 'NA'



    }



    const currentTableData2 = useMemo(() => {
        if (newresult) {
            const firstPageIndex = (currentPage2 - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            setfin(1);
            return newresult.slice(firstPageIndex, lastPageIndex);
        }


        if (result) {
            const firstPageIndex = (currentPage2 - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            setfin(1);
            return result.slice(firstPageIndex, lastPageIndex);
        }
        else {
            return [];
            setfin(0);
        }
    }, [currentPage2]);

    function searchFunction(e) {
        // Nettoyage du resultat precedent.
        setResult([]);

        var crit_TrainingSupport = "";
        var crit_TrainingType = "";
        var crit_TrainingCertif = "";
        var crit_TrainingLang = "";
        var crit_TrainingPresentiel = "";
        var crit_TrainingPrice = "";
        var crit_TrainingDuration = "";
        var my_ville = "";

        var my_cp = ""






        //Get Criterias
        var crit_SearchText = document.getElementById("SearchText").value;

        if (document.getElementById("TrainingSupport"))
            crit_TrainingSupport = document.getElementById("TrainingSupport").value;

        if (document.getElementById("TrainingType"))
            crit_TrainingType = document.getElementById("TrainingType").value;

        if (document.getElementById("TrainingCertif"))
            crit_TrainingCertif = document.getElementById("TrainingCertif").value;
        if (crit_TrainingCertif.length == 0)
            crit_TrainingCertif = "NA";


        if (document.getElementById("TrainingLang"))
            crit_TrainingLang = document.getElementById("TrainingLang").value;

        if (document.getElementById("TrainingPresentiel"))
            crit_TrainingPresentiel = document.getElementById("TrainingPresentiel").value;

        if (document.getElementById("TrainingPrice"))
            crit_TrainingPrice = document.getElementById("TrainingPrice").value;

        if (document.getElementById("duration"))
            crit_TrainingDuration = document.getElementById("duration").value;


        if (document.getElementById("ville")) {
            my_ville = crit_TrainingSupport = document.getElementById("ville").value;

        }

        if (document.getElementById("zip_code")) {
            my_cp = crit_TrainingSupport = document.getElementById("zip_code").value;

        }


        var crit_Trainingdistance = Trainingdistance;

        /*console.log('SearchText = ' + crit_SearchText + ' - TrainingSupport = ' + crit_TrainingSupport +
            ' - TrainingType = ' + crit_TrainingType + ' - TrainingCertif ' + crit_TrainingCertif +
            " Langue = " + crit_TrainingLang + " TrainingPrice = " + crit_TrainingPrice +
            " - Distance = " + crit_Trainingdistance + " -- Presentiel :" + crit_TrainingPresentiel +
            " - Duration = " + crit_TrainingDuration + " - stored_user_cookie = " + stored_user); */


        var form = new FormData();
        form.append("search_text", crit_SearchText);
        form.append("token", stored_user);
        form.append("certif", crit_TrainingCertif);
        form.append("support", crit_TrainingSupport);
        form.append("type", crit_TrainingType);
        form.append("lang", crit_TrainingLang);
        form.append("price", crit_TrainingPrice);
        form.append("distance", crit_Trainingdistance);
        form.append("duration", crit_TrainingDuration);


        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = ""
        if (displayAllClasses == true) {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_class/";
        }
        else {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_text_simple/";
        }




        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message)

                //Verification si le resutlat est vide
                if (res.data.message.length <= 0) {
                    setbesoinaiderecherche("1");
                }
                if (scrollDiv.current) {
                    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
                }
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
                //textInput.current.focus();
            }


        }).catch((error) => {
            // console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })

    }

    // Cette fonction affiche les X premieres formations
    function DisplayXfirstClas(ip) {

        // Nettoyage du resultat precedent.
        setResult([]);

        //Get Criterias
        var crit_SearchText = "";
        var myurl = "";

        if (typeof (NewSearchText) === "undefined" || String(NewSearchText) === '') {
            crit_SearchText = "";
            myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_class/";
        } else {
            clearsearchtext();
            crit_SearchText = NewSearchText;
            myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_text_simple/";
        }

        //alert(" crit_SearchText = " + crit_SearchText+" myurl = "+myurl);



        var form = new FormData();
        form.append("search_text", crit_SearchText);
        form.append("token", "");
        form.append("certif", "");
        form.append("support", "");
        form.append("type", "");
        form.append("lang", "");
        form.append("price", "");
        form.append("distance", "");
        form.append("duration", "");
        form.append("cpf", "");


        // Ajout des info IP user
        form.append("user_ip", ip.IPv4);
        form.append("user_country_code", ip.country_code);
        form.append("user_country_name", ip.country_name);
        form.append("user_city", ip.city);
        form.append("user_postal", ip.postal);
        form.append("user_latitude", ip.latitude);
        form.append("user_longitude", ip.longitude);
        form.append("user_state", ip.state);


        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In DisplayXfirstClas  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message)
                // textInput.current.focus();
                setbesoinaiderecherche("");

            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
                //textInput.current.focus();
            }


        }).catch((error) => {
            // console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })


    }

    function trier(e) {

        setistrier();
        var trierpar = ""
        if (document.getElementById("trierpar"))
            trierpar = document.getElementById("trierpar").value;

        if (String(trierpar) === String("duration")) {
            setCurrentPage2(1);
            setCurrentPage(1);

            if (typeof (newresult) === "undefined" || String(newresult) === '') {

                //console.log(" AVANT TRIE : [0].duration = " + JSON.parse(result[0]).duration + " - [1].duration = " + JSON.parse(result[1]).duration)
                result.sort(function (a, b) {
                    return JSON.parse(a).duration - JSON.parse(b).duration;
                });

                //console.log("APRES TRIE :  [0].duration = " + JSON.parse(result[0]).duration + " - [1].duration = " + JSON.parse(result[1]).duration)
                setistrier("1");
            }
            else if (typeof (newresult) !== "undefined" || newresult.length > 0) {
                newresult.sort(function (a, b) {
                    return JSON.parse(a).duration - JSON.parse(b).duration;
                });
            }
        }

        else if (String(trierpar) === String("price")) {
            setCurrentPage2(1);

            if (typeof (newresult) === "undefined" || String(newresult) === '') {
                //console.log(" AVANT TRIE : [0].price = " + JSON.parse(result[0]).price + " - [1].price = " + JSON.parse(result[1]).price);
                result.sort(function (a, b) {
                    return JSON.parse(a).price - JSON.parse(b).price;
                });
                //console.log("APRES TRIE :  [0].price = " + JSON.parse(result[0]).price + " - [1].price = " + JSON.parse(result[1]).price);
                setistrier("2");
            }
            else if (typeof (newresult) !== "undefined" || newresult.length > 0) {
                newresult.sort(function (a, b) {
                    return JSON.parse(a).price - JSON.parse(b).price;
                });
            }
        }

        else if (String(trierpar) === String("note")) {
            setCurrentPage2(1);

            if (typeof (newresult) === "undefined" || String(newresult) === '') {
                //console.log(" AVANT TRIE : [0].price = " + JSON.parse(result[0]).price + " - [1].price = " + JSON.parse(result[1]).price);
                result.sort(function (a, b) {
                    return JSON.parse(b).note - JSON.parse(a).note;
                });
                //console.log("APRES TRIE :  [0].price = " + JSON.parse(result[0]).price + " - [1].price = " + JSON.parse(result[1]).price);
                setistrier("2");
            }
            else if (typeof (newresult) !== "undefined" || newresult.length > 0) {
                newresult.sort(function (a, b) {
                    return JSON.parse(b).note - JSON.parse(a).note;
                });
            }
        }

    }


    function getClassByMetier_categorie(mycate) {

        var mymetier = mycate;
        /*
                InitAllfilters();
        
                if (String(mymetier) === String(selectmetier)) {
                    //alert(" il faut faire un desactivation");
                    document.getElementById(mymetier).style.opacity = "1";
                    document.getElementById(mymetier).style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)";
                    setinitAllfiter("1");
                    return;
                }
        
        
                if (selectmetier && selectmetier !== "tout") {
                    document.getElementById(selectmetier).style.opacity = "1";
                    document.getElementById(selectmetier).style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)";
                }
        
        
                setselectmetier(mymetier);
        
        
                if (mymetier && mymetier !== "tout") {
                    document.getElementById(mymetier).style.opacity = "0.5";
                    document.getElementById(mymetier).style.boxShadow = "10px 20px 30px blue";
        
                }
        */

        // Nettoyage du resultat precedent.
        setResult([]);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_class_by_metier/";

        /*if (mymetier === "tout") {
            //alert(" tout et sortir");
            setinitAllfiter("1");
            return;

        }*/

        var form = new FormData();
        form.append("metier", mymetier);
        form.append("token", "");

        // Ajout des info IP user
        /* form.append("user_ip", ip.IPv4);
         form.append("user_country_code", ip.country_code);
         form.append("user_country_name", ip.country_name);
         form.append("user_city", ip.city);
         form.append("user_postal", ip.postal);
         form.append("user_latitude", ip.latitude);
         form.append("user_longitude", ip.longitude);
         form.append("user_state", ip.state);
         */
        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {

                setmyApiResponse("True");
                setResult(res.data.message)
                //textInput.current.focus();
                setbesoinaiderecherche("");
                setnewresult("");
                //console.log(" In getClassByMetier  res.data.status = " + res.data.status);
                //console.log(" In getClassByMetier  res.data.message = " + res.data.message);
                //setnewresult(res.data.message);
                setCurrentPage2(1);
                if (scrollDiv.current) {
                    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
                }

                // reinitialisation de tous les filtre

                //setmyApimyApiMessage("OK")
                //console.log("taille = "+res.data.message.length);
            }
            else {
                //console.log(" In getClassByMetier  res.data.status = " + res.data.status);
                //console.log(" In getClassByMetier  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
                //textInput.current.focus();
            }


        }).catch((error) => {
            console.warn(' getClassByMetier : Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })

    }



    function getClassByMetier(e) {

        var mymetier = "";
        mymetier = e.target.id
        /*
                InitAllfilters();
        
                if (String(mymetier) === String(selectmetier)) {
                    //alert(" il faut faire un desactivation");
                    document.getElementById(mymetier).style.opacity = "1";
                    document.getElementById(mymetier).style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)";
                    setinitAllfiter("1");
                    return;
                }
        
        
                if (selectmetier && selectmetier !== "tout") {
                    document.getElementById(selectmetier).style.opacity = "1";
                    document.getElementById(selectmetier).style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)";
                }
        
        
                setselectmetier(mymetier);
        
        
                if (mymetier && mymetier !== "tout") {
                    document.getElementById(mymetier).style.opacity = "0.5";
                    document.getElementById(mymetier).style.boxShadow = "10px 20px 30px blue";
        
                }
        */

        // Nettoyage du resultat precedent.
        setResult([]);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_class_by_metier/";

        /*if (mymetier === "tout") {
            //alert(" tout et sortir");
            setinitAllfiter("1");
            return;

        }*/

        var form = new FormData();
        form.append("metier", mymetier);
        form.append("token", "");

        // Ajout des info IP user
        /* form.append("user_ip", ip.IPv4);
         form.append("user_country_code", ip.country_code);
         form.append("user_country_name", ip.country_name);
         form.append("user_city", ip.city);
         form.append("user_postal", ip.postal);
         form.append("user_latitude", ip.latitude);
         form.append("user_longitude", ip.longitude);
         form.append("user_state", ip.state);
         */
        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {

                setmyApiResponse("True");
                setResult(res.data.message)
                //textInput.current.focus();
                setbesoinaiderecherche("");
                setnewresult("");
                //console.log(" In getClassByMetier  res.data.status = " + res.data.status);
                //console.log(" In getClassByMetier  res.data.message = " + res.data.message);
                //setnewresult(res.data.message);
                setCurrentPage2(1);
                if (scrollDiv.current) {
                    scrollDiv.current.scrollIntoView({ behavior: "smooth" });
                }

                // reinitialisation de tous les filtre

                //setmyApimyApiMessage("OK")
                //console.log("taille = "+res.data.message.length);
            }
            else {
                //console.log(" In getClassByMetier  res.data.status = " + res.data.status);
                //console.log(" In getClassByMetier  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
                //textInput.current.focus();
            }


        }).catch((error) => {
            console.warn(' getClassByMetier : Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })

    }


    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);



    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };


    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };




    const handleClose_Account = () => {
        //alert(" my account");
        setAccountMenu(null);
    };

    const handleBlogMenu = (event) => {
        setproduitMenu(event.currentTarget);
        history.push("/Recherche-Article-formation")
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };


    const handleMyPartnerAccount = () => {
        setAccountMenu(null);
        history.push("/Partner")
    };


    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/Produits-Services/")
    };

    const handleAvis = () => {
        setavisMenu(null);
        history.push("/Recherche-Article-formation")
    };

    function test() {
        alert(" coucou");
    }


    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);

    const stored_part = getCookie('tokenmysypart');

    function whosisconnected() {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            // alert(" partner connected OK");
        }
    }

    function handleAccountLogout() {

        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }




    function handleAccountConnexion_v2() {

        if (String(userconnected) === String("1")) {
            handleMyAccount();
        }
        else if (String(partnerconnected) === String("1")) {
            handleMyPartnerAccount();
        }
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_part) != "undefined") {
            setpartCookie("tokenmysypart", "", { path: '/' });
        }
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }

    const [range_price, setrange_price] = useState({ min: 0, max: 9999, });
    function rangevalue() {
        alert(range_price.max + "   -   " + range_price.min);
        //console.log(" range = " + range_price);
    }

    const [trainingonsite, settrainingonsite] = useState()
    function isonsite() {
        settrainingonsite(document.querySelector('input[name="TrainingPresentiel"]:checked').value)

    }

    const [certifchecked, setcertifchecked] = useState();
    function annulefiltrecertif() {
        var radio = document.querySelector('input[name="TrainingCertif"]:checked');
        radio.checked = false;
        setcertifchecked("");

        var toto = "";
        var filtre1 = "";


        // Reinitialisation
        filtre1 = result;
        setv11(filtre1);


        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);

        }

        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
           crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value;
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }

        // recuperation du filtre  du type
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingType);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }

        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        setnewresult(filtre1);
        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);


        return;


    }


    function annulefiltrecpf() {
        var radio = document.querySelector('input[name="TrainingCPF"]:checked');
        radio.checked = false;
        setcpfchecked("");

        var toto = "";
        var filtre1 = "";


        // Reinitialisation
        filtre1 = result;
        setv11(filtre1);

         // recuperation du filtre  de la certification
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value;
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);

        }

        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);

        }


        // recuperation du filtre  du type
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingType);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }

        var crit_TrainingPrice = range_price.min + ":" + range_price.max;

        setnewresult(filtre1);
        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);


        return;


    }

    const [supportchecked, setsupportchecked] = useState();
    function annulefiltresupportchecked() {
        var radio = document.querySelector('input[name="TrainingSupport"]:checked');
        radio.checked = false;
        setsupportchecked("");
        var crit_TrainingSupport = "";
        var toto = "";
        var filtre1 = "";



        // Reinitialisation
        filtre1 = result;
        setv11(filtre1);

        // recuperation du filtre  de la certification
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value;
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);

        }


         // recuperation du filtre  du CPF
         var crit_TrainingCPF = "";
         if (document.querySelector('input[name="TrainingCPF"]:checked')) {
            crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value;
             if (crit_TrainingCPF.length > 0)
                 filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);
 
         }
 

        // recuperation du filtre  du type (cours, tuto, etc)
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value;
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);

        }

        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value;
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }
        }
        // Recup du filtre de prix
        var crit_TrainingPrice = "";

        setnewresult(filtre1);

        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);
    }


    const [typechecked, settypechecked] = useState();
    function annulefiltretypechecked() {
        var radio = document.querySelector('input[name="TrainingType"]:checked');
        radio.checked = false;
        settypechecked("");

        var toto = "";
        var filtre1 = "";
        var filtre2 = "";
        var filtre3 = "";



        // Reinitialisation 
        filtre1 = result;
        setv11(filtre1);
        //alert("rinitialisation")
        ;

        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);

        }

        // recuperation du filtre  du certificat
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);

        }

        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
           crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value;
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }


        // recuperation du filtre de la distance
        var crit_Trainingdistance = "";
        if (document.querySelector('input[name="TrainingPresentiel"]:checked')) {
            crit_Trainingdistance = document.querySelector('input[name="TrainingPresentiel"]:checked').value
            if (crit_Trainingdistance.length > 0) {
                // filtre presentiel = 1
                if (String(crit_Trainingdistance) === String("1")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.distantiel === "1");
                    setv11(filtre1);

                }
                else if (String(crit_Trainingdistance) === String("0")) {
                    filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).presentiel.presentiel === "1");
                    setv11(filtre1);
                }
            }

        }


        setnewresult(filtre1);
        //alert("crit_TrainingCertif = " + crit_TrainingCertif + " ET  crit_TrainingSupport = " + crit_TrainingSupport + "  ET crit_TrainingType = " + crit_TrainingType);
        //console.log("############################  filtre1 = " + filtre1);
        setCurrentPage2(1);
        //console.log(" ????????????????????????????filtre2 = "+filtre2);

        return;

    }


    const [lieuchecked, setlieuchecked] = useState();
    function annulefiltrelieuchecked() {
        var radio = document.querySelector('input[name="TrainingPresentiel"]:checked');
        radio.checked = false;
        setlieuchecked("");
        var toto = "";
        var filtre1 = "";
        settrainingonsite('');



        //reinitialisation des filtres
        filtre1 = result;
        setv11(filtre1);

        // Annulation de la suggestion de villes
        setsuggestioncities([]);


        /* Recuperation des autres filtres */
        // recuperation du filtre  du support
        var crit_TrainingSupport = "";
        if (document.querySelector('input[name="TrainingSupport"]:checked')) {
            crit_TrainingSupport = document.querySelector('input[name="TrainingSupport"]:checked').value;
            if (crit_TrainingSupport.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).support === crit_TrainingSupport);
        }

        // recuperation du filtre  du certification
        var crit_TrainingCertif = "";
        if (document.querySelector('input[name="TrainingCertif"]:checked')) {
            crit_TrainingCertif = document.querySelector('input[name="TrainingCertif"]:checked').value;
            if (crit_TrainingCertif.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).certif === crit_TrainingCertif);
        }

        // recuperation du filtre  du CPF
        var crit_TrainingCPF = "";
        if (document.querySelector('input[name="TrainingCPF"]:checked')) {
           crit_TrainingCPF = document.querySelector('input[name="TrainingCPF"]:checked').value;
            if (crit_TrainingCPF.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).cpf === crit_TrainingCPF);

        }

        // recuperation du filtre  du type (cours, tuto, etc)
        var crit_TrainingType = "";
        if (document.querySelector('input[name="TrainingType"]:checked')) {
            crit_TrainingType = document.querySelector('input[name="TrainingType"]:checked').value;
            if (crit_TrainingType.length > 0)
                filtre1 = filtre1.filter((maformation) => JSON.parse(maformation).type === crit_TrainingType);
        }
        setnewresult(filtre1);
        setCurrentPage2(1);
    }

    function OnblurHandle(e) {
        setTimeout(() => {
            setsuggestion2([])
        }, 100)
    }


    function OnSuggestionHandler(text) {
        var val = text;
        settext(val);
    }

    function OnCitiesSuggestionHandler(text) {
        var val = text;
        setcities(val);
        document.getElementById("ville").value = val;
        LeaveCity();
         // Annulation de la suggestion de villes
         setsuggestioncities([]);
    }

    function publiecatalogue() {

        history.push("/Partner", { mysy_arg: 'partner' });
    }

    function LeaveCity() {
        TrainingDistancielHandle();
    }

    function LeaveZipCode() {
        TrainingDistancielHandle();
    }

    return (
        <div className="reherche_new_cartouche_com" >
            <Helmet>
                <title>Formation & Tutoriel Gratuit ou payant Excellente qualité</title>
                <meta name="description" content="Trouver des Formations et Tutoriels d'xcellente qualité : Gratuit ou payant / A distance ou en presentiel, etc." />
                <meta name="robots" content="index,follow" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/recherche-formation"} />
                <meta name="title" content="Formation & Tutoriel Gratuit ou payant Excellente qualité" />
            </Helmet>

            <Menu
                keepMounted
                anchorEl={PartnerMenu}
                onClose={PartnerMenu_Close}
                open={Boolean(PartnerMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handlePartnerConnexion} className="mymenu_item"
                >Mon Compte</MenuItem>
                <MenuItem onClick={PartnerMenu_Close} className="mymenu_item"
                >Deconnexion</MenuItem>

            </Menu>

            <Menu
                keepMounted
                anchorEl={AccountMenu}
                onClose={AccountMenu_Close}
                open={Boolean(AccountMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handleMyAccount} className="mymenu_item"
                >Mon Compte</MenuItem>
                <MenuItem onClick={handleAccountConnexion} className="mymenu_item"
                >Connexion</MenuItem>

                <MenuItem onClick={handleAccountCreation} className="mymenu_item"
                >S'inscrire</MenuItem>
                <MenuItem onClick={AccountMenu_Close} className="mymenu_item"
                >Deconnexion</MenuItem>
            </Menu>

            <Menu
                keepMounted
                anchorEl={ProduitMenu}
                onClose={ProduitMenu_Close}
                open={Boolean(ProduitMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleProduitService} className="mymenu_item">
                    <button className='bton_menu'>Nos produits & Services </button></MenuItem>

                {/*<MenuItem onClick={handleAvis} className="mymenu_item">
                    <button className='bton_menu'>Blog </button>

    </MenuItem>*/}

            </Menu>



            <div className="div_row2">
                <div className="div_row_bas">
                    <div className="div_001">
                        <div className="div_002">
                            <a href={process.env.REACT_APP_BASE_URL}> <img class="img-responsive img_logo" src={logowhite} alt="MySy Training Technology" /> </a>
                        </div>
                        <div className="div_003">
                            <h1 className="h1_transparent">Recherche Formations  Tutoriels</h1>

                            <div className="menuPrincipal_bton" onClick={handleAccountConnexion}>
                                <div style={{ "textAlign": "left" }}>
                                    {String(someoneconnected) !== String("1") && <button className="buton_ch22" onClick={handleAccountConnexion}> <img src={connect_white}
                                        style={{ "width": "25%" }} /> Connexion
                                    </button>}
                                    {String(someoneconnected) === String("1") && <button className="buton_ch22" onClick={handleAccountLogout}> Deconnexion<img src={connect_green}
                                        style={{ "width": "25%" }} />
                                    </button>}
                                </div>
                            </div>


                            {String(someoneconnected) === String("1") && <div className="menuPrincipal" onClick={handleAccountConnexion_v2}>
                                Mon Compte
                            </div>}

                            {String(someoneconnected) !== String("1") && <div className="menuPrincipal" onClick={handleAccountCreation}>
                                S'inscrire
                            </div>}

                            <div className="menuPrincipal" onClick={handleProduitMenu}>
                                Nos Services
                            </div>

                           {/* <div className="menuPrincipal" onClick={handleBlogMenu}>
                                Le blog
                            </div> */}

                            <div className="menuPrincipal" onClick={publiecatalogue}
                                style={{ "background": "red", "borderRadius": "5px", "paddingLeft": "3px", "paddingRight": "3px" }}>
                                publier mes formations
                            </div>

                        </div>


                    </div>


                    <div className="text_head">
                        <hr className="div_004" />
                        <div className="middle">

                            <div className="bienvenu">    Bienvenue sur MySy </div>
                            <font className="message_hili">Recherche </font> de Formations & Tutoriels

                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} className="div_005">
                            <div class="form" style={{ "textAlign": "left" }}>

                                <i class="fa fa-search"></i>

                                <input
                                    type="text"
                                    class="form-control form-input"
                                    placeholder={textplaceholder}
                                    onChange={SearchTextHandle}
                                    id={"SearchText"}
                                    name={"SearchText"}
                                    value={texte}
                                    onBlur={OnblurHandle}

                                />
                                {SearchText.length > 2 && <span_clear> <BsFillXCircleFill onClick={clearsearchtext} /> </span_clear>} <span class="left-pan" >  &nbsp;<FaSearch onClick={searchFunction} /></span>
                                {suggestion2 && suggestion2.slice(0, 5).map((suggestion, id) =>
                                    <div key={id} style={{
                                        "cursor": "pointer", "color": "white",
                                        "fontSize": "small", "paddingLeft": "1rem", "fontStyle": "italic", "letterSpacing": "2px"
                                    }}
                                        onClick={() => OnSuggestionHandler(JSON.parse(suggestion).name)}> {JSON.parse(suggestion).name}</div>
                                )}

                            </div>
                        </form>
                        <div className="met_polaroid" >
                            <button className="img_categorie_v2" style={{
                                "background": "#81BC3A", "borderColor": "#81BC3A",
                            }}
                                alt="Formation Programmation" id="IT" name="programmation2" onClick={getClassByMetier} >

                                <p className="met_text" id="IT">Informatique</p>

                            </button>
                            <button className="img_categorie_v2" style={{ "background": "#DAF7A6", "borderColor": "#DAF7A6" }}
                                alt="Formation graphisme" id="graphisme" onClick={getClassByMetier}>
                                <p className="met_text" id="graphisme">Graphisme</p>
                            </button>

                            <button className="img_categorie_v2" style={{ "background": "#FFC300", "borderColor": "#FFC300" }}
                                alt="Formation gestion projets" id="projets" onClick={getClassByMetier}>
                                <p className="met_text" id="projets">Projets</p>
                            </button>



                            <button className="img_categorie_v2" style={{
                                "background": "#FF5733", "borderColor": "#FF5733",
                                "color": "white"
                            }}
                                alt="Formation Management" id="management" onClick={getClassByMetier}>
                                <p className="met_text" id="management">Management</p>
                            </button>
                            <button className="img_categorie_v2" style={{
                                "background": "#900C3F", "borderColor": "#900C3F",
                                "color": "white"
                            }}
                                alt="Formation Digitale" id="digital" onClick={getClassByMetier}>
                                <p className="met_text" id="digital">Digital</p></button>
                        </div>


                        <div className="met_polaroid_line2" >
                            <button className="img_categorie_v2" style={{
                                "background": "#81BC3A", "borderColor": "#81BC3A",
                            }}
                                alt="Ressources Humaines" id="RH" onClick={getClassByMetier}>
                                <p className="met_text" id="RH">RH</p></button>

                            <button className="img_categorie_v2" style={{ "background": "#DAF7A6", "borderColor": "#DAF7A6" }}
                                alt="Marketing" id="marketing" onClick={getClassByMetier}>
                                <p className="met_text" id="marketing">Marketing</p></button>

                            <button className="img_categorie_v2" style={{ "background": "#FFC300", "borderColor": "#FFC300" }}
                                alt="Bureautique" id="Office" onClick={getClassByMetier}>
                                <p className="met_text" id="Office">Bureautique</p></button>

                            <button className="img_categorie_v2" style={{
                                "background": "#FF5733", "borderColor": "#FF5733",
                                "color": "white"
                            }}
                                alt="Commerce" id="vente" onClick={getClassByMetier}>
                                <p className="met_text" id="vente">Commerce</p></button>


                            <button className="img_categorie_v2" style={{
                                "background": "#900C3F", "borderColor": "#900C3F",
                                "color": "white"
                            }}
                                alt="Autres" id="dev_perso" onClick={getClassByMetier}>
                                <p className="met_text" id="dev_perso">Dev. Personnel</p></button>


                        </div>


                    </div>
                </div>
            </div>


            <div className="div_mobile">
                <div style={{"width":"100%"}}>
                  

                    <div className="rech_middlebox">

                        {String(userconnected) === String("1") && <div>
                            <label className="default_font">
                                <i>Afficher toutes les formations :</i>  &nbsp;&nbsp;
                                <input type="checkbox" onChange={displayAllClassesHandle} />
                            </label>
                        </div>
                        }

                        {myApiResponse == "False" && <div className="koUpdateData">

                            Impossible de contacter l'hote distant
                        </div>}

                        {myApiResponse == "True" && String(besoinaiderecherche) === String("1") && <div className="okUpdateData">

                            Le resultat est vide, besoin d'aide ?
                            <AideRecherche />

                        </div>}


                        {result && myApiResponse === "True" && !newresult &&
                            <div className="okUpdateData">
                                <div style={{ "width": "100%" }}>
                                    <div style={{ "width": "50%", "float": "left" }}>
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage2}
                                            totalCount={result.length}
                                            pageSize={PageSize}
                                            onPageChange={page => setCurrentPage2(page)}
                                        />
                                    </div>
                                    <div style={{ "width": "40%", "float": "right" }}>
                                        <div clmenuassName="input-group input-group-sm mb-3 ">
                                            <span className="filter_span">Trier par </span>

                                            <select className="filter_val"
                                                id={"trierpar"} name={"trierpar"}
                                                onChange={trier}>
                                                <option selected value="price">Prix</option>

                                                <option value="duration">Duree</option>
                                                <option value="note">Pertinence</option>
                                            </select>
                                        </div>
                                    </div>


                                </div>


                            </div>}

                        {result && myApiResponse === "True" && newresult &&
                            <div className="okUpdateData">
                                <Pagination
                                    className="pagination-bar2" style="padding: 0;"
                                    currentPage={currentPage2}
                                    totalCount={newresult.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage2(page)}
                                />


                            </div>}



                        {result && myApiResponse == "True" && fin == 0 && !newresult &&
                            result.slice(0, PageSize).map((formation) => (
                                <div className="rech_list_formation2" >

                                    <Formation formation={JSON.parse(formation)} key={JSON.parse(formation).title} coeur={JSON.parse(formation).title} />
                                </div>

                            ))}

                        {result && myApiResponse == "True" && fin == 0 && newresult &&
                            newresult.slice(0, PageSize).map((formation) => (
                                <div className="rech_list_formation2">

                                    <Formation formation={JSON.parse(formation)} key={JSON.parse(formation).title} />
                                </div>

                            ))}



                        {result && myApiResponse === "True" && fin === 1 && !newresult
                            && currentTableData2
                            && currentTableData2.map((formation) => (
                                <div className="rech_list_formation2">

                                    <Formation formation={JSON.parse(formation)} key={JSON.parse(formation).title} />
                                </div>

                            ))}

                        {result && myApiResponse === "True" && fin === 1 && newresult
                            && currentTableData2
                            && currentTableData2.map((formation) => (
                                <div className="rech_list_formation2">

                                    <Formation formation={JSON.parse(formation)} key={JSON.parse(formation).title} />
                                </div>

                            ))}


                        {result && !newresult && <div>

                            {String(userconnected) === String("1") && <label className="default_font">
                                <i>Afficher toutes les formations :</i>  &nbsp;&nbsp;
                                <input type="checkbox" onChange={displayAllClassesHandle} />
                            </label>}
                            <Pagination
                                className="pagination-bar2" style={{ 'padding': "0px" }}
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />
                        </div>
                        }

                        {result && newresult && <div>

                            {String(userconnected) === String("1") && <label className="default_font">
                                <i>Afficher toutes les formations :</i>  &nbsp;&nbsp;
                                <input type="checkbox" onChange={displayAllClassesHandle} />
                            </label>}
                            <Pagination
                                className="pagination-bar3" style="padding: 0;"
                                currentPage={currentPage2}
                                totalCount={newresult.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />
                        </div>
                        }
                    </div>


          

                    <div className="pieddepage">
                        <Footer />
                    </div>

                    <br />

                </div>

            </div>


        </div >

    );
}


export default Recherche_new;