import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { getCookie, setCookie } from 'react-use-cookie';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { Button, Form, FormGroup, Label } from "reactstrap";
import PasswordIcon from '@mui/icons-material/Password'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useHistory } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import { Redirect } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react';
import SendIcon from '@mui/icons-material/Send';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import Rating from '@mui/material/Rating';
import parse from 'html-react-parser'



function Inscription_Information(props) {


    const [action, setaction] = useState(props.action);
    const [class_internal_url, setclass_internal_url] = useState(props.internal_url);
    ;

    const modefinancement = [
        {
            value: 'cpf',
            label: 'CPF',
        },
        {
            value: 'perso',
            label: 'Fonds Propres',
        },
        {
            value: 'entreprise',
            label: 'Votre entreprise',
        },

        {
            value: 'mixte_cpf_perso',
            label: 'CPF & Fonds Propres',
        },
        {
            value: 'autre',
            label: 'Autre',
        },

    ];


    const opco = [
        {
            value: 'opco Paris',
            label: 'paris',
        },
        {
            value: 'opco Nanterre',
            label: 'Nanterre',
        },
        {
            value: 'inconnu',
            label: 'inconnu',
        },
    ];

    const [myopco, setmyopco] = useState("");
    function handleChangeOpco(event) {
        setmyopco(event.target.value);
    };


    const [mysession, setmysession] = useState("");
    const [monfinancement, setmonfinancement] = useState("");

    function handleChangeSession(event) {
        setmysession(event.target.value);
    };

    function handleChangeFinancement(event) {
        setmonfinancement(event.target.value);
    };


    const [RecordData_api, setRecordData_api] = useState("");
    const [RecordData_status, setRecordData_status] = useState("");
    const [RecordData_message, setRecordData_message] = useState("");

    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass/";

        var nom = document.getElementsByName("nom")[0].value;
        if (String(nom).trim().length <= 0) {
            document.getElementsByName("nom")[0].style.borderColor = "red";
            alert(" Le nom d'utilisateur est obligatoire 3");
            return;
        }

        var prenom = document.getElementsByName("prenom")[0].value;
        if (String(prenom).trim().length <= 0) {
            document.getElementsByName("telephone")[0].style.borderColor = "red";
            alert(" Le prenom d'utilisateur est obligatoire ");
            return;
        }


        var telephone = document.getElementsByName("telephone")[0].value;
        if (String(telephone).trim().length < 10) {
            document.getElementsByName("telephone")[0].style.borderColor = "red";
            alert(" Le numero de telephone doit comporter 10 chiffres ");
            return;
        }

        var email = document.getElementsByName("email")[0].value;
        var re2 = /\S+@\S+\.\S+/;
        if (re2.test(email) === false) {
            alert("L'email  est incorrect ");
            document.getElementsByName("email")[0].style.borderColor = "red";
            return;
        }



        var modefinancement = document.getElementById('modefinancement').value;
        if (String(modefinancement).trim().length <= 0) {
            alert("Vous devez choisir une mode de financement  ");
            return;
        }

        var opco = "inconnu"
        if (document.getElementById('opco') && document.getElementById('opco').value) {
            opco = document.getElementById('opco').value;
            if (String(opco).trim().length <= 0) {
                alert("Vous devez choisir un centre Opco  ");
                return;
            }
        }

        var session = mysession;
        if (String(nom).trim().length <= 0) {
            alert(" Le nom d'utilisateur est obligatoire 2 ");
            return;
        }


        var employeur = ""
        if (document.getElementById('employeur') && document.getElementById('employeur').value) {
            employeur = document.getElementById('employeur').value;
        }

        // var class_internal_url = document.getElementsByName("adr_country")[0].value;

        formData.append('nom', nom);
        formData.append('employeur', employeur);
        formData.append('prenom', prenom);
        formData.append('telephone', telephone);
        formData.append('email', email);
        formData.append('modefinancement', modefinancement);
        formData.append('opco', opco);
        formData.append('session_id', session);
        formData.append('class_internal_url', props.internal_url);
        formData.append('price', props.trainig_price);

        /* Ceci est une preinscription faite par un end user, depuis le font, donc on force le 'status' à 0 (0 = préinscription)*/
        formData.append('status', '0');

        /*
                 Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
               */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            if (String(spec_field_updated_values_hooks[i].field_name) === "float") {

            }
            else if (String(spec_field_updated_values_hooks[i].field_name) === "string") {

            }

            formData.append(String(spec_field_updated_values_hooks[i].field_name), String(spec_field_updated_values_hooks[i].field_value));
        }

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setRecordData_api(result['status']);
                setRecordData_status(result['status']);
                setRecordData_message(result['message']);

            })
            .catch((error) => {
                console.error('Error:', error);
                setRecordData_api("false");
                setRecordData_status("false");
                setRecordData_message();
            });


    };

    const [GetListOpco_api, setGetListOpco_api] = useState();
    const [GetListOpco_message, setGetListOpco_message] = useState();
    const [GetListOpco_result, setGetListOpco_result] = useState();
    function GetListOpco(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetListOpco/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log(' GetListOpco Success:', data['message'], "data['status'] = ", data['status']);

                if (String(data['status']) === String("false")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListOpco_api("false");
                    setGetListOpco_message(data['message'])

                }
                else {

                    setGetListOpco_api("true");
                    setGetListOpco_result(data['message']);
                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetListOpco_api("false");
            });

    }


    const [closeform, setcloseform] = useState("");
    function close_inscription_form() {

        window.location.reload();
    }


    const [GetCurrentClass_api, setGetCurrentClass_api] = useState();
    const [GetCurrentClass_message, setGetCurrentClass_message] = useState();
    const [GetCurrentClass_result, setGetCurrentClass_result] = useState();
    function GetCurrentClass_trainingsession(event) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysych');
        form.append("class_internal_url", props.internal_url);
        form.append("token", stored_cookie);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetActiveSessionFormation_List/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) !== "false") {
                //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
                setGetCurrentClass_api("true");
                setGetCurrentClass_result(res.data.message);
            }
            else {
                setGetCurrentClass_api("false");
                setGetCurrentClass_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
            setGetCurrentClass_api("false");
            //setmyApimyApiMessage("")
        })
    }
    const [class_sales_price, setclass_sales_price] = useState("0");

    useEffect(() => {


        Get_Partner_Object_Specific_Fields("inscription");

        if (String(props.action).toLocaleLowerCase() !== "inscription" &&
            String(props.action).toLocaleLowerCase() !== "information") {


            CheckEvaluationToken();
            setclass_sales_price(props.trainig_price);

        }
        GetCurrentClass_trainingsession();
        GetListOpco();


    }, []);


    // Cette fonction verifier si le token de l'evaluation est valide
    //si $action != 'information' et 'inscription', alors la valeur est forcement celle d'un token.
    const [tokenEval_api, settokenEval_api] = useState();
    const [tokenEval_message, settokenEval_message] = useState();
    const [tokenEval_result, settokenEval_result] = useState();
    function CheckEvaluationToken() {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysych');
        form.append("eval_token", props.action);
        form.append("class_internal_url", props.internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/ckeckEvaluationToken/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) !== "false") {
                //console.log(" In tokenEval_trainingsession  res.data.status = " + res.data.status);
                //console.log(" In tokenEval_trainingsession  res.data.message r_class = " + res.data.message);
                settokenEval_api("true");
                settokenEval_result(res.data.message);
            }
            else {
                settokenEval_api("false");
                settokenEval_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( CheckEvaluationToken = ', error);
            settokenEval_api("false");
            //setmyApimyApiMessage("")
        })
    }

    function clearfileds() {
        if (document.getElementsByName("nom")[0]) {
            document.getElementsByName("nom")[0].value = "";
        }

        if (document.getElementsByName("prenom")[0]) {
            document.getElementsByName("prenom")[0].value = "";
        }

        if (document.getElementsByName("email")[0]) {
            document.getElementsByName("email")[0].value = "";
        }

        if (document.getElementsByName("telephone")[0]) {
            document.getElementsByName("telephone")[0].value = "";
        }

        if (document.getElementsByName("employeur")[0]) {
            document.getElementsByName("employeur")[0].value = "";
        }


    }


    function clearfileds_info() {
        if (document.getElementsByName("nom_info")[0]) {
            document.getElementsByName("nom_info")[0].value = "";
        }

        if (document.getElementsByName("prenom_info")[0]) {
            document.getElementsByName("prenom_info")[0].value = "";
        }

        if (document.getElementsByName("email_info")[0]) {
            document.getElementsByName("email_info")[0].value = "";
        }

        if (document.getElementsByName("telephone_info")[0]) {
            document.getElementsByName("telephone_info")[0].value = "";
        }

        if (document.getElementsByName("employeur_info")[0]) {
            document.getElementsByName("employeur_info")[0].value = "";
        }


    }

    const [AnnulerFction_status, setAnnulerFction_status] = useState("");
    function AnnulerFction() {
        clearfileds();
        setRecordData_status("false");
        setmysession("");
    }


    const [AnnulerFction_info_status, setAnnulerFction_info_status] = useState("");
    function AnnulerFction_info() {
        clearfileds_info();
        setAnnulerFction_info_status("false");

        if (props.Close_info_form)
            props.Close_info_form();
    }


    const editorRef_description = useRef(null);
    const [field_description, setfield_description] = useState("");
    const [editor_tochange, seteditor_tochange] = useState("");
    function editor_keyup() {
        seteditor_tochange("1");
    }


    const editorRef_evaluation = useRef(null);
    const [field_evaluation, setfield_evaluation] = useState("");
    const [editor_evaluation_tochange, seteditor_evaluation_tochange] = useState("");
    function editor_evalution_keyup() {
        seteditor_evaluation_tochange("1");
    }



    const [datamodification, setdatamodification] = useState("0");
    function DataUpdated() {
        setdatamodification("1");
    }



    const [SendInformationRequest_api, setSendInformationRequest_api] = useState("");
    const [SendInformationRequest_status, setSendInformationRequest_status] = useState("");
    const [SendInformationRequest_message, setSendInformationRequest_message] = useState("");

    function SendInformationRequest() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/RenseignementClass/";

        var nom = valnom_info;

        //console.log(" nom = ", nom, );
        if (String(nom).trim().length <= 0) {
            document.getElementsByName("nom_info")[0].style.borderColor = "red";
            alert(" Le nom d'utilisateur est obligatoire 1 ");
            return;
        }

        var prenom = valprenom_info;
        if (String(prenom).trim().length <= 0) {
            document.getElementsByName("telephone_info")[0].style.borderColor = "red";
            alert(" Le prenom d'utilisateur est obligatoire ");
            return;
        }


        var telephone = valtelephone_info;
        if (String(telephone).trim().length < 10) {
            document.getElementsByName("telephone_info")[0].style.borderColor = "red";
            alert(" Le numero de telephone doit comporter 10 chiffres ");
            return;
        }

        var email = valemail_info;
        var re2 = /\S+@\S+\.\S+/;
        if (re2.test(email) === false) {
            alert("L'email  est incorrect ");
            document.getElementsByName("email_info")[0].style.borderColor = "red";
            return;
        }


        var employeur = ""
        if (valemployeur_info) {
            employeur = valemployeur_info;
        }


        var message = "";
        if (editorRef_description.current) {
            message = editorRef_description.current.getContent();
        }


        formData.append('nom', nom);
        formData.append('employeur', employeur);
        formData.append('prenom', prenom);
        formData.append('telephone', telephone);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('class_internal_url', props.internal_url);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setSendInformationRequest_api(result['status']);
                setSendInformationRequest_status(result['status']);
                setSendInformationRequest_message(result['message']);

            })
            .catch((error) => {
                console.error('Error:', error);
                setSendInformationRequest_api("false");
                setSendInformationRequest_status("false");
                setSendInformationRequest_message();
            });


    };


    const [SendEvaluationClass_api, setSendEvaluationClass_api] = useState("");
    const [SendEvaluationClass_status, setSendEvaluationClass_status] = useState("");
    const [SendEvaluationClass_message, setSendEvaluationClass_message] = useState("");

    function SendEvaluationClass() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Evaluation_Class/";


        var message = "";
        if (editorRef_evaluation.current) {
            message = editorRef_evaluation.current.getContent();
        }



        /*
                var pedagogie = "";
                var ele = document.getElementsByName('pedagogie');
        
                for (let i = 0; i < ele.length; i++) {
                    if (ele[i].checked)
                        pedagogie = ele[i].value;
                }
        
                var logistique = "";
                ele = document.getElementsByName('log');
        
                for (let i = 0; i < ele.length; i++) {
                    if (ele[i].checked)
                        logistique = ele[i].value;
                }
        
        */
        if (!RatingValue) {

            alert(" Aidez-nous à nous améliorer, donnez une appréciation globale svp. Le point N° 1. Merci d'avance")
            return;
        }

        if (!RatinpedagogiegValue) {

            alert(" Aidez-nous à nous améliorer, donnez une appréciation sur notre pédagigie svp. Le point N° 2. Merci d'avance")
            return;
        }




        formData.append('inscription_id', props.eval_token);
        formData.append('eval_note', RatingValue);
        formData.append('eval_pedagogie', RatinpedagogiegValue);
        formData.append('eval_eval', message);
        formData.append('class_internal_url', class_internal_url);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === "true") {
                    setSendEvaluationClass_api("true");
                } else if (String(result['status']) === "false") {
                    setSendEvaluationClass_api("false");
                    setSendEvaluationClass_message(result['message']);
                }


            })
            .catch((error) => {
                console.error('Error:', error);
                setSendEvaluationClass_api("false");

            });

    };

    const [RatingValue, setRatingValue] = useState(0);
    const [RatinpedagogiegValue, setRatinpedagogiegValue] = useState(0);
    let datafromchild = "yaaaaa child";

    const [valnom_info, setvalnom_info] = useState();
    const [valprenom_info, setvalprenom_info] = useState();
    const [valemail_info, setvalemail_info] = useState();
    const [valtelephone_info, setvaltelephone_info] = useState();
    const [valemployeur_info, setvalemployeur_info] = useState();

    const handlervalnom_info = event => {
        var val = event.target.value;
        setvalnom_info(val);
    };


    const handlervalprenom_info = event => {
        var val = event.target.value;
        setvalprenom_info(val);
    };


    const handlervalemail_info = event => {
        var val = event.target.value;
        setvalemail_info(val);
    };


    const handlervaltelephone_info = event => {
        var val = event.target.value;
        setvaltelephone_info(val);
    };

    const handlervalemployeur_info = event => {
        var val = event.target.value;
        setvalemployeur_info(val);
    };

    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
    
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
    
    */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values = []
    const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);


    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks(spec_field_updated_values)
    }
    // -- end champs specifiques 



    return (
        <div className='inscription'>

            {String(RecordData_status) === "true" &&
                String(action).toLocaleLowerCase() === "inscription" &&
                <div className="okUpdateData">

                    Votre inscription a bien été prise en compte.
                    Vous allez recevoir une email de confirmation dans votre boite email.
                    Merci.
                </div>}

            {String(SendInformationRequest_status) === "true" &&
                String(action).toLocaleLowerCase() === "information" &&
                <div className="okUpdateData">

                    Votre demande d'information a été prise en compte.<br />
                    Merci.
                </div>}



            {(String(RecordData_status) === "false" ||
                String(RecordData_status).length === 0) &&
                String(action).toLocaleLowerCase() === "inscription" &&
                String(AnnulerFction_status) !== "1" && <div>

                    &nbsp;
                    <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>
                        {String(RecordData_status) === "false" && <div className="koUpdateData">
                            {RecordData_message}

                        </div>}
                        <div className="select-container">
                            <div className="titre1_aide_finance"> Je m'inscris </div>
                            <div style={{ "float": "left", "width": "40%", "textAlign": "left" }}>
                                Choisir une session &nbsp;
                            </div>

                            <div style={{ "float": "right", "width": "60%", "textAlign": "right" }}>


                                <select value={mysession} onChange={handleChangeSession} className="selectsession">
                                    <option value="">Choisir</option>

                                    {GetCurrentClass_result && GetCurrentClass_result.map((formation) => (



                                        <option value={JSON.parse(formation)._id} style={{ "height": "3rem", "borderRadius": "0px" }}>


                                            {(JSON.parse(formation).session_ondemande) === "1" && " A la Demande - "}
                                            {(!JSON.parse(formation).session_ondemande || JSON.parse(formation).session_ondemande === "0") &&
                                                (JSON.parse(formation).date_debut).substring(0, 10) + " au " + (JSON.parse(formation).date_fin).substring(0, 10)}

                                            {/*(JSON.parse(formation).date_debut).substring(0, 10)} au {(JSON.parse(formation).date_fin).substring(0, 10)*/} -
                                            {(JSON.parse(formation).distantiel) === "1" && " A Distance - "}
                                            {(JSON.parse(formation).presentiel) === "1" && (JSON.parse(formation).adresse) && " En Présentiel - " + (JSON.parse(formation).adresse)}
                                            {(JSON.parse(formation).presentiel) === "1" && (!JSON.parse(formation).adresse) && " En Présentiel - "}
                                            {(JSON.parse(formation).code_postal) && " " + (JSON.parse(formation).code_postal)}



                                        </option>
                                    ))}
                                </select>

                                {/*           {GetCurrentClass_result &&   <select value={mysession} onChange={handleChangeSession} className="selectsession">
                                    <option value="">Choisir</option>
                                    {
                                        JSON.parse(GetCurrentClass_result).map((session) => <option value={session.session_id}>
                                            {session.date_debut} - {session.date_fin} à </option>)
                                    }
                                </select>} */}
                            </div>


                        </div>

                    </Box>

                    {mysession &&
                        <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>
                            &nbsp;
                            <TextField
                                required
                                label="Nom"
                                name="nom"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                            <TextField
                                required
                                label="Prénom(s)"
                                name="prenom"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                            <TextField
                                required
                                name="email"
                                label="Adresse mail"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                            <TextField
                                required
                                name="telephone"
                                label="Téléphone"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                            <TextField

                                name="employeur"
                                id="employeur"
                                label="Employeur"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeWorkIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                            {/* -- début champs specifiques **/}

                            <div className="div_row" style={{ "padding": "5px", "width": "100%" }}>

                                {rows_champs_specifics &&
                                    rows_champs_specifics.filter((spec_field) => JSON.parse(spec_field).is_public === "1").map((champ_spec) => (

                                        <div>
                                            {String(JSON.parse(champ_spec).field_type) === "float" && <TextField

                                                name={JSON.parse(champ_spec).field_name}
                                                id={JSON.parse(champ_spec).field_name}
                                                label={JSON.parse(champ_spec).field_label}
                                                className="texte_area"
                                                sx={{ m: 1, width: '48%' }}
                                                type="number"
                                                inputProps={{ min: "1", max: "999999", step: "1" }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AssignmentSharpIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />}

                                            {String(JSON.parse(champ_spec).field_type) === "string" && <TextField

                                                name={JSON.parse(champ_spec).field_name}
                                                id={JSON.parse(champ_spec).field_name}
                                                label={JSON.parse(champ_spec).field_label}
                                                className="texte_area"

                                                inputProps={{ min: "1", max: "999999", step: "1" }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AssignmentSharpIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                            />}


                                        </div>


                                    ))}

                                <br />

                            </div>
                            {/* -- end  champs specifiques **/}


                            <div style={{ "width": "10%" }}> &nbsp;</div>
                            <div className="titre1_aide_finance"> Nous vous aidons à financer la formation</div>

                            &nbsp;
                            <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>
                                <div className="select-container" style={{ "marginBottom": "1rem" }}>
                                    <div className="field_gauche">
                                        Choisir le mode financement : &nbsp;
                                    </div>
                                    <div className="field_droite">
                                        <select id="modefinancement" value={monfinancement} onChange={handleChangeFinancement} className="selectsession">
                                            <option value="">Choisir </option>
                                            {modefinancement.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                            </Box>
                            <div style={{ "width": "100%" }}> &nbsp;</div>
                            {monfinancement && String(monfinancement) !== "perso" &&

                                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>
                                    &nbsp;
                                    <div className="select-container">
                                        <div className="field_gauche">
                                            Choisir votre centre de rattachement
                                        </div>
                                        <div className="field_droite">
                                            <select id="opco" value={myopco} onChange={handleChangeOpco} className="selectsession">
                                                <option value="">Choisir </option>
                                                {GetListOpco_result.map((option) => (
                                                    <option value={(JSON.parse(option).idcc_code)}>{(JSON.parse(option).idcc_code)} </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="okUpdateData"><i> *Laisser "Choisir" si vous ne le connaissez pas. </i>
                                        </div>
                                    </div>

                                </Box>}




                        </Box>}
                    <div className="div_row22">
                        {<div className="div_row" style={{ "width": "100%", "marginTop": "0.5rem" }}>
                            <div className="div_row_droite" style={{ "textAlign": "left", "width": "50%" }}>
                                <Button className="bton_envoyer"
                                    onClick={RecordData}>Se pré-inscrire &nbsp; &nbsp;  {<SendIcon />}</Button>
                            </div>
                            {<div className="div_row_gauche" style={{ "textAlign": "right", "width": "50%" }}>
                                <Button variant="contained" color="success" className="bton_fermer"
                                    onClick={AnnulerFction_info}>Fermer &nbsp; &nbsp;{<IoCloseCircleOutline />}</Button>
                            </div>}

                        </div>
                        }

                    </div>
                </div>}

            {/*  Gestion de la note/evaluation */}


            {(String(RecordData_status) === "false" ||
                String(RecordData_status).length === 0) &&
                String(action).toLocaleLowerCase() === "evaluation" &&
                String(AnnulerFction_status) !== "1" &&
                String(SendEvaluationClass_api) === "true" && <div className="survey_page">
                    <div className="okUpdateData">
                        Votre évaluation a bien été prise en compte. Merci.
                    </div>

                </div>}


            {(String(RecordData_status) === "false" ||
                String(RecordData_status).length === 0) &&
                String(action).toLocaleLowerCase() === "evaluation" &&
                String(AnnulerFction_status) !== "1" &&
                String(SendEvaluationClass_api) !== "true" && <div className="survey_page">

                    {String(SendEvaluationClass_api) === "false" && <div className="survey_page">
                        <div className="koUpdateData">
                            {SendEvaluationClass_message}
                        </div>

                    </div>}

                    <h3> Demande d'evaluation </h3>
                    <div className="div_row22 survey_block">
                        <fieldset>
                            <nav className="survey_title"> 1. Une <b>note </b> pour l'appréciation générale </nav>
                            <Rating name="half-rating-read"
                                defaultValue={0}
                                precision={0.5}
                                onChange={(event, newValue) => {
                                    setRatingValue(newValue);
                                }} /> <br />
                        </fieldset>
                    </div>

                    <div className="div_row22 survey_block">
                        <fieldset>
                            <nav className="survey_title"> 2 .Pédagogie </nav>
                            <nav className="survey_desc"> Comment la formation a été dispensée</nav>
                            <Rating name="half-rating-read"
                                defaultValue={0}
                                precision={0.5}
                                onChange={(event, newValue) => {
                                    setRatinpedagogiegValue(newValue);
                                }} /> <br />
                        </fieldset>
                    </div>


                    <div className="div_row22 survey_block">
                        <fieldset style={{ "maxHeight": '200px' }}>
                            <nav className="survey_title"> 4 .Un commentaire ?</nav>

                            <Editor
                                onInit={(evt, editor) => editorRef_evaluation.current = editor}
                                initialValue={field_evaluation}
                                onKeyUp={editor_keyup}
                                onEditorChange={(newValue, editor) => {

                                    if (String(editor_evaluation_tochange) === "1") {

                                        seteditor_evaluation_tochange("");
                                    }
                                }}

                                init={{
                                    resize: false,
                                    height: 100,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: false,
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                }}
                            />
                        </fieldset>
                    </div>
                    <div className="div_row22">
                        {<div className="div_row">
                            <div className="div_row_droite" style={{ "textAlign": "left", "marginTop": "0.5rem" }}>
                                <Button variant="contained" className="bton_envoyer"
                                    onClick={SendEvaluationClass}
                                    startIcon={<SendIcon />}>Envoyer évaluation&nbsp; &nbsp;  {<SendIcon />}</Button>

                            </div>

                            {<div className="div_row_gauche" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                <Button variant="contained" color="success" className="bton_fermer"
                                    onClick={AnnulerFction_info}>Fermer &nbsp; &nbsp;{<IoCloseCircleOutline />}</Button>
                            </div>}
                        </div>

                        }

                    </div>



                </div>}

            {/*  Gestion des demandes d'information */}


            {(String(SendInformationRequest_status) === "false" ||
                String(SendInformationRequest_status).length === 0) &&
                String(action).toLocaleLowerCase() === "information" &&
                String(AnnulerFction_info_status) !== "false" && <div>

                    <div className="div_info_gauche">
                        {String(RecordData_status) === "false" && <div className="koUpdateData">
                            {RecordData_message}

                        </div>}
                        <div className="select-container">
                            <div className="titre1_aide_finance"> Je m'informe</div>

                        </div>


                        <TextField
                            required
                            label="Nom "
                            name="nom_info"
                            id="nom_info"
                            className="texte_area"
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handlervalnom_info}
                            variant="standard"
                        />

                        <TextField
                            required
                            label="Prénom(s)"
                            name="prenom_info"
                            className="texte_area"
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handlervalprenom_info}
                            variant="standard"
                        />

                        <TextField
                            required
                            name="email_info"
                            label="Adresse mail"
                            className="texte_area"
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handlervalemail_info}
                            variant="standard"
                        />

                        <TextField
                            required
                            name="telephone_info"
                            label="Téléphone"
                            className="texte_area"
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handlervaltelephone_info}
                            variant="standard"
                        />

                        <TextField
                            name="employeur_info"
                            id="employeur_info"
                            label="Employeur"
                            className="texte_area"
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <HomeWorkIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handlervalemployeur_info}
                            variant="standard"
                        />

                    </div>

                    {/* -- début champs specifiques **/}
                    <div className="div_row">
                        <hr />
                    </div>
                    <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques 2<br />

                        {rows_champs_specifics &&
                            rows_champs_specifics.map((champ_spec) => (

                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                    <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}


                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            //setEmployee_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />
                                </div>
                            ))}

                        <br />

                    </div>
                    {/* -- end  champs specifiques **/}


                    <div className="div_info_droite">
                        <div className="titre1_aide_finance"> Dites nous en plus sur votre besoin :)</div>
                        Votre message <br />
                        <Editor
                            onInit={(evt, editor) => editorRef_description.current = editor}
                            initialValue={field_description}
                            onKeyUp={editor_keyup}
                            onEditorChange={(newValue, editor) => {

                                if (String(editor_tochange) === "1") {
                                    //alert(" change");
                                    DataUpdated()
                                    seteditor_tochange("");
                                }
                            }}

                            init={{
                                resize: false,
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: false,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                            }}
                        />

                    </div>
                    <div> &nbsp;</div>

                    <div className="div_row22">
                        {<div className="div_row" style={{ "width": "100%" }}>
                            <div className="div_row_droite" style={{ "textAlign": "left", "marginTop": "0.5rem" }}>
                                <Button variant="contained" className="bton_envoyer"
                                    onClick={SendInformationRequest}
                                    startIcon={<SendIcon />}>Envoyer &nbsp; &nbsp;  {<SendIcon />}</Button>

                            </div>


                            {<div className="div_row_gauche" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                <Button variant="contained" color="success" className="bton_fermer"
                                    onClick={AnnulerFction_info}>Fermer &nbsp; &nbsp;{<IoCloseCircleOutline />}</Button>
                            </div>}
                        </div>

                        }

                    </div>


                </div>}


        </div>
    );
}
export default Inscription_Information;