
import React, { useState, useEffect } from 'react';
//import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Button from '@mui/material/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import connect_white from "../mysy_img/connect_white_v2.png";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import connect_green from "../mysy_img/connect_green.png";


const ButtonHilight = () => {
    const history = useHistory();
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);

    const controlNavbar = () => {

        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY && window.scrollY > 100) {
                setShow(false);
            } else if (window.scrollY < lastScrollY && window.scrollY < 100) { // if scroll up show the navbar

                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    const [userconnected, setuserconnected] = useState("0");
    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const stored_user = getCookie('tokenmysych');
    const stored_part = getCookie('tokenmysypart');

    function whosisconnected() {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            // alert(" partner connected OK");
        }
    }

    useEffect(() => {

        whosisconnected();

        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }


    }, [lastScrollY]);


    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);



    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };


    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };

    const handleQuisommesNousMenu = (event) => {
        history.push("/QuiSommesNous")
    };


    

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };



    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/Produits-Services/")
    };

    const handleAvis = () => {
        setavisMenu(null);
        history.push("/Recherche-Article-formation")
    };


    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        //alert(" deccc");
        //console.log(" ### AVANNN deconnexion iciiii");
        if (typeof (stored_part) != "undefined") {
            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie + "_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });
            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });
        }
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro bb)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }
    function handleAccountLogout() {


        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }

    const [CategorieMenu, setCategorieMenu] = React.useState(null);
    const CategorieMenu_Close = () => {
        setCategorieMenu(null);
    };

    const handleCategorieMenu = (event) => {
        setCategorieMenu(event.currentTarget);
    };

    const handleClassCategorie = (e) => {
        setCategorieMenu(null);
        const pieces = window.location.href.split("/")
        const last = pieces[pieces.length - 1]


        var mymetier = "";
        mymetier = e.target.id
        if (last === mymetier) {
            //console.log(" ## RELAOD");
            window.location.reload(true);
        } else {
            history.push("/Recherche_new_v2/0/" + mymetier);
        }
    };

    function publiecatalogue() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }


    const handleBlogMenu = (event) => {
        history.push("/Recherche-Article-formation")
    };


    return (
        <div className='ButtonHilight'>
            <Helmet>
                <title>Compte utilisateur</title>
                <meta name="description" content="Compte utilisateur formation" />
                <meta name="robots" content="index,follow" />
            </Helmet>

            <Menu
                keepMounted
                anchorEl={PartnerMenu}
                onClose={PartnerMenu_Close}
                open={Boolean(PartnerMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handlePartnerConnexion} className="mymenu_item"
                >Mon Compte</MenuItem>
                <MenuItem onClick={PartnerMenu_Close} className="mymenu_item"
                >Deconnexion</MenuItem>

            </Menu>

            <Menu
                keepMounted
                anchorEl={AccountMenu}
                onClose={AccountMenu_Close}
                open={Boolean(AccountMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handleMyAccount} className="mymenu_item"
                >Mon Compte</MenuItem>
                <MenuItem onClick={handleAccountConnexion} className="mymenu_item"
                >Connexion</MenuItem>

                <MenuItem onClick={handleAccountCreation} className="mymenu_item"
                >S'inscrire</MenuItem>
                <MenuItem onClick={AccountMenu_Close} className="mymenu_item"
                >Deconnexion</MenuItem>
            </Menu>

            <Menu
                keepMounted
                anchorEl={ProduitMenu}
                onClose={ProduitMenu_Close}
                open={Boolean(ProduitMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleProduitService} className="mymenu_item"
                >  <button className='bton_menu'> Nos produits & Services </button></MenuItem>

                {/* <MenuItem onClick={handleAvis} className="mymenu_item"
                > <button className='bton_menu'> Blog </button> </MenuItem>*/}

            </Menu>


            <Menu
                keepMounted
                anchorEl={CategorieMenu}
                onClose={CategorieMenu_Close}
                open={Boolean(CategorieMenu)}
                style={{ "top": "2rem" }}
                className="ButtonHilight"
            >
                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="IT" >Informatique</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="vente" > Commerce</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="RH" > Ressources Humaines</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="marketing" >Marketting</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="graphisme" >Graphisme</button>
                </MenuItem>


                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="management" >Management</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item">
                    <button className='bton_menu' id="dev_perso" >Dev. Personnel </button>
                </MenuItem>


            </Menu>

            <div className={`mini_entete  ${show && 'hidden'}`}  >
                <div className="div_001">
                    <div className="div_002">
                        <h1 className="h1_transparent">Connexion MySy Training Technogy </h1>
                        <div className="vvv" >
                            <a href={process.env.REACT_APP_BASE_URL}> <img class="img-responsive header_img_logo22" src={logowhite} alt="MySy Training Technology" /> </a>
                        </div>
                        <div className="header_menuPrincipal_left" onClick={handleCategorieMenu}>
                            Les formations
                        </div>
                    </div>
                    <div className="div_003">
                        <h1 className="h1_transparent">Recherche Formations  Tutoriels</h1>
                        <div className="hilight_menuPrincipal_bton" onClick={handleAccountConnexion}>
                            <div style={{ "textAlign": "left" }}>
                                {String(someoneconnected) !== String("1") && <button className="buton_ch22" onClick={handleAccountConnexion}> <img src={connect_white}
                                    style={{ "width": "25%" }} /> Connexion
                                </button>}
                                {String(someoneconnected) === String("1") && <button className="buton_ch22" onClick={handleAccountLogout}> Deconnexion<img src={connect_green}
                                    style={{ "width": "25%" }} />
                                </button>}
                            </div>
                        </div>

                        {String(someoneconnected) === String("1") && <div className="hilight_menuPrincipal" onClick={handleMyAccount}>
                            Mon Compte
                        </div>}

                        {String(someoneconnected) !== String("1") && <div className="hilight_menuPrincipal" onClick={handleAccountCreation}>
                            S'inscrire
                        </div>}

                        <div className="hilight_menuPrincipal" onClick={handleProduitMenu}>
                            Nos Services
                        </div>

                        {/* <div className="hilight_menuPrincipal" onClick={handleBlogMenu}>
                            Le blog
    </div>*/}

                        <div className="hilight_menuPrincipal" onClick={handleQuisommesNousMenu}>
                            Qui Sommes nous ?
                        </div>

                        {/* <div className="hilight_menuPrincipal" onClick={publiecatalogue}
                            style={{ "background": "red", "borderRadius": "5px", "paddingLeft": "3px", "paddingRight": "3px" }}>
                            publier mes formations
</div>*/}

                    </div>


                </div>
                {/* <div className={`button1  ${show && 'hidden'}`} >
                    <div style={{ "width": "100%", "backgroundColor": "white" }}>

                        <Button variant="outlined"
                            className={`button1  ${show && 'hidden'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "/Recherche-Article-formation";
                            }}>  Le blog </Button> <br />

                    </div>
                        </div>*/}
            </div>

        </div>
    );
}



export default ButtonHilight;