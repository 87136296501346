import DisplayDetailArticle from "../components/DisplayDetailArticle";
import Navigation from "../components/Navigation";

export default function displaydetailclass() {
    return (
        <div>
           <div >
            <Navigation />
            </div>
            
            <DisplayDetailArticle />
               
            
        </div>
    );
}