import React, { useState, useEffect } from "react";
import axios from "axios";

import profileimg from "../mysy_img/MYSY-profil-2.png";
import TrainingForm from "./AddClassManual"
import PartnerTranings from "./DisplayPartnerTrainingsPagination";
import HebergementLms from "./HebergementLms";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import PartnerInfo from "./UpdatePartnerInfo";
import PartenairStat from "./PartenairStat";
import { Helmet } from "react-helmet";

import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { GiTeacher } from "react-icons/gi";
import Footer from "./Fotter";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";
import Header from "./Header";
import Facture from "./Facture";
import Abonnement from "./Abonnement";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom'
import CheckOut from "./CheckOut";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { FaHandPointRight } from "react-icons/fa";
import OrderConfirmation from "./OrderConfirmation";
import Partner_Client from "./Partner_Client";
import Pricing from "./Pricing";
import {
    FcHome, FcButtingIn, FcDepartment, FcBullish, FcAddDatabase, FcFolder,
    FcList, FcGraduationCap, FcMultipleDevices, FcCurrencyExchange,
    FcMoneyTransfer, FcFeedback, FcKey, FcVideoCall, FcSettings,
    FcBusinesswoman, FcServices, FcCollect, FcCandleSticks, FcKindle,
    FcFlowChart, FcReading, FcFinePrint, FcCancel, FcCalendar,
} from "react-icons/fc";
import { PiStudent } from "react-icons/pi";

import { BiPurchaseTag } from "react-icons/bi";

import { BsTools } from "react-icons/bs";

import DisplayPartnerSession from "./DisplayPartnerSession";
import DisplayPartnerStagiaires from "./DisplayPartnerStagiaires";
import Employes from "./Employes";
import Materiels from "./Materiels";
import Partner_Commande from "./Partner_Commande";
import Config_Documents from "./Config_Document";
import Factures_Client from "./Partner_Facture";
import Configuration_Technique from "./Partner_Config_Technique";
import Config_Champs_Personnalise from "./Config_Champs_Personnalise";
import Module_Agenda from "./Module_Agenda";
import Partner_Configuration_Session_Steps from "./Partner_Config_Session_Steps";
import Partner_Configuration_Jours_Travail from "./Partner_Config_Jours_Travail";
import Dashbord_Session from "./Dashbord_Session";
import Dashbord_Ressources_Humaines from "./Dashbord_Ressources_Humaines";
import Dashbord_Inscriptions from "./Dashbord_Inscription";

import Mon_Tableau_De_Bord from "./Mon_Tableau_De_Bord";
import Dashbord_Formation from "./Dashbord_Formation";
import Partner_Prix_Achat from "./Partner_Prix_Achat";
import Apprenant from "./Apprenant";
import { PiSlideshowFill, PiSlideshowThin } from "react-icons/pi";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ImWarning } from "react-icons/im";
//import { FcHome} from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Dropdown from 'rsuite/Dropdown';
//import 'rsuite/dist/rsuite.min.css';
import { AiOutlineDashboard } from "react-icons/ai";

const Partner = (props) => {
    const { commingmenu, commingpack } = useParams();
    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);
    const stored_part = getCookie('tokenmysypart');
    const [parntername, setparntername] = useState("");
    const [parnter_user_employee, setparnter_user_employee] = useState("");
    const [parnternbformation, setparnternbformation] = useState("");
    const [selectedfilt1, setselectedfilt1] = useState("");
    const [selectedfilt2, setselectedfilt2] = useState("");
    const [selectedfilt3, setselectedfilt3] = useState("");
    const [excelimportmessage, setexcelimportmessage] = useState("");
    const [apiexcelimportmessage, setapiexcelimportmessage] = useState("");
    const [gotocheckout, setgotocheckout] = useState("");

    // Constante permet de savoir a fonction du menu on a faire
    const [menu, setmenu] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);

    const [partnerPackService, setpartnerPackService] = useState("");

    const [isimgSelected, setisimgSelected] = useState(false);
    const [isimgSelectedfile, setisimgSelectedfile] = useState(false);
    const [userimgprofil, setuserimgprofil] = useState();

    const [partnerstatus, setpartnerstatus] = useState();
    const [refesh, setrefesh] = useState("init");

    const [firstConnexion, setfirstConnexion] = useState("");
    const [part_is_partner_admin_account, setpart_is_partner_admin_account] = useState("");

    const { action, orderid, packs, object_key, class_internal_url } = useParams()

    const [previous_action, setprevious_action] = useState("");


    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
        setapiexcelimportmessage("");
    };

    const changeHandler2 = (event) => {
        setapiexcelimportmessage();
        setformation_file_name();
        sethandleSubmission_api();
        hiddenFileInput_formation.current.click();
    };

    const hiddenFileInput_formation = React.useRef(null);

    const history = useHistory();


    const stored_cookie = getCookie('tokenmysypart');
    const location = useLocation();

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_cookie || stored_cookie.length <= 0) {
        if (location && location.state && location.state.mysy_arg) {
            //alert(" mysy arg (dans partner) = " + location.state.mysy_arg);
            history.push("/Connexion", { mysy_arg: location.state.mysy_arg });
        }
        else {
            history.push("/Connexion");
        }
    }


    const formData = new FormData();

    const [handleSubmission_api, sethandleSubmission_api] = useState();
    const [handleSubmission_result, sethandleSubmission_result] = useState();
    const [handleSubmission_message, sethandleSubmission_message] = useState();

    const handleSubmission = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setformation_file_name(event.target.files[0].name);

        //console.log(" import du ficier ", event.target.files[0].name);


        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');


        formData.append("token", stored_cookie);

        //console.log("token = " + stored_cookie); theme_name
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/add_class_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);
                setexcelimportmessage(result['message']);
                setmyApiResponse("true");
                setapiexcelimportmessage(result['status']);
                setLoading(false);
                if (String(result['status']) === String("true")) {

                    sethandleSubmission_api("true");
                    var local_message = result['message'] + " Vous allez être redirigé vers la liste des formations"
                    alert(local_message);
                    history.push("/Partner/affichage");
                    window.location.reload();
                    return;

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    sethandleSubmission_api("false");
                    sethandleSubmission_message(result['message']);
                    alert(result['message']);
                }
                //alert("result['status'] " + result['status']);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', result);
                setmyApiResponse("false");
                setapiexcelimportmessage("-1");
                sethandleSubmission_api("false");
                alert(" Impossible d'importer/mettre à jour les formations");
            });
    };

    const [myimage, setmyimage] = useState();
    const [isimage, setisimage] = useState();

    function getImage(e) {
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedImage/";
        var form = new FormData();
        form.append("token", stored_cookie);
        form.append("type", "partner");

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" getImage : In test  res.data.status = " + res.data.status);
                //console.log(" getImage: res.data.message.img = " + res.data.message);

                if (res.data.message.img.length > 0) {
                    setisimage("True");
                }
                else {
                    setisimage("False");
                }
                setmyimage(res.data.message.img);
                var ch_img = "data:image/png;base64," + res.data.message.img;
                setuserimgprofil(ch_img);
            }
            else {
                //console.log(" In Erreur   res.data.status = " + res.data.status);
                //console.log(" In Erreur  res.data.message = " + res.data.message);
                setisimage("False");
                setuserimgprofil();
                //alert("4");
            }
        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setisimage("False");
            setuserimgprofil(profileimg);
        })

    }


    // Cette fonction recuperer les droits d'acces stocké en cookie
    const [connected_user_access_right, setconnected_user_access_right] = React.useState(null);
    function retrieve_user_acces_right() {
        const stored_cookie = getCookie('tokenmysypart');
        var acces_right_token_name = stored_cookie + "_uar";

        var acces_right_token_val = getCookie(acces_right_token_name, { path: '/' });

        var tab_json_access_right = JSON.parse(acces_right_token_val);

        setconnected_user_access_right(tab_json_access_right);

    }

    // check du droit d'acces de l'utilisateur
    function check_user_acces_right(local_module, local_action) {

        //console.log(" ### connected_user_access_right = ", connected_user_access_right);
        //console.log(" #### check_user_acces_right = ", local_module, " --- local_action = ", local_action);

        if (String(local_action) === "read") {
            for (let i = 0; i < connected_user_access_right.length; i++) {
                if (String(connected_user_access_right[i].module) === String(local_module)
                    && String(connected_user_access_right[i].read) === "true") {

                    return 1;
                }
            }
            return 0;
        } else if (String(local_action) === "write") {
            for (let i = 0; i < connected_user_access_right.length; i++) {
                if (String(connected_user_access_right[i].module) === String(local_module)
                    && String(connected_user_access_right[i].write) === "true") {

                    return 1;
                }
            }
            return 0;
        } else {
            return 0;
        }

    }


    useEffect(() => {

        setapiexcelimportmessage();

        if (location && location.state && location.state.local_sub_menu) {
            setmenu(location.state.local_sub_menu);
            setpartnerPackService(location.state.currentpack);
            //alert(" le pack = "+location.state.currentpack);
        }

        var list_menu = ['creation', 'infopartner', 'statistique', 'abonnement', 'affichage', 'ordervalide',
            'mes_sessions', 'setuplms', 'facture', 'pricing', 'mes_clients', 'mes_devis', 'mes_cmd',
            'mes_stagiaires', 'mes_employes', 'mon_materiel', 'config_document', 'factures_client', "config_technique",
            "config_champ_specific", "module_agenda", "config_session_steps", "config_jours_heures", "dashbord_formation",
            "dashbord_session", "mon_tableau_de_bord", "prix_achat", "mes_apprenants", "dashbord_ressources_humaines",
            "dashbord_inscriptions"]

        if (action && list_menu.includes(action)) {

            setmenu(action);


            if (action && orderid && packs && String(action) === "ordervalide") {
                CheckSalesOrder();
            }

            /* if (action && orderid && packs && String("abonnement")) {
                 //console.log(" abonnement : payement intent = ", orderid, "  pack = ", packs);
             }*/
        } else {
            DiplaytrainingList();

        }

        getImage();
        GetPartnerNameFromToken();
        setformation_file_name();
        GetPartnerLMSData();
        retrieve_user_acces_right();
    }, [])



    function CreateTraining(event) {
        var security_retval = check_user_acces_right("formation", "read");
        if (security_retval === 1) {
            setmenu("creation");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/creation");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des formations");
        }
    }

    function PersonnalData(event) {

        setmenu("infopartner");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/infopartner");
        document.body.style.backgroundColor = "#ffffff";

    }


    function config_document_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_document");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_document");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function config_specfic_fields_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_champ_specific");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_champ_specific");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function module_agenda_func(event) {
        var security_retval = check_user_acces_right("module_agenda", "read");
        if (security_retval === 1) {
            setmenu("module_agenda");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/module_agenda");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function mon_tableau_de_bord_func(event) {
        setmenu("mon_tableau_de_bord");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/mon_tableau_de_bord");
        document.body.style.backgroundColor = "#ffffff";
    }


    function prix_achat_func(event) {
        setmenu("prix_achat");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/prix_achat");
        document.body.style.backgroundColor = "#ffffff";
    }





    function config_technique_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_technique");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_technique");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function config_session_steps_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_session_steps");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_session_steps");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function config_jours_travaille_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_jours_heures");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_jours_heures");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function mes_factures_client_func(event) {
        //var security_retval = check_user_acces_right("facture_client", "read");
        //if (security_retval === 1) {
        setmenu("factures_client");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/factures_client");
        document.body.style.backgroundColor = "#ffffff";
        /*} else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }*/
    }


    function DisplayStat(event) {
        var security_retval = check_user_acces_right("formation", "read");
        if (security_retval === 1) {
            setmenu("statistique");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/statistique");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des formations");
        }
    }

    function DisplaySetUpLMS(event) {
        var security_retval = check_user_acces_right("lms", "read");
        if (security_retval === 1) {
            setmenu("setuplms");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/setuplms");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du E-Learning");
        }

    }

    function DisplayFacure(event) {
        var security_retval = check_user_acces_right("commande", "read");
        if (security_retval === 1) {
            setmenu("facture");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/facture");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des commandes");
        }

    }

    function Abonnement_func(event) {
        var security_retval = check_user_acces_right("abonnement", "read");
        if (security_retval === 1) {
            setmenu("abonnement");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/abonnement");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des abonnements");
        }

    }

    function pricing_func(event) {
        var security_retval = check_user_acces_right("prix", "read");
        if (security_retval === 1) {
            setmenu("pricing");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/pricing");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des prix");
        }

    }

    function mes_clients_func(event) {
        var security_retval = check_user_acces_right("client", "read");

        if (security_retval === 1) {
            setmenu("mes_clients");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_clients");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des clients");
        }
    }

    function mes_devis_func(event) {
        var security_retval = check_user_acces_right("commande", "read");
        if (security_retval === 1) {
            setmenu("mes_devis");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_devis");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des commandes");
        }
    }


    function mes_cmd_func(event) {
        var security_retval = check_user_acces_right("commande", "read");
        if (security_retval === 1) {
            setmenu("mes_cmd");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_cmd");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des commandes");
        }
    }


    function DiplaySessionList(event) {
        var security_retval = check_user_acces_right("session", "read");


        if (security_retval === 1) {
            setmenu("mes_sessions");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_sessions");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des sessions");
        }
    }

    function DiplayStagiaireList(event) {
        var security_retval = check_user_acces_right("stagiaire", "read");
        if (security_retval === 1) {
            setmenu("mes_stagiaires");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_stagiaires");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des stagiaires");
        }
    }

    function mes_apprenants_func(event) {
        var security_retval = check_user_acces_right("stagiaire", "read");
        if (security_retval === 1) {
            setmenu("mes_apprenants");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_apprenants");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des stagiaires");
        }
    }

    function DisplayeEmployeList(event) {
        var security_retval = check_user_acces_right("employe", "read");
        if (security_retval === 1) {
            setmenu("mes_employes");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_employes");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des employés");
        }
    }

    function DisplayeMaterielList(event) {
        var security_retval = check_user_acces_right("materiel", "read");

        if (security_retval === 1) {
            setmenu("mon_materiel");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/mon_materiel");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function config_dashbord_formation_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_formation");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/dashbord_formation");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function config_dashbord_session_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_session");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_session");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }


    function config_dashbord_ressources_humaines_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_ressources_humaines");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_ressources_humaines");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }



    function config_dashbord_inscriptions_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_inscriptions");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_inscriptions");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function DiplaytrainingList(event) {

        setmenu("affichage");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/affichage");

        var server_address = "127.0.0.1";
        //var server_address = "89.156.84.196";
        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = selectedfilt1;
        var filtre2 = selectedfilt2;
        var filtre3 = selectedfilt3;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";

        // Recuperation des valeurs de filtres

        if (selectedfilt1) {
            filtre1_value = document.getElementsByName("filtre1")[0].value;
            form.append(filtre1, filtre1_value);
        }

        if (selectedfilt2) {
            filtre2_value = document.getElementsByName("filtre2")[0].value;
            form.append(filtre2, filtre2_value);
        }

        if (selectedfilt3) {
            filtre3_value = document.getElementsByName("filtre3")[0].value;
            form.append(filtre3, filtre3_value);
        }

        //console.log(" filtres1 = " + filtre1 + " = " + filtre1_value);
        //console.log(" filtres2 = " + filtre2 + " = " + filtre2_value);
        //console.log(" filtres3 = " + filtre3 + " = " + filtre3_value);


        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

        axios.post(myurl, form).then(res => {
            if (res.data.status !== "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("true");
                setResult(res.data.message)
                //setmyApimyApiMessage("OK")
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("false");
            //setmyApimyApiMessage("")
        })
    }

    function logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_part) != "undefined") {
            removeCookie_part('tokenmysypart', { path: '/' });
            history.push("/recherche-formation");
        }
    }

    function logout() {
        confirmAlert({
            title: 'Deconnexion ?',
            message: 'Confirmez la deconnexion',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const imgchangeHandler = (event) => {
        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;


        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 100000) {
            alert("L'image doit faire moins de 1 mega Kilo Octets");
            return;
        }
        setisimgSelectedfile(event.target.files[0]);
        setisimgSelected(true);
        setuserimgprofil(URL.createObjectURL(event.target.files[0]));
    };


    const [conntected_employee_id, setconntected_employee_id] = useState("");
    function GetPartnerNameFromToken(event) {

        var form = new FormData();

        //console.log(" GetPartnerNameFromToken stored_cookie = ", stored_cookie);

        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetPartnerNameFromToken  res.data.status = " + res.data.status);
                //console.log(" In GetPartnerNameFromToken  res.data.message = " + res.data.message);
                setparntername(JSON.parse(res.data.message).nom);



                if (JSON.parse(res.data.message).pack_service) {
                    setpartnerPackService(JSON.parse(res.data.message).pack_service);
                }
                else {
                    setpartnerPackService("Aucun Service");
                }

                if (JSON.parse(res.data.message).nb_formation) {
                    setparnternbformation(JSON.parse(res.data.message).nb_formation);
                }
                else {
                    setparnternbformation("");
                }

                if (JSON.parse(res.data.message).ressource_humaine_id) {
                    setconntected_employee_id(JSON.parse(res.data.message).ressource_humaine_id);
                }
                else {
                    setconntected_employee_id("");
                }


                var partner_employee_user_name = "Inconnu";

                if (JSON.parse(res.data.message).contact_nom) {
                    partner_employee_user_name = (JSON.parse(res.data.message).contact_nom);
                }


                if (JSON.parse(res.data.message).contact_prenom) {
                    partner_employee_user_name = partner_employee_user_name + " " + (JSON.parse(res.data.message).contact_prenom);
                }

                setparnter_user_employee(partner_employee_user_name);


                if (JSON.parse(res.data.message).ispending) {
                    setpartnerstatus(JSON.parse(res.data.message).ispending);
                }

                if (JSON.parse(res.data.message).firstconnexion &&
                    String(JSON.parse(res.data.message).firstconnexion) === "0") {
                    setfirstConnexion("0");
                } else {
                    setfirstConnexion("1");
                }

                if (JSON.parse(res.data.message).is_partner_admin_account &&
                    String(JSON.parse(res.data.message).is_partner_admin_account) === "1") {
                    setpart_is_partner_admin_account("1");
                } else {
                    setpart_is_partner_admin_account("0");
                }

            }
            else {
                //console.log(" In GetPartnerNameFromToken  res.data.status = " + res.data.status);
                //console.log(" In GetPartnerNameFromToken  res.data.message = " + res.data.message);

            }


        }).catch((error) => {
            console.warn('In GetPartnerNameFromToken Not good man :( mysearchtext = ');

        })
    }

    function sendImage(e) {
        var formData = new FormData();

        formData.append('File', isimgSelectedfile);
        formData.append("token", stored_part);
        formData.append("type", "partner");
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/recordImage/",
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);
                setisimgSelected(false);

            })
            .catch((error) => {
                console.error('Error:');
                setisimgSelected(true);
            });
    };




    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };

    function alertactivecompte() {
        alert(" Vous devez ajouter un mode de Paiement pour acceder à cette fonctionnalité");
    }

    function ValideFirstConnexion() {
        setfirstConnexion("");
        setmenu("abonnement");
    }

    const [formation_file_name, setformation_file_name] = useState();
    const [CheckSalesOrder_api, setCheckSalesOrder_api] = useState("");
    const [CheckSalesOrder_message, setCheckSalesOrder_message] = useState("");
    function CheckSalesOrder(event) {

        var form = new FormData();

        formData.append("token", stored_part);
        formData.append("orderid", orderid);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CheckSalesOrder/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                setCheckSalesOrder_api("true")
            }
            else {
                setCheckSalesOrder_api("false")
                setCheckSalesOrder_message(String(res.data.message))
            }


        }).catch((error) => {
            //console.log('In CheckSalesOrder ', error);
            setCheckSalesOrder_api("false")

        })
    }

    const [isLoading, setLoading] = useState();


    const [GetPartnerLMSData_api, setGetPartnerLMSData_api] = useState();
    const [GetPartnerLMSData_result, setGetPartnerLMSData_result] = useState();
    const [GetPartnerLMSData_message, setGetPartnerLMSData_message] = useState();

    const [partner_lms_url, setpartner_lms_url] = useState();
    const [has_partner_lms_url, sethas_partner_lms_url] = useState();

    function GetPartnerLMSData(event) {

        var form = new FormData();

        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/LMS_Get_Partner_Data/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetPartnerLMSData  res.data.status = " + res.data.status);
                //console.log(" In GetPartnerLMSData  res.data.message = " + res.data.message);
                setGetPartnerLMSData_api("true");
                setGetPartnerLMSData_result(res.data.message);


                var my_lms_url = "";
                if (JSON.parse(res.data.message).lms_account &&
                    String(JSON.parse(res.data.message).lms_account) === "1") {
                    sethas_partner_lms_url(JSON.parse(res.data.message).lms_account);
                    my_lms_url = (JSON.parse(res.data.message).lms_virtualhost_url) + "user_portal.php?recid=" + encodeURIComponent(JSON.parse(res.data.message).lms_part_recid) +
                        "&mysytoken=" + encodeURIComponent(JSON.parse(res.data.message).lms_pwd_mask);

                    //console.log(" ### LMS url = ", my_lms_url);
                    setpartner_lms_url(my_lms_url);
                }

            }
            else {
                setGetPartnerLMSData_api("false");
                setGetPartnerLMSData_message(res.data.message);
            }


        }).catch((error) => {
            console.warn('In GetPartnerLMSData Not good man :( mysearchtext = ', error);
            setGetPartnerLMSData_api("false");

        })
    }

    const [deplie_hebergement, setdeplie_hebergement] = useState();
    function ftion_deplie_hebergement() {
        if (String(deplie_hebergement) === "1") {
            setdeplie_hebergement();
        } else {
            setdeplie_hebergement("1");
        }

    }
    const [deplie_mes_clients, setdeplie_mes_clients] = useState();
    function ftion_deplie_mes_clients() {
        if (String(deplie_mes_clients) === "1") {
            setdeplie_mes_clients();
        } else {
            setdeplie_mes_clients("1");
        }

    }

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_acces_insuffisant(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [left_menu, setleft_menu] = React.useState(true);

    const open_close_left_menu = () => {
        if (left_menu === true)
            setleft_menu(false);
        else
            setleft_menu(true);

    };


    return (

        <div className="partner">


            <Helmet>
                <title>Partenaires editeurs formations</title>
                <meta name="description" content="backoffice partenaire editeurs formation" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />



            <div className="div_mobile" id="div_mobile">
                {isLoading && <div className="loader-container">
                    <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
                </div>}
                <Dialog
                    open={Dialog_1_open}
                    onClose={Dialog_1_handleClose}

                >

                    <DialogTitle>MySy Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <ImWarning />   {Dialog_1_message}
                        </DialogContentText>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                    </DialogActions>
                </Dialog>

                {left_menu === true && <div className="sub_dib_mobile"  >
                    <div className="div_gauche" id="div_gauche">
                        <nav className="not_display_mobile">
                            {left_menu === true && <div style={{ "cursor": "pointer", textAlign: "left" }} onClick={open_close_left_menu}>
                                <Tooltip className="tooltip_css" id="tooltip_id_0" />
                                <a data-tooltip-id="tooltip_id_0" data-tooltip-html="Réduire le menu">
                                    <PiSlideshowFill /> &nbsp; Réduire
                                </a>
                            </div>}
                            {left_menu === false && <div style={{ "cursor": "pointer", textAlign: "center" }}>
                                <Tooltip className="tooltip_css" id="tooltip_id_1" />
                                <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Agrandir le menu">
                                    <PiSlideshowThin onClick={open_close_left_menu} />
                                </a>
                            </div>}
                        </nav>

                        <img class="img-responsive img_user" src={userimgprofil} />


                        {!isimgSelected && String(part_is_partner_admin_account) === "1" && <div className="nonlable">
                            <input type="file" id="files" accept="image/*" onChange={imgchangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} />
                            <label for="files" className="label">Changer image</label>


                        </div>}

                        {isimgSelected && <div className="nonlable">
                            <Button variant="outlined" onClick={sendImage} className="enreg">Enregistrer</Button>
                        </div>}


                        {String(part_is_partner_admin_account) === "1" && <div className="nom_prenom">   {parntername && <b> {parntername}&nbsp;-&nbsp;{String(partnerPackService).toUpperCase()} ({parnternbformation})</b>}
                            {!parntername && <b> Partenaire </b>} </div>}


                        {String(part_is_partner_admin_account) !== "1" && <div className="nom_prenom">   {parnter_user_employee}
                            {!parntername && <b> Utilisateur </b>} </div>}



                        <div class="separator"> Mon Compte </div>


                        {String(menu) !== "infopartner" && <div className="sousmenu" onClick={PersonnalData}>   <FcHome className="icone_menu" /> MES INFORMATIONS</div>}
                        {String(menu) === "infopartner" && <div className="sousmenu_selected" onClick={PersonnalData}  >  <FcHome className="icone_menu" />  MES INFORMATIONS</div>}
                        <hr className="my_hr" />


                        {String(menu) !== "mon_tableau_de_bord" && <div className="sousmenu" onClick={mon_tableau_de_bord_func}>   <AiOutlineDashboard className="icone_menu" /> MON TABLEAU DE BORD</div>}
                        {String(menu) === "mon_tableau_de_bord" && <div className="sousmenu_selected" onClick={mon_tableau_de_bord_func}  >  <AiOutlineDashboard className="icone_menu" /> MON TABLEAU DE BORD</div>}
                        <hr className="my_hr" />


                        {String(menu) !== "module_agenda" && <div className="sousmenu" onClick={module_agenda_func}>   <FcCalendar className="icone_menu" /> PLANNING </div>}
                        {String(menu) === "module_agenda" && <div className="sousmenu_selected" onClick={module_agenda_func}  >  <FcCalendar className="icone_menu" /> PLANNING</div>}
                        <hr className="my_hr" />

                        {String(menu) !== "abonnement" && <div className="sousmenu" onClick={Abonnement_func} > <FcKey className="icone_menu" /> ABONNEMENT </div>}
                        {String(menu) === "abonnement" && <div className="sousmenu_selected" onClick={Abonnement_func} >  <FcKey className="icone_menu" /> ABONNEMENT </div>}
                        <hr className="my_hr" />

                        {String(menu) !== "facture" && <div className="sousmenu" onClick={DisplayFacure}  ><FcFeedback className="icone_menu" /> FACTURE MYSY</div>}
                        {String(menu) === "facture" && <div className="sousmenu_selected" onClick={DisplayFacure}  > <FcFeedback className="icone_menu" />FACTURE MYSY</div>}
                        <hr className="my_hr" />

                        <div class="separator"> Mes Formations </div>

                        {/*
                        {String(menu) !== "creation" && <div className="sousmenu" onClick={CreateTraining}> <FcAddDatabase className="icone_menu" /> CREER FORMATION</div>}
                        {String(menu) === "creation" && <div className="sousmenu_selected" onClick={CreateTraining}> <FcAddDatabase className="icone_menu" /> CREER FORMATION</div>}
                        <hr className="my_hr" />
    */}

                        {String(menu) !== "affichage" && <div className="sousmenu" onClick={DiplaytrainingList}  > <FcList className="icone_menu" /> MES FORMATIONS</div>}
                        {String(menu) === "affichage" && <div className="sousmenu_selected" onClick={DiplaytrainingList}  ><FcList className="icone_menu" />  MES FORMATIONS</div>}
                        <hr className="my_hr" />

                        {String(menu) !== "mes_sessions" && <div className="sousmenu" onClick={DiplaySessionList}  > <FcFlowChart className="icone_menu" /> MES SESSIONS </div>}
                        {String(menu) === "mes_sessions" && <div className="sousmenu_selected" onClick={DiplaySessionList}  ><FcFlowChart className="icone_menu" />  MES SESSIONS</div>}
                        <hr className="my_hr" />

                        {String(menu) !== "mes_stagiaires" && <div className="sousmenu" onClick={DiplayStagiaireList}  > <FcReading className="icone_menu" /> MES STAGIAIRES </div>}
                        {String(menu) === "mes_stagiaires" && <div className="sousmenu_selected" onClick={DiplayStagiaireList}  ><FcReading className="icone_menu" />  MES STAGIAIRES</div>}
                        <hr className="my_hr" />

                        {String(menu) !== "mes_apprenants" && <div className="sousmenu" onClick={mes_apprenants_func}  > <PiStudent className="icone_menu" /> MES APPRENANTS </div>}
                        {String(menu) === "mes_apprenants" && <div className="sousmenu_selected" onClick={mes_apprenants_func}  ><PiStudent className="icone_menu" />  MES APPRENANTS</div>}
                        <hr className="my_hr" />


                        {String(menu) !== "mes_employes" && <div className="sousmenu" onClick={DisplayeEmployeList}  > <GiTeacher className="icone_menu" /> MES EMPLOYES </div>}
                        {String(menu) === "mes_employes" && <div className="sousmenu_selected" onClick={DisplayeEmployeList}  ><GiTeacher className="icone_menu" />  MES EMPLOYES</div>}
                        <hr className="my_hr" />

                        {String(menu) !== "mon_materiel" && <div className="sousmenu" onClick={DisplayeMaterielList}  > <BsTools className="icone_menu" /> MON MATERIEL </div>}
                        {String(menu) === "mon_materiel" && <div className="sousmenu_selected" onClick={DisplayeMaterielList}  ><BsTools className="icone_menu" />  MON MATERIEL </div>}
                        <hr className="my_hr" />



                        {String(menu) !== "statistique" && <div className="sousmenu" onClick={DisplayStat}> <FcBullish className="icone_menu" /> STATISTIQUES</div>}
                        {String(menu) === "statistique" && <div className="sousmenu_selected" onClick={DisplayStat}> <FcBullish className="icone_menu" /> STATISTIQUES</div>}
                        <hr className="my_hr" />




                        <div class="separator"> Mes Clients </div>

                        <div>
                            <hr className="my_hr" />
                            {String(menu) !== "mes_clients" && <div className="sousmenu" onClick={mes_clients_func}>&nbsp;&nbsp;<FcBusinesswoman className="icone_menu" />&nbsp;MES CLIENTS</div>}
                            {String(menu) === "mes_clients" && <div className="sousmenu_selected" onClick={mes_clients_func}> &nbsp;&nbsp; <FcBusinesswoman className="icone_menu" />&nbsp;MES CLIENTS</div>}
                        </div>

                        <div>
                            <hr className="my_hr" />
                            {String(menu) !== "pricing" && <div className="sousmenu" onClick={pricing_func} >&nbsp;&nbsp;<FcCurrencyExchange className="icone_menu" />&nbsp; MES PRIX CLIENT</div>}
                            {String(menu) === "pricing" && <div className="sousmenu_selected" onClick={pricing_func} > &nbsp;&nbsp;<FcCurrencyExchange className="icone_menu" />&nbsp;MES PRIX CLIENT</div>}
                        </div>

                        <div>
                            <hr className="my_hr" />
                            {String(menu) !== "mes_cmd" && <div className="sousmenu" onClick={mes_cmd_func}>&nbsp;&nbsp;<FcCollect className="icone_menu" />&nbsp;MES COMMANDES & DEVIS</div>}
                            {String(menu) === "mes_cmd" && <div className="sousmenu_selected" onClick={mes_cmd_func}> &nbsp;&nbsp; <FcCollect className="icone_menu" />&nbsp;MES COMMANDES & DEVIS</div>}
                        </div>

                        <div>
                            <hr className="my_hr" />
                            {String(menu) !== "factures_client" && <div className="sousmenu" onClick={mes_factures_client_func}>&nbsp;&nbsp;<FcKindle className="icone_menu" />&nbsp;MES FACTURES</div>}
                            {String(menu) === "factures_client" && <div className="sousmenu_selected" onClick={mes_factures_client_func}> &nbsp;&nbsp; <FcCollect FcKindle="icone_menu" />&nbsp;MES FACTURES</div>}
                        </div>

                        <div>
                            <hr className="my_hr" />
                            {String(menu) !== "prix_achat" && <div className="sousmenu" onClick={prix_achat_func}>&nbsp;&nbsp;<BiPurchaseTag className="icone_menu" />&nbsp;MES PRIX ACHAT</div>}
                            {String(menu) === "prix_achat" && <div className="sousmenu_selected" onClick={prix_achat_func}> &nbsp;&nbsp; <BiPurchaseTag FcKindle="icone_menu" />&nbsp;MES PRIX ACHAT</div>}
                        </div>


                        <div class="separator"> Espace E-Learning </div>


                        {String(has_partner_lms_url) === "1" && <div className="sousmenu" onClick={(e) => {
                            e.preventDefault();
                            window.open(
                                partner_lms_url,
                                '_blank'
                            );
                        }}>&nbsp;&nbsp;<FcVideoCall className="icone_menu" />&nbsp;MON HEBERGEMENT </div>}

                        {String(has_partner_lms_url) === "1" && <div>
                            <hr className="my_hr" />
                            {String(menu) !== "statistique" && <div className="sousmenu" onClick={DisplaySetUpLMS}>&nbsp;&nbsp;<FcServices className="icone_menu" />&nbsp;CONFIGURATION</div>}
                            {String(menu) === "statistique" && <div className="sousmenu_hebergement_lms" onClick={DisplaySetUpLMS}> &nbsp;&nbsp; <FcServices className="icone_menu" />&nbsp;CONFIGURATION</div>}
                        </div>}

                        <div class="separator"> Configuration </div>

                        <Dropdown title="Configuration Technique &nbsp;&nbsp;"
                            onOpen={(event) => {

                            }}

                            onClose={(event) => {


                            }}

                        >
                            <Dropdown.Item >
                                {String(menu) !== "config_document" && <div className="sousmenu" onClick={config_document_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;MES DOCUMENTS</div>}
                                {String(menu) === "config_document" && <div className="sousmenu_selected" onClick={config_document_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;MES DOCUMENTS</div>}
                            </Dropdown.Item>

                            <Dropdown.Item>
                                {String(menu) !== "config_champ_specific" && <div className="sousmenu" onClick={config_specfic_fields_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;CHAMP SPECIFIQUE</div>}
                                {String(menu) === "config_champ_specific" && <div className="sousmenu_selected" onClick={config_specfic_fields_func}> &nbsp;&nbsp; <FcFinePrint className="icone_menu" />&nbsp;CHAMP SPECIFIQUE</div>}
                            </Dropdown.Item>
                            <Dropdown.Item>
                                {String(menu) !== "config_technique" && <div className="sousmenu" onClick={config_technique_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;TECHNIQUE</div>}
                                {String(menu) === "config_technique" && <div className="sousmenu_selected" onClick={config_technique_func}> &nbsp;&nbsp; <FcFinePrint className="icone_menu" />&nbsp;TECHNIQUE</div>}

                            </Dropdown.Item>

                            <Dropdown.Item>
                                {String(menu) !== "config_session_steps" && <div className="sousmenu" onClick={config_session_steps_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;SESSION ETAPE</div>}
                                {String(menu) === "config_session_steps" && <div className="sousmenu_selected" onClick={config_session_steps_func}> &nbsp;&nbsp; <FcFinePrint className="icone_menu" />&nbsp;SESSION ETAPE</div>}

                            </Dropdown.Item>

                            <Dropdown.Item>
                                {String(menu) !== "config_jours_heures" && <div className="sousmenu" onClick={config_jours_travaille_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;JOURS & HEURES</div>}
                                {String(menu) === "config_jours_heures" && <div className="sousmenu_selected" onClick={config_jours_travaille_func}> &nbsp;&nbsp; <FcFinePrint className="icone_menu" />&nbsp;JOURS & HEURES</div>}

                            </Dropdown.Item>



                        </Dropdown>




                        <Dropdown title="Tableaux de bord &nbsp;&nbsp;"
                            onOpen={(event) => {

                            }}

                            onClose={(event) => {


                            }}

                        >
                            <Dropdown.Item >
                                {String(menu) !== "dashbord_formation" && <div className="sousmenu" onClick={config_dashbord_formation_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;FORMATION</div>}
                                {String(menu) === "dashbord_formation" && <div className="sousmenu_selected" onClick={config_dashbord_formation_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;FORMATION</div>}
                            </Dropdown.Item>

                            <Dropdown.Item>
                                {String(menu) !== "dashbord_session" && <div className="sousmenu" onClick={config_dashbord_session_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;SESSION</div>}
                                {String(menu) === "dashbord_session" && <div className="sousmenu_selected" onClick={config_dashbord_session_func}> &nbsp;&nbsp; <FcFinePrint className="icone_menu" />&nbsp;SESSION</div>}
                            </Dropdown.Item>

                            <Dropdown.Item>
                                {String(menu) !== "dashbord_ressources_humaines" && <div className="sousmenu" onClick={config_dashbord_ressources_humaines_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;RES. HUMAINES</div>}
                                {String(menu) === "dashbord_ressources_humaines" && <div className="sousmenu_selected" onClick={config_dashbord_ressources_humaines_func}> &nbsp;&nbsp;  <FcFinePrint className="icone_menu" />&nbsp;RES. HUMAINES</div>}
                            </Dropdown.Item>

                            <Dropdown.Item>
                                {String(menu) !== "dashbord_inscriptions" && <div className="sousmenu" onClick={config_dashbord_inscriptions_func}>&nbsp;&nbsp;<FcFinePrint className="icone_menu" />&nbsp;INSCRIPTIONS</div>}
                                {String(menu) === "dashbord_inscriptions" && <div className="sousmenu_selected" onClick={config_dashbord_inscriptions_func}> &nbsp;&nbsp;  <FcFinePrint className="icone_menu" />&nbsp;INSCRIPTIONS</div>}
                            </Dropdown.Item>




                        </Dropdown>


                    </div>
                </div>}
                {left_menu === true && <div>
                    {String(firstConnexion) === "1" && String(part_is_partner_admin_account) === "1" &&
                        <div className="div_droite firstConnexion">
                            <div className="firstConnexion_text">
                                <b>Cher partenaire, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                Nous sommes ravis de vous accueillir dans notre aventure MySy.
                                Pour commencer à publier vos formations, veuillez choisir une formule d'abonnement en cliquant <Button onClick={ValideFirstConnexion} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; &nbsp; ici</Button><br />
                                &nbsp; <br /> Merci.
                            </div>

                        </div>}

                    {String(firstConnexion) === "1" && String(part_is_partner_admin_account) !== "1" &&
                        <div className="div_droite firstConnexion">
                            <div className="firstConnexion_text">
                                <b>Cher Utilisateur, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                Nous sommes ravis de vous accueillir dans notre aventure MySy. Nous vous invitons à modifier votre mot de passe à partir du menu <i>"MES INFIRMATIONS"</i> ==&gt; Bouton <i>"SECURITE"</i>  <br /><br />
                                <Button onClick={(e) => setfirstConnexion()} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; Fermer</Button><br />
                                &nbsp; <br /> Merci.
                            </div>

                        </div>}


                    {String(myApiResponse) === String("false") && <div className="div_droite">
                        <div className="koUpdateData"> Impossible de contacter  l'hote distant. Merci de contacter votre administrateur  </div>
                    </div>

                    }

                    {String(menu) === "affichage" &&
                        <div className="div_droite">
                            <PartnerTranings ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                class_external_code={orderid} class_internal_url={packs} />
                        </div>
                    }




                    {String(menu) === "checkout" && <div className="div_droite">
                        <Button onClick={"returntoorder"} className="btn_retour_ps"> Retour aux produits & Services </Button>
                        <CheckOut packs={location.state.new_pack} periodicity={location.state.period} mypackprice={location.state.price} />
                    </div>

                    }

                    {String(menu) === "creation" &&
                        <div className="div_droite">


                            <div className="parter_div_droite_haut">
                                {String(apiexcelimportmessage) === String("true") &&
                                    <div className="parter_div_filtrer_row okUpdateData">
                                        {excelimportmessage}<br />&nbsp;<br />

                                    </div>

                                }
                                {String(apiexcelimportmessage) === String("false") &&
                                    <div className="parter_div_filtrer_row koUpdateData">
                                        Impossible de traiter le fichier <br />
                                        <u> <b> {excelimportmessage} </b></u>
                                        <br />&nbsp;<br />
                                    </div>

                                }

                                {String(apiexcelimportmessage) === String("-1") &&
                                    <div className="parter_div_filtrer_row koUpdateData">
                                        Impossible de traiter le fichier <br />&nbsp;<br />
                                    </div>

                                }


                                <div className="parter_div_filtrer_row">
                                    <div>


                                        {String(partnerstatus) === String("0") && <div className="parter_div_filtrer_row_gauche">

                                            <Button variant="outlined" onClick={changeHandler2} className="detail_class_submenu" style={{
                                                "background": "#c8cfd5",
                                                "minWidth": "15rem", "color": "black", "width": "auto"
                                            }}
                                                id='menu_import_formation' name='menu_import_formation'>Choisir un fichier et importer des formations &nbsp; <SendIcon />
                                            </Button><br />
                                            {formation_file_name && <nav className="okUpdateData"><i>{formation_file_name}</i></nav>}
                                            <input type="file"
                                                accept=".csv"
                                                ref={hiddenFileInput_formation}
                                                style={{ display: 'none' }}
                                                name="liste_formation_file"
                                                onChange={handleSubmission}
                                            />



                                            <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                            <br />

                                            {/* <input type="file" name="file" accept=".csv" onChange={changeHandler} /> <br /> */}
                                            <br />&nbsp;
                                            <div className="import_excel_text">

                                                <nav className="titre1">  Comment utiliser le fichier ? </nav>
                                                <nav style={{ "color": "orange" }}>
                                                    Dans le fichier à importer, seules les colonnes suivantes sont obligatoires  :
                                                    <ul>
                                                        <li>external_code : C'est le code de la formation chez vous, editeur de la formation </li>
                                                        <li> titre : Le titre de la formation </li>
                                                        <li> domaine : Le domaine au quel est rattaché la formation</li>
                                                        <li> description : La descriptin de la formation</li>
                                                    </ul>
                                                </nav>
                                                Pour le reste, <br />
                                                <ul>
                                                    <li> Il s'agit d'un fichier csv. Les colonnes sont separées par des ";"</li>
                                                    <li> A l'interieur d'une même colonne, les informations sont separées par des ";"     </li>
                                                    <li> Champ 'Metier' :  les valeurs acceptées sont : IT, RH, vente, dev_perso, Graphisme, Management, Digital, Office, Autre</li>
                                                    <li> ...</li>
                                                </ul>
                                                <a href='/sample/MySy_Import_formation_csv.pdf' download>Télécharger le guide complet pour plus d'informations</a><br />
                                            </div>
                                            <div style={{ "width": "50%" }}> &nbsp; </div>





                                        </div>}

                                        {String(partnerstatus) !== String("0") && <div className="parter_div_filtrer_row_gauche">


                                            Import excel d'une formation <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                            <br />

                                            <input type="file" name="file" onChange={""} accept=".csv" />


                                            <br />
                                            <div align="right">
                                                <Button onClick={alertactivecompte} variant="contained" endIcon={<SendIcon />}> Importer </Button>
                                            </div>

                                        </div>}
                                    </div>

                                    <br />

                                </div>
                            </div>
                            {/* <div className="parter_div_droite_bas">
                                <div className="titre1_bis"> Création manuelle d'une formation </div>

                                <TrainingForm mytrainingclass={{ 'ref': '', 'dist': 'Oui', 'plus_produit': '0' }} ispending={partnerstatus}
                                    currentpack={String(partnerPackService).toUpperCase()} />
                            </div>*/}

                        </div>
                    }


                    {String(menu) === "infopartner" &&
                        <div className="div_droite">

                            <PartnerInfo check_user_acces_right={check_user_acces_right} />

                        </div>
                    }

                    {String(menu) === "statistique" &&
                        <div className="div_droite">
                            <PartenairStat />
                        </div>
                    }

                    {String(menu) === "setuplms" &&
                        <div className="div_droite">
                            <HebergementLms conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("lms", "read")} write_access={check_user_acces_right("lms", "write")} />
                        </div>
                    }


                    {String(menu) === "mes_sessions" &&
                        <div className="div_droite">

                            <DisplayPartnerSession conntected_employee_id={conntected_employee_id} object_key={orderid} class_internal_url={packs}
                                read_access={check_user_acces_right("session", "read")} write_access={check_user_acces_right("session", "write")} />
                        </div>
                    }

                    {String(menu) === "mes_employes" &&
                        <div className="div_droite">
                            <Employes conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("employe", "read")} write_access={check_user_acces_right("employe", "write")} />
                        </div>
                    }

                    {String(menu) === "factures_client" &&
                        <div className="div_droite">
                            <Factures_Client conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("facture_client", "read")} write_access={check_user_acces_right("facture_client", "write")} />
                        </div>
                    }

                    {String(menu) === "prix_achat" &&
                        <div className="div_droite">
                            <Partner_Prix_Achat conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix_achat", "read")} write_access={check_user_acces_right("prix_achat", "write")} />
                        </div>
                    }


                    {String(menu) === "mon_materiel" &&
                        <div className="div_droite">
                            <Materiels conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")} write_access={check_user_acces_right("materiel", "write")} />
                        </div>
                    }

                    {String(menu) === "mes_cmd" &&
                        <div className="div_droite">
                            <Partner_Commande conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("commande", "read")} write_access={check_user_acces_right("commande", "write")} />
                        </div>
                    }


                    {String(menu) === "facture" && <div className="div_droite">

                        <Facture currentpack={partnerPackService} conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("compte_partner", "read")} write_access={check_user_acces_right("compte_partner", "write")} />
                    </div>
                    }

                    {String(menu) === "pricing" && <div className="div_droite">

                        <Pricing conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix", "read")} write_access={check_user_acces_right("prix", "write")} />
                    </div>
                    }

                    {String(menu) === "mes_stagiaires" && <div className="div_droite">

                        <DisplayPartnerStagiaires conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                            read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")} />
                    </div>
                    }

                    {String(menu) === "mes_apprenants" && <div className="div_droite">

                        <Apprenant conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                            read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")} />
                    </div>
                    }


                    {String(menu) === "abonnement" && <div className="div_droite">

                        <Abonnement currentpack={partnerPackService} conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("abonnement", "read")} write_access={check_user_acces_right("abonnement", "write")} />
                    </div>
                    }

                    {String(menu) === "ordervalide" && orderid && packs && <div className="div_droite">
                        <OrderConfirmation num_cmd={orderid} packs={String(packs).toLocaleUpperCase()} />
                    </div>
                    }

                    {String(menu) === "mes_clients" && <div className="div_droite">

                        <Partner_Client conntected_employee_id={conntected_employee_id} object_key={orderid}
                            read_access={check_user_acces_right("client", "read")} write_access={check_user_acces_right("client", "write")} />
                    </div>
                    }

                    {String(menu) === "config_document" && <div className="div_droite">

                        <Config_Documents conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }

                    {String(menu) === "config_technique" && <div className="div_droite">

                        <Configuration_Technique conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }


                    {String(menu) === "config_session_steps" && <div className="div_droite">

                        <Partner_Configuration_Session_Steps conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }



                    {String(menu) === "config_jours_heures" && <div className="div_droite">

                        <Partner_Configuration_Jours_Travail conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }

                    {String(menu) === "config_champ_specific" && <div className="div_droite">

                        <Config_Champs_Personnalise conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }


                    {String(menu) === "module_agenda" && <div className="div_droite">

                        <Module_Agenda conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("module_agenda", "read")} write_access={check_user_acces_right("module_agenda", "write")} />
                    </div>
                    }


                    {String(menu) === "dashbord_session" && <div className="div_droite">

                        <Dashbord_Session conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }

                    {String(menu) === "dashbord_formation" && <div className="div_droite">

                        <Dashbord_Formation conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }

                    {String(menu) === "dashbord_ressources_humaines" && <div className="div_droite">

                        <Dashbord_Ressources_Humaines conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }

                    {String(menu) === "dashbord_inscriptions" && <div className="div_droite">

                        <Dashbord_Inscriptions conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }

                    {String(menu) === "mon_tableau_de_bord" && <div className="div_droite">

                        <Mon_Tableau_De_Bord conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />


                    </div>
                    }


                </div>}


                {/* div_gauche div_gauche_reduit */}
                {left_menu === false && <div className="sub_dib_mobile"  >
                    <div className="div_gauche div_gauche_reduit" id="div_gauche">

                        <nav className="not_display_mobile">
                            {left_menu === true && <div style={{ "cursor": "pointer", textAlign: "left" }} onClick={open_close_left_menu}>
                                <Tooltip className="tooltip_css" id="tooltip_id_0" />
                                <a data-tooltip-id="tooltip_id_0" data-tooltip-html="Réduire le menu">
                                    <PiSlideshowFill />  &nbsp; Réduire
                                </a>
                            </div>}
                            {left_menu === false && <div style={{ "cursor": "pointer", textAlign: "center" }}>
                                <Tooltip className="tooltip_css" id="tooltip_id_1" />
                                <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Agrandir le menu">
                                    <PiSlideshowThin onClick={open_close_left_menu} />
                                </a>
                            </div>}
                        </nav>

                        <div> <hr /> </div>

                        <Tooltip className="tooltip_css" id="tooltip_id_2" />
                        <a data-tooltip-id="tooltip_id_2" data-tooltip-html="MES INFORMATIONS">
                            {String(menu) !== "infopartner" && <div className="sousmenu" onClick={PersonnalData}>   <FcHome className="icone_menu" /> &nbsp; </div>}
                            {String(menu) === "infopartner" && <div className="sousmenu_selected" onClick={PersonnalData}  >  <FcHome className="icone_menu" />  &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_3" />
                        <a data-tooltip-id="tooltip_id_3" data-tooltip-html="MON TABLEAU DE BORD">
                            {String(menu) !== "mon_tableau_de_bord" && <div className="sousmenu" onClick={mon_tableau_de_bord_func}>   <AiOutlineDashboard className="icone_menu" />&nbsp;</div>}
                            {String(menu) === "mon_tableau_de_bord" && <div className="sousmenu_selected" onClick={mon_tableau_de_bord_func}  >  <AiOutlineDashboard className="icone_menu" /> &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_4" />
                        <a data-tooltip-id="tooltip_id_4" data-tooltip-html="PLANNING">
                            {String(menu) !== "module_agenda" && <div className="sousmenu" onClick={module_agenda_func}>   <FcCalendar className="icone_menu" /> &nbsp; </div>}
                            {String(menu) === "module_agenda" && <div className="sousmenu_selected" onClick={module_agenda_func}  >  <FcCalendar className="icone_menu" />&nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_5" />
                        <a data-tooltip-id="tooltip_id_5" data-tooltip-html="ABONNEMENT">
                            {String(menu) !== "abonnement" && <div className="sousmenu" onClick={Abonnement_func} > <FcKey className="icone_menu" />&nbsp; </div>}
                            {String(menu) === "abonnement" && <div className="sousmenu_selected" onClick={Abonnement_func} >  <FcKey className="icone_menu" /> &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_6" />
                        <a data-tooltip-id="tooltip_id_6" data-tooltip-html="FACTURE MYSY">
                            {String(menu) !== "facture" && <div className="sousmenu" onClick={DisplayFacure}  ><FcFeedback className="icone_menu" /> &nbsp;</div>}
                            {String(menu) === "facture" && <div className="sousmenu_selected" onClick={DisplayFacure}  > <FcFeedback className="icone_menu" />&nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <div> <hr /> </div>

                        {/* <Tooltip className="tooltip_css" id="tooltip_id_7" />
                        <a data-tooltip-id="tooltip_id_7" data-tooltip-html="CREER FORMATION">
                            {String(menu) !== "creation" && <div className="sousmenu" onClick={CreateTraining}> <FcAddDatabase className="icone_menu" /> &nbsp;</div>}
                            {String(menu) === "creation" && <div className="sousmenu_selected" onClick={CreateTraining}> <FcAddDatabase className="icone_menu" /> </div>}
                        </a>
                        <hr className="my_hr" />*/}

                        <Tooltip className="tooltip_css" id="tooltip_id_8" />
                        <a data-tooltip-id="tooltip_id_8" data-tooltip-html="MES FORMATIONS">
                            {String(menu) !== "affichage" && <div className="sousmenu" onClick={DiplaytrainingList}  > <FcList className="icone_menu" /> &nbsp;</div>}
                            {String(menu) === "affichage" && <div className="sousmenu_selected" onClick={DiplaytrainingList}  ><FcList className="icone_menu" /> &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_9" />
                        <a data-tooltip-id="tooltip_id_9" data-tooltip-html="MES SESSIONS">
                            {String(menu) !== "mes_sessions" && <div className="sousmenu" onClick={DiplaySessionList}  > <FcFlowChart className="icone_menu" /> &nbsp; </div>}
                            {String(menu) === "mes_sessions" && <div className="sousmenu_selected" onClick={DiplaySessionList}  ><FcFlowChart className="icone_menu" /> &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_10" />
                        <a data-tooltip-id="tooltip_id_10" data-tooltip-html="MES STAGIAIRES">
                            {String(menu) !== "mes_stagiaires" && <div className="sousmenu" onClick={DiplayStagiaireList}  > <FcReading className="icone_menu" />&nbsp; </div>}
                            {String(menu) === "mes_stagiaires" && <div className="sousmenu_selected" onClick={DiplayStagiaireList}  ><FcReading className="icone_menu" />&nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_11" />
                        <a data-tooltip-id="tooltip_id_11" data-tooltip-html="MES APPRENANTS">
                            {String(menu) !== "mes_apprenants" && <div className="sousmenu" onClick={mes_apprenants_func}  > <PiStudent className="icone_menu" />&nbsp; </div>}
                            {String(menu) === "mes_apprenants" && <div className="sousmenu_selected" onClick={mes_apprenants_func}  ><PiStudent className="icone_menu" /> &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_12" />
                        <a data-tooltip-id="tooltip_id_12" data-tooltip-html="MES EMPLOYES">
                            {String(menu) !== "mes_employes" && <div className="sousmenu" onClick={DisplayeEmployeList}  > <GiTeacher className="icone_menu" />&nbsp; </div>}
                            {String(menu) === "mes_employes" && <div className="sousmenu_selected" onClick={DisplayeEmployeList}  ><GiTeacher className="icone_menu" /> &nbsp;</div>}
                        </a>
                        <hr className="my_hr" />

                        <Tooltip className="tooltip_css" id="tooltip_id_13" />
                        <a data-tooltip-id="tooltip_id_13" data-tooltip-html=" MON MATERIEL">
                            {String(menu) !== "mon_materiel" && <div className="sousmenu" onClick={DisplayeMaterielList}  > <BsTools className="icone_menu" />&nbsp; </div>}
                            {String(menu) === "mon_materiel" && <div className="sousmenu_selected" onClick={DisplayeMaterielList}  ><BsTools className="icone_menu" />&nbsp; </div>}
                        </a>
                        <hr className="my_hr" />


                        <Tooltip className="tooltip_css" id="tooltip_id_14" />
                        <a data-tooltip-id="tooltip_id_14" data-tooltip-html="STATISTIQUES">
                            {String(menu) !== "statistique" && <div className="sousmenu" onClick={DisplayStat}> <FcBullish className="icone_menu" />&nbsp;</div>}
                            {String(menu) === "statistique" && <div className="sousmenu_selected" onClick={DisplayStat}> <FcBullish className="icone_menu" />&nbsp;</div>}
                        </a>
                        <hr className="my_hr" />


                        <div>  <hr /> </div>

                        <div>

                            <hr className="my_hr" />
                            <Tooltip className="tooltip_css" id="tooltip_id_15" />
                            <a data-tooltip-id="tooltip_id_15" data-tooltip-html="MES CLIENTS">
                                {String(menu) !== "mes_clients" && <div className="sousmenu" onClick={mes_clients_func}><FcBusinesswoman className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "mes_clients" && <div className="sousmenu_selected" onClick={mes_clients_func}> <FcBusinesswoman className="icone_menu" />&nbsp;</div>}
                            </a>
                        </div>

                        <div>
                            <hr className="my_hr" />
                            <Tooltip className="tooltip_css" id="tooltip_id_16" />
                            <a data-tooltip-id="tooltip_id_16" data-tooltip-html=" MES PRIX CLIENT">
                                {String(menu) !== "pricing" && <div className="sousmenu" onClick={pricing_func} ><FcCurrencyExchange className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "pricing" && <div className="sousmenu_selected" onClick={pricing_func} > <FcCurrencyExchange className="icone_menu" />&nbsp;</div>}
                            </a>
                        </div>

                        <div>
                            <hr className="my_hr" />
                            <Tooltip className="tooltip_css" id="tooltip_id_17" />
                            <a data-tooltip-id="tooltip_id_17" data-tooltip-html="MES COMMANDES & DEVIS">
                                {String(menu) !== "mes_cmd" && <div className="sousmenu" onClick={mes_cmd_func}><FcCollect className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "mes_cmd" && <div className="sousmenu_selected" onClick={mes_cmd_func}>  <FcCollect className="icone_menu" />&nbsp;</div>}
                            </a>
                        </div>

                        <div>
                            <hr className="my_hr" />
                            <Tooltip className="tooltip_css" id="tooltip_id_18" />
                            <a data-tooltip-id="tooltip_id_18" data-tooltip-html="MES FACTURES">
                                {String(menu) !== "factures_client" && <div className="sousmenu" onClick={mes_factures_client_func}><FcKindle className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "factures_client" && <div className="sousmenu_selected" onClick={mes_factures_client_func}>  <FcCollect FcKindle="icone_menu" />&nbsp;</div>}
                            </a>
                        </div>

                        <div>
                            <hr className="my_hr" />
                            <Tooltip className="tooltip_css" id="tooltip_id_19" />
                            <a data-tooltip-id="tooltip_id_19" data-tooltip-html="MES PRIX ACHAT">
                                {String(menu) !== "prix_achat" && <div className="sousmenu" onClick={prix_achat_func}><BiPurchaseTag className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "prix_achat" && <div className="sousmenu_selected" onClick={prix_achat_func}> <BiPurchaseTag FcKindle="icone_menu" />&nbsp;</div>}
                            </a>
                        </div>


                        <div>  <hr /> </div>


                        {String(has_partner_lms_url) === "1" && <div className="sousmenu" onClick={(e) => {
                            e.preventDefault();
                            window.open(
                                partner_lms_url,
                                '_blank'
                            );
                        }}>
                            <Tooltip className="tooltip_css" id="tooltip_id_2" />
                            <a data-tooltip-id="tooltip_id_2" data-tooltip-html="HEBERGEMENT E-LEARNING">
                                <FcVideoCall className="icone_menu" />
                            </a>
                        </div>}

                        {String(has_partner_lms_url) === "1" && <div>
                            <hr className="my_hr" />
                            <Tooltip className="tooltip_css" id="tooltip_id_20" />
                            <a data-tooltip-id="tooltip_id_20" data-tooltip-html="CONFIGURATION E-LEARNING">
                                {String(menu) !== "statistique" && <div className="sousmenu" onClick={DisplaySetUpLMS}><FcServices className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "statistique" && <div className="sousmenu_hebergement_lms" onClick={DisplaySetUpLMS}>  <FcServices className="icone_menu" />&nbsp;</div>}

                            </a>
                        </div>}



                    </div>
                </div>
                }

                {/* div_droite div_droite_ralonge */}
                {left_menu === false && <div>

                    {String(firstConnexion) === "1" && String(part_is_partner_admin_account) === "1" &&
                        <div className="div_droite firstConnexion">
                            <div className="firstConnexion_text">
                                <b>Cher partenaire, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                Nous sommes ravis de vous accueillir dans notre aventure MySy.
                                Pour commencer à publier vos formations, veuillez choisir une formule d'abonnement en cliquant <Button onClick={ValideFirstConnexion} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; &nbsp; ici</Button><br />
                                &nbsp; <br /> Merci.
                            </div>

                        </div>}

                    {String(firstConnexion) === "1" && String(part_is_partner_admin_account) !== "1" &&
                        <div className="div_droite firstConnexion">
                            <div className="firstConnexion_text">
                                <b>Cher Utilisateur, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                Nous sommes ravis de vous accueillir dans notre aventure MySy. Nous vous invitons à modifier votre mot de passe à partir du menu <i>"MES INFIRMATIONS"</i> ==&gt; Bouton <i>"SECURITE"</i> <br /><br />
                                <Button onClick={(e) => setfirstConnexion()} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; Fermer</Button><br />
                                &nbsp; <br /> Merci.
                            </div>

                        </div>}


                    {String(myApiResponse) === String("false") && <div className="div_droite div_droite_ralonge">
                        <div className="koUpdateData"> Impossible de contacter  l'hote distant. Merci de contacter votre administrateur  </div>
                    </div>

                    }

                    {String(menu) === "affichage" &&
                        <div className="div_droite div_droite_ralonge">
                            <PartnerTranings ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                class_external_code={orderid} class_internal_url={packs} />
                        </div>
                    }





                    {String(menu) === "checkout" && <div className="div_droite div_droite_ralonge">
                        <Button onClick={"returntoorder"} className="btn_retour_ps"> Retour aux produits & Services </Button>
                        <CheckOut packs={location.state.new_pack} periodicity={location.state.period} mypackprice={location.state.price} />
                    </div>

                    }

                    {String(menu) === "creation" &&
                        <div className="div_droite div_droite_ralonge">


                            <div className="parter_div_droite_haut">
                                {String(apiexcelimportmessage) === String("true") &&
                                    <div className="parter_div_filtrer_row okUpdateData">
                                        {excelimportmessage}<br />&nbsp;<br />

                                    </div>

                                }
                                {String(apiexcelimportmessage) === String("false") &&
                                    <div className="parter_div_filtrer_row koUpdateData">
                                        Impossible de traiter le fichier <br />
                                        <u> <b> {excelimportmessage} </b></u>
                                        <br />&nbsp;<br />
                                    </div>

                                }

                                {String(apiexcelimportmessage) === String("-1") &&
                                    <div className="parter_div_filtrer_row koUpdateData">
                                        Impossible de traiter le fichier <br />&nbsp;<br />
                                    </div>

                                }


                                <div className="parter_div_filtrer_row">
                                    <div>


                                        {String(partnerstatus) === String("0") && <div className="parter_div_filtrer_row_gauche">

                                            <Button variant="outlined" onClick={changeHandler2} className="detail_class_submenu" style={{
                                                "background": "#c8cfd5",
                                                "minWidth": "15rem", "color": "black", "width": "auto"
                                            }}
                                                id='menu_import_formation' name='menu_import_formation'>Choisir un fichier et importer des formations &nbsp; <SendIcon />
                                            </Button><br />
                                            {formation_file_name && <nav className="okUpdateData"><i>{formation_file_name}</i></nav>}
                                            <input type="file"
                                                accept=".csv"
                                                ref={hiddenFileInput_formation}
                                                style={{ display: 'none' }}
                                                name="liste_formation_file"
                                                onChange={handleSubmission}
                                            />



                                            <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                            <br />

                                            {/* <input type="file" name="file" accept=".csv" onChange={changeHandler} /> <br /> */}
                                            <br />&nbsp;
                                            <div className="import_excel_text">

                                                <nav className="titre1">  Comment utiliser le fichier ? </nav>
                                                <nav style={{ "color": "orange" }}>
                                                    Dans le fichier à importer, seules les colonnes suivantes sont obligatoires :
                                                    <ul>
                                                        <li>external_code : C'est le code de la formation chez vous, editeur de la formation </li>
                                                        <li> titre : Le titre de la formation </li>
                                                        <li> domaine : Le domaine au quel est rattaché la formation</li>
                                                        <li> description : La descriptin de la formation</li>
                                                    </ul>
                                                </nav>
                                                Pour le reste, <br />
                                                <ul>
                                                    <li> Il s'agit d'un fichier csv. Les colonnes sont separées par des ";"</li>
                                                    <li> A l'interieur d'une même colonne, les informations sont separées par des ";"     </li>
                                                    <li> Champ 'Metier' :  les valeurs acceptées sont : IT, RH, vente, dev_perso, Graphisme, Management, Digital, Office, Autre</li>
                                                    <li> ...</li>
                                                </ul>
                                                <a href='/sample/MySy_Import_formation_csv.pdf' download>Télécharger le guide complet pour plus d'informations</a><br />
                                            </div>
                                            <div style={{ "width": "50%" }}> &nbsp; </div>





                                        </div>}

                                        {String(partnerstatus) !== String("0") && <div className="parter_div_filtrer_row_gauche">


                                            Import excel d'une formation <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                            <br />

                                            <input type="file" name="file" onChange={""} accept=".csv" />


                                            <br />
                                            <div align="right">
                                                <Button onClick={alertactivecompte} variant="contained" endIcon={<SendIcon />}> Importer </Button>
                                            </div>

                                        </div>}
                                    </div>

                                    <br />

                                </div>
                            </div>
                            <div className="parter_div_droite_bas">
                                <div className="titre1_bis"> Création manuelle d'une formation </div>

                                <TrainingForm mytrainingclass={{ 'ref': '', 'dist': 'Oui', 'plus_produit': '0' }} ispending={partnerstatus}
                                    currentpack={String(partnerPackService).toUpperCase()} />
                            </div>

                        </div>
                    }


                    {String(menu) === "infopartner" &&
                        <div className="div_droite div_droite_ralonge">

                            <PartnerInfo check_user_acces_right={check_user_acces_right} />

                        </div>
                    }

                    {String(menu) === "statistique" &&
                        <div className="div_droite div_droite_ralonge">
                            <PartenairStat />
                        </div>
                    }

                    {String(menu) === "setuplms" &&
                        <div className="div_droite div_droite_ralonge">
                            <HebergementLms conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("lms", "read")} write_access={check_user_acces_right("lms", "write")} />
                        </div>
                    }


                    {String(menu) === "mes_sessions" &&
                        <div className="div_droite div_droite_ralonge">

                            <DisplayPartnerSession conntected_employee_id={conntected_employee_id} object_key={orderid} class_internal_url={packs}
                                read_access={check_user_acces_right("session", "read")} write_access={check_user_acces_right("session", "write")} />
                        </div>
                    }

                    {String(menu) === "mes_employes" &&
                        <div className="div_droite div_droite_ralonge">
                            <Employes conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("employe", "read")} write_access={check_user_acces_right("employe", "write")} />
                        </div>
                    }

                    {String(menu) === "factures_client" &&
                        <div className="div_droite div_droite_ralonge">
                            <Factures_Client conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("facture_client", "read")} write_access={check_user_acces_right("facture_client", "write")} />
                        </div>
                    }

                    {String(menu) === "prix_achat" &&
                        <div className="div_droite div_droite_ralonge">
                            <Partner_Prix_Achat conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix_achat", "read")} write_access={check_user_acces_right("prix_achat", "write")} />
                        </div>
                    }


                    {String(menu) === "mon_materiel" &&
                        <div className="div_droite div_droite_ralonge">
                            <Materiels conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")} write_access={check_user_acces_right("materiel", "write")} />
                        </div>
                    }

                    {String(menu) === "mes_cmd" &&
                        <div className="div_droite div_droite_ralonge">
                            <Partner_Commande conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("commande", "read")} write_access={check_user_acces_right("commande", "write")} />
                        </div>
                    }


                    {String(menu) === "facture" && <div className="div_droite div_droite_ralonge">

                        <Facture currentpack={partnerPackService} conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("compte_partner", "read")} write_access={check_user_acces_right("compte_partner", "write")} />
                    </div>
                    }

                    {String(menu) === "pricing" && <div className="div_droite div_droite_ralonge">

                        <Pricing conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix", "read")} write_access={check_user_acces_right("prix", "write")} />
                    </div>
                    }

                    {String(menu) === "mes_stagiaires" && <div className="div_droite div_droite_ralonge">

                        <DisplayPartnerStagiaires conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                            read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")} />
                    </div>
                    }

                    {String(menu) === "mes_apprenants" && <div className="div_droite div_droite_ralonge">

                        <Apprenant conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                            read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")} />
                    </div>
                    }


                    {String(menu) === "abonnement" && <div className="div_droite div_droite_ralonge">

                        <Abonnement currentpack={partnerPackService} conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("abonnement", "read")} write_access={check_user_acces_right("abonnement", "write")} />
                    </div>
                    }

                    {String(menu) === "ordervalide" && orderid && packs && <div className="div_droite div_droite_ralonge">
                        <OrderConfirmation num_cmd={orderid} packs={String(packs).toLocaleUpperCase()} />
                    </div>
                    }

                    {String(menu) === "mes_clients" && <div className="div_droite div_droite_ralonge">

                        <Partner_Client conntected_employee_id={conntected_employee_id} object_key={orderid}
                            read_access={check_user_acces_right("client", "read")} write_access={check_user_acces_right("client", "write")} />
                    </div>
                    }

                    {String(menu) === "config_document" && <div className="div_droite div_droite_ralonge">

                        <Config_Documents conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }

                    {String(menu) === "config_technique" && <div className="div_droite div_droite_ralonge">

                        <Configuration_Technique conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }


                    {String(menu) === "config_session_steps" && <div className="div_droite div_droite_ralonge">

                        <Partner_Configuration_Session_Steps conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }



                    {String(menu) === "config_jours_heures" && <div className="div_droite div_droite_ralonge">

                        <Partner_Configuration_Jours_Travail conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }

                    {String(menu) === "config_champ_specific" && <div className="div_droite div_droite_ralonge">

                        <Config_Champs_Personnalise conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                    </div>
                    }


                    {String(menu) === "module_agenda" && <div className="div_droite div_droite_ralonge">

                        <Module_Agenda conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("module_agenda", "read")} write_access={check_user_acces_right("module_agenda", "write")} />
                    </div>
                    }


                    {String(menu) === "dashbord_session" && <div className="div_droite div_droite_ralonge">

                        <Dashbord_Session conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }

                    {String(menu) === "dashbord_formation" && <div className="div_droite div_droite_ralonge">

                        <Dashbord_Formation conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }

                    {String(menu) === "dashbord_ressources_humaines" && <div className="div_droite div_droite_ralonge">

                        <Dashbord_Ressources_Humaines conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                    </div>
                    }



                    {String(menu) === "mon_tableau_de_bord" && <div className="div_droite div_droite_ralonge">

                        <Mon_Tableau_De_Bord conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />


                    </div>
                    }


                </div>}


                <div className="pieddepage">
                    <Footer />
                </div>

            </div>

        </div >


    )
}


export default Partner;