import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
//import { getCookie, setCookie, removeCookie  } from 'react-use-cookie';
import { Helmet } from "react-helmet";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import tick_ok from "../mysy_img/tick_OK.png";
import tick_ko from "../mysy_img/tick_KO.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Footer from "./Fotter";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Header from "./Header";
import SendIcon from '@mui/icons-material/Send';

function ProduitsServices() {
    const history = useHistory();
    const [check, setCheck] = useState(false);
    const [periode, setperiode] = useState("Mensuel");

    function handleChange(checked) {
        //this.setState({ checked });
        setCheck(checked);
        if (String(periode) === String("Mensuel")) {
            setperiode("Annuel");
        }
        else if (String(periode) === String("Annuel")) {
            setperiode("Mensuel");
        }
    }


    function Clik_bton_mensuel(e) {
        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel")[0].style.opacity = "80%";

        document.getElementsByName("bton_mensuel2")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel2")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel2")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel2")[0].style.opacity = "80%";



        setperiode("Mensuel");
    }

    function Clik_bton_annuel(e) {
        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "80%";


        document.getElementsByName("bton_annuel2")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel2")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel2")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel2")[0].style.opacity = "80%";



        setperiode("Annuel");
    }
    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    function sendEmail() {
        window.location = "mailto:contact@mysy-training.com";
    }

    return (
        <div className="produitsservices">
            <Helmet>
                <title>Produits & Services</title>
                <meta name="description" content="Produits et Services" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Produits et Services" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/mysy-training-login/0/0"} />

            </Helmet>

            <Header />

            <div className="div_mobile">
                <div className="critere">

                    <div className="critere_gauche">

                        <div className="input-group input-group-sm mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-sm3">Devise  </span>
                            <select className="form-select form-select-sm limite_input" onChange={"ChangeDevise"} aria-label=".form-select-sm example"
                                id={"devise"} name={"devise"}  >
                                <option selected value="euro">€</option>
                                {/*<option value="dollard">$ US</option>*/}

                            </select>
                        </div>
                    </div>
                    {/*<div className="critere_droite">
                        <span className="span1"> Periodicité &nbsp; </span>
                        <button
                            onClick={Clik_bton_mensuel}
                            className="bton_radius_gauche"
                            id="bton_mensuel" name="bton_mensuel">Mensuelle
                        </button>


                        <button
                            onClick={Clik_bton_annuel}
                            className="bton_radius_droite"
                            id="bton_annuel" name="bton_annuel">Annuelle
                        </button>


                    </div> */}

                </div>


                <div className="div_row">
                    <div className="div_row_description">
                        <div className="div_basique_header" style={{ "background": "#E9ECEF" }}>  Produits & Services  </div>
                        <hr style={{ "margin": "1px" }} />
                        <div style={{ "marginBottom": "0px", "fontSize": "small" }} className="block_vide_produit_service">
                            <nav style={{ "marginBottom": "0.8rem", "fontSize": "small", "textAlign": 'center', "fontWeight": "700" }}> &nbsp; </nav>
                            <nav> &nbsp;</nav>
                            <nav> &nbsp;</nav>
                            <nav> &nbsp; </nav>
                        </div>
                        <div className="produits_service_label height_publication_header">
                            <b> Gestion Administrative & Pédagogique </b>
                        </div>
                        <div className="produits_service_label height_gestion_admin">
                            <b>Gestion administrative</b>

                            <ul>
                                <li>Publication catalogue formations (intranet / API)</li>
                                <li>Gestion des Inscriptions (mise en attente, motif annulation, etc) </li>
                                <li>Gestion des Sessions (planifiée, à la demande)</li>
                                <li>Gestion avis participants</li>
                                <li>Gestion des employés et du matériel pédagogique</li>
                                <li>Émargement électronique</li>
                                <li>Gestion des agendas & réservation de matériel (salles, projecteurs, etc)</li>
                                <li>Personnalisation et versionning des documents (convention, convocation, ...)</li>
                                <li>Edition automatique des document liés à la formation (Convention, convocation, Programme…)</li>
                                <li>Gestion automatique et manuelle des séquences de formations </li>
                                <li>Gestion des absences</li>



                            </ul>
                        </div>

                        <div className="produits_service_label height_publication_header">
                            <b> Gestion relation client (CRM) </b>
                        </div>

                        <div className="produits_service_label height_gestion_crm">
                            <b>Gestion relation client (CRM) </b>

                            <ul>
                                <li>Gestion clients & Contacts</li>
                                <li>Affectation et Suivi tâches</li>
                                <li>Affectation et Suivi tâches</li>
                                <li>Catalogue prix clients</li>
                                <li>Devis & Commandes (envoi électronique) </li>
                                <li>Facturation</li>

                            </ul>
                        </div>

                        <div className="produits_service_label height_publication_header">
                            <b> Hébergement de formations (LMS) </b>
                        </div>


                        <div className="produits_service_label height_intranet">
                            <b>Intranet personnalisé</b>

                            <ul>
                                <li>Mise en place d’un site web (intranet) personnalisé.</li>

                            </ul>
                        </div>
                        <div className="produits_service_label height_e_learning_persona">
                            <b>Plate-forme de E-learning personnalisée</b>

                            <ul>
                                <li>Nom de domaine personnalisé</li>
                                <li>Personnalisation de la charte graphique (couleurs & bannière) </li>

                            </ul>

                        </div>

                        <div className="produits_service_label height_e_learning">
                            <b>Système de E-learning</b>

                            <ul>
                                <li>Echange prof / apprenant (courriel interne)</li>
                                <li>Espace enseignant</li>
                                <li>Espace apprenant</li>
                                <li>Evaluation (note)</li>
                                <li>Gestion planning</li>
                                <li>Travaux dirigés & pratiques</li>
                                <li>Session live virtuelle</li>

                            </ul>
                        </div>



                        <div className="produits_service_label height_publication_header">
                            <b> Moteur de Recherche </b>
                        </div>

                        <div className="produits_service_label height_publication">
                            <b> Publication des
                                formations </b>

                            <ul>
                                <li>Mise en ligne de votre formation
                                    (Description, Objectif,
                                    Programme, etc).</li>
                                <li>Mise en forme des textes selon
                                    votre souhait. </li>
                                <li>Définition du pays et la région
                                    de
                                    publication</li>
                                <li>Import/Export Excel</li>
                            </ul>
                        </div>


                        <div className="produits_service_label height_price_1">
                            <b> Gestion des prix </b>
                            <ul>
                                <li>Remise client / Tout public par période.
                                </li>
                            </ul>

                        </div>

                        <div className="produits_service_label height_affichage_ftion">
                            <b> Personnalisation de l'affichage d'une formation </b>
                            <ul>
                                <li>Ajout de vos propres images pour communiquer sur votre structure et votre marque.
                                </li>
                                <li>Affichage de votre logo à côté de la formation</li>
                                <li>Affichage de votre bannière en grand sur la page détaillée.</li>

                            </ul>
                        </div>
                        <div className="produits_service_label height_leads">
                            <b> Génération automatique de leads </b>

                            <ul>
                                <li>L’intérêt des stagiaires génère des leads qui vous sont envoyés automatiquement.
                                </li>


                            </ul>
                        </div>



                        <div className="produits_service_label height_appel_offre">
                            <b>Appels d’offre </b>

                            <ul>
                                <li>Consultation des appels d’offre de formation pour manifestation d’intérêt.</li>
                                <li>Publication d’un appel d’offre de formation et gestion des réponses.</li>

                            </ul>
                        </div>
                       

                     

                    </div>

                    <div className="div_row_basique">
                        <div className="div_basique_header">  Indépendant </div>
                        <hr style={{ "margin": "1px" }} />
                        <div style={{ "marginBottom": "0px", "fontSize": "small" }}>
                            <nav style={{ "marginBottom": "0.8rem", "fontSize": "small", "textAlign": 'center', "fontWeight": "700" }}> Moins de 25 Sessions / an </nav>
                            <nav> Sans engagement</nav>
                            <nav> 2 Utilisateurs   </nav>
                            <nav> &nbsp; </nav>
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_gestion_admin">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_gestion_crm">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_intranet">
                        <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_e_learning_persona">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_e_learning">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>


                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_publication">

                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_price_1">
                        <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_affichage_ftion">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_leads">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_appel_offre">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                       

                        <div className="div_row_basique_price">
                        150 HT €/mois
                        </div>
                        <div className="div_row_basique_price">
                            &nbsp;
                        </div>

                        <div className="abonnement">
                            <Button variant="contained"
                                color="success"
                                className="btn_abonnement"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/Partner";
                                }}
                            > Je m'abonne  </Button>
                        </div>

                    </div>

                    <div className="div_row_standard">
                        <div className="div_standard_header"> Standard </div>
                        <hr style={{ "margin": "0.2rem" }} />
                        <div style={{ "marginBottom": "0.8rem", "fontSize": "small" }}>
                            <nav style={{ "marginBottom": "0.8rem", "fontSize": "small", "textAlign": 'center', "fontWeight": "700" }}> De 26 à 200 Sessions / an </nav>
                            <nav> Sans engagement</nav>
                            <nav> Utilisateurs illimités </nav>
                            <nav> &nbsp; </nav>
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_gestion_admin">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_gestion_crm">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_intranet">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_e_learning_persona">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_e_learning">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>


                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_publication">

                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_price_1">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_affichage_ftion">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_leads">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_appel_offre">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                       
                      

                        <div className="div_row_standard_price">
                            299 HT €/mois

                        </div>

                        <div className="div_row_standard_price">
                            &nbsp;
                        </div>

                        <div className="abonnement">
                            <Button variant="contained"
                                color="success"
                                className="btn_abonnement"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/Partner";
                                }}> Je m'abonne  </Button>
                        </div>


                    </div>

                    <div className="div_row_gold">
                        <div className="div_gold_header">   Gold  </div>
                        <hr style={{ "margin": "0.2rem" }} />
                        <div style={{ "marginBottom": "0.8rem", "fontSize": "small" }}>
                            <nav style={{ "marginBottom": "0.8rem", "fontSize": "small", "textAlign": 'center', "fontWeight": "700" }}> A partir de 201 Sessions / an  </nav>
                            <nav> Sans engagement</nav>
                            <nav> Utilisateurs illimités </nav>
                            <nav> &nbsp; </nav>
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_gestion_admin">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>
                        <div className="produits_service_tick height_gestion_crm">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_intranet">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_e_learning_persona">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_e_learning">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_publication">

                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_price_1">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_affichage_ftion">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_leads">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_appel_offre">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        
                       


                        <div className="div_row_gold_price">
                            A partir de 599 € HT /mois

                        </div>

                        <div className="div_row_gold_price">
                            &nbsp;
                        </div>

                        <div className="abonnement">
                            <Button variant="contained"
                                color="success"
                                className="btn_abonnement"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href = "/Partner";
                                }}
                            > Je m'abonne  </Button>
                        </div>


                    </div>


                    {<div style={{ "width": "1%" }}> &nbsp;</div>}
                </div>

                <div className="div_row">


                    <div className="bton_envoyer_precision" onClick={sendEmail}>

                        Vous souhaitez avoir des précisions, un <b> Devis </b> contactez nous. &nbsp; &nbsp;  {<SendIcon />}


                    </div>

                </div>
                {/*  <div className="div_row">
                    <div className="div_row_description" >
                        {String(periode) === String("Mensuel") && <div style={{ "background": "#E9ECEF", "minHeight": "3.5rem", "paddingTop": "1rem" }}>
                            Prix par Mois

                        </div>}

                        {String(periode) === String("Annuel") && <div style={{ "background": "#E9ECEF", "minHeight": "3.5rem", "paddingTop": "1rem" }}>
                            Prix par An

                        </div>}

                    </div>
                    <div className="div_row_basique_price">
                        Gratuit
                    </div>
                    <div className="div_row_standard_price">
                        {String(periode) === String("Mensuel") && "350 €/mois"}
                        {String(periode) === String("Annuel") && "1 500 €/an - (2 mois offerts)"}
                    </div>
                    <div className="div_row_gold_price">
                        {String(periode) === String("Mensuel") && "550 €/mois"}
                        {String(periode) === String("Annuel") && "4 950 €/an - (3 mois offerts)"}

                    </div>
                    <div className="div_row_sur_mesure_price">
                        CONTACTEZ-NOUS
                    </div>
                    </div> ---*/}

                <div className="pieddepage">
                    <Footer />
                </div>
            </div>

        </div>

    );


}

export default ProduitsServices;