import React, { useRef, useState, useEffect } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { tokens } from "../theme";
import { MdDeleteForever } from "react-icons/md";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import {
    useTheme,
} from "@mui/material";

import { ResponsiveBar } from "@nivo/bar";

import { RiFileExcel2Line } from "react-icons/ri";

const Dashbord_Inscriptions_all_inscriptions = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [user_dashbord_id, setuser_dashbord_id] = useState(props.user_dashbord_id);


    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        {
            field: 'inscription_status', headerName: 'Inscrit', hide: false, minWidth: 50, flex: 1, maxWidth: 60,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Pré.";
                else if (String(params.value) === "1")
                    return "Ins.";
                else if (String(params.value) === "2")
                    return "Enc.";
                else if (String(params.value) === "-1")
                    return "Ann.";

                else
                    return "?";
            },
        },
        { field: 'apprenant_id', headerName: 'apprenant_id', hide: true, Width: 0, },
        { field: 'civilite', headerName: 'Civ.', hide: false, minWidth: 50, flex: 1, maxWidth: 60 },
        { field: 'nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 20 },
        { field: 'prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200 },
        { field: 'email', headerName: 'Email', hide: false, flex: 1, minWidth: 200 },
        { field: 'telephone', headerName: 'Téléphone', hide: false, minWidth: 50, flex: 1, maxWidth: 100 },
        { field: 'session_class_title', headerName: 'Titre Ftion', hide: false, minWidth: 200, flex: 1, },
        { field: 'session_code_session', headerName: 'Code session', hide: false, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'session_date_debut', headerName: 'Session Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200 },
        { field: 'session_date_fin', headerName: 'Session Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200 },
        { field: 'session_distantiel', headerName: 'Distantiel', hide: true, Width: 20 },
        { field: 'session_presentiel', headerName: 'Présentiel', hide: true, Width: 20 },
    ]


    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));


    const [filter_value, setfilter_value] = useState("d");

    const [filter_type_tache, setfilter_type_tache] = useState("");

    const [tdb_title, settdb_title] = useState();

    const [display_nb_column, setdisplay_nb_column] = useState("1");
    const [display_column_size, setdisplay_column_size] = useState("100%");

    const [dashbord_internal_code, setdashbord_internal_code] = useState("");
    const [gridline_id, setgridline_id] = useState("");

    const [Getall_Qry_Inscription_Data_result_JSON, setGetall_Qry_Inscription_Data_result_JSON] = useState([]);
    const [Getall_Qry_Inscription_Data_api, setGetall_Qry_Inscription_Data_api] = useState();
    const [Getall_Qry_Inscription_Data_message, setGetall_Qry_Inscription_Data_message] = useState();
    const [Getall_Qry_Inscription_Data_result, setGetall_Qry_Inscription_Data_result] = useState([]);
    function Getall_Qry_Inscription_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);


        console.log(' ####  Get_Qery_Inscription_By_Session_By_Periode form = ', form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_By_Session_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Inscription_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Inscription_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Inscription_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_Inscription_Data_api("true");
                setGetall_Qry_Inscription_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Inscription_Data_api("false");
                setGetall_Qry_Inscription_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Inscription_Data = ', error);
            setGetall_Qry_Inscription_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    
    const [Remove_From_User_TBD_api, setRemove_From_User_TBD_api] = useState();
    const [Remove_From_User_TBD_message, setRemove_From_User_TBD_message] = useState();
    const [Remove_From_User_TBD_result, setRemove_From_User_TBD_result] = useState();
    function Remove_From_User_TBD() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');



        form.append("token", stored_cookie);
        form.append("user_dashbord_id", user_dashbord_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Remove_From_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Remove_From_User_TBD  res.data.message r_class = " + res.data.message);

                setRemove_From_User_TBD_api("true");
                setRemove_From_User_TBD_result(res.data.message);
                Getall_Qry_Inscription_Data();
                alert(res.data.message);
                window.location.reload();


            }
            else {
                setRemove_From_User_TBD_api("false");
                setRemove_From_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Remove_From_User_TBD = ', error);
            setRemove_From_User_TBD_api("false");
            alert("Impossible de supprimer le tableau de bord de votre liste");
            //setmyApimyApiMessage("")
        })
    }


    const [Add_To_User_TBD_api, setAdd_To_User_TBD_api] = useState();
    const [Add_To_User_TBD_message, setAdd_To_User_TBD_message] = useState();
    const [Add_To_User_TBD_result, setAdd_To_User_TBD_result] = useState();
    function Add_To_User_TBD(local_dashbord_internal_code) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        //console.log(" ### dashbord_internal_code = ", local_dashbord_internal_code);

        var tdb_title = "default";
        var filter_val = "";
        var filter_type_event = "";


        if (document.getElementsByName("tdb_title")[0]) {
            tdb_title = document.getElementsByName("tdb_title")[0].value;
        }

        if (document.getElementsByName("filtre11")[0]) {
            filter_val = document.getElementsByName("filtre11")[0].value;
        }

        if (document.getElementsByName("filtre12")[0]) {
            filter_type_event = document.getElementsByName("filtre12")[0].value;
        }

        // Si filter_val =  "d", alors on va recuperer l'interval de date
        if (String(filter_val) === "d") {
            var date_from = p_filter_date_from;
            var date_to = p_filter_date_to;


            filter_val = "{'session_start_date':'" + String(date_from) + "' , 'session_end_date':'" + String(date_to) + "'}";
        } else {
            filter_val = "{'periode':'" + String(filter_val) + "'}"
        }

        form.append("token", stored_cookie);
        form.append("dashbord_internal_code", local_dashbord_internal_code);
        form.append("default_filter", filter_val);
        form.append("title", tdb_title);
        form.append("type_event", filter_type_event);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_To_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Add_To_User_TBD  res.data.message r_class = " + res.data.message);

                setAdd_To_User_TBD_api("true");
                setAdd_To_User_TBD_result(res.data.message);
                settdb_title();
                alert(res.data.message)


            }
            else {
                setAdd_To_User_TBD_api("false");
                setAdd_To_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Add_To_User_TBD = ', error);
            setAdd_To_User_TBD_api("false");
            alert("Impossible d'ajouter le tableau de bord");
            //setmyApimyApiMessage("")
        })
    }

    const [event_type_filter, setevent_type_filter] = useState(props.user_dashbord_filter_type_envent);

    useEffect(() => {

        console.log(" &&&& props.user_dashbord_filter_end = ", props.user_dashbord_filter_end);

        setp_filter_date_from(props.user_dashbord_filter_start);
        setp_filter_date_to(props.user_dashbord_filter_end);
        setevent_type_filter(props.user_dashbord_filter_type_envent);

        Getall_Qry_Inscription_Data();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [p_filter_date_from, p_filter_date_to])



    function Export_Dashbord_to_CSV(local_dashbord_internal_id) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_csv.csv";
        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/TBD_Inscription_Export_Dashbord_To_Csv/" + stored_cookie + "/" + local_dashbord_internal_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les données (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les données ")

            });

    }

    return (
        <div className="dashbord_session">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}
            <div>
                <div style={{ "height": "40rem" }}>
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "80%", "float": "left", "textAlign": "center" }}>  TBD : {props.user_dashbord_title} - Du : {props.user_dashbord_filter_start} au : {props.user_dashbord_filter_end}</nav>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer de ma liste de TBD">
                            <Popup
                                trigger={
                                    <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                        <MdDeleteForever style={{ "cursor": "pointer", }} />
                                    </nav>
                                }
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, le tableau sera supprimé de votre tableau de bord global.<br />
                                            Vous pourrez le rajouter à nouveau si besoin. <br />

                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    Remove_From_User_TBD();
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </a>

                        <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip03" data-tooltip-html="Export Csv/Excel">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <RiFileExcel2Line style={{ "cursor": "pointer", }} onClick={(event) => {
                                    Export_Dashbord_to_CSV(props.user_dashbord_id);

                                }} />
                            </nav>
                        </a>

                    </div>
                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',
                                            '& .cell--inscrit': {
                                                backgroundColor: '#2ECC71',
                                                color: '#1a3e72',
                                                fontWeight: 'bold',
                                            },
                                            '& .cell--preinscrit': {
                                                backgroundColor: '#F7DC6F',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--encours': {
                                                backgroundColor: '#D2B4DE',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--annule': {
                                                backgroundColor: '#ABB2B9',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },


                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_session_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /*  setaddOneParticipant();
                                                      setdisplay_detail_insc();
                                                      setselected_code_session();
                                                      setsubmenu();*/
                                                }
                                            }}
                                            selectionModel={selectionModel}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Qry_Inscription_Data_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    civilite: JSON.parse(item).civilite,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    email: JSON.parse(item).email,
                                                    telephone: JSON.parse(item).telephone,
                                                    session_class_title: JSON.parse(item).session_class_title,
                                                    session_code_session: JSON.parse(item).session_code_session,
                                                    session_date_debut: JSON.parse(item).session_date_debut,
                                                    session_date_fin: JSON.parse(item).session_date_fin,
                                                    session_distantiel: JSON.parse(item).session_distantiel,
                                                    session_presentiel: JSON.parse(item).session_presentiel,
                                                    inscription_status: JSON.parse(item).inscription_status,
                                                    apprenant_id: JSON.parse(item).apprenant_id,

                                                }
                                            ))}

                                            columns={columns}
                                            pageSize={20}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {

                                            }}



                                            rowsPerPageOptions={[20]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {

                                                if (params.field === 'inscription_status' && String(params.value) === "-1") {
                                                    return 'cell--annule';
                                                }
                                                if (params.field === "inscription_status" && String(params.value) == "0") {
                                                    return 'cell--preinscrit';
                                                }

                                                if (params.field === "inscription_status" && String(params.value) == "1") {
                                                    return 'cell--inscrit';
                                                }

                                                if (params.field === "inscription_status" && String(params.value) == "2") {
                                                    return '.cell--encours';
                                                }

                                            }}

                                            getRowClassName={(params) => {

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {

                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }

                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>



                    </Box>

                </div>
            </div>

        </div>)
}


export default Dashbord_Inscriptions_all_inscriptions;