import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/index.scss"
import App from './App';
import Footer from "./pages/footer";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51LUUfAAbmaEugrFTI25uZBD3IFjbtaL6jUfRV83diDf7nco8worna4NGKhMHbPP71WCwT5EHFRdDNatxPrJWwgZ300kgH5EO4p');

const stripePromise_PROD = loadStripe('pk_live_51LUUfAAbmaEugrFTyfNe4Dj0vbxc5qXQqNs0rztjAKy8oSlc5EEQyJGjA8Z5kXmP2gvUMcYLUBYCIyWWzLCw59RY00PtGInkVf');

window.ela_tocken="";



ReactDOM.render(

  <React.StrictMode>
     <Elements stripe={stripePromise_PROD}>
    <App />
    </Elements>
  </React.StrictMode>
  
    ,
  document.getElementById('root')
  
);

