import React, { useRef, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { getCookie, setCookie } from 'react-use-cookie';
import { Alert } from "@mui/material";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import profileimg from "../mysy_img/MYSY-profil-2.png";
import Abonnement from "./Abonnement";
import { ConstructionOutlined, PropaneSharp } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RichTextEditor } from '@mantine/rte';
import { Editor } from '@tinymce/tinymce-react';
import { IoArrowUndoCircle } from "react-icons/io5";
import { GrUserAdmin, GrOrderedList } from "react-icons/gr";
import parse from 'html-react-parser';
import { format } from 'date-fns'
import moment from "moment";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import GestionAdministrative from "./GestionAdministrative";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { Fab } from "@material-ui/core";
import { ConsoleView } from "react-device-detect";
import fileDownload from 'js-file-download'
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineUserAdd } from "react-icons/ai";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';


const PartnerClientActivite = (props) => {

    const history = useHistory();
    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [current_activite_recid, setcurrent_activite_recid] = useState("");

    const [show_all_activite_list, setshow_all_activite_list] = useState("1");
    const [showactivite_detail, setshowactivite_detail] = useState();
    const [add_one_activite, setadd_one_activite] = useState("");

    function Add_Activite() {
        setdatamodification_activite("0");
        setformedit_mode_activite("0");
        setcurrent_activite_recid("");
        //clean_contact_set_values();

        setadd_one_activite("1");
        setshowactivite_detail();
        setshow_all_activite_list();

        Clear_activite_P_fields();

    }

    function Close_Activite() {
        setshowactivite_detail("0");
        setdatamodification_activite("0");
        setformedit_mode_activite("0");
        setcurrent_activite_recid("");
        //clear_contact_fields();
        //clean_contact_set_values();

    }

    function retour_liste_Activite() {
        setshowactivite_detail();
        setadd_one_activite();
        setshow_all_activite_list("1");

        setcurrent_activite_recid("");
        setformedit_mode_activite("0");
    }

    const [datamodification_activite, setdatamodification_activite] = useState("0");
    const [formedit_mode_activite, setformedit_mode_activite] = useState("0");

    function DataUpdated_Activite_Data() {
        setdatamodification_activite("1");
    }


    function DataEditMode_Activite_Data() {
        setformedit_mode_activite("1");
        Enable_activite_fields();

    }



    const type_activite_list = [
        {
            value: 'email',
            label: 'Email',
        },
        {
            value: 'call',
            label: 'Appel',
        },
        {
            value: 'meeting',
            label: 'Réunion',
        },
        {
            value: 'autre',
            label: 'Autre',
        },
        {
            value: 'cmd',
            label: 'commande',
        },
        {
            value: 'devis',
            label: 'devis',
        },
        {
            value: 'facture',
            label: 'facture',
        },

    ];

    const activite_status_list = [
        {
            value: '0',
            label: 'encours',
        },
        {
            value: '1',
            label: 'Fait',
        },
        {
            value: '-1',
            label: 'annulé',
        },


    ];


    // Données Activites
    const [p_activite_description, setp_activite_description] = useState("");
    const [p_activite_detail, setp_activite_detail] = useState("");
    const [p_activite_deadline, setp_activite_deadline] = useState("");
    const [p_activite_deadline_label, setp_activite_deadline_label] = useState("");
    const [p_activite_type, setp_activite_type] = useState("");
    const [p_activite_status, setp_activite_status] = useState("");
    const [p_activite_status_label, setp_activite_status_label] = useState("");
    const [p_activite_employee_assigned_id, setp_activite_employee_assigned_id] = useState(props.conntected_employee_id);
    const [p_activite_employee_assigned_nom_prenom, setp_activite_employee_assigned_nom_prenom] = useState();

    const [p_activite_employee_assigned, setp_activite_employee_assigned] = useState(props.conntected_employee_id);


    // Recuperation des activités du client d'un partner
    const [Get_List_Activite_Of_client_Part_api, setGet_List_Activite_Of_client_Part_api] = useState();
    const [Get_List_Activite_Of_client_Part_message, setGet_List_Activite_Of_client_Part_message] = useState();
    const [Get_List_Activite_Of_client_Part_result, setGet_List_Activite_Of_client_Part_result] = useState();
    function Get_List_Activite_Of_client_Part(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        const related_collection = "partner_client"


        form.append("token", stored_cookie);
        form.append("related_collection", related_collection);
        form.append("related_collection_owner_email", props.email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Entity_Activite/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Activite_Of_client_Part  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Activite_Of_client_Part  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Activite_Of_client_Part_api("true");
                setGet_List_Activite_Of_client_Part_result(res.data.message);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Activite_Of_client_Part_api("false");
                setGet_List_Activite_Of_client_Part_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Activite_Of_client_Part = ', error);
            setGet_List_Activite_Of_client_Part_api("false");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Getall_Training_Employee_No_Filter();
        Get_List_Activite_Of_client_Part();

    }, []);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);
            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };



    // Fonction de mise à jour d'une activité
    const [recordPartnerClient_Activite_Data_api, setrecordPartnerClient_Activite_Data_api] = useState("");
    const [recordPartnerClient_Activite_Data_result, setrecordPartnerClient_Activite_Data_result] = useState("");
    const [recordPartnerClient_Activite_Data_message, setrecordPartnerClient_Activite_Data_message] = useState("");
    function recordPartnerClient_Activite_Data(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("description", p_activite_description);
        form.append("detail", p_activite_detail);
        form.append("deadline", format(p_activite_deadline, 'dd/MM/yyyy'));
        form.append("type", p_activite_type);
        form.append("status", p_activite_status);

        if (p_activite_employee_assigned_id)
            form.append("employee_assigned_to_id", p_activite_employee_assigned_id);



        // Controle d'integrité des infos
        var activite_description = ""
        if (document.getElementsByName("activite_description")) {
            activite_description = document.getElementsByName("activite_description")[0].value;
        }
        if (activite_description.length == 0) {
            alert(" Le champ 'Description' est vide ");
            return;
        }

        if (activite_description.length > 255) {
            alert(" Le champ 'Description' comporte plus de 255 caractères ");
            return;
        }


        var activite_detail = ""
        if (document.getElementsByName("activite_detail")) {
            activite_detail = document.getElementsByName("activite_detail")[0].value;
        }
        if (activite_detail.length > 500) {
            alert(" Le champ 'Détail' comporte plus de 500 caractères ");
            return;
        }


        var myurl = "";

        // Il s'agit d'un mise à jour. on a une valeur dans le current_activite_recid
        myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_activite/";
        form.append("activite_recid", current_activite_recid);



        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient_Activite_Data  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient_Activite_Data  res.data.message  = " + res.data.message);
                setrecordPartnerClient_Activite_Data_api("true");
                setrecordPartnerClient_Activite_Data_result(res.data.message);

                Get_List_Activite_Of_client_Part();
                setdatamodification_activite("0");
                setformedit_mode_activite("0");
                setcurrent_activite_recid("");


                setshow_all_activite_list("1");
                setshowactivite_detail('0');
                setadd_one_activite('0');
                alert(res.data.message);
            }

            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecordPartnerClient_Activite_Data_api("false");
                setrecordPartnerClient_Activite_Data_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient_Activite_Data = ', error);
            setrecordPartnerClient_Activite_Data_result("false");
            setrecordPartnerClient_Activite_Data_message(" Impossible d'ajouter l'activité ")
            alert(" Impossible d'ajouter/mettre à jour l'activité ");

        })
    }


    // Fonction d'ajout d'un activité
    const [record_One_PartnerClient_Activite_Data_api, setrecord_One_PartnerClient_Activite_Data_api] = useState("");
    const [record_One_PartnerClient_Activite_Data_result, setrecord_One_PartnerClient_Activite_Data_result] = useState("");
    const [record_One_PartnerClient_Activite_Data_message, setrecord_One_PartnerClient_Activite_Data_message] = useState("");
    function record_One_PartnerClient_Activite_Data(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);



        // Controle d'integrité des infos
        if (one_activite_description && one_activite_description.length == 0) {
            alert(" Le champ 'Description' est vide ");
            return;
        }

        if (one_activite_description && one_activite_description.length > 255) {
            alert(" Le champ 'Description' comporte plus de 255 caractères ");
            return;
        }

        if (one_activite_detail && one_activite_detail.length > 255) {
            alert(" Le champ 'Détail' comporte plus de 255 caractères ");
            return;
        }

        if (one_activite_description)
            form.append("description", one_activite_description);
        else
            form.append("description", "");

        if (one_activite_detail)
            form.append("detail", one_activite_detail);
        else
            form.append("detail", "");

        if (one_activite_deadline)
            form.append("deadline", one_activite_deadline);

        if (one_activite_type)
            form.append("type", one_activite_type);

        if (one_activite_status)
            form.append("status", one_activite_status);

        if (p_activite_employee_assigned_id)
            form.append("employee_assigned_to_id", p_activite_employee_assigned_id);


        var myurl = "";

        // Il s'agit d'un ajout d'une nouvelle activité. on a rien dans le current_activite_recid
        myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_activite/";
        form.append("related_collection", "partner_client");
        form.append("related_collection_owner_email", props.email);


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient_Activite_Data  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient_Activite_Data  res.data.message  = " + res.data.message);
                setrecord_One_PartnerClient_Activite_Data_api("true");
                setrecord_One_PartnerClient_Activite_Data_result(res.data.message);

                setshowactivite_detail("0");
                Get_List_Activite_Of_client_Part();
                alert(res.data.message);
                setdatamodification_activite("0");
                setformedit_mode_activite("0");
                setcurrent_activite_recid("");
                retour_liste_Activite();
            }

            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecord_One_PartnerClient_Activite_Data_api("false");
                setrecord_One_PartnerClient_Activite_Data_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient_Activite_Data = ', error);
            setrecord_One_PartnerClient_Activite_Data_api("false");
            setrecord_One_PartnerClient_Activite_Data_message(" Impossible d'ajouter l'activité ")
            alert(" Impossible d'ajouter/mettre à jour l'activité ");

        })
    }

    const [Display_Part_Client_Activite_api, setDisplay_Part_Client_Activite_api] = useState("");
    const [Display_Part_Client_Activite_result, setDisplay_Part_Client_Activite_result] = useState("");
    const [Display_Part_Client_Activite_message, setDisplay_Part_Client_Activite_message] = useState("");
    async function Display_Part_Client_Activite(event) {


        var myactivity_recid = event.currentTarget.getAttribute('recid');

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("activite_recid", myactivity_recid);


        //console.log(" ### event.currentTarget.getAttribute('recid') = ", event.currentTarget.getAttribute('recid'));

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Activite/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Display_Part_Client_Activite  res.data.status = " + res.data.status);
                //console.log(" In Display_Part_Client_Activite  res.data.message  = " + res.data.message);
                setDisplay_Part_Client_Activite_api("true");
                setDisplay_Part_Client_Activite_result(res.data.message);

                setshowactivite_detail("1");
                setshow_all_activite_list();
                setadd_one_activite();


                var mylocalclient_activite = JSON.parse(res.data.message);


                if (mylocalclient_activite) {

                    // Remplissage des doonées de l'activite
                    if (mylocalclient_activite.description)
                        setp_activite_description(mylocalclient_activite.description);
                    else
                        setp_activite_description("");

                    if (mylocalclient_activite.detail)
                        setp_activite_detail(mylocalclient_activite.detail);
                    else
                        setp_activite_detail("");

                    if (mylocalclient_activite.deadline) {
                        var act_deadline = new Date(moment(mylocalclient_activite.deadline, "DD/MM/YYYY HH:mm:ss"));
                        setp_activite_deadline(act_deadline);
                        setp_activite_deadline_label(mylocalclient_activite.deadline);
                    }
                    else
                        setp_activite_deadline("");


                    if (mylocalclient_activite.employee_assigned_to_id) {
                        setp_activite_employee_assigned_nom_prenom(mylocalclient_activite.employee_assigned_to_nom_prenom);
                        setp_activite_employee_assigned_id(mylocalclient_activite.employee_assigned_to_id);
                    }


                    if (mylocalclient_activite.type)
                        setp_activite_type(mylocalclient_activite.type);
                    else
                        setp_activite_type("");


                    if (mylocalclient_activite.status) {
                        setp_activite_status(mylocalclient_activite.status);
                        if (String(mylocalclient_activite.status) === "0")
                            setp_activite_status_label("En cours");

                        else if (String(mylocalclient_activite.status) === "1")
                            setp_activite_status_label("Fait");

                        else if (String(mylocalclient_activite.status) === "-1")
                            setp_activite_status_label("Annulé");


                    }

                    else
                        setp_activite_status("");


                    if (mylocalclient_activite.recid)
                        setcurrent_activite_recid(mylocalclient_activite.recid);
                    else
                        setcurrent_activite_recid("");
                }


                Disable_activite_fields();

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setDisplay_Part_Client_Activite_api("false");
                setDisplay_Part_Client_Activite_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Display_Part_Client_Activite = ', error);
            setDisplay_Part_Client_Activite_result("false");
            setDisplay_Part_Client_Activite_message(" Impossible de recuperer les details de l'activité")
            alert(" Impossible de recuperer les details de l'activité");

        })
    }

    async function Disable_activite_fields() {

        await sleep(5);

        if (document.getElementsByName("activite_description")[0]) {
            document.getElementsByName("activite_description")[0].disabled = true;
            document.getElementsByName("activite_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_deadline")[0]) {
            document.getElementsByName("activite_deadline")[0].disabled = true;
            document.getElementsByName("activite_deadline")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_type")[0]) {
            document.getElementsByName("activite_type")[0].disabled = true;
            document.getElementsByName("activite_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_status")[0]) {
            document.getElementsByName("activite_status")[0].disabled = true;
            document.getElementsByName("activite_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_assigned_to")[0]) {
            document.getElementsByName("activite_assigned_to")[0].disabled = true;
            document.getElementsByName("activite_assigned_to")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("activite_detail")[0]) {
            document.getElementsByName("activite_detail")[0].disabled = true;
            document.getElementsByName("activite_detail")[0].style.backgroundColor = "#ECEFF1";
        }


    }

    function Enable_activite_fields() {
        if (document.getElementsByName("activite_description")) {
            document.getElementsByName("activite_description")[0].disabled = false;
            document.getElementsByName("activite_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_deadline")) {
            document.getElementsByName("activite_deadline")[0].disabled = false;
            document.getElementsByName("activite_deadline")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_type")) {
            document.getElementsByName("activite_type")[0].disabled = false;
            document.getElementsByName("activite_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_status")) {
            document.getElementsByName("activite_status")[0].disabled = false;
            document.getElementsByName("activite_status")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_assigned_to")) {
            document.getElementsByName("activite_assigned_to")[0].disabled = false;
            document.getElementsByName("activite_assigned_to")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("activite_detail")) {
            document.getElementsByName("activite_detail")[0].disabled = false;
            document.getElementsByName("activite_detail")[0].style.backgroundColor = "#FFFFFF";
        }

    }



    function Clear_activite_P_fields() {
        setp_activite_description("");
        setp_activite_detail("");
        setp_activite_deadline("");
        setp_activite_type("");
        setp_activite_status("");
        setp_activite_status_label("");
    }



    function Clear_activite_fields() {
        if (document.getElementsByName("activite_description")) {
            document.getElementsByName("activite_description")[0].value = "";
        }

        if (document.getElementsByName("activite_deadline")) {
            document.getElementsByName("activite_deadline")[0].value = "";
        }

        if (document.getElementsByName("activite_type")) {
            document.getElementsByName("activite_type")[0].value = "";
        }

        if (document.getElementsByName("activite_status")) {
            document.getElementsByName("activite_status")[0].value = "";
        }

        if (document.getElementsByName("activite_detail")) {
            document.getElementsByName("activite_detail")[0].value = "";
        }

    }



    const [one_activite_description, setone_activite_description] = useState();
    const [one_activite_deadline, setone_activite_deadline] = useState(new Date().toLocaleDateString('fr-FR'));
    const [one_activite_type, setone_activite_type] = useState("call");
    const [one_activite_status, setone_activite_status] = useState("0");
    const [one_activite_detail, setone_activite_detail] = useState();

    function Close_add_one_activite() {
        setone_activite_description();

        setone_activite_deadline(new Date().toLocaleDateString('fr-FR'));


        setone_activite_type("call");
        setone_activite_status("0");
        setone_activite_detail();

        setshowactivite_detail();

        setshowactivite_detail();
        setadd_one_activite();
        setshow_all_activite_list("1");

        setcurrent_activite_recid("");
        setformedit_mode_activite("0");

    }



    return (
        <div className="partner_client_activite" >

            <div className="div_row">
                {String(show_all_activite_list) === "1" && String(showactivite_detail) !== "1" && String(add_one_activite) !== "1" &&
                    <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }}>
                        <div className="div_row" onClick={Add_Activite} style={{ "cursor": "pointer" }}> <AiOutlineUserAdd /> Ajouter une activité  </div>
                        <div className="div_row">

                            <div className="block_all_activite">

                                {Get_List_Activite_Of_client_Part_result && String(Get_List_Activite_Of_client_Part_api) === "true" &&
                                    Get_List_Activite_Of_client_Part_result.map((client) => (
                                        <nav className="block_activite">


                                            {String(String(JSON.parse(client).status)) === "1" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={Display_Part_Client_Activite} className="detail_activite">
                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >
                                                        <i>FAIT </i>  <br />
                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                            {String(String(JSON.parse(client).status)) === "0" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={Display_Part_Client_Activite} className="detail_activite">
                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >

                                                        {parseInt(String(JSON.parse(client).retard)) < 0 && <i style={{ "color": "red" }}> RETARD de {String(JSON.parse(client).retard)} jours <br /></i>}
                                                        {parseInt(String(JSON.parse(client).retard)) > 0 && <i> DANS  {String(JSON.parse(client).retard)} jours <br /></i>}
                                                        {parseInt(String(JSON.parse(client).retard)) == 0 && <i style={{ "color": "orange" }}> AUJOURD'HUI <br /></i>}


                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                            {String(String(JSON.parse(client).status)) === "-1" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={Display_Part_Client_Activite} className="detail_activite">
                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >
                                                        <i>ANNULE </i>  <br />
                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}




                                        </nav>
                                    ))}

                            </div>

                        </div>
                    </div>}
                {String(showactivite_detail) === "1" && <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }} onChange={DataUpdated_Activite_Data}>
                    <div className="training_caract"> Description  <br />

                        <TextField
                            required
                            name="activite_description"
                            //label="Description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_description}
                            onChange={(e) => setp_activite_description(e.target.value)}

                        />
                    </div>



                    {String(formedit_mode_activite) === "1" && <div className="training_caract">
                        Date souhaitée <br />
                        <DatePicker
                            name="activite_deadline"
                            id="activite_deadline"
                            selected={p_activite_deadline}
                            onChange={(date) => {
                                setp_activite_deadline(date);
                                setdatamodification_activite("1");
                            }
                            }
                            showTimeSelect={false}
                            filterTime={filterPassedTime_start}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'
                        />

                    </div>}

                    {String(formedit_mode_activite) === "0" && <div className="training_caract">

                        Date souhaitée <br />
                        <TextField
                            required
                            name="activite_deadline"
                            //label="Date souhaitée"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_deadline_label}
                        />

                    </div>}

                    {String(formedit_mode_activite) === "1" && <div className="training_caract">
                        Type d'activité <br />
                        <TextField
                            required
                            select
                            name="activite_type"
                            //label="Type d'activité"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_type}
                            onChange={(e) => setp_activite_type(e.target.value)} >
                            {type_activite_list.map((option) => (
                                <MenuItem key={option.value} value={option.value} 
                                style={{"width":"100%", "paddingLeft":"5px"}}>
                                    {option.label} &nbsp; <br />
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>}

                    {String(formedit_mode_activite) === "0" && <div className="training_caract">
                        Type d'activité <br />
                        <TextField
                            required
                            name="activite_type"
                            //label="Type d'activité"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_type}
                            onChange={(e) => setp_activite_type(e.target.value)} >

                        </TextField>

                    </div>}

                    {String(formedit_mode_activite) === "1" && <div className="training_caract">
                        Statut <br />
                        <TextField
                            required
                            select
                            name="activite_status"
                            //label="Statut"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_status}
                            onChange={(e) => setp_activite_status(e.target.value)} >
                            {activite_status_list.map((option) => (
                                <MenuItem key={option.value} value={option.value} >
                                    {option.label} &nbsp; <br />
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>}

                    {String(formedit_mode_activite) === "0" && <div className="training_caract">
                        Statut <br />
                        <TextField
                            required
                            name="activite_status"
                            //label="Statut"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_status_label}
                            onChange={(e) => setp_activite_status(e.target.value)} >
                        </TextField>
                    </div>}

                    
                    {String(formedit_mode_activite) === "1" && <div className="training_caract" > Assigné à (employé) <br />

                        <TextField

                            id="activite_assigned_to"
                            name="activite_assigned_to"
                            select
                            
                            className="disabled_style"
                            value={p_activite_employee_assigned_id}
                            onChange={(e) => {
                                setp_activite_employee_assigned_id(e.target.value);

                            }
                            }
                        >
                            {Getall_Training_Employee_No_Filter_result &&
                                Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                   style={{"width":"100%", "paddingLeft":"5px"}} >
                                        {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>}

                    {String(formedit_mode_activite) !== "1" && <div className="training_caract" > Assigné à (employé) <br />

                        <TextField

                            margin="dense"
                            id="activite_assigned_to"
                            name="activite_assigned_to"
                            className="disabled_style"
                            
                            fullWidth

                            value={p_activite_employee_assigned_nom_prenom}

                        />

                    </div>}


                    <div className="training_caract" style={{ "width": "105%" }}>
                        Détail <br />
                        <TextField
                            required
                            name="activite_detail"
                            //  label="Détail"
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style_no_border"
                            value={p_activite_detail}
                            onChange={(e) => setp_activite_detail(e.target.value)}

                        />
                    </div>






                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(datamodification_activite) === "1" && <div className="div_row">
                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}

                    {String(formedit_mode_activite) === "1" && <div className="div_row">
                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={recordPartnerClient_Activite_Data}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_annule" onClick={retour_liste_Activite}>Annuler</Button>
                        </div>
                    </div>
                    }

                    {String(formedit_mode_activite) !== "1" && <div className="div_row">

                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={retour_liste_Activite}>Liste Activités &nbsp; <GrOrderedList /> </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_edit" onClick={DataEditMode_Activite_Data}> Modifier</Button>
                        </div>
                    </div>
                    }


                </div>}

                {String(add_one_activite) === "1" && <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }} >
                    <div className="training_caract">  Description <br />
                        <TextField
                            required
                            name="activite_description"
                            //label="Description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={one_activite_description}
                            onChange={(e) => setone_activite_description(e.target.value)}

                        />
                    </div>



                    <div className="training_caract">
                        Date souhaitée  <br />
                        <DatePicker
                            name="activite_deadline"
                            id="activite_deadline"
                            selected={new Date(moment(one_activite_deadline, "DD/MM/YYYY HH:mm:ss"))}

                            onChange={(date) => {
                                setone_activite_deadline(format(date, 'dd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            filterTime={filterPassedTime_start}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'

                        />

                    </div>


                    <div className="training_caract">  Type d'activité <br />
                        <TextField
                            required
                            select
                            name="activite_type"
                            //label="Type d'activité"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={one_activite_type}
                            onChange={(e) => setone_activite_type(e.target.value)} >
                            {type_activite_list.map((option) => (
                                <MenuItem key={option.value} value={option.value} style={{"width":"100%", "paddingLeft":"5px"}} >
                                    {option.label} &nbsp; <br />
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>

                    <div className="training_caract">  Statut <br />
                        <TextField
                            required
                            select
                            name="activite_status"
                            //label="Statut"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={one_activite_status}
                            onChange={(e) => setone_activite_status(e.target.value)} >
                            {activite_status_list.map((option) => (
                                <MenuItem key={option.value} value={option.value} >
                                    {option.label} &nbsp; <br />
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>

                    <div className="training_caract">   Assigné à (employé) <br />
                        <TextField

                            id="activite_assigned_to"
                            name="activite_assigned_to"

                            select
                            
                            className="disabled_style"
                          
                            value={p_activite_employee_assigned_id}
                            onChange={(e) => {
                                setp_activite_employee_assigned_id(e.target.value);

                            }
                            }
                        >
                            {Getall_Training_Employee_No_Filter_result &&
                                Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                    style={{"paddingLeft":"5px", "width":"100%"}}>
                                        {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom} <br />

                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>

                    <div className="training_caract" style={{ "width": "105%" }}>  Détail <br />
                        <TextField
                            required
                            name="activite_detail"
                            //label="Détail"
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style_no_border"
                            value={one_activite_detail}
                            onChange={(e) => setone_activite_detail(e.target.value)}

                        />
                    </div>

                    


                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>


                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={record_One_PartnerClient_Activite_Data}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_annule" onClick={Close_add_one_activite}>Annuler</Button>
                        </div>
                    </div>

                </div>}

            </div>

        </div >
    )
}

export default PartnerClientActivite;