import React, { useState, useEffect }  from "react";
import  {BrowserRouter, Switch, Route} from "react-router-dom"
import './App.css';
import NotFound  from './pages/NotFound'
import Contact from './pages/Contact'
import Connexion from './pages/Connexion'
import Login from './pages/Login';
import Recherche from "./pages/recherche_new";
import Recherche_new_v2 from "./pages/recherche_new_v2";
import TestUrl from "./pages/testurl";
import Account from "./pages/account";
import DisplayDetailClass from "./pages/displaydetailclass"; 
import DisplayDetailClass_new_v2 from "./pages/displaydetailclass_new_v2";
import DisplayDetailClassAction from "./pages/displaydetailclassaction";
import DisplayDetailArticle from "./pages/displaydetailarticle";
import Acceuil from "./pages/acceuil";
import Partner from "./pages/partner";
import CreateAccount from "./pages/createaccount";
import PartnerLogin from "./pages/partnerlogin";
import PartnerLogin0 from "./pages/partnerlogin0";
import PartnerLogin1 from "./pages/partnerlogin1";
import Footer from "./pages/footer";
import RechecheArticles from "./pages/recherchearticles";
import AddArticle from "./pages/addarticle";
import ResetUserPwd from "./pages/resetuserpwd";
import DeleteUserAccount from "./pages/deleteuseraccount";
import QuiSommesNous from "./pages/quisommesnous";
import ContactezNous from "./pages/contactez_nous";
import CoupDeCoeur from "./pages/coupdecoeur";
import AccountUserActivated from "./pages/accountuseractivated";
import ProduitsService from "./pages/produitservice";
import scrollToTop from "react-scroll-to-top";
import Recherche_new from "./pages/recherche_new";
import Recherche_old from "./pages/recherche";
import UserSignFirst from "./pages/usersigninfirst";
import GestionAdministrative from "./components/GestionAdministrative";
import Emargement from "./pages/emargementOk";
import Formation_Cartouche_Com from "./pages/recherche_cartouche_com";
import SeDesabonner from './pages/sedesabonner';
import OrderConfirmation_3DS from "./pages/orderconfirmation_3DS";
import Training_Inactive_Or_Not_Found from "./pages/Traning_Inactif_Or_Not_Exist";
import TestUrl_New from "./pages/testurl_new";
import QuiSommesNous_v3 from "./pages/quisommesnous_v3";

function App() {

  const [screenSizeh, getDimension] = useState({
    dynamicWidth: window.innerHeight
  });


  return (
    <div> 
    <div className="App"> 
    {/*API DEV = {process.env.REACT_APP_API_URL_DEV} <br/>
    API REC = {process.env.REACT_APP_API_URL_REC}<br/>
    API URL = {process.env.REACT_APP_API_URL}<br/>
  */}
    <div className="main_haut"> 
        <BrowserRouter>
        <scrollToTop />
            <Switch>
                <Route path="/" exact component={Recherche_new_v2} />
               
                <Route path="/acceuil" exact component={Acceuil} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/mysy-user-account" exact component={Account} />
                <Route path="/create_account" exact component={CreateAccount} />
                <Route path="/user-connexion" exact component={Connexion} />
                <Route path="/Connexion" exact component={Login} />
                <Route path="/recherche-formation" exact component={Recherche_new_v2} />
                <Route path="/recherche-formation/ref/:NewSearchText" exact component={Recherche_new_v2} />
                <Route path="/testurl/:course/:tab" exact component={TestUrl} />
                <Route path="/testurl_new/:courseid/:tabid" exact component={TestUrl_New} />
                <Route path="/Display-Detail-formation/:classId" exact component={DisplayDetailClass_new_v2} />
                <Route path="/Display-Detail-formation/:classId" exact component={DisplayDetailClass_new_v2} />
                <Route path="/Display-Detail-formation/:classId/:action" exact component={DisplayDetailClass_new_v2} />
                <Route path="/Display-Detail-formation_old/:classId/:action" exact component={DisplayDetailClass_new_v2} />
                <Route path="/Display-Detail-Article/:articleId" exact component={DisplayDetailArticle} />
                <Route path="/Display-Detail-formation-Coeur/:classId" exact component={CoupDeCoeur} />
                <Route path="/Partner/:action/:orderid/:packs" exact component={Partner} /> 

                <Route path="/Partner/:action/:orderid" exact component={Partner} /> 

            
                <Route path="/Partner/:action" exact component={Partner} /> 
                <Route path="/Partner" exact component={Partner} />  
                <Route path="/PartnerLogin" exact component={PartnerLogin} />  
                <Route path="/PartnerLogin0" exact component={PartnerLogin0} />  
                <Route path="/PartnerLogin1" exact component={PartnerLogin1} />                                    
                <Route path="/Recherche-Article-formation" exact component={RechecheArticles} />
                <Route path="/Ajout-Article-formation" exact component={AddArticle} /> 
                <Route path="/ResetUserPwd/:token/:accounttype" exact component={ResetUserPwd} />
                <Route path="/DeleteUserAccount/:token" exact component={DeleteUserAccount} />  
                <Route path="/QuiSommesNous" exact component={QuiSommesNous_v3} />
                <Route path="/QuiSommesNous_v3" exact component={QuiSommesNous_v3} />
                <Route path="/ContactezNous" exact component={ContactezNous} />
                <Route path="/mysy-training-login/:obj/:objid" exact component={Login} />
                <Route path="/mysy-account-activated/" exact component={AccountUserActivated} /> 
                <Route path="/Produits-Services/" exact component={ProduitsService} />
                <Route path="/Recherche_new/" exact component={Recherche_new} />
                <Route path="/GestionAdministrative/:internal_url" exact component={GestionAdministrative} />
                <Route path="/Recherche_new/:type/:categorie" exact component={Recherche_new} />
                <Route path="/Recherche_old/" exact component={Recherche_old} /> 
                <Route path="/Connexion-utilisateur-0/" exact component={UserSignFirst} />
                <Route path="/Emargement/" exact component={Emargement} />
                <Route path="/CartoucheComMySy/" exact component={Formation_Cartouche_Com} />
                <Route path="/Recherche_new_v2/" exact component={Recherche_new_v2} />
                <Route path="/Recherche_new_v2/:type/:categorie" exact component={Recherche_new_v2} />
                <Route path="/unsubscribe/" exact component={SeDesabonner} />
                <Route path="/OrderConfirmation_3DS/:orderid/:packs" exact component={OrderConfirmation_3DS} />
                <Route path="/page-inconnue/" exact component={NotFound} />
                <Route path="/formation-inconnue/" exact component={Training_Inactive_Or_Not_Found} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
        </div>
        </div>

        
    </div>
   
  );
}

export default App;
