import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
//import { getCookie, setCookie, removeCookie  } from 'react-use-cookie';
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useParams } from 'react-router-dom'
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import MenuItem from "@material-ui/core/MenuItem";
import Button2 from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { AiOutlineEdit, AiOutlinePlusCircle, AiTwotoneDelete } from "react-icons/ai";
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { set } from "date-fns";


function Pricing() {

    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rowss, setRows] = useState([]);
    const [menu, setmenu] = useState("default");
    const [iseditclientprice, setiseditclientprice] = useState();

    const columns_prix_client = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'price_line_id', headerName: '_id', hide: true },
        { field: 'client', headerName: 'Client', width: 150, flex: 1 },
        { field: 'client_recid', headerName: 'Client Id', width: 150, hide: true },
        { field: 'discount', headerName: 'Réduction (%)', width: 100, hideable: false, flex: 1 },
        { field: 'date_debut', headerName: 'Date début', width: 150, flex: 1 },
        { field: 'date_fin', headerName: 'Date Fin', width: 150, flex: 1 },

        {
            field: "modifier", headerName: 'Modifier',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}
                    >
                        <AiOutlineEdit />

                    </Button>

                );
            }
        },
        {
            field: "supprimer", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (
                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_delete(event, cellValues);
                            }}
                        >
                            <AiTwotoneDelete />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, vous allez definitivement supprimer ce proix du système<br />
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>
                );
            }
        }
    ]


    function handleClick(event, cellValues) {

        var price_line_id = cellValues.row.price_line_id;
        setcurrentlineprice_id(price_line_id);

        setiseditclientprice();
        setisaddprice();
        Clean_notification();

        GetAPrice_Data(price_line_id);

    }

    const [isaddprice, setisaddprice] = useState();
    function handleAddPriceClick() {
        Clean_notification();
        setisaddprice("1");
        setiseditclientprice();
        Desable_new_prices_fields();

    }

    const [GetClienPrices_api, setGetClienPrices_api] = useState()
    const [GetClienPrices_result, setGetClienPrices_result] = useState([]);
    const [GetClienPrices_message, setGetClienPrices_message] = useState()

    function GetClientPrices(event) {
        var form = new FormData();

        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_cust_prices_by_partner/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);


                if (String(data['status']) === String("false")) {

                    //console.log("erreur rrrr:" + data['status'])
                    setGetClienPrices_api("false");

                }
                else if (String(data['status']) === String("true")) {
                    setGetClienPrices_result(data['message']);
                    setGetClienPrices_api("true");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetClienPrices_api("false");
            });

    }

    const [selectedlinked_client, setselectedlinked_client] = useState();


    function changeselectedclient(event) {
        setselectedlinked_client(event.target.value);
        if (String(event.target.value).trim() !== "na") {
            Enable_new_prices_fields();

        } else {
            Desable_new_prices_fields();
        }
    };


    const [GetClient_Class_api, setGetClient_Class_api] = useState()
    const [GetClient_Class_result, setGetClient_Class_result] = useState([]);
    const [GetClient_Class_message, setGetClient_Class_message] = useState()

    function GetClientClass(event) {
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);


                if (String(data['status']) === String("false")) {

                    //console.log("erreur rrrr:" + data['status'])
                    setGetClient_Class_api("false");

                }
                else if (String(data['status']) === String("true")) {
                    setGetClient_Class_result(data['message']);
                    setGetClient_Class_api("true");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetClient_Class_api("false");
            });

    }

    const [GetClient_Linked_api, setGetClient_Linked_api] = useState()
    const [GetClient_Linked_result, setGetClient_Linked_result] = useState([]);
    const [GetClient_Linked_message, setGetClient_Linked_message] = useState()

    function GetClientlinked(event) {
        var form = new FormData();

        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_linked_customer_to_partner/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);


                if (String(data['status']) === String("false")) {

                    //console.log("erreur rrrr:" + data['status'])
                    setGetClient_Linked_api("false");

                }
                else if (String(data['status']) === String("true")) {
                    setGetClient_Linked_result(data['message']);
                    setGetClient_Linked_api("true");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetClient_Linked_api("false");
            });

    }


    function Enable_new_prices_fields() {


        if (document.getElementsByName("new_discount") && document.getElementsByName("new_discount")[0]) {
            document.getElementsByName("new_discount")[0].disabled = false;
            document.getElementsByName("new_discount")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("new_sessiondatedebut") && document.getElementsByName("new_sessiondatedebut")[0]) {
            document.getElementsByName("new_sessiondatedebut")[0].disabled = false;
            document.getElementsByName("new_sessiondatedebut")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("new_sessiondatefin") && document.getElementsByName("new_sessiondatefin")[0]) {
            document.getElementsByName("new_sessiondatefin")[0].disabled = false;
            document.getElementsByName("new_sessiondatefin")[0].style.backgroundColor = "#FFFFFF";
        }

    }

    function Desable_new_prices_fields() {


        if (document.getElementsByName("new_discount") && document.getElementsByName("new_discount")[0]) {
            document.getElementsByName("new_discount")[0].disabled = true;
            document.getElementsByName("new_discount")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("new_sessiondatedebut") && document.getElementsByName("new_sessiondatedebut")[0]) {
            document.getElementsByName("new_sessiondatedebut")[0].disabled = true;
            document.getElementsByName("new_sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";            

        }

        if (document.getElementById("new_sessiondatefin")) {
            document.getElementById("new_sessiondatefin").disabled = true;
            document.getElementById("new_sessiondatefin").style.backgroundColor = "#ECEFF1";
          

        }
    }


    function Clean_new_prices_fields() {


        if (document.getElementsByName("new_discount") && document.getElementsByName("new_discount")[0]) {
            document.getElementsByName("new_discount")[0].disabled = true;
            document.getElementsByName("new_discount")[0].style.backgroundColor = "#ECEFF1";
            setnew_discount_val("");
        }

        if (document.getElementsByName("new_sessiondatedebut") && document.getElementsByName("new_sessiondatedebut")[0]) {
            document.getElementsByName("new_sessiondatedebut")[0].disabled = true;
            document.getElementsByName("new_sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";
            setNew_SessionstartDate("");
        }

        if (document.getElementsByName("new_sessiondatefin") && document.getElementsByName("new_sessiondatefin")[0]) {
            document.getElementsByName("new_sessiondatefin")[0].disabled = true;
            document.getElementsByName("new_sessiondatefin")[0].style.backgroundColor = "#ECEFF1";
            setNew_SessionendDate("");
        }

    }

    function Clean_prices_fields() {


        if (document.getElementsByName("discount") && document.getElementsByName("discount")[0]) {
            document.getElementsByName("discount")[0].disabled = true;
            document.getElementsByName("discount")[0].style.backgroundColor = "#ECEFF1";
            setnew_discount_val("");
        }

        if (document.getElementsByName("sessiondatedebut") && document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = true;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";
            setNew_SessionstartDate("");
        }

        if (document.getElementsByName("sessiondatefin") && document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = true;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#ECEFF1";
            setNew_SessionendDate("");
        }
    }

    function DataUpdated() {
        Clean_notification();

    }

    useEffect(() => {
        GetClientPrices();
        GetClientlinked();
        GetClientClass();
    }, []);

    let [sessionStatus, setsessionStatus] = useState(false);
    let [sessionChanged, setsessionChanged] = useState(false);

    const [SessionstartDate, setSessionstartDate] = useState("");
    const [New_SessionstartDate, setNew_SessionstartDate] = useState("");

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    const [SessionendDate, setSessionendDate] = useState("");
    const [New_SessionendDate, setNew_SessionendDate] = useState("");

    const filterPassedTime_end = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const [discount_val, setdiscount_val] = useState("");

    const [new_discount_val, setnew_discount_val] = useState("");



    const Change_new_discount_val = (event) => {
        const value = event.target.value;
        if (isNaN(value)) {
            alert(" Valeur incorrecte. ");

        } else {
            setnew_discount_val(value);

        }

    }

    const Change_discount_val = (event) => {
        const value = event.target.value;
        if (isNaN(value)) {
            alert(" Valeur incorrecte. ");

        } else {
            setdiscount_val(value);

        }

    }

    const [Record_new_Price_api, setRecord_new_Price_api] = useState("");
    const [Record_new_Price_result, setRecord_new_Price_result] = useState("");
    const [Record_new_Price_message, setRecord_new_Price_message] = useState("");

    function Record_new_Price() {
        Clean_notification();

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/add_business_price/";

        if (String(selectedlinked_client).trim() === "na") {
            alert(" Le client n'est pas identifié");
            return;
        }

        if (String(new_discount_val).trim() === "") {
            alert(" Il manque la réduction");
            return;
        }

        var local_date_debut = ""
        if (!New_SessionstartDate || String(New_SessionstartDate).trim() === "") {
            alert(" Vous devez definir la date d'application de la réduction ");
            return;
        } else {
            local_date_debut = moment(New_SessionstartDate).format('DD/MM/YYYY');
        }

        var local_date_fin = ""
        if (!New_SessionendDate || String(New_SessionendDate).trim() === "") {
            alert(" Vous devez definir la date de fin de la réduction ");
            return;

        } else {
            local_date_fin = moment(New_SessionendDate).format('DD/MM/YYYY');
        }


        // var class_internal_url = document.getElementsByName("adr_country")[0].value;
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append('client_recid', selectedlinked_client);
        formData.append('discount', new_discount_val);
        formData.append('date_du', local_date_debut);
        formData.append('date_au', local_date_fin);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setRecord_new_Price_api(result['status']);
                setRecord_new_Price_result(result['status']);
                setRecord_new_Price_message(result['message']);

                if (String(result['status']) === "true") {
                    Clean_new_prices_fields();
                    GetClientPrices();
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setRecord_new_Price_api("false");
                setRecord_new_Price_result("false");
                setRecord_new_Price_message(" Impossible de créer le prix");
            });


    };


    const [currentlineprice_id, setcurrentlineprice_id] = useState("");

    const [Update_Price_api, setUpdate_Price_api] = useState("");
    const [Update_Price_result, setUpdate_Price_result] = useState("");
    const [Update_Price_message, setUpdate_Price_message] = useState("");

    function Clean_notification() {
        setRecord_new_Price_api("");
        setRecord_new_Price_result("");
        setRecord_new_Price_message("");
        setDelete_Price_api("");
        setUpdate_Price_api("");

    }

    function Update_Price() {

        Clean_notification();

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_business_price/";

        if (String(discount_val).trim() === "") {
            alert(" Il manque la réduction");
            return;
        }

        var local_date_debut = ""
        if (!SessionstartDate || String(SessionstartDate).trim() === "") {
            alert(" Vous devez definir la date d'application de la réduction ");
            return;
        } else {
            local_date_debut = moment(SessionstartDate).format('DD/MM/YYYY');
        }

        var local_date_fin = ""
        if (!SessionendDate || String(SessionendDate).trim() === "") {
            alert(" Vous devez definir la date de fin de la réduction ");
            return;

        } else {
            local_date_fin = moment(SessionendDate).format('DD/MM/YYYY');
        }


        // var class_internal_url = document.getElementsByName("adr_country")[0].value;
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append('price_id', currentlineprice_id);
        formData.append('discount', discount_val);
        formData.append('date_du', local_date_debut);
        formData.append('date_au', local_date_fin);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setUpdate_Price_api(result['status']);
                setUpdate_Price_result(result['status']);
                setUpdate_Price_message(result['message']);

                if (String(result['status']) === "true") {
                    Clean_prices_fields();
                    GetClientPrices();
                    setiseditprice(false);
                    GetClientPrices();
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setUpdate_Price_api("false");
                setUpdate_Price_result("false");
                setUpdate_Price_message(" Impossible de créer le prix");
            });


    };


    const [Delete_Price_api, setDelete_Price_api] = useState("");
    const [Delete_Price_result, setDelete_Price_result] = useState("");
    const [Delete_Price_message, setDelete_Price_message] = useState("");

    function Delete_Price(localprice_line_id) {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/delete_business_price/";



        // var class_internal_url = document.getElementsByName("adr_country")[0].value;
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append('price_id', localprice_line_id);


        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setDelete_Price_api(result['status']);
                setDelete_Price_result(result['status']);
                setDelete_Price_message(result['message']);

                if (String(result['status']) === "true") {

                    GetClientPrices();
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setDelete_Price_api("false");
                setDelete_Price_result("false");
                setDelete_Price_message(" Impossible de créer le prix");
            });


    };


    function Close_Price_windows() {
        setisaddprice();

        Clean_notification();

    }

    function handleClick_delete(event, cellValues) {
        Clean_notification();

        var price_line_id = cellValues.row.price_line_id;
        setcurrentlineprice_id(price_line_id);
        setisaddprice();
        setiseditclientprice();
        Delete_Price(price_line_id);

    }

    // Cette fonction recupere une ligne de prix donnée


    const [client_nom, setclient_nom] = useState();


    const [GetAPrice_api, setGetAPrice_api] = useState();
    const [GetAPrice_message, setGetAPrice_message] = useState();
    const [GetAPrice_result, setGetAPrice_result] = useState();
    async function GetAPrice_Data(price_line_id) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("price_line_id", price_line_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_cust_specific_prices_by_partner/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetAPrice  res.data.status = " + res.data.status);
                //console.log(" In GetAPrice  res.data.message r_class = " + res.data.message);
                setiseditclientprice("1");
                setGetAPrice_api("true");
                setGetAPrice_result(res.data.message);
                var mylocalprice = JSON.parse(res.data.message);


                if (mylocalprice.client_nom) {
                    setclient_nom(mylocalprice.client_nom);
                }

                if (mylocalprice.date_du) {
                    var date_du = new Date(moment(mylocalprice.date_du, "DD/MM/YYYY"));
                    setSessionstartDate(date_du);
                }

                if (mylocalprice.date_au) {
                    var date_au = new Date(moment(mylocalprice.date_au, "DD/MM/YYYY"));
                    setSessionendDate(date_au);
                }

                if (mylocalprice.discount) {
                    setdiscount_val(mylocalprice.discount);

                }
                Desable_exist_prices_fields();
            }
            else {
                setGetAPrice_api("false");
                setGetAPrice_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetAPrice = ', error);
            setGetAPrice_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [iseditprice, setiseditprice] = useState();
    function EditPrice() {
        Clean_notification();

        setiseditprice(true);
        Enable_exist_prices_fields();

    }


    function Enable_exist_prices_fields() {


        if (document.getElementsByName("discount") && document.getElementsByName("discount")[0]) {
            document.getElementsByName("discount")[0].disabled = false;
            document.getElementsByName("discount")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("sessiondatedebut") && document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = false;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("sessiondatefin") && document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = false;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#FFFFFF";
        }
    }

    function Desable_exist_prices_fields() {


        if (document.getElementsByName("discount") && document.getElementsByName("discount")[0]) {
            document.getElementsByName("discount")[0].disabled = true;
            document.getElementsByName("discount")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatedebut") && document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = true;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatefin") && document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = true;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#ECEFF1";
        }


    }




    function DataUpdated_exist_price() {

    }

    function cancel_update_price() {

        setiseditclientprice();
        setisaddprice();
        Clean_notification();

        GetAPrice_Data(currentlineprice_id);
        setiseditprice(false);
    }

    return (
        <div className="pricing">

            <h2 style={{ "textAlign": "center" }}> Gestion des prix </h2>

            {String(Update_Price_api) === "true" &&
                <div className="okUpdateData"> Les prix à a été correctement mis à jour</div>}

            {String(Update_Price_api) === "false" &&
                <div className="koUpdateData"> {Update_Price_message}</div>}

            {String(Delete_Price_api) === "true" &&
                <div className="okUpdateData"> Les prix à a été correctement supprimé</div>}

            {String(Delete_Price_api) === "false" &&
                <div className="koUpdateData"> {Delete_Price_message}</div>}

            {String(iseditclientprice) === "1" && <div>
                Modification du prix
                 &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }} onChange={DataUpdated_exist_price}>

                    <TextField
                        required
                        label="Client"
                        name="client"
                        value={client_nom}
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />

                    <TextField
                        label="Reduction (%)"
                        name="discount"
                        id="discount"
                        value={discount_val}
                        onChange={Change_discount_val}
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />

                    

                    <div className="div_row">
                        <div className="div_row_gauche">
                            <nav className="my_label"> Date début </nav>
                            <DatePicker
                                name="sessiondatedebut"
                                id="sessiondatedebut"
                                selected={SessionstartDate}
                                onChange={(date) => {
                                    var date_du = new Date(moment(date, "DD/MM/YYYY")).setHours(8, 0, 0);
                                    setSessionstartDate(date_du);
                                    setsessionChanged(true);
                                }
                                }
                                showTimeSelect={false}
                                filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="texte_area"
                                locale='fr-FR'

                            />
                        </div>

                        <div className="div_row_droite div_row_droite_adapt_date">
                            <nav className="my_label"> Date Fin la </nav>
                            <DatePicker
                                name="sessiondatefin"
                                id="sessiondatefin"
                                selected={SessionendDate}
                                onChange={(date) => {
                                    var date_au = new Date(moment(date, "DD/MM/YYYY")).setHours(8, 0, 0);
                                    setSessionendDate(date_au);
                                    setsessionChanged(true);
                                }
                                }

                                showTimeSelect={false}
                                filterTime={filterPassedTime_end}
                                dateFormat="dd/MM/yyyy"
                                className="texte_area"
                            />
                        </div>
                    </div>

                </Box>

                <div className="div_row">&nbsp;
                </div>

                <div className="div_row">

                    <div className="div_row_gauche">

                        {iseditprice && <Button variant="contained" className="btn_enreg" onClick={Update_Price}>Mettre à jour le prix
                        </Button>}


                    </div>

                    <div className="div_row_droite div_row_droite_adapt_date">

                        {!iseditprice && <Button variant="contained" className="btn_editer" onClick={EditPrice}>Editer
                        </Button>}

                        {iseditprice && <Button variant="contained" className="btn_editer" onClick={cancel_update_price}>Annuler les modifications
                        </Button>}
                    </div>

                </div>
                <div className="div_row">
                    <hr />
                </div>
            </div>

            }

            {String(Record_new_Price_api) === "true" &&
                <div className="okUpdateData"> Les prix à a été correctement ajouté</div>}

            {String(Record_new_Price_api) === "false" &&
                <div className="koUpdateData"> {Record_new_Price_message}</div>}

            {String(isaddprice) === "1" && <div>
                Creation d'un nouveau prix client
                 &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }} onChange={DataUpdated}>

                    <div className="div_row">
                        <div className="div_row_gauche">
                            <nav className="my_label"> Client </nav>
                            {GetClient_Linked_result && <div className="field_droite">
                                <select id="selected_client"
                                    name="selected_client"
                                    value={selectedlinked_client} onChange={changeselectedclient}

                                    style={{
                                        "width": "90%", "height": "3rem"

                                    }}>
                                    <option value="na">  Choisir </option>
                                    {GetClient_Linked_result.map((option) => (
                                        <option value={JSON.parse(option).recid}>{JSON.parse(option).nom}</option>
                                    ))}
                                    <option value="all"> Tout client </option>
                                </select>
                            </div>}
                        </div>
                        <div className="div_row_droite div_row_droite_adapt_date">

                            <TextField
                                label="Reduction (%)"
                                name="new_discount"
                                id="new_discount"

                                value={new_discount_val}
                                onChange={Change_new_discount_val}
                                sx={{ m: 1, width: '90%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                style={{ "marginLeft": "0px" }}
                            />

                        </div>
                    </div>

                 

                    <div className="div_row">
                        <div className="div_row_gauche">
                            <nav className="my_label"> Date début </nav>

                            <DatePicker
                                name="new_sessiondatedebut"
                                id="new_sessiondatedebut"
                                selected={New_SessionstartDate}
                                onChange={(date) => {
                                    var date_du = new Date(moment(date, "DD/MM/YYYY")).setHours(8, 0, 0);
                                    setNew_SessionstartDate(date_du);
                                    setsessionChanged(true);
                                }
                                }
                                showTimeSelect={false}
                                filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="texte_area"
                                locale='fr-FR'

                            />
                        </div>
                        <div className="div_row_droite div_row_droite_adapt_date">

                            <nav className="my_label"> Date Fin</nav>
                            <DatePicker
                                name="new_sessiondatefin"
                                id="new_sessiondatefin"
                                selected={New_SessionendDate}
                                onChange={(date) => {
                                    var date_au = new Date(moment(date, "DD/MM/YYYY")).setHours(8, 0, 0);
                                    setNew_SessionendDate(date_au);
                                    setsessionChanged(true);
                                }
                                }
                                showTimeSelect={false}
                                filterTime={filterPassedTime_end}
                                dateFormat="dd/MM/yyyy"
                                className="texte_area"
                            />
                        </div>
                    </div>

                </Box>

                <div className="div_row">&nbsp;
                </div>
                <div className="div_row">

                    <div className="div_row_gauche">

                        <Button variant="contained" className="btn_enreg" onClick={Record_new_Price}>Enregistrer le prix
                        </Button>
                    </div>

                    <div className="div_row_droite">

                        <Button variant="contained" className="btn_editer" onClick={Close_Price_windows}>Fermer
                        </Button>
                    </div>


                </div>
                <div className="div_row">
                    <hr />
                </div>
            </div>

            }
            <hr />
            <div className="div_row" style={{ "marginBottom": "0px", "marginTop": "1rem" }}>
                <Button className="buton_add_price" onClick={handleAddPriceClick}
                > Ajouter un nouveau prix <AiOutlinePlusCircle /> </Button>
            </div>


            Mes prix client
            {String(menu) === String("default") && GetClienPrices_result && GetClienPrices_result.length > 0 && <div>
                <div className="titre1">
                    Les prix que vous accordez à vos clients
                </div>
                {String(GetClienPrices_api) === "false" && <div className='koUpdateData'>
                    Impossible de traiter la demande
                </div>}
                {String(GetClienPrices_api) === "true" &&
                    <div style={{ height: 700, width: '100%' }}>
                        <DataGrid
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                                //console.log("ch selected--" + newSelectionModel);
                            }}
                            selectionModel={selectionModel}


                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            rows={GetClienPrices_result.map((item, index) => (
                                {
                                    id: index,
                                    client: JSON.parse(item).client_nom,
                                    client_recid: JSON.parse(item).client_recid,
                                    discount: JSON.parse(item).discount,
                                    date_debut: JSON.parse(item).date_du,
                                    date_fin: JSON.parse(item).date_au,
                                    price_line_id: JSON.parse(item)._id,
                                    formation: JSON.parse(item).class,

                                }
                            ))}

                            columns={columns_prix_client}
                            pageSize={10}
                            className="datagridclass"

                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}


                        />


                        <br />
                        {/* <Button variant="contained" onClick={GetAllClass_new}>Contained</Button>*/}
                    </div>}

            </div>}

            {/*<h2> Mes prix fournisseur</h2>*/}
        </div>
    );
}

export default Pricing;

