import React from "react";
import Navigation from "../components/Navigation";
import bannerimg2 from "./../mysy_img/MYSY-LOGO-BLUE.png";
import { Helmet } from "react-helmet";
import Footer from "./../components/Fotter";
import Header from "./../components/Header";


const NotFound = () => {
  return (
    <div className="notfound">
      <div>
        <Navigation />
      </div>
      <Helmet>
        <title>MySy Training, qui sommes nous ?</title>
        <meta name="description" content="Nous sommes une base de données uniquement dediée aux formations et tutoriels" />
        <meta name="robots" content="index,follow" />
        <meta name="title" content="Trouver des Formations.Acces libre.Qualité top" />
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/QuiSommesNous"} />

      </Helmet>
      <Header />

 
      <div className="div_mobile">
        <div className="nom_prenom"> Le site MySy Training Technology ne connait pas cette page</div>
        <div className="nom_prenom"> <a href={process.env.REACT_APP_BASE_URL}> Retour vers le moteur de recherche </a> </div>
      </div>
      <div className="pieddepage">
        <Footer />
      </div>


    </div>
  )
}

export default NotFound;