import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcSearch } from "react-icons/fc";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcAlarmClock, FcMultipleSmartphones, FcWorkflow } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RestoreFromTrashRounded } from "@mui/icons-material";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';
import ToggleSwitch from "./ToggleSwitch";
import Module_Historique_Action from "./Module_Historique_Action";
import { HiDocumentCheck } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";
import Autocomplete from '@mui/material/Autocomplete';
import { FcOpenedFolder } from "react-icons/fc";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";


const DisplayPartnerSession = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [actionmass_preinsc_val, setactionmass_preinsc_val] = useState();
    const [one_SessionstartDateInscription, setone_SessionstartDateInscription] = useState();
    const [one_SessionendDateInscription, setone_SessionendDateInscription] = useState();
    const [one_SessionendDate, setone_SessionendDate] = useState();
    const [one_SessionstartDate, setone_SessionstartDate] = useState();
    const [one_urlpreview_certif, setone_urlpreview_certif] = useState();
    const [one_field_contenu_ftion, setone_field_contenu_ftion] = useState("");

    const filters = [
        { value: 'code_session', label: 'Code Session' },
        { value: 'class_title', label: 'Titre Formation' },
        { value: 'certif', label: 'Certification' }
    ]

    const [selectedfilt1, setselectedfilt1] = useState("");
    const [selectedfilt2, setselectedfilt2] = useState("");
    const [selectedfilt3, setselectedfilt3] = useState("");
    const [reftrainingtoupdate, setreftrainingtoupdate] = useState();
    const [externalcodetraining_toupdate, setexternalcodetraining_toupdate] = useState();
    const [updatetraining, setupdatetraining] = useState(false);

    function Clearselectedfilt1() {
        setselectedfilt1("");
    }

    function selectedFilter1(event) {
        setselectedfilt1(event.value);
    }

    function selectedFilter2(event) {
        setselectedfilt2(event.value);
    }

    function selectedFilter3(event) {
        setselectedfilt3(event.value);
    }

    function CancelFiltre2(event) {
        setselectedfilt2("");
    }

    function CancelFiltre3(event) {
        setselectedfilt3("");
    }

    function closeTrainingForm() {
        setupdatetraining(false);
    }

    const [selectedtraining, setselectedtraining] = useState([])

    const BorderLinearProgress = styled(LinearProgress)(() => ({
        height: "20px",
        width: "60px",
        borderRadius: "2px",
        backgroundColor: " #ebf5fb",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#1bd900",
            transition: "none",
            transformOrigin: "left",
        },
    }));

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                {expanded ? value : value.slice(0, 50)}&nbsp;
                {value.length > 50 && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                        type="button"
                        component="button"
                        sx={{ fontSize: 'inherit' }}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                    </Link>
                )}
            </div>
        );
    }

    const [datagrid_columns_size_model1, setdatagrid_columns_size_model1] = useState(200);
    const [datagrid_columns_size_model2, setdatagrid_columns_size_model2] = useState(100);

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'class_internal_url', headerName: 'class_internal_url', hide: true },
        { field: 'code_session', headerName: 'Code session', hide: false, minWidth: 100, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'session_title', headerName: 'Titre', hide: false, flex: 1, minWidth: 250, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        {
            field: 'statut', headerName: 'Statut', hide: true, width: datagrid_columns_size_model2,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Inactif";
                else if (String(params.value) === "1")
                    return "Actif";
                else
                    return "?";
            },
        },

        {
            field: 'etape', headerName: 'Etape', hide: false, width: datagrid_columns_size_model2,

        },
        { field: 'class_external_code', headerName: 'Code Formation', align: "center", hideable: true, flex: 1, hide: true, minWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'date_debut', headerName: 'Debut', hide: false, width: datagrid_columns_size_model2, },
        { field: 'date_fin', headerName: 'Fin', hideable: false, width: datagrid_columns_size_model2, },
        {
            field: 'distantiel', headerName: 'Distantiel', hideable: false, width: datagrid_columns_size_model2,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";
                else
                    return "?";
            },
        },
        {
            field: 'presentiel', headerName: 'Presentiel', width: datagrid_columns_size_model2,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";
                else
                    return "?";
            },
        },
        { field: 'formateur', headerName: 'Formateur', minWidth: datagrid_columns_size_model1, hide: true, hideable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nb_participant', headerName: 'Nb Parti.', minWidth: datagrid_columns_size_model2, align: "center", hide: true, },
        {
            field: 'taux_remplissage', headerName: 'Remplissage', align: "center", flex: 1, minWidth: 100, maxWidth: 150, flex: 1,
            align: "left",
            renderCell: (params) => {
                return (
                    <>
                        <BorderLinearProgress
                            color="success"
                            sx={{ color: "#1bd900" }}
                            variant="determinate"
                            value={(params?.row.taux_remplissage * 100).toFixed(2)}
                        />
                        <Typography
                            variant="body2"
                            color="text.secondary"
                        >{`${(params?.row.taux_remplissage * 100).toFixed(0)}%`}</Typography>
                    </>
                );
            },
        },
        { field: 'nb_inscrit', headerName: 'Inscrits', minWidth: 20, align: "center", hide: true, hideable: true, },
        { field: 'nb_preinscrit', headerName: 'Preinscrits', minWidth: 20, align: "center", hide: true, hideable: true, },
        { field: 'prix_session', headerName: 'Prix', minWidth: 20, align: "center", },
        { field: 'title', headerName: 'Formation', minWidth: 250, flex: 1, align: "center", hide: true, hideable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'domaine', headerName: 'Domaine', minWidth: datagrid_columns_size_model1, align: "center", hide: true, hideable: true, },
        { field: 'published', headerName: 'publié', minWidth: datagrid_columns_size_model1, align: "center", hide: true, hideable: true, },

        { field: 'duration', headerName: 'Durée', minWidth: datagrid_columns_size_model2, align: "center", hide: true, hideable: true, },
        {
            field: "Detail", headerName: 'Voir détail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            setLoading(true);
                            setsessionChanged(false);
                            handleClick_edit_session_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                //handleClick_delete_Session_Formation(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la session de formation sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Session_Formation(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },


    ]

    const columns_preinscrit = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'civilite', headerName: 'Civ.', minWidth: 70, flex: 1, maxWidth: 100, hide: false, editable: false, editable: false },
        { field: 'nom', headerName: 'nom', minWidth: 150, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minWidth: 150, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minWidth: 150, flex: 1, hideable: false, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'client_nom', headerName: 'Client', minWidth: 150, flex: 1, maxWidth: 200, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'employeur', headerName: 'Employeur', minWidth: 150, flex: 1, maxWidth: 200, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'opco', headerName: 'Centre Opco', minWidth: 100, flex: 1, maxWidth: 150, hide: false, editable: true },
        { field: 'modefinancement', headerName: 'Financement', minWidth: 100, flex: 1, maxWidth: 150, hide: false, editable: true },
        { field: 'amount', headerName: 'Montant Formation', minWidth: 100, flex: 1, maxWidth: 150, type: 'number', hideable: false, flex: 1, editable: true },
        {
            field: "update", headerName: 'Mise à jour',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_accept(event, cellValues);
                            }}
                        >
                            <FcAcceptDatabase />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    Confirmer la mise à jour des informations du stagiaire
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_update_data(event, cellValues);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>

                );
            }
        },

        {
            field: "valide", headerName: 'Valider',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_accept(event, cellValues);
                            }}
                        >
                            <FcApproval />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera définitivement validée. <br />
                                    Il (le stagiaire) sera automatiquement notifié par email de la validation de son inscription. <br />
                                    Le stagiaire passera de l'onglet "préinscrit" vers l'onglet "inscription".<br />
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_accept(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>

                );
            }
        },

        {
            field: "refuse", headerName: 'Refuser',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_refuse(event, cellValues);
                            }}
                        >
                            <FcCancel />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> refusée</font></i>. <br />
                                    Il (le stagiaire) sera automatiquement <i><font color="red"> notifié par email du refus de son inscription. </font></i> <br />
                                    <br /> Motif du refus : <br />
                                    <textarea name='motif_refus' id="motif_refus" style={{ "width": "80%" }}
                                        rows="3" maxlength="180" />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_refuse(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const columns_inscrit = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'civilite', headerName: 'Civ.', minWidth: 70, flex: 1, maxWidth: 100, hide: false, editable: false, editable: false },
        { field: 'nom', headerName: 'nom', minWidth: 150, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minWidth: 150, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minWidth: 150, flex: 1, hideable: false, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'client_rattachement_nom', headerName: 'Client', minWidth: 150, flex: 1, maxWidth: 200, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'employeur', headerName: 'Employeur', minWidth: 150, flex: 1, maxWidth: 200, hide: false, editable: true },
        { field: 'opco', headerName: 'Centre Opco', minWidth: 100, flex: 1, maxWidth: 150, hide: false, editable: true },
        { field: 'modefinancement', headerName: 'Financement', minWidth: 100, flex: 1, maxWidth: 150, hide: false, editable: true },
        { field: 'amount', headerName: 'Montant Formation', minWidth: 100, flex: 1, maxWidth: 150, type: 'number', hideable: false, flex: 1, editable: true },
        {
            field: "sendmail", headerName: 'Email Confirmation',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick_resend_conf_mail(event, cellValues);
                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        },

        {
            field: "Detail", headerName: 'Voir détail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            if (cellValues.row._id && String(cellValues.row._id).length > 2) {
                                var tmp = selected_code_session + "&" + selected_session_id + "&" + selected_internal_url + "&" + cellValues.row.email;

                                window.open(
                                    process.env.REACT_APP_FRONT_URL + "Partner/mes_stagiaires/" + String(cellValues.row._id) + "/" + String(tmp),
                                    '_blank'
                                );
                            }
                            //Display_Detail_Inscription(event, cellValues);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: "Imprimer", headerName: 'Imprimer detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Print_Detail_Inscription(event, cellValues);
                        }}
                    >
                        <FcPrint />

                    </Button>

                );
            }
        },
        {
            field: "annulation", headerName: 'Annulation',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_refuse(event, cellValues);
                            }}
                        >
                            <FcCancel />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> définitivement annulée</font></i>. <br />
                                    Il (le stagiaire) sera automatiquement <i><font color="red"> notifié par email de l'annulation de son inscription. </font></i> <br />
                                    <br /> Motif du annulation : <br />
                                    <textarea name='motif_refus' id="motif_refus" style={{ "width": "80%" }}
                                        rows="3" maxlength="180" />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_refuse(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },



    ]

    const columns_list_emargement = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'date', headerName: 'date', width: 100, hide: true, flex: 1 },
        { field: 'sequence_start', headerName: 'Debut', width: 100, hideable: false, flex: 1 },
        { field: 'sequence_end', headerName: 'Fin', width: 100, hideable: false, flex: 1 },
        { field: 'is_present', headerName: 'Présent', width: 100, hide: false, editable: true, type: 'boolean', },

        { field: 'email', headerName: 'email', width: 250, hideable: false, flex: 1 },
        { field: 'nom', headerName: 'nom', width: 150, hide: false, editable: true },
        { field: 'prenom', headerName: 'prenom', width: 150, hide: false, editable: false },

        {
            field: "valide", headerName: 'Valider',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick_valide_emargement(event, cellValues);
                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        },

    ]

    const columns_list_evaluation = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'email', headerName: 'email', width: 200, hideable: true, resizable: true },
        { field: 'nom', headerName: 'nom', width: 200, hide: false, editable: true },
        { field: 'prenom', headerName: 'prenom', width: 200, hide: false, editable: false },
        {
            field: 'eval_status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Envoyé";
                else if (String(params.value) === "1")
                    return "Evalué";
                else
                    return "A envoyer";
            },
        },
        { field: 'eval_eval', headerName: 'Evaluation', width: 100, editable: false, flex: true },
        { field: 'eval_note', headerName: 'Note', width: 100, editable: false, },
        { field: 'eval_pedagogie', headerName: 'Pédagogie', width: 100, editable: false, },
        { field: 'eval_date', headerName: 'Date evalution', width: 100, editable: false, },
        {/*
            field: "valide", headerName: 'Valider',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick_valide_emargement(event, cellValues);
                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        */},

    ]


    const columns_sequence = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'session_id', headerName: 'session_id', width: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'sequence_title', headerName: 'Titre', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'sequence_start', headerName: 'Debut', minWidth: 200, flex: 1, hide: false, editable: false },
        { field: 'sequence_end', headerName: 'Fin', minWidth: 200, flex: 1, hide: false, editable: false },


        {
            field: "Detail", headerName: 'Voir detail', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            setselected_detail_sequence_title(cellValues.row.sequence_title);

                            setselected_sequence_startDate(cellValues.row.sequence_start);
                            setselected_sequence_endDate(cellValues.row.sequence_end);

                            Getall_List_Sequence_Ressource(cellValues.row._id);

                            setselected_sequence_id(cellValues.row._id);
                            setdisplay_detail_sequence("1");

                            if (document.getElementById('myRef_seq_ressource')) {
                                var divh = document.getElementById('myRef_seq_ressource').offsetTop;
                                window.scrollTo({
                                    top: divh,
                                    behavior: "smooth",
                                });
                            }

                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "modifier", headerName: 'Modifier', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            setp_detail_sequence_title(cellValues.row.sequence_title);
                            setselected_sequence_id(cellValues.row._id);
                            var date_du = new Date(moment(cellValues.row.sequence_start, "DD/MM/YYYY HH:mm"));
                            var date_au = new Date(moment(cellValues.row.sequence_end, "DD/MM/YYYY HH:mm"));

                            setsequence_startDate(date_du);
                            setsequence_endDate(date_au);
                            setupdate_sequence("1");
                            submenu_update_sequence();
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la séquence sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            Delete_Sequence_Session_Data(cellValues.row._id);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const columns_sequence_ressources_affectation = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true, minWidth: 200, },
        { field: 'related_target_collection_id', headerName: 'related_target_collection_id', minWidth: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'related_target_collection', headerName: 'related_target_collection', minWidth: 150, hide: true, editable: false, resizable: true },
        { field: 'type_ressource', headerName: 'Type', minWidth: 150, hide: true, editable: false },
        { field: 'related_target_collection_object', headerName: 'object', minWidth: 200, hide: false, editable: false, resizable: true, flex: 1, },
        { field: 'related_target_collection_id_nom', headerName: 'nom', minWidth: 200, hide: false, editable: false, resizable: true, flex: 1, },
        { field: 'poste', headerName: 'Poste', minWidth: 200, hide: false, editable: false, flex: 1, },
        { field: 'comment', headerName: 'Comment', width: 200, hide: false, editable: false },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ressource sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {

                                            Delete_Sequence_Session_Ressource_Data(cellValues.row._id);
                                            console.log('cellValues.row =  ', cellValues.row);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const [Getall_TrainingSession_api, setGetall_TrainingSession_api] = useState();
    const [Getall_TrainingSession_message, setGetall_TrainingSession_message] = useState();
    const [Getall_TrainingSession_result, setGetall_TrainingSession_result] = useState();
    function Getall_TrainingSession(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;


        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionPartner_List/";


        // Verification s'il faut faire une recherche avec une option like.
        //console.log(" p_filtre1 = ", p_filtre1, "   filtre1_value = ", filtre1_value);

        if (p_filtre1_value || p_filtre2_value) {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionPartner_List_filter_like/";
        }


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingSession  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingSession  res.data.message r_class = " + res.data.message);
                setGetall_TrainingSession_api("true");
                setGetall_TrainingSession_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_TrainingSession_api("false");
                setGetall_TrainingSession_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_TrainingSession = ', error);
            setGetall_TrainingSession_api("false");
            alert("Impossible de lancer la recherche");
            //setmyApimyApiMessage("")
        })
    }


    function Getall_TrainingSession_no_filter(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionPartner_List/";


        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingSession  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingSession  res.data.message r_class = " + res.data.message);
                setGetall_TrainingSession_api("true");
                setGetall_TrainingSession_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_TrainingSession_api("false");
                setGetall_TrainingSession_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_TrainingSession = ', error);
            setGetall_TrainingSession_api("false");
            //setmyApimyApiMessage("")
        })
    }



    const [Edite_session, setEdite_session] = useState();
    /* Envoi de l'envoi de l'email de confirmation de la validation de l'inscription */
    function handleClick_edit_session(event, cellValues) {


        var code_session = cellValues.row.code_session;
        setselected_code_session(cellValues.row.code_session);

        var internal_url = cellValues.row.class_internal_url;
        setselected_internal_url(cellValues.row.class_internal_url);

        var session_id = cellValues.row._id;
        setselected_session_id(session_id);


        setselected_class_title(cellValues.row.title);


        setEdite_session("1");
        submenu_detail_session();


        GetCurrentSession(cellValues.row._id, cellValues.row.class_internal_url);

        GetListePreinscrit(cellValues.row._id, cellValues.row.class_internal_url);

        GetListeInscrit(cellValues.row._id, cellValues.row.class_internal_url);

        GetListeEmargement(cellValues.row._id, cellValues.row.class_internal_url);
    }


    const [session_selected_row_id, setsession_selected_row_id] = useState("");

    const [selected_class_external_code, setselected_class_external_code] = useState("");

    function handleClick_edit_session_From_Line(selected_row_id) {
        setgridline_id(selected_row_id);
        setLoading(true);

        setaddOneSession("0");
        setEdite_session("0");
        setnb_stagiaire_Preinscrit("0");
        setnb_stagiaire_Inscrit("0");

        setselectionModel_preinsc([]);
        setSelectionModel_insc([]);

        var line = JSON.parse(rowss[selected_row_id]);


        var code_session = line.code_session;
        setselected_code_session(line.code_session);

        var internal_url = line.class_internal_url;
        setselected_internal_url(line.class_internal_url);

        var session_id = line._id;
        setselected_session_id(session_id);

        setselected_class_external_code(line.class_external_code);

        Getall_Sequence_Of_Session(session_id);


        setselected_class_title(line.title);

        submenu_detail_session();

        GetCurrentSession(line._id, line.class_internal_url);

        GetListePreinscrit(line._id, line.class_internal_url);

        GetListeInscrit(line._id, line.class_internal_url);

        GetListeEmargement(line._id, line.class_internal_url);
        setLoading(false);
    }


    // lorsqu'on rentre dans ce fichier avec deja un session_id (cas des directions )
    function Display_session_From_Props(props_session_id, props_class_internal_url) {
        setLoading(true);

        setaddOneSession("0");
        setEdite_session("0");
        setnb_stagiaire_Preinscrit("0");
        setnb_stagiaire_Inscrit("0");

        setselectionModel_preinsc([]);
        setSelectionModel_insc([]);


        setselected_internal_url(props_class_internal_url);

        setselected_session_id(props_session_id);

        Getall_Sequence_Of_Session(props_session_id);


        submenu_detail_session();

        GetCurrentSession(props_session_id, props_class_internal_url);

        GetListePreinscrit(props_session_id, props_class_internal_url);

        GetListeInscrit(props_session_id, props_class_internal_url);

        GetListeEmargement(props_session_id, props_class_internal_url);
        setLoading(false);
    }


    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
        })
    }

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Training_Materiel_No_Filter_result, setNew_Getall_Training_Materiel_No_Filter_result] = useState([]);


    const [Getall_Training_Materiel_No_Filter_api, setGetall_Training_Materiel_No_Filter_api] = useState();
    const [Getall_Training_Materiel_No_Filter_message, setGetall_Training_Materiel_No_Filter_message] = useState();
    const [Getall_Training_Materiel_No_Filter_result, setGetall_Training_Materiel_No_Filter_result] = useState();
    function Getall_Training_Materiel_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_No_Filter_api("true");
                setGetall_Training_Materiel_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_ref_interne = JSON.parse(x).ref_interne;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "ref_interne": local_ref_interne,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Materiel_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Materiel_No_Filter_api("false");
                setGetall_Training_Materiel_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Training_Materiel_No_Filter = ', error);
            setGetall_Training_Materiel_No_Filter_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Parter_OR_Default_Session_Steps_result, setNew_Getall_Parter_OR_Default_Session_Steps_result] = useState([]);

    const [Getall_Parter_OR_Default_Session_Steps_api, setGetall_Parter_OR_Default_Session_Steps_api] = useState();
    const [Getall_Parter_OR_Default_Session_Steps_message, setGetall_Parter_OR_Default_Session_Steps_message] = useState();
    const [Getall_Parter_OR_Default_Session_Steps_result, setGetall_Parter_OR_Default_Session_Steps_result] = useState();
    function Getall_Parter_OR_Default_Session_Steps(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Or_Default_session_step/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_OR_Default_Session_Steps  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_OR_Default_Session_Steps  res.data.message r_class = " + res.data.message);
                setGetall_Parter_OR_Default_Session_Steps_api("true");
                setGetall_Parter_OR_Default_Session_Steps_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_session_step_name = JSON.parse(x).session_step_name;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_session_step_name,
                        "session_step_name": local_session_step_name,


                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Parter_OR_Default_Session_Steps_result(new_data2);


            }
            else {
                setGetall_Parter_OR_Default_Session_Steps_api("false");
                setGetall_Parter_OR_Default_Session_Steps_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_OR_Default_Session_Steps = ', error);
            setGetall_Parter_OR_Default_Session_Steps_api("false");
            alert(" Impossible de recuperer la liste des étapes de session");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {


        Get_Partner_Object_Specific_Fields_stagiaire();
        Get_Partner_Object_Specific_Fields("session_formation");
        Getall_Training_Employee_No_Filter();
        Getall_Training_Materiel_No_Filter();

        Getall_Parter_OR_Default_Session_Steps();

        GetCurrentPartnerClass();
        Getall_TrainingSession();
        GetAttestation_Certif();
        Get_List_Partner_Clients();
        let windowWidth = window.innerWidth;
        if (windowWidth < 1001) {
            setdatagrid_columns_size_model2(50);
        }



        if (props.object_key && String(props.object_key).length > 3) {

            setLoading(true);
            GetCurrentSession(props.object_key, props.class_internal_url);
            Display_session_From_Props(props.object_key, props.class_internal_url);
            setLoading(false);
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }


    }, [])


    async function actionmass_preinsc_Traitemet() {
    }

    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selectionModel_sequence, setselectionModel_sequence] = React.useState([]);

    const actionmass_preinsc = (event) => {
        const value = event.target.value;

        if (String(value) === "valider" || String(value) === "refuser") {
            setactionmass_preinsc_val(value);
        }
        else {
            setactionmass_preinsc_val();
        }
    }

    function submenu_preinscrit() {
        setsubmenu("preinscrit");

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#104277";
            document.getElementById("preinscrit").style.color = "white";
            document.getElementById("preinscrit").style.fontWeight = "bold";
        }

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#d8edfc";
            document.getElementById("editique").style.color = "black";
            document.getElementById("editique").style.fontWeight = "normal";
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
            document.getElementById("detail_session").style.fontWeight = "normal";
        }

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("inscrit").style.color = "black";
            document.getElementById("inscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#d8edfc";
            document.getElementById("sequence_session").style.color = "black";
            document.getElementById("sequence_session").style.fontWeight = "normal";
        }

    }

    function submenu_inscrit() {
        setsubmenu("inscrit");

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#104277";
            document.getElementById("inscrit").style.color = "white";
            document.getElementById("inscrit").style.fontWeight = "bold";
        }

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#d8edfc";
            document.getElementById("editique").style.color = "black";
            document.getElementById("editique").style.fontWeight = "normal";
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
            document.getElementById("detail_session").style.fontWeight = "normal";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
            document.getElementById("preinscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#d8edfc";
            document.getElementById("sequence_session").style.color = "black";
            document.getElementById("sequence_session").style.fontWeight = "normal";
        }

        if (document.getElementById("historique")) {
            document.getElementById("historique").style.backgroundColor = "#d8edfc";
            document.getElementById("historique").style.color = "black";
            document.getElementById("historique").style.fontWeight = "normal";
        }

    }

    async function submenu_detail_session() {
        setsubmenu("detail_session");

        //await sleep(5);
        if (!edit_session_form) {
            desableSessionFields();
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#104277";
            document.getElementById("detail_session").style.color = "white";
            document.getElementById("detail_session").style.fontWeight = "bold";
        }

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#d8edfc";
            document.getElementById("editique").style.color = "black";
            document.getElementById("editique").style.fontWeight = "normal";
        }

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("inscrit").style.color = "black";
            document.getElementById("inscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
            document.getElementById("preinscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#d8edfc";
            document.getElementById("sequence_session").style.color = "black";
            document.getElementById("sequence_session").style.fontWeight = "normal";
        }

        if (document.getElementById("historique")) {
            document.getElementById("historique").style.backgroundColor = "#d8edfc";
            document.getElementById("historique").style.color = "black";
            document.getElementById("historique").style.fontWeight = "normal";
        }
    }

    async function submenu_sequence_session() {
        setsubmenu("sequence_session");

        //await sleep(5);
        if (!edit_session_form) {
            desableSessionFields();
        }

        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#104277";
            document.getElementById("sequence_session").style.color = "white";
            document.getElementById("sequence_session").style.fontWeight = "bold";
        }

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#d8edfc";
            document.getElementById("editique").style.color = "black";
            document.getElementById("editique").style.fontWeight = "normal";
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
            document.getElementById("detail_session").style.fontWeight = "normal";
        }

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("inscrit").style.color = "black";
            document.getElementById("inscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
            document.getElementById("preinscrit").style.fontWeight = "normal";
        }


        if (document.getElementById("historique")) {
            document.getElementById("historique").style.backgroundColor = "#d8edfc";
            document.getElementById("historique").style.color = "black";
            document.getElementById("historique").style.fontWeight = "normal";
        }

    }


    async function submenu_emargement() {
        setsubmenu("emargement");

        //await sleep(5);
        if (!edit_session_form) {
            desableSessionFields();
        }

        if (document.getElementById("emargement")) {
            document.getElementById("emargement").style.backgroundColor = "#104277";
            document.getElementById("emargement").style.color = "white";
            document.getElementById("emargement").style.fontWeight = "bold";
        }

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#d8edfc";
            document.getElementById("editique").style.color = "black";
            document.getElementById("editique").style.fontWeight = "normal";
        }

        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#d8edfc";
            document.getElementById("sequence_session").style.color = "black";
            document.getElementById("sequence_session").style.fontWeight = "normal";
        }


        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
            document.getElementById("detail_session").style.fontWeight = "normal";
        }

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("inscrit").style.color = "black";
            document.getElementById("inscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
            document.getElementById("preinscrit").style.fontWeight = "normal";
        }


        if (document.getElementById("historique")) {
            document.getElementById("historique").style.backgroundColor = "#d8edfc";
            document.getElementById("historique").style.color = "black";
            document.getElementById("historique").style.fontWeight = "normal";
        }

    }

    async function submenu_historique() {
        setsubmenu("historique");

       // await sleep(5);
        if (!edit_session_form) {
            desableSessionFields();
        }

    
        if (document.getElementById("historique")) {
            document.getElementById("historique").style.backgroundColor = "#104277";
            document.getElementById("historique").style.color = "white";
            document.getElementById("historique").style.fontWeight = "bold";
        }

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#d8edfc";
            document.getElementById("editique").style.color = "black";
            document.getElementById("editique").style.fontWeight = "normal";
        }

        if (document.getElementById("emargement")) {
            document.getElementById("emargement").style.backgroundColor = "#d8edfc";
            document.getElementById("emargement").style.color = "black";
            document.getElementById("emargement").style.fontWeight = "normal";
        }


        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#d8edfc";
            document.getElementById("sequence_session").style.color = "black";
            document.getElementById("sequence_session").style.fontWeight = "normal";
        }


        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
            document.getElementById("detail_session").style.fontWeight = "normal";
        }

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("inscrit").style.color = "black";
            document.getElementById("inscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
            document.getElementById("preinscrit").style.fontWeight = "normal";
        }




    }


    function submenu_editique() {
        setsubmenu("editique");

        if (document.getElementById("editique")) {
            document.getElementById("editique").style.backgroundColor = "#104277";
            document.getElementById("editique").style.color = "white";
            document.getElementById("editique").style.fontWeight = "bold";
            console.log(" editique");
        }

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("inscrit").style.color = "black";
            document.getElementById("inscrit").style.fontWeight = "normal";
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
        }

        if (document.getElementById("sequence_session")) {
            document.getElementById("sequence_session").style.backgroundColor = "#d8edfc";
            document.getElementById("sequence_session").style.color = "black";
        }

        if (document.getElementById("historique")) {
            document.getElementById("historique").style.backgroundColor = "#d8edfc";
            document.getElementById("historique").style.color = "black";
            document.getElementById("historique").style.fontWeight = "normal";
        }

    }



    function handleClickSessionManagement(event, cellValues) {
        var code_session = cellValues.row.code_session;
        setselected_code_session(cellValues.row.code_session);

        var internal_url = cellValues.row.class_internal_url;
        setselected_internal_url(cellValues.row.class_internal_url);

        var session_id = cellValues.row._id;
        setselected_session_id(session_id);

        setselected_class_title(cellValues.row.title);

        GetListePreinscrit(cellValues.row._id, cellValues.row.class_internal_url);
        GetListeInscrit(cellValues.row._id, cellValues.row.class_internal_url);

        GetListeEmargement(cellValues.row._id, cellValues.row.class_internal_url);

    }

    const [selected_code_session, setselected_code_session] = useState();
    const [selected_session_id, setselected_session_id] = useState();
    const [selected_internal_url, setselected_internal_url] = useState();
    const [selected_class_title, setselected_class_title] = useState();

    const [one_selected_internal_url, setone_selected_internal_url] = useState();


    const [liste_participants_file_change_api, setliste_participants_file_change_api] = useState();
    const [liste_participants_file_change_result, setliste_participants_file_change_result] = useState();
    const [liste_participants_file_change_message, setliste_participants_file_change_message] = useState();
    const liste_participants_file_change = event => {
        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setparticipant_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //formData.append("session_id", selected_session_id);
        //formData.append("class_internal_url", selected_internal_url);
        //console.log("token = " + stored_cookie);

        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass_mass_for_many_session/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (String(result['status']) === String("true")) {
                    setLoading(false);
                    //console.log('Success:', result['message']);
                    setliste_participants_file_change_result(result['message']);
                    setliste_participants_file_change_api("true");
                    GetListePreinscrit(selected_session_id, selected_internal_url);
                    GetListeInscrit(selected_session_id, selected_internal_url);

                    GetListeEmargement(selected_session_id, selected_internal_url);

                    alert(" Les stagiaires ont été correctement importés");
                    submenu_inscrit();

                } else if (String(result['status']) === String("Err_Connexion")) {
                    setLoading(false);
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setLoading(false);
                    setliste_participants_file_change_message(result['message']);
                    setliste_participants_file_change_api("false");
                    alert(result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_participants_file_change_api("false");
                alert(" Impossible d'importer la liste des participants");
            });

    };

    const [session_file_name, setsession_file_name] = useState();
    function submenu_import_session() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }

    const hiddenFileInput_session = React.useRef(null);

    const [addOneSession, setaddOneSession] = useState("");
    function submenu_add_one_session() {

        setaddOneSession("1");
        setEdite_session("0");

        setselectionModel_preinsc([]);
        setSelectionModel_insc([]);
        setnb_stagiaire_Preinscrit("0");
        setnb_stagiaire_Inscrit("0");

        /*  Activation des champs spécifiques  */

        // Pour les champs spécifiques

        submenu_detail_session();

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;

            if (document.getElementsByName(String(field_name))) {

                document.getElementsByName(String(field_name)).disabled = false;
                document.getElementsByName(String(field_name)).backgroundColor = "#FFFFFF";
            }
        }

        setgridline_id('');

        //myRef.current.scrollIntoView({ behavior: "smooth" });
        var divh = document.getElementById('myRef_add_session').offsetTop;
        window.scrollTo({
            top: divh,
            behavior: "smooth",
        });

    }



    const [Getall_Sequence_Of_Session_api, setGetall_Sequence_Of_Session_api] = useState();
    const [Getall_Sequence_Of_Session_message, setGetall_Sequence_Of_Session_message] = useState();
    const [Getall_Sequence_Of_Session_result, setGetall_Sequence_Of_Session_result] = useState();
    function Getall_Sequence_Of_Session(local_session_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", local_session_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Session_Sequence_List/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Sequence_Of_Session  res.data.status = " + res.data.status);
                //console.log(" In Getall_Sequence_Of_Session  res.data.message r_class = " + res.data.message);
                setGetall_Sequence_Of_Session_api("true");
                setGetall_Sequence_Of_Session_result(res.data.message);

                //console.log(" ##NB_Sequence  = ", res.data.message.length);
                setnb_sequence(res.data.message.length);

            }
            else {
                setGetall_Sequence_Of_Session_api("false");
                setGetall_Sequence_Of_Session_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Sequence_Of_Session = ', error);
            setGetall_Sequence_Of_Session_api("false");
            alert("Impossible de récuperer la liste des séquences")
            //setmyApimyApiMessage("")
        })
    }



    const [addOneParticipant, setaddOneParticipant] = useState();
    function submenu_add_one_participant() {
        setaddOneParticipant("1");

        var myelement;

        if (document.getElementById('ajout_participant')) {
            var divh = document.getElementById('ajout_participant').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }



    }

    function submenu_add_one_participant_inactif() {
        alert(" Vous devez choisir une session.");
    }


    const [participant_file_name, setparticipant_file_name] = useState();
    function submenu_import_participant() {
        setparticipant_file_name();
        setliste_participants_file_change_api();
        hiddenFileInput.current.click();
    }

    const hiddenFileInput = React.useRef(null);

    /* Recuperation de la liste des inscrits */

    const [nb_stagiaire_Inscrit, setnb_stagiaire_Inscrit] = useState("0");

    const [nb_sequence, setnb_sequence] = useState("0");

    const [selectionModel_preinsc, setselectionModel_preinsc] = React.useState([]);

    const [selectionModel_insc, setSelectionModel_insc] = React.useState([]);
    const [rowss_insc, setRows_insc] = useState([]);
    const [rowss_preinsc, setRows_preinsc] = useState([]);
    const [GetListePreinscrit_insc_api, setGetListePreinscrit_insc_api] = useState();
    const [GetListePreinscrit_insc_meassage, setGetListePreinscrit_insc_meassage] = useState();
    const [GetListePreinscrit_insc_result, setGetListePreinscrit_insc_result] = useState([])
    function GetListeInscrit(localsessionid, class_internal_url) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", class_internal_url);
        form.append("session_id", localsessionid);
        form.append("status", "1");

        /*
     
    /!\ : Cette fonction a besoin d'un token actif
    */

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllClassStagiaire/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                setLoading(false);
                //console.log(' GetListeInscrit :  Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_insc_result(data['message']);
                setRows_insc(data['message']);
                if (String(data['status']) === String("true")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_insc_api("true");
                    setnb_stagiaire_Inscrit(data['message'].length);
                    //console.log(" ### setnb_stagiaire_Inscrit = ", data['message'].length);

                } else if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else {
                    setGetListePreinscrit_insc_api("false");

                }

            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setGetListePreinscrit_insc_api("false");
            });

    }

    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {
        clean_old_messages();

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation_mass_for_many_class/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                setLoading(false);
                if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");
                    GetCurrentClass_trainingsession();
                    //clearSessionFields();
                    Getall_TrainingSession();
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    alert(result['message']);

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer les sessions");
            });

    }

    const [GetCurrentClass_trainingsession_api, setGetCurrentClass_trainingsession_api] = useState();
    const [GetCurrentClass_trainingsession_message, setGetCurrentClass_trainingsession_message] = useState();
    const [GetCurrentClass_trainingsession_result, setGetCurrentClass_trainingsession_result] = useState();
    function GetCurrentClass_trainingsession(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionFormation_List/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
                setGetCurrentClass_trainingsession_api("true");
                setGetCurrentClass_trainingsession_result(res.data.message);
            }
            else {
                setGetCurrentClass_trainingsession_api("false");
                setGetCurrentClass_trainingsession_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
            setGetCurrentClass_trainingsession_api("false");
            //setmyApimyApiMessage("")
        })
    }

    // Cette fonction nettoye les messages avant nouvelle action
    function clean_old_messages() {
        /*setResend_LMS_Credentials_api();
        setResend_LMS_Credentials_message();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();
        setUpdateStagiaireData_api();
        setSendInscriptionCancell_api();
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setSendEvaluationEmail_api();*/

    }

    const [nb_stagiaire_Preinscrit, setnb_stagiaire_Preinscrit] = useState("0");

    const [GetListePreinscrit_api, setGetListePreinscrit_api] = useState();
    const [GetListePreinscrit_meassage, setGetListePreinscrit_meassage] = useState();
    const [GetListePreinscrit_result, setGetListePreinscrit_result] = useState([])
    function GetListePreinscrit(localsessionid, class_internal_url) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", class_internal_url);
        form.append("session_id", localsessionid);
        form.append("status", "0");

        //console.log(" ### localsessionid = ", localsessionid, " -- class_internal_url = ", class_internal_url);
        /*
     
       /!\ : Cette fonction a besoin d'un token actif
       */

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide");
            history.push("/Connexion");
            return;
        }


        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllClassStagiaire/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                setLoading(false);
                //console.log('GetListePreinscrit:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_result(data['message']);
                setRows_preinsc(data['message']);

                //console.log(" #### LENGHT  GetListePreinscrit = ", data['message'].length); 
                if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else if (String(data['status']) === String("true")) {
                    setGetListePreinscrit_api("true");
                    setnb_stagiaire_Preinscrit(data['message'].length);

                }
                else {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_api("false");
                    alert(data['message']);

                }

            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setGetListePreinscrit_api("false");
                alert(" Impossible de recuperer la liste des préinscriptions");
            });

    }

    const [p_one_detail_client_rattachement_nom, setp_one_detail_client_rattachement_nom] = useState();
    const [p_one_detail_client_rattachement_id, setp_one_detail_client_rattachement_id] = useState();
    const [p_one_detail_type_apprenant, setp_one_detail_type_apprenant] = useState();


    const [One_Create_Participant_api, setOne_Create_Participant_api] = useState();
    const [One_Create_Participant_message, setOne_Create_Participant_message] = useState();
    const [One_Create_Participant_result, setOne_Create_Participant_result] = useState();
    function One_Create_Participant() {
        clean_old_messages();
        var form = new FormData();
        var nb_participant = "0";
        var prix_session;


        var one_civilite_part = "";
        if (p_one_civilite_part)
            one_civilite_part = p_one_civilite_part;

        if (one_civilite_part.trim() == "") {
            alert(" Vous devez saisir la civilité du participant");
            return;
        }



        var one_nom_part = "";
        if (p_one_nom_part)
            one_nom_part = p_one_nom_part;

        if (one_nom_part.trim() == "") {
            alert(" Vous devez saisir le nom du participant");
            return;
        }

        var one_prenom_part = "";
        if (p_one_prenom_part)
            one_prenom_part = p_one_prenom_part;


        if (one_prenom_part.trim() == "") {
            alert(" Vous devez saisir le prénom du participant");
            return;
        }

        var one_naissance_part = "";
        if (p_one_naissance_part)
            one_naissance_part = p_one_naissance_part;

        if (one_naissance_part.trim() == "") {
            alert(" Vous devez saisir la date de naissance du participant");
            return;
        }



        var one_email_part = "";
        if (p_one_email_part)
            one_email_part = p_one_email_part;

        console.log(" one_email_part = ", one_email_part);
        if (one_email_part.trim() == "") {
            alert(" Vous devez saisir l'email du participant");
            return;
        }


        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!one_email_part.trim().match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }


        var one_phone_part = "";
        if (p_one_phone_part)
            one_phone_part = p_one_phone_part;

        if (one_phone_part.trim() == "") {
            alert(" Vous devez saisir le téléphone du participant");
            return;
        }


        if (selected_code_session.trim() === "" || selected_internal_url.trim() === "") {
            alert(" Vous devez selectionner une session d'abord");
            return;
        }


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);
        form.append("civilite", one_civilite_part);
        form.append("nom", one_nom_part);
        form.append("prenom", one_prenom_part);
        form.append("email", one_email_part.trim());

        form.append("date_naissance", p_one_naissance_part.trim());


        form.append("telephone", one_phone_part);
        form.append("status", myinscript_status);
        form.append("modefinancement", "");

        if (p_one_detail_client_rattachement_id)
            form.append("client_rattachement_id", p_one_detail_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");


        if (p_one_detail_type_apprenant)
            form.append("type_apprenant", p_one_detail_type_apprenant);
        else
            form.append("type_apprenant", "0");

        /*
                        Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
                      */
        for (let i = 0; i < spec_field_updated_values_hooks_stagiaire.length; i++) {
            var local_value = String(spec_field_updated_values_hooks_stagiaire[i].field_value);

            if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks_stagiaire[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " est obligatoire.")
                    return;
                }

            }


            form.append(String(spec_field_updated_values_hooks_stagiaire[i].field_name), local_value);
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass/";
        setLoading(true);


        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In One_Create_Participant  res.data.status = " + res.data.status);
            //console.log(" In One_Create_Participant  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setOne_Create_Participant_api("true");
                setOne_Create_Participant_result(res.data.message);


                setaddOneParticipant("0");
                //setreduction_session("1");
                GetListePreinscrit(selected_session_id, selected_internal_url);
                GetListeInscrit(selected_session_id, selected_internal_url);
                GetListeEmargement(selected_session_id, selected_internal_url);

                submenu_inscrit();
                One_clearParticipantFields();
                alert(" Le participant a été correctement ajouté");

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setOne_Create_Participant_api("false");
                setOne_Create_Participant_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('One_Create_Participant : Not good man :(  = ' + error);
            setOne_Create_Participant_api("false");

        })
    }


    const status_inscription = [
        {
            value: '1',
            label: 'Inscription',
        },
        {
            value: '0',
            label: 'PréInscription',
        },
        {
            value: '-1',
            label: 'Annulation',
        },
        {
            value: '2',
            label: 'En Cours',
        },

    ];

    const status_inscription_add = [
        {
            value: '1',
            label: 'Inscription',
        },
        {
            value: '0',
            label: 'PréInscription',
        },
        {
            value: '2',
            label: 'En Cours',
        },

    ];

    const [myinscript_status, setmyinscript_status] = useState("");



    const handleChangeInscription_Status = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setmyinscript_status(value);
    }

    function One_annuleAdd_Participant() {
        One_clearParticipantFields();
        setaddOneParticipant("0");
        var myelement;

        if (document.getElementById('participant_menu_tab')) {
            //myelement = document.getElementById('participant_menu_tab');
            var divh = document.getElementById('participant_menu_tab').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    const [actionmass_insc_val, setactionmass_insc_val] = useState();
    const actionmass_inscrit = (event) => {
        const value = event.target.value;


        if (String(value) === "confirmation" || String(value) === "impression" || String(value) === "annule inscription") {
            setactionmass_insc_val(value);
        }
        else {
            setactionmass_insc_val();
        }
    }


    const [actionmass_sequence_val, setactionmass_sequence_val] = useState();
    const actionmass_sequence = (event) => {
        const value = event.target.value;


        if (String(value) === "ajout_ressource" || String(value) === "supprimer") {
            setactionmass_sequence_val(value);
        }
        else {
            setactionmass_sequence_val();
        }
    }

    function GetSelectedRows_Insc_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_insc.length; i++) {
            //console.log(" ### selectionModel_insc[i] = ", selectionModel_insc[i]);
            var myid = parseInt(String(selectionModel_insc[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    const [SendInscriptionConfirmation_api, setSendInscriptionConfirmation_api] = useState();
    const [SendInscriptionConfirmation_message, setSendInscriptionConfirmation_message] = useState();
    const [SendInscriptionConfirmation_result, setSendInscriptionConfirmation_result] = useState();
    function SendInscriptionConfirmation(email) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (selected_code_session.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);
        form.append("email", email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendInscriptionConfirmation/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSendInscriptionConfirmation_api("true");
                setSendInscriptionConfirmation_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendInscriptionConfirmation_api("false");
                setSendInscriptionConfirmation_message(res.data.message);

            }

        }).catch((error) => {

            console.warn('SendInscriptionConfirmation : Not good man :( mysearchtext = ' + error);
            setSendInscriptionConfirmation_api("false");

        })
    }



    const [gridline_id, setgridline_id] = useState("");
    function GetSelectedRows_Sequence_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_sequence.length; i++) {
            var myid = parseInt(String(selectionModel_sequence[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_sequence_Traitement() {

        setAdd_One_Sequence_Session_mass("1");
        var liste_formation = GetSelectedRows_Sequence_Ids();

        var tab_seq_mongo_ids = [];

        for (var i = 0; i < selectionModel_sequence.length; i++) {

            var line_id = selectionModel_sequence[i];
            var mongo_id = JSON.parse(Getall_Sequence_Of_Session_result[line_id])._id;
            tab_seq_mongo_ids.push(mongo_id);


        }

        settab_selected_sequence_id(tab_seq_mongo_ids);


        if (String(actionmass_sequence_val) === "ajout_ressource") {
            setDialog_seq_ressource_1_open(true);

        }
        else if (String(actionmass_sequence_val) === "supprimer") {

            Delete_Sequence_Session_Data_Mass(tab_seq_mongo_ids);

        }

    }



    async function actionmass_insc_Traitemet() {

        var liste_formation = GetSelectedRows_Insc_Ids();
        for (var i = 0; i < liste_formation.length; i++) {


            var line = JSON.parse(rowss_insc[liste_formation[i]]);
            //console.log(" line = ", line);
            var email = line.email;
            //console.log(" Traitement de email = ", email);
            if (String(actionmass_insc_val) === "confirmation") {

                SendInscriptionConfirmation(email);
            } else if (String(actionmass_insc_val) === "impression") {

                //console.log(" impress mail = ", email);
                DownloadAttendeeDetail_one(email);

            } else if (String(actionmass_insc_val) === "annule inscription") {

                //console.log(" impress mail = ", email);
                SendInscriptionCancell(email);

            }

            await sleep(5);

        }

        setSelectionModel_insc([]);
        setactionmass_insc_val();
        GetListePreinscrit(selected_session_id, selected_internal_url);
        GetListeInscrit(selected_session_id, selected_internal_url);
        GetListeEmargement(selected_session_id, selected_internal_url);

    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    function DownloadAttendeeDetail_one(addendeeEmail) {

        if (addendeeEmail == "") {
            alert(" Aucun utilisateur choisi ");
            return;
        }

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeDetail_perSession/" + stored_cookie + "/" + selected_code_session + "/" + addendeeEmail + "/" + selected_internal_url;


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }

    const [DeleteStagiaireData_api, setDeleteStagiaireData_api] = useState();
    const [DeleteStagiaireData_message, setDeleteStagiaireData_message] = useState();
    const [DeleteStagiaireData_result, setDeleteStagiaireData_result] = useState();
    function DeleteStagiaireData(nom, prenom, email) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (selected_code_session.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);
        form.append("nom", nom);
        form.append("prenom", prenom);
        form.append("email", email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/DeleteAttendeeInscription/";

        axios.post(myurl, form).then(res => {
            //console.log(" In DeleteStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In DeleteStagiaireData  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDeleteStagiaireData_api("true");
                setDeleteStagiaireData_result(res.data.message);
                GetListePreinscrit(selected_session_id, selected_internal_url);
                GetListeInscrit(selected_session_id, selected_internal_url);
                GetListeEmargement(selected_session_id, selected_internal_url);

                setretval_ch("1");
                alert(" La mise à jour été correctement faite.")
            }
            else {
                setDeleteStagiaireData_api("false");
                setDeleteStagiaireData_message(res.data.message);
                setretval_ch("-1");
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('DeleteStagiaireData : Not good man :( mysearchtext = ' + error);
            setDeleteStagiaireData_api("false");
            setretval_ch("-1");
            alert(" Impossible de supprimer le stagaire");
        })
    }


    function handleClick_delete_Session_Formation(event, cellValues) {
        // Recuperation du motif du refus :

        var session_id = cellValues.row._id;
        Delete_Session_Formation(session_id);
    }

    const [Delete_Session_Formation_api, setDelete_Session_Formation_api] = useState();
    const [Delete_Session_Formation_message, setDelete_Session_Formation_message] = useState();
    const [Delete_Session_Formation_result, setDelete_Session_Formation_result] = useState();
    function Delete_Session_Formation(local_session_id) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("session_id", local_session_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_SessionFormation/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Session_Formation  res.data.status = " + res.data.status);
            //console.log(" In Delete_Session_Formation  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Session_Formation_api("true");
                setDelete_Session_Formation_result(res.data.message);
                setSelectionModel([]);
                setaddOneParticipant();
                setdisplay_detail_insc();
                setselected_code_session();
                setsubmenu();
                Getall_TrainingSession();

                alert(" La session a été correctement supprimée.")
            }
            else {
                setDelete_Session_Formation_api("false");
                setDelete_Session_Formation_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_Session_Formation : Not good man :( mysearchtext = ' + error);
            setDelete_Session_Formation_api("false");
            alert(" Impossible de supprimer la session");
        })
    }


    const [retval_ch, setretval_ch] = useState();

    const [UpdateStagiaireData_api, setUpdateStagiaireData_api] = useState();
    const [UpdateStagiaireData_message, setUpdateStagiaireData_message] = useState();
    const [UpdateStagiaireData_result, setUpdateStagiaireData_result] = useState();
    function Update_One_StagiaireData() {
        setLoading(true);
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (p_detail_session_class.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);
        form.append("nom", p_detail_nom);
        form.append("prenom", p_detail_prenom);
        form.append("email", p_detail_mail);
        form.append("telephone", p_detail_tel);

        if (String(p_detail_nom).trim().length < 2) {
            alert("Le nom est invalide.");
            return;
        }

        if (String(p_detail_prenom).trim().length < 2) {
            alert("Le prénom est invalide.");
            return;
        }

        if (String(p_detail_tel).trim().length < 2) {
            alert("Le Téléphone est invalide.");
            return;
        }

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_detail_mail.trim().match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }


        if (p_detail_employeur)
            form.append("employeur", p_detail_employeur);
        else
            form.append("employeur", "");

        form.append("status", p_detail_status_inscrit);

        if (p_detail_cout)
            form.append("price", p_detail_cout);
        else
            form.append("price", "");

        if (p_detail_mode_fin)
            form.append("modefinancement", p_detail_mode_fin);
        else
            form.append("modefinancement", "");


        if (p_detail_opco)
            form.append("opco", p_detail_opco);
        else
            form.append("opco", "");

        form.append("_id", selected_inscription_id);

        if (p_detail_client_rattachement_id)
            form.append("client_rattachement_id", p_detail_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");

        /*
    Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
    */
        for (let i = 0; i < spec_field_updated_values_hooks_stagiaire.length; i++) {

            var local_value = String(spec_field_updated_values_hooks_stagiaire[i].field_value);

            if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks_stagiaire[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks_stagiaire[i].field_name), local_value);
        }

        //console.log(" form == ", form);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass/";

        axios.post(myurl, form).then(res => {
            setLoading(false);
            // console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdateStagiaireData_api("true");
                setUpdateStagiaireData_result(res.data.message);
                setparticipant_data_changed();
                Getall_TrainingSession();
                disableAttendeeDetailFields();
                setparticipant_data_changed();
                setparticipant_data_edit_mode();
                GetAttendee_Data(p_detail_mail, selected_session_id);
                alert(" La mise à jour été correctement faite.");


                //myRef_head.current.scrollIntoView({ behavior: "smooth" });

            }
            else {
                setUpdateStagiaireData_api("false");
                setUpdateStagiaireData_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireData_api("false");
            alert(" Impossible de mettre à jour le participant");

        })
    }


    const [SendInscriptionCancell_api, setSendInscriptionCancell_api] = useState();
    const [SendInscriptionCancell_message, setSendInscriptionCancell_message] = useState();
    const [SendInscriptionCancell_result, setSendInscriptionCancell_result] = useState();
    function SendInscriptionCancell(email) {

        clean_old_messages();
        setUpdateStagiaireData_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (selected_code_session.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);
        form.append("email", email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CancelAttendeeInscription/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSendInscriptionCancell_api("true");
                setSendInscriptionCancell_result(res.data.message);

                GetListePreinscrit(selected_session_id, selected_internal_url);
                GetListeInscrit(selected_session_id, selected_internal_url);

                GetListeEmargement(selected_session_id, selected_internal_url);

                submenu_inscrit();

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendInscriptionCancell_api("false");
                setSendInscriptionCancell_message(res.data.message);

            }

        }).catch((error) => {

            console.warn('SendInscriptionCancell : Not good man :( mysearchtext = ' + error);
            setSendInscriptionCancell_api("false");

        })
    }



    const [selectedattendeeemail, setselectedattendeeemail] = useState([])

    function DownloadAttendeeAttestation(event) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Attestation.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeCertification/" + stored_cookie + "/" + selected_code_session + "/" + selectedattendeeemail + "/" + selected_internal_url;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            })
    }

    const datagridSx = {
        borderRadius: 2,
        marginRight: 1,
        marginLeft: 1,
        border: 0,
        "& .MuiDataGrid-main": { borderRadius: 0 },
        /*'& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
          color: "blue",
          fontSize: 18,
          //risky
          minHeight: "60px !important",
          height: 60,
          "& div": {
            minHeight: "60px !important",
            height: 60,
            lineHeight: "59px !important"
          }
        },*/
        "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
            }
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#c8cfd5",
            color: "black",
            fontSize: 16
        }
    };

    const editorRef_contenu_ftion = useRef(null);
    const [editorRef_contenu_ftion_limite, seteditorRef_description_limite] = useState(800);

    const one_editorRef_contenu_ftion = useRef(null);
    const [one_editorRef_contenu_ftion_limite, setone_editorRef_description_limite] = useState(800);

    const [field_contenu_ftion, setfield_contenu_ftion] = useState("");
    function editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }


    function one_editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }


    function One_annuleSessionUpdate() {
        One_clearSessionFields();
        GetCurrentSession(selected_session_id);
        setsessionChanged(false);
        setCreateSessionFormation_api();
        setCreateSessionFormation_message();
        setone_CreateSessionFormation_api();
        setone_CreateSessionFormation_message();
        setone_CreateSessionFormation_result()
        setaddOneSession();
    }


    let [sessionStatus, setsessionStatus] = useState(false);

    let [sessionChanged, setsessionChanged] = useState(false);


    function desableSessionFields() {

        setedit_session_form(false);

        if (document.getElementsByName("myclass_title") && document.getElementsByName("myclass_title")[0]) {
            document.getElementsByName("myclass_title")[0].disabled = true;
            document.getElementsByName("myclass_title")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("code_session") && document.getElementsByName("code_session")[0]) {
            document.getElementsByName("code_session")[0].disabled = true;
            document.getElementsByName("code_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_status")[0]) {
            document.getElementsByName("session_status")[0].disabled = true;
            document.getElementsByName("session_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_etape")[0]) {
            document.getElementsByName("session_etape")[0].disabled = true;
            document.getElementsByName("session_etape")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = true;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = true;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("adresse")[0]) {
            document.getElementsByName("adresse")[0].disabled = true;
            document.getElementsByName("adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("code_postal")[0]) {
            document.getElementsByName("code_postal")[0].disabled = true;
            document.getElementsByName("code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("ville")[0]) {
            document.getElementsByName("ville")[0].disabled = true;
            document.getElementsByName("ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("pays")[0]) {
            document.getElementsByName("pays")[0].disabled = true;
            document.getElementsByName("pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("formateur")[0]) {
            document.getElementsByName("formateur")[0].disabled = true;
            document.getElementsByName("formateur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("formateur_label")[0]) {
            document.getElementsByName("formateur_label")[0].disabled = true;
            document.getElementsByName("formateur_label")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("formateur_id")[0]) {
            document.getElementsByName("formateur_id")[0].disabled = true;
            document.getElementsByName("formateur_id")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("nb_participant")[0]) {
            document.getElementsByName("nb_participant")[0].disabled = true;
            document.getElementsByName("nb_participant")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("prix_session")[0]) {
            document.getElementsByName("prix_session")[0].disabled = true;
            document.getElementsByName("prix_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("titre_session")[0]) {
            document.getElementsByName("titre_session")[0].disabled = true;
            document.getElementsByName("titre_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("emplacement_session")[0]) {
            document.getElementsByName("emplacement_session")[0].disabled = true;
            document.getElementsByName("emplacement_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("is_bpf_session")[0]) {
            document.getElementsByName("is_bpf_session")[0].disabled = true;
            document.getElementsByName("is_bpf_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_ondemande")[0]) {
            document.getElementsByName("session_ondemande")[0].disabled = true;
            document.getElementsByName("session_ondemande")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("presentiel")[0]) {
            document.getElementsByName("presentiel")[0].disabled = true;
            document.getElementsByName("presentiel")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("distantiel")[0]) {
            document.getElementsByName("distantiel")[0].disabled = true;
            document.getElementsByName("distantiel")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatedebutinscription")[0]) {
            document.getElementsByName("sessiondatedebutinscription")[0].disabled = true;
            document.getElementsByName("sessiondatedebutinscription")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatefininscription")[0]) {
            document.getElementsByName("sessiondatefininscription")[0].disabled = true;
            document.getElementsByName("sessiondatefininscription")[0].style.backgroundColor = "#ECEFF1";
        }


        // Pour les champs spécifiques


        if (String(addOneSession) !== "1") {
            for (let i = 0; i < rows_champs_specifics.length; i++) {

                var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
                var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
                var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
                var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;

                if (document.getElementsByName(String(field_name))[0]) {

                    document.getElementsByName(String(field_name))[0].disabled = true;
                    document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
                }
            }
        }


        setfields1desabled(true);

    }

    let [edit_session_form, setedit_session_form] = useState(false);
    function enableSessionFields() {

        setedit_session_form(true);

        document.getElementsByName("code_session")[0].disabled = false;
        document.getElementsByName("code_session")[0].style.backgroundColor = "#FFFFFF";

        if (document.getElementsByName("session_status")[0]) {
            document.getElementsByName("session_status")[0].disabled = false;
            document.getElementsByName("session_status")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("session_etape")[0]) {
            document.getElementsByName("session_etape")[0].disabled = false;
            document.getElementsByName("session_etape")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = false;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = false;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("adresse")[0]) {
            document.getElementsByName("adresse")[0].disabled = false;
            document.getElementsByName("adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("code_postal")[0]) {
            document.getElementsByName("code_postal")[0].disabled = false;
            document.getElementsByName("code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("ville")[0]) {
            document.getElementsByName("ville")[0].disabled = false;
            document.getElementsByName("ville")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("pays")[0]) {
            document.getElementsByName("pays")[0].disabled = false;
            document.getElementsByName("pays")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("formateur")[0]) {
            document.getElementsByName("formateur")[0].disabled = false;
            document.getElementsByName("formateur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("formateur_label")[0]) {
            document.getElementsByName("formateur_label")[0].disabled = false;
            document.getElementsByName("formateur_label")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("formateur_id")[0]) {
            document.getElementsByName("formateur_id")[0].disabled = false;
            document.getElementsByName("formateur_id")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("nb_participant")[0]) {
            document.getElementsByName("nb_participant")[0].disabled = false;
            document.getElementsByName("nb_participant")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("prix_session")[0]) {
            document.getElementsByName("prix_session")[0].disabled = false;
            document.getElementsByName("prix_session")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("titre_session")[0]) {
            document.getElementsByName("titre_session")[0].disabled = false;
            document.getElementsByName("titre_session")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("emplacement_session")[0]) {
            document.getElementsByName("emplacement_session")[0].disabled = false;
            document.getElementsByName("emplacement_session")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("is_bpf_session")[0]) {
            document.getElementsByName("is_bpf_session")[0].disabled = false;
            document.getElementsByName("is_bpf_session")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("session_ondemande")[0]) {
            document.getElementsByName("session_ondemande")[0].disabled = false;
            document.getElementsByName("session_ondemande")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("presentiel")[0]) {
            document.getElementsByName("presentiel")[0].disabled = false;
            document.getElementsByName("presentiel")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("distantiel")[0]) {
            document.getElementsByName("distantiel")[0].disabled = false;
            document.getElementsByName("distantiel")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("sessiondatedebutinscription")[0]) {
            document.getElementsByName("sessiondatedebutinscription")[0].disabled = false;
            document.getElementsByName("sessiondatedebutinscription")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("sessiondatefininscription")[0]) {
            document.getElementsByName("sessiondatefininscription")[0].disabled = false;
            document.getElementsByName("sessiondatefininscription")[0].style.backgroundColor = "#FFFFFF ";
        }

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = false;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#FFFFFF";
            }
        }

        if (document.getElementById('myRef_add_session')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef_add_session').offsetTop;

            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }


        setfields1desabled(false);
    }

    function clearSessionFields() {

        if (document.getElementsByName("sessiondatedebut")[0])
            document.getElementsByName("code_session")[0].value = "";

        if (document.getElementsByName("sessiondatedebut")[0])
            document.getElementsByName("sessiondatedebut")[0].value = "";

        if (document.getElementsByName("sessiondatefin")[0])
            document.getElementsByName("sessiondatefin")[0].value = "";

        if (document.getElementsByName("session_status")[0])
            document.getElementsByName("session_status")[0].value = "";

        if (document.getElementsByName("session_etape")[0])
            document.getElementsByName("session_etape")[0].value = "";



        if (document.getElementsByName("adresse")[0])
            document.getElementsByName("adresse")[0].value = "";

        if (document.getElementsByName("code_postal")[0])
            document.getElementsByName("code_postal")[0].value = "";

        if (document.getElementsByName("ville")[0])
            document.getElementsByName("ville")[0].value = "";


        if (document.getElementsByName("pays")[0])
            document.getElementsByName("pays")[0].value = "";

        if (document.getElementsByName("nb_participant")[0])
            document.getElementsByName("nb_participant")[0].value = "";

        if (document.getElementsByName("prix_session")[0])
            document.getElementsByName("prix_session")[0].value = "";

        if (document.getElementsByName("presentiel")[0])
            document.getElementsByName("presentiel")[0].value = "";

        if (document.getElementsByName("session_ondemande")[0])
            document.getElementsByName("session_ondemande")[0].value = "";

        if (document.getElementsByName("distantiel")[0])
            document.getElementsByName("distantiel")[0].value = "";

        if (document.getElementsByName("sessiondatedebutinscription")[0])
            document.getElementsByName("sessiondatedebutinscription")[0].value = "";

        if (document.getElementsByName("sessiondatefininscription")[0])
            document.getElementsByName("sessiondatefininscription")[0].value = "";

        setSessionstartDateInscription();
        setSessionendDateInscription();
        setSessionendDate();
        setSessionstartDate();
        seturlpreview_certif();
        setfield_contenu_ftion();


        setp_myclass_title();
        setp_code_session();
        setp_adresse();
        setp_ville();
        setp_pays();
        setp_formateur();
        setp_formateur_id();
        setp_formateur_label();
        setp_lms_class_code();
        setp_nb_participant();
        setp_prix_session();
        setp_session_etape_label();
        setp_session_etape();
        setp_session_status_label();
        setp_session_status();
        setp_session_presentiel_label();
        setp_session_presentiel();
        setp_session_distance_label();
        setp_session_distance();
        setp_session_ondemande_label();
        setp_session_ondemande();

    }


    function One_clearSessionFields() {
        document.getElementsByName("one_code_session")[0].value = "";
        document.getElementsByName("one_sessiondatedebut")[0].value = "";
        document.getElementsByName("one_sessiondatefin")[0].value = "";

        if (document.getElementsByName("one_adresse")[0])
            document.getElementsByName("one_adresse")[0].value = "";

        if (document.getElementsByName("one_code_postal")[0])
            document.getElementsByName("one_code_postal")[0].value = "";

        if (document.getElementsByName("one_ville")[0])
            document.getElementsByName("one_ville")[0].value = "";

        if (document.getElementsByName("one_nb_participant")[0])
            document.getElementsByName("one_nb_participant")[0].value = "";

        if (document.getElementsByName("one_prix_session")[0])
            document.getElementsByName("one_prix_session")[0].value = "";

        if (document.getElementsByName("one_presentiel")[0])
            document.getElementsByName("one_presentiel")[0].value = "";

        if (document.getElementsByName("one_session_ondemande")[0])
            document.getElementsByName("one_session_ondemande")[0].value = "";

        if (document.getElementsByName("one_distantiel")[0])
            document.getElementsByName("one_distantiel")[0].value = "";

        if (document.getElementsByName("one_sessiondatedebutinscription")[0])
            document.getElementsByName("one_sessiondatedebutinscription")[0].value = "";

        if (document.getElementsByName("one_sessiondatefininscription")[0])
            document.getElementsByName("one_sessiondatefininscription")[0].value = "";


        if (document.getElementsByName("one_titre_session")[0])
            document.getElementsByName("one_titre_session")[0].value = "";


        setone_SessionstartDateInscription();
        setone_SessionendDateInscription();
        setone_SessionendDate();
        setone_SessionstartDate();
        setone_urlpreview_certif();
        setone_field_contenu_ftion();
        setp_one_myclass_title();
        setp_one_code_session();
        setp_one_code_session_id();
        setp_one_adresse();
        setp_one_code_postal();
        setp_one_ville();
        setp_one_pays();
        setp_one_detail_type_apprenant();
        setp_one_formateur();
        setp_one_formateur_id();
        setp_one_lms_class_code();
        setp_one_nb_participant();
        setp_one_prix_session();
        setp_one_session_ondemande("0");
        setp_one_session_ondemande_label();
        setp_one_session_distance("0");
        setp_one_session_distance_label();
        setp_one_session_presentiel("0");
        setp_one_session_presentiel_label();
        setp_one_session_status();
        setp_one_session_status_label();
        setp_one_session_etape();
        setp_one_session_etape_label();

        setp_one_titre("");




    }


    function One_clearParticipantFields() {


        if (document.getElementsByName("one_civilite_part")[0])
            document.getElementsByName("one_civilite_part")[0].value = "";

        if (document.getElementsByName("one_nom_part")[0])
            document.getElementsByName("one_nom_part")[0].value = "";

        if (document.getElementsByName("one_prenom_part")[0])
            document.getElementsByName("one_prenom_part")[0].value = "";

        if (document.getElementsByName("one_email_part")[0])
            document.getElementsByName("one_email_part")[0].value = "";

        if (document.getElementsByName("one_phone_part")[0])
            document.getElementsByName("one_phone_part")[0].value = "";

        if (document.getElementsByName("one_status_part")[0])
            document.getElementsByName("one_status_part")[0].value = "";


        setp_one_civilite_part();
        setp_one_nom_part();
        setp_one_prenom_part();
        setp_one_email_part();
        setp_one_naissance_part(new Date().toLocaleDateString('fr-FR'));
        setp_one_phone_part();
        setp_one_status_part();



    }

    const [is_lms_class_code, setis_lms_class_code] = useState();

    const [CreateSessionFormation_api, setCreateSessionFormation_api] = useState();
    const [CreateSessionFormation_message, setCreateSessionFormation_message] = useState();
    const [CreateSessionFormation_result, setCreateSessionFormation_result] = useState();
    function CreateSessionFormation() {
        clean_old_messages();
        var form = new FormData();
        var nb_participant = "0";
        var prix_session;


        if (selected_internal_url.trim() == "") {
            alert(" Vous devez choisir une formation ");
            return;
        }

        if (!p_session_etape) {
            alert(" Vous devez choisir une étape");
            return;
        }



        if (selected_code_session.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }

        var new_code_session = ""
        if (document.getElementById("code_session") && document.getElementById("code_session").value != "") {
            new_code_session = document.getElementById("code_session").value;
        }
        if (new_code_session.trim() == "") {
            alert(" Le code session est obligatoire");
            return;
        }


        nb_participant = ""
        if (document.getElementById("nb_participant") && document.getElementById("nb_participant").value != "") {
            nb_participant = document.getElementById("nb_participant").value;
        }
        if (nb_participant.trim() == "") {
            alert(" Le nombre de participants est obligatoire");
            return;
        }

        prix_session = ""
        if (document.getElementById("prix_session") && document.getElementById("prix_session").value != "") {
            prix_session = document.getElementById("prix_session").value;
        }
        if (prix_session.trim() == "") {
            alert(" Le prix de la session est vide");
            return;
        }



        var session_ondemande = p_session_ondemande;


        var presentiel = p_session_presentiel
        if (presentiel.trim() == "") {
            alert(" Vous devez préciser si la session est en présentiel");
            return;
        }

        var distantiel = p_session_distance
        if (distantiel.trim() == "") {
            alert(" Vous devez préciser si la session est en distantiel");
            return;
        }


        var adress = "";
        if (document.getElementById("adresse") && document.getElementById("adresse").value != "")
            adress = document.getElementById("adresse").value;

        var codepostal = "";
        if (document.getElementById("code_postal") && document.getElementById("code_postal").value != "")
            codepostal = document.getElementById("code_postal").value;


        var ville = "";
        if (document.getElementById("ville") && document.getElementById("ville").value != "")
            ville = document.getElementById("ville").value;

        var code_session = "";
        if (document.getElementById("code_session") && document.getElementById("code_session").value != "")
            code_session = document.getElementById("code_session").value;



        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", GetCurrentSession_id);
        form.append("class_internal_url", selected_internal_url);
        form.append("date_debut", format(SessionstartDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("date_fin", format(SessionendDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("prix_session", prix_session);
        form.append("nb_participant", nb_participant);
        form.append("session_ondemande", session_ondemande);
        form.append("presentiel", presentiel);
        form.append("distantiel", distantiel);
        form.append("adresse", adress);
        form.append("code_postal", codepostal);
        form.append("ville", ville);
        if (p_pays)
            form.append("pays", p_pays);

        else
            form.append("pays", "");

        form.append("code_session", code_session);

        form.append("titre", p_detail_titre);
        form.append("location_type", p_detail_location_type);
        form.append("is_bpf", p_detail_is_bpf);


        form.append("formateur_id", p_formateur_id);


        // Il s'agit d'une mise à jour de session
        if (String(p_class_is_published) === "1") {

            if (String(document.getElementById("sessiondatedebutinscription").value).trim() == "") {
                alert(" La date de début des inscriptions est obligatoire");
                return;
            }

            if (String(document.getElementById("sessiondatefininscription").value).trim() == "") {
                alert(" La date de fin des inscriptions est obligatoire");
                return;
            }

            form.append("date_debut_inscription", format(SessionstartDateInscription, 'dd/MM/yyyy kk:mm:ss'));
            form.append("date_fin_inscription", format(SessionendDateInscription, 'dd/MM/yyyy kk:mm:ss'));
        } else {
            form.append("date_debut_inscription", "");
            form.append("date_fin_inscription", "");
        }


        form.append("attestation_certif", "");

        form.append("session_status", p_session_status);
        form.append("session_etape", p_session_etape);



        var contenu_ftion = "";
        if (editorRef_contenu_ftion.current) {
            contenu_ftion = editorRef_contenu_ftion.current.getContent();

            if (parse(contenu_ftion.replace(/(<([^>]+)>)/ig, '')).length > editorRef_contenu_ftion_limite) {
                alert(" Champ 'Contenu de la formation', limite depassée !")
                return;
            }
        }
        form.append("contenu_ftion", contenu_ftion);

        /*
          Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
        */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            var local_value = String(spec_field_updated_values_hooks[i].field_value);

            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
        }


        setLoading(true);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation/";



        axios.post(myurl, form).then(res => {
            //console.log(" In CreateSessionFormation  res.data.status = " + res.data.status);
            //console.log(" In CreateSessionFormation  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setCreateSessionFormation_api("true");
                setCreateSessionFormation_result(res.data.message);
                setsessionChanged(false);
                desableSessionFields();
                Getall_TrainingSession();
                GetCurrentSession(selected_session_id, selected_internal_url);
                alert(String(res.data.message));

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreateSessionFormation_api("false");
                setCreateSessionFormation_message(res.data.message);
                alert(String(res.data.message));
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('CreateSessionFormation : Not good man :( mysearchtext = ' + error);
            setCreateSessionFormation_api("false");
            alert(" Impossible de mettre à jour la session");

        })
    }


    const [one_CreateSessionFormation_api, setone_CreateSessionFormation_api] = useState();
    const [one_CreateSessionFormation_message, setone_CreateSessionFormation_message] = useState();
    const [one_CreateSessionFormation_result, setone_CreateSessionFormation_result] = useState();

    function One_CreateSessionFormation() {
        clean_old_messages();
        var form = new FormData();



        var one_myclass_title = ""


        if (p_one_myclass_title) {
            one_myclass_title = p_one_myclass_title;
        }
        if (one_myclass_title.trim() == "") {
            alert(" Vous devez choisir une formation ");
            return;
        }


        var nb_participant = ""
        if (document.getElementById("one_nb_participant") && document.getElementById("one_nb_participant").value != "") {
            nb_participant = document.getElementById("one_nb_participant").value;
        }
        if (nb_participant.trim() == "") {
            const response = window.confirm("Le nombre de participants est vide. Par défaut, 1. Continuer ?");
            if (!response) {
                return;
            }
            nb_participant = "1"
            setp_one_nb_participant('1');

        }

        var prix_session = p_one_prix_session


        var one_session_ondemande = p_one_session_ondemande

        var presentiel = p_one_session_presentiel;

        if (presentiel.trim() === "") {
            const response = window.confirm(" Vous devez préciser si la session est en présentiel. Continuer ?");
            if (!response) {
                return;
            }
        }


        var distantiel = p_one_session_distance;

        if (distantiel.trim() == "") {

            const response = window.confirm(" Vous devez préciser si la session est en distantiel. Continuer ?");
            if (!response) {
                return;
            }

        }

        if (String(document.getElementById("one_sessiondatedebut").value).trim() == "") {
            window.alert("La date de début de session est obligatoire.");
            return;
        }

        if (String(document.getElementById("one_sessiondatefin").value).trim() == "") {
            window.alert(" La date de fin de session est obligatoire.");
            return;
        }




        var adress = "";
        if (document.getElementById("one_adresse") && document.getElementById("one_adresse").value != "")
            adress = document.getElementById("one_adresse").value;

        var codepostal = "";
        if (document.getElementById("one_code_postal") && document.getElementById("one_code_postal").value != "")
            codepostal = document.getElementById("one_code_postal").value;


        var ville = "";
        if (document.getElementById("one_ville") && document.getElementById("one_ville").value != "")
            ville = document.getElementById("one_ville").value;

        var code_session = "";
        if (document.getElementById("one_code_session") && document.getElementById("one_code_session").value != "")
            code_session = document.getElementById("one_code_session").value;

        /*var formateur = "";
        if (document.getElementById("one_formateur") && document.getElementById("one_formateur").value != "")
            formateur = document.getElementById("one_formateur").value;
    */

        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code_session", p_one_code_session);
        form.append("class_internal_url", one_selected_internal_url);
        form.append("date_debut", format(one_SessionstartDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("date_fin", format(one_SessionendDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("prix_session", p_one_prix_session);
        form.append("nb_participant", p_one_nb_participant);
        form.append("session_ondemande", p_one_session_ondemande);
        form.append("presentiel", p_one_session_presentiel);
        form.append("distantiel", p_one_session_distance);
        form.append("adresse", p_one_adresse);
        form.append("code_postal", p_one_code_postal);
        form.append("ville", p_one_ville);
        form.append("pays", p_one_pays);


        //form.append("formateur", p_one_formateur);
        form.append("formateur_id", p_one_formateur_id);
        form.append("titre", p_one_titre);
        form.append("location_type", p_one_location_type);
        form.append("is_bpf", p_one_is_bpf);


        form.append("session_status", p_one_session_status);
        form.append("session_etape", p_one_session_etape);

        // Il s'agit d'une création  de session
        if (String(p_one_myclass_is_published) === "1") {

            if (String(document.getElementById("one_sessiondatedebutinscription").value).trim() == "") {
                alert(" La date de début des inscriptions est obligatoire");
                return;
            }

            if (String(document.getElementById("one_sessiondatefininscription").value).trim() == "") {
                alert(" La date de fin des inscriptions est obligatoire");
                return;
            }

            form.append("date_debut_inscription", format(one_SessionstartDateInscription, 'dd/MM/yyyy kk:mm:ss'));
            form.append("date_fin_inscription", format(one_SessionendDateInscription, 'dd/MM/yyyy kk:mm:ss'));
        } else {
            form.append("date_debut_inscription", "");
            form.append("date_fin_inscription", "");
        }



        form.append("attestation_certif", "");

        var contenu_ftion = "";
        if (one_editorRef_contenu_ftion.current) {
            contenu_ftion = one_editorRef_contenu_ftion.current.getContent();

            if (parse(contenu_ftion.replace(/(<([^>]+)>)/ig, '')).length > one_editorRef_contenu_ftion_limite) {
                alert(" Champ 'Contenu de la formation', limite depassée !")
                return;
            }
        }
        form.append("contenu_ftion", contenu_ftion);

        /*
             Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
           */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            var local_value = String(spec_field_updated_values_hooks[i].field_value);

            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), String(spec_field_updated_values_hooks[i].field_value));
        }



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In One_CreateSessionFormation  res.data.status = " + res.data.status);
            //console.log(" In One_CreateSessionFormation  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setone_CreateSessionFormation_api("true");
                setone_CreateSessionFormation_result(res.data.message);
                setsessionChanged(false);
                Getall_TrainingSession();

                One_clearSessionFields();
                setaddOneSession();

                setaddOneParticipant();
                setdisplay_detail_insc();
                setselected_code_session();
                setsubmenu();

                alert(res.data.message);
                //GetCurrentSession(p_one_code_session, one_selected_internal_url);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setone_CreateSessionFormation_api("false");
                setone_CreateSessionFormation_message(res.data.message);
                alert("Erreur : " + res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('One_CreateSessionFormation : Not good man :(  = ' + error);
            setone_CreateSessionFormation_api("false");
            alert("Erreur : Impossible d'ajouter la session");

        })
    }

    let [fields1desabled, setfields1desabled] = useState(true);
    const [detailuser_date_inscription, setdetailuser_date_inscription] = useState();
    const [detailuser_date_evaluation, setdetailuser_date_evaluation] = useState();
    const [detailuser_date_certification, setdetailuser_date_certification] = useState();

    const [SessionstartDateInscription, setSessionstartDateInscription] = useState();
    const [urlpreview_certif, seturlpreview_certif] = useState();
    function handleChangeselectedCertif(event) {

        var val = event.target.value
        //console.log(val);
        GetSpecificAttestation_Certif(val)

        setselectedCertif(val);

        //seturlpreview_certif(val[1]);
    }

    function handleChangeone_selectedCertif(event) {

        var val = event.target.value
        //console.log(val);
        GetSpecificAttestation_Certif(val)

        setone_selectedCertif(val);

        //seturlpreview_certif(val[1]);
    }


    const [p_session_ondemande, setp_session_ondemande] = useState("0");
    const [p_session_ondemande_label, setp_session_ondemande_label] = useState("");

    const [p_session_distance, setp_session_distance] = useState("0");
    const [p_session_distance_label, setp_session_distance_label] = useState("");

    const [p_session_presentiel, setp_session_presentiel] = useState("0");
    const [p_session_presentiel_label, setp_session_presentiel_label] = useState("");

    const [p_session_status, setp_session_status] = useState();
    const [p_session_status_label, setp_session_status_label] = useState();


    const [p_session_etape, setp_session_etape] = useState();
    const [p_session_etape_label, setp_session_etape_label] = useState();


    const [GetCurrentSession_id, setGetCurrentSession_id] = useState();

    const [GetCurrentSession_api, setGetCurrentSession_api] = useState();
    const [GetCurrentSession_message, setGetCurrentSession_message] = useState();
    const [GetCurrentSession_result, setGetCurrentSession_result] = useState();
    function GetCurrentSession(local_session_id, local_selected_internal_url) {

        clean_old_messages();
        clearSessionFields();

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", local_selected_internal_url);
        form.append("session_id", local_session_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetSessionFormation/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetCurrentSession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentSession  res.data.message r_class = " + res.data.message);
                //console.log(" In GetCurrentSession  res.data.message len = " + String(res.data.message).length);

                setLoading(false);
                setGetCurrentSession_api("true");
                if (String(res.data.message).length > 0) {
                    setGetCurrentSession_result(res.data.message);
                    var mylocaltraining = JSON.parse(res.data.message);

                    if (mylocaltraining._id) {
                        setGetCurrentSession_id(mylocaltraining._id);
                    }

                    if (mylocaltraining.session_status) {
                        setp_session_status(mylocaltraining.session_status);
                        var result2 = status_tab.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.session_status)))
                        if (result2[0])
                            setp_session_status_label(result2[0].label);

                    } else {
                        setp_session_status("0");
                        setp_session_status_label("Inactif");
                    }


                    if (mylocaltraining.session_etape) {
                        setp_session_etape(mylocaltraining.session_etape);

                        var result2 = New_Getall_Parter_OR_Default_Session_Steps_result.filter((local_mymanager) => local_mymanager.session_step_name === String((mylocaltraining.session_etape)))
                        if (result2[0]) {
                            setp_session_etape_label(result2[0].label);
                        }


                    }


                    if (mylocaltraining.date_debut) {
                        var date_du = new Date(moment(mylocaltraining.date_debut, "DD/MM/YYYY HH:mm:ss"));
                        setSessionstartDate(date_du);
                    }

                    if (mylocaltraining.date_fin) {
                        var date_fin = new Date(moment(mylocaltraining.date_fin, "DD/MM/YYYY HH:mm:ss"));
                        setSessionendDate(date_fin);
                    }

                    if (mylocaltraining.date_debut_inscription) {
                        var date_du_inscript = new Date(moment(mylocaltraining.date_debut_inscription, "DD/MM/YYYY HH:mm:ss"));
                        setSessionstartDateInscription(date_du_inscript);

                    }

                    if (mylocaltraining.date_fin_inscription) {
                        var date_fin_inscript = new Date(moment(mylocaltraining.date_fin_inscription, "DD/MM/YYYY HH:mm:ss"));
                        setSessionendDateInscription(date_fin_inscript);
                    }

                    if (mylocaltraining.attestation_certif) {
                        setselectedCertif("");
                        GetSpecificAttestation_Certif("");

                    }

                    document.getElementsByName("myclass_title")[0].value = "";
                    if (mylocaltraining.myclass[0].title)
                        setp_myclass_title(mylocaltraining.myclass[0].title);


                    if (mylocaltraining.class_external_code)
                        setselected_class_external_code(mylocaltraining.class_external_code);

                    if (mylocaltraining.class_ispublished)
                        setp_class_is_published(mylocaltraining.class_ispublished);
                    else
                        setp_class_is_published("0");


                    document.getElementsByName("code_session")[0].value = "";
                    if (mylocaltraining.code_session) {
                        setp_code_session(mylocaltraining.code_session);
                        setselected_code_session(mylocaltraining.code_session);
                    }

                    document.getElementsByName("nb_participant")[0].value = "";
                    if (mylocaltraining.nb_participant)
                        setp_nb_participant(mylocaltraining.nb_participant);

                    document.getElementsByName("prix_session")[0].value = "";
                    if (mylocaltraining.prix_session)
                        setp_prix_session(mylocaltraining.prix_session);

                    if (mylocaltraining.distantiel) {
                        setp_session_distance(mylocaltraining.distantiel);
                        if (String(mylocaltraining.distantiel) === "1")
                            setp_session_distance_label("Oui");
                        else
                            setp_session_distance_label("Non")
                    }


                    if (mylocaltraining.session_ondemande) {
                        setp_session_ondemande(mylocaltraining.session_ondemande);
                        if (String(mylocaltraining.session_ondemande) === "1")
                            setp_session_ondemande_label("Oui");
                        else
                            setp_session_ondemande_label("Non")

                    }

                    if (mylocaltraining.presentiel) {
                        setp_session_presentiel(mylocaltraining.presentiel);
                        if (String(mylocaltraining.presentiel) === "1")
                            setp_session_presentiel_label("Oui");
                        else
                            setp_session_presentiel_label("Non")
                    }

                    if (mylocaltraining.contenu_ftion)
                        setfield_contenu_ftion(mylocaltraining.contenu_ftion);



                    document.getElementsByName("adresse")[0].value = "";
                    if (mylocaltraining.adresse)
                        setp_adresse(mylocaltraining.adresse);

                    document.getElementsByName("ville")[0].value = "";
                    if (mylocaltraining.ville) {
                        setp_ville(mylocaltraining.ville);
                    }

                    document.getElementsByName("pays")[0].value = "";
                    if (mylocaltraining.ville) {
                        setp_pays(mylocaltraining.pays);
                    }




                    if (mylocaltraining.formateur_id) {
                        setp_formateur_id(mylocaltraining.formateur_id);
                        setp_formateur_label(mylocaltraining.formateur_nom_prenom);

                    } else {
                        setp_formateur_id("");
                        setp_formateur_label("");

                    }


                    if (mylocaltraining.lms_class_code) {
                        setis_lms_class_code(mylocaltraining.lms_class_code);
                    }



                    if (mylocaltraining.titre) {
                        setp_detail_titre(mylocaltraining.titre);
                    }


                    if (mylocaltraining.title) {
                        setselected_class_title(mylocaltraining.title);
                    }


                    if (mylocaltraining.location_type) {
                        setp_detail_location_type(mylocaltraining.location_type);
                        var result2 = emplacement_tab.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.location_type)))

                        if (result2[0])
                            setp_detail_location_type_label(result2[0].label);

                    } else {
                        setp_detail_location_type("");
                        setp_detail_location_type_label("");
                    }


                    if (mylocaltraining.is_bpf) {
                        setp_detail_is_bpf(mylocaltraining.is_bpf);
                        var result2 = inclus_bpf_tab.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.is_bpf)))

                        if (result2[0])
                            setp_detail_is_bpf_label(result2[0].label);
                    } else {
                        setp_detail_is_bpf("0");
                        setp_detail_is_bpf_label("Non");
                    }

                    document.getElementsByName("code_postal")[0].value = "";
                    if (mylocaltraining.code_postal)
                        setp_code_postal(mylocaltraining.code_postal);


                    if (String(mylocaltraining.session_status) === "true")
                        setsessionStatus(true);
                    else if (String(mylocaltraining.session_status) === "false")
                        setsessionStatus(false);

                    /*
               * Update 22/10/2023 :
               Gestion des champs spécifiques. ils commencent tous par 'my_'
               */

                    for (let i = 0; i < rows_champs_specifics.length; i++) {

                        var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
                        var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
                        var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
                        var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;



                        if (mylocaltraining.hasOwnProperty(field_name)) {
                            var local_valeur = mylocaltraining[String(field_name)]

                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = local_valeur;

                            var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                            var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);

                            if (johnIndex > -1) {
                                spec_field_updated_values[johnIndex] = new_val;

                            } else {
                                spec_field_updated_values.push(new_val);
                            }

                        } else {

                        }
                    }

                    setspec_field_updated_values_hooks(spec_field_updated_values);
                    /*
                                       *  end Update 22/10/2023 :
                                       */


                    desableSessionFields();


                    if (document.getElementById('myRef_add_session')) {
                        // myRef.current.scrollIntoView({ behavior: "smooth" });
                        var divh = document.getElementById('myRef_add_session').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }
                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetCurrentSession_api("false");
                setGetCurrentSession_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetCurrentSession = ', error);
            setGetCurrentSession_api("false");
            alert(" Impossible de récuperer les données de la session ");


        })
    }


    const [p_class_is_published, setp_class_is_published] = useState("0");

    const [p_myclass_title, setp_myclass_title] = useState("");
    const [p_code_session, setp_code_session] = useState("");
    const [p_adresse, setp_adresse] = useState("");
    const [p_code_postal, setp_code_postal] = useState("");
    const [p_ville, setp_ville] = useState("");
    const [p_pays, setp_pays] = useState("");
    const [p_formateur, setp_formateur] = useState("");
    const [p_formateur_id, setp_formateur_id] = useState("");
    const [p_formateur_label, setp_formateur_label] = useState("");
    const [p_lms_class_code, setp_lms_class_code] = useState("");
    const [p_nb_participant, setp_nb_participant] = useState("");
    const [p_prix_session, setp_prix_session] = useState("");


    const [p_one_myclass_title, setp_one_myclass_title] = useState("");
    const [p_one_myclass_is_published, setp_one_myclass_is_published] = useState("");

    const [p_one_code_session, setp_one_code_session] = useState("");
    const [p_one_code_session_id, setp_one_code_session_id] = useState("");
    const [p_one_adresse, setp_one_adresse] = useState("");
    const [p_one_code_postal, setp_one_code_postal] = useState("");
    const [p_one_ville, setp_one_ville] = useState("");
    const [p_one_pays, setp_one_pays] = useState("");
    const [p_one_formateur, setp_one_formateur] = useState("");
    const [p_one_formateur_id, setp_one_formateur_id] = useState("");
    const [p_one_lms_class_code, setp_one_lms_class_code] = useState("");
    const [p_one_nb_participant, setp_one_nb_participant] = useState("");
    const [p_one_prix_session, setp_one_prix_session] = useState("");
    const [p_one_session_ondemande, setp_one_session_ondemande] = useState("0");
    const [p_one_session_ondemande_label, setp_one_session_ondemande_label] = useState("Non");
    const [p_one_session_distance, setp_one_session_distance] = useState("0");
    const [p_one_session_distance_label, setp_one_session_distance_label] = useState("Non");
    const [p_one_session_presentiel, setp_one_session_presentiel] = useState("0");
    const [p_one_session_presentiel_label, setp_one_session_presentiel_label] = useState("Non");
    const [p_one_session_status, setp_one_session_status] = useState("0");
    const [p_one_session_status_label, setp_one_session_status_label] = useState("Inactif");
    const [p_one_session_etape, setp_one_session_etape] = useState();
    const [p_one_session_etape_label, setp_one_session_etape_label] = useState();
    const [p_one_titre, setp_one_titre] = useState("");
    const [p_one_location_type, setp_one_location_type] = useState("");
    const [p_one_location_type_label, setp_one_location_type_label] = useState("");

    const [p_one_is_bpf, setp_one_is_bpf] = useState("0");
    const [p_one_is_bpf_label, setp_one_is_bpf_label] = useState("Non");

    function annuleSessionUpdate() {
        clearSessionFields();
        setsessionChanged(false);
        setCreateSessionFormation_api();
        setCreateSessionFormation_message();
        setone_CreateSessionFormation_api();
        setone_CreateSessionFormation_message();
        setone_CreateSessionFormation_result();
        GetCurrentSession(selected_session_id, selected_internal_url);
    }

    const [SessionstartDate, setSessionstartDate] = useState();

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const [selectedCertif, setselectedCertif] = useState();
    const [one_selectedCertif, setone_selectedCertif] = useState();

    const [GetSpecificAttestation_Certif_api, setGetSpecificAttestation_Certif_api] = useState();
    const [GetSpecificAttestation_Certif_message, setGetSpecificAttestation_Certif_message] = useState();
    const [GetSpecificAttestation_Certif_result, setGetSpecificAttestation_Certif_result] = useState();
    function GetSpecificAttestation_Certif(nom) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("nom", nom);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetSpecificPartnerAttestation_Certificat/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetSpecificAttestation_Certif  res.data.status = " + res.data.status);
                //console.log(" In GetSpecificAttestation_Certif  res.data.message r_class = " + res.data.message);
                setGetSpecificAttestation_Certif_api("true");
                setGetSpecificAttestation_Certif_result(res.data.message);

                var mylocal = JSON.parse(res.data.message);
                //console.log(" ####  (mylocal.preview_url = ", mylocal.preview_url);
                seturlpreview_certif(mylocal.preview_url);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetSpecificAttestation_Certif_api("false");
                setGetSpecificAttestation_Certif_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetSpecificAttestation_Certif = ', error);
            setGetSpecificAttestation_Certif_api("false");

        })
    }

    const [reduction_session, setreduction_session] = useState("0");
    function Session_small() {
        if (String(reduction_session) === "1") {
            setreduction_session("0");
        }
        else if (String(reduction_session) === "0") {
            setreduction_session("1");
        }

    }

    const [GetAttestation_Certif_api, setGetAttestation_Certif_api] = useState();
    const [GetAttestation_Certif_message, setGetAttestation_Certif_message] = useState();
    const [GetAttestation_Certif_result, setGetAttestation_Certif_result] = useState();
    function GetAttestation_Certif(event) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetPartnerAttestation_Certificat/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetAttestation_Certif  res.data.status = " + res.data.status);
                //console.log(" In GetAttestation_Certif  res.data.message r_class = " + res.data.message);
                setGetAttestation_Certif_api("true");
                setGetAttestation_Certif_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetAttestation_Certif_api("false");
                setGetAttestation_Certif_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetAttestation_Certif = ', error);
            setGetAttestation_Certif_api("false");

        })
    }

    function handleChangeselectedCertif(event) {

        var val = event.target.value
        //console.log(val);
        GetSpecificAttestation_Certif(val)

        setselectedCertif(val);

        //seturlpreview_certif(val[1]);
    }

    const [SessionendDate, setSessionendDate] = useState();

    const filterPassedTime_end = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    function One_annuleSessionUpdate() {
        One_clearSessionFields();
        //GetCurrentSession(selected_code_session);
        setsessionChanged(false);
        setCreateSessionFormation_api();
        setCreateSessionFormation_message();
        setone_CreateSessionFormation_api();
        setone_CreateSessionFormation_message();
        setone_CreateSessionFormation_result()
        setaddOneSession();
    }
    const [SessionendDateInscription, setSessionendDateInscription] = useState();

    const [New_GetCurrentPartnerClass_result, setNew_GetCurrentPartnerClass_result] = useState([]);

    const [GetCurrentPartnerClass_api, setGetCurrentPartnerClass_api] = useState();
    const [GetCurrentPartnerClass_message, setGetCurrentPartnerClass_message] = useState();
    const [GetCurrentPartnerClass_result, setGetCurrentPartnerClass_result] = useState([]);
    function GetCurrentPartnerClass(event) {
        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = selectedfilt1;
        var filtre2 = selectedfilt2;
        var filtre3 = selectedfilt3;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";

        // Recuperation des valeurs de filtres

        if (selectedfilt1) {
            filtre1_value = document.getElementsByName("filtre1")[0].value;
            form.append(filtre1, filtre1_value);
        }

        if (selectedfilt2) {
            filtre2_value = document.getElementsByName("filtre2")[0].value;
            form.append(filtre2, filtre2_value);
        }

        if (selectedfilt3) {
            filtre3_value = document.getElementsByName("filtre3")[0].value;
            form.append(filtre3, filtre3_value);
        }

        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentPartnerClass  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentPartnerClass  res.data.message r_class = " + res.data.message);
                setGetCurrentPartnerClass_api("true");
                setGetCurrentPartnerClass_result(res.data.message);


                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_internal_url = JSON.parse(x).internal_url;
                    var local_title = JSON.parse(x).title;
                    var local_is_published = JSON.parse(x).published;


                    //---
                    var node = {
                        "_id": local_id, "id": localid,
                        "label": local_title,
                        "internal_url": local_internal_url,
                        "title": local_title,
                        "ispublished": local_is_published,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });


                if (new_data2.length > 0)
                    setNew_GetCurrentPartnerClass_result(new_data2);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetCurrentPartnerClass_api("false");
                setGetCurrentPartnerClass_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ', error);
            setGetCurrentPartnerClass_api("false");
            alert(" Impossible de recuperer la liste des formations")
            //setmyApimyApiMessage("")
        })
    }

    function close_edit_form() {
        setEdite_session("0");
        setaddOneSession("");
        setEdite_session("");
        submenu_detail_session();
        setedit_session_form(false);
        setsubmenu("");
        setSelectionModel([]);
    }


    function IssessionChanged() {
        setsessionChanged(true);
    }


    /*
    # status :  0 ==> Preinscription
    # status : 1 ==> Inscription validée
    # status : -1 ==> Inscription annulée
    """
    */
    function handleClick_accept(event, cellValues) {

        var montant = String(cellValues.row.amount);
        if (parseFloat(montant).toFixed(2) <= 0) {
            alert(" Le montant de la formation est incorrect");
            return;
        }

        if (String(montant) === "undefined") {
            alert(" Le montant de la formation est incorrect");
            return;
        }



        var _id = cellValues.row._id;
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;
        var opco = cellValues.row.opco;
        if (String(opco) === "undefined") {
            opco = ""
        }

        var modefinancement = cellValues.row.modefinancement;
        if (String(modefinancement) === "undefined") {
            modefinancement = ""
        }

        var status = "1";
        var employeur = cellValues.row.employeur;
        if (String(employeur) === "undefined") {
            employeur = ""
        }


        UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, "", _id);
    }


    function UpdateStagiaireData(nom, prenom, email, status, price, opco, modefinancement, employeur, comment, object_id) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);
        form.append("nom", nom);
        form.append("prenom", prenom);
        form.append("email", email);
        form.append("employeur", employeur);
        form.append("status", status);
        form.append("price", price);
        form.append("opco", opco);
        form.append("modefinancement", modefinancement);
        form.append("comment", comment);
        form.append("_id", object_id);

        form.append("client_rattachement_id", p_detail_client_rattachement_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdateStagiaireData_api("true");
                setUpdateStagiaireData_result(res.data.message);
                GetListePreinscrit(selected_session_id, selected_internal_url);
                GetListeInscrit(selected_session_id, selected_internal_url);

                GetListeEmargement(selected_session_id, selected_internal_url);

                setretval_ch("1");
                alert(" La mise à jour été correctement faite.")
            }
            else {
                setUpdateStagiaireData_api("false");
                setUpdateStagiaireData_message(res.data.message);
                setretval_ch("-1");
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireData_api("false");
            setretval_ch("-1");
            alert(" Impossible d'ajouter le participant")

        })
    }


    function handleClick_update_data(event, cellValues) {


        var montant = String(cellValues.row.amount);
        if (String(montant) === "undefined") {
            montant = "0"
        }

        var _id = cellValues.row._id;
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;
        var opco = cellValues.row.opco;
        if (String(opco) === "undefined") {
            opco = ""
        }

        var modefinancement = cellValues.row.modefinancement;
        if (String(modefinancement) === "undefined") {
            modefinancement = ""
        }

        var status = "0";

        var employeur = cellValues.row.employeur;
        if (String(employeur) === "undefined") {
            employeur = ""
        }

        var comment = "";


        UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, comment, _id);
    }

    function handleClick_refuse(event, cellValues) {
        // Recuperation du motif du refus :
        var motif_refus = document.getElementById("motif_refus").value;

        var montant = String(cellValues.row.amount);
        if (String(montant) === "undefined") {
            montant = "0"
        }

        var _id = cellValues.row._id;
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;

        var opco = cellValues.row.opco;
        if (String(opco) === "undefined") {
            opco = ""
        }

        var modefinancement = cellValues.row.modefinancement;
        if (String(modefinancement) === "undefined") {
            modefinancement = ""
        }

        var status = "-1";
        var employeur = cellValues.row.employeur;
        if (String(employeur) === "undefined") {
            employeur = ""
        }

        UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, motif_refus, _id);
    }


    function handleClick_delete(event, cellValues) {
        // Recuperation du motif du refus :


        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;

        DeleteStagiaireData(nom, prenom, email);
    }


    const [p_one_civilite_part, setp_one_civilite_part] = useState();
    const [p_one_nom_part, setp_one_nom_part] = useState();
    const [p_one_prenom_part, setp_one_prenom_part] = useState();
    const [p_one_email_part, setp_one_email_part] = useState();
    const [p_one_naissance_part, setp_one_naissance_part] = useState(new Date().toLocaleDateString('fr-FR'));

    const [p_one_phone_part, setp_one_phone_part] = useState();
    const [p_one_status_part, setp_one_status_part] = useState();
    const [p_one_status_part_lable, setp_one_status_part_lable] = useState();


    /* Envoi de l'envoi de l'email de confirmation de la validation de l'inscription */
    function handleClick_resend_conf_mail(event, cellValues) {
        var email = cellValues.row.email;
        //alert(" confirmation email = " + email);
        SendInscriptionConfirmation(email);

    }

    const [selected_inscription_id, setselected_inscription_id] = useState("");
    function Display_Detail_Inscription(event, cellValues) {
        clearDetailAttendeFields();
        setdisplay_detail_insc("1")
        setuserimgclassprofil("");
        var montant = String(cellValues.row.amount);
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        setselectedattendeeemail(email);

        setselected_inscription_id(cellValues.row._id);
        var prenom = cellValues.row.prenom;
        GetAttendee_Data(email, selected_session_id);
        getStagiaireImages(selected_internal_url, selected_session_id, email,);

        Get_List_Of_All_PJ(cellValues.row._id);

    }

    const [print_detail_insc, setprint_detail_insc] = React.useState();
    function Print_Detail_Inscription(event, cellValues) {

        setprint_detail_insc("1")

        var email = cellValues.row.email;
        setselectedattendeeemail(email);
        DownloadAttendeeDetail();

    }

    function DownloadAttendeeDetail(event) {


        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeDetail_perSession/" + stored_cookie + "/" + selected_code_session + "/" + selectedattendeeemail + "/" + selected_internal_url;

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee)
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);

            });
    }

    const [display_detail_insc, setdisplay_detail_insc] = React.useState();

    const [getPartnerImages_api, setgetPartnerImages_api] = useState();
    const [getPartnerImages_message, setgetPartnerImages_message] = useState();
    const [getPartnerImages_result, setgetPartnerImages_result] = useState();
    function getStagiaireImages(local_internal_url, local_mysession, local_email) {
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedStagiaireImage_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", local_internal_url);
        form.append("session_id", local_mysession);
        form.append("email", local_email);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" getStagiaireImages : In test  res.data.status = " + res.data.status);
            // console.log(" getStagiaireImages: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setgetPartnerImages_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setgetPartnerImages_api("false");
                setgetPartnerImages_message(res.data.message)

            }
        }).catch((error) => {
            setLoading(false);
            console.warn('getStagiaireImages ee: Not good man :(  = ', error);
            setgetPartnerImages_api("false");
            setgetPartnerImages_message(" Impossible de recuperer les images/médias du partenaire")
        })

    }

    // Cette fonction remplit la zone detail de la personne inscrite
    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function GetAttendee_Data(attendee_email, session_id,) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", session_id);
        form.append("attendee_email", attendee_email);
        form.append("internal_url", selected_internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAttendeeDetail_perSession/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === "true") {
                //console.log(" In GetAttendee  res.data.status = " + res.data.status);
                //console.log(" In GetAttendee  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                var mylocalattendee = JSON.parse(res.data.message);


                if (mylocalattendee.inscription_validation_date) {
                    var date_du = new Date(moment(mylocalattendee.inscription_validation_date, "DD/MM/YYYY HH:mm:ss"));
                    setdetailuser_date_inscription(date_du);
                }


                document.getElementsByName("detail_session")[0].value = "";
                if (mylocalattendee.code_session)
                    setp_detail_session_class(mylocalattendee.code_session);

                if (mylocalattendee.session_id)
                    setp_detail_session_class_id(mylocalattendee.session_id);


                document.getElementsByName("detail_status_inscript")[0].value = "";
                if (mylocalattendee.status) {
                    setp_detail_status_inscrit(mylocalattendee.status);
                    if (String(mylocalattendee.status) === "1")
                        setp_detail_status_inscrit_label("Inscrit");
                    else if (String(mylocalattendee.status) === "0")
                        setp_detail_status_inscrit_label("Preinsc.");
                    else if (String(mylocalattendee.status) === "-1")
                        setp_detail_status_inscrit_label("Annulé");
                }

                document.getElementsByName("detail_opco")[0].value = "";
                if (mylocalattendee.opco)
                    setp_detail_opco(mylocalattendee.opco);
                else {
                    setp_detail_opco("")
                }


                document.getElementsByName("detail_client_rattachement")[0].value = "";
                if (mylocalattendee.client_rattachement_id) {
                    setp_detail_client_rattachement_id(mylocalattendee.client_rattachement_id);
                    setp_detail_client_rattachement_nom(mylocalattendee.client_rattachement_nom);

                } else {
                    setp_detail_client_rattachement_id("");
                    setp_detail_client_rattachement_nom("");
                }


                document.getElementsByName("detail_nom")[0].value = "";
                if (mylocalattendee.nom)
                    setp_detail_nom(mylocalattendee.nom);
                else {
                    setp_detail_nom("")
                }

                document.getElementsByName("detail_prenom")[0].value = "";
                if (mylocalattendee.prenom)
                    setp_detail_prenom(mylocalattendee.prenom);
                else {
                    setp_detail_prenom("")
                }

                document.getElementsByName("detail_mail")[0].value = "";
                if (mylocalattendee.email) {
                    setp_detail_mail(mylocalattendee.email);
                    setattendee_lms_login(mylocalattendee.email);
                }
                else {
                    setp_detail_mail("");
                    setattendee_lms_login("")
                }


                document.getElementsByName("detail_tel")[0].value = "";
                if (mylocalattendee.telephone) {
                    setp_detail_tel(mylocalattendee.telephone);
                }
                else {
                    setp_detail_tel("")
                }

                document.getElementsByName("detail_adresse")[0].value = "";
                if (mylocalattendee.adresse) {
                    setp_detail_adresse(mylocalattendee.adresse);
                }
                else {
                    setp_detail_adresse("")
                }

                document.getElementsByName("detail_code_postal")[0].value = "";
                if (mylocalattendee.code_postal) {
                    setp_detail_code_postal(mylocalattendee.code_postal);
                }
                else {
                    setp_detail_code_postal("")
                }

                document.getElementsByName("detail_ville")[0].value = "";
                if (mylocalattendee.ville) {
                    setp_detail_ville(mylocalattendee.ville);
                }
                else {
                    setp_detail_ville("")
                }


                document.getElementsByName("detail_cout")[0].value = "";
                if (mylocalattendee.price) {
                    setp_detail_cout(mylocalattendee.price);
                }
                else {
                    setp_detail_cout("")
                }


                document.getElementsByName("detail_mode_fin")[0].value = "";
                if (mylocalattendee.modefinancement) {
                    setp_detail_mode_fin(mylocalattendee.modefinancement);
                }
                else {
                    setp_detail_mode_fin("")
                }


                document.getElementsByName("detail_employeur")[0].value = "";
                if (mylocalattendee.employeur) {
                    setp_detail_employeur(mylocalattendee.employeur);
                } else {
                    setp_detail_employeur("")
                }

                document.getElementsByName("date_evaluation")[0].value = "";
                if (mylocalattendee.eval_date) {

                    var date_eval = new Date(moment(mylocalattendee.eval_date, "YYYY/MM/DD"));
                    setdetailuser_date_evaluation(date_eval);
                }

                document.getElementsByName("certif_date")[0].value = "";
                if (mylocalattendee.certification_send_date) {
                    //alert(" certification_send_date = ", mylocalattendee.certification_send_date);

                    var date_eval = new Date(moment(mylocalattendee.certification_send_date, "DD/MM/YYYY"));
                    setdetailuser_date_certification(date_eval);
                }



                document.getElementsByName("note_eval")[0].value = "";
                if (mylocalattendee.eval_note) {
                    setp_note_eval(mylocalattendee.eval_note);
                }


                if (mylocalattendee.eval_eval) {
                    setfield_evaluation(mylocalattendee.eval_eval);

                }

                if (mylocalattendee.lms_pwd) {
                    setattendee_lms_pwd("xxxxxx");
                }

                /*
                 * Update 22/10/2023 :
                 Gestion des champs spécifiques. ils commencent tous par 'my_'
                 */
                for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

                    var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
                    var field_type = JSON.parse(rows_champs_specifics_stagiaire[i]).field_type;
                    var field_label = JSON.parse(rows_champs_specifics_stagiaire[i]).field_label;
                    var is_mandatory = JSON.parse(rows_champs_specifics_stagiaire[i]).is_mandatory;


                    if (mylocalattendee.hasOwnProperty(field_name)) {
                        var local_valeur = mylocalattendee[String(field_name)]

                        if (document.getElementById(String(field_name)))
                            document.getElementById(String(field_name)).value = local_valeur;

                        var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                        var johnIndex = findIndexByProperty(spec_field_updated_values_stagiaire, 'field_name', field_name);

                        if (johnIndex > -1) {
                            spec_field_updated_values_stagiaire[johnIndex] = new_val;

                        } else {
                            spec_field_updated_values_stagiaire.push(new_val);
                        }

                    } else {

                    }
                }

                setspec_field_updated_values_hooks_stagiaire(spec_field_updated_values_stagiaire);
                /*
                                   *  end Update 22/10/2023 :
                                   */

                var myelement;

                if (document.getElementById('ajout_participant')) {
                    //myelement = document.getElementById('ajout_participant');
                    var divh = document.getElementById('ajout_participant').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

                disableAttendeeDetailFields();




            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetAttendee = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données du participant");
            //setmyApimyApiMessage("")
        })
    }

    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");

    const [record_Stagiaire_Image_api, setrecord_Stagiaire_Image_api] = useState();
    const [record_Stagiaire_Image_message, setrecord_Stagiaire_Image_message] = useState();
    const [record_Stagiaire_Image_result, setrecord_Stagiaire_Image_result] = useState();
    function record_Stagiaire_Image(l) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Update_Stagiaire_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("class_internal_url", selected_internal_url);
        formData.append("session_id", selected_session_id);
        formData.append("email", selectedattendeeemail);

        //console.log(" ### userimgclassprofilchanged =", userimgclassprofilchanged)


        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);
        setLoading(true);

        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_Stagiaire_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_Stagiaire_Image_api("false");
                    alert(String(result['message']));
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setrecord_Stagiaire_Image_api("false");
                alert(" Impossible d'enregistrer l'image ");

            });


    };


    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/DeleteImage_Stagiaire_v2/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log(' removeRecodedClassImage : status:', result['status']);
                //console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");
                    //getStagiaireImages();

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }

    const [Resend_LMS_Credentials_api, setResend_LMS_Credentials_api] = useState();
    const [Resend_LMS_Credentials_result, setResend_LMS_Credentials_result] = useState();
    const [Resend_LMS_Credentials_message, setResend_LMS_Credentials_message] = useState();
    const Resend_LMS_Credentials = event => {

        clean_old_messages();
        const formData = new FormData();

        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("session_id", selected_session_id);

        var local_mail = ""
        if (document.getElementsByName("detail_mail") && document.getElementsByName("detail_mail")[0])
            local_mail = document.getElementsByName("detail_mail")[0].value;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!local_mail.trim().match(validRegex)) {
            alert("L'adresse email est invalide. Impossible de renvoyer les identifiants");
            return;
        }
        formData.append("email", local_mail.trim());
        formData.append("class_internal_url", selected_internal_url);
        //console.log("token = " + stored_cookie);
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/LMS_Credential_Sending_mail/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setResend_LMS_Credentials_result(result['message']);
                    setResend_LMS_Credentials_api("true");
                    alert(" Les identifiants de connexion ont été correctement envoyés")
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setResend_LMS_Credentials_message(result['message']);
                    setResend_LMS_Credentials_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setResend_LMS_Credentials_api("false");
            });

    };

    function clearDetailAttendeFields() {

        if (document.getElementsByName("detail_nom") && document.getElementsByName("detail_nom")[0])
            document.getElementsByName("detail_nom")[0].value = "";

        if (document.getElementsByName("detail_prenom") && document.getElementsByName("detail_prenom")[0])
            document.getElementsByName("detail_prenom")[0].value = "";

        if (document.getElementsByName("detail_mail") && document.getElementsByName("detail_mail")[0])
            document.getElementsByName("detail_mail")[0].value = "";

        if (document.getElementsByName("detail_tel") && document.getElementsByName("detail_tel")[0])
            document.getElementsByName("detail_tel")[0].value = "";

        if (document.getElementsByName("detail_adresse") && document.getElementsByName("detail_adresse")[0])
            document.getElementsByName("detail_adresse")[0].value = "";

        if (document.getElementsByName("detail_code_postal") && document.getElementsByName("detail_code_postal")[0])
            document.getElementsByName("detail_code_postal")[0].value = "";

        if (document.getElementsByName("detail_ville") && document.getElementsByName("detail_ville")[0])
            document.getElementsByName("detail_ville")[0].value = "";

        if (document.getElementsByName("detail_incr_date") && document.getElementsByName("detail_incr_date")[0])
            document.getElementsByName("detail_incr_date")[0].value = "";

        if (document.getElementsByName("detail_cout") && document.getElementsByName("detail_cout")[0])
            document.getElementsByName("detail_cout")[0].value = "";

        if (document.getElementsByName("detail_mode_fin") && document.getElementsByName("detail_mode_fin")[0])
            document.getElementsByName("detail_mode_fin")[0].value = "";

        if (document.getElementsByName("detail_employeur") && document.getElementsByName("detail_employeur")[0])
            document.getElementsByName("detail_employeur")[0].value = "";

        if (document.getElementsByName("date_evaluation") && document.getElementsByName("date_evaluation")[0])
            document.getElementsByName("date_evaluation")[0].value = "";

        if (document.getElementsByName("note_eval") && document.getElementsByName("note_eval")[0])
            document.getElementsByName("note_eval")[0].value = "";

        if (document.getElementsByName("certif_date") && document.getElementsByName("certif_date")[0])
            document.getElementsByName("certif_date")[0].value = "";

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].value = "";
            }
        }

        setfield_evaluation();
        setdetailuser_date_certification();
        setdetailuser_date_evaluation();


    }
    const [attendee_lms_login, setattendee_lms_login] = useState();
    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);

    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

    const editorRef_evaluation = useRef(null);
    const [field_evaluation, setfield_evaluation] = useState("");
    function editor_keyup() {
    }


    function editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }


    function one_editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }
    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };

    const [SendEvaluationEmail_api, setSendEvaluationEmail_api] = useState();
    const [SendEvaluationEmail_message, setSendEvaluationEmail_message] = useState();
    const [SendEvaluationEmail_result, setSendEvaluationEmail_result] = useState();
    function SendEvaluationEmail() {
        clean_old_messages();
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("attendee_email", selectedattendeeemail);
        form.append("class_internal_url", selected_internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendTrainingEvaluationEmail/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendEvaluationEmail  res.data.status = " + res.data.status);
                //console.log(" In SendEvaluationEmail  res.data.message r_class = " + res.data.message);
                setSendEvaluationEmail_api("true");
                setSendEvaluationEmail_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendEvaluationEmail_api("false");
                setSendEvaluationEmail_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendEvaluationEmail = ', error);
            setSendEvaluationEmail_api("false");

        })
    }

    function SendEvaluationEmail_One(email) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("attendee_email", email);
        form.append("class_internal_url", selected_internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendTrainingEvaluationEmail/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In SendEvaluationEmail  res.data.status = " + res.data.status);
                //console.log(" In SendEvaluationEmail  res.data.message r_class = " + res.data.message);
                setSendEvaluationEmail_api("true");
                setSendEvaluationEmail_result(res.data.message);
                alert(" La demande a été correctement envoyée");

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendEvaluationEmail_api("false");
                setSendEvaluationEmail_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( SendEvaluationEmail = ', error);
            setSendEvaluationEmail_api("false");

        })
    }

    const [attendee_lms_pwd, setattendee_lms_pwd] = useState();

    const [SendAttendeeAttestation_api, setSendAttendeeAttestation_api] = useState();
    const [SendAttendeeAttestation_message, setSendAttendeeAttestation_message] = useState();
    const [SendAttendeeAttestation_result, setSendAttendeeAttestation_result] = useState();
    function SendAttendeeAttestation() {
        clean_old_messages();
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("attendee_email", selectedattendeeemail);
        form.append("class_internal_url", selected_internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendAttendeeCertification/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In SendAttendeeAttestation  res.data.status = " + res.data.status);
                //console.log(" In SendAttendeeAttestation  res.data.message r_class = " + res.data.message);
                setSendAttendeeAttestation_api("true");
                setSendAttendeeAttestation_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendAttendeeAttestation_api("false");
                setSendAttendeeAttestation_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( SendAttendeeAttestation = ', error);
            setSendAttendeeAttestation_api("false");

        })

    }

    function desableAttendeeDetailFields() {
        document.getElementsByName("date_evaluation")[0].disabled = true;
        document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_incr_date")[0].disabled = true;
        document.getElementsByName("detail_incr_date")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("certif_date")[0].disabled = true;
        document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";
    }


    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    function clean_all_filters() {
        setp_filtre1();
        setp_filtre1_value();
        setp_filtre2();
        setp_filtre2_value();
        setgridline_id('');

        setnb_stagiaire_Preinscrit("");
        setnb_stagiaire_Inscrit("");
        setSelectionModel([]);
        setRows([]);
        setRows_insc([]);
        setRows_preinsc([]);

        setsubmenu("");

        Getall_TrainingSession_no_filter();
    }

    const myRef = useRef(null)

    let [participant_data_changed, setparticipant_data_changed] = useState();
    let [participant_data_edit_mode, setparticipant_data_edit_mode] = useState();

    function AnnuleAttendeeDetailFields() {

        clearDetailAttendeFields();
        GetAttendee_Data(selectedattendeeemail, selected_session_id);
        disableAttendeeDetailFields();
        setparticipant_data_changed();
        setparticipant_data_edit_mode();
    }

    function disableAttendeeDetailFields() {
        setparticipant_data_edit_mode("0");

        if (document.getElementsByName("detail_session_class")[0]) {
            document.getElementsByName("detail_session_class")[0].disabled = true;
            document.getElementsByName("detail_session_class")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_client_rattachement")[0]) {
            document.getElementsByName("detail_client_rattachement")[0].disabled = true;
            document.getElementsByName("detail_client_rattachement")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_nom")[0]) {
            document.getElementsByName("detail_nom")[0].disabled = true;
            document.getElementsByName("detail_nom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_status_inscript")[0]) {
            document.getElementsByName("detail_status_inscript")[0].disabled = true;
            document.getElementsByName("detail_status_inscript")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_opco")[0]) {
            document.getElementsByName("detail_opco")[0].disabled = true;
            document.getElementsByName("detail_opco")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_mode_fin")[0]) {
            document.getElementsByName("detail_mode_fin")[0].disabled = true;
            document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_employeur")[0]) {
            document.getElementsByName("detail_employeur")[0].disabled = true;
            document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_cout")[0]) {
            document.getElementsByName("detail_cout")[0].disabled = true;
            document.getElementsByName("detail_cout")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_prenom")[0]) {
            document.getElementsByName("detail_prenom")[0].disabled = true;
            document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_mail")[0]) {
            document.getElementsByName("detail_mail")[0].disabled = true;
            document.getElementsByName("detail_mail")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tel")[0]) {
            document.getElementsByName("detail_tel")[0].disabled = true;
            document.getElementsByName("detail_tel")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_adresse")[0]) {
            document.getElementsByName("detail_adresse")[0].disabled = true;
            document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_code_postal")[0]) {
            document.getElementsByName("detail_code_postal")[0].disabled = true;
            document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_ville")[0]) {
            document.getElementsByName("detail_ville")[0].disabled = true;
            document.getElementsByName("detail_ville")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_pays")[0]) {
            document.getElementsByName("detail_pays")[0].disabled = true;
            document.getElementsByName("detail_pays")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_cout")[0]) {
            document.getElementsByName("detail_cout")[0].disabled = true;
            document.getElementsByName("detail_cout")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_mode_fin")[0]) {
            document.getElementsByName("detail_mode_fin")[0].disabled = true;
            document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_employeur")[0]) {
            document.getElementsByName("detail_employeur")[0].disabled = true;
            document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("date_evaluation")[0]) {
            document.getElementsByName("date_evaluation")[0].disabled = true;
            document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("certif_date")[0]) {
            document.getElementsByName("certif_date")[0].disabled = true;
            document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("note_eval")[0]) {
            document.getElementsByName("note_eval")[0].disabled = true;
            document.getElementsByName("note_eval")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("date_evaluation")[0]) {
            document.getElementsByName("date_evaluation")[0].disabled = true;
            document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_incr_date")[0]) {

            document.getElementsByName("detail_incr_date")[0].disabled = true;
            document.getElementsByName("detail_incr_date")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("certif_date")[0]) {
            document.getElementsByName("certif_date")[0].disabled = true;
            document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";

        }

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = true;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
            }
        }

    }

    function EnableAttendeeDetailFields() {
        setparticipant_data_edit_mode("1");

        if (document.getElementsByName("detail_session_class")[0]) {
            document.getElementsByName("detail_session_class")[0].disabled = false;
            document.getElementsByName("detail_session_class")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_client_rattachement")[0]) {
            document.getElementsByName("detail_client_rattachement")[0].disabled = false;
            document.getElementsByName("detail_client_rattachement")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_nom")[0]) {
            document.getElementsByName("detail_nom")[0].disabled = false;
            document.getElementsByName("detail_nom")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_status_inscript")[0]) {
            document.getElementsByName("detail_status_inscript")[0].disabled = false;
            document.getElementsByName("detail_status_inscript")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_opco")[0]) {
            document.getElementsByName("detail_opco")[0].disabled = false;
            document.getElementsByName("detail_opco")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_cout")[0]) {
            document.getElementsByName("detail_cout")[0].disabled = false;
            document.getElementsByName("detail_cout")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_employeur")[0]) {
            document.getElementsByName("detail_employeur")[0].disabled = false;
            document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_mode_fin")[0]) {
            document.getElementsByName("detail_mode_fin")[0].disabled = false;
            document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_prenom")[0]) {
            document.getElementsByName("detail_prenom")[0].disabled = false;
            document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_mail")[0]) {
            document.getElementsByName("detail_mail")[0].disabled = false;
            document.getElementsByName("detail_mail")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_tel")[0]) {
            document.getElementsByName("detail_tel")[0].disabled = false;
            document.getElementsByName("detail_tel")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_adresse")[0]) {
            document.getElementsByName("detail_adresse")[0].disabled = false;
            document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_code_postal")[0]) {
            document.getElementsByName("detail_code_postal")[0].disabled = false;
            document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_ville")[0]) {
            document.getElementsByName("detail_ville")[0].disabled = false;
            document.getElementsByName("detail_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_pays")[0]) {
            document.getElementsByName("detail_pays")[0].disabled = false;
            document.getElementsByName("detail_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_cout")[0]) {
            document.getElementsByName("detail_cout")[0].disabled = false;
            document.getElementsByName("detail_cout")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_mode_fin")[0]) {
            document.getElementsByName("detail_mode_fin")[0].disabled = false;
            document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_employeur")[0]) {
            document.getElementsByName("detail_employeur")[0].disabled = false;
            document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("date_evaluation")[0]) {
            document.getElementsByName("date_evaluation")[0].disabled = false;
            document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("certif_date")[0]) {
            document.getElementsByName("certif_date")[0].disabled = false;
            document.getElementsByName("certif_date")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("note_eval")[0]) {
            document.getElementsByName("note_eval")[0].disabled = false;
            document.getElementsByName("note_eval")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("date_evaluation")[0]) {
            document.getElementsByName("date_evaluation")[0].disabled = false;
            document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_incr_date")[0]) {
            document.getElementsByName("detail_incr_date")[0].disabled = false;
            document.getElementsByName("detail_incr_date")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("certif_date")[0]) {
            document.getElementsByName("certif_date")[0].disabled = false;
            document.getElementsByName("certif_date")[0].style.backgroundColor = "#FFFFFF";
        }

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;

            // console.log(" #### field_name = ", field_name);

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = false;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#FFFFFF";
            }
        }


    }

    const [p_detail_nom, setp_detail_nom] = useState();
    const [p_detail_prenom, setp_detail_prenom] = useState();
    const [p_detail_mail, setp_detail_mail] = useState();
    const [p_detail_tel, setp_detail_tel] = useState();
    const [p_detail_adresse, setp_detail_adresse] = useState();
    const [p_detail_code_postal, setp_detail_code_postal] = useState();
    const [p_detail_ville, setp_detail_ville] = useState();
    const [p_detail_cout, setp_detail_cout] = useState();
    const [p_detail_mode_fin, setp_detail_mode_fin] = useState();
    const [p_detail_employeur, setp_detail_employeur] = useState();
    const [p_date_evaluation, setp_date_evaluation] = useState();
    const [p_note_eval, setp_note_eval] = useState();
    const [p_detail_session_class, setp_detail_session_class] = useState();
    const [p_detail_session_class_id, setp_detail_session_class_id] = useState();
    const [p_detail_status_inscrit, setp_detail_status_inscrit] = useState();
    const [p_detail_status_inscrit_label, setp_detail_status_inscrit_label] = useState();
    const [p_detail_opco, setp_detail_opco] = useState();
    const [p_detail_client_rattachement_nom, setp_detail_client_rattachement_nom] = useState();
    const [p_detail_client_rattachement_id, setp_detail_client_rattachement_id] = useState();
    const [p_detail_titre, setp_detail_titre] = useState();

    const [p_detail_location_type, setp_detail_location_type] = useState();
    const [p_detail_location_type_label, setp_detail_location_type_label] = useState("");

    const [p_detail_is_bpf, setp_detail_is_bpf] = useState();
    const [p_detail_is_bpf_label, setp_detail_is_bpf_label] = useState();


    const emplacement_tab = [
        {
            value: 'intra',
            label: 'Intra',
        },
        {
            value: 'extra',
            label: 'Extra',
        },
        {
            value: 'autre',
            label: 'Autre',
        },

    ];

    const inclus_bpf_tab = [
        {
            value: '1',
            label: 'Oui',
        },
        {
            value: '0',
            label: 'Non',
        },
    ];

    const status_tab = [
        {
            value: '1',
            label: 'Actif',
        },
        {
            value: '0',
            label: 'Inactif',
        },
    ];


    const session_ondemande_tab = [
        {
            value: '1',
            label: 'Oui',
        },
        {
            value: '0',
            label: 'Non',
        },

    ];

    const presentiel_tab = [
        {
            value: '1',
            label: 'Oui',
        },
        {
            value: '0',
            label: 'Non',
        },
    ];

    const distaciel_tab = [
        {
            value: '1',
            label: 'Oui',
        },
        {
            value: '0',
            label: 'Non',
        },
    ];



    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {

    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const [Dialog_2_message, setDialog_2_message] = React.useState(false);
    const [Dialog_2_open, setDialog_2_open] = React.useState(false);
    function Dialog_2_handle_change_participant_session(message) {
        setDialog_2_message(message);
        setDialog_2_open(true);
    }

    const Dialog_2_handleClose = () => {

    };

    const Dialog_2_handleClose_buton = () => {
        setDialog_2_open(false);
        setsession_selected_row_id('');
        setgridline_id('');
    };



    const [isLoading, setLoading] = useState();


    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();

    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }
        setfile_1_name(event.target.files[0]);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };


    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }
        setfile_2_name(event.target.files[0]);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();

    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "inscription");
            formData.append("object_owner_id", selected_inscription_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);
            setLoading(true);

            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    setLoading(false);
                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(selected_inscription_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            //console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();

    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(selected_inscription_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();

    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "inscription");
        formData.append("object_owner_id", local_employee_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    const [Get_Class_Data_api, setGet_Class_Data_api] = useState();
    const [Get_Class_Data_message, setGet_Class_Data_message] = useState();
    const [Get_Class_Data_result, setGet_Class_Data_result] = useState();
    function Get_Class_Data(local_internal_url) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("internal_url", local_internal_url);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Class_From_Internal_Url/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Class_Data  res.data.status = " + res.data.status);
            //console.log(" In Get_Class_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_Class_Data_api("true");
                setGet_Class_Data_message(res.data.message);

                if (JSON.parse(res.data.message).price) {
                    setp_one_prix_session(String(JSON.parse(res.data.message).price));
                    setp_prix_session(String(JSON.parse(res.data.message).price));

                }

                if (JSON.parse(res.data.message).formateur_id) {
                    setp_one_formateur_id(JSON.parse(res.data.message).formateur_id);
                    setp_formateur_id(JSON.parse(res.data.message).formateur_id);
                }


            }
            else {
                setGet_Class_Data_api("false");
                setGet_Class_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.warn('Get_Class_Data : Not good man :( Get_Class_Data = ' + error);
            setGet_Class_Data_api("false");
            alert(" Impossible de récuperer les données de la formation  ");
        })


    }

    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes

    // Action en mass

    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();
    const actionmass_ftion = (event) => {
        const value = event.target.value;


        if (String(value) === "supprimer" || String(value) === "dupliquer" || String(value) === "desactiver") {
            setactionmass_ftion_val(value);
        }
        else {
            setactionmass_ftion_val();
        }
    }

    async function actionmass_ftion_Traitemet() {


        var liste_session = GetSelectedRowsSession();

        //console.log(" liste_formation = ", liste_formation);
        //console.log(" actionmass_ftion_val = ", actionmass_ftion_val);

        if (String(actionmass_ftion_val) === String("supprimer")) {

            //console.log(" ### liste session : ", liste_session)
            Delete_LIST_Session_Formation(liste_session);

        }

        else if (String(actionmass_ftion_val) === String("dupliquer")) {
            Dupliquer_LIST_Session_Formation(liste_session);
        }

        else if (String(actionmass_ftion_val) === String("publier")) {

        }

        else if (String(actionmass_ftion_val) === String("desactiver")) {

        }

    }

    function GetSelectedRowsSession() {
        ///alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length);
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);
        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss[myid]);
            tab_tmp.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining(tab_tmp);
        return tab_tmp;

    }

    const [Delete_LIST_Session_Formation_api, setDelete_LIST_Session_Formation_api] = useState();
    const [Delete_LIST_Session_Formation_message, setDelete_LIST_Session_Formation_message] = useState();
    const [Delete_LIST_Session_Formation_result, setDelete_LIST_Session_Formation_result] = useState();
    function Delete_LIST_Session_Formation(local_liste_session) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        //console.log(" #### local_liste_session = ", local_liste_session)

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_session_id", local_liste_session);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_List_SessionFormation/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_LIST_Session_Formation  res.data.status = " + res.data.status);
            //console.log(" In Delete_LIST_Session_Formation  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_LIST_Session_Formation_api("true");
                setDelete_LIST_Session_Formation_result(res.data.message);
                setSelectionModel([]);
                setaddOneParticipant();
                setdisplay_detail_insc();
                setselected_code_session();
                setsubmenu();
                Getall_TrainingSession();

                alert(res.data.message);
            }
            else {
                setDelete_LIST_Session_Formation_api("false");
                setDelete_LIST_Session_Formation_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_LIST_Session_Formation : Not good man :( mysearchtext = ' + error);
            setDelete_LIST_Session_Formation_api("false");
            alert(" Impossible de supprimer la liste des sessions de formation");
        })
    }

    const [Dupliquer_LIST_Session_Formation_api, setDupliquer_LIST_Session_Formation_api] = useState();
    const [Dupliquer_LIST_Session_Formation_message, setDupliquer_LIST_Session_Formation_message] = useState();
    const [Dupliquer_LIST_Session_Formation_result, setDupliquer_LIST_Session_Formation_result] = useState();
    function Dupliquer_LIST_Session_Formation(local_liste_session) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        //console.log(" #### local_liste_session = ", local_liste_session)

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_session_id", local_liste_session);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Duplicate_List_Session_Formation/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Dupliquer_LIST_Session_Formation  res.data.status = " + res.data.status);
            //console.log(" In Dupliquer_LIST_Session_Formation  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDupliquer_LIST_Session_Formation_api("true");
                setDupliquer_LIST_Session_Formation_result(res.data.message);
                setSelectionModel([]);
                setaddOneParticipant();
                setdisplay_detail_insc();
                setselected_code_session();
                setsubmenu();
                Getall_TrainingSession();

                alert(res.data.message);
            }
            else {
                setDupliquer_LIST_Session_Formation_api("false");
                setDupliquer_LIST_Session_Formation_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Dupliquer_LIST_Session_Formation : Not good man :( mysearchtext = ' + error);
            setDupliquer_LIST_Session_Formation_api("false");
            alert(" Impossible de dupliquer la liste des sessions de formation");
        })
    }
    // -- end action en mass

    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
     
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
     
    */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values = []
    const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);


    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks(spec_field_updated_values)
    }

    // -- pour les stagiaires
    const list_nom_champs_spec_stagiaire = []
    const [spec_champs_valeur_stagiaire, setspec_champs_valeur_stagiaire] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_stagiaire_api, setGet_Partner_Object_Specific_Fields_stagiaire_api] = useState();
    const [Get_Partner_Object_Specific_Fields_stagiaire_message, setGet_Partner_Object_Specific_Fields_stagiaire_message] = useState();
    const [Get_Partner_Object_Specific_Fields_stagiaire_result, setGet_Partner_Object_Specific_Fields_stagiaire_result] = useState();
    function Get_Partner_Object_Specific_Fields_stagiaire() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", "inscription");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_stagiaire_api("true");
                setGet_Partner_Object_Specific_Fields_stagiaire_result(res.data.message);


                setrows_champs_specifics_stagiaire(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_stagiaire_api("false");
                setGet_Partner_Object_Specific_Fields_stagiaire_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_stagiaire_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics_stagiaire, setrows_champs_specifics_stagiaire] = useState([]);
    const [display_champs_specifics_stagiaire, setdisplay_champs_specifics_stagiaire] = useState("0");

    function afficher_champs_spec_stagiaire() {

        setdisplay_champs_specifics_stagiaire("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }


    const spec_field_updated_values_stagiaire = []
    const [spec_field_updated_values_hooks_stagiaire, setspec_field_updated_values_hooks_stagiaire] = useState([]);


    function change_champs_spec_handle_stagiaire() {

        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics_stagiaire[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics_stagiaire[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics_stagiaire[i]).is_mandatory;

            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values_stagiaire, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values_stagiaire[johnIndex] = new_val;

                } else {
                    spec_field_updated_values_stagiaire.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks_stagiaire(spec_field_updated_values_stagiaire)
    }

    // -- end champs specifiques 

    function Show_Detailled_Session_Data() {
        setsessionChanged(false);
        Dialog_2_handleClose_buton();
        handleClick_edit_session_From_Line(session_selected_row_id);

        Getall_Sequence_Of_Session(selected_session_id);
    }

    const [p_detail_sequence_title, setp_detail_sequence_title] = useState();
    const [p_detail_sequence_comment, setp_detail_sequence_comment] = useState();
    const [sequence_startDate, setsequence_startDate] = useState(new Date());
    const [sequence_endDate, setsequence_endDate] = useState(new Date());


    const [selected_detail_sequence_title, setselected_detail_sequence_title] = useState();
    const [selected_detail_sequence_comment, setselected_detail_sequence_comment] = useState();
    const [selected_sequence_startDate, setselected_sequence_startDate] = useState(new Date());
    const [selected_sequence_endDate, setselected_sequence_endDate] = useState(new Date());


    const [Dialog_seq_1_message, setDialog_seq_1_message] = React.useState(false);
    const [Dialog_seq_1_open, setDialog_seq_1_open] = React.useState(false);

    function Close_Dialog_Detail_seq_open() {
        setDialog_seq_1_open(false);
    }

    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_1_message(message);
        setDialog_seq_1_open(true);
    }

    const Dialog_seq_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_seq_1_handleClose_buton = () => {
        setDialog_seq_1_open(false);
    };


    function submenu_add_one_sequence() {
        setupdate_sequence();
        setp_detail_sequence_title();

        setp_detail_sequence_comment();
        setsequence_startDate(new Date());
        setsequence_endDate(new Date());

        setDialog_seq_1_open(true);
    }


    function submenu_update_sequence() {
        setupdate_sequence("1");

        setDialog_seq_1_open(true);
    }

    const [Add_One_Sequence_Session_api, setAdd_One_Sequence_Session_api] = useState();
    const [Add_One_Sequence_Session_message, setAdd_One_Sequence_Session_message] = useState();
    const [Add_One_Sequence_Session_result, setAdd_One_Sequence_Session_result] = useState();
    const Add_One_Sequence_Session = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(sequence_startDate).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(sequence_endDate).format("DD/MM/YYYY HH:mm");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("sequence_title", new_title);
        form.append("sequence_start", new_start_date);
        form.append("sequence_end", new_end_date);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Session_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_api("true");
                setAdd_One_Sequence_Session_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title();
                setp_detail_sequence_comment();
                setsequence_startDate();
                setsequence_endDate();



                alert(res.data.message);
                Close_Dialog_Detail_seq_open();
            }
            else {
                setAdd_One_Sequence_Session_api("false");
                setAdd_One_Sequence_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session = ' + error);
            setAdd_One_Sequence_Session_api("false");
            alert(" Impossible d'ajouter la séquence");
        })
    }



    const [Add_Automatic_Sequence_Session_api, setAdd_Automatic_Sequence_Session_api] = useState();
    const [Add_Automatic_Sequence_Session_message, setAdd_Automatic_Sequence_Session_message] = useState();
    const [Add_Automatic_Sequence_Session_result, setAdd_Automatic_Sequence_Session_result] = useState();
    const Add_Automatic_Sequence_Session = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(sequence_startDate).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(sequence_endDate).format("DD/MM/YYYY HH:mm");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("jounree_modele_id", "");


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Create_Automatic_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Automatic_Sequence_Session  res.data.status = " + res.data.status);
            //console.log(" In Add_Automatic_Sequence_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Automatic_Sequence_Session_api("true");
                setAdd_Automatic_Sequence_Session_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title();
                setp_detail_sequence_comment();
                setsequence_startDate();
                setsequence_endDate();



                alert(res.data.message);
                Close_Dialog_Detail_seq_open();
            }
            else {
                setAdd_Automatic_Sequence_Session_api("false");
                setAdd_Automatic_Sequence_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Automatic_Sequence_Session = ' + error);
            setAdd_Automatic_Sequence_Session_api("false");
            alert(" Impossible de créer automatiquement les séquences");
        })
    }



    const [Update_One_Sequence_Session_api, setUpdate_One_Sequence_Session_api] = useState();
    const [Update_One_Sequence_Session_message, setUpdate_One_Sequence_Session_message] = useState();
    const [Update_One_Sequence_Session_result, setUpdate_One_Sequence_Session_result] = useState();
    const Update_One_Sequence_Session = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(sequence_startDate).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(sequence_endDate).format("DD/MM/YYYY HH:mm");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("sequence_title", new_title);
        form.append("sequence_start", new_start_date);
        form.append("sequence_end", new_end_date);

        form.append("_id", selected_sequence_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Session_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Sequence_Session  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Sequence_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_One_Sequence_Session_api("true");
                setUpdate_One_Sequence_Session_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title();
                setp_detail_sequence_comment();
                setsequence_startDate();
                setsequence_endDate();

                setupdate_sequence();

                alert(res.data.message);
                Close_Dialog_Detail_seq_open();
            }
            else {
                setUpdate_One_Sequence_Session_api("false");
                setUpdate_One_Sequence_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Sequence_Session = ' + error);
            setUpdate_One_Sequence_Session_api("false");
            alert(" Impossible de mettre à jour  la séquence");
        })
    }


    const [Delete_Sequence_Session_Data_api, setDelete_Sequence_Session_Data_api] = useState();
    const [Delete_Sequence_Session_Data_message, setDelete_Sequence_Session_Data_message] = useState();
    const [Delete_Sequence_Session_Data_result, setDelete_Sequence_Session_Data_result] = useState();
    function Delete_Sequence_Session_Data(local_seq_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_seq_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Session_Sequence/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Data_api("true");
                setDelete_Sequence_Session_Data_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setDialog_seq_ressource_1_selected_type_ressource();
                setDialog_seq_ressource_1_selected_ressource_id();
                setDialog_seq_ressource_1_selected_ressource_poste();
                setdisplay_detail_sequence();
                setselected_sequence_id();
                setupdate_sequence();
                setgridline_id('');


                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Data_api("false");
                setDelete_Sequence_Session_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Data_api("false");
            alert(" Impossible de supprimer la sequence");
        })
    }


    const [Delete_Sequence_Session_Data_Mass_api, setDelete_Sequence_Session_Data_Mass_api] = useState();
    const [Delete_Sequence_Session_Data_Mass_message, setDelete_Sequence_Session_Data_Mass_message] = useState();
    const [Delete_Sequence_Session_Data_Mass_result, setDelete_Sequence_Session_Data_Mass_result] = useState();
    function Delete_Sequence_Session_Data_Mass(local_tab_ids) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_id", local_tab_ids);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Session_Sequence_Mass/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Data_Mass  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Data_Mass  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Data_Mass_api("true");
                setDelete_Sequence_Session_Data_Mass_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setDialog_seq_ressource_1_selected_type_ressource();
                setDialog_seq_ressource_1_selected_ressource_id();
                setDialog_seq_ressource_1_selected_ressource_poste();
                setdisplay_detail_sequence();
                setselected_sequence_id();
                setupdate_sequence();

                setAdd_One_Sequence_Session_mass();
                settab_selected_sequence_id([]);


                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Data_Mass_api("false");
                setDelete_Sequence_Session_Data_Mass_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Data_Mass : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Data_Mass_api("false");
            alert(" Impossible de supprimer les sequences en masse");
        })
    }

    const [display_detail_sequence, setdisplay_detail_sequence] = useState();
    const [selected_sequence_id, setselected_sequence_id] = useState();
    const [tab_selected_sequence_id, settab_selected_sequence_id] = useState([]);



    const [Getall_List_Sequence_Ressource_api, setGetall_List_Sequence_Ressource_api] = useState();
    const [Getall_List_Sequence_Ressource_message, setGetall_List_Sequence_Ressource_message] = useState();
    const [Getall_List_Sequence_Ressource_result, setGetall_List_Sequence_Ressource_result] = useState([]);
    function Getall_List_Sequence_Ressource(local_sequence_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("sequence_session_id", local_sequence_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Sequence_Ressource_Affectation/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_List_Sequence_Ressource  res.data.status = " + res.data.status);
                //console.log(" In Getall_List_Sequence_Ressource  res.data.message r_class = " + res.data.message);
                setGetall_List_Sequence_Ressource_api("true");
                setGetall_List_Sequence_Ressource_result(res.data.message);

            }
            else {
                setGetall_List_Sequence_Ressource_api("false");
                setGetall_List_Sequence_Ressource_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_List_Sequence_Ressource = ', error);
            setGetall_List_Sequence_Ressource_api("false");
            alert("Impossible de récuperer la liste des ressources de la sequence")
            //setmyApimyApiMessage("")
        })
    }


    function submenu_add_one_Ressource() {
        setDialog_seq_ressource_1_open(true);
    }



    const [Dialog_seq_ressource_1_message, setDialog_seq_ressource_1_message] = React.useState(false);
    const [Dialog_seq_ressource_1_open, setDialog_seq_ressource_1_open] = React.useState(false);



    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_ressource_1_message(message);
        setDialog_seq_ressource_1_open(true);
    }

    const Dialog_seq_ressource_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_seq_ressource_1_handleClose_buton = () => {
        setDialog_seq_ressource_1_open(false);
    };



    const [Dialog_seq_ressource_1_selected_type_ressource, setDialog_seq_ressource_1_selected_type_ressource] = React.useState();
    const [Dialog_seq_ressource_1_selected_ressource_id, setDialog_seq_ressource_1_selected_ressource_id] = React.useState();
    const [Dialog_seq_ressource_1_selected_ressource_poste, setDialog_seq_ressource_1_selected_ressource_poste] = React.useState();



    const [Add_One_Sequence_Session_mass, setAdd_One_Sequence_Session_mass] = useState();

    const [Add_One_Sequence_Session_Ressource_api, setAdd_One_Sequence_Session_Ressource_api] = useState();
    const [Add_One_Sequence_Session_Ressource_message, setAdd_One_Sequence_Session_Ressource_message] = useState();
    const [Add_One_Sequence_Session_Ressource_result, setAdd_One_Sequence_Session_Ressource_result] = useState();
    const Add_One_Sequence_Session_Ressource = event => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("sequence_session_id", selected_sequence_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        if (String("new_ressource_type") === "ressource_humaine" && String(new_ressource_poste).trim() === "") {
            alert(" Vous devez préciser le poste occupé pour cette ressrouce");
            return;
        }

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Affectation_Ressource_Poste/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session_Ressource  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session_Ressource  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_Ressource_api("true");
                setAdd_One_Sequence_Session_Ressource_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                setDialog_seq_ressource_1_selected_type_ressource();
                setDialog_seq_ressource_1_selected_ressource_id();
                setDialog_seq_ressource_1_selected_ressource_poste();

                alert(res.data.message);
                Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Sequence_Session_Ressource_api("false");
                setAdd_One_Sequence_Session_Ressource_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session_Ressource = ' + error);
            setAdd_One_Sequence_Session_Ressource_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }

    const [Delete_Sequence_Session_Ressource_Data_api, setDelete_Sequence_Session_Ressource_Data_api] = useState();
    const [Delete_Sequence_Session_Ressource_Data_message, setDelete_Sequence_Session_Ressource_Data_message] = useState();
    const [Delete_Sequence_Session_Ressource_Data_result, setDelete_Sequence_Session_Ressource_Data_result] = useState();
    function Delete_Sequence_Session_Ressource_Data(local_seq_ressource_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_seq_ressource_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Sequence_Affectation_Ressource_Poste/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Ressource_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Ressource_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Ressource_Data_api("true");
                setDelete_Sequence_Session_Ressource_Data_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Ressource_Data_api("false");
                setDelete_Sequence_Session_Ressource_Data_message(res.data.message);

                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Ressource_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Ressource_Data_api("false");
            alert(" Impossible de supprimer la sequence");
        })
    }


    const [Add_One_Sequence_Session_Ressource_Mass_api, setAdd_One_Sequence_Session_Ressource_Mass_api] = useState();
    const [Add_One_Sequence_Session_Ressource_Mass_message, setAdd_One_Sequence_Session_Ressource_Mass_message] = useState();
    const [Add_One_Sequence_Session_Ressource_Mass_result, setAdd_One_Sequence_Session_Ressource_Mass_result] = useState();
    const Add_One_Sequence_Session_Ressource_Mass = tab_ids => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_sequence_session_id", tab_selected_sequence_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Affectation_Ressource_Poste_Mass/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session_Ressource_Mass  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session_Ressource_Mass  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_Ressource_Mass_api("true");
                setAdd_One_Sequence_Session_Ressource_Mass_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                setDialog_seq_ressource_1_selected_type_ressource();
                setDialog_seq_ressource_1_selected_ressource_id();
                setDialog_seq_ressource_1_selected_ressource_poste();

                setAdd_One_Sequence_Session_mass();
                settab_selected_sequence_id([]);

                alert(res.data.message);
                Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Sequence_Session_Ressource_Mass_api("false");
                setAdd_One_Sequence_Session_Ressource_Mass_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session_Ressource_Mass = ' + error);
            setAdd_One_Sequence_Session_Ressource_Mass_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }

    const myRef_seq_ressource = useRef(null)
    const myRef_add_session = useRef(null)

    const [update_sequence, setupdate_sequence] = useState();


    /* Recuperation de la liste d'emargement */
    const [selectionModel_emarg, setSelectionModel_emarg] = React.useState([]);
    const [rowss_emarg, setRows_emarg] = useState([]);
    const [GetListePreinscrit_emarg_api, setGetListePreinscrit_emarg_api] = useState();
    const [GetListePreinscrit_emarg_meassage, setGetListePreinscrit_emarg_meassage] = useState();
    const [GetListePreinscrit_emarg_result, setGetListePreinscrit_emarg_result] = useState([])
    function GetListeEmargement(localsessionid, class_internal_url) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", class_internal_url);
        form.append("session_id", localsessionid);

        /*
    
      /!\ : Cette fonction a besoin d'un token actif
      */

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetTableauEmargement/";

        setLoading(true);

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                setLoading(false);
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_emarg_result(data['message']);
                setRows_emarg(data['message']);
                if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else if (String(data['status']) === String("true")) {
                    setGetListePreinscrit_emarg_api("true");

                } else {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_emarg_api("false");

                }

            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setGetListePreinscrit_emarg_api("false");
            });

    }


    const [actionmass_emarg_val, setactionmass_emarg_val] = useState();
    const actionmass_emarg = (event) => {
        const value = event.target.value;


        if (String(value) === "valider" || String(value) === "demande emargement") {
            setactionmass_emarg_val(value);
        }
        else {
            setactionmass_emarg_val();
        }
    }

    function GetSelectedRows_Emarg_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_emarg.length; i++) {
            var myid = parseInt(String(selectionModel_emarg[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_emarge_Traitemet() {
        var liste_formation = GetSelectedRows_Emarg_Ids();
        for (var i = 0; i < liste_formation.length; i++) {
            var line = JSON.parse(rowss_emarg[liste_formation[i]]);
            //console.log(" line = ", line);
            var email = line.email;
            //console.log(" Traitement de email = ", email);

            if (String(actionmass_emarg_val) === "demande emargement") {
                ActionMassListEmargement();
            }
            await sleep(50)
        }
        setSelectionModel_emarg([]);
        setactionmass_emarg_val();

        GetListeEmargement(selected_session_id, selected_internal_url);

    }

    const [ActionMassListEmargement_api, setActionMassListEmargement_api] = useState();
    const [ActionMassListEmargement_message, setActionMassListEmargement_message] = useState();
    const [ActionMassListEmargement_result, setActionMassListEmargement_result] = useState();
    function ActionMassListEmargement(e) {

        clean_old_messages();
        if (selectionModel_emarg.length <= 0) {
            alert(" Selectionnez un enregistrement pour envoyer la demande d'emargement");
            return;
        }

        var actionenmasse = ""
        if (document.getElementById("actionmass_emarge")) {
            actionenmasse = document.getElementById("actionmass_emarge").value;
        }

        var liste_emargement_id = GetSelectedRowsEmargement();

        //console.log("liste_formation = " + liste_emargement_id);

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_ids", liste_emargement_id);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/EmargementMail/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

                setActionMassListEmargement_api("true");
                setActionMassListEmargement_result(res.data.message);
            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setActionMassListEmargement_api("false");
                setActionMassListEmargement_message(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('ActionMassListEmargement : Not good man :( mysearchtext = ' + error);
            setActionMassListEmargement_api("false");

        })



        if (String(actionenmasse) === String("depublier")) {

            alert("depublier");
        }

        else if (String(actionenmasse) === String("publier")) {
            alert("publier");
        }

        else if (String(actionenmasse) === String("supprimer")) {
            alert("supprimer");
        }
    }

    function ConfirmInitListEmargement() {
        confirmAlert({
            message: "Confirmez la (re)initialisation de la liste d'emargement ? Toutes les confirmations seront perdues",

            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { CreateListEmargement() }
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }

    const [CreateListEmargement_api, setCreateListEmargement_api] = useState();
    const [CreateListEmargement_message, setCreateListEmargement_message] = useState();
    const [CreateListEmargement_result, setCreateListEmargement_result] = useState();
    function CreateListEmargement() {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", selected_session_id);

        //alert(" mysession = "+mysession);

        //var myurl = process.env.REACT_APP_API_URL + "myclass/api/CreateTableauEmargement/";

        // Update du 23/11/2023 : Les emargements sont basé sur les séquence déjà créées
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CreateTableauEmargement_From_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreateListEmargement_api("true");
                setCreateListEmargement_result(res.data.message);
                GetListeEmargement(selected_session_id, selected_internal_url);
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreateListEmargement_api("false");
                setCreateListEmargement_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('CreateListEmargement : Not good man :( mysearchtext = ' + error);
            setCreateListEmargement_api("false");
            alert(" Impossible d'initialiser la liste d'émargement");

        })
    }

    const [selectedemargement, setselectedemargement] = useState([])
    function GetSelectedRowsEmargement() {

        var tab_tmp = []


        for (var i = 0; i < selectionModel_emarg.length; i++) {
            var myid = parseInt(String(selectionModel_emarg[i]));
            tab_tmp.push(JSON.parse(rowss_emarg[myid])._id);

        }
        setselectedemargement(tab_tmp);
        return tab_tmp;
    }

    function DownloadListEmargement(event) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_liste_emarge = "Liste_Emargement.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/GerneratePDFEmargementList/" + stored_cookie + "/" + selected_session_id + "/" + selected_internal_url;
        setLoading(true);


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_liste_emarge)
            }).catch((error) => {
                setLoading(false);
                alert(" Impossible de recuperer la liste d'émargement");
                console.error('Error:', error);

            });
    }

    function ConfirmEnvoiListEmargement() {
        confirmAlert({
            message: "Confirmez l'envoi des demandes d'emargement aux participants",

            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { ActionMassListEmargement() }
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }

    const [UpdateStagiaireEmargement_api, setUpdateStagiaireEmargement_api] = useState();
    const [UpdateStagiaireEmargement_message, setUpdateStagiaireEmargement_message] = useState();
    const [UpdateStagiaireEmargement_result, setUpdateStagiaireEmargement_result] = useState();
    function handleClick_valide_emargement(event, cellValues) {

        var date = cellValues.row.date;
        var email = cellValues.row.email;
        var matin = cellValues.row.matin;
        var matin_val = "";
        var is_present_val = "";


        console.log(" ### cellValues.row = ", cellValues.row);
        var is_present = cellValues.row.is_present;

        if (is_present === true) {
            is_present_val = "1"
        } else if (matin === false) {
            is_present_val = "0"
        }


        /*console.log(" ### date , email, matin,matin_val,  apresmidi, apresmidi_val = ",
            date, email, matin, matin_val, apresmidi, apresmidi_val);*/

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", cellValues.row._id);
        form.append("session_id", selected_session_id);
        form.append("email", email);
        form.append("date", date);
        form.append("is_present", is_present_val);

        console.log(" ## form =  ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateUserEmargementDate/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setUpdateStagiaireEmargement_api("true");
                setUpdateStagiaireEmargement_result(res.data.message);
                GetListeEmargement(selected_session_id, selected_internal_url);
                alert(" La mise à jour a été bien faite");
            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setUpdateStagiaireEmargement_api("false");
                setUpdateStagiaireEmargement_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('handleClick_valide_emargement : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireEmargement_api("false");
            alert(" Impossible de valider l'émargement");

        })

    }


    function submenu_add_one_tuteur() {
        setDialog_tuteur_1_open(true);
    }


    const [Dialog_tuteur_1_message, setDialog_tuteur_1_message] = React.useState(false);
    const [Dialog_tuteur_1_open, setDialog_tuteur_1_open] = React.useState(false);

    function Dialog_1_handle_change_tuteur(message) {
        setDialog_tuteur_1_message(message);
        setDialog_tuteur_1_open(true);
    }

    const Dialog_tuteur_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_tuteur_1_handleClose_buton = () => {
        setDialog_tuteur_1_open(false);
    };

    // -- début convention
    // -- convention 

    const [sessions_file_change_1_convention_api, setsessions_file_change_1_convention_api] = useState();
    const [sessions_file_change_1_convention_result, setsessions_file_change_1_convention_result] = useState();
    const [sessions_file_change_1_convention_message, setsessions_file_change_1_convention_message] = useState();
    const sessions_file_change_1_convention = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        if (file_type !== "application/pdf") {
            alert("Le fichier n'est pas de type PDF");
            return;
        }



        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


        var new_tmp = [];

        if (tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.length > 0) {

            tab_convention_pieces_jointes_result.map((x) => {
                new_tmp.push(x);

            });

        }

        new_tmp.push(event.target.files[0])

        settab_convention_pieces_jointes_result(new_tmp);

        console.log(" tab_convention_pieces_jointes_result = ", tab_convention_pieces_jointes_result)


        return;


    };

    const [tab_convention_pieces_jointes_result, settab_convention_pieces_jointes_result] = useState([]);

    const [Dialog_convention_message, setDialog_convention_message] = React.useState(false);
    const [Dialog_convention_open, setDialog_convention_open] = React.useState(false);
    function Dialog_convention_handle_change_participant_session(message) {
        setDialog_convention_message(message);
        setDialog_convention_open(true);
    }

    const Dialog_convention_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_convention_handleClose_buton = () => {

        setDialog_convention_open(false);
        setselected_convention_id();
        setselected_convention_type();
        setselected_convention_email_destinataire_prod();
        setselected_convention_email_test();

        setactionmass_ftion_val();
        setSelectionModel([]);
        settab_convention_pieces_jointes_result([])
    };


    const [selected_convention_id, setselected_convention_id] = useState();
    const [selected_convention_type, setselected_convention_type] = useState("");
    const [selected_convention_email_destinataire_prod, setselected_convention_email_destinataire_prod] = useState("");
    const [selected_convention_email_test, setselected_convention_email_test] = useState("");


    const [New_Get_List_Stagiaire_Conventions_result, setNew_Get_List_Stagiaire_Conventions_result] = useState([]);

    const [Get_List_Stagiaire_Conventions_api, setGet_List_Stagiaire_Conventions_api] = useState();
    const [Get_List_Stagiaire_Conventions_message, setGet_List_Stagiaire_Conventions_message] = useState();
    const [Get_List_Stagiaire_Conventions_result, setGet_List_Stagiaire_Conventions_result] = useState();
    function Get_List_Stagiaire_Conventions(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        //form.append("inscription_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Conventions_Stagiaire/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {

                //console.log(" In Get_List_Stagiaire_Conventions  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Stagiaire_Conventions  res.data.message r_class = " + res.data.message);
                setGet_List_Stagiaire_Conventions_api("true");
                setGet_List_Stagiaire_Conventions_result(res.data.message);

                var new_data2 = [];
                var node = {
                    "_id": "default_pdf",
                    "id": "default_pdf",
                    "label": "PDF par default",
                    "ref_interne": "",
                    "nom": "",
                    "sujet": "",
                    "type_doc": "",
                    "cible": "",
                    "joint_pdf": "",

                };
                new_data2.push(node);

                var node2 = {
                    "_id": "default_mail",
                    "id": "default_mail",
                    "label": "Email par default",
                    "ref_interne": "",
                    "nom": "",
                    "sujet": "",
                    "type_doc": "",
                    "cible": "",
                    "joint_pdf": "",

                };
                new_data2.push(node2);
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Stagiaire_Conventions_result(new_data2);

            }
            else {
                setGet_List_Stagiaire_Conventions_api("false");
                setGet_List_Stagiaire_Conventions_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Stagiaire_Conventions = ', error);
            setGet_List_Stagiaire_Conventions_api("false");
            alert("Impossible de récupérer les modèles de convention");
            //setmyApimyApiMessage("")
        })
    }


    const [Send_Stagiaire_Conventions_By_Email_api, setSend_Stagiaire_Conventions_By_Email_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_message, setSend_Stagiaire_Conventions_By_Email_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_result, setSend_Stagiaire_Conventions_By_Email_result] = useState();
    function Send_Stagiaire_Conventions_By_Email(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", selected_convention_email_destinataire_prod);

        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convention_From_Session_By_Email/";
        if (String(selected_convention_id) === "default_mail") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convention_From_Session_By_Email/";
        }


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Send_Stagiaire_Conventions_By_Email  res.data.status = " + res.data.status);
                //console.log(" In Send_Stagiaire_Conventions_By_Email  res.data.message r_class = " + res.data.message);
                setSend_Stagiaire_Conventions_By_Email_api("true");
                setSend_Stagiaire_Conventions_By_Email_result(res.data.message);

                alert(res.data.message);
                Dialog_convention_handleClose_buton()
            }
            else {
                setSend_Stagiaire_Conventions_By_Email_api("false");
                setSend_Stagiaire_Conventions_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email = ', error);
            setSend_Stagiaire_Conventions_By_Email_api("false");
            alert("Impossible d'envoyer la convention par email");
            //setmyApimyApiMessage("")
        })
    }


    function Download_Session_Conventions_PDF() {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Convention.zip";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convention_From_Session_By_PDF/" + stored_cookie + "/" + selected_session_id + "/" + selected_convention_id;



        if (String(selected_convention_id) === "default_pdf") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convention_From_Session_By_PDF/" + stored_cookie + "/" + selected_session_id + "/" + selected_convention_id;

        }


        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_session_id = ", selected_session_id, " --- selected_convention_id =", selected_convention_id);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Download_Stagiaire_Conventions_PDF  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible de télécharger la convention (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de télécharger la convention ")

            });

    }

    const [Send_Stagiaire_Conventions_By_Email_Test_api, setSend_Stagiaire_Conventions_By_Email_Test_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Test_message, setSend_Stagiaire_Conventions_By_Email_Test_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Test_result, setSend_Stagiaire_Conventions_By_Email_Test_result] = useState();
    function Send_Stagiaire_Conventions_By_Email_Test(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", "");

        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convention_From_Session_By_Email/";

        if (String(selected_convention_id) === "default_mail") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convention_From_Session_By_Email/";
        }


        setLoading(true);
        fetch(
            myurl,
            {
                method: 'POST',
                body: form,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                setLoading(false);
                console.log(" In Send_Stagiaire_Conventions_By_Email_Test  res.data.status = " + result['status']);
                console.log(" In Send_Stagiaire_Conventions_By_Email_Test  res.data.message r_class = " + result['message']);
                if (String(result['status']) === String("true")) {

                    setSend_Stagiaire_Conventions_By_Email_Test_api("true");
                    setSend_Stagiaire_Conventions_By_Email_Test_result(result['message']);

                    alert(result['message']);
                    Dialog_convention_handleClose_buton()
                }
                else {
                    setSend_Stagiaire_Conventions_By_Email_Test_api("false");
                    setSend_Stagiaire_Conventions_By_Email_Test_message(result['message']);
                    alert(result['message']);
                }

            }).catch((error) => {
                setLoading(false);
                console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email_Test = ', error);
                setSend_Stagiaire_Conventions_By_Email_Test_api("false");
                alert("Impossible d'envoyer l'email de test ");
                //setmyApimyApiMessage("")
            })
    }




    const [Get_Stagiaire_List_Email_Com_api, setGet_Stagiaire_List_Email_Com_api] = useState();
    const [Get_Stagiaire_List_Email_Com_message, setGet_Stagiaire_List_Email_Com_message] = useState();
    const [Get_Stagiaire_List_Email_Com_result, setGet_Stagiaire_List_Email_Com_result] = useState();
    function Get_Stagiaire_List_Email_Com(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Stagiaire_Communication_List_Email/";

        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Stagiaire_List_Email_Com  res.data.status = " + res.data.status);
                //console.log(" In Get_Stagiaire_List_Email_Com  res.data.message r_class = " + res.data.message);
                setGet_Stagiaire_List_Email_Com_api("true");
                setGet_Stagiaire_List_Email_Com_result(res.data.message);

                var tab_email_prod = []
                res.data.message.map((x) => {
                    tab_email_prod.push(String(JSON.parse(x).email));
                }
                );

                var liste_email_prod = tab_email_prod.join()
                setselected_convention_email_destinataire_prod(liste_email_prod);

            }
            else {
                setGet_Stagiaire_List_Email_Com_api("false");
                setGet_Stagiaire_List_Email_Com_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Stagiaire_List_Email_Com = ', error);
            setGet_Stagiaire_List_Email_Com_api("false");
            alert("Impossible de récupérer les adresses email de communication");
            //setmyApimyApiMessage("")
        })
    }




    const [Get_Convention_List_Of_All_PJ_result, setGet_Convention_List_Of_All_PJ_result] = useState([]);

    function Delete_Convention_Attached_Doc(event) {

        var doc_to_del_name = event.target.id;
        const myArray = tab_convention_pieces_jointes_result;



        let new_myArray = myArray.filter(file => file.name !== String(doc_to_del_name));

        console.log(" new_myArray = ", new_myArray);


        var new_tab = []
        for (let i = 0; i < new_myArray.length; i++) {
            new_tab.push(new_myArray[i]);
        }

        settab_convention_pieces_jointes_result(new_tab);


    }


    // -- end convention

    const New_Option_Type_Ressource = [
        { "id": "0", "label": "Employe", "value": "ressource_humaine" },
        { "id": "1", "label": "Materiel", "value": "ressource_materielle" },
    ]


    return (
        <div className="displaypartnersession">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>


            <Dialog
                open={Dialog_2_open}
                onClose={Dialog_2_handleClose}
                className="displaypartnersession"
            >


                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_2_message}
                    </DialogContentText>
                    <div className="div_row" > <hr className="mysy_hr_text" />
                    </div>
                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Etape
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <FcAlarmClock /> {JSON.parse(rowss[session_selected_row_id]).session_etape}
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Code session
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).code_session}
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Durée
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).duration_concat}
                        </div>}
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Modalité

                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {String(JSON.parse(rowss[session_selected_row_id]).distantiel) === "1" &&
                                "A distance  "
                            }

                            {String(JSON.parse(rowss[session_selected_row_id]).presentiel) === "1" &&
                                "En presentiel  "
                            }

                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Date début
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).date_debut}
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Date fin
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).date_fin}
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Prix
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).prix_session}
                        </div>}
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Nb participants
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).nb_participant}
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Taux Remplissage
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {JSON.parse(rowss[session_selected_row_id]).taux_remplissage}
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Ftion Publiée
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={JSON.parse(rowss[session_selected_row_id]).class_published} />
                        </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Exclu BPF
                        </div>
                        {rowss && String(session_selected_row_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={JSON.parse(rowss[session_selected_row_id]).is_bpf} />
                        </div>}
                    </div>





                </DialogContent>

                <div className="div_row" > &nbsp;

                </div>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Show_Detailled_Session_Data} className="bton_enreg_dialog">Voir détail</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_2_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            <Dialog
                open={Dialog_seq_1_open}
                onClose={Dialog_seq_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Ajout Sequence</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_seq_1_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" > Titre
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={p_detail_sequence_title}
                            onChange={(e) => {
                                setp_detail_sequence_title(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > Debut
                        <DatePicker
                            name="event_dialog_start"
                            id="event_dialog_start"
                            selected={sequence_startDate}
                            onChange={(date) => {

                                return setsequence_startDate(date);
                            }
                            }

                            className="disabled_style session_caract_Dialog_DatePicker"
                            style={{ width: "99%" }}
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>


                    <div className="session_caract_Dialog" > Fin
                        <DatePicker
                            name="event_dialog_end"
                            id="event_dialog_end"
                            selected={sequence_endDate}

                            onChange={(date) => {

                                return setsequence_endDate(date);
                            }
                            }


                            className="disabled_style session_caract_Dialog_DatePicker"
                            style={{ width: "99%" }}
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_comment"
                            id="event_dialog_comment"

                            fullWidth

                            value={p_detail_sequence_comment}
                            onChange={(e) => {

                                setp_detail_sequence_comment(e.target.value);
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(update_sequence) !== "1" && <Button onClick={Add_One_Sequence_Session} className="bton_enreg_dialog">Ajouter</Button>}
                            {String(update_sequence) === "1" && <Button onClick={Update_One_Sequence_Session} className="bton_enreg_dialog">Mettre à jour</Button>}
                        </div>




                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_seq_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>


            <Dialog
                open={Dialog_seq_ressource_1_open}
                onClose={Dialog_seq_ressource_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Ajout ressource </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_seq_ressource_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Type de ressource
                        <Autocomplete
                            disablePortal
                            name="event_dialog_type_ressource"
                            id="event_dialog_type_ressource"
                            fullWidth

                            options={New_Option_Type_Ressource}
                            onChange={(event, value) => {
                                if (value && value.value) {

                                    setDialog_seq_ressource_1_selected_type_ressource(value.value);
                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />
                        {/*<TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_type_ressource"
                            id="event_dialog_type_ressource"

                            select
                            fullWidth

                            value={Dialog_seq_ressource_1_selected_type_ressource}
                            onChange={(e) => {
                                setDialog_seq_ressource_1_selected_type_ressource(e.target.value);
                            }
                            }

                        >

                            <MenuItem value="ressource_humaine" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Employé &nbsp;</MenuItem>
                            <MenuItem value="ressource_materielle" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Materiel &nbsp;</MenuItem>
                        </TextField>*/}

                    </div>



                    {String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_humaine" && <div className="session_caract_Dialog" > Ressource Humaine

                        <Autocomplete
                            disablePortal
                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"
                            fullWidth
                            options={New_Getall_Training_Employee_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setDialog_seq_ressource_1_selected_ressource_id(value._id);

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />

                        {/* <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"

                            select
                            fullWidth

                            value={Dialog_seq_ressource_1_selected_ressource_id}
                            onChange={(e) => {

                                setDialog_seq_ressource_1_selected_ressource_id(e.target.value);
                            }
                            }

                        >
                            {Getall_Training_Employee_No_Filter_result &&
                                Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id} nom={JSON.parse(myclient).nom}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} id="filtre1_value_list">
                                        {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                    </MenuItem>
                                ))}
                        </TextField>*/}

                    </div>
                    }


                    {String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_materielle" && <div className="session_caract_Dialog" > Ressource Materiel

                        <Autocomplete
                            disablePortal
                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"
                            fullWidth
                            options={New_Getall_Training_Materiel_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setDialog_seq_ressource_1_selected_ressource_id(value._id);


                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />
                        {/*<TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"

                            select
                            fullWidth

                            value={Dialog_seq_ressource_1_selected_ressource_id}
                            onChange={(e) => {

                                setDialog_seq_ressource_1_selected_ressource_id(e.target.value);
                            }
                            }

                        >
                            {Getall_Training_Materiel_No_Filter_result &&
                                Getall_Training_Materiel_No_Filter_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                        style={{ "paddingLeft": "25px", "textAlign": "left", "width": "100%" }}>
                                        {JSON.parse(myclient).nom}&nbsp;

                                    </MenuItem>
                                ))}
                        </TextField>*/}

                    </div>
                    }


                    <div className="session_caract_Dialog" > Poste
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={Dialog_seq_ressource_1_selected_ressource_poste}
                            onChange={(e) => {

                                setDialog_seq_ressource_1_selected_ressource_poste(e.target.value);
                            }
                            }

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(Add_One_Sequence_Session_mass) !== "1" && <Button onClick={Add_One_Sequence_Session_Ressource} className="bton_enreg_dialog">Ajouter</Button>}

                            {String(Add_One_Sequence_Session_mass) === "1" && <Button onClick={Add_One_Sequence_Session_Ressource_Mass} className="bton_enreg_dialog">Ajouter en masse</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_seq_ressource_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>


            <Dialog
                open={Dialog_tuteur_1_open}
                onClose={Dialog_tuteur_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Ajout ressource </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_tuteur_1_message}
                    </DialogContentText>

                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(Add_One_Sequence_Session_mass) !== "1" && <Button onClick={Add_One_Sequence_Session_Ressource} className="bton_enreg_dialog">Ajouter</Button>}

                            {String(Add_One_Sequence_Session_mass) === "1" && <Button onClick={Add_One_Sequence_Session_Ressource_Mass} className="bton_enreg_dialog">Ajouter en masse</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_tuteur_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            <Dialog
                open={Dialog_convention_open}
                onClose={Dialog_convention_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>


                    <div className="session_caract_Dialog" > Choisir une convention
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_convention"
                            id="event_dialog_version_convention"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Get_List_Stagiaire_Conventions_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_convention_id(value._id);

                                    if (String(value._id) === "default_mail") {
                                        setselected_convention_type("email");
                                    }
                                    else if (String(value._id) === "default_pdf") {
                                        setselected_convention_type("pdf");
                                    } else {
                                        var result2 = Get_List_Stagiaire_Conventions_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id === String(value._id))
                                        setselected_convention_type(JSON.parse(result2).type_doc);
                                    }
                                    settab_convention_pieces_jointes_result([]);
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />

                    </div>


                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" > Type Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '75%' }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"

                            select


                            value={selected_convention_type}
                            onChange={(e) => {
                                setselected_convention_type(e.target.value);
                            }

                            }
                        >

                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >PDF &nbsp;</MenuItem>
                        </TextField>
                        {String(selected_convention_type) === "email" && <label className="bton_image_class" style={{ "width": "20%", "float": "right", "height": "3.5rem", "paddingTop": "15px" }} onClick={(e) => {

                            if (selected_convention_email_test) {
                                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (!selected_convention_email_test.match(validRegex)) {
                                    alert("L'adresse email de test est invalide.");
                                    return;
                                }
                                Send_Stagiaire_Conventions_By_Email_Test();
                            } else {
                                alert("L'adresse email de test est invalide.");
                            }



                        }} > <FcSearch /> &nbsp; Test</label>}

                    </div>}

                    {String(selected_convention_type) === "pdf" && <div className="session_caract_Dialog" > Type Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"

                            select
                            fullWidth

                            value={selected_convention_type}
                            onChange={(e) => {
                                setselected_convention_type(e.target.value);
                            }
                            }
                        >

                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >PDF &nbsp;</MenuItem>
                        </TextField>

                    </div>}


                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" >
                        <Tooltip className="tooltip_css" id="test01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="test01" data-tooltip-html="Pour recevoir une email de test avant de l'envoyer">
                            Adresse email de test <FcInfo /> <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_test_convention"
                                id="event_dialog_email_test_convention"

                                fullWidth

                                value={selected_convention_email_test}
                                onChange={(e) => {
                                    setselected_convention_email_test(e.target.value);
                                }
                                }
                            />
                        </a>

                    </div>
                    }

                    {String(selected_convention_type) === "pdf" && <div className="session_caract_Dialog" > Recevoir un pré-envoie
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_email_test_convention"
                            id="event_dialog_email_test_convention"
                            fullWidth
                            value=""

                        />

                    </div>
                    }

                    {String(selected_convention_type) === "email" &&
                        String(actionmass_ftion_val) !== String("convention") && <div className="session_caract_Dialog" > Email destinaitaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_production_convention"
                                id="event_dialog_email_production_convention"

                                fullWidth

                                value={selected_convention_email_destinataire_prod}
                                onChange={(e) => {
                                    setselected_convention_email_destinataire_prod(e.target.value);
                                }
                                }
                            />

                        </div>
                    }

                    {String(selected_convention_type) === "pdf" &&
                        String(actionmass_ftion_val) !== String("convention") && <div className="session_caract_Dialog" > Email destinaitaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_production_convention"
                                id="event_dialog_email_production_convention"

                                fullWidth

                                value=""

                            />

                        </div>
                    }

                    {String(selected_convention_type) === "email" && <div>

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes Convention

                            </div>

                            <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload_convention_file">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload_convention_file"
                                            name="upload_convention_file"
                                            type="file"
                                            onChange={sessions_file_change_1_convention}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>


                            </div>


                            {tab_convention_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    {<div className="div_row">
                                        {tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj_convention"  >
                                                <nav style={{ "color": "green", "cursor": "pointer" }}
                                                    onClick={(e) => {
                                                        Delete_Convention_Attached_Doc(e);
                                                    }}
                                                    name={val.name} id={val.name}> Supprimer  {val.name} </nav> <br />


                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>}



                        </div>
                    </div>}

                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            {String(selected_convention_type) === "email" && <Button onClick={(e) => {
                                if (String(selected_convention_email_test).length > 2) {
                                    const response = window.confirm(" Vous allez envoyer la convention au destinaitaire final. Continuer ?");
                                    if (!response) {
                                        return;
                                    }
                                    Send_Stagiaire_Conventions_By_Email();
                                    Dialog_convention_handleClose_buton();
                                }
                            }}
                                className="bton_enreg_dialog">Envoyer mail </Button>}
                            {String(selected_convention_type) === "pdf" &&
                                <Button className="bton_enreg_dialog" onClick={(e) => {
                                    Download_Session_Conventions_PDF();
                                    Dialog_convention_handleClose_buton();
                                }}
                                >Télécharger </Button>}
                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_convention_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            <h3> Vos sessions de formation </h3>

            <div>
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value)
                            }}
                        >

                            <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="class_external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Code Formation (code externe) &nbsp;</MenuItem>
                            <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                            <MenuItem value="session_start_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date début session  &nbsp;</MenuItem>
                            <MenuItem value="session_end_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date fin session  &nbsp;</MenuItem>

                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                options={filters}
                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value)
                                }}
                            >

                                <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="class_external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Code Formation (code externe) &nbsp;</MenuItem>
                                <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                                <MenuItem value="session_start_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date début session  &nbsp;</MenuItem>
                                <MenuItem value="session_end_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date fin session  &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter" >
                            {String(p_filtre2).length > 3 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>


                        {String(p_filtre2).length > 3 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}
                    </div>
                }


                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_TrainingSession}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>}

            </div>

            <br />
            <div style={{ "textAlign": "left", "float": "left", "width": "100%", "paddingRight": "10px" }} id="participant_menu_tab">


                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',
                                    '& .cell--presentiel': {
                                        backgroundColor: '#a2cf6e',
                                        color: '#1a3e72',
                                        fontWeight: 'bold',
                                    },
                                    '& .cell--distantiel': {
                                        backgroundColor: '#ffac33',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },


                                    '& .cell--etape--projet': {
                                        backgroundColor: '#E6F7C8',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--etape--planification': {
                                        backgroundColor: '#E6CEAA',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--etape--planifie': {
                                        backgroundColor: '#F4ECF7',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--etape--termine': {
                                        backgroundColor: '#D6EAF8',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--etape--facture': {
                                        backgroundColor: '#D5F5E3',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--etape--annule': {
                                        backgroundColor: '#566573',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },


                                    '& .line--etape--projet': {
                                        backgroundColor: 'yellow',
                                        color: 'black',
                                    },
                                    '& .line--etape--planification': {
                                        backgroundColor: 'red',
                                        color: 'black',
                                    },
                                    '& .line--etape--planifie': {
                                        backgroundColor: 'blue',
                                        color: 'black',
                                    },
                                    '& .line--etape--termine': {
                                        backgroundColor: 'orange',
                                        color: 'black',
                                    },
                                    '& .line--etape--facture': {
                                        backgroundColor: '#D5F5E3',
                                        color: 'black',
                                    },
                                    '& .line--etape--annule': {
                                        backgroundColor: '#566573',
                                        color: 'white',
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_session_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            setaddOneParticipant();
                                            setdisplay_detail_insc();
                                            setselected_code_session();
                                            setsubmenu();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            class_internal_url: JSON.parse(item).class_internal_url,
                                            code_session: JSON.parse(item).code_session,
                                            _id: JSON.parse(item)._id,
                                            date_debut: JSON.parse(item).date_debut,
                                            date_fin: JSON.parse(item).date_fin,
                                            distantiel: JSON.parse(item).distantiel,
                                            presentiel: JSON.parse(item).presentiel,
                                            statut: JSON.parse(item).session_status,
                                            etape: JSON.parse(item).session_etape,
                                            formateur: JSON.parse(item).formateur,
                                            nb_participant: JSON.parse(item).nb_participant,
                                            title: JSON.parse(item).titre,
                                            domaine: JSON.parse(item).domaine,
                                            taux_remplissage: JSON.parse(item).taux_remplissage,
                                            nb_inscrit: JSON.parse(item).nb_inscrit,
                                            nb_preinscrit: JSON.parse(item).nb_preinscrit,
                                            duration: JSON.parse(item).duration_concat,
                                            class_external_code: JSON.parse(item).class_external_code,
                                            prix_session: JSON.parse(item).prix_session,
                                            published: JSON.parse(item).class_published,
                                            session_title: JSON.parse(item).titre,
                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        setgridline_id(newSelectionModel.row.id);
                                        setaddOneSession("0");
                                        setEdite_session("0");
                                        setsessionChanged(false);
                                        //handleClick_edit_session_From_Line(newSelectionModel.row.id);

                                        setselected_session_id(newSelectionModel.row._id);

                                        setsession_selected_row_id(newSelectionModel.row.id);

                                        setDialog_2_message(String(JSON.parse(rowss[newSelectionModel.row.id]).title));
                                        setDialog_2_open(true);

                                    }}



                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "etape"
                                        if (params.field === "etape" && String(params.value) == "0") {
                                            return 'cell--etape--projet';
                                        }

                                        if (params.field === "etape" && String(params.value) == "1") {
                                            return 'cell--etape--planification';
                                        }

                                        if (params.field === "etape" && String(params.value) == "2") {
                                            return 'cell--etape--planifie';
                                        }

                                        if (params.field === "etape" && String(params.value) == "3") {
                                            return 'cell--etape--termine';
                                        }

                                        if (params.field === "etape" && String(params.value) == "4") {
                                            return 'cell--etape--facture';
                                        }

                                        if (params.field === "etape" && String(params.value) == "-1") {
                                            return 'cell--etape--annule';
                                        }

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}

                                    getRowClassName={(params) => {

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {

                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                        <div className="div_row">

                            {selectionModel && selectionModel.length >= 1 && <div className="block_en_mass">
                                <nav >Traitement en masse </nav>
                                &nbsp;
                                <TextField

                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="block_en_mass_select"

                                    value={actionmass_ftion_val}
                                    onChange={actionmass_ftion}
                                >
                                    <MenuItem value="n/a" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Action &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="supprimer" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Supprimer &nbsp;</MenuItem>
                                    <MenuItem value="dupliquer" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Dupliquer &nbsp;</MenuItem>
                                </TextField>


                                {actionmass_ftion_val && <nav className='block_en_mass_bton_action'>
                                    <Popup
                                        trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                            <FcAcceptDatabase /> Traiter

                                        </Button>}
                                        modal
                                        nested
                                        position="center center"

                                    >

                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information </div>
                                                <div className="gest_content">
                                                    {' '}

                                                    <font color="red">  Confirmer l'action <b> {actionmass_ftion_val} </b> en masse pour {selectionModel.length} lignes.
                                                    </font>
                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            actionmass_ftion_Traitemet();
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </nav>
                                }

                            </div>}
                        </div>
                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>


                        <Button variant="outlined" onClick={submenu_import_session} className="detail_class_submenu bton_import_excel"
                            id='menu_import_participant' name='menu_import_participant'>Importer des Sessions Excel &nbsp;
                            <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                        </Button>


                        <Button variant="outlined" onClick={submenu_add_one_session}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Session &nbsp;
                            <img src={add_plus} alt="ajout session" className="icon_plus" />
                        </Button>

                        <br />
                        {session_file_name && <nav><i>{session_file_name}</i></nav>}
                        {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les sessions ont été correctement importées</nav>}
                        {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                        <input type="file"
                            accept=".csv"
                            ref={hiddenFileInput_session}
                            style={{ display: 'none' }}
                            name="liste_sessions_file"
                            onChange={liste_sessions_file_change}
                        />

                        <a href='/sample/template_import_session_formation.csv' download>Télécharger un fichier modèle</a>
                    </div>
                </div>
                <div className="div_row" ref={myRef_add_session} id="myRef_add_session"> &nbsp; </div>

                {selected_code_session && String(selected_code_session).length > 0 && <div className="div_row">
                    <Button variant="outlined" ref={myRef} onClick={submenu_detail_session} className="detail_class_submenu" id='detail_session' name='detail_session'>Détail Session</Button>
                    <Button variant="outlined" ref={myRef} onClick={submenu_sequence_session} className="detail_class_submenu" id='sequence_session' name='sequence_session'>Séquences ({String(nb_sequence)})</Button>
                    <Button variant="outlined" onClick={submenu_preinscrit} className="detail_class_submenu" id='preinscrit' name='preinscrit'>Preinscription ({String(nb_stagiaire_Preinscrit)}) </Button>
                    <Button variant="outlined" onClick={submenu_inscrit} className="detail_class_submenu" id='inscrit' name='inscrit'>Inscription ({String(nb_stagiaire_Inscrit)}) </Button>
                    <Button variant="outlined" onClick={submenu_editique} className="detail_class_submenu" id='editique' name='editique'>Editique  </Button>
                    <Button variant="outlined" onClick={submenu_emargement} className="detail_class_submenu" id='emargement' name='emargement'>Emargement  </Button>
                    <Button variant="outlined" onClick={submenu_historique} className="detail_class_submenu" id='historique' name='historique'>Historique  </Button>
                </div>}


                <div>

                    {String(submenu) === String("detail_session") && <div style={{ "border": "None" }}>



                        {String(addOneSession) !== "1" && <div className="session_data" onChange={IssessionChanged}>


                            <div className="div_row" style={{ "marginTop": "1rem", "marginBottom": "1rem" }}>

                                <div className="div_row_gauche">
                                    &nbsp;
                                </div>

                                <div className="div_row_droite">
                                    <div style={{ "marginLeft": "2rem", "marginRight": "2rem", "marginBottom": "1rem" }}>

                                        <label className="bton_image_class" onClick={(e) => {
                                            Get_List_Stagiaire_Conventions();
                                            setselected_convention_id();
                                            setselected_convention_type();
                                            setselected_convention_email_destinataire_prod();

                                            setselected_convention_email_test();
                                            ///Get_Stagiaire_List_Email_Com();
                                            setDialog_convention_open(true);
                                        }} ><HiDocumentCheck /> <BsSend />  Editer une convention</label> <br />
                                    </div>


                                </div>

                            </div>

                            <div className="session_caract"> <b> Formation </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required

                                    name="myclass_title"
                                    id="myclass_title"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={true}
                                    className="disabled_style enable_style disabled_style_left_text_with_visualiser_icone"
                                    value={p_myclass_title}

                                />

                                <Tooltip className="tooltip_css" id="detail_01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="detail_01" data-tooltip-html="Voir les détails">
                                    <div className="disabled_style disabled_style_right_bton_visualiser" onClick={(event) => {

                                        if (selected_internal_url && String(selected_internal_url).length > 2) {
                                            window.open(
                                                process.env.REACT_APP_FRONT_URL + "Partner/affichage/" + String(selected_class_external_code) + "/" + String(selected_internal_url),
                                                '_blank'
                                            );
                                        }
                                    }
                                    }
                                    >
                                        <div className="disabled_style_with_visualiser_icone" >
                                            <FcOpenedFolder />

                                        </div>
                                    </div>
                                </a>


                            </div>
                            <div className="session_caract"><b> Code Session </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="code_session"
                                    id="code_session"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_code_session}
                                    onChange={(e) => setp_code_session(e.target.value)}

                                />
                            </div>

                            <div className="session_caract"> Titre Session<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="titre_session"
                                    id="titre_session"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_titre}
                                    onChange={(e) => setp_detail_titre(e.target.value)}

                                />
                            </div>

                            {edit_session_form && <div className="session_caract"> Emplacement <br />
                                <Autocomplete
                                    disablePortal
                                    name="titre_session"
                                    id="titre_session"
                                    className="disabled_style"
                                    options={emplacement_tab}
                                    value={p_detail_location_type_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_detail_location_type(value.value);
                                            setp_detail_location_type_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />


                                {/*  <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="titre_session"
                                    id="titre_session"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_location_type}
                                    onChange={(e) => setp_detail_location_type(e.target.value)}

                                >
                                    <MenuItem value="intra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Intra &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="extra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Extra &nbsp;</MenuItem>
                                    <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Autre &nbsp;</MenuItem>
                                </TextField> */}
                            </div>}


                            {!edit_session_form && <div className="session_caract"> Emplacement <br />

                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="emplacement_session"
                                    id="emplacement_session"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_location_type_label}

                                />
                            </div>}


                            {edit_session_form && <div className="session_caract"> Inclus au BPF <br />

                                <Autocomplete
                                    disablePortal
                                    name="is_bpf_session"
                                    id="is_bpf_session"
                                    className="disabled_style"
                                    options={inclus_bpf_tab}
                                    value={p_detail_is_bpf_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_detail_is_bpf(value.value);
                                            setp_detail_is_bpf_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />


                                {/*<TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="is_bpf_session"
                                    id="is_bpf_session"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_is_bpf}
                                    onChange={(e) => setp_detail_is_bpf(e.target.value)}

                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                </TextField>*/}
                            </div>}


                            {!edit_session_form && <div className="session_caract"> Inclus au BPF<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="is_bpf_session"
                                    id="is_bpf_session"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"

                                    value={p_detail_is_bpf_label}

                                />

                            </div>}


                            {edit_session_form && <div className="session_caract">Session à la demande <br />

                                <Autocomplete
                                    disablePortal
                                    name="session_ondemande"
                                    id="session_ondemande"
                                    className="disabled_style"
                                    options={session_ondemande_tab}
                                    value={p_detail_is_bpf_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_session_ondemande(value.value);
                                            setp_session_ondemande_label(value.label);

                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />
                                {/*<TextField
                                    name="session_ondemande"
                                    id="session_ondemande"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_session_ondemande}
                                    onChange={(e) => {
                                        setp_session_ondemande(e.target.value);
                                        IssessionChanged();
                                    }}
                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                </TextField> */}
                            </div>}

                            {!edit_session_form && <div className="session_caract">Session à la demande <br />
                                <TextField
                                    name="session_ondemande"
                                    id="session_ondemande"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_session_ondemande_label}

                                />

                            </div>}


                            {edit_session_form && <div className="session_caract">Statut <br />
                                <Autocomplete
                                    disablePortal
                                    name="session_status"
                                    id="session_status"
                                    className="disabled_style"
                                    options={status_tab}
                                    value={p_session_status_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_session_status(value.value);
                                            setp_session_status_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/* <TextField
                                    name="session_status"
                                    id="session_status"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_session_status}
                                    onChange={(e) => {
                                        setp_session_status(e.target.value);
                                        IssessionChanged();
                                    }
                                    }
                                >
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "background": "orange" }}>Inactif &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "background": "green" }}>Actif &nbsp;</MenuItem>
                                </TextField> */}
                            </div>}
                            {!edit_session_form && String(p_session_status) === "1" && <div className="session_caract">Statut  <br />
                                <TextField
                                    name="session_status_not_change_font"
                                    id="session_status_not_change_font"
                                    value={p_session_status_label}
                                    className="disabled_style enable_style"
                                    style={{ "background": "#D2E6AA" }}
                                    sx={{
                                        backgroundColor: '#ffd60a',
                                        border: '3px solid #001d3d',
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#003566',
                                            textTransform: 'capitalize',
                                        },
                                    }}
                                    disabled={false}

                                    inputProps={{
                                        sx: {
                                            color: 'black',
                                            paddingLeft: '15px',
                                            //fontSize: '20px',
                                        },
                                    }}
                                />
                            </div>}

                            {!edit_session_form && String(p_session_status) === "0" && <div className="session_caract">Statut  <br />
                                <TextField
                                    name="session_status_not_change_font"
                                    id="session_status_not_change_font"
                                    value={p_session_status_label}
                                    className="disabled_style enable_style"
                                    style={{ "background": "#F0785E" }}
                                    sx={{
                                        backgroundColor: '#ffd60a',
                                        border: '3px solid #001d3d',
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            color: '#003566',
                                            textTransform: 'capitalize',
                                        },
                                    }}
                                    disabled={false}

                                    inputProps={{
                                        sx: {
                                            color: 'black',
                                            paddingLeft: '15px',
                                            //fontSize: '20px',
                                        },
                                    }}
                                />
                            </div>}

                            {/*!edit_session_form && <div className="session_caract">Statut a * <br />
                                <TextField
                                    name="session_status"
                                    id="session_status"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_session_status_label}
                                    style={{
                                        backgroundColor: "blue"
                                    }}
                                    InputProps={{
                                        style: {
                                            color: "red"
                                        }
                                    }}

                                />

                                </div>*/}


                            {edit_session_form && <div className="session_caract">Etape <br />

                                <Autocomplete
                                    disablePortalNew
                                    name="session_etape"
                                    id="session_etape"
                                    className="disabled_style"
                                    options={New_Getall_Parter_OR_Default_Session_Steps_result}
                                    value={p_session_etape_label}
                                    onChange={(event, value) => {


                                        if (value && value.session_step_name) {
                                            setp_session_etape(value.session_step_name);
                                            setp_session_etape_label(value.session_step_name);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/*<TextField
                                    name="session_etape"
                                    id="session_etape"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_session_etape}
                                    onChange={(e) => {
                                        setp_session_etape(e.target.value);
                                        IssessionChanged();
                                    }}
                                >
                                    {Getall_Parter_OR_Default_Session_Steps_result &&
                                        Getall_Parter_OR_Default_Session_Steps_result.map((step) => (
                                            <MenuItem key={JSON.parse(step)._id} value={JSON.parse(step).session_step_name}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem", "width": '100%' }}>
                                                {JSON.parse(step).session_step_name}&nbsp;

                                            </MenuItem>
                                        ))}
                                </TextField> */}
                            </div>}

                            {!edit_session_form && <div className="session_caract">Etape <br />

                                <TextField
                                    name="session_etape"
                                    id="session_etape"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_session_etape_label}

                                />

                            </div>}



                            <div className="session_caract">
                                <b>Date début </b>
                                <DatePicker
                                    name="sessiondatedebut"
                                    id="sessiondatedebut"
                                    selected={SessionstartDate}
                                    onChange={(date) => {
                                        if (edit_session_form) {
                                            setSessionstartDate(date);
                                            setsessionChanged(true);
                                            IssessionChanged();
                                        }
                                    }
                                    }
                                    showTimeSelect={false}
                                    filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                    locale='fr-FR'

                                />
                            </div>


                            <div className="session_caract"> <b>Date Fin </b>
                                <DatePicker
                                    name="sessiondatefin"
                                    id="sessiondatefin"
                                    selected={SessionendDate}
                                    onChange={(date) => {
                                        if (edit_session_form) {
                                            setSessionendDate(date);
                                            setsessionChanged(true);
                                            IssessionChanged();
                                        }
                                    }
                                    }
                                    showTimeSelect={false}
                                    filterTime={filterPassedTime_end}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                />
                            </div>


                            {p_class_is_published && String(p_class_is_published) === "1" && <div className="session_caract">
                                <Tooltip className="tooltip_css" id="deb_inscription" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="deb_inscription" data-tooltip-html="La formation est publiée sur l'intranet. A partir de quand les inscriptions sont autorisées">
                                    <b>Date début des inscriptions  &nbsp; <FcInfo /></b>
                                </a>
                                <DatePicker
                                    required
                                    name="sessiondatedebutinscription"
                                    id="sessiondatedebutinscription"
                                    selected={SessionstartDateInscription}
                                    onChange={(date) => {
                                        if (edit_session_form) {
                                            setSessionstartDateInscription(date);
                                            setsessionChanged(true);
                                            IssessionChanged();
                                        }
                                    }

                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                    locale='fr-FR'

                                />
                            </div>}


                            {p_class_is_published && String(p_class_is_published) === "1" && <div className="session_caract">
                                <Tooltip className="tooltip_css" id="fin_inscription" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="fin_inscription" data-tooltip-html="La formation est publiée sur l'intranet. A partir de quand les inscriptions sont terminées">
                                    <b>Date Fin des inscriptions &nbsp; <FcInfo /></b>
                                </a>
                                <DatePicker
                                    required
                                    name="sessiondatefininscription"
                                    id="sessiondatefininscription"
                                    selected={SessionendDateInscription}
                                    onChange={(date) => {
                                        if (edit_session_form) {
                                            setSessionendDateInscription(date);
                                            setsessionChanged(true);
                                            IssessionChanged();
                                        }
                                    }
                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_end}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                />
                            </div>}


                            {edit_session_form && <div className="session_caract"> <b> Présentiel </b> <br />

                                <Autocomplete
                                    disablePortal
                                    name="presentiel"
                                    id="presentiel"
                                    className="disabled_style"
                                    options={presentiel_tab}
                                    value={p_session_presentiel_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_session_presentiel(value.value);
                                            setp_session_presentiel_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/*<TextField
                                    name="presentiel"
                                    id="presentiel"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_session_presentiel}
                                    onChange={(e) => {
                                        setp_session_presentiel(e.target.value);
                                        IssessionChanged();
                                    }
                                    }
                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                </TextField>*/}
                            </div>}

                            {!edit_session_form && <div className="session_caract">  <b>Présentiel  </b> <br />
                                <TextField
                                    name="presentiel"
                                    id="presentiel"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_session_presentiel_label}
                                />

                            </div>}



                            {edit_session_form && <div className="session_caract"><b>A distance </b><br />

                                <Autocomplete
                                    disablePortal
                                    name="distantiel"
                                    id="distantiel"
                                    className="disabled_style"
                                    options={distaciel_tab}
                                    value={p_session_distance_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_session_distance(value.value);
                                            setp_session_distance_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/*<TextField
                                    name="distantiel"
                                    id="distantiel"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_session_distance}
                                    onChange={(e) => {
                                        setp_session_distance(e.target.value);
                                        IssessionChanged();
                                    }
                                    }
                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                </TextField>*/}
                            </div>}

                            {!edit_session_form && <div className="session_caract"><b>A distance </b><br />
                                <TextField
                                    name="distantiel"
                                    id="distantiel"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_session_distance_label}
                                />

                            </div>}


                            <div className="session_caract"> Adresse<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="adresse"
                                    id="adresse"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_adresse}
                                    onChange={(e) => setp_adresse(e.target.value)}

                                />
                            </div>
                            <div className="session_caract"> Code postal<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="code_postal"
                                    id="code_postal"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_code_postal}
                                    onChange={(e) => setp_code_postal(e.target.value)}
                                />
                            </div>
                            <div className="session_caract"> Ville<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="ville"
                                    id="ville"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_ville}
                                    onChange={(e) => setp_ville(e.target.value)}
                                />
                            </div>

                            <div className="session_caract"> Pays<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="pays"
                                    id="pays"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_pays}
                                    onChange={(e) => setp_pays(e.target.value)}
                                />
                            </div>

                            {/* <div className="session_caract"> Formateur<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="formateur"
                                    id="formateur"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_formateur}
                                    onChange={(e) => setp_formateur(e.target.value)}
                                />
                            </div>*/}

                            {!edit_session_form && <div className="session_caract"> Formateur <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="formateur_label"
                                    id="formateur_label"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_formateur_label}

                                />

                            </div>}

                            {/*edit_session_form && <div className="session_caract"> Formateur <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="formateur_id"
                                    id="formateur_id"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_formateur_id}
                                    onChange={(e) => {
                                        setp_formateur_id(e.target.value);
                                        IssessionChanged();

                                    }
                                    }

                                >
                                    {Getall_Training_Employee_No_Filter_result &&
                                        Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                            <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem", "width": '100%' }}>
                                                {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                            </MenuItem>
                                        ))}

                                </TextField>
                            </div>*/}

                            {edit_session_form && <div className="session_caract"> Formateur  <br />
                                <Autocomplete
                                    disablePortal
                                    name="formateur_id"
                                    id="formateur_id"
                                    className="disabled_style"
                                    options={New_Getall_Training_Employee_No_Filter_result}
                                    value={p_formateur_label}
                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_formateur_id(value._id);
                                            setp_formateur_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />
                            </div>}





                            <div className="session_caract"> <b> Nombre participants </b><br />
                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="nb_participant"
                                    id="nb_participant"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "1", max: "1000", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_nb_participant}
                                    onChange={(e) => {
                                        setp_nb_participant(e.target.value);
                                        IssessionChanged();
                                    }
                                    }
                                />
                            </div>

                            <div className="session_caract"> Prix session <br />
                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="prix_session"
                                    id="prix_session"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "1", max: "1000", step: "1" }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_prix_session}
                                    onChange={(e) => {
                                        setp_prix_session(e.target.value);
                                        IssessionChanged();
                                    }}
                                />
                            </div>


                            <div className="div_row" style={{ "border": "None" }}>
                                {/*     {!fields1desabled && <div className="session_caract">Choisir un modèle d'attestation :

                                 <select id="certif_liste" name="certif_liste" value={selectedCertif} onChange={handleChangeselectedCertif} className="selectsession">
                                        <option value="">Choisir un modèle </option>
                                        {GetAttestation_Certif_result &&
                                            GetAttestation_Certif_result.map((certificat) => (
                                                <option value={JSON.parse(certificat).nom}>
                                                    {JSON.parse(certificat).nom}

                                                </option>
                                            ))}
                                    </select>
                                    <img className="preview_certif" src={urlpreview_certif} />

                                </div>}
                                {fields1desabled && <div className="session_caract">Le modèle d'attestation :

                                    <img className="preview_certif" src={urlpreview_certif} />

                                </div>}
                                
*/}
                                <div className="session_caract"> Contenu de la formation (max 800 caractères)<br />
                                    <i> (à afficher sur l'attestation) </i>
                                    {!fields1desabled && <br />}
                                    {!fields1desabled && <br />}

                                    <Editor
                                        onInit={(evt, editor) => editorRef_contenu_ftion.current = editor}
                                        initialValue={field_contenu_ftion}
                                        onKeyUp={editor_contenu_ftion_keyup}
                                        disabled={fields1desabled}

                                        init={{
                                            resize: false,

                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: false,
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px; }'
                                        }}
                                    />
                                </div>

                                {/* -- début   champs specifiques **/}

                                <div className="div_row">
                                    <hr />
                                </div>
                                <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques  <br />

                                    {rows_champs_specifics &&
                                        rows_champs_specifics.map((champ_spec) => (

                                            <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                                <br />

                                                {String(JSON.parse(champ_spec).field_type) === "float" &&
                                                    <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}
                                                        type="number"
                                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle(e.target.value);
                                                            // setEmployee_data_changed("1");
                                                        }}
                                                    />}



                                                {String(JSON.parse(champ_spec).field_type) === "string" &&
                                                    <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}
                                                        type="text"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle(e.target.value);
                                                            // setEmployee_data_changed("1");
                                                        }}

                                                    />}


                                            </div>
                                        ))}

                                    <br />

                                </div>

                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {/* -- end  champs specifiques **/}
                            </div>


                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>


                            {sessionChanged && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                /!\ Pensez à enregistrer les modifications
                            </div>}

                            {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    {!sessionChanged && selected_session_id && fields1desabled &&
                                        <Button variant="contained" className="bton_annule" onClick={close_edit_form}>Fermer
                                        </Button>}

                                    {edit_session_form && selected_session_id &&
                                        <Button variant="contained" className="bton_enreg" onClick={CreateSessionFormation}>Enregistrer les modifications
                                        </Button>}
                                </div>

                                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                    {!sessionChanged && selected_session_id && fields1desabled &&
                                        <Button variant="contained" className="bton_edit" onClick={enableSessionFields}>Editer
                                        </Button>}

                                    {((sessionChanged && selected_session_id) || !fields1desabled) &&
                                        <Button variant="contained" className="bton_annule" onClick={annuleSessionUpdate}>Annuler les modifications
                                        </Button>}

                                </div>

                            </div>}



                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                        </div>}


                        {String(addOneSession) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }} >
                            <h5> Ajout d'une session de formation</h5>

                            {/*<div className="session_caract" > Choisir une formation *<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_myclass_title"
                                    id="one_myclass_title"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_myclass_title}
                                    onChange={(e) => {

                                        setone_selected_internal_url(e.target.value);
                                        setp_one_myclass_title(e.target.value)
                                        Get_Class_Data(e.target.value);
                                    }}
                                >

                                    {GetCurrentPartnerClass_result &&
                                        GetCurrentPartnerClass_result.map((myclass) => (
                                            <MenuItem key={JSON.parse(myclass).internal_url} value={JSON.parse(myclass).internal_url} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                                {JSON.parse(myclass).title}

                                            </MenuItem>
                                        ))}

                                </TextField>

                            </div>*/}

                            <div className="session_caract" > <b>Choisir une formation </b> <br />

                                <Autocomplete
                                    disablePortal
                                    name="one_myclass_title"
                                    id="one_myclass_title"
                                    className="disabled_style enable_style"
                                    options={New_GetCurrentPartnerClass_result}
                                    onChange={(event, value) => {
                                        // setp_champ_table(value.nom_champ_technique);
                                        setone_selected_internal_url(value.internal_url);
                                        setp_one_myclass_title(value.internal_url);
                                        setp_one_myclass_is_published(value.ispublished);
                                        Get_Class_Data(value.internal_url);
                                    }}


                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                            </div>


                            <div className="session_caract"><b> Code Session </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_code_session"
                                    id="one_code_session"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_code_session}
                                    onChange={(e) => setp_one_code_session(e.target.value)}

                                />
                            </div>

                            <div className="session_caract"> Titre Session<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_titre_session"
                                    id="one_titre_session"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_one_titre}
                                    onChange={(e) => setp_one_titre(e.target.value)}

                                />
                            </div>

                            <div className="session_caract"> Emplacement <br />
                                <Autocomplete
                                    disablePortal
                                    name="one_emplacement_session"
                                    id="one_emplacement_session"
                                    className="disabled_style"
                                    options={emplacement_tab}
                                    value={p_one_location_type_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_location_type(value.value);
                                            setp_one_location_type_label(value.label);

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/* <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_emplacement_session"
                                    id="one_emplacement_session"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_one_location_type}
                                    onChange={(e) => setp_one_location_type(e.target.value)}

                                >
                                    <MenuItem value="intra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Intra &nbsp;</MenuItem>
                                    <MenuItem value="extra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Extra &nbsp;</MenuItem>
                                    <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Autre &nbsp;</MenuItem>
                                </TextField>*/}
                            </div>


                            <div className="session_caract"> Inclus au BPF <br />
                                <Autocomplete
                                    disablePortal
                                    name="one_is_bpf_session"
                                    id="one_is_bpf_session"
                                    className="disabled_style"
                                    options={session_ondemande_tab}
                                    value={p_one_is_bpf_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_is_bpf(value.value);
                                            setp_one_is_bpf_label(value.label);

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/* <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_is_bpf_session"
                                    id="one_is_bpf_session"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_one_is_bpf}
                                    onChange={(e) => setp_one_is_bpf(e.target.value)}

                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                </TextField>*/}
                            </div>



                            <div className="session_caract">Session à la demande <br />
                                <Autocomplete
                                    disablePortal
                                    name="one_session_ondemande"
                                    id="one_session_ondemande"
                                    className="disabled_style"
                                    options={session_ondemande_tab}
                                    value={p_one_session_ondemande_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_session_ondemande(value.value);
                                            setp_one_session_ondemande_label(value.label);

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/*<TextField
                                    name="one_session_ondemande"
                                    id="one_session_ondemande"
                                    type="number"

                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_session_ondemande}
                                    onChange={(e) => {
                                        setp_one_session_ondemande(e.target.value);
                                    }}
                                >

                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                </TextField>*/}
                            </div>


                            <div className="session_caract">Statut <br />
                                <Autocomplete
                                    disablePortal
                                    name="session_status"
                                    id="session_status"
                                    className="disabled_style"
                                    options={status_tab}
                                    value={p_one_session_status_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_session_status(value.value);
                                            setp_one_session_status_label(value.label);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/* <TextField
                                    name="session_status"
                                    id="session_status"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_session_status}
                                    onChange={(e) => setp_one_session_status(e.target.value)}
                                >
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "background": "orange" }}>Inactif &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "background": "green" }}>Actif &nbsp;</MenuItem>
                                </TextField> */}
                            </div>




                            <div className="session_caract">Etape <br />

                                <Autocomplete
                                    disablePortalNew
                                    name="one_session_etape"
                                    id="one_session_etape"
                                    className="disabled_style"
                                    options={New_Getall_Parter_OR_Default_Session_Steps_result}
                                    value={p_one_session_etape_label}
                                    onChange={(event, value) => {
                                        if (value && value.session_step_name) {
                                            setp_one_session_etape(value.session_step_name);
                                            setp_one_session_etape_label(value.session_step_name);

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />


                                {/*<TextField
                                    name="session_etape"
                                    id="session_etape"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "0", max: "1", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_session_etape}
                                    onChange={(e) => setp_one_session_etape(e.target.value)}
                                >
                                    {Getall_Parter_OR_Default_Session_Steps_result &&
                                        Getall_Parter_OR_Default_Session_Steps_result.map((step) => (
                                            <MenuItem key={JSON.parse(step)._id} value={JSON.parse(step).session_step_name}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem", "width": '100%' }}>
                                                {JSON.parse(step).session_step_name}&nbsp;

                                            </MenuItem>
                                        ))}
                                </TextField>*/}
                            </div>

                            <div className="session_caract">
                                <b> Date début </b>
                                <DatePicker
                                    name="one_sessiondatedebut"
                                    id="one_sessiondatedebut"
                                    selected={one_SessionstartDate}
                                    onChange={(date) => {
                                        setone_SessionstartDate(date);
                                        setsessionChanged(true);
                                    }
                                    }
                                    showTimeSelect={false}
                                    filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'

                                />
                            </div>

                            <div className="session_caract"> <b>Date Fin </b>
                                <DatePicker
                                    name="one_sessiondatefin"
                                    id="one_sessiondatefin"
                                    selected={one_SessionendDate}
                                    onChange={(date) => {
                                        setone_SessionendDate(date);
                                        setsessionChanged(true);
                                    }
                                    }
                                    showTimeSelect={false}
                                    filterTime={filterPassedTime_end}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                />
                            </div>


                            {p_one_myclass_is_published && String(p_one_myclass_is_published) === "1" && <div className="session_caract">
                                <Tooltip className="tooltip_css" id="deb_inscription" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="deb_inscription" data-tooltip-html="La formation est publiée sur l'intranet. A partir de quand les inscriptions sont autorisées">
                                    <b> Date début des inscriptions &nbsp; <FcInfo /></b>
                                </a>
                                <DatePicker
                                    required
                                    name="one_sessiondatedebutinscription"
                                    id="one_sessiondatedebutinscription"
                                    selected={one_SessionstartDateInscription}
                                    onChange={(date) => {
                                        setone_SessionstartDateInscription(date);
                                        setsessionChanged(true);
                                    }

                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'

                                />

                            </div>}


                            {p_one_myclass_is_published && String(p_one_myclass_is_published) === "1" && <div className="session_caract">
                                <Tooltip className="tooltip_css" id="fin_inscription" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="fin_inscription" data-tooltip-html="La formation est publiée sur l'intranet. A partir de quand les inscriptions sont terminées">
                                    <b> Date Fin des inscriptions <FcInfo /></b>
                                    <DatePicker
                                        required
                                        name="one_sessiondatefininscription"
                                        id="one_sessiondatefininscription"
                                        selected={one_SessionendDateInscription}
                                        onChange={(date) => {
                                            setone_SessionendDateInscription(date);
                                            setsessionChanged(true);
                                        }
                                        }
                                        showTimeSelect={false}
                                        //filterTime={filterPassedTime_end}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                    />
                                </a>
                            </div>}


                            <div className="session_caract">Présentiel <br />

                                <Autocomplete
                                    disablePortal
                                    name="one_presentiel"
                                    id="one_presentiel"
                                    className="disabled_style enable_style"
                                    options={presentiel_tab}
                                    value={p_one_session_presentiel_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_session_presentiel(value.value);
                                            setp_one_session_presentiel_label(value.label);
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />

                                {/*<TextField
                                    name="one_presentiel"
                                    id="one_presentiel"
                                    type="number"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_session_presentiel}
                                    onChange={(e) => setp_one_session_presentiel(e.target.value)}
                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }} >Non &nbsp;</MenuItem>

                                </TextField> */}
                            </div>

                            <div className="session_caract">A distance <br />
                                <Autocomplete
                                    disablePortal
                                    name="one_distantiel"
                                    id="one_distantiel"
                                    className="disabled_style enable_style"
                                    options={distaciel_tab}
                                    value={p_one_session_presentiel_label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_session_distance(value.value);
                                            setp_one_session_distance_label(value.label);
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />
                                {/*<TextField
                                    name="one_distantiel"
                                    id="one_distantiel"
                                    type="number"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_session_distance}
                                    onChange={(e) => setp_one_session_distance(e.target.value)}
                                >
                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>

                                </TextField>*/}
                            </div>

                            <div className="session_caract"> Adresse<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_adresse"
                                    id="one_adresse"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_adresse}
                                    onChange={(e) => setp_one_adresse(e.target.value)}
                                />
                            </div>
                            <div className="session_caract"> Code postal<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_code_postal"
                                    id="one_code_postal"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_code_postal}
                                    onChange={(e) => setp_one_code_postal(e.target.value)}
                                />
                            </div>
                            <div className="session_caract"> Ville<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_ville"
                                    id="one_ville"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_ville}
                                    onChange={(e) => setp_one_ville(e.target.value)}
                                />
                            </div>

                            <div className="session_caract"> Pays<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_pays"
                                    id="one_pays"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_pays}
                                    onChange={(e) => setp_one_pays(e.target.value)}
                                />
                            </div>



                            {/*<div className="session_caract"> Formateur<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_formateur"
                                    id="one_formateur"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_formateur}
                                    onChange={(e) => setp_one_formateur(e.target.value)}
                                />
                            </div>*/}

                            {/* <div className="session_caract"> Formateur<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_formateur_id"
                                    id="one_formateur_id"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_formateur_id}
                                    onChange={(e) => {
                                        setp_one_formateur_id(e.target.value);

                                    }
                                    }
                                >
                                    {Getall_Training_Employee_No_Filter_result &&
                                        Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                            <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                                {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                            </MenuItem>
                                        ))}
                                </TextField>
                            </div>*/}


                            <div className="session_caract"> Formateur <br />
                                <Autocomplete
                                    disablePortal
                                    name="formateur_id"
                                    id="formateur_id"
                                    className="disabled_style"
                                    options={New_Getall_Training_Employee_No_Filter_result}

                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_formateur_id(value._id);
                                            IssessionChanged();

                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />
                            </div>

                            <div className="session_caract"> Nombre participants <br />
                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_nb_participant"
                                    id="one_nb_participant"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "1", max: "1000", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_nb_participant}
                                    onChange={(e) => setp_one_nb_participant(e.target.value)}
                                />
                            </div>

                            <div className="session_caract"> Prix session <br />
                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="one_prix_session"
                                    id="one_prix_session"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: "1", max: "1000", step: "1" }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_prix_session}
                                    onChange={(e) => setp_one_prix_session(e.target.value)}
                                />
                            </div>




                            <div className="div_row" style={{ "border": "None" }}>
                                {/* <div className="session_caract">Choisir un modèle d'attestation :

                                    <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_certif_liste"
                                        id="one_certif_liste"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "1", max: "1000", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        onChange={handleChangeone_selectedCertif}
                                    >
                                        {GetAttestation_Certif_result &&
                                            GetAttestation_Certif_result.map((certificat) => (
                                                <MenuItem key={JSON.parse(certificat).nom} value={JSON.parse(certificat).nom} style={{ "paddingLeft": "5px", "textAlign": "left", "width": '100%' }}>
                                                    {JSON.parse(certificat).nom}&nbsp; &nbsp;<br />

                                                </MenuItem>
                                            ))}
                                    </TextField>

                                    <img className="preview_certif" src={urlpreview_certif} />

                                </div>*/}


                                <div className="session_caract"> Contenu de la formation (max 800 caractères)<br />
                                    <i> (à afficher sur l'attestation) </i>
                                    {!fields1desabled && <br />}
                                    {!fields1desabled && <br />}

                                    <Editor
                                        onInit={(evt, editor) => one_editorRef_contenu_ftion.current = editor}
                                        initialValue={one_field_contenu_ftion}
                                        onKeyUp={one_editor_contenu_ftion_keyup}
                                        disabled={false}

                                        init={{
                                            resize: false,

                                            height: 200,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: false,
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px; }'
                                        }}
                                    />
                                </div>

                                {/* -- début champs specifiques **/}
                                <div className="div_row">
                                    <hr />
                                </div>
                                <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques<br />

                                    {rows_champs_specifics &&
                                        rows_champs_specifics.map((champ_spec) => (

                                            <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                                <br />
                                                {String(JSON.parse(champ_spec).field_type) === "float" &&
                                                    <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        type="number"
                                                        inputProps={{ min: "1", max: "999999", step: "1" }}

                                                        className="disabled_style enable_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle(e.target.value);
                                                            // setEmployee_data_changed("1");
                                                        }}

                                                    />}

                                                {String(JSON.parse(champ_spec).field_type) === "string" &&
                                                    <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style enable_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle(e.target.value);
                                                            // setEmployee_data_changed("1");
                                                        }}

                                                    />}

                                            </div>
                                        ))}

                                    <br />

                                </div>

                                <div className="div_row">
                                    &nbsp;
                                </div>

                                {/* -- end  champs specifiques **/}

                            </div>


                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                            {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                    <Button variant="contained" className="bton_enreg" onClick={One_CreateSessionFormation}>Enregistrer la session
                                    </Button>
                                </div>

                                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                    <Button variant="contained" className="bton_annule" onClick={One_annuleSessionUpdate}>Annuler
                                    </Button>

                                </div>

                            </div>}
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>
                        }

                    </div>}

                    {String(submenu) === String("sequence_session") && <div style={{ "border": "None" }}>

                        <h5> Gestion séquences </h5>

                        <div className="div_row" style={{ "border": "None", paddingRight: '10px' }}>

                            <div className="session_data">
                                <div style={{ "border": "None" }}>

                                    <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                        &nbsp;
                                        <Box
                                            sx={{
                                                height: 500,
                                                width: '100%',
                                                paddingRight: '1px',


                                            }}
                                        >
                                            <DataGrid
                                                checkboxSelection={true}
                                                disableRowSelectionOnClick
                                                onSelectionModelChange={(newSelectionModel) => {
                                                    setselectionModel_sequence(newSelectionModel);
                                                    /*if (newSelectionModel.length === 1)
                                                        handleClick_edit_session_From_Line(newSelectionModel);*/
                                                    if (newSelectionModel.length !== 1) {

                                                    }
                                                }}
                                                selectionModel={selectionModel_sequence}

                                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                                rows={Getall_Sequence_Of_Session_result.map((item, index) => (
                                                    {
                                                        id: index,

                                                        _id: JSON.parse(item)._id,
                                                        sequence_start: JSON.parse(item).sequence_start,
                                                        sequence_end: JSON.parse(item).sequence_end,
                                                        session_id: JSON.parse(item).session_id,
                                                        sequence_title: JSON.parse(item).sequence_title,


                                                    }
                                                ))}

                                                columns={columns_sequence}
                                                pageSize={10}
                                                className="datagridclass"

                                                onRowDoubleClick={(newSelectionModel) => {

                                                    setselected_detail_sequence_title(newSelectionModel.row.sequence_title);
                                                    setselected_sequence_startDate(newSelectionModel.row.sequence_start);
                                                    setselected_sequence_endDate(newSelectionModel.row.sequence_end);

                                                    Getall_List_Sequence_Ressource(newSelectionModel.row._id);

                                                    setselected_sequence_id(newSelectionModel.row._id);
                                                    setdisplay_detail_sequence("1");

                                                    if (document.getElementById('myRef_seq_ressource')) {
                                                        var divh = document.getElementById('myRef_seq_ressource').offsetTop;
                                                        window.scrollTo({
                                                            top: divh,
                                                            behavior: "smooth",
                                                        });
                                                    }

                                                }}



                                                rowsPerPageOptions={[10]}
                                                //disableSelectionOnClick
                                                components={{
                                                    Toolbar: GridToolbar,
                                                }}
                                                sx={datagridSx}
                                                getCellClassName={(params) => {
                                                    //field === 'distantiel'
                                                    if (params.field === 'distantiel' && String(params.value) === "1") {
                                                        return 'cell--distantiel';
                                                    }
                                                    if (params.field === "presentiel" && String(params.value) == "1") {
                                                        return 'cell--presentiel';
                                                    }


                                                    //field === "etape"
                                                    if (params.field === "etape" && String(params.value) == "0") {
                                                        return 'cell--etape--projet';
                                                    }

                                                    if (params.field === "etape" && String(params.value) == "1") {
                                                        return 'cell--etape--planification';
                                                    }

                                                    if (params.field === "etape" && String(params.value) == "2") {
                                                        return 'cell--etape--planifie';
                                                    }

                                                    if (params.field === "etape" && String(params.value) == "3") {
                                                        return 'cell--etape--termine';
                                                    }

                                                    if (params.field === "etape" && String(params.value) == "4") {
                                                        return 'cell--etape--facture';
                                                    }

                                                    if (params.field === "etape" && String(params.value) == "-1") {
                                                        return 'cell--etape--annule';
                                                    }

                                                }}


                                            />
                                        </Box>


                                        {selectionModel_sequence && selectionModel_sequence.length > 1 &&
                                            <div className="block_en_mass">
                                                <nav className='traitement_mass'>Traitement en masse / Séquence</nav>
                                                &nbsp;<select
                                                    id={"actionmassinscr"} name={"actionmassinscr"}
                                                    onChange={actionmass_sequence}
                                                    className="action_mass">
                                                    <option selected value="n/a">Choisir une action</option>
                                                    <option value="ajout_ressource">Ajouter Ressource</option>
                                                    <option value="supprimer">Supprimer</option>


                                                </select> &nbsp;

                                                {actionmass_sequence_val &&
                                                    <Popup
                                                        trigger={<Button className="bton_traiter_en_mass" >
                                                            <FcAcceptDatabase /> Traiter

                                                        </Button>}
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >

                                                        {close => (
                                                            <div>
                                                                <button className="gest_close" onClick={close}>
                                                                    &times;
                                                                </button>
                                                                <div className="gest_header"> MySy Information </div>
                                                                {String(actionmass_sequence_val) === "ajout_ressource" && <div className="gest_content">
                                                                    {' '}

                                                                    Confirmer l'action <b> d'ajout de ressource </b> en masse.
                                                                </div>}

                                                                {String(actionmass_sequence_val) === "supprimer" && <div className="gest_content">
                                                                    {' '}

                                                                    Confirmer l'action <b style={{ "color": "red" }}> de suppression </b> en masse.
                                                                </div>}


                                                                <div className="gest_actions">
                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                                            actionmass_sequence_Traitement();
                                                                            close();
                                                                        }}> Valider </button>

                                                                    </div>
                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                        <button
                                                                            className="gest_bton_popup"
                                                                            onClick={() => {
                                                                                //console.log('modal closed ');
                                                                                close();
                                                                            }}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                }
                                                <br />


                                            </div>

                                        }
                                        <br />

                                    </div>

                                </div>

                            </div>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>



                                <Popup
                                    trigger={
                                        <Button variant="outlined"
                                            className="detail_class_submenu bton_add_session bton_add_session_create_automatic" style={{ "width": "40%" }}
                                            id='menu_import_participant' name='menu_import_participant'>Création automatique
                                            <FcMultipleSmartphones />
                                        </Button>

                                    }
                                    modal
                                    nested
                                    position="center center"
                                >

                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}

                                                <font color="red">  Confirmer l'action <b> {actionmass_preinsc_val} </b> en masse. <br />
                                                    /!\ : Toutes les séquences de cette session seront supprimées. </font>
                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                        Add_Automatic_Sequence_Session();
                                                        close();
                                                    }}> Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                                &nbsp;
                                <Button variant="outlined" onClick={submenu_add_one_sequence}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 Séquence &nbsp;
                                    <img src={add_plus} alt="ajout session" className="icon_plus" />
                                </Button>

                                <br />

                            </div>

                        </div>
                        <div className="div_row" ref={myRef_seq_ressource} id="myRef_seq_ressource"> &nbsp;</div>
                        {String(display_detail_sequence) === "1" && <div className="div_row" style={{ "border": "None" }}>

                            <div className="session_caract"> Titre <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="seq_title"
                                    id="seq_title"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={selected_detail_sequence_title}

                                />
                            </div>

                            <div className="session_caract"> début <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="seq_start"
                                    id="seq_start"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={selected_sequence_startDate}

                                />
                            </div>

                            <div className="session_caract"> Fin <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="seq_end"
                                    id="seq_end"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={selected_sequence_endDate}

                                />
                            </div>


                            <div className="session_data">
                                <div style={{ "border": "None" }}>

                                    <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                        &nbsp;
                                        <Box
                                            sx={{
                                                height: 500,
                                                width: '100%',
                                                paddingRight: '1px',


                                            }}
                                        >
                                            <DataGrid
                                                checkboxSelection
                                                onSelectionModelChange={(newSelectionModel) => {
                                                    setselectionModel_sequence(newSelectionModel);
                                                    /*if (newSelectionModel.length === 1)
                                                        handleClick_edit_session_From_Line(newSelectionModel);*/
                                                    if (newSelectionModel.length !== 1) {

                                                    }
                                                }}
                                                selectionModel={selectionModel}

                                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                                rows={Getall_List_Sequence_Ressource_result.map((item, index) => (
                                                    {
                                                        id: index,
                                                        _id: JSON.parse(item)._id,
                                                        related_target_collection_id: JSON.parse(item).related_target_collection_id,
                                                        related_target_collection: JSON.parse(item).related_target_collection,
                                                        type_ressource: JSON.parse(item).related_target_collection_object,
                                                        related_target_collection_object: JSON.parse(item).related_target_collection_object,
                                                        related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                                        poste: JSON.parse(item).poste,
                                                    }
                                                ))}

                                                columns={columns_sequence_ressources_affectation}
                                                pageSize={10}
                                                className="datagridclass"

                                                onRowDoubleClick={(newSelectionModel) => {


                                                }}



                                                rowsPerPageOptions={[10]}
                                                disableSelectionOnClick
                                                components={{
                                                    Toolbar: GridToolbar,
                                                }}
                                                sx={datagridSx}



                                            />
                                        </Box>
                                        <br />

                                    </div>


                                </div>

                            </div>
                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                                <Button variant="outlined" onClick={submenu_add_one_Ressource}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 Ressource &nbsp;
                                    <img src={add_plus} alt="ajout session" className="icon_plus" />
                                </Button>

                                <br />

                            </div>

                        </div>}

                    </div>}

                    {String(submenu) === String("preinscrit") && <div style={{ "border": "None" }}>

                        <div className="div_row" style={{ "border": "None" }}>


                            {String(addOneParticipant) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>
                                <div className="titre1"> Ajout d'un nouveau participant à la session <u> {selected_code_session} </u> de la formation <u> {selected_class_title} </u></div>

                                <div className="session_caract"> <b> Civilité </b><br />
                                    <TextField
                                        required
                                        name="one_civilite_part"
                                        //label="Civilité"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        select
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_civilite_part}
                                        onChange={(e) => setp_one_civilite_part(e.target.value)}

                                    >
                                        <MenuItem value="Mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                                        <MenuItem value="M." style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >M.  &nbsp;</MenuItem>
                                        <MenuItem value="Neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Neutre&nbsp;&nbsp;</MenuItem>


                                    </TextField>
                                </div>

                                <div className="session_caract"> <b> Nom </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_nom_part"
                                        id="one_nom_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_nom_part}
                                        onChange={(e) => {
                                            setp_one_nom_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>


                                <div className="session_caract"> <b> Prénom </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_prenom_part"
                                        id="one_prenom_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_prenom_part}
                                        onChange={(e) => {
                                            setp_one_prenom_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>

                                <div className="session_caract"> <b> Email </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_email_part"
                                        id="one_email_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_email_part}
                                        onChange={(e) => {
                                            setp_one_email_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>

                                <div className="session_caract"> <b> Date Naissance </b><br />
                                    <DatePicker
                                        name="one_naissance_part"
                                        id="one_naissance_part"
                                        selected={new Date(moment(p_one_naissance_part, "DD/MM/YYYY"))}
                                        onChange={(date) => {
                                            setp_one_naissance_part(format(date, 'd/MM/yyyy'));

                                        }
                                        }
                                        showTimeSelect={false}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                        locale='fr-FR'

                                    />

                                </div>


                                <div className="session_caract"> <b> téléphone </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_phone_part"
                                        id="one_phone_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_phone_part}
                                        onChange={(e) => {
                                            setp_one_phone_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>

                                <div className="session_caract"> Statut Inscription <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        select
                                        value={myinscript_status}
                                        name="one_status_part"
                                        id="one_status_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChangeInscription_Status}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                    >
                                        {status_inscription_add.map((option) => (
                                            <MenuItem key={option.value} value={option.value} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {option.label} &nbsp; <br />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>



                                <div className="session_caract"> Client  <br />
                                    <Autocomplete
                                        disablePortal
                                        name="one_detail_client_rattachement"
                                        id="one_detail_client_rattachement"
                                        className="disabled_style"
                                        options={New_Get_List_Partner_Clients_result}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_one_detail_client_rattachement_id(value._id);
                                                setp_one_detail_client_rattachement_nom(value.nom);

                                            }
                                        }}
                                        value={p_one_detail_client_rattachement_nom}

                                        renderInput={(params) => <TextField {...params} label="" />
                                        }
                                    />
                                </div>

                                <div className="session_caract"> Type apprenant <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_detail_type_apprenant"
                                        id="one_detail_type_apprenant"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_one_detail_type_apprenant}
                                        onChange={(e) => {
                                            setp_one_detail_type_apprenant(e.target.value);
                                        }
                                        }

                                    >
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Autre &nbsp;</MenuItem>
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Salariés &nbsp;</MenuItem>
                                        <MenuItem value="2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Apprentis &nbsp;</MenuItem>
                                        <MenuItem value="3" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Particuliers &nbsp;</MenuItem>
                                        <MenuItem value="4" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Rech. Emploi &nbsp;</MenuItem>

                                    </TextField>
                                </div>

                                {/* -- début champs specifiques **/}
                                <div className="div_row" style={{ "border": 'none' }}>
                                    <hr />
                                </div>
                                <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

                                    {rows_champs_specifics_stagiaire &&
                                        rows_champs_specifics_stagiaire.map((champ_spec) => (

                                            <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name={JSON.parse(champ_spec).field_name}
                                                    id={JSON.parse(champ_spec).field_name}

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}


                                                    className="disabled_style"
                                                    onChange={(e) => {
                                                        change_champs_spec_handle_stagiaire(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }}
                                                //onChange={change_champs_spec_handle}


                                                />
                                            </div>
                                        ))}

                                    <br />

                                </div>
                                {/* -- end  champs specifiques **/}

                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                        <Button variant="contained" className="bton_enreg" onClick={One_Create_Participant}>Enregistrer le participant
                                        </Button>
                                    </div>

                                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                        <Button variant="contained" className="bton_annule" onClick={One_annuleAdd_Participant}>Annuler
                                        </Button>

                                    </div>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>



                            </div>}
                        </div>

                        {String(addOneParticipant) !== "1" && <div className="session_data" style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                checkboxSelection
                                onSelectionModelChange={(newSelectionModel) => {
                                    setselectionModel_preinsc(newSelectionModel);
                                    //console.log("ch selected--" + newSelectionModel);
                                }}
                                selectionModel={selectionModel_preinsc}

                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={rowss_preinsc.map((item, index) => (
                                    {
                                        id: index,
                                        _id: JSON.parse(item)._id,
                                        nom: JSON.parse(item).nom,
                                        prenom: JSON.parse(item).prenom,
                                        employeur: JSON.parse(item).employeur,
                                        email: JSON.parse(item).email,
                                        amount: JSON.parse(item).price,
                                        opco: JSON.parse(item).opco,
                                        modefinancement: JSON.parse(item).modefinancement,
                                        civilite: JSON.parse(item).civilite,
                                        client_rattachement_nom: JSON.parse(item).client_rattachement_nom,


                                    }
                                ))}

                                columns={columns_preinscrit}
                                pageSize={10}
                                className="datagridclass"

                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                getEstimatedRowHeight={() => 200}
                                getRowHeight={() => "auto"}
                                sx={{
                                    "& .MuiDataGrid-cellContent": {
                                        minHeight: 50,

                                    },
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />

                            {selectionModel_preinsc && selectionModel_preinsc.length >= 1 &&
                                <div className="div_row" style={{ "border": "none" }}>
                                    <div className="block_en_mass">
                                        <nav className='traitement_mass'>Traitement en masse</nav>
                                        &nbsp;<select
                                            id={"actionmass_preinsc"} name={"actionmass_preinsc"}
                                            onChange={actionmass_preinsc}
                                            className="action_mass">
                                            <option selected value="n/a">Choisir une action</option>
                                            <option value="valider">Valider</option>
                                            <option value="refuser">Refuser</option>

                                        </select> &nbsp;
                                        {actionmass_preinsc_val &&
                                            <Popup
                                                trigger={<Button className="bton_traiter_en_mass" >
                                                    <FcAcceptDatabase /> Traiter

                                                </Button>}
                                                modal
                                                nested
                                                position="center center"
                                            >

                                                {close => (
                                                    <div>
                                                        <button className="gest_close" onClick={close}>
                                                            &times;
                                                        </button>
                                                        <div className="gest_header"> MySy Information </div>
                                                        <div className="gest_content">
                                                            {' '}

                                                            Confirmer l'action <b> {actionmass_preinsc_val} </b> en masse.
                                                        </div>
                                                        <div className="gest_actions">
                                                            <div style={{ "width": "45%", "float": "left" }}>
                                                                <button className="gest_bton_popup" onClick={(event) => {
                                                                    actionmass_preinsc_Traitemet();
                                                                    close();
                                                                }}> Valider </button>

                                                            </div>
                                                            <div style={{ "width": "45%", "float": "right" }}>
                                                                <button
                                                                    className="gest_bton_popup"
                                                                    onClick={() => {
                                                                        //console.log('modal closed ');
                                                                        close();
                                                                    }}
                                                                >
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                        }
                                        <br />

                                    </div>

                                </div>}
                            <br />

                            {String(addOneParticipant) !== "1" && selected_code_session && selected_internal_url && <div id='ajout_participant' style={{ "textAlign": "left", "float": "left", "width": "100%" }}>

                                <Button variant="outlined" onClick={submenu_import_participant} className="detail_class_submenu bton_import_excel" style={{
                                    "background": "#c8cfd5",
                                    "color": "black", "width": "auto"
                                }}
                                    id='menu_import_participant' name='menu_import_participant'>Importer des participants excel &nbsp;
                                    <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                                </Button>
                                <Button variant="outlined" onClick={submenu_add_one_participant}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_one_participant' name='menu_one_participant'>Ajouter 1 participant &nbsp;
                                    <img src={participants} alt="ajout un participant" className="icon_plus" />
                                </Button>

                                <br />
                                <a href='/sample/template_import_stagiaires.csv' download>Télécharger un fichier modèle</a>
                                <br />

                                <i>{participant_file_name}</i><br />
                                {String(liste_participants_file_change_api) === String("true") && <nav className="okUpdateData"> Les participants ont été correctement importés</nav>}
                                {String(liste_participants_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_participants_file_change_message} </nav>}

                                <input type="file"
                                    accept=".csv"
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                    name="liste_participants_file"
                                    onChange={liste_participants_file_change}
                                />


                            </div>}

                        </div>}


                    </div>}


                    {String(submenu) === String("inscrit") && <div style={{ "border": "None" }}>
                        <div className="div_row" style={{ "border": "None" }}>

                            {String(addOneParticipant) !== "1" && selected_code_session && selected_internal_url && <div className="div_row" style={{ "border": "None" }}>

                                {String(addOneParticipant) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>
                                    <h5> Ajout d'un nouveau participant</h5>

                                    <div className="session_caract"> <b> Civilité </b><br />
                                        <TextField
                                            required
                                            name="one_civilite_part"
                                            //label="Civilité"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            select
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_one_civilite_part}
                                            onChange={(e) => setp_one_civilite_part(e.target.value)}

                                        >
                                            <MenuItem value="Mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                                            <MenuItem value="M." style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >M.  &nbsp;</MenuItem>
                                            <MenuItem value="Neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Neutre&nbsp;&nbsp;</MenuItem>


                                        </TextField>
                                    </div>


                                    <div className="session_caract"> <b> Nom </b><br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="one_nom_part"
                                            id="one_nom_part"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_one_nom_part}
                                            onChange={(e) => setp_one_nom_part(e.target.value)}
                                        />
                                    </div>


                                    <div className="session_caract"> <b> Prénom  </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="one_prenom_part"
                                            id="one_prenom_part"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_one_prenom_part}
                                            onChange={(e) => setp_one_prenom_part(e.target.value)}
                                        />
                                    </div>

                                    <div className="session_caract"> <b> Email </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="one_email_part"
                                            id="one_email_part"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_one_email_part}
                                            onChange={(e) => {
                                                setp_one_email_part(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="session_caract"> <b> Date Naissance </b><br />
                                        <DatePicker
                                            name="one_naissance_part"
                                            id="one_naissance_part"
                                            selected={new Date(moment(p_one_naissance_part, "DD/MM/YYYY"))}
                                            onChange={(date) => {
                                                setp_one_naissance_part(format(date, 'd/MM/yyyy'));

                                            }
                                            }
                                            showTimeSelect={false}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style enable_style"
                                            locale='fr-FR'

                                        />

                                    </div>

                                    <div className="session_caract"> <b> Téléphone </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="one_phone_part"
                                            id="one_phone_part"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_one_phone_part}
                                            onChange={(e) => setp_one_phone_part(e.target.value)}
                                        />
                                    </div>

                                    <div className="session_caract"> Statut Inscription <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            select
                                            value={myinscript_status}
                                            name="one_status_part"
                                            id="one_status_part"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={handleChangeInscription_Status}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                        >
                                            {status_inscription.map((option) => (
                                                <MenuItem key={option.value} value={option.value} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                    {option.label} &nbsp; <br />
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                    {/* -- début champs specifiques **/}
                                    <div className="div_row" style={{ "border": 'none' }}>
                                        <hr />
                                    </div>
                                    <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

                                        {rows_champs_specifics_stagiaire &&
                                            rows_champs_specifics_stagiaire.map((champ_spec) => (

                                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                                    <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle_stagiaire(e.target.value);
                                                            setparticipant_data_changed("1");
                                                        }}
                                                    //onChange={change_champs_spec_handle}


                                                    />
                                                </div>
                                            ))}

                                        <br />

                                    </div>
                                    {/* -- end  champs specifiques **/}

                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>
                                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                            <Button variant="contained" className="bton_envoyer" onClick={One_Create_Participant}>Enregistrer le participant
                                            </Button>
                                        </div>

                                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                            <Button variant="contained" className="bton_fermer" onClick={One_annuleAdd_Participant}>Annuler
                                            </Button>

                                        </div>

                                    </div>}
                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>



                                </div>}
                            </div>}

                            {String(addOneParticipant) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>
                                <div className="titre1"> Ajout d'un nouveau participant a  la session <u> {selected_code_session} </u> de la formation <u> {selected_class_title} </u></div>

                                <div className="session_caract"> <b> Civilité </b><br />
                                    <TextField
                                        required
                                        name="one_civilite_part"
                                        id="one_civilite_part"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        select
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_civilite_part}
                                        onChange={(e) => setp_one_civilite_part(e.target.value)}

                                    >
                                        <MenuItem value="Mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                                        <MenuItem value="M." style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >M.  &nbsp;</MenuItem>
                                        <MenuItem value="Neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Neutre&nbsp;&nbsp;</MenuItem>


                                    </TextField>
                                </div>



                                <div className="session_caract"> <b> Nom </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_nom_part"
                                        id="one_nom_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_nom_part}
                                        onChange={(e) => {
                                            setp_one_nom_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>


                                <div className="session_caract"> <b> Prénom </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_prenom_part"
                                        id="one_prenom_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_prenom_part}
                                        onChange={(e) => {
                                            setp_one_prenom_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>

                                <div className="session_caract"> <b> Email </b> <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_email_part"
                                        id="one_email_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_email_part}
                                        onChange={(e) => {
                                            setp_one_email_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>

                                <div className="session_caract"> <b> Date Naissance </b><br />
                                    <DatePicker
                                        name="one_naissance_part"
                                        id="one_naissance_part"
                                        selected={new Date(moment(p_one_naissance_part, "DD/MM/YYYY"))}
                                        onChange={(date) => {
                                            setp_one_naissance_part(format(date, 'd/MM/yyyy'));

                                        }
                                        }
                                        showTimeSelect={false}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                        locale='fr-FR'

                                    />

                                </div>

                                <div className="session_caract"> <b> Téléphone </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_phone_part"
                                        id="one_phone_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_one_phone_part}
                                        onChange={(e) => {
                                            setp_one_phone_part(e.target.value);
                                        }
                                        }
                                    />
                                </div>

                                <div className="session_caract"> Statut Inscription <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        select
                                        value={myinscript_status}
                                        name="one_status_part"
                                        id="one_status_part"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChangeInscription_Status}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                    >
                                        {status_inscription_add.map((option) => (
                                            <MenuItem key={option.value} value={option.value} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {option.label} &nbsp; <br />
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className="session_caract"> Client <br />
                                    <Autocomplete
                                        disablePortal
                                        name="one_detail_client_rattachement"
                                        id="one_detail_client_rattachement"
                                        className="disabled_style"
                                        options={New_Get_List_Partner_Clients_result}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_one_detail_client_rattachement_id(value._id);
                                                setp_one_detail_client_rattachement_nom(value.nom);

                                            }
                                        }}
                                        value={p_one_detail_client_rattachement_nom}

                                        renderInput={(params) => <TextField {...params} label="" />
                                        }
                                    />
                                </div>

                                <div className="session_caract"> Type apprenant <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_detail_type_apprenant"
                                        id="one_detail_type_apprenant"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_one_detail_type_apprenant}
                                        onChange={(e) => {
                                            setp_one_detail_type_apprenant(e.target.value);
                                        }
                                        }

                                    >
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Autre &nbsp;</MenuItem>
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Salariés &nbsp;</MenuItem>
                                        <MenuItem value="2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Apprentis &nbsp;</MenuItem>
                                        <MenuItem value="3" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Particuliers &nbsp;</MenuItem>
                                        <MenuItem value="4" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Rech. Emploi &nbsp;</MenuItem>

                                    </TextField>
                                </div>

                                {/* -- début champs specifiques **/}
                                <div className="div_row" style={{ "border": 'none' }}>
                                    <hr />
                                </div>
                                <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

                                    {rows_champs_specifics_stagiaire &&
                                        rows_champs_specifics_stagiaire.map((champ_spec) => (

                                            <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name={JSON.parse(champ_spec).field_name}
                                                    id={JSON.parse(champ_spec).field_name}

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}


                                                    className="disabled_style"
                                                    onChange={(e) => {
                                                        change_champs_spec_handle_stagiaire(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }}
                                                //onChange={change_champs_spec_handle}


                                                />
                                            </div>
                                        ))}

                                    <br />

                                </div>
                                {/* -- end  champs specifiques **/}

                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                        <Button variant="contained" className="bton_enreg" onClick={One_Create_Participant}>Enregistrer le participant
                                        </Button>
                                    </div>

                                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                        <Button variant="contained" className="bton_annule" onClick={One_annuleAdd_Participant}>Annuler
                                        </Button>

                                    </div>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>



                            </div>}
                        </div>

                        {String(addOneParticipant) !== "1" && <div className="session_data" style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                checkboxSelection
                                onSelectionModelChange={(newSelectionModel_insc) => {
                                    setSelectionModel_insc(newSelectionModel_insc);
                                    //console.log("ch selected--" + newSelectionModel_insc);
                                }}
                                selectionModel={selectionModel_insc}

                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={rowss_insc.map((item, index) => (
                                    {
                                        id: index,
                                        _id: JSON.parse(item)._id,
                                        nom: JSON.parse(item).nom,
                                        prenom: JSON.parse(item).prenom,
                                        employeur: JSON.parse(item).employeur,
                                        email: JSON.parse(item).email,
                                        amount: JSON.parse(item).price,
                                        opco: JSON.parse(item).opco,
                                        modefinancement: JSON.parse(item).modefinancement,
                                        civilite: JSON.parse(item).civilite,
                                        client_rattachement_nom: JSON.parse(item).client_rattachement_nom,

                                    }
                                ))}

                                columns={columns_inscrit}
                                pageSize={10}
                                className="datagridclass"

                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                getEstimatedRowHeight={() => 200}
                                getRowHeight={() => "auto"}
                                sx={{
                                    "& .MuiDataGrid-cellContent": {
                                        minHeight: 50,

                                    },
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}

                            />

                            {selectionModel_insc && selectionModel_insc.length >= 1 &&
                                <div className="block_en_mass">
                                    <nav className='traitement_mass'>Traitement en masse / Inscrit</nav>
                                    &nbsp;<select
                                        id={"actionmassinscr"} name={"actionmassinscr"}
                                        onChange={actionmass_inscrit}
                                        className="action_mass">
                                        <option selected value="n/a">Choisir une action</option>
                                        <option value="confirmation">Renvoyer une confirmation</option>
                                        <option value="impression">Imprimer fiches</option>
                                        <option value="annule inscription">Annuler inscription</option>

                                    </select> &nbsp;

                                    {actionmass_insc_val &&
                                        <Popup
                                            trigger={<Button className="bton_traiter_en_mass" >
                                                <FcAcceptDatabase /> Traiter

                                            </Button>}
                                            modal
                                            nested
                                            position="center center"
                                        >

                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}

                                                        Confirmer l'action <b> {actionmass_insc_val} </b> en masse.
                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {
                                                                actionmass_insc_Traitemet();
                                                                close();
                                                            }}> Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    }
                                    <br />


                                </div>

                            }

                            <br />

                            {String(addOneParticipant) !== "1" && selected_code_session && selected_internal_url && <div id='ajout_participant' style={{ "textAlign": "left", "float": "left", "width": "100%" }}>

                                <Button variant="outlined" onClick={submenu_import_participant} className="detail_class_submenu bton_import_excel" style={{
                                    "background": "#c8cfd5",
                                    "color": "black", "width": "auto"
                                }}
                                    id='menu_import_participant' name='menu_import_participant'>Importer des participants excel  &nbsp;
                                    <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                                </Button>
                                <Button variant="outlined" onClick={submenu_add_one_participant}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_one_participant' name='menu_one_participant'>Ajouter 1 participant &nbsp;
                                    <img src={participants} alt="ajout un participant" className="icon_plus" />
                                </Button>

                                <br />
                                <a href='/sample/template_import_stagiaires.csv' download>Télécharger un fichier modèle</a>
                                <br />

                                <i>{participant_file_name}</i><br />
                                {String(liste_participants_file_change_api) === String("true") && <nav className="okUpdateData"> Les participants ont été correctement importés</nav>}
                                {String(liste_participants_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_participants_file_change_message} </nav>}

                                <input type="file"
                                    accept=".csv"
                                    ref={hiddenFileInput}
                                    style={{ display: 'none' }}
                                    name="liste_participants_file"
                                    onChange={liste_participants_file_change}
                                />


                            </div>}


                        </div>}


                        {String(addOneParticipant) !== "1" && String(display_detail_insc) === "1" && selected_code_session && <div className="div_row block_detail_inscrit">
                            <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail inscrit </nav>
                            {String(GetAttendee_api) === "true" && <div className="div_row" style={{ "border": "None" }}>

                                {String(Resend_LMS_Credentials_api) === "true" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                    Les identifiants de connexion ont été correctement envoyés</div>}


                                {String(Resend_LMS_Credentials_api) === "false" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                    {Resend_LMS_Credentials_message}</div>}


                                <div className="div_row" style={{ "border": "None" }}>

                                    <div className="div_row" style={{ "border": "None" }}>
                                        <div className="div_row_gauche">

                                            <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                                                <div className="div_row_gauche_image">
                                                    <img class="img_class_logo" src={userimgclassprofil} /><br />

                                                </div>
                                                <div className="div_row_droite_image">
                                                    <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                                    {<nav>

                                                        <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                                        {String(userimgclassprofilchanged) === "1" &&
                                                            <nav className="bton_image_class" onClick={record_Stagiaire_Image}>Enregistrer la photo<br />  </nav>
                                                        }

                                                        {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                                            <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                                        }
                                                        {String(userimgclassprofilchanged) === "1" &&
                                                            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                                            </div>}


                                                    </nav>}

                                                    {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                                    {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                                    {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                                    {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                                                </div>

                                            </div>
                                            <div className="div_row_bis tips_img_class">
                                                <li>
                                                    L'image doit etre carrée.
                                                </li>
                                                <li>
                                                    Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                                                </li>
                                                <li>
                                                    Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                                    Les dimensions maximales sont de 256pixels X 256pixels
                                                </li>
                                                <li>
                                                    La taille de l'image ne doit pas dépasser 1 mega octet
                                                </li>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="session_data" >
                                        <div>
                                            {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> <b> Session </b><br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_session_class"
                                                    id="detail_session_class"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_session_class}


                                                />

                                            </div>}


                                            {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Session <br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_session_class"
                                                    id="detail_session_class"
                                                    select
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_session_class_id}
                                                    onChange={(e) => {
                                                        setp_detail_session_class(e.target.value);
                                                        setparticipant_data_changed("1");
                                                        setp_detail_session_class_id(e.target.value);
                                                        Dialog_1_handle_change_participant_session(" Attention, vous allez modifier la session d'affectation du participant");
                                                    }
                                                    }

                                                >
                                                    {Getall_TrainingSession_result &&
                                                        Getall_TrainingSession_result.map((mysession) => (
                                                            <MenuItem key={JSON.parse(mysession)._id} value={JSON.parse(mysession)._id}
                                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": '100%' }}>
                                                                {JSON.parse(mysession).code_session}&nbsp;&nbsp;

                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </div>}


                                            {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Client <br />
                                                <Autocomplete
                                                    disablePortal
                                                    name="one_detail_client_rattachement"
                                                    id="one_detail_client_rattachement"
                                                    className="disabled_style"
                                                    options={New_Get_List_Partner_Clients_result}
                                                    onChange={(event, value) => {
                                                        if (value && value._id) {
                                                            setp_detail_client_rattachement_id(value._id);
                                                            setp_detail_client_rattachement_nom(value.nom);
                                                            setparticipant_data_changed("1");
                                                            Dialog_1_handle_change_participant_session(" Attention, vous allez modifier le client de rattachement du participant");


                                                        }
                                                    }}
                                                    value={p_detail_client_rattachement_nom}

                                                    renderInput={(params) => <TextField {...params} label="" />
                                                    }
                                                />

                                            </div>}

                                            {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Client<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_client_rattachement"
                                                    id="detail_client_rattachement"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_client_rattachement_nom}
                                                />

                                            </div>}

                                            {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Statut<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_status_inscript"
                                                    id="detail_status_inscript"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_status_inscrit_label}
                                                />

                                            </div>}

                                            {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Statut<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_status_inscript"
                                                    id="detail_status_inscript"
                                                    select
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_status_inscrit}
                                                    onChange={(e) => {
                                                        setp_detail_status_inscrit(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }

                                                >
                                                    <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Inscrit &nbsp;&nbsp;</MenuItem>
                                                    <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Preinscrit &nbsp;</MenuItem>
                                                    <MenuItem value="-1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Annule &nbsp;</MenuItem>
                                                    <MenuItem value="2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Encours &nbsp;</MenuItem>
                                                </TextField>
                                            </div>}


                                            <div className="session_caract"> <b> Nom </b> <br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_nom"
                                                    id="detail_nom"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_nom}
                                                    onChange={(e) => {
                                                        setp_detail_nom(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }

                                                />
                                            </div>

                                            <div className="session_caract"> <b> Prénom </b><br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_prenom"
                                                    id="detail_prenom"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_prenom}
                                                    onChange={(e) => {
                                                        setp_detail_prenom(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div className="session_caract"> <b> Email </b><br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_mail"
                                                    id="detail_mail"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_mail}
                                                    onChange={(e) => {
                                                        setp_detail_mail(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div className="session_caract"> <b> Téléphone </b> <br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_tel"
                                                    id="detail_tel"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_tel}
                                                    onChange={(e) => {
                                                        setp_detail_tel(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>


                                            <div className="session_caract"> Adresse<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_adresse"
                                                    id="detail_adresse"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_adresse}
                                                    onChange={(e) => {
                                                        setp_detail_adresse(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="session_caract"> Code postal<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_code_postal"
                                                    id="detail_code_postal"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_code_postal}
                                                    onChange={(e) => {

                                                        setp_detail_code_postal(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="session_caract"> Ville r <br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_ville"
                                                    id="detail_ville"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    value={p_detail_ville}
                                                    onChange={(e) => {
                                                        setp_detail_ville(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                    className="disabled_style"

                                                />
                                            </div>


                                            <div className="session_caract">
                                                Date Inscription
                                                <DatePicker
                                                    name="detail_incr_date"
                                                    id="detail_incr_date"
                                                    selected={detailuser_date_inscription}

                                                    dateFormat="dd/MM/yyyy"
                                                    className="disabled_style"
                                                    locale='fr-FR'

                                                />
                                            </div>


                                            <div className="session_caract"> Cout <br />
                                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_cout"
                                                    id="detail_cout"
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{ min: "1", max: "999999", step: "1" }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_cout}
                                                    onChange={(e) => {
                                                        setp_detail_cout(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>

                                            <div className="session_caract"> Opco <br />
                                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_opco"
                                                    id="detail_opco"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}

                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_opco}
                                                    onChange={(e) => {
                                                        setp_detail_opco(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }}
                                                />
                                            </div>


                                            <div className="session_caract"> Mode Financement<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_mode_fin"
                                                    id="detail_mode_fin"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_mode_fin}
                                                    onChange={(e) => {
                                                        setp_detail_mode_fin(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />
                                            </div>


                                            <div className="session_caract"> Employeur<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="detail_employeur"
                                                    id="detail_employeur"

                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={false}
                                                    className="disabled_style"
                                                    value={p_detail_employeur}
                                                    onChange={(e) => {
                                                        setp_detail_employeur(e.target.value);
                                                        setparticipant_data_changed("1");
                                                    }
                                                    }
                                                />

                                            </div>

                                            {/* -- début champs specifiques **/}
                                            <div className="div_row" style={{ "border": 'none' }}>
                                                <hr />
                                            </div>
                                            <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

                                                {rows_champs_specifics_stagiaire &&
                                                    rows_champs_specifics_stagiaire.map((champ_spec) => (

                                                        <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                                            <TextField
                                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                                required
                                                                name={JSON.parse(champ_spec).field_name}
                                                                id={JSON.parse(champ_spec).field_name}

                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}


                                                                className="disabled_style"
                                                                onChange={(e) => {
                                                                    change_champs_spec_handle_stagiaire(e.target.value);
                                                                    setparticipant_data_changed("1");
                                                                }}
                                                            //onChange={change_champs_spec_handle}


                                                            />
                                                        </div>
                                                    ))}

                                                <br />

                                            </div>
                                            {/* -- end  champs specifiques **/}


                                            <div className="div_row">
                                                <div className="div_row" style={{ "border": "None" }}>
                                                    &nbsp;
                                                </div>
                                                {String(participant_data_changed) === "1" && String(participant_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                                    /!\ Pensez à enregistrer les modifications
                                                </div>}


                                                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                                    {String(participant_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                                        <Button variant="contained" className="bton_enreg" onClick={Update_One_StagiaireData}>Enregistrer les modifications
                                                        </Button>

                                                    </div>}

                                                    {String(participant_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                                        <Button variant="contained" className="bton_annule" onClick={AnnuleAttendeeDetailFields}>Annuler
                                                        </Button>
                                                    </div>}
                                                </div>


                                                {String(participant_data_edit_mode) !== "1" && <div className="div_row" style={{ "textAlign": 'right', 'marginRight': '15px' }}>



                                                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                                        <Button variant="contained" className="bton_edit" onClick={EnableAttendeeDetailFields}>Editer
                                                        </Button>
                                                    </div>

                                                </div>}


                                            </div>

                                            <div>
                                                <div className="div_row" style={{ "border": "None" }}>
                                                    <hr className="hr_break" />
                                                </div>

                                                <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                                    Pièces jointes

                                                </div>

                                                <div className="div_row" style={{ "padding": "5px" }}>

                                                    <div style={{ "fontSize": "12px" }}>
                                                        <label htmlFor="upload-photo">
                                                            <input
                                                                style={{ display: "none" }}
                                                                id="upload-photo"
                                                                name="upload-photo"
                                                                type="file"
                                                                onChange={sessions_file_change_1}
                                                            />

                                                            <Fab
                                                                color="secondary"
                                                                size="small"
                                                                component="span"
                                                                aria-label="add"
                                                                variant="extended"
                                                            >
                                                                <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                                            </Fab>

                                                        </label>
                                                    </div>
                                                    {file_1_name && file_1_name.name &&
                                                        <nav style={{ "fontSize": "12px" }}>
                                                            <div className="session_caract">Nom du fichier <br />
                                                                <TextField
                                                                    name="file_to_download_type_name"
                                                                    id="file_to_download_type_name"

                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}

                                                                    disabled={false}
                                                                    className="disabled_style enable_style"
                                                                    value={p_detail_one_file_to_download_type_name}
                                                                    onChange={(e) => {
                                                                        setp_detail_one_file_to_download_type_name(e.target.value);

                                                                    }}
                                                                />

                                                            </div>
                                                            <br />
                                                            <br />
                                                            Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                                                onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                                                        </nav>}

                                                    {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                                                    {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                                                </div>


                                                {file_1_name && file_1_name.name && <div className="div_row">

                                                    <div className="div_row_gauche">
                                                        <Button variant="contained" onClick={Record_All_PJ}
                                                            startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                                                    </div>


                                                </div>}

                                                {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                                                    <div className="div_row">
                                                        Liste des pièces jointes <br />
                                                        <div className="div_row">
                                                            {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                                                <div className="div_row_list_pj"  >
                                                                    <nav style={{ "color": "green", "cursor": "pointer" }} onClick={Download_one_attached_document} name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}> Télécharger &nbsp;<i> {(JSON.parse(val).file_business_object)}</i> </nav> <br />
                                                                    <Popup
                                                                        trigger={
                                                                            <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                                                        }
                                                                        modal
                                                                        nested
                                                                        position="center center"
                                                                    >
                                                                        {close => (
                                                                            <div>
                                                                                <button className="gest_close" onClick={close}>
                                                                                    &times;
                                                                                </button>
                                                                                <div className="gest_header"> MySy Information </div>
                                                                                <div className="gest_content">
                                                                                    {' '}

                                                                                    En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                                                </div>
                                                                                <div className="gest_actions">
                                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                                        <button className="gest_bton_popup" onClick={(event) => {

                                                                                            Delete_one_attached_document(event);
                                                                                            close();
                                                                                        }}
                                                                                            name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                                                        > Valider </button>

                                                                                    </div>
                                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                                        <button
                                                                                            className="gest_bton_popup"
                                                                                            onClick={() => {
                                                                                                //console.log('modal closed ');
                                                                                                close();
                                                                                            }}
                                                                                        >
                                                                                            Annuler
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Popup>

                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>

                                            {is_lms_class_code && <div>
                                                <div className="div_row" style={{ "border": "None" }}>
                                                    <hr className="hr_break" />
                                                </div>
                                                <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                                    Acces LMS

                                                </div>
                                                <div className="div_row" style={{ "border": "None" }}>
                                                    <div className="session_caract"> login LMS<br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                                            name="login_lms"
                                                            id="login_lms"
                                                            value={attendee_lms_login}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={true}
                                                            className="disabled_style"

                                                        />
                                                    </div>

                                                    <div className="session_caract"> Mot de passe LMS<br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="lms_pwd"
                                                            id="lms_pwd"
                                                            value="xxxxx"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={true}
                                                            className="disabled_style"

                                                        />
                                                    </div>
                                                    <div className="session_caract" style={{ "paddingTop": "15px" }}> &nbsp;<br />
                                                        <Button variant="contained" className="bton_enreg" onClick={Resend_LMS_Credentials} style={{ "width": "80%" }}> (R)envoyer les Identifiants
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>}


                                            <div className="div_row" style={{ "border": "None" }}>
                                                &nbsp;
                                            </div>



                                            <div className="div_row" style={{ "border": "None" }}>
                                                <hr className="hr_break" />
                                            </div>
                                        </div>

                                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                            Evaluation

                                        </div>

                                        <div className="div_row" style={{ "border": "None" }}>
                                            <div className="session_caract">
                                                Date Evaluation
                                                <DatePicker
                                                    name="date_evaluation"
                                                    id="date_evaluation"
                                                    selected={detailuser_date_evaluation}


                                                    dateFormat="dd/MM/yyyy"
                                                    className="disabled_style"
                                                    locale='fr-FR'


                                                />
                                            </div>

                                            <div className="session_caract"> Note Evaluation<br />
                                                <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="note_eval"
                                                    id="note_eval"
                                                    type="number"


                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{ min: "0", max: "5", step: "1", readOnly: true, }}

                                                    className="disabled_style"
                                                />
                                            </div>

                                            <div className="session_caract"> Evaluation<br />
                                                <Editor
                                                    onInit={(evt, editor) => editorRef_evaluation.current = editor}
                                                    initialValue={field_evaluation}
                                                    onKeyUp={editor_keyup}
                                                    disabled={true}

                                                    init={{
                                                        resize: false,

                                                        height: 100,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: false,
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                                    }}
                                                />



                                            </div>


                                        </div>


                                        <div className="div_row" style={{ "border": "None" }}>
                                            &nbsp;
                                        </div>

                                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                                {String(SendEvaluationEmail_api) === "false" && <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                    {SendEvaluationEmail_message}</div>}


                                                {String(SendEvaluationEmail_api) === "true" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                    La demande d'evaluation ete envoyée par email </div>}


                                                <Button variant="contained" className="bton_enreg" onClick={SendEvaluationEmail}>Envoi demande evaluation
                                                </Button>

                                            </div>

                                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                                <Button variant="contained" className="bton_edit" onClick={DownloadAttendeeDetail}>Imprimer fiche detaillée
                                                </Button>

                                            </div>

                                        </div>}
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <hr className="hr_break" />
                                        </div>

                                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                            Attestation

                                        </div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            {detailuser_date_certification && <div className="session_caract">
                                                Attestation delivrée le
                                                <DatePicker
                                                    name="certif_date"
                                                    id="certif_date"
                                                    selected={detailuser_date_certification}

                                                    dateFormat="dd/MM/yyyy"
                                                    className="disabled_style"
                                                    locale='fr-FR'


                                                />
                                            </div>}

                                            {!detailuser_date_certification && <div className="session_caract">
                                                Attestation NON délivrée encore
                                                <nav style={{ "visibility": "hidden" }}> <DatePicker
                                                    name="certif_date"
                                                    id="certif_date"
                                                    selected={detailuser_date_certification}

                                                    dateFormat="dd/MM/yyyy"
                                                    className="disabled_style"
                                                    locale='fr-FR'
                                                    style={{ "visibility": "hidden" }}

                                                />
                                                </nav>
                                            </div>}

                                        </div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            &nbsp;
                                        </div>

                                        {sessionChanged && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                            /!\ Pensez à enregistrer les modifications
                                        </div>}

                                        {!sessionChanged && String(CreateSessionFormation_api) === "true" &&
                                            <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                La session de formation à été bien mise à jour.
                                            </div>}

                                        {!sessionChanged && String(CreateSessionFormation_api) === "false" &&
                                            <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                {CreateSessionFormation_message}
                                            </div>}


                                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>


                                                {String(SendAttendeeAttestation_api) === "true" &&
                                                    <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                        L'attestation a été bien envoyée par email
                                                    </div>}

                                                {String(SendAttendeeAttestation_api) === "false" &&
                                                    <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                        {SendAttendeeAttestation_message}
                                                    </div>}


                                                {!detailuser_date_certification && <Button variant="contained" className="bton_enreg" onClick={SendAttendeeAttestation}>Delivrer l'attestation de formation
                                                </Button>}
                                            </div>

                                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                                <Button variant="contained" className="bton_edit" onClick={DownloadAttendeeAttestation} style={{ "textAlign": "center" }}>Imprimer l'attestation de formation
                                                </Button>




                                            </div>

                                        </div>}
                                        <div className="div_row" style={{ "border": "None" }}>
                                            &nbsp;
                                        </div>


                                    </div>


                                </div>

                            </div>}

                            {String(GetAttendee_api) === "false" && <div className="div_row koUpdateData">
                                {GetAttendee_message}
                            </div>}


                        </div>}

                    </div>}

                    {String(submenu) === String("emargement") && <div style={{ "border": "None" }}>

                        <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                            <Button className="bton_emarge" onClick={ConfirmInitListEmargement}>Initialiser la liste émargement</Button>
                            <Button className="bton_emarge" onClick={DownloadListEmargement}>Imprimer la liste émargement</Button>
                            <Button className="bton_emarge" onClick={ConfirmEnvoiListEmargement}>Envoyer les demandes d'émargement</Button><br />
                        </div>

                        <div className="session_data" style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                checkboxSelection
                                onSelectionModelChange={(newSelectionModel_emarg) => {
                                    setSelectionModel_emarg(newSelectionModel_emarg);
                                    //console.log("ch selected--" + newSelectionModel_emarg);

                                }}

                                selectionModel={selectionModel_emarg}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={rowss_emarg.map((item, index) => (
                                    {
                                        id: index,
                                        _id: JSON.parse(item)._id,
                                        nom: JSON.parse(item).nom,
                                        prenom: JSON.parse(item).prenom,
                                        email: JSON.parse(item).email,
                                        date: JSON.parse(item).date,
                                        matin: JSON.parse(item).matin,
                                        apresmidi: JSON.parse(item).apresmidi,

                                        sequence_start: JSON.parse(item).sequence_start,
                                        sequence_end: JSON.parse(item).sequence_end,
                                        is_present: JSON.parse(item).is_present,


                                    }
                                ))}

                                columns={columns_list_emargement}
                                pageSize={10}
                                className="datagridclass"

                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: GridToolbar,
                                }}


                            />
                            {selectionModel_emarg && selectionModel_emarg.length >= 1 &&
                                <div className="block_en_mass">
                                    <nav className='traitement_mass'>Traitement en masse / Emarge</nav>
                                    &nbsp;<select
                                        id={"actionmassemarg"} name={"actionmassemarg"}
                                        onChange={actionmass_emarg}
                                        className="action_mass">
                                        <option selected value="n/a">Choisir une action</option>
                                        <option value="demande emargement">Demande emargement</option>

                                    </select> &nbsp;

                                    {actionmass_emarg_val &&
                                        <Popup
                                            trigger={<Button className="bton_traiter_en_mass" >
                                                <FcAcceptDatabase /> Traiter

                                            </Button>}
                                            modal
                                            nested
                                            position="center center"
                                        >

                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}

                                                        Confirmer l'action <b> {actionmass_emarg_val} </b> en masse.
                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {
                                                                actionmass_emarge_Traitemet();
                                                                close();
                                                            }}> Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    }
                                    <br />


                                </div>
                            }

                            <br />
                            {/*<Button className="bton_emarge" onClick={ActionMassListEmargement}>Envoyer les demandes d'emargement</Button><br />
*/}


                        </div>

                    </div>}

                    {String(submenu) === String("historique") && <div style={{ "border": "None" }}>

                        <Module_Historique_Action related_collection="session_formation"
                            related_collection_recid={selected_session_id}
                            read_access={props.read_access}
                            write_access={props.write_access} />
                    </div>}

                    {String(submenu) === String("editique") && <div style={{ "border": "None" }}>

                       
                    </div>}

                </div>


            </div>


        </div >
    );
}

export default DisplayPartnerSession;

