import React, { useRef, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import Button from '@mui/material/Button';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { getCookie, setCookie } from 'react-use-cookie';
import { Alert } from "@mui/material";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import profileimg from "../mysy_img/MYSY-profil-2.png";
import Abonnement from "./Abonnement";
import { ContentPasteSearchOutlined, PropaneSharp, ResetTvRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RichTextEditor } from '@mantine/rte';
import { Editor } from '@tinymce/tinymce-react';
import { IoArrowUndoCircle } from "react-icons/io5";
import { GrUserAdmin, GrOrderedList } from "react-icons/gr";
import parse from 'html-react-parser';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import GestionAdministrative from "./GestionAdministrative";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import Autocomplete from '@mui/material/Autocomplete';

const AddClassManual = (props) => {
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL + "/myclass/api/add_partner_account/";
  const [inputs, setInputs] = useState({});

  const [mypublished, setmypublished] = useState("0");
  const [mypublished_label, setmypublished_label] = useState("-");

  const [mytrainingsupport, setmytrainingsupport] = useState("n/a");
  const [mytrainingsupport_label, setmytrainingsupport_label] = useState("-");

  const [mytrainingdurationunit, setmytrainingdurationunit] = useState("heure");


  const [mycpf, setmycpf] = useState("0");
  const [mycpf_label, setmycpf_label] = useState("Non");

  const [mycertif, setmycertif] = useState("0");
  const [mycertif_label, setmycertif_label] = useState("Non");

  const [mypresence, setmypresence] = useState("0");
  const [mypresence_label, setmypresence_label] = useState("Non");


  const [mydistance, setmydistance] = useState("0");
  const [mydistance_label, setmydistance_label] = useState("Non");


  const [importmessage, setimportmessage] = useState("");
  const [myApiResponse, setmyApiResponse] = useState("");
  const setText = "coucou";
  const [ShowModal, setShowModal] = useState(true);
  const [result, setResult] = useState("");
  const [isupdateclass, setisupdateclass] = useState(false);
  const [testval, settestval] = useState("");
  const [displayedinternalurl, setdisplayedinternalurl] = useState("");
  const [datamodification, setdatamodification] = useState("0");
  const [datelieu, setdatelieu] = useState("");

  const [plus_produit_enabled, setplus_produit_enabled] = useState("");
  const [mots_cle_enabled, setmots_cle_enabled] = useState("");
  const [zone_diffusion_enabled, setzone_diffusion_enabled] = useState("");


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmydistance(value);
    setdatamodification("1");
    setmyApiResponse();

  }

  const handleChangeSupportTraining = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmytrainingsupport(value);
    setdatamodification("1");

  }

  const handleChangeDurationUnit = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmytrainingdurationunit(value);
    setdatamodification("1");

  }

  const handleChangecpf = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmycpf(value);
    setdatamodification("1");

  }

  const handleChangecertif = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmycertif(value);
    setdatamodification("1");

  }

  const handleChangePresentiel = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setmypresence(value);
    setdatamodification("1");
  }

  const handleChangePublished = (event) => {
    const value = event.target.value;
    setmypublished(value);
    setdatamodification("1");


  }



  const handleSubmit = (event) => {
    event.preventDefault();
    //console.log(inputs);

  }
  const presentiel = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const support_training_list = [
    {
      value: 'video',
      label: 'Video',
    },
    {
      value: 'document',
      label: 'Document',
    },
    {
      value: 'réalité virtuelle',
      label: 'Réalité Virtuelle',
    },
    {
      value: 'mixte',
      label: 'Mixte',
    },
    {
      value: 'autre',
      label: 'Autre',
    },
    {
      value: 'n/a',
      label: 'n/a',
    },

  ];


  const New_support_training_list = [
    {
      value: 'video',
      label: 'Video',
    },
    {
      value: 'document',
      label: 'Document',
    },
    {
      value: 'réalité virtuelle',
      label: 'Réalité Virtuelle',
    },
    {
      value: 'mixte',
      label: 'Mixte',
    },
    {
      value: 'autre',
      label: 'Autre',
    },
    {
      value: 'n/a',
      label: 'n/a',
    },

  ];
  const duration_unit_list = [
    {
      value: 'heure',
      label: 'Heures',
    },

    {
      value: 'jour',
      label: 'Jours',
    },
    {
      value: 'semaine',
      label: 'Semaines',
    },
    {
      value: 'mois',
      label: 'Mois',
    },
    {
      value: 'user_rythme',
      label: 'Rythme stagiaire',
    }
  ];


  const New_duration_unit_list = [
    {
      value: 'heure',
      label: 'Heures',
    },

    {
      value: 'jour',
      label: 'Jours',
    },
    {
      value: 'semaine',
      label: 'Semaines',
    },
    {
      value: 'mois',
      label: 'Mois',
    },
    {
      value: 'user_rythme',
      label: 'Rythme stagiaire',
    }
  ];



  const publiee = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];



  function handleOnClose(event) {
    setShowModal(false);
  }



  function DeleteClass(event) {




    var code = document.getElementsByName("external_code")[0].value;
    var server_address = "127.0.0.1";
    //var server_address = "89.156.84.196";



    var formData = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append("class_internal_url", props.mytrainingclass['ref']);



    var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_Class_by_internal_url/";
    setLoading(true);
    fetch(
      myurl,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setimportmessage(result['message']);
        setmyApiResponse(result['status']);


        if (String(result['status']) === "true") {
          //alert(result['status']+"  -- "+myApiResponse+' mess = '+result['message']);
          document.getElementsByName("external_code")[0].value = "";
          document.getElementsByName("title")[0].value = "";

          //document.getElementsByName("objectif")[0].value = "";
          //document.getElementsByName("programme")[0].value = "";
          //document.getElementsByName("prerequis")[0].value = "";


          //document.getElementsByName("description2")[0].value = "";

          document.getElementsByName("distentiel")[0].value = "";
          document.getElementsByName("price")[0].value = "";
          document.getElementsByName("domaine")[0].value = "";
          document.getElementsByName("duree")[0].value = "";
          //document.getElementsByName("mots_cle")[0].value = "";
          //document.getElementsByName("plus_produit")[0].value = "";
          document.getElementsByName("url")[0].value = "";
          document.getElementsByName("lms_class_code")[0].value = "";
          document.getElementsByName("class_inscription_url")[0].value = "";
          document.getElementsByName("metier")[0].value = "";

          document.getElementsByName("note")[0].value = "";

          //document.getElementsByName("objectif")[0].value = "";
          //document.getElementsByName("programme")[0].value = "";
          //document.getElementsByName("prerequis")[0].value = "";
          alert(" La formation a été correctement supprimée.");
          history.push({
            pathname: "/Partner/",
            state: {
              current_pack: props.currentpack, local_sub_menu: 'affichage', price: "",
              period: "", new_pack: ""
            }
          });
          window.location.reload();
        } else if (String(result['status']) === "false") {
          alert(result['message']);
        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        }



      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        setmyApiResponse("false");
      });
  }

  const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

  const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
  const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
  const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
  function Getall_Training_Employee_No_Filter(event) {

    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



    axios.post(myurl, form).then(res => {

      setLoading(true);

      if (String(res.data.status) === String("true")) {
        setLoading(false);
        //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
        //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
        setGetall_Training_Employee_No_Filter_api("true");
        setGetall_Training_Employee_No_Filter_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x)._id;
          var local_nom = JSON.parse(x).nom;
          var local_prenom = JSON.parse(x).prenom;
          var local_ismanager = JSON.parse(x).ismanager;


          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "label": local_nom + " " + local_prenom,
            "nom": local_nom,
            "prenom": local_prenom,
            "ismanager": local_ismanager
          };
          new_data2.push(node);
        });

        if (new_data2.length > 0)
          setNew_Getall_Training_Employee_No_Filter_result(new_data2);

      }
      else {
        setGetall_Training_Employee_No_Filter_api("false");
        setGetall_Training_Employee_No_Filter_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
      setGetall_Training_Employee_No_Filter_api("false");
      alert(" Impossible de recuperer la liste des employés");
      //setmyApimyApiMessage("")
    })
  }


  useEffect(() => {

    Get_Partner_Object_Specific_Fields("myclass");
    Getall_Training_Employee_No_Filter();
    Get_List_Niveau_Formation();

    setfield_description("");
    setfield_objectif("");
    setfield_programme("");
    setfield_prerequis("");
    setfield_mots_cle("");
    setfield_plus_produit("");
    setfield_datelieu("");
    setfield_zone_diffusion("");
    setmyApiResponse();
    Get_List_Domaines();
    Get_List_Metiers();

    fillfield();
    if (props.mytrainingclass['class_external_code']) {
      getClassImage();
      //console.log("props.mytrainingclass['plus_produit'] = " + props.mytrainingclass['plus_produit']);
      setplus_produit_enabled(props.mytrainingclass['plus_produit']);
      setmots_cle_enabled(props.mytrainingclass['mots_cle']);
      setzone_diffusion_enabled(props.mytrainingclass['zone_diffusion']);
    }

    setdatamodification("0");



    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }, [props.mytrainingclass['ref'],]);


  const [field_description, setfield_description] = useState("");
  const [field_objectif, setfield_objectif] = useState("");
  const [field_programme, setfield_programme] = useState("");
  const [field_prerequis, setfield_prerequis] = useState("");
  const [field_pourqui, setfield_pourqui] = useState("");
  const [field_mots_cle, setfield_mots_cle] = useState("");
  const [field_plus_produit, setfield_plus_produit] = useState("");

  const [field_datelieu, setfield_datelieu] = useState("");
  const [field_zone_diffusion, setfield_zone_diffusion] = useState("");


  function fillfield(event) {
    setisupdateclass(true);
    // DisableTraining();
    var val = props.mytrainingclass['ref'];


    // si la reference n'est pas rempli, alors on fait de la creation
    // si non il s'agit  get pour un update/
    if (!val) {
      setisupdateclass(false);
      return;
    }


    var form = new FormData();
    //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("internal_url", val);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      if (String(res.data.status) === String("true")) {
        setLoading(false);
        //console.log(" In get_partner_class  res.data.status = " + res.data.status);
        //console.log(" In get_partner_class  res.data.message r_class = " + res.data.message);
        setmyApiResponse("True");
        setResult(res.data.message);


        var mylocaltraining = JSON.parse(res.data.message);


        if (mylocaltraining) {
          setdisplayedinternalurl(mylocaltraining.internal_url);
          document.getElementsByName("external_code")[0].value = mylocaltraining.external_code;
          document.getElementsByName("title")[0].value = mylocaltraining.title;

          if (mylocaltraining.objectif) {
            //document.getElementsByName("objectif")[0].value = mylocaltraining.objectif;
            //alert(" laaaaa ");
            setfield_objectif(mylocaltraining.objectif);
          }


          if (mylocaltraining.programme) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_programme(mylocaltraining.programme);
          }

          if (mylocaltraining.pourqui) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_pourqui(mylocaltraining.pourqui);
          }



          if (mylocaltraining.prerequis) {
            //document.getElementsByName("prerequis")[0].value = mylocaltraining.prerequis;
            setfield_prerequis(mylocaltraining.prerequis);
          }

          //document.getElementsByName("description2")[0].value = mylocaltraining.description;
          setfield_description(mylocaltraining.description);


          setp_formateur_label("-");
          if (mylocaltraining.formateur_id) {
            setp_formateur_id(mylocaltraining.formateur_id);
            setp_formateur_label(mylocaltraining.formateur_nom_prenom);
            //console.log(" #### mylocaltraining.formateur_nom_prenom = ", mylocaltraining.formateur_nom_prenom);

          } else {
            setp_formateur_id("");
            setp_formateur_label("-");

          }

          if (mylocaltraining.presentiel.distantiel) {
            setmydistance(mylocaltraining.presentiel.distantiel);
            var result2 = presentiel.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.presentiel.distantiel)))
            if (result2[0])
              setmydistance_label(result2[0].label);
          } else {
            setmydistance("0");
            setmydistance_label("Non");
          }


          if (mylocaltraining.presentiel.presentiel) {
            setmypresence(mylocaltraining.presentiel.distantiel);
            var result2 = presentiel.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.presentiel.presentiel)))
            if (result2[0])
              setmypresence_label(result2[0].label);
          } else {
            setmypresence("0");
            setmypresence_label("Non");
          }


          //setmypresence(mylocaltraining.presentiel.presentiel);

          if (mylocaltraining.published) {
            setmypublished(mylocaltraining.published);
            var result2 = publiee.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.published)))
            if (result2[0])
              setmypublished_label(result2[0].label);
          }
          else {
            setmypublished("0");
            setmypublished_label("Non");
          }

          if (mylocaltraining.cpf) {
            setmycpf(mylocaltraining.cpf);
            var result2 = cpf.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.cpf)))
            if (result2[0])
              setmycpf_label(result2[0].label);
          }
          else {
            setmycpf("0");
            setmycpf_label("Non");
          }


          if (mylocaltraining.support) {
            setmytrainingsupport(mylocaltraining.support);


            var result2 = New_support_training_list.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.support)))
            if (result2[0])
              setmytrainingsupport_label(result2[0].label);

          }
          else {
            setmytrainingsupport("n/a");
            setmytrainingsupport_label("Autre");
          }

          if (mylocaltraining.duration_unit) {
            setmytrainingdurationunit(mylocaltraining.duration_unit);
            //console.log(" ### duration_unit = ", mylocaltraining.duration_unit);
          }
          else {
            setmytrainingdurationunit("heure");
          }



          if (mylocaltraining.certif) {
            setmycertif(mylocaltraining.certif);
            var result2 = certif.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.certif)));


            if (result2[0]) {
              setmycertif_label(result2[0].label);

            }
          }
          else {
            setmycertif("0");
            setmycertif_label("Non");
          }

          if (mylocaltraining.class_level && String(mylocaltraining.class_level).trim() !== "") {
            setp_class_level(mylocaltraining.class_level);
            setp_class_level_label(mylocaltraining.class_level_description);

          }
          else {
            setp_class_level("2");
            setp_class_level_label("Aucune certification");
          }


          if (mylocaltraining.price)
            document.getElementsByName("price")[0].value = mylocaltraining.price;
          else
            document.getElementsByName("price")[0].value = "";


          if (mylocaltraining.domaine) {
            setmytrainingdomaine(mylocaltraining.domaine);
            //console.log(" ### mylocaltraining.domaine = ", mylocaltraining.domaine);
          }
          else
            setmytrainingdomaine("-");


          if (mylocaltraining.metier) {
            setmytrainingmetier(mylocaltraining.metier);
            //console.log(" ### mylocaltraining.metier = ", mylocaltraining.metier);
          }
          else
            setmytrainingmetier("-");



          if (mylocaltraining.duration)
            document.getElementsByName("duree")[0].value = mylocaltraining.duration;
          else
            document.getElementsByName("duree")[0].value = "";

          //document.getElementsByName("mots_cle")[0].value = "";
          if (mylocaltraining.mots_cle) {
            //document.getElementsByName("mots_cle")[0].value = mylocaltraining.mots_cle;
            setfield_mots_cle(mylocaltraining.mots_cle);
          }




          document.getElementsByName("note")[0].value = "";
          if (mylocaltraining.note)
            document.getElementsByName("note")[0].value = mylocaltraining.note;

          //document.getElementsByName("plus_produit")[0].value = "";
          if (mylocaltraining.plus_produit) {
            //document.getElementsByName("plus_produit")[0].value = mylocaltraining.plus_produit;
            setfield_plus_produit(mylocaltraining.plus_produit);
          }

          document.getElementsByName("url")[0].value = "";
          if (mylocaltraining.url)
            document.getElementsByName("url")[0].value = mylocaltraining.url;

          document.getElementsByName("lms_class_code")[0].value = "";
          if (mylocaltraining.lms_class_code)
            document.getElementsByName("lms_class_code")[0].value = mylocaltraining.lms_class_code;


          document.getElementsByName("class_inscription_url")[0].value = "";
          if (mylocaltraining.class_inscription_url)
            document.getElementsByName("class_inscription_url")[0].value = mylocaltraining.class_inscription_url;

          document.getElementsByName("class_banner_img_url")[0].value = "";
          if (mylocaltraining.img_banner_detail_class)
            document.getElementsByName("class_banner_img_url")[0].value = mylocaltraining.img_banner_detail_class;

          //document.getElementsByName("zone_diffusion")[0].value = "";
          if (mylocaltraining.zone_diffusion_str) {
            //document.getElementsByName("zone_diffusion")[0].value = mylocaltraining.zone_diffusion_str;
            document.getElementsByName("zone_diffusion_v2")[0].value = mylocaltraining.zone_diffusion_str;
          }

          /*
               * Update 22/10/2023 :
               Gestion des champs spécifiques. ils commencent tous par 'my_'
               */

          //console.log(" #### props.rows_champs_specifics = ", props.rows_champs_specifics);
          for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;

            //console.log(" field_name = ", field_name);

            if (mylocaltraining.hasOwnProperty(field_name)) {
              var local_valeur = mylocaltraining[String(field_name)]

              if (document.getElementById(String(field_name)))
                document.getElementById(String(field_name)).value = local_valeur;

              var new_val = { 'field_name': field_name, 'field_value': local_valeur }
              var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);

              if (johnIndex > -1) {
                spec_field_updated_values[johnIndex] = new_val;

              } else {
                spec_field_updated_values.push(new_val);
              }

            } else {

            }
          }

          setspec_field_updated_values_hooks(spec_field_updated_values);
          /*
                             *  end Update 22/10/2023 :
                             */

        }
      }
      else {
        //console.log(" In test  res.data.status = "+res.data.status);
        //console.log(" In test  res.data.message = "+res.data.message);
        setmyApiResponse("False");
        //setmyApimyApiMessage("")
      }


    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( mysearchtext = ', error);
      setmyApiResponse("False");
      //setmyApimyApiMessage("")
    })
  }



  const [title_limite, settitle_limite] = useState(90);

  const editorRef_description = useRef(null);
  const [editorRef_description_limite, seteditorRef_description_limite] = useState(1000);


  const editorRef_objectif = useRef(null);
  const [editorRef_objectif_limite, seteditorRef_objectif] = useState(1000);

  const editorRef_programme = useRef(null);
  const [editorRef_programme_limite, seteditorRef_programme] = useState(1000);

  const editorRef_prerequis = useRef(null);
  const editorRef_pourqui = useRef(null);
  const editorRef_mots_cle = useRef(null);
  const editorRef_plus_produit = useRef(null);
  const editorRef_zone_diffusion = useRef(null);
  const editorRef_date_lieu = useRef(null);


  function RecordTraining(event) {


    var code = document.getElementsByName("external_code")[0].value;

    if (code.length < 1) {
      alert(" Le Champ 'code' est obligatoire")
      return;
    }

    var title = document.getElementsByName("title")[0].value;
    if (String(title).length > title_limite) {
      alert(" Champ 'Titre', limite depassée !")
      return;
    }

    if (title.length < 1) {
      alert(" Le Champ 'Titre' est obligatoire")
      return;
    }


    var presentiel = mypresence;
    var distentiel = mydistance;
    var domaine = mytrainingdomaine;
    if (String(domaine).trim() === "-") {
      domaine = ""
    }
    var mypublier = mypublished;
    var niveau_ftion = p_class_level;
    var price = document.getElementsByName("price")[0].value;
    var duree = document.getElementsByName("duree")[0].value;
    var url = document.getElementsByName("url")[0].value;
    var lms_class_code = document.getElementsByName("lms_class_code")[0].value;
    var class_inscription_url = document.getElementsByName("class_inscription_url")[0].value;
    var mymetier = mytrainingmetier;
    if (String(mymetier).trim() === "-") {
      mymetier = ""
    }
    var mynote = document.getElementsByName("note")[0].value;
    var localmycpf = mycpf;
    var mycertif = mycertif;

    var trainingSupport = mytrainingsupport;
    var banniereImg = document.getElementsByName("class_banner_img_url")[0].value;


    var pourqui = "";
    if (editorRef_pourqui.current) {
      pourqui = editorRef_pourqui.current.getContent();
    }



    var objectif = "";
    if (editorRef_objectif.current) {
      objectif = editorRef_objectif.current.getContent();
      if (parse(objectif.replace(/(<([^>]+)>)/ig, '')).length > editorRef_objectif_limite) {
        alert(" Champ 'Objectif', limite depassée !")
        return;
      }
    }

    if (objectif.length < 1) {
      alert(" Le Champ 'Objectif' est obligatoire")
      return;
    }

    var programme = "";
    if (editorRef_programme.current) {
      programme = editorRef_programme.current.getContent();

      if (parse(programme.replace(/(<([^>]+)>)/ig, '')).length > editorRef_programme_limite) {
        alert(" Champ 'Programme', limite depassée !")
        return;
      }
    }

    var prerequis = "";
    if (editorRef_prerequis.current) {
      prerequis = editorRef_prerequis.current.getContent();
    }

    var description = "";
    if (editorRef_description.current) {
      description = editorRef_description.current.getContent();

      if (parse(description.replace(/(<([^>]+)>)/ig, '')).length > editorRef_description_limite) {
        alert(" Champ 'Description', limite depassée !")
        return;
      }
    }

    if (description.length < 1) {
      alert(" Le Champ 'Description' est obligatoire")
      return;
    }

    var mots_cle = "";
    if (editorRef_mots_cle.current) {
      mots_cle = editorRef_mots_cle.current.getContent();
    }

    var plus_produit = "";
    if (editorRef_plus_produit.current) {
      plus_produit = editorRef_plus_produit.current.getContent();
    }



    // Verification si il y a plus de 3 mot clés separés par des ";"
    mots_cle = mots_cle.replace(/(<([^>]+)>)/ig, '');
    mots_cle = mots_cle.replaceAll("&nbsp;", '');
    mots_cle = mots_cle.replaceAll(",", ";");


    var words = mots_cle.split(';');

    var traited_list_mot_cle = []
    for (let i = 0; i < words.length; i++) {
      //console.log(" ### alanlyse du mot  "+String(words[i]))
      if (words[i] && String(words[i]).length > 2 && String(words[i]).length < 50)
        traited_list_mot_cle.push(String(words[i]));
      else if (words[i] && (String(words[i]).length < 2 || String(words[i]).length > 50)) {
        alert(" Chaque mot clé doit faire entre 2 et 50 caractères. Le mot '" + String(words[i]) + "' est incorrecte");
        return;
      }

    }

    //console.log(" ## traited_list_mot_cle = ", traited_list_mot_cle);

    if (traited_list_mot_cle.length > 3) {
      alert(" Il a plus de 3 mots clés. le max autorisé est 3");
      return;
    }

    var list_mot_cle_string = traited_list_mot_cle.toString().replaceAll(",", ";");

    //console.log(" ## list_mot_cle_string = ", list_mot_cle_string);

    //return;


    var myurl = "";

    if (isupdateclass) {
      // Il s'agit d'une mise à jour
      myurl = process.env.REACT_APP_API_URL + "myclass/api/update_class/";
      //alert("Il s'agit d'une mise à jour");

    } else {
      // Il s'agit d'une creation 
      myurl = process.env.REACT_APP_API_URL + "myclass/api/create_class/";
      //alert(" Il s'agit d'une creation ");
    }


    //alert(" OK Enreg code = "+code+" distentiel = "+distentiel+" duree = "+duree);
    const formData = new FormData();

    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');

    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append('external_code', code);
    formData.append('title', title);

    formData.append('objectif', objectif);
    formData.append('programme', programme);
    formData.append('prerequis', prerequis);

    formData.append('description', description);
    formData.append('formateur_id', p_formateur_id);
    formData.append('presentiel', presentiel);
    formData.append('domaine', domaine);
    formData.append('distantiel', distentiel);
    formData.append('class_level', niveau_ftion);
    formData.append('price', price);
    formData.append('duration', duree);
    formData.append('duration_unit', mytrainingdurationunit);
    formData.append('url', url);
    formData.append('lms_class_code', lms_class_code);
    formData.append('class_inscription_url', class_inscription_url);
    formData.append('mots_cle', list_mot_cle_string);
    formData.append('plus_produit', plus_produit);
    formData.append('internal_url', displayedinternalurl);
    formData.append('zone_diffusion', "");
    formData.append('metier', mymetier);
    formData.append('note', mynote);

    formData.append('published', mypublier);
    formData.append('cpf', localmycpf);

    formData.append('certif', mycertif);

    formData.append('pourqui', pourqui);
    formData.append('support', trainingSupport);
    formData.append('img_banner_detail_class', banniereImg);
    formData.append('img_url', banniereImg);


    /*
             Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
           */
    for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
      var local_value = String(spec_field_updated_values_hooks[i].field_value);

      if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

        local_value = local_value.replaceAll(",", ".");

        if (isNaN(local_value)) {
          alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
          return;
        }

      }
      else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

      }
      if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
        if (String(local_value).trim().length <= 0) {
          alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
          return;
        }

      }

      formData.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
    }

    //console.log(" formData = "+formData);
    setLoading(true);

    fetch(
      myurl,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setLoading(false);
        setimportmessage(result['message']);
        setmyApiResponse(result['status']);
        setdatamodification("0");

        if (String(userimgclassprofilchanged) === "1")
          sendClassImage();

        //alert(result['status'] + "  -- " + myApiResponse + ' mess = ' + result['message']);

        if (String(result['status']) === "true") {
          alert(" La formation a été correctement ajoutée / mise à jour. Vous allez être redirigé vers la liste des formations.");
          history.push({
            pathname: "/Partner/",
            state: {
              current_pack: props.currentpack, local_sub_menu: 'affichage', price: "",
              period: "", new_pack: ""
            }
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        setmyApiResponse("false");
      });
  };

  function DataUpdated() {
    setdatamodification("1");
    setimportmessage();

  }


  const [mytrainingmetier, setmytrainingmetier] = useState("-");

  const [New_Get_List_Metiers_result, setNew_Get_List_Metiers_result] = useState([]);

  const [Get_List_Metiers_api, setGet_List_Metiers_api] = useState();
  const [Get_List_Metiers_message, setGet_List_Metiers_message] = useState();
  const [Get_List_Metiers_result, setGet_List_Metiers_result] = useState();
  function Get_List_Metiers() {
    var form = new FormData();
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_List_all_metier_formation/";
    setLoading(true);
    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);
        console.log(' IN Get_List_Metiers Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Metiers_api("true");
          setGet_List_Metiers_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {


            //---
            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_metier = JSON.parse(x).metier;
            var local_domaine = JSON.parse(x).domaine;


            //---
            var node = {
              "id": localid,
              "label": local_metier,
              "metier": local_metier,
              "domaine": local_domaine,

            };
            new_data2.push(node);
          });

          if (new_data2.length > 0) {
            setNew_Get_List_Metiers_result(new_data2);
            //console.log(" ### new_data2 ", new_data2);
            //console.log(" ### setNew_Get_List_Metiers_result ", New_Get_List_Metiers_result);
          }


        }
        else {
          setGet_List_Metiers_api("false");
          setGet_List_Metiers_message(data['message']);
        }

      }).catch((error) => {
        setLoading(false);
        console.error('Error Get_List_Metiers :', error);
        setGet_List_Metiers_api("false");
      });

  }


  const handleChangeMetierTraining = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmytrainingmetier(value);
    setdatamodification("1");

  }


  const [mytrainingdomaine, setmytrainingdomaine] = useState("-");

  const [New_Get_List_Domaines_result, setNew_Get_List_Domaines_result] = useState([]);

  const [Get_List_Domaines_api, setGet_List_Domaines_api] = useState();
  const [Get_List_Domaines_message, setGet_List_Domaines_message] = useState();
  const [Get_List_Domaines_result, setGet_List_Domaines_result] = useState();
  function Get_List_Domaines() {
    var form = new FormData();
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_List_domaine_formation_JSON/";

    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        //console.log(' IN Get_List_Domaines Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Domaines_api("true");
          setGet_List_Domaines_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {

            //---
            var localid = x.id;
            var local_domaine = x.domaine;


            //---
            var node = {
              "id": localid,
              "label": local_domaine,
              "domaine": local_domaine,

            };
            new_data2.push(node);
          });

          if (new_data2.length > 0)
            setNew_Get_List_Domaines_result(new_data2);



        }
        else {
          setGet_List_Domaines_api("false");
          setGet_List_Domaines_message(data['message']);
        }

      }).catch((error) => {
        console.error('Error Get_List_Domaines :', error);
        setGet_List_Domaines_api("false");
      });
  }

  const handleChangeDomaineTraining = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmytrainingdomaine(value);
    setdatamodification("1");

  }


  function alertactivecompte() {
    //alert(" ispending = ===== " + props.ispending);
    if (String(props.ispending) === String("1")) {
      alert(" Vous devez ajouter un mode de Paiement pour acceder à cette fonctionnalité");
      return;
    }
  }

  const [isimgclassSelected, setisimgclassSelected] = useState(false);
  const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);
  const [userimgclassprofil, setuserimgclassprofil] = useState();
  const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

  const imglogoclasschangeHandler = (event) => {


    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    //console.log(event.target.files);

    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;



    //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
    if (file_size > 1000000) {
      alert("L'image ne doit pas dépasser un 1 Méga octets");
      return;
    }
    setisimgclassSelected(event.target.files[0]);
    setisimgclassSelectedfile(true);

    setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
    setuserimgclassprofilchanged("1");
  };


  const [isimgclassdeleted, setisimgclassdeleted] = useState("");
  const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");

  function removeRecodedClassImage() {
    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("class_external_code", class_external_code);
    form.append("type", "partner");
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteRecodedClassImage/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {
        setLoading(false);

        //console.log(' removeRecodedClassImage : status:', result['status']);
        //console.log('removeRecodedClassImage : Success:', result['message']);

        setisimgclassSelected(false);

        if (String(result['status']) === String("true")) {
          setisimgclassdeleted("1");
          setuserimgclassprofil("");
        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          setisimgclassdeleted("0");
          setisimgclassdeleted_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassdeleted("0");
        setisimgclassdeleted_message(result['message']);
      });

  }

  const [isimgclassSaved, setisimgclassSaved] = useState("");
  const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
  function sendClassImage(e) {

    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var formData = new FormData();
    formData.append('File', isimgclassSelected);
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append("class_external_code", class_external_code);

    formData.append("type", "partner");
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/recordClassImage/",
      {
        method: 'POST',
        body: formData,
      }
    ).then((response) => response.json())
      .then((result) => {
        setLoading(false);
        //console.log('status:', result['status']);
        //console.log('Success:', result['message']);
        setisimgclassSelected(false);

        if (String(result['status']) == String("true")) {
          setisimgclassSaved("1");
        }
        else {
          setisimgclassSaved("0");
          setisimgclassSaved_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassSaved("0");
        setisimgclassSaved_message(result['message']);
      });
  };


  const [myclassimage, setmyclassimage] = useState();
  const [isclassimage, setisclassimage] = useState();

  function getClassImage(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedClassImage/";

    var class_external_code = props.mytrainingclass['class_external_code'];

    if (class_external_code.length < 3) {
      //console.log("Le code externe prend au moins 3 caractères");
      return;
    }


    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("type", "partner");
    form.append("class_external_code", class_external_code);

    setLoading(true);
    axios.post(myurl, form).then(res => {

      if (res.data.status != "False") {
        setLoading(false);
        //console.log(" getClassImage : In test  res.data.status = " + res.data.status);
        //console.log(" getClassImage: res.data.message.img = " + res.data.message);

        if (res.data.message.img.length > 0) {
          setisclassimage("True");
        }
        else {
          setisclassimage("False");
        }
        setisclassimage(res.data.message.img);
        var ch_img = "data:image/png;base64," + res.data.message.img;
        setuserimgclassprofil(ch_img);
      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setisclassimage("False");
        setuserimgclassprofil();
        //alert("4");
      }
    }).catch((error) => {
      setLoading(false);
      console.warn('getClassImage ee: Not good man :( getClassImage = ');
      setisclassimage("False");
      setuserimgclassprofil("");
    })

  }


  const [activepack, setactivepack] = useState();
  function ActivePackStandard() {

    history.push({
      pathname: "/Partner/",
      state: {
        current_pack: props.currentpack, local_sub_menu: 'checkout', price: "50",
        period: "mensuelle", new_pack: "standard"
      }
    });
    window.location.reload();
  }

  function ActivePackGold() {

    history.push({
      pathname: "/Partner/",
      state: {
        current_pack: props.currentpack, local_sub_menu: 'checkout', price: "50",
        period: "mensuelle", new_pack: "gold"
      }
    });
    window.location.reload();
  }



  function change_zone_diffsion() {
    setfield_zone_diffusion(document.getElementById("zone_diffusion_v2").value)
  }


  function UndoModif() {
    setfield_description("");
    setfield_objectif("");
    setfield_programme("");
    setfield_prerequis("");
    setfield_mots_cle("");
    setfield_plus_produit("");
    setfield_datelieu("");
    setfield_zone_diffusion("");
    seteditor_tochange("");


    fillfield();
    if (props.mytrainingclass['class_external_code']) {
      getClassImage();
      //console.log("props.mytrainingclass['plus_produit'] = " + props.mytrainingclass['plus_produit']);
      setplus_produit_enabled(props.mytrainingclass['plus_produit']);
      setmots_cle_enabled(props.mytrainingclass['mots_cle']);
      setzone_diffusion_enabled(props.mytrainingclass['zone_diffusion']);
    }

    setdatamodification("0");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }

  const [editor_tochange, seteditor_tochange] = useState("");
  function editor_keyup() {
    seteditor_tochange("1");
  }

  const cpf = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];


  const certif = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  function retourList() {
    props.closeTrainingForm();

  }

  const [isLoading, setLoading] = useState();

  // Gestion champs specifiques 

  /*
  *** ALGORITHME
  1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
  2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
     - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
  
  3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
  4 - Afficher en html les elements 'rows_champs_specifics'
  5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
  6 - On recuperer les valeur lors du click d'enregistrement.
  
  */


  const list_nom_champs_spec = []
  const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

  //const spec_champs_valeur = ""

  const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
  const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
  const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
  function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("object_name", local_collection_obj_metier);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";
    setLoading(true);
    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("true")) {
        setLoading(false);
        //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
        //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

        setGet_Partner_Object_Specific_Fields_api("true");
        setGet_Partner_Object_Specific_Fields_result(res.data.message);


        setrows_champs_specifics(res.data.message);


      }
      else {
        setGet_Partner_Object_Specific_Fields_api("false");
        setGet_Partner_Object_Specific_Fields_message(res.data.message);
        alert(res.data.message)
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
      setGet_Partner_Object_Specific_Fields_api("false");
      alert(" Impossible de recuperer la liste des collection et champs personnalisables");
      //setmyApimyApiMessage("")
    })
  }

  const [New_Get_List_Niveau_Formation_result, setNew_Get_List_Niveau_Formation_result] = useState([]);

  const [Get_List_Niveau_Formation_api, setGet_List_Niveau_Formation_api] = useState();
  const [Get_List_Niveau_Formation_message, setGet_List_Niveau_Formation_message] = useState();
  const [Get_List_Niveau_Formation_result, setGet_List_Niveau_Formation_result] = useState([]);
  function Get_List_Niveau_Formation() {
    var form = new FormData();
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Niveau_Formation/";
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    setLoading(true);
    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);
        //console.log(' IN Get_List_Niveau_Formation Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Niveau_Formation_api("true");
          setGet_List_Niveau_Formation_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {

            //---
            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_code = JSON.parse(x).code;
            var local_description = JSON.parse(x).description;


            //---
            var node = {
              "_id": local_id,
              "id": localid,
              "label": local_description,
              "description": local_description,
              "code": local_code,

            };
            new_data2.push(node);
          });

          if (new_data2.length > 0)
            setNew_Get_List_Niveau_Formation_result(new_data2);




        }
        else {
          setGet_List_Niveau_Formation_api("false");
          setGet_List_Niveau_Formation_message(data['message']);
          alert(data['message'])
        }

      }).catch((error) => {
        setLoading(false);
        console.error('Error Get_List_Niveau_Formation :', error);
        setGet_List_Niveau_Formation_api("false");
        alert(" Impossible de récupérer la liste des niveaux de formation");

      });

  }



  //const [rows_champs_specifics, setrows_champs_specifics] = useState([]);

  // Cas spécifique de cette page.
  const [rows_champs_specifics, setrows_champs_specifics] = useState(props.rows_champs_specifics);

  const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

  function afficher_champs_spec() {

    setdisplay_champs_specifics("1");
    //setrows_champs_specifics(spec_champs_valeur);

  }

  function findIndexByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {

      if (data[i][key] == value) {
        return i;
      }
    }
    return -1;
  }


  function findValueByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {
      if (data[i][key] == value) {

        var tmp_val = JSON.parse(data[i]);

        return tmp_val.field_value

      }
    }
    return "";
  }


  const spec_field_updated_values = []
  const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);


  function change_champs_spec_handle() {

    for (let i = 0; i < rows_champs_specifics.length; i++) {

      var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
      var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
      var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
      var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


      if (document.getElementsByName(String(field_name))[0]) {

        var field_value = document.getElementsByName(String(field_name))[0].value;
        var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
        var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
        if (johnIndex > -1) {
          spec_field_updated_values[johnIndex] = new_val;

        } else {
          spec_field_updated_values.push(new_val);
        }
      }

    }

    //setrows_champs_specifics(spec_champs_valeur);

    //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
    setspec_field_updated_values_hooks(spec_field_updated_values)
  }
  // -- end champs specifiques 


  const [p_formateur_id, setp_formateur_id] = useState("");
  const [p_formateur_label, setp_formateur_label] = useState("-");

  const [p_class_level, setp_class_level] = useState("2");
  const [p_class_level_label, setp_class_level_label] = useState("Aucune certification");


  return (
    <div className="addclassmanual">
      {isLoading && <div className="loader-container">
        <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
      </div>}

      {String(props.mytrainingclass.ref).trim() !== "" && <div className="div_row_ch">



        <div className="row_block zone_retour_liste" onClick={retourList}>

          <Button variant="contained" color="success" className="bton_retour_list">
            Retour liste formations &nbsp; <GrOrderedList />

          </Button>

        </div>

        <div className="row_block zone_gestion_admin">
          <Button variant="contained" color="success" className="bton_gestion_admin"

            onClick={(e) => {
              e.preventDefault();
              window.open(
                "/GestionAdministrative/" + displayedinternalurl,
                '_blank'
              );

            }}>

            GESTION ADMINISTRATIVE &nbsp;  <GrUserAdmin />
          </Button>
        </div>




      </div>}

      <div className="training_data" onChange={DataUpdated}>

        <div className="training_caract">
          <TextField
            required
            name="external_code"
            label="Code"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"

          />
        </div>

        <div className="training_caract">
          <TextField
            required
            name="title"
            label="Titre (max 90 caractères) "
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
          />
        </div>


        {/*<div className="training_caract">
          <TextField
            required
            name="formateur_id"
            label="Formateur"
            select
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
            value={p_formateur_id}
            onChange={(e) => {
              setp_formateur_id(e.target.value);
              DataUpdated();

            }
            }
          >
            {Getall_Training_Employee_No_Filter_result &&
              Getall_Training_Employee_No_Filter_result.map((myclient) => (
                <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                  style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                  {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                </MenuItem>
              ))}
          </TextField>
        </div>*/}


        <div className="training_caract">
          <Autocomplete
            disablePortal
            name="formateur_id"
            id="formateur_id"
            className="disabled_style"
            options={New_Getall_Training_Employee_No_Filter_result}
            value={p_formateur_label}
            onChange={(event, value) => {
              if (value && value._id) {
                setp_formateur_id(value._id);
                setp_formateur_label(value.label)

                DataUpdated();

              }
            }}

            renderInput={(params) => <TextField {...params} label="Formateur" />
            }
          />



        </div>

        {/*String(datamodification) === "1" && <div className="training_caract">
          <TextField
            required
            name="formateur_label"
            label="Formateur"
            
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
            value={p_formateur_label}

          />

          </div>*/}


        <div className="training_caract">

          <Autocomplete
            disablePortal
            name="certif"
            id="certif"
            className="disabled_style"
            options={cpf}
            value={mycpf_label}
            onChange={(event, value) => {
              if (value && value.value) {

                setmycpf(value.value);
                setmycpf_label(value.label);
                setdatamodification("1");

              }
            }}

            renderInput={(params) => <TextField {...params} label="Eligible CPF" />
            }
          />

          {/* <TextField
            name="cpf"
            select
            label="Eligible CPF"
            value={mycpf}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangecpf}
            disabled={false}
            className="disabled_style"
          >
            {cpf.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label} &nbsp; <br />
              </MenuItem>
            ))}
            </TextField> */}
        </div>

        <div className="training_caract">

          <Autocomplete
            disablePortal
            name="certif"
            id="certif"
            className="disabled_style"
            options={certif}
            value={mycertif_label}
            onChange={(event, value) => {
              if (value && value.value) {

                setmycertif(value.value);
                setmycertif_label(value.label);
                setdatamodification("1");

              }
            }}

            renderInput={(params) => <TextField {...params} label="Certification" />
            }
          />

          {/*
          <TextField
            name="certif"
            select
            label="Certification"
            value={mycertif}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangecertif}
            disabled={false}
            className="disabled_style"
          >
            {certif.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label} &nbsp; <br />
              </MenuItem>
            ))}
          </TextField>
            */}
        </div>

        <div className="training_caract">
          <Autocomplete
            disablePortal


            name="class_level"
            id="class_level"
            className="disabled_style"
            options={New_Get_List_Niveau_Formation_result}
            value={p_class_level_label}
            onChange={(event, value) => {
              if (value && value.code) {
                setp_class_level(value.code);
                setp_class_level_label(value.description)
                DataUpdated();

              }
            }}

            renderInput={(params) => <TextField {...params} label="Niveau (2 à 8)" />
            }
          />
        </div>


        <div className="training_caract">

          <Autocomplete
            disablePortal
            name="published"
            id="published"
            className="disabled_style"
            options={publiee}
            value={mypublished_label}
            onChange={(event, value) => {
              if (value && value.value) {
                console.log(" value = ", value);
                setmypublished(value.value);
                setmypublished_label(value.label);
                setdatamodification("1");

              }
            }}

            renderInput={(params) => <TextField {...params} label="Publié" />
            }
          />


        </div>

        <div className="training_caract">

          <Autocomplete
            disablePortal
            name="presentiel"
            id="presentiel"
            className="disabled_style"
            options={presentiel}
            value={mydistance_label}
            onChange={(event, value) => {
              if (value && value.value) {

                setmydistance(value.value);
                setmydistance_label(value.label);
                setdatamodification("1");

              }
            }}

            renderInput={(params) => <TextField {...params} label="Présentiel" />
            }
          />

          {/*<TextField
            name="presentiel"
            select
            label="Présentiel"
            value={mypresence}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangePresentiel}
            disabled={false}
            className="disabled_style"
          >
            {presentiel.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label} &nbsp; <br />
              </MenuItem>
            ))}
            </TextField>*/}
        </div>

        <div className="training_caract">

          <Autocomplete
            disablePortal
            name="distentiel"
            id="distentiel"
            className="disabled_style"
            options={presentiel}
            value={mypresence_label}
            onChange={(event, value) => {
              if (value && value.value) {

                setmypresence(value.value);
                setmypresence_label(value.label);
                setdatamodification("1");

              }
            }}

            renderInput={(params) => <TextField {...params} label="A distance" />
            }
          />
          {/*<TextField
            name="distentiel"
            select
            label="A distance"
            value={mydistance}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
            disabled={false}
            className="disabled_style"
          >
            {presentiel.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label} &nbsp; <br />
              </MenuItem>
            ))}
            </TextField>**/}
        </div>


        <div className="training_caract">

          <Autocomplete
            disablePortal

            name="training_support"
            id="training_support"
            className="disabled_style"
            options={New_support_training_list}

            value={mytrainingsupport_label}
            onChange={(event, value) => {
              if (value && value.value) {

                setmytrainingsupport(value.value);
                setdatamodification("1");
                setmytrainingsupport_label(value.label)

              }
            }}

            renderInput={(params) => <TextField {...params} label="Support de formation" />
            }
          />
          {/*  <TextField
            name="training_support"
            id="training_support"
            select
            label="Support de formation"
            value={mytrainingsupport}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeSupportTraining}
            disabled={false}
            className="disabled_style"
          >
            {support_training_list.map((option) => (
              <MenuItem key={option.value} value={option.value} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", }}>
                {option.label} &nbsp;
              </MenuItem>
            ))}
            </TextField>*/}
        </div>


        {/*} <div className="training_caract">
          <TextField
            required
            select
            name="domaine"
            label="Domaine"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
            onChange={handleChangeDomaineTraining}
            value={mytrainingdomaine}
          >
            {Get_List_Domaines_result && Get_List_Domaines_result.map((option) => (
              <MenuItem key={option} value={option} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", }}>
                {option} &nbsp; <br />
              </MenuItem>
            ))}
          </TextField>

        </div>*/}

        <div className="training_caract">
          <Autocomplete
            disablePortal

            name="domaine"
            id="domaine"
            className="disabled_style"
            options={New_Get_List_Domaines_result}
            value={mytrainingdomaine}
            onChange={(event, value) => {
              if (value && value.domaine) {
                setmytrainingdomaine(value.domaine);
                setmytrainingmetier("-");
                setdatamodification("1");
              } else {
                setmytrainingdomaine("-");
                setmytrainingmetier("-");
              }
            }}

            renderInput={(params) => <TextField {...params} label="Domaine" />
            }
          />

        </div>

        {mytrainingdomaine && String(mytrainingdomaine).trim() !== "-" && <div className="training_caract">
          <Autocomplete
            disablePortal

            name="metier"
            id="metier"
            className="disabled_style"
            options={New_Get_List_Metiers_result.filter((local_mymanager) => local_mymanager.domaine === String(String(mytrainingdomaine).trim()))}
            value={mytrainingmetier}
            onChange={(event, value) => {


              if (value && value.metier) {

                setmytrainingmetier(value.metier);
                setdatamodification("1");
              }
            }}

            renderInput={(params) => <TextField {...params} label="Métier" />
            }
          />

        </div>}

        {(!mytrainingdomaine || String(mytrainingdomaine).trim().length <= 0 || String(mytrainingdomaine).trim() === "-") && <div className="training_caract">
          <Autocomplete
            disablePortal

            name="metier"
            id="metier"
            className="disabled_style"
            options={New_Get_List_Metiers_result}
            value={mytrainingmetier}
            onChange={(event, value) => {


              if (value && value.metier) {

                setmytrainingmetier(value.metier);
                setdatamodification("1");
              }
            }}

            renderInput={(params) => <TextField {...params} label="Métier" />
            }
          />

        </div>}

        {/*<div className="training_caract">
          <TextField
            name="metier"
            label="metier"
            select
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
            onChange={handleChangeMetierTraining}
            value={mytrainingmetier}
          >
            {Get_List_Metiers_result && Get_List_Metiers_result.map((option) => (
              <MenuItem key={option} value={option} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", }}>
                {option} &nbsp; <br />
              </MenuItem>
            ))}
          </TextField>
        </div>*/}

        <div className="training_caract">
          <TextField
            name="price"
            label="Prix"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
          />
        </div>

        <div className="training_caract">

          <TextField
            name="duree"
            label="Duree"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
          />
        </div>
        <Tooltip className="tooltip_css" id="my-tooltip11" />
        <a data-tooltip-id="my-tooltip11" data-tooltip-html="Les durée autorisées sont : 'heure', 'jour', 'semaine', 'mois', 'annee', 'rythme participant'">
          <div className="training_caract">
            <Autocomplete
              disablePortal


              name="duration_unit"
              id="duration_unit"
              className="disabled_style"
              options={New_duration_unit_list}
              value={mytrainingdurationunit}
              onChange={(event, value) => {
                if (value && value.value) {

                  setmytrainingdurationunit(value.value);
                  setdatamodification("1");

                }
              }}

              renderInput={(params) => <TextField {...params} label="Unité de durée" />
              }
            />

            {/*   <TextField
              name="duration_unit"
              id="duration_unit"
              select
              label="Unité de durée"
              value={mytrainingdurationunit}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeDurationUnit}
              disabled={false}
              className="disabled_style"
            >

              {duration_unit_list.map((option) => (
                <MenuItem key={option.value} value={option.value} >
                  {option.label} &nbsp; <br />
                </MenuItem>
              ))}

            </TextField> */}
          </div>
        </a>

        <div className="training_caract">
          <TextField
            name="url"
            label="site web"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
          />
        </div>

        <div className="training_caract">
          <TextField
            name="lms_class_code"
            label="Code LMS"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
          />
        </div>


        <div className="training_caract">
          <TextField
            name="class_inscription_url"
            label="Page d'inscription"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={false}
            className="disabled_style"
          />
        </div>

       

          <div className="training_caract">
          <Tooltip className="tooltip_css" id="tooltip_img_profil_01" />
        <a data-tooltip-id="tooltip_img_profil_01" data-tooltip-html="L'image de profil de la formation. <br/>L'ordre de priorité d'affichage des images est : <br/>
        1 - Image Bannière <br/>
        2 - L'image carrée téléchargéeen bas de page <br/>
        3 - L'image par defaut du métier dans le système
         ">
            <TextField
              name="class_banner_img_url"
              id="class_banner_img_url"
              label="Image Bannière"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={false}
              className="disabled_style"
            />
            </a>
          </div>
        

        <div className="training_caract">
          <TextField
            name="note"
            id="note"
            label="Note"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ min: "1", max: "5", step: "0.5" }}
            disabled={false}
            className="disabled_style"
          />
        </div>

        {/* -- début champs specifiques **/}
        <div className="div_row" style={{ "border": 'none' }}>
          <hr />
        </div>
        <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

          {rows_champs_specifics &&
            rows_champs_specifics.map((champ_spec) => (

              <div className="training_caract">
                {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                  name={JSON.parse(champ_spec).field_name}
                  id={JSON.parse(champ_spec).field_name}
                  label={JSON.parse(champ_spec).field_label}
                  type="number"
                  inputProps={{ min: "1", max: "999999", step: "1" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={false}
                  className="disabled_style"
                  onChange={(e) => {
                    change_champs_spec_handle(e.target.value);
                    //setEmployee_data_changed("1");
                  }}
                />}

                {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                  name={JSON.parse(champ_spec).field_name}
                  id={JSON.parse(champ_spec).field_name}
                  label={JSON.parse(champ_spec).field_label}
                  type="text"

                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={false}
                  className="disabled_style"
                  onChange={(e) => {
                    change_champs_spec_handle(e.target.value);
                    //setEmployee_data_changed("1");
                  }}
                />}

              </div>
            ))}

          <br />

        </div>
        {/* -- end  champs specifiques **/}

      </div>
      <div style={{ "width": "100%" }}>
        {String(datamodification) === String("1") && <div className="parter_div_filtrer_row">
          <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
        </div>}

        {String(props.ispending) === String("0") && <div className="parter_div_filtrer_row">


          {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) && <div className="parter_div_filtrer_row_gauche" >
            <Button variant="contained" onClick={RecordTraining} startIcon={<AiTwotoneSave />} className="bton_enreg2">ENREGISTRER LA FORMATION </Button>
          </div>
          }

          {/*String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" >
          <Button variant="contained" onClick={"ModifTraining"} startIcon={<AiTwotoneEdit />}>MODIFIER </Button>
        </div>
      */ }



          {String(datamodification) !== String("1") && String(editor_tochange) !== String("1") && String(props.mytrainingclass.ref) !== "" &&
            <div className="parter_div_filtrer_row_droite" >

              <Popup
                trigger={
                  <Button variant="contained" startIcon={<DeleteIcon />} className="bton_suppr2">   SUPPRIMER LA FORMATION </Button>

                }
                modal
                nested
                position="center center"
              >
                {close => (
                  <div>
                    <button className="gest_close" onClick={close}>
                      &times;
                    </button>
                    <div className="gest_header"> MySy Information </div>
                    <div className="gest_content">
                      {' '}

                      En confirmant cette opération, la formation sera <i><font color="red"> définitivement supprimée</font></i>. <br />
                      Avant, assurez-vous de n'avoir aucune session rattachée à la formation.

                    </div>
                    <div className="gest_actions">
                      <div style={{ "width": "45%", "float": "left" }}>
                        <button className="gest_bton_popup" onClick={(event) => {
                          DeleteClass(event);
                          //console.log('modal closed ');
                          close();
                        }}> Valider </button>

                      </div>
                      <div style={{ "width": "45%", "float": "right" }}>
                        <button
                          className="gest_bton_popup"
                          onClick={() => {
                            console.log('modal closed ');
                            close();
                          }}
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>

            </div>
          }


          {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) && String(props.mytrainingclass.ref) !== "" && <div className="parter_div_filtrer_row_droite" >
            <Button variant="contained" onClick={UndoModif} startIcon={<IoArrowUndoCircle />} className="bton_suppr2">   ANNULER LES MODIFICATIONS </Button>

          </div>}

        </div>}

        {String(props.ispending) !== String("0") && <div className="parter_div_filtrer_row">

          datamodification = {datamodification}
          {String(datamodification) === String("1") && <div className="parter_div_filtrer_row_gauche bton_enreg2" onClick={alertactivecompte} >
            <AiTwotoneSave />ENREGISTRER
          </div>
          }


          {String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" onClick={alertactivecompte}>
            <AiTwotoneEdit />MODIFICATION
          </div>
          }


          <div className="parter_div_filtrer_row_droite" >
            <Button variant="outlined" color="error" onClick={alertactivecompte} startIcon={<DeleteIcon />} className="bton_suppr2">   Supprimer</Button>

          </div>

        </div>}


        {String(myApiResponse) === String("true") && importmessage &&
          <div className="parter_div_filtrer_row okUpdateData">
            {importmessage}

          </div>}

        {String(myApiResponse) === String("false") &&
          <div className="parter_div_filtrer_row koUpdateData">
            {importmessage}

          </div>}
      </div>


      <div className="training_data">

        <div className="training_text">
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Description * (max 1000 caractères) </div>
            <Editor
              onInit={(evt, editor) => editorRef_description.current = editor}
              initialValue={field_description}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {

                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated()
                  seteditor_tochange("");
                }
              }}

              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>

        <div className="training_text" >
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Objectif* (max 1000 caractères) </div>
            <Editor
              onInit={(evt, editor) => editorRef_objectif.current = editor}
              initialValue={field_objectif}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {
                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated();
                  seteditor_tochange("");
                }
              }}
              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>

        <div className="training_text">
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Programme (max 1000 caractères) </div>
            <Editor
              onInit={(evt, editor) => editorRef_programme.current = editor}
              initialValue={field_programme}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {
                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated();
                  seteditor_tochange("");
                }
              }}
              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>


        <div className="training_text">
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Pré-requis</div>
            <Editor
              onInit={(evt, editor) => editorRef_prerequis.current = editor}
              initialValue={field_prerequis}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {
                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated();
                  seteditor_tochange("");
                }
              }}
              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>

        <div className="training_text">
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Pour Qui ?</div>
            <Editor
              onInit={(evt, editor) => editorRef_pourqui.current = editor}
              initialValue={field_pourqui}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {
                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated();
                  seteditor_tochange("");
                }
              }}
              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>

        <div className="training_text">
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Mots clés</div>
            <Editor
              onInit={(evt, editor) => editorRef_mots_cle.current = editor}
              initialValue={field_mots_cle}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {
                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated();
                  seteditor_tochange("");
                }
              }}
              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>

        <div className="training_text">
          <div className="plus_produit_desabled">
            <div className="titre_training_text"> Plus produit</div>
            <Editor
              onInit={(evt, editor) => editorRef_plus_produit.current = editor}
              initialValue={field_plus_produit}
              onKeyUp={editor_keyup}
              onEditorChange={(newValue, editor) => {
                if (String(editor_tochange) === "1") {
                  //alert(" change");
                  DataUpdated();
                  seteditor_tochange("");
                }
              }}
              init={{
                resize: false,
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],

                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
              }}
            />

          </div>
        </div>

        {/*<div className="training_text">
          <div className="plus_produit_desabled" onChange={DataUpdated}>
            <div className="titre_training_text"> Zone de diffusion</div>
            <textarea style={{ "width": "100%", "resize": "none", "fontSize": "13px", "height": "150px" }}
              name="zone_diffusion_v2"
              id="zone_diffusion_v2"
              onChange={change_zone_diffsion}
              rows={3}
            />

          </div>
        </div> */}

        {/* <div className="training_text">
          <div className="plus_produit_desabled" onChange={DataUpdated}>
            <div className="titre_training_text"> Date et lieu de formation</div>
            <textarea style={{ "width": "100%", "resize": "none", "fontSize": "13px", "height": "150px" }}
              name="datelieu_v2"
              id="datelieu_v2"
              onChange={change_date_lieu}
              rows={3}
            />

          </div>
        </div> */}

      </div>

      &nbsp;
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        onChange={DataUpdated}
      >
        <div>

          {(String(props.currentpack).toLocaleLowerCase() === String("gold") ||
            String(props.currentpack).toLocaleLowerCase() === String("mesure"))
            && <div style={{ "width": "100%", "marginBottom": "1rem" }}>


            </div>}



          {String(props.currentpack).toLocaleLowerCase() === String("decouverte") && <div style={{ "width": "100%", "marginBottom": "1rem" }}>

            <nav className="new_zone">
              <TextField
                name="mots_cle"
                id="mots_cle"
                label="Mots clés"
                type="text"
                multiline
                maxRows={4}
                rows={4}

                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
                className="plus_produit_desabled2"
                value=""
              />
              <nav className="plus_produit_activation" onClick={ActivePackStandard}> Passer au Standard</nav>
            </nav>


            {/*  <nav className="new_zone">
              <TextField
                name="zone_diffusion"
                label="Zone Diffusion (FR-Marseille;FR-Paris;etc)"
                type="text"
                multiline
                maxRows={4}
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
                className="plus_produit_desabled2"
                value=""
              />
              <nav className="plus_produit_activation" onClick={ActivePackGold}> Passer au Gold </nav>
            </nav>*/}

            <nav className="new_zone">
              <TextField
                name="plus_produit"
                label="Plus Produit"
                type="text"
                multiline
                maxRows={4}
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
                className="plus_produit_desabled2"
                value=""
              />
              <nav className="plus_produit_activation" onClick={ActivePackStandard}> Passer au Standard</nav>
            </nav>

          </div>
          }

          {String(props.currentpack).toLocaleLowerCase() === String("standard") &&
            <div >

              {/*  <nav className="new_zone2">
                <TextField
                  name="zone_diffusion"
                  label="Zone Diffusion (FR-Marseille;FR-Paris;etc)"
                  type="text"
                  multiline
                  maxRows={4}
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  className="plus_produit_desabled2"
                  value=""
                />
                <nav className="plus_produit_activation_no_float" onClick={ActivePackGold}> Passer au Gold </nav>
              </nav>
*/}

              <nav >
                &nbsp;
              </nav>



            </div>
          }


          <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >



            <Tooltip className="tooltip_css" id="tooltip_img_profil" />
            <a data-tooltip-id="tooltip_img_profil"  data-tooltip-html="L'image de profil de la formation. <br/>L'ordre de priorité d'affichage des images est : <br/>
        1 - Image Bannière <br/>
        2 - L'image carrée téléchargéeen bas de page <br/>
        3 - L'image par defaut du métier dans le système
         ">
              <div className="div_row_gauche_image"> Image de profil <br />
                <img class="img_class_logo" src={userimgclassprofil} /><br />

              </div>
            </a>
            <div className="div_row_droite_image">
              <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /> <br />
              <label for="files_img_class" className="bton_image_class">Charger une image</label><br />
              <nav className="bton_supprime_image_class" onClick={removeRecodedClassImage}>Supprimer l'image</nav><br />
              {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
              {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
              {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
              {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
            </div>

          </div>
          <div className="div_row_bis tips_img_class">
            <li>
              L'image doit etre carrée.
            </li>
            <li>
              Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
            </li>
            <li>
              Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
              Les dimensions maximales sont de 256pixels X 256pixels
            </li>
            <li>
              La taille de l'image ne doit pas dépasser 1 mega octet
            </li>
          </div>
        </div>

      </Box>

      {String(datamodification) === String("1") && <div className="parter_div_filtrer_row">
        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
      </div>}

      {String(props.ispending) === String("0") && <div className="parter_div_filtrer_row">


        {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) && <div className="parter_div_filtrer_row_gauche" >
          <Button variant="contained" onClick={RecordTraining} startIcon={<AiTwotoneSave />} className="bton_enreg2">ENREGISTRER LA FORMATION </Button>
        </div>
        }

        {/*String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" >
          <Button variant="contained" onClick={"ModifTraining"} startIcon={<AiTwotoneEdit />}>MODIFIER </Button>
        </div>
      */ }



        {String(datamodification) !== String("1") && String(editor_tochange) !== String("1") &&
          String(props.mytrainingclass.ref) !== "" && <div className="parter_div_filtrer_row_droite" >
            <Button variant="contained" onClick={DeleteClass} startIcon={<DeleteIcon />} className="bton_suppr2">  SUPPRIMER LA FORMATION </Button>

          </div>
        }


        {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) &&
          String(props.mytrainingclass.ref) !== "" && <div className="parter_div_filtrer_row_droite" >
            <Button variant="contained" onClick={UndoModif} startIcon={<IoArrowUndoCircle />} className="bton_suppr2">   ANNULER LES MODIFICATIONS </Button>

          </div>}

      </div>}

      {String(props.ispending) !== String("0") && <div className="parter_div_filtrer_row">
        {String(datamodification) === String("1") && <div className="parter_div_filtrer_row_gauche bton_enreg2" onClick={alertactivecompte} >
          <AiTwotoneSave />ENREGISTRER
        </div>
        }


        {String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" onClick={alertactivecompte}>
          <AiTwotoneEdit />MODIFICATION 1
        </div>
        }


        <div className="parter_div_filtrer_row_droite" >
          <Button variant="outlined" color="error" onClick={alertactivecompte} startIcon={<DeleteIcon />} className="bton_suppr2">   Supprimer</Button>

        </div>

      </div>}


      {String(myApiResponse) === String("true") && importmessage &&
        <div className="parter_div_filtrer_row okUpdateData">
          {importmessage}

        </div>}

      {String(myApiResponse) === String("false") &&
        <div className="parter_div_filtrer_row koUpdateData">
          {importmessage}

        </div>}



    </div>
  );
}

export default AddClassManual;
