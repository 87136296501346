import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import { withStyles } from "@material-ui/core/styles";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";


const Partner_Configuration_Jours_Travail = (props) => {


    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [isLoading, setLoading] = useState();

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'config_name', headerName: 'Nom étape', flex: 1, hide: false, editable: false },
        { field: 'config_value', headerName: 'Sequence', flex: 1, hide: false, editable: false },
        { field: 'config_comment', headerName: 'Sequence', flex: 1, hide: true, editable: false },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            //handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]



    const myRef = useRef(null)


    const myRef_head = useRef(null);
    const [selected_id, setselected_id] = useState("");


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const check_time_format = (e) => {
        const val = e.target.value;
        const regex = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');


        if (regex.test(val) === false) {
            alert(" Erreur ")
        }

    }

    function add_one_nb_annee_off() {
        var current_nb_annee = parseInt(nb_date_off) + 1
        setnb_date_off(current_nb_annee);
    }

    function minus_one_nb_annee_off() {

        var current_nb_annee = parseInt(nb_date_off) - 1
        setnb_date_off(current_nb_annee);

    }


    const renderRadioButtons = () => {
        let li = [];
        for (let i = 1; i <= parseInt(nb_date_off); i++) {
            li.push(
                <div className="sequence_date">
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name={"date_off_" + String(i)}
                        id={"date_off_" + String(i)}
                        type="date"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"

                    />
                </div>);
        }
        return li;
    }


    function add_one_nb_sequence(event) {

        var jour_name = event.target.value;

        if (String(jour_name).toLowerCase() === "lundi") {
            var current_nb_sequence = parseInt(nb_sequence_lundi) + 1
            setnb_sequence_lundi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "mardi") {
            var current_nb_sequence = parseInt(nb_sequence_mardi) + 1
            setnb_sequence_mardi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "mercredi") {

            var current_nb_sequence = parseInt(nb_sequence_mercredi) + 1
            setnb_sequence_mercredi(current_nb_sequence);
        }


        else if (String(jour_name).toLowerCase() === "jeudi") {
            var current_nb_sequence = parseInt(nb_sequence_jeudi) + 1
            setnb_sequence_jeudi(current_nb_sequence);
        }


        else if (String(jour_name).toLowerCase() === "vendredi") {
            var current_nb_sequence = parseInt(nb_sequence_vendredi) + 1
            setnb_sequence_vendredi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "samedi") {
            var current_nb_sequence = parseInt(nb_sequence_samedi) + 1
            setnb_sequence_samedi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "dimanche") {
            var current_nb_sequence = parseInt(nb_sequence_dimanche) + 1
            setnb_sequence_dimanche(current_nb_sequence);
        }
    }

    function minus_one_nb_sequence(event) {

        var jour_name = event.target.value;


        if (String(jour_name).toLowerCase() === "lundi") {
            var current_nb_sequence = parseInt(nb_sequence_lundi) - 1
            setnb_sequence_lundi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "mardi") {
            var current_nb_sequence = parseInt(nb_sequence_mardi) - 1
            setnb_sequence_mardi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "mercredi") {
            var current_nb_sequence = parseInt(nb_sequence_mercredi) - 1
            setnb_sequence_mercredi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "jeudi") {
            var current_nb_sequence = parseInt(nb_sequence_jeudi) - 1
            setnb_sequence_jeudi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "vendredi") {
            var current_nb_sequence = parseInt(nb_sequence_vendredi) - 1
            setnb_sequence_vendredi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "samedi") {
            var current_nb_sequence = parseInt(nb_sequence_samedi) - 1
            setnb_sequence_samedi(current_nb_sequence);
        }

        else if (String(jour_name).toLowerCase() === "dimanche") {
            var current_nb_sequence = parseInt(nb_sequence_dimanche) - 1
            setnb_sequence_dimanche(current_nb_sequence);
        }


    }

    const render_nb_sequence = (jour_name) => {

        if (String(jour_name).toLowerCase() === "lundi") {
            let li = [];

            var name_racine_from = "lundi_seq_from_";
            var name_racine_to = "lundi_seq_to_";

            for (let i = 1; i <= parseInt(nb_sequence_lundi); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }


        else if (String(jour_name).toLowerCase() === "mardi") {
            let li = [];

            var name_racine_from = "mardi_seq_from_";
            var name_racine_to = "mardi_seq_to_";


            for (let i = 1; i <= parseInt(nb_sequence_mardi); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }

        else if (String(jour_name).toLowerCase() === "mercredi") {
            let li = [];

            var name_racine_from = "mercredi_seq_from_";
            var name_racine_to = "mercredi_seq_to_";


            for (let i = 1; i <= parseInt(nb_sequence_mercredi); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }

        else if (String(jour_name).toLowerCase() === "jeudi") {
            let li = [];

            var name_racine_from = "jeudi_seq_from_";
            var name_racine_to = "jeudi_seq_to_";


            for (let i = 1; i <= parseInt(nb_sequence_jeudi); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }


        else if (String(jour_name).toLowerCase() === "vendredi") {
            let li = [];

            var name_racine_from = "vendredi_seq_from_";
            var name_racine_to = "vendredi_seq_to_";


            for (let i = 1; i <= parseInt(nb_sequence_vendredi); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }


        else if (String(jour_name).toLowerCase() === "samedi") {
            let li = [];

            var name_racine_from = "samedi_seq_from_";
            var name_racine_to = "samedi_seq_to_";


            for (let i = 1; i <= parseInt(nb_sequence_samedi); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }


        else if (String(jour_name).toLowerCase() === "dimanche") {
            let li = [];

            var name_racine_from = "dimanche_seq_from_";
            var name_racine_to = "dimanche_seq_to_";


            for (let i = 1; i <= parseInt(nb_sequence_dimanche); i++) {
                li.push(
                    <td style={{ "border": "1px solid", "width": "20%" }}>
                        <div className="sequence_heure" style={{}}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_from + String(i)}
                                id={name_racine_from + String(i)}
                                type="time"
                                label="Du (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"


                            />
                        </div>
                        <div className="sequence_heure">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={name_racine_to + String(i)}
                                id={name_racine_to + String(i)}
                                type="time"
                                label="Au (hh:mm) "
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"

                            />
                        </div>
                    </td>
                );
            }
            return li;
        }
    }

    const [nb_sequence_lundi, setnb_sequence_lundi] = useState("0");
    const [nb_sequence_mardi, setnb_sequence_mardi] = useState("0");
    const [nb_sequence_mercredi, setnb_sequence_mercredi] = useState("0");
    const [nb_sequence_jeudi, setnb_sequence_jeudi] = useState("0");
    const [nb_sequence_vendredi, setnb_sequence_vendredi] = useState("0");
    const [nb_sequence_samedi, setnb_sequence_samedi] = useState("2");
    const [nb_sequence_dimanche, setnb_sequence_dimanche] = useState("2");

    const [nb_date_off, setnb_date_off] = useState("1");

    const [Add_Update_working_Setup_api, setAdd_Update_working_Setup_api] = useState();
    const [Add_Update_working_Setup_message, setAdd_Update_working_Setup_message] = useState();
    const [Add_Update_working_Setup_result, setAdd_Update_working_Setup_result] = useState();
    function Add_Update_working_Setup() {

        /*** début traitement des données du lundi  */
        var data_lundi = {}
        data_lundi['jour'] = "lundi";
        data_lundi['tab_sequence'] = []

        var tab_sequence_lundi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_lundi)); i++) {

            var name_racine_from = "lundi_seq_from_" + String(i);
            var name_racine_to = "lundi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du Lundi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du Lundi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_lundi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_lundi['tab_sequence'].push(json_lundi)

            }


        }


        //data_lundi['tab_sequence'].push(tab_sequence_lundi)
        // console.log(" tab_sequence_lundi = ", data_lundi);


        /*** début traitement des données du mardi  */
        var data_mardi = {}
        data_mardi['jour'] = "mardi";
        data_mardi['tab_sequence'] = []

        var tab_sequence_mardi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_mardi)); i++) {

            var name_racine_from = "mardi_seq_from_" + String(i);
            var name_racine_to = "mardi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du mardi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du mardi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_mardi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_mardi['tab_sequence'].push(json_mardi)

            }


        }


        //data_mardi['tab_sequence'].push(tab_sequence_mardi)
        // console.log(" tab_sequence_mardi = ", data_mardi);


        /*** début traitement des données du mardi  */
        var data_mardi = {}
        data_mardi['jour'] = "mardi";
        data_mardi['tab_sequence'] = []

        var tab_sequence_mardi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_mardi)); i++) {

            var name_racine_from = "mardi_seq_from_" + String(i);
            var name_racine_to = "mardi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du mardi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du mardi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_mardi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_mardi['tab_sequence'].push(json_mardi)

            }


        }


        //data_mardi['tab_sequence'].push(tab_sequence_mardi)
        // console.log(" tab_sequence_mardi = ", data_mardi);


        /*** début traitement des données du mercredi  */
        var data_mercredi = {}
        data_mercredi['jour'] = "mercredi";
        data_mercredi['tab_sequence'] = []

        var tab_sequence_mercredi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_mercredi)); i++) {

            var name_racine_from = "mercredi_seq_from_" + String(i);
            var name_racine_to = "mercredi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du mercredi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du mercredi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_mercredi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_mercredi['tab_sequence'].push(json_mercredi)

            }


        }


        //data_mercredi['tab_sequence'].push(tab_sequence_mercredi)
        // console.log(" tab_sequence_mercredi = ", data_mercredi);


        /*** début traitement des données du jeudi  */
        var data_jeudi = {}
        data_jeudi['jour'] = "jeudi";
        data_jeudi['tab_sequence'] = []

        var tab_sequence_jeudi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_jeudi)); i++) {

            var name_racine_from = "jeudi_seq_from_" + String(i);
            var name_racine_to = "jeudi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du jeudi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du jeudi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_jeudi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_jeudi['tab_sequence'].push(json_jeudi)

            }


        }


        //data_jeudi['tab_sequence'].push(tab_sequence_jeudi)
        // console.log(" tab_sequence_jeudi = ", data_jeudi);


        /*** début traitement des données du vendredi  */
        var data_vendredi = {}
        data_vendredi['jour'] = "vendredi";
        data_vendredi['tab_sequence'] = []

        var tab_sequence_vendredi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_vendredi)); i++) {

            var name_racine_from = "vendredi_seq_from_" + String(i);
            var name_racine_to = "vendredi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du vendredi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du vendredi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_vendredi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_vendredi['tab_sequence'].push(json_vendredi)

            }


        }


        //data_vendredi['tab_sequence'].push(tab_sequence_vendredi)
        // console.log(" tab_sequence_vendredi = ", data_vendredi);

        /*** début traitement des données du samedi  */
        var data_samedi = {}
        data_samedi['jour'] = "samedi";
        data_samedi['tab_sequence'] = []

        var tab_sequence_samedi = []

        for (let i = 1; i <= parseInt(String(nb_sequence_samedi)); i++) {

            var name_racine_from = "samedi_seq_from_" + String(i);
            var name_racine_to = "samedi_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du samedi sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du samedi sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_samedi = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_samedi['tab_sequence'].push(json_samedi)

            }


        }


        //data_samedi['tab_sequence'].push(tab_sequence_samedi)
        // console.log(" tab_sequence_samedi = ", data_samedi);

        /*** début traitement des données du dimanche  */
        var data_dimanche = {}
        data_dimanche['jour'] = "dimanche";
        data_dimanche['tab_sequence'] = []

        var tab_sequence_dimanche = []

        for (let i = 1; i <= parseInt(String(nb_sequence_dimanche)); i++) {

            var name_racine_from = "dimanche_seq_from_" + String(i);
            var name_racine_to = "dimanche_seq_to_" + String(i);

            var local_val = {};


            if (document.getElementsByName(name_racine_from)[0] && document.getElementsByName(name_racine_to)[0]) {
                var sequence_from = document.getElementsByName(name_racine_from)[0].value;
                var sequence_to = document.getElementsByName(name_racine_to)[0].value;

                if (sequence_from === undefined || String(sequence_from) === ""
                    || sequence_to === undefined || String(sequence_to) === "") {
                    console.log(" Les heures du dimanche sont incorrectes");
                    return;
                }

                var work_sequence_from = parseInt(String(sequence_from).replaceAll(":", ''))
                var work_sequence_to = parseInt(String(sequence_to).replaceAll(":", ''))


                if (work_sequence_from >= work_sequence_to) {
                    console.log(" Les heures du dimanche sont incorrectes. L'heure de fin de séquence doit être antérieure à l'heure de début de séquence");
                    return;
                }

                var json_dimanche = {
                    'heure_debut': String(sequence_from),
                    'heure_fin': String(sequence_to),
                }

                data_dimanche['tab_sequence'].push(json_dimanche)

            }


        }


        //data_dimanche['tab_sequence'].push(tab_sequence_dimanche)
        // console.log(" tab_sequence_dimanche = ", data_dimanche);



        var global_partner_working_hours = {}

        global_partner_working_hours['lundi'] = data_lundi;
        global_partner_working_hours['mardi'] = data_mardi;
        global_partner_working_hours['mercredi'] = data_mercredi;
        global_partner_working_hours['jeudi'] = data_jeudi;
        global_partner_working_hours['vendredi'] = data_vendredi;
        global_partner_working_hours['samedi'] = data_samedi;
        global_partner_working_hours['dimanche'] = data_dimanche;

        /*** Recuperation des dates fériés  */

        global_partner_working_hours['date_off'] = []

        for (let i = 1; i <= parseInt(String(nb_date_off)); i++) {

            var date_off_name = "date_off_" + String(i);

            if (document.getElementsByName(date_off_name)[0]) {
                var date_off_value = document.getElementsByName(date_off_name)[0].value;



                global_partner_working_hours['date_off'].push(date_off_value)

            }

        }


        if (document.getElementsByName("planning_debut_journee")[0] && document.getElementsByName("planning_fin_journee")[0]) {
            var debut_journee_value = document.getElementsByName("planning_debut_journee")[0].value;
            var fin_journee_value = document.getElementsByName("planning_fin_journee")[0].value;

            if (debut_journee_value === undefined || String(debut_journee_value) === ""
                || fin_journee_value === undefined || String(fin_journee_value) === "") {
                console.log(" Les heures de début et de fin de journée (planning) sont incorrectes");
                return;
            }

            var work_debut_journee_value = parseInt(String(debut_journee_value).replaceAll(":", ''))
            var work_fin_journee_value = parseInt(String(fin_journee_value).replaceAll(":", ''))


            if (work_debut_journee_value >= work_fin_journee_value) {
                console.log(" Les heures de début et de fin de journée (planning) sont incorrectes. L'heure de fin de journée doit être antérieure à l'heure de début de journée");
                return;
            }

            var json_journee_travaille = {
                'planning_debut_journee': String(debut_journee_value),
                'planning_fin_journee': String(fin_journee_value),
            }

            global_partner_working_hours['debut_fin_journee'] = json_journee_travaille 

        }


       

        var json_str = JSON.stringify(global_partner_working_hours)

        //console.log(" json_str = ", json_str);

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("modele_journee", json_str);
        form.append("_id", String(selected_Model_Journee_id));

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Modele_Journee/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_working_Setup  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_working_Setup  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_working_Setup_api("true");
                setAdd_Update_working_Setup_result(res.data.message);

                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                alert(res.data.message);

            }
            else {
                setAdd_Update_working_Setup_api("false");
                setAdd_Update_working_Setup_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_working_Setup = ' + error);
            setAdd_Update_working_Setup_api("false");
            alert(" Impossible de créer / mettre à jour le modèle de journée de travail ");

        })
    }



    async function Get_working_Setup() {

        var json_test = {
            "lundi": {
                "jour": "lundi",
                "tab_sequence": [
                    {
                        "heure_debut": "08:00",
                        "heure_fin": "12:00"
                    },
                    {
                        "heure_debut": "14:00",
                        "heure_fin": "16:00"
                    }
                ]
            },
            "mardi": {
                "jour": "mardi",
                "tab_sequence": []
            },
            "mercredi": {
                "jour": "mercredi",
                "tab_sequence": []
            },
            "jeudi": {
                "jour": "jeudi",
                "tab_sequence": []
            },
            "vendredi": {
                "jour": "vendredi",
                "tab_sequence": []
            },
            "samedi": {
                "jour": "samedi",
                "tab_sequence": []
            },
            "dimanche": {
                "jour": "dimanche",
                "tab_sequence": [
                    {
                        "heure_debut": "07:00",
                        "heure_fin": "09:00"
                    },
                    {
                        "heure_debut": "11:00",
                        "heure_fin": "17:00"
                    }
                ]
            },
            "date_off": [
                "2023-11-06",
                "2023-11-10",
                "2023-11-20"
            ]
        }


        /***
         * Recuperation des nombres de sequence
         */
        var data_lundi = json_test.lundi;
        var nb_sequen_lundi = json_test.lundi.tab_sequence.length;
        setnb_sequence_lundi(String(nb_sequen_lundi));

        var data_mardi = json_test.mardi;
        var nb_sequen_mardi = json_test.mardi.tab_sequence.length;
        setnb_sequence_mardi(String(nb_sequen_mardi));


        var data_mercredi = json_test.mercredi;
        var nb_sequen_mercredi = json_test.mercredi.tab_sequence.length;
        setnb_sequence_mercredi(String(nb_sequen_mercredi));


        var data_jeudi = json_test.jeudi;
        var nb_sequen_jeudi = json_test.jeudi.tab_sequence.length;
        setnb_sequence_jeudi(String(nb_sequen_jeudi));


        var data_vendredi = json_test.vendredi;
        var nb_sequen_vendredi = json_test.vendredi.tab_sequence.length;
        setnb_sequence_vendredi(String(nb_sequen_vendredi));


        var data_samedi = json_test.samedi;
        var nb_sequen_samedi = json_test.samedi.tab_sequence.length;
        setnb_sequence_samedi(String(nb_sequen_samedi));


        var data_dimanche = json_test.dimanche;
        var nb_sequen_dimanche = json_test.dimanche.tab_sequence.length;
        setnb_sequence_dimanche(String(nb_sequen_dimanche));



        await sleep(5);


        /**
         * Traitement des données du lundi
         */

        for (let i = 1; i <= nb_sequen_lundi; i++) {

            var name_racine_from = "lundi_seq_from_" + String(i);
            var name_racine_to = "lundi_seq_to_" + String(i);


            var from = json_test.lundi.tab_sequence[i - 1].heure_debut;
            var to = json_test.lundi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }

        /**
         * Traitement des données du mardi
         */

        for (let i = 1; i <= nb_sequen_mardi; i++) {

            var name_racine_from = "mardi_seq_from_" + String(i);
            var name_racine_to = "mardi_seq_to_" + String(i);


            var from = json_test.mardi.tab_sequence[i - 1].heure_debut;
            var to = json_test.mardi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du mercredi
         */

        for (let i = 1; i <= nb_sequen_mercredi; i++) {

            var name_racine_from = "mercredi_seq_from_" + String(i);
            var name_racine_to = "mercredi_seq_to_" + String(i);


            var from = json_test.mercredi.tab_sequence[i - 1].heure_debut;
            var to = json_test.mercredi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du jeudi
         */

        for (let i = 1; i <= nb_sequen_jeudi; i++) {

            var name_racine_from = "jeudi_seq_from_" + String(i);
            var name_racine_to = "jeudi_seq_to_" + String(i);


            var from = json_test.jeudi.tab_sequence[i - 1].heure_debut;
            var to = json_test.jeudi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du vendredi
         */

        for (let i = 1; i <= nb_sequen_vendredi; i++) {

            var name_racine_from = "vendredi_seq_from_" + String(i);
            var name_racine_to = "vendredi_seq_to_" + String(i);


            var from = json_test.vendredi.tab_sequence[i - 1].heure_debut;
            var to = json_test.vendredi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du samedi
         */

        for (let i = 1; i <= nb_sequen_samedi; i++) {

            var name_racine_from = "samedi_seq_from_" + String(i);
            var name_racine_to = "samedi_seq_to_" + String(i);


            var from = json_test.samedi.tab_sequence[i - 1].heure_debut;
            var to = json_test.samedi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du dimanche
         */

        for (let i = 1; i <= nb_sequen_dimanche; i++) {

            var name_racine_from = "dimanche_seq_from_" + String(i);
            var name_racine_to = "dimanche_seq_to_" + String(i);


            var from = json_test.dimanche.tab_sequence[i - 1].heure_debut;
            var to = json_test.dimanche.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }



        /**
         * Traitement des dates off
         */

        var data_dates_off = json_test.date_off;
        var nb_sequen_data_dates_off = json_test.date_off.length;
        setnb_date_off(String(nb_sequen_data_dates_off));


        await sleep(5);


        for (let i = 1; i <= json_test.date_off.length; i++) {

            var date_off_name = "date_off_" + String(i);

            var date_off_value = json_test.date_off[i - 1];


            if (document.getElementById(date_off_name)) {
                document.getElementById(date_off_name).value = String(date_off_value);

            }

        }

    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    const [selected_Model_Journee_id, setselected_Model_Journee_id] = useState("");

    const [Getall_Default_Model_Journee_api, setGetall_Default_Model_Journee_api] = useState();
    const [Getall_Default_Model_Journee_message, setGetall_Default_Model_Journee_message] = useState();
    const [Getall_Default_Model_Journee_result, setGetall_Default_Model_Journee_result] = useState();
    function Getall_Default_Model_Journee(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Default_Modele_Journee/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Default_Model_Journee  res.data.status = " + res.data.status);
                //console.log(" In Getall_Default_Model_Journee  res.data.message r_class = " + res.data.message);
                setGetall_Default_Model_Journee_api("true");
                setGetall_Default_Model_Journee_result(res.data.message);

                var line = JSON.parse(res.data.message);


                if (String(JSON.parse(res.data.message).partner_owner_recid) !== "default") {
                    setselected_Model_Journee_id(String(JSON.parse(res.data.message)._id))
                }

                Get_working_Setup_With_arg(line);

                if (String(JSON.parse(res.data.message).debut_fin_journee.planning_debut_journee) ) {
                    document.getElementById("planning_debut_journee").value = String(JSON.parse(res.data.message).debut_fin_journee.planning_debut_journee);
                }

                if (String(JSON.parse(res.data.message).debut_fin_journee.planning_fin_journee) ) {
                    document.getElementById("planning_fin_journee").value = String(JSON.parse(res.data.message).debut_fin_journee.planning_fin_journee);
                }

            }
            else {
                setGetall_Default_Model_Journee_api("false");
                setGetall_Default_Model_Journee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Default_Model_Journee = ', error);
            setGetall_Default_Model_Journee_api("false");
            alert(" Impossible de recuperer la configuration des journées de travail");
            //setmyApimyApiMessage("")
        })
    }

    async function Get_working_Setup_With_arg(json_test) {

        /***
         * Recuperation des nombres de sequence
         */
        var data_lundi = json_test.lundi;
        var nb_sequen_lundi = json_test.lundi.tab_sequence.length;
        setnb_sequence_lundi(String(nb_sequen_lundi));

        var data_mardi = json_test.mardi;
        var nb_sequen_mardi = json_test.mardi.tab_sequence.length;
        setnb_sequence_mardi(String(nb_sequen_mardi));


        var data_mercredi = json_test.mercredi;
        var nb_sequen_mercredi = json_test.mercredi.tab_sequence.length;
        setnb_sequence_mercredi(String(nb_sequen_mercredi));


        var data_jeudi = json_test.jeudi;
        var nb_sequen_jeudi = json_test.jeudi.tab_sequence.length;
        setnb_sequence_jeudi(String(nb_sequen_jeudi));


        var data_vendredi = json_test.vendredi;
        var nb_sequen_vendredi = json_test.vendredi.tab_sequence.length;
        setnb_sequence_vendredi(String(nb_sequen_vendredi));


        var data_samedi = json_test.samedi;
        var nb_sequen_samedi = json_test.samedi.tab_sequence.length;
        setnb_sequence_samedi(String(nb_sequen_samedi));


        var data_dimanche = json_test.dimanche;
        var nb_sequen_dimanche = json_test.dimanche.tab_sequence.length;
        setnb_sequence_dimanche(String(nb_sequen_dimanche));



        await sleep(5);


        /**
         * Traitement des données du lundi
         */

        for (let i = 1; i <= nb_sequen_lundi; i++) {

            var name_racine_from = "lundi_seq_from_" + String(i);
            var name_racine_to = "lundi_seq_to_" + String(i);


            var from = json_test.lundi.tab_sequence[i - 1].heure_debut;
            var to = json_test.lundi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }

        /**
         * Traitement des données du mardi
         */

        for (let i = 1; i <= nb_sequen_mardi; i++) {

            var name_racine_from = "mardi_seq_from_" + String(i);
            var name_racine_to = "mardi_seq_to_" + String(i);


            var from = json_test.mardi.tab_sequence[i - 1].heure_debut;
            var to = json_test.mardi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du mercredi
         */

        for (let i = 1; i <= nb_sequen_mercredi; i++) {

            var name_racine_from = "mercredi_seq_from_" + String(i);
            var name_racine_to = "mercredi_seq_to_" + String(i);


            var from = json_test.mercredi.tab_sequence[i - 1].heure_debut;
            var to = json_test.mercredi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du jeudi
         */

        for (let i = 1; i <= nb_sequen_jeudi; i++) {

            var name_racine_from = "jeudi_seq_from_" + String(i);
            var name_racine_to = "jeudi_seq_to_" + String(i);


            var from = json_test.jeudi.tab_sequence[i - 1].heure_debut;
            var to = json_test.jeudi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du vendredi
         */

        for (let i = 1; i <= nb_sequen_vendredi; i++) {

            var name_racine_from = "vendredi_seq_from_" + String(i);
            var name_racine_to = "vendredi_seq_to_" + String(i);


            var from = json_test.vendredi.tab_sequence[i - 1].heure_debut;
            var to = json_test.vendredi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du samedi
         */

        for (let i = 1; i <= nb_sequen_samedi; i++) {

            var name_racine_from = "samedi_seq_from_" + String(i);
            var name_racine_to = "samedi_seq_to_" + String(i);


            var from = json_test.samedi.tab_sequence[i - 1].heure_debut;
            var to = json_test.samedi.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }


        /**
         * Traitement des données du dimanche
         */

        for (let i = 1; i <= nb_sequen_dimanche; i++) {

            var name_racine_from = "dimanche_seq_from_" + String(i);
            var name_racine_to = "dimanche_seq_to_" + String(i);


            var from = json_test.dimanche.tab_sequence[i - 1].heure_debut;
            var to = json_test.dimanche.tab_sequence[i - 1].heure_fin;

            if (document.getElementById(name_racine_from) && document.getElementById(name_racine_to)) {
                document.getElementById(name_racine_from).value = String(from);
                document.getElementById(name_racine_to).value = String(to);
            }

        }



        /**
         * Traitement des dates off
         */

        var data_dates_off = json_test.date_off;
        var nb_sequen_data_dates_off = json_test.date_off.length;
        setnb_date_off(String(nb_sequen_data_dates_off));


        await sleep(5);


        for (let i = 1; i <= json_test.date_off.length; i++) {

            var date_off_name = "date_off_" + String(i);

            var date_off_value = json_test.date_off[i - 1];


            if (document.getElementById(date_off_name)) {
                document.getElementById(date_off_name).value = String(date_off_value);

            }

        }

    }

    useEffect(() => {

        Getall_Default_Model_Journee();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])

    const [gridline_id, setgridline_id] = useState("");

    return (
        <div className="partner_configuration_jours_travail">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}
            <h3> Votre configuration des jours et heure de travail </h3>

            <div className="div_row">
                <div className="div_row" >

                    <Popup
                        trigger={
                            <Button variant="contained" className="bton_enreg" >Enregistrer
                            </Button>
                        }
                        modal
                        nested
                        position="center center"

                    >

                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    <font color="red">  Confirmer la modification des jours et heures travaillés.
                                    </font>
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            Add_Update_working_Setup();
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>

                    {/*<Button variant="contained" className="bton_enreg" onClick={Get_working_Setup}>test affichage
                    </Button>*/}

                </div>
                <table style={{ "border": "1px solid", "width": "95%" }}>

                    <thead >
                        <tr>
                            <td colspan="4" style={{ "background": "#EAECEE", "textAlign": "center" }}>
                                Config jours et heures de travail
                            </td>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "textAlign": "center" }}> Jour </td>

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  Lundi  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="lundi" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="lundi" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>

                            </td>

                            {render_nb_sequence("lundi")}

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  Mardi  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="mardi" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="mardi" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>



                            </td>

                            {render_nb_sequence("mardi")}

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  Mercredi  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="mercredi" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="mercredi" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>



                            </td>

                            {render_nb_sequence("mercredi")}

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  Jeudi  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="jeudi" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="jeudi" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>



                            </td>

                            {render_nb_sequence("jeudi")}

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  vendredi  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="vendredi" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="vendredi" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>



                            </td>

                            {render_nb_sequence("vendredi")}

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  Samedi  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="samedi" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="samedi" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>



                            </td>

                            {render_nb_sequence("samedi")}

                        </tr>

                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%", "paddingLeft": "10px" }} >
                                <div className="div_row" style={{ "textAlign": "left", "fontWeight": "bold" }}>  Dimanche  </div>
                                <div className="div_row" style={{ "textAlign": "left", "paddingLeft": "10px" }}>  <FormControlLabel control={<Checkbox />} label=" Jour Off"
                                    onChange={(e) => {

                                    }}
                                />
                                </div>
                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button value="dimanche" onClick={add_one_nb_sequence}> <IoMdAddCircle /> &nbsp; Séquence</Button>  <br />
                                    <Button value="dimanche" onClick={minus_one_nb_sequence}><IoIosRemoveCircleOutline />   &nbsp; Séquence </Button>
                                </div>



                            </td>

                            {render_nb_sequence("dimanche")}

                        </tr>


                    </tbody>

                </table>
            </div>

            <div className="div_row" > &nbsp;</div>

            <div className="div_row">
                <table style={{ "border": "1px solid", "width": "95%" }}>

                    <thead >
                        <tr>
                            <td colspan="4" style={{ "background": "#EAECEE", "textAlign": "center" }}>
                                Plage horaire (planning)
                            </td>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%" }} >

                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button >  Heure Début & Fin journée</Button>
                                </div>

                            </td>
                            <td style={{ "border": "1px solid", "width": "50%" }}>

                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="planning_debut_journee"
                                    id="planning_debut_journee"
                                    type="time"
                                    label="Du (hh:mm) "
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"


                                />

                            </td>

                            <td style={{ "border": "1px solid", "width": "50%" }}>

                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="planning_fin_journee"
                                    id="planning_fin_journee"
                                    type="time"
                                    label="Au (hh:mm) "
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"

                                />

                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>

            <div className="div_row" > &nbsp;</div>

            <div className="div_row">
                <table style={{ "border": "1px solid", "width": "95%" }}>

                    <thead >
                        <tr>
                            <td colspan="4" style={{ "background": "#EAECEE", "textAlign": "center" }}>
                                Config Dates non travaillées (exemple les dates ou vous êtes fermées)
                            </td>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td style={{ "border": "1px solid", "width": "10%" }} >

                                <div className="div_row" style={{ "textAlign": "left" }}>
                                    <Button onClick={add_one_nb_annee_off}> <IoMdAddCircle /> &nbsp; Date</Button>  <br />
                                    <Button onClick={minus_one_nb_annee_off}> <IoIosRemoveCircleOutline />   &nbsp; Date </Button>
                                </div>

                            </td>
                            <td style={{ "border": "1px solid", "width": "100%" }}>

                                {renderRadioButtons()}

                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>

            <div className="div_row" >

                <Popup
                    trigger={
                        <Button variant="contained" className="bton_enreg" >Enregistrer
                        </Button>
                    }
                    modal
                    nested
                    position="center center"

                >

                    {close => (
                        <div>
                            <button className="gest_close" onClick={close}>
                                &times;
                            </button>
                            <div className="gest_header"> MySy Information </div>
                            <div className="gest_content">
                                {' '}

                                <font color="red">  Confirmer la modification des jours et heures travaillés.
                                </font>
                            </div>
                            <div className="gest_actions">
                                <div style={{ "width": "45%", "float": "left" }}>
                                    <button className="gest_bton_popup" onClick={(event) => {
                                        Add_Update_working_Setup();
                                        close();
                                    }}> Valider </button>

                                </div>
                                <div style={{ "width": "45%", "float": "right" }}>
                                    <button
                                        className="gest_bton_popup"
                                        onClick={() => {
                                            //console.log('modal closed ');
                                            close();
                                        }}
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>

            </div>

        </div>
    )
}


export default Partner_Configuration_Jours_Travail;

