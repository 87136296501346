import QuisSommesNous from "../components/QuisSommesNous";
import Navigation from "../components/Navigation";
import QuiSommesNous_v3 from "../components/QuisSommesNous_V3";

const recherche_draft = () => {

  return (
            <div>
              <div>
            <Navigation />
            </div>
            <QuiSommesNous_v3/>
        </div>

  )
}


export default recherche_draft;
