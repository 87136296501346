import React, { useState, useEffect } from 'react';
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Button from '@mui/material/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useCookies } from "react-cookie";



const SideBar = () => {
  const stored_cookie = getCookie('tokenmysych');
  const history = useHistory();


  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);
  // Gestion des Cookies
  const stored_partner = cookie_part.tokenmysypart;
  const stored_user = cookie.tokenmysych;

  const environnment = process.env.REACT_APP_ENV



  function logout_confirmation() {

    // si c'est une connexion partner qui est active
    if (typeof (stored_partner) != "undefined") {
      //alert(" c'est une connexion PARTTT  qui est active");
      removeCookie_part('tokenmysypart', { path: '/' });
      history.push("/PartnerLogin");
    }

    // si c'est une connexion user qui est active
    if (typeof (stored_user) != "undefined") {
      //alert(" c'est une connexion USERR  qui est active");
      removeCookie_part('tokenmysych', { path: '/' });
      history.push("/mysy-training-login/0/0");
    }
  }


  function logout() {
    confirmAlert({
      title: 'Deconnexion ?',
      message: 'Confirmez la deconnexion',
      buttons: [
        {
          label: 'Oui',
          onClick: () => logout_confirmation()
        },
        {
          label: 'Non',

        }
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypress: () => { },
      onKeypressEscape: () => { },
      overlayClassName: "overlay-custom-class-name"
    });
  }

  const [someoneconnected, setsomeoneconnected] = useState("0");
  const [partnerconnected, setpartnerconnected] = useState("0");
  const [userconnected, setuserconnected] = useState("0");

  const stored_part = getCookie('tokenmysypart');


  function whosisconnected() {
    var local_user_connect = 0;
    var local_part_connect = 0;

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
      local_user_connect = 0;
    } else {
      setuserconnected("1");
      setsomeoneconnected("1");
      local_user_connect = 1;
      //alert(" user connected OK");
    }

    if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
      setpartnerconnected("0");
      local_part_connect = 0;
    } else {
      setpartnerconnected("1");
      setsomeoneconnected("1");
      local_part_connect = 1;
      //alert(" partner connected OK");
    }
  }

  useEffect(() => {
    whosisconnected();
  }, []);

  return (
    // Pass on our props
    <div>


      {String(environnment) === "PROD" &&
        <Menu className="ch_burger_menu">
          {String(someoneconnected) !== String("1") && <NavLink exact to={"/Connexion"} activeClassName={"nav-active"}>
            Connexion Utilisateur
          </NavLink>}

          {String(someoneconnected) !== String("1") &&  <NavLink exact to={"/create_account"} activeClassName={"nav-active"}>
            Créer compte
          </NavLink>}


          {String(someoneconnected) === String("1") && <NavLink exact to={"/mysy-user-account"} activeClassName={"nav-active"}>
            Mon compte
          </NavLink>}

          <NavLink exact to={"/recherche-formation"} activeClassName={"nav-active"}>
            Recherche formation
          </NavLink>

          <NavLink exact to={"/QuiSommesNous"} activeClassName={"nav-active"}>
          Qui Sommes nous ?
          </NavLink>

          <NavLink exact to={"/Produits-Services"} activeClassName={"nav-active"}>
            Produits & Services
          </NavLink>

          <NavLink exact to={"/Partner"} activeClassName={"nav-active"}>
            Partenaires
          </NavLink>

          <NavLink exact to={"/PartnerLogin"} activeClassName={"nav-active"}>
            Partenaire Login
          </NavLink>

          <NavLink exact to={"/Recherche-Article-formation"} activeClassName={"nav-active"}>
            Liste Articles
          </NavLink>



          {typeof (stored_partner) != "undefined" &&
            /* Connexion partner active */
            <Button className='bton_annule' onClick={logout} style={{color:"red", opacity:'100%', borderRadius:'5rem', fontSize:"12px", width:"100%", background:"black",
            padding:"5px"}}>
              <i> Deconnexion </i>
            </Button>


          }

          {typeof (stored_user) != "undefined" &&
            /* Connexion utilisateur active */
            <Button className='bton_annule' onClick={logout}  startIcon={<LogoutOutlinedIcon />} sx={{ color: "white", background: "green", fontSize: 10 }} >
              Deconnexion
            </Button>
          }
        </Menu>}

      {(String(environnment) === "REC" || String(environnment) === "DEM") &&
        <Menu className="ch_burger_menu">
          {String(someoneconnected) !== String("1") && <NavLink exact to={"/Connexion"} activeClassName={"nav-active"}>
            Connexion Utilisateur
          </NavLink>}

          {String(someoneconnected) !== String("1") &&   <NavLink exact to={"/create_account"} activeClassName={"nav-active"}>
            Créer compte
          </NavLink>}

          {String(someoneconnected) === String("1") && <NavLink exact to={"/mysy-user-account"} activeClassName={"nav-active"}>
            Mon compte
          </NavLink>}

          <NavLink exact to={"/recherche-formation"} activeClassName={"nav-active"}>
            Recherche formation
          </NavLink>

          <NavLink exact to={"/QuiSommesNous"} activeClassName={"nav-active"}>
          Qui Sommes nous ?
          </NavLink>

          <NavLink exact to={"/Produits-Services"} activeClassName={"nav-active"}>
            Produits & Services
          </NavLink>

          <NavLink exact to={"/Partner"} activeClassName={"nav-active"}>
            Partenaires
          </NavLink>

          <NavLink exact to={"/PartnerLogin"} activeClassName={"nav-active"}>
            Partenaire Login
          </NavLink>

          <NavLink exact to={"/Recherche-Article-formation"} activeClassName={"nav-active"}>
            Liste Articles
          </NavLink>



          {typeof (stored_partner) != "undefined" &&
            /* Connexion partner active */
            <Button className='bton_annule' onClick={logout} style={{
              color: "red", opacity: '100%', borderRadius: '5rem', fontSize: "12px", width: "100%", background: "black",
              padding: "5px"
            }}>
              <i> Deconnexion </i>
            </Button>


          }

          {typeof (stored_user) != "undefined" &&
            /* Connexion utilisateur active */
            <Button className='bton_annule' onClick={logout} startIcon={<LogoutOutlinedIcon />} sx={{ color: "white", background: "green", fontSize: 10 }} >
              Deconnexion
            </Button>
          }
        </Menu>}


      {String(environnment) === "DEV" &&
        <Menu className="ch_burger_menu">

          <NavLink exact to={"/mysy-training-login/0/0"} activeClassName={"nav-active"}>
            DEV : someoneconnected = {String(someoneconnected)}
          </NavLink>


          {String(someoneconnected) !== String("1") && <NavLink exact to={"/mysy-training-login/0/0"} activeClassName={"nav-active"}>
            Connexion Utilisateur
          </NavLink>}

          {String(someoneconnected) !== String("1") && <NavLink exact to={"/create_account"} activeClassName={"nav-active"}>
            Créer compte
          </NavLink>}


          {String(someoneconnected) === String("1") && <NavLink exact to={"/Partner"} activeClassName={"nav-active"}>
            Mon compte
          </NavLink>}


          <NavLink exact to={"/recherche-formation/"} activeClassName={"nav-active"}>
            Recherche formation
          </NavLink>

          <NavLink exact to={"/QuiSommesNous"} activeClassName={"nav-active"}>
          Qui Sommes nous ?
          </NavLink>

          <NavLink exact to={"/Produits-Services"} activeClassName={"nav-active"}>
            Produits & Services
          </NavLink>

          <NavLink exact to={"/Display-Detail-formation/:classId"} activeClassName={"nav-active"}>
            Detail Formation
          </NavLink>


          <NavLink exact to={"/testurl/:course/:tab"} activeClassName={"nav-active"}>
            Test url
          </NavLink>


          <NavLink exact to={"/PartnerLogin"} activeClassName={"nav-active"}>
            Partenaire Login
          </NavLink>


          <NavLink exact to={"/Display-Detail-Article/:articleId"} activeClassName={"nav-active"}>
            Detail Articles
          </NavLink>


          <NavLink exact to={"/Recherche-Article-formation"} activeClassName={"nav-active"}>
            Liste Articles
          </NavLink>

          <NavLink exact to={"/ResetUserPwd/:token"} activeClassName={"nav-active"}>
            Reset User Pwd
          </NavLink>

          <NavLink exact to={"/DeleteUserAccount/:token"} activeClassName={"nav-active"}>
            Delete User Accound
          </NavLink>

          <NavLink exact to={"/mysy-account-activated/"} activeClassName={"nav-active"}>
            User Account Activated
          </NavLink>


          {typeof (stored_partner) != "undefined" &&
            /* Connexion partner active */
            <Button className='bton_annule' onClick={logout} style={{
              color: "red", opacity: '100%', borderRadius: '5rem', fontSize: "12px", width: "100%", background: "black",
              padding: "5px", letterSpacing:"2px"
            }}>
              <i> Deconnexion </i>
            </Button>


          }

          {typeof (stored_user) != "undefined" &&
            /* Connexion utilisateur active */
            <Button className='bton_annule' onClick={logout} startIcon={<LogoutOutlinedIcon />} sx={{ color: "white", background: "green", fontSize: 10 }} >
              Deconnexion
            </Button>
          }
        </Menu>}
    </div>

  );
};


export default SideBar;