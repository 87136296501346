import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
//import { getCookie, setCookie, removeCookie  } from 'react-use-cookie';
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import { useParams } from 'react-router-dom'
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import MenuItem from "@material-ui/core/MenuItem";
import Button2 from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'


function SignIn() {
    const [isconnected, setisconnected] = useState("");
    const [cookie_acces_right, setCookie_acces_right, removeCookie_acces_right] = useCookies(['mysy_user_access_right']);
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych'], { path: '/' });
    const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart'], { path: '/' });
    const [retval, setretval] = useState("0");
    const [myApimyApiMessage, setmyApimyApiMessage] = useState("");
    const [initpasswd, setinitpasswd] = useState();
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("false");
    const url_login = process.env.REACT_APP_API_URL + "myclass/api/login/";

    const random1 = Math.floor(Math.random() * 10);
    const random2 = Math.floor(Math.random() * 10);

    const [closepopup, setclosepopup] = useState(0);
    const { obj, objid } = useParams();
    const [menucompte, setmenucompte] = useState("partner");
    const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
    const [typecompte, settypecompte] = useState("");

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();


    const history = useHistory();

    function logout_partner_confirmation() {
        removeCookie_part('tokenmysypart', { path: '/' });
    }

    function retnull() {
        setretval("-1");
    }

    function Logout_Partenaire() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion compte PRO',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_partner_confirmation()
                },

            ]
        });
    }


    function logout_user_confirmation() {
        removeCookie_part('tokenmysych', { path: '/' });
    }

    function Logout_User() {
        confirmAlert({
            title: 'Deconnexion utilisateur ?',
            message: 'Confirmez la deconnexion utilisateur',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_user_confirmation()
                },
                /* {
                   label: 'Non',
                   onClick: () => retnull()
         
                 }*/
            ]
        });
    }


    const onSubmit = async (data) => {

        //console.log(" CONNEXION DE = ",menucompte);
        var partner_connexion = "";
        var url_login = "";

        if (String(menucompte) === "partner") {
            url_login = process.env.REACT_APP_API_URL + "myclass/api/partner_login/";
            setmenucompte("partner");
            partner_connexion = "partner";
          

        }
        else if (String(menucompte) === "user") {
            url_login = process.env.REACT_APP_API_URL + "myclass/api/login/";
            setmenucompte("user");
            partner_connexion = "user";
           
        }

        // Gestion des Cookies
        const stored_partner = cookie_part.tokenmysypart;
        const stored_user = cookie.tokenmysych;

        const formData = new FormData();
        var mymail = document.getElementsByName("email")[0].value;
        var mypwd = document.getElementsByName("pwd")[0].value;

        formData.append("email", mymail);
        formData.append("pwd", mypwd);

        var re = /\S+@\S+\.\S+/;
        if (re.test(mymail) === false) {
            alert("l'adresse email est incorrecte");
            return;
        }

        if (mypwd.length <= 0) {
            alert("Vous n'avez pas saisi de mot de passe");
            return;
        }


        // si c'est une connexion partner qui est active
        if (typeof (stored_partner) != "undefined" && String(stored_partner) !== String('')) {
            //alert(" c'est une connexion PARTTT  qui est active");
            Logout_Partenaire();

        }

        // si c'est une connexion user qui est active
        if (typeof (stored_user) != "undefined" && String(stored_user) !== String('')) {
            //alert(" c'est une connexion USERR  qui est active");
            Logout_User();
        }


        fetch(
            url_login,
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                var val = result['status'];
 
                if (String(val) === String("true")) {

                    if (String(partner_connexion) === String("partner")) {

                        setCookie("tokenmysypart", JSON.parse(result['message']).token, { path: '/' });

                        var acces_right_token_name = String(JSON.parse(result['message']).token)+"_uar";
                        var acces_right_token_val = JSON.parse(result['message']).user_access_right;

                        //acces_right_token_val = [{'key1':'val1'}, {'key2':'val2'}];

                        ///console.log(" acces_right_token_val = ", acces_right_token_val);

                        setCookie_acces_right(acces_right_token_name, acces_right_token_val, { path: '/' });

                        //setCookie("tokenmysypart", result['message']);
                        history.push("/Partner");

                    }

                    if (String(partner_connexion) === String("user")) {
                        setCookie("tokenmysych", result['message']);

                        // Verification vers quelle page les renvoyer
                        if (obj === "training" && String(objid).length > 0) {
                            // redirection vers l'affichage d'une formation
                            history.push("/Display-Detail-formation/" + objid);
                        }
                        else {
                            // Redirection par defaut vers la compte utilisateur 
                            history.push("/mysy-user-account");
                        }
                    }
                }
                else {
                    setisconnected("false");
                    setmyApimyApiMessage(result['message']);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setisconnected("false");
                setmyApimyApiMessage("Le service est momentanémentindisponible, merci de ressayer plus tard. ");

            });
    }

    const location = useLocation();

    useEffect(() => {
        setmenucompte("partner");

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {

                event.preventDefault();

                onSubmit();
            }
        };
        document.addEventListener("keydown", listener);

        if (String(menucompte) === String("user")) {
            menu_user_account();
        }
        else {
            menu_parter_account();
        }

        if (location && location.state && location.state.mysy_arg && String(location.state.mysy_arg) === "partner") {
            menu_parter_account();
        }


    }, []);



    function menu_parter_account() {
        //alert(" menu_parter_account  =  "+String(menucompte));
        setmenucompte("partner");
        document.getElementsByName("partner_account")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("user_account")[0].style.backgroundColor = "#ffffff";


    }

    function menu_user_account() {

        setmenucompte("user");
        document.getElementsByName("user_account")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("partner_account")[0].style.backgroundColor = "#ffffff";
    }


    function resetpasswd_ok(e) {

        var total = " ?? ";
        if (document.getElementsByName("sommerandom")[0].value) {
            total = document.getElementsByName("sommerandom")[0].value;
        }
        var calcaul_total = parseInt(random1) + parseInt(random2);
        if (parseInt(total) != parseInt(calcaul_total)) {
            document.getElementsByName("pasrobot")[0].style.backgroundColor = "red";

            alert(" Le total dans la zone 'je ne suis pas un robot' est incohérent. Recommencez svp :) !!!");
            return;
        }


        // Controle du mail
        var mymail = document.getElementsByName("resetemailvalue")[0].value;
        //alert(" le mail de reset  = "+mymail);
        var re = /\S+@\S+\.\S+/;
        if (re.test(mymail) === false) {
            alert("l'adresse email est incorrecte");
            return;
        }

        var form = new FormData();

        form.append("email", mymail);


        // Controle du type de compte
        if( String(typecompte) !== "partner" && String(typecompte) !== "user"){
            alert("Le type de compte est inconnu");
            document.getElementsByName("is_user_or_pro")[0].style.backgroundColor = "orangered";
            return;
        }
        form.append("account_type", typecompte);
      

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/InitUserPasswd/";

        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            setclosepopup(1);
            setinitpasswd("true");
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("true");

                setResult(res.data.message);

            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
                setResult(res.data.message);
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("false");
            setinitpasswd("false");
            //setmyApimyApiMessage("")
        })


    }

    function resetpasswd(e) {
        confirmAlert({
            title: 'Reinitialisation Mot de Passe?',
            message: 'Confirmez la reinitialisation',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => resetpasswd_ok()
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                },

            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });

    }
    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };
    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };
    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };
    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };
    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    function closepopupinitpwd() {
        setclosepopup("1");
    }

    // Cette fonction permet de cacher le mot de passer et eviter les 
    // autocompletion
    const [realpwdvalue, setrealpwdvalue] = useState("");
    const [realpwdhidenvalue, setrealpwdhidenvalue] = useState("");
    function realpwd(e) {
        const val = e.target.value;
        const last = val.charAt(val.length - 1);
        var new_val = realpwdvalue;
        var new_val_hiden = realpwdhidenvalue;

        //alert(" new_val avant saisie= "+new_val);
        new_val = new_val + last;
        new_val_hiden = new_val_hiden + "*";

        //alert("  - new_val apres sisie = "+new_val+"  new_val_hiden = "+new_val_hiden);
        setrealpwdhidenvalue(new_val_hiden);
        setrealpwdvalue(new_val);

        //setrealpwdvalue(val);
        //document.getElementById("cle_secrete").value = new_val_hiden;

    }

    /*  function displayvlaue(){
          alert(" la valeur secrete = "+realpwdvalue);
      }*/


    const handleClickShowPassword = () => {
        if (currentpawdvisible == false) {
            document.getElementsByName("pwd")[0].type = "text";
            setcurrentpawdvisible(true);
        }
        else if (currentpawdvisible == true) {
            document.getElementsByName("pwd")[0].type = "password";
            setcurrentpawdvisible(false);
        }
    };

    return (
        <div className="signin">
            <Helmet>
                <title>Connexion MySy Training Technogy</title>
                <meta name="description" content="utisateur formation connexion" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Connexion MySy Training Technogy" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/mysy-training-login/0/0"} />

            </Helmet>
            <Header />


            <div className="div_mobile">
                <div>
                    <h1 className="text_welcome">Se connecter à MySy Training</h1>
                </div>
                <div className="display_screen_600_901">
                    <div className="div_centre_master">
                        <div className="div_centre_002">
                            <Button variant="outlined" onClick={menu_user_account} className="signin_menu"
                                id="user_account" name="user_account"
                                style={{ "float": "left", "textAlign": "center", "height": "3rem" }}>Utilisateur</Button>

                            <Button variant="outlined" onClick={menu_parter_account} className="signin_menu"
                                id="partner_account" name="partner_account"
                                style={{ "float": "left", "textAlign": "center", "height": "3rem" }}>Organisme de formation</Button>

                        </div>

                        <div className="div_centre">

                            <div className="div_row_bis" >

                                <FormGroup>
                                    <Label>Votre adresse email</Label>
                                    <Input type="email" placeholder="Email" name="email" />
                                </FormGroup>

                                <FormGroup>
                                    &nbsp;
                                </FormGroup>


                                <FormGroup>
                                    <div className="label_pwd_div">
                                        <div className="label_div" >
                                            <Label>Votre mot de passe </Label>
                                        </div>
                                        <div className="show_pwd_div" onClick={handleClickShowPassword}>
                                            {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                                        </div>

                                    </div>
                                    <Input type="password" placeholder="Password" name="pwd" />
                                </FormGroup>



                                {/*String(menucompte) === String("partner") && <FormGroup autocomplete="off">
                                    <Label >Clé secrete*</Label>
                                    <Input type="password" placeholder="" name="cle_secrete" id="cle_secrete"
                                        autocomplete="new-password" />
    </FormGroup>*/}


                                {String(initpasswd) === String("true") && closepopup === 1 && <div className="okUpdateData">
                                    La demande reinitialisation a bien ete prise en compte.
                                    Verifiez votre boite email.
                                </div>}
                                <div className="forgot_pass" >
                                    {String(initpasswd) !== String("true") && String(closepopup) !== String("1")
                                        && <Popup trigger={<button className="avisformation"> Mot de passe oublié</button>}
                                            position="center" className="signin popup-reset-pwd">
                                            <div className="signin-content popup-reset-pwd-content">
                                                <div className="div_row" style={{ "textAlign": "center", "background": "orangered",
                                                "height":"2rem", "paddingTop":"5px", "fontSize":"14px", "fontWeight":"bold"  }}
                                                > Modifier le mot de passe</div>
                                                <br />

                                                <label for="exampleFormControlTextarea1">Saisir votre email</label>
                                                <br />

                                                <input type="text" id="resetemailvalue" name="resetemailvalue" rows="1" cols="20"
                                                    className="zone_mail" />
                                                <br />


                                             <div>
                                                <br/>
                                                <Tooltip className="tooltip_css" id="is_user_or_pro-tooltip" />
                                                <a data-tooltip-id="is_user_or_pro-tooltip" data-tooltip-html="Compte partenaire : création et gestion de formation <br/>
                                                compte utilisateur : non autorisé à la création et gestion de formation">
                                                    <label for="is_user_or_pro">Compte partenaire ou utilisateur * ?</label>
                                                    <br />

                                                   
                                                    <select id="is_user_or_pro" name="is_user_or_pro" style={{"width":'100%', "height":"2.5rem",
                                                    'marginTop':'5px'}}
                                                     onChange={(event) => settypecompte(event.target.value)}>
                                                        <option selected value="">Choisir </option>

                                                        <option value="partner">Partenaire </option>
                                                        <option value="user">Utilisateur </option>
                                                    </select>
                                                    </a>
                                                    <br />

                                                </div>



                                                <div className="text_pwd_crit" style={{ "textAlign": "center" }}>
                                                    Je ne suis pas un robot <br />
                                                    <div  >


                                                        <div name="pasrobot" > {random1} + {random2} =  <input
                                                            type='text'
                                                            name='sommerandom'
                                                            id='sommerandom'

                                                            {...register('sommerandom', {
                                                                required: { value: true, message: 'sommes de valeurs' },
                                                                maxLength: {
                                                                    value: 30,
                                                                }
                                                            })}

                                                            className="pasrobot_css"
                                                            placeholder='Le total est ?'
                                                        />

                                                            {errors.sommerandom && (
                                                                <span className='koUpdateData'>la sommes est incorrect</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="submit" class="btn btn-secondary btn-sm" onClick={resetpasswd_ok}>Envoyer</button> &nbsp; &nbsp; &nbsp;
                                                <button type="submit" class="btn btn-secondary btn-sm" onClick={closepopupinitpwd}>   Annuler</button>
                                            </div>

                                        </Popup>
                                    }
                                </div>
                            </div>
                            <FormGroup>
                                &nbsp;
                            </FormGroup>

                            <div className="div_row22">
                                <Button variant="contained" color="success" className="div_row22 btn_login button" onClick={onSubmit}>Connectez-Vous</Button>
                            </div>


                        </div>
                    </div>
                    {isconnected === "false" && <div className="erreurconnexion">
                        <br />
                        {myApimyApiMessage}</div>
                    }
                    {/*<div className="div_centre_social">

                    <FacebookLoginButton className="mt-3 mb-3" />
                    <GoogleLoginButton buttonText="Login" className="mt-3 mb-3" />
            </div>*/}


                    <div className="div_row_create_account" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "/create_account";
                    }}> Vous n'avez pas de compte, Créez-le !!!</div>
                </div>

                <div className="pieddepage">
                    <Footer />
                </div>

            </div>

        </div>
    );
}

export default SignIn;
