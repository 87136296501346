import React, { useState, useEffect } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import { confirmAlert } from 'react-confirm-alert'; // Import
import bannerimg from "../mysy_img/MYSY_banner_compte.png";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';
import { useCookies } from "react-cookie";

import connect_white from "../mysy_img/connect_white_v2.png";
import connect_green from "../mysy_img/connect_green.png";

const Header = () => {
    const history = useHistory();
    const stored_user = getCookie('tokenmysych');
    const stored_part = getCookie('tokenmysypart');
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych'], { path: '/' });
    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart'], { path: '/' });


    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);
    const [CategorieMenu, setCategorieMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };


    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    const CategorieMenu_Close = () => {
        setCategorieMenu(null);
    };

    const handleCategorieMenu = (event) => {
        setCategorieMenu(event.currentTarget);
    };



    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };


    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };



    const handleClassCategorie = (e) => {
        setCategorieMenu(null);
        var mymetier = "";
        mymetier = e.target.id
        //alert(" cours de : " + mymetier);
        history.push("/Recherche_new_v2/0/" + mymetier);
    };


    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/Produits-Services/")
    };

    const handleAvis = () => {
        setavisMenu(null);
        history.push("/Recherche-Article-formation")
    };

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */

        if (typeof (stored_part) != "undefined") {
            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie + "_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });
            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });


        }
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }

    const [userconnected, setuserconnected] = useState("0");
    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");

    useEffect(() => {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            //alert(" partner connected OK");
        }
    }, []);

    function handleAccountLogout() {


        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }


    const handleMyPartnerAccount = () => {
        setAccountMenu(null);
        history.push("/Partner")
    };

    function handleAccountConnexion_v2() {

        if (String(userconnected) === String("1")) {
            handleMyAccount();
        }
        else if (String(partnerconnected) === String("1")) {
            handleMyPartnerAccount();
        }
    }
    function publiecatalogue() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }

    const handleBlogMenu = (event) => {

        history.push("/Recherche-Article-formation")
    };

    const handleQuisommesNousMenu = (event) => {
        history.push("/QuiSommesNous")
    };

    return (
        <div className="header">

            <Menu
                keepMounted
                anchorEl={PartnerMenu}
                onClose={PartnerMenu_Close}
                open={Boolean(PartnerMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handlePartnerConnexion} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }} >Mon Compte</MenuItem>
                <MenuItem onClick={part_logout} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }} >Deconnexion</MenuItem>

            </Menu>

            <Menu
                keepMounted
                anchorEl={AccountMenu}
                onClose={AccountMenu_Close}
                open={Boolean(AccountMenu)}
                style={{ "top": "2rem" }}

            > <MenuItem onClick={handleMyAccount} className="mymenu_item"
                style={{ "fontSize": "0.7rem" }}>Mon Compte</MenuItem>
                <MenuItem onClick={handleAccountConnexion} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }}>Connexion</MenuItem>
                <MenuItem onClick={handleAccountCreation} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }}>Creation</MenuItem>
                <MenuItem onClick={user_logout} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }}>Deconnexion</MenuItem>
            </Menu>

            <Menu
                keepMounted
                anchorEl={ProduitMenu}
                onClose={ProduitMenu_Close}
                open={Boolean(ProduitMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleProduitService} className="mymenu_item">
                    <button className='bton_menu'> Nos produits & Services </button>
                </MenuItem>

                <MenuItem onClick={handleAvis} className="mymenu_item">
                    <button className='bton_menu'>Le blog </button>
                </MenuItem>

            </Menu>


            <Menu
                keepMounted
                anchorEl={CategorieMenu}
                onClose={CategorieMenu_Close}
                open={Boolean(CategorieMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="IT" >Informatique</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="vente" > Commerce</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="RH" > Ressources Humaines</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="marketing" >Marketting</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="graphisme" >Graphisme</button>
                </MenuItem>


                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="management" >Management</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item">
                    <button className='bton_menu' id="dev_perso" >Dev. Personnel </button>
                </MenuItem>


            </Menu>


            <div className="header_div_row2">

                <div className="header_div_row_bas">
                    <div className="header_div_001">
                        <div className="header_div_002">
                            <h1 className="header_h1_transparent">Connexion MySy Training Technogy </h1>

                            <div className="vvv" >
                                <a href={process.env.REACT_APP_BASE_URL}> <img class="img-responsive header_img_logo22" src={logowhite} alt="MySy Training Technology" /> </a>

                            </div>
                            <div className="header_menuPrincipal_left" onClick={handleCategorieMenu}>
                                Les formations
                            </div>

                        </div>
                        <div className="header_div_003">
                            <h1 className="header_h1_transparent">Connexion MySy Training Technogy </h1>

                            <div className="header_menuPrincipal_bton" onClick={handleAccountConnexion}>
                                <div style={{ "textAlign": "left" }}>
                                    {String(someoneconnected) !== String("1") && <button className="buton_ch22" onClick={handleAccountConnexion}> <img src={connect_white}
                                        style={{ "width": "25%" }} /> Connexion
                                    </button>}
                                    {String(someoneconnected) === String("1") && <button className="buton_ch22" onClick={handleAccountLogout}> Deconnexion<img src={connect_green}
                                        style={{ "width": "25%" }} />
                                    </button>}
                                </div>
                            </div>


                            {String(someoneconnected) === String("1") && <div className="header_menuPrincipal" onClick={handleAccountConnexion_v2}>
                                Mon Compte
                            </div>}

                            {String(someoneconnected) !== String("1") && <div className="header_menuPrincipal" onClick={handleAccountCreation}>
                                S'inscrire
                            </div>}

                            <div className="header_menuPrincipal" onClick={handleProduitMenu}>
                                Nos Services
                            </div>

                        <div className="header_menuPrincipal" onClick={handleQuisommesNousMenu}>
                                Qui Sommes nous?
                            </div> 


                            {/* <div className="header_menuPrincipal" onClick={handleBlogMenu}>
                                Le blog
                            </div> */}


                            <div className="header_menuPrincipal" onClick={publiecatalogue}
                                style={{ "background": "red", "borderRadius": "5px", "paddingLeft": "3px", "paddingRight": "3px" }}>
                                publier mes formations
                            </div>
                        </div>
                    </div>


                    <div className="header_text_head">
                        <hr className="header_div_004" />
                        <font style={{ "color": "#81BC3A", "fontWeight": "bold" }}>Recherche </font> de Formations & Tutoriels
                    </div>

                </div>
            </div>

        </div>
    )

}

export default Header;