import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom'
import Header from "./Header";
import { useLocation } from "react-router-dom";
import fileDownload from 'js-file-download'
import { RichTextEditor } from '@mantine/rte';
import { Editor } from '@tinymce/tinymce-react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'

import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { v4 as uuid } from "uuid";
import { isCompositeComponent } from "react-dom/test-utils";
import moment from "moment";
import allLocales from '@fullcalendar/core/locales-all'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

const locales = {
    'en-US': enUS,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})



const TestUrl_New = (props) => {

    const { courseid, tabid } = useParams();
    const [message, setMessage] = useState(null);
    const editorRef_programme = useRef(null);
    const [editorRef_programme_limite, seteditorRef_programme] = useState(1000);
    const [field_programme, setfield_programme] = useState("");
    const [editor_tochange, seteditor_tochange] = useState("");

    function editor_keyup() {
        seteditor_tochange("1");
    }

    function DataUpdated() {

    }

    function add_text() {
        console.log(" Ajouter un texte ");
        editorRef_programme.current.insertContent("<i> OKKK </i> ");
    }

    function add_nom() {
        editorRef_programme.current.insertContent(" <i>{{nom}}</i> ");
    }

    function add_prenom() {
        editorRef_programme.current.insertContent(" <i>{{prenom}}</i> ");
    }

    function add_document() {
        var programme = editorRef_programme.current.getContent();
        console.log(" doc text = ", programme);
    }

    function delete_document() {

    }

    function add_selected_fields() {

        if (!p_collection || !p_champ_table) {
            alert(" Champ invalide ");
            return;
        }
        var local_field = "{{" + String(p_collection) + "." + String(p_champ_table) + "}}";
        editorRef_programme.current.insertContent(" <b><i>" + String(local_field) + "</i></b> ");
    }



    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_champ_table, setp_champ_table] = useState();
    const [p_collection, setp_collection] = useState();


    const [p_champ_table_bis, setp_champ_table_bis] = useState();

    const [Get_Personnalisable_Collection_and_Fields_api, setGet_Personnalisable_Collection_and_Fields_api] = useState();
    const [Get_Personnalisable_Collection_and_Fields_message, setGet_Personnalisable_Collection_and_Fields_message] = useState();
    const [Get_Personnalisable_Collection_and_Fields_result, setGet_Personnalisable_Collection_and_Fields_result] = useState();
    function Get_Personnalisable_Collection_and_Fields() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Personnalisable_Collection_Fields/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Personnalisable_Collection_and_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Personnalisable_Collection_and_Fields  res.data.message r_class = " + res.data.message);

                setGet_Personnalisable_Collection_and_Fields_api("true");
                setGet_Personnalisable_Collection_and_Fields_result(res.data.message);

            }
            else {
                setGet_Personnalisable_Collection_and_Fields_api("false");
                setGet_Personnalisable_Collection_and_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Personnalisable_Collection_and_Fields = ', error);
            setGet_Personnalisable_Collection_and_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Personnalisable_Collections_api, setGet_Personnalisable_Collections_api] = useState();
    const [Get_Personnalisable_Collections_message, setGet_Personnalisable_Collections_message] = useState();
    const [Get_Personnalisable_Collections_result, setGet_Personnalisable_Collections_result] = useState();
    function Get_Personnalisable_Collections() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Personnalisable_Collection/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Personnalisable_Collections  res.data.status = " + res.data.status);
                //console.log(" In Get_Personnalisable_Collections  res.data.message r_class = " + res.data.message);

                setGet_Personnalisable_Collections_api("true");
                setGet_Personnalisable_Collections_result(res.data.message);

            }
            else {
                setGet_Personnalisable_Collections_api("false");
                setGet_Personnalisable_Collections_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Personnalisable_Collections = ', error);
            setGet_Personnalisable_Collections_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [mysy_events, setmysy_events] = useState();

    const [Get_List_Agenda_Events_api, setGet_List_Agenda_Events_api] = useState();
    const [Get_List_Agenda_Events_message, setGet_List_Agenda_Events_message] = useState();
    const [Get_List_Agenda_Events_result, setGet_List_Agenda_Events_result] = useState();
    function Get_List_Agenda_Events(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Agenda_Events  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_api("true");
                setGet_List_Agenda_Events_result(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {


                    var local_val = {
                        'title': String(JSON.parse(obj).event_title),
                        'start': String(JSON.parse(obj).event_start),
                        'end': String(JSON.parse(obj).event_end),
                        'id': String(JSON.parse(obj)._id),
                    }

                    tab_event.push(local_val);



                });
                console.log(" #### tab_event =", tab_event);
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                //history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_api("false");
                setGet_List_Agenda_Events_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Agenda_Events = ', error);
            setGet_List_Agenda_Events_api("false");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Get_List_Agenda_Events();
        Get_Personnalisable_Collections();
        Get_Personnalisable_Collection_and_Fields();
        Get_Partner_Object_Specific_Fields("ressource_humaine");

    }, [])


    const [p_document_name, setp_document_name] = useState();
    const [p_document_type, setp_document_type] = useState();
    const [p_document_ref_interne, setp_document_ref_interne] = useState();
    const [p_document_sujet, setp_document_sujet] = useState();
    const [p_document_nom, setp_document_nom] = useState();

    const [Add_one_Partner_Document_api, setAdd_one_Partner_Document_api] = useState();
    const [Add_one_Partner_Document_message, setAdd_one_Partner_Document_message] = useState();
    const [Add_one_Partner_Document_result, setAdd_one_Partner_Document_result] = useState();
    function Add_one_Partner_Document() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        if (p_document_ref_interne)
            form.append("ref_interne", p_document_ref_interne);
        else {
            alert(" Vous devez definir une reference interne");
            return;
        }


        if (p_document_type)
            form.append("type_doc", p_document_type);
        else {
            alert(" Vous devez definir un type de document");
            return;
        }


        if (p_document_sujet)
            form.append("sujet", p_document_sujet);
        else {
            alert(" Vous devez definir un sujet");
            return;
        }



        if (editorRef_programme) {
            var programme = editorRef_programme.current.getContent();
            form.append("contenu_doc", programme);
        }
        else {
            form.append("contenu_doc", "");
        }

        console.log(" Add_one_Partner_Document affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Document/";

        axios.post(myurl, form).then(res => {
            console.log(" In Add_one_Partner_Document  res.data.status = " + res.data.status);
            console.log(" In Add_one_Partner_Document  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_one_Partner_Document_api("true");
                setAdd_one_Partner_Document_result(res.data.message);

                alert(res.data.message);

            }
            else {
                setAdd_one_Partner_Document_api("false");
                setAdd_one_Partner_Document_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Add_one_Partner_Document = ' + error);
            setAdd_one_Partner_Document_api("false");
            alert(" Impossible d'ajouter le document");

        })
    }

    const myEventsList = []
    const [events, setEvents] = useState([]);
    const handleSelect = (info) => {

        const { start, end } = info;
        const eventNamePrompt = prompt("Enter, Nom evenment");
        if (eventNamePrompt) {

            setEvents([
                ...events,
                {
                    start,
                    end,
                    title: eventNamePrompt,
                    id: uuid(),
                },
            ]);
        }
    };

    const EventItem = ({ info }) => {
        const { event } = info;
        return (
            <div>
                <p>ch - {event.title}</p>
            </div>
        );
    };


    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
    
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
    
    */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_list_specific_field_partner_by_object/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }



    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values = []

    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
    }
    // -- end champs specifiques 

    const renderEventContent = (eventInfo) => {
        console.log(eventInfo);
        return (
            <>
                <b>{eventInfo.timeText}</b><br />
                <i>{eventInfo.event.title}</i>
            </>
        )
    };

    const handleEventClick = (arg) => {
        alert(arg.event.title)
        alert(arg.event.start)
        setdialogMessage(" coucou cherif");
        setOpen(true);
    }

    const [open, setOpen] = React.useState(false);

    const [dialogMessage, setdialogMessage] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const handleClose_buton = () => {
        setOpen(false);
    };

    const [contextMenu, setContextMenu] = React.useState(null);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose22 = () => {
        alert(" 22");
        setContextMenu(null);
    };

    const handleClose23 = () => {
        alert(" 23");
        setContextMenu(null);
    };
    const handleClose24 = () => {
        alert(" 24");
        setContextMenu(null);
    };
    const handleClose25 = () => {
        setContextMenu(null);
    };
    return (
        <div className="testurl_new">
            <Helmet>
                <title>testurl_new</title>
                <meta name="description" content="{this.metaDetails.description}" />
                <meta name="keywords" content="{this.metaDetails.keywords}" />
            </Helmet>

            <Header />

            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>Subscribe</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogMessage}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose_buton}>Cancel</Button>
                    <Button onClick={handleClose_buton}>Subscribe</Button>
                </DialogActions>
            </Dialog>


            <div className="div_mobile">
                <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>

                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="detail_session_class"
                        id="detail_session_class"
                        value={"uuuuuuuuuuu"}
                        //onContextMenu={handleContextMenu}

                    >
                    </TextField>
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <MenuItem onClick={handleClose22} style={{"width":"100%"}}>Copy</MenuItem>
                        <MenuItem onClick={handleClose22} style={{"width":"100%"}}>Print</MenuItem>
                        <MenuItem onClick={handleClose22} style={{"width":"100%"}}>Highlight</MenuItem>
                        <MenuItem onClick={handleClose22} style={{"width":"100%"}}>Email</MenuItem>
                    </Menu>

                </div>


                <h3> Page de test * testurl_new </h3>
                parametres courseid = {courseid}, tabid = {tabid}
                <br />

                <Button variant="contained" className="bton_enreg"
                    onClick={afficher_champs_spec}>
                    Display Spec fields
                </Button>

                <br />

                {rows_champs_specifics &&
                    rows_champs_specifics.map((champ_spec) => (
                        <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                            <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name={JSON.parse(champ_spec).field_name}
                                id={JSON.parse(champ_spec).field_name}

                                InputLabelProps={{
                                    shrink: true,
                                }}


                                className="disabled_style"
                                onChange={change_champs_spec_handle}


                            />
                        </div>
                    ))}

                <br />

                <Button variant="contained" className="bton_enreg"
                    onClick={"get_champs_spec"}>
                    Display valeurs des champs spec
                </Button>

                <nav> Liste des valeurs des champs spec </nav>
                {rows_champs_specifics &&
                    rows_champs_specifics.map((champ_spec) => (
                        <div className="session_caract"> '{champ_spec.field_value}'  </div>
                    ))}

                <br />

                <div className="div_row">
                    liste des events =
                    {Get_List_Agenda_Events_result && <div> {Get_List_Agenda_Events_result} </div>}

                </div>

                <div className="div_row">
                    local events =
                    <button onClick={(e) => {
                        console.log("#### local events =  ", events);
                        setdialogMessage(" coucou cherif");
                        setOpen(true);
                    }}>
                        display events
                    </button>

                </div>

                <div style={{ "width": "90%", "marginLeft": "1%" }}>
                    <FullCalendar
                        locales={allLocales}
                        locale={'fr'}
                        editable
                        selectable
                        events={events}
                        //events={mysy_events}

                        select={handleSelect}
                        headerToolbar={{
                            start: "today prev next",
                            center: "title",
                            end: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        //eventContent={(info) => <EventItem info={info} />}
                        //plugins={[timeGridPlugin]}
                        plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                        slotMinTime="07:00"
                        slotMaxTime="20:00"
                        scrollTime='08:00'
                        displayEventEnd={true}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}


                    />;



                </div>

                <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_name"
                        label="Choisir un document"
                        select
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_name}
                        onChange={(e) => {
                            setp_document_name(e.target.value);
                        }}
                    >
                        <MenuItem value="convocation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Convocation &nbsp;</MenuItem>
                        <MenuItem value="document2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Document 2  &nbsp;</MenuItem>
                        <MenuItem value="document3" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Document 3&nbsp;</MenuItem>
                        <MenuItem value="document4" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Document 4 &nbsp;</MenuItem>


                    </TextField>
                </div>

                {p_document_name && <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_type"
                        label="Type de document"
                        select
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_type}
                        onChange={(e) => {
                            setp_document_type(e.target.value);

                        }}
                    >
                        <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Pdf &nbsp;</MenuItem>
                        <MenuItem value="sms" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >SMS  &nbsp;</MenuItem>
                        <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email&nbsp;</MenuItem>


                    </TextField>
                </div>
                }

                {p_document_name && <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_ref_interne"
                        label="Code Document"
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_ref_interne}
                        onChange={(e) => {
                            setp_document_ref_interne(e.target.value);

                        }}
                    />

                </div>}

                {p_document_name && <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="document_sujet"
                        label="Sujet"
                        sx={{ m: 1, width: '100%' }}
                        value={p_document_sujet}
                        onChange={(e) => {
                            setp_document_sujet(e.target.value);

                        }}
                    />

                </div>}



                <div className="div_row">
                    <div style={{ "width": "48%", "marginLeft": "1rem", "float": "left" }}>
                        <div className="titre_training_text"> Programme (max 1000 caractères) </div>
                        <Editor
                            id="cherif"
                            onInit={(evt, editor) => editorRef_programme.current = editor}
                            initialValue={field_programme}
                            onKeyUp={editor_keyup}
                            onEditorChange={(newValue, editor) => {
                                if (String(editor_tochange) === "1") {
                                    //alert(" change");
                                    DataUpdated();
                                    seteditor_tochange("");
                                }
                            }}
                            init={{
                                resize: false,
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                    'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                ],
                                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                            }}
                        />
                        <Button className="bton_enreg" onClick={Add_one_Partner_Document} style={{ "margin": "1rem" }}
                        >Ajouter &nbsp;

                        </Button>


                        <Button className="bton_suppr" onClick={delete_document} style={{ "margin": "1rem" }}
                        > Supprimer &nbsp;

                        </Button>
                    </div>



                    <div style={{ "width": "48%", "marginLeft": "1rem", "float": "left" }}>
                        <div className="titre1"> Action </div>


                        <div className="div_row">

                            <div className="session_caract"> Choisir un objet  <br />
                                <TextField
                                    name="collection"
                                    id="collection"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_collection}
                                    onChange={(e) => {
                                        setp_collection(e.target.value);


                                    }}
                                >

                                    {Get_Personnalisable_Collections_result &&
                                        Get_Personnalisable_Collections_result.map((mycollection) => (
                                            <MenuItem key={JSON.parse(mycollection).collection_technical_name}
                                                value={JSON.parse(mycollection).collection_technical_name}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mycollection).collection_fonctional_name}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}


                                </TextField>

                            </div>
                            {<div className="session_caract"> Choisir un champ  <br />
                                <TextField
                                    name="champ_table"
                                    id="champ_table"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_champ_table}
                                    onChange={(e) => {
                                        setp_champ_table(e.target.value);
                                    }}
                                >

                                    {Get_Personnalisable_Collection_and_Fields_result &&
                                        Get_Personnalisable_Collection_and_Fields_result.filter((local_mymanager) => JSON.parse(local_mymanager).collection_technical_name === p_collection).map((mycollection) => (
                                            <MenuItem key={JSON.parse(mycollection).field_technical_name}
                                                value={JSON.parse(mycollection).field_technical_name}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mycollection).field_fonctional_name}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}
                                </TextField>

                            </div>}




                        </div>



                        <Button className="bton_enreg" onClick={add_selected_fields} style={{ "margin": "1rem" }}
                        >Ajouter Champ Choisi &nbsp;

                        </Button>

                    </div>
                </div>
            </div>
        </div >

    )
}


export default TestUrl_New;