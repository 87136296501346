import DisplayDetailClass from "../components/DisplayDetailClass";
import DisplayDetailClass_new_v2 from "../components/DisplayDetailClass_new_v2";
import Navigation from "../components/Navigation";

export default function displaydetailclass() {
    return (
        <div>
           <div >
            <Navigation />
            </div>
            <DisplayDetailClass_new_v2 />
            
        </div>
    );
}