import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { getCookie, setCookie } from 'react-use-cookie';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Footer from "./Fotter";
import Header from "./Header";
import { Button, } from "reactstrap";
import axios from "axios";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import { DataGrid, GridToolbar, frFR, GridCellParams } from '@mui/x-data-grid';
import { AiFillFolderOpen, AiFillMinusSquare } from "react-icons/ai";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ToggleSwitch from "./ToggleSwitch";
import { format } from 'date-fns'
import moment from "moment";
import fileDownload from 'js-file-download'

import { confirmAlert } from 'react-confirm-alert';

import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import parse from 'html-react-parser'
import { Editor } from '@tinymce/tinymce-react';

import add_plus from "../mysy_img/plus.png";
import gif_new from "../mysy_img/new_icon_gif.gif";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import { useHistory } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CiTrash } from "react-icons/ci";
import { ConstructionOutlined } from "@mui/icons-material";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";

import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";

import { Fab } from "@material-ui/core";


function GestionAdministrative(props) {

    const history = useHistory();
    const { internal_url } = useParams();
    const [mypresence, setmypresence] = useState("");
    const [mypublished, setmypublished] = useState("");
    const [mydistance, setmydistance] = useState("");

    const [classtitle, setclasstitle] = useState("");

    const columns_preinscrit = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'email', headerName: 'email', width: 150, hideable: false, },
        { field: 'nom', headerName: 'nom', width: 150, hide: false, editable: true },
        { field: 'prenom', headerName: 'prenom', width: 150, hide: false, editable: true },
        { field: 'employeur', headerName: 'Employeur', width: 200, hide: false, editable: true },
        { field: 'opco', headerName: 'Centre Opco', width: 150, hide: false, editable: true },
        { field: 'modefinancement', headerName: 'Financement', width: 150, hide: false, editable: true },
        { field: 'amount', headerName: 'Montant Formation', width: 150, type: 'number', hideable: false, flex: 1, editable: true },
        {
            field: "update", headerName: 'Mise à jour',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_accept(event, cellValues);
                            }}
                        >
                            <FcAcceptDatabase />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    Confirmer la mise à jour des informations du stagiaire
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_update_data(event, cellValues);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>

                );
            }
        },

        {
            field: "valide", headerName: 'Valider',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_accept(event, cellValues);
                            }}
                        >
                            <FcApproval />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera définitivement validée. <br />
                                    Il (le stagiaire) sera automatiquement notifié par email de la validation de son inscription. <br />
                                    Le stagiaire passera de l'onglet "préinscrit" vers l'onglet "inscription".<br />
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_accept(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>

                );
            }
        },

        {
            field: "refuse", headerName: 'Refuser',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_refuse(event, cellValues);
                            }}
                        >
                            <FcCancel />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> refusée</font></i>. <br />
                                    Il (le stagiaire) sera automatiquement <i><font color="red"> notifié par email du refus de son inscription. </font></i> <br />
                                    <br /> Motif du refus : <br />
                                    <textarea name='motif_refus' id="motif_refus" style={{ "width": "80%" }}
                                        rows="3" maxlength="180" />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_refuse(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const columns_inscrit = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'email', headerName: 'emails', width: 200, hideable: false, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'nom', headerName: 'nom', width: 150, hide: false, editable: true, resizable: true },
        { field: 'prenom', headerName: 'prenom', width: 150, hide: false, editable: true },
        { field: 'employeur', headerName: 'Employeur', width: 200, hide: false, editable: true },
        { field: 'opco', headerName: 'Centre Opco', width: 150, hide: false, editable: true },
        { field: 'modefinancement', headerName: 'Financement', width: 150, hide: false, editable: true },
        { field: 'amount', headerName: 'Montant Formation', width: 150, type: 'number', hideable: false, flex: 1, editable: true },
        {
            field: "sendmail", headerName: 'Email Confirmation',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick_resend_conf_mail(event, cellValues);
                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        },

        {
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Display_Detail_Inscription(event, cellValues);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: "Imprimer", headerName: 'Imprimer detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Print_Detail_Inscription(event, cellValues);
                        }}
                    >
                        <FcPrint />

                    </Button>

                );
            }
        },
        {
            field: "annulation", headerName: 'Annulation',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_refuse(event, cellValues);
                            }}
                        >
                            <FcCancel />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> définitivement annulée</font></i>. <br />
                                    Il (le stagiaire) sera automatiquement <i><font color="red"> notifié par email de l'annulation de son inscription. </font></i> <br />
                                    <br /> Motif du annulation : <br />
                                    <textarea name='motif_refus' id="motif_refus" style={{ "width": "80%" }}
                                        rows="3" maxlength="180" />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_refuse(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },


    ]

    const columns_list_emargement = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'date', headerName: 'date', width: 100, hide: true, flex: 1 },
        { field: 'sequence_start', headerName: 'Debut', width: 100, hideable: false, flex: 1 },
        { field: 'sequence_end', headerName: 'Fin', width: 100, hideable: false, flex: 1 },
        { field: 'is_present', headerName: 'Présent', width: 100, hide: false, editable: true, type: 'boolean', },

        { field: 'email', headerName: 'email', width: 250, hideable: false, flex: 1 },
        { field: 'nom', headerName: 'nom', width: 150, hide: false, editable: true },
        { field: 'prenom', headerName: 'prenom', width: 150, hide: false, editable: false },
       
        {
            field: "valide", headerName: 'Valider',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick_valide_emargement(event, cellValues);
                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        },

    ]

    const columns_list_evaluation = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'email', headerName: 'email', width: 200, hideable: true, resizable: true },
        { field: 'nom', headerName: 'nom', width: 200, hide: false, editable: true },
        { field: 'prenom', headerName: 'prenom', width: 200, hide: false, editable: false },
        {
            field: 'eval_status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Envoyé";
                else if (String(params.value) === "1")
                    return "Evalué";
                else
                    return "A envoyer";
            },
        },
        { field: 'eval_eval', headerName: 'Evaluation', width: 100, editable: false, flex: true },
        { field: 'eval_note', headerName: 'Note', width: 100, editable: false, },
        { field: 'eval_pedagogie', headerName: 'Pédagogie', width: 100, editable: false, },
        { field: 'eval_date', headerName: 'Date evalution', width: 100, editable: false, },
        {/*
            field: "valide", headerName: 'Valider',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick_valide_emargement(event, cellValues);
                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        */},

    ]

    function handleClick_delete(event, cellValues) {
        // Recuperation du motif du refus :


        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;

        DeleteStagiaireData(nom, prenom, email);
    }


    const [DeleteStagiaireData_api, setDeleteStagiaireData_api] = useState();
    const [DeleteStagiaireData_message, setDeleteStagiaireData_message] = useState();
    const [DeleteStagiaireData_result, setDeleteStagiaireData_result] = useState();
    function DeleteStagiaireData(nom, prenom, email) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (mysession.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", mysession);
        form.append("nom", nom);
        form.append("prenom", prenom);
        form.append("email", email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/DeleteAttendeeInscription/";

        axios.post(myurl, form).then(res => {
            //console.log(" In DeleteStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In DeleteStagiaireData  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDeleteStagiaireData_api("true");
                setDeleteStagiaireData_result(res.data.message);
                GetListePreinscrit(mysession);

                setretval_ch("1");
                alert(" La mise à jour été correctement faite.")
            }
            else {
                setDeleteStagiaireData_api("false");
                setDeleteStagiaireData_message(res.data.message);
                setretval_ch("-1");
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('DeleteStagiaireData : Not good man :( mysearchtext = ' + error);
            setDeleteStagiaireData_api("false");
            setretval_ch("-1");
            alert(" Impossible de supprimer le stagaire");
        })
    }


    const [UpdateStagiaireEmargement_api, setUpdateStagiaireEmargement_api] = useState();
    const [UpdateStagiaireEmargement_message, setUpdateStagiaireEmargement_message] = useState();
    const [UpdateStagiaireEmargement_result, setUpdateStagiaireEmargement_result] = useState();
    function handleClick_valide_emargement(event, cellValues) {

        var date = cellValues.row.date;
        var email = cellValues.row.email;
        var matin = cellValues.row.matin;
        var matin_val = "";
        var is_present_val = "";


        console.log(" ### cellValues.row = ", cellValues.row);
        var is_present = cellValues.row.is_present;

        if (is_present === true) {
            is_present_val = "1"
        } else if (matin === false) {
            is_present_val = "0"
        }


        /*console.log(" ### date , email, matin,matin_val,  apresmidi, apresmidi_val = ",
            date, email, matin, matin_val, apresmidi, apresmidi_val);*/

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", cellValues.row._id);
        form.append("session_id", selected_session_id);
        form.append("email", email);
        form.append("date", date);
        form.append("is_present", is_present_val);
       
        console.log(" ## form =  ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateUserEmargementDate/";
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setUpdateStagiaireEmargement_api("true");
                setUpdateStagiaireEmargement_result(res.data.message);
                GetListeEmargement(selected_session_id);
                alert(" La mise à jour a été bien faite");
            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setUpdateStagiaireEmargement_api("false");
                setUpdateStagiaireEmargement_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('handleClick_valide_emargement : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireEmargement_api("false");
            alert(" Impossible de valider l'émargement");

        })

    }


    function handleClick_valide_emargement_One(tab_data) {

        var date = tab_data.date;
        var email = tab_data.email;
        var matin = tab_data.matin;
        var matin_val = "";
        var apresmidi = tab_data.apresmidi;
        var apresmidi_val = "";

        var is_present_val = "";

        var is_present = tab_data.is_present;
        var is_present = "";

        if (is_present === true) {
            is_present_val = "1"
        } else if (matin === false) {
            is_present_val = "0"
        }


        /*console.log(" ### date , email, matin,matin_val,  apresmidi, apresmidi_val = ",
            date, email, matin, matin_val, apresmidi, apresmidi_val);*/

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", mysession);
        form.append("email", email);
        form.append("date", date);
         form.append("is_present", is_present);
        form.append("class_internal_url", internal_url);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateUserEmargementDate/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setUpdateStagiaireEmargement_api("true");
                setUpdateStagiaireEmargement_result(res.data.message);
                GetListeEmargement(mysession);
            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setUpdateStagiaireEmargement_api("false");
                setUpdateStagiaireEmargement_message(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('handleClick_valide_emargement : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireEmargement_api("false");

        })

    }


    /*
    # status :  0 ==> Preinscription
    # status : 1 ==> Inscription validée
    # status : -1 ==> Inscription annulée
    """
    */
    function handleClick_accept(event, cellValues) {

        var montant = String(cellValues.row.amount);
        if (parseFloat(montant).toFixed(2) <= 0) {
            alert(" Le montant de la formation est incorrect");
            return;
        }

        if (String(montant) === "undefined") {
            alert(" Le montant de la formation est incorrect");
            return;
        }



        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;
        var object_id = cellValues.row._id;

        var opco = cellValues.row.opco;
        if (String(opco) === "undefined") {
            opco = ""
        }

        var modefinancement = cellValues.row.modefinancement;
        if (String(modefinancement) === "undefined") {
            modefinancement = ""
        }

        var status = "1";
        var employeur = cellValues.row.employeur;
        if (String(employeur) === "undefined") {
            employeur = ""
        }


        UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, "", object_id);
    }

    function handleClick_update_data(event, cellValues) {

        var montant = String(cellValues.row.amount);
        if (String(montant) === "undefined") {
            montant = "0"
        }

        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;
        var object_id = cellValues.row._id;

        var opco = cellValues.row.opco;
        if (String(opco) === "undefined") {
            opco = ""
        }

        var modefinancement = cellValues.row.modefinancement;
        if (String(modefinancement) === "undefined") {
            modefinancement = ""
        }

        var status = "0";

        var employeur = cellValues.row.employeur;
        if (String(employeur) === "undefined") {
            employeur = ""
        }

        var comment = "";

        UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, comment, object_id);
    }

    function handleClick_refuse(event, cellValues) {
        // Recuperation du motif du refus :
        var motif_refus = document.getElementById("motif_refus").value;

        var montant = String(cellValues.row.amount);
        if (String(montant) === "undefined") {
            montant = "0"
        }

        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;
        var object_id = cellValues.row._id;

        var opco = cellValues.row.opco;
        if (String(opco) === "undefined") {
            opco = ""
        }

        var modefinancement = cellValues.row.modefinancement;
        if (String(modefinancement) === "undefined") {
            modefinancement = ""
        }

        var status = "-1";
        var employeur = cellValues.row.employeur;
        if (String(employeur) === "undefined") {
            employeur = ""
        }

        UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, motif_refus, object_id);
    }

    const [submenu, setsubmenu] = useState("preinscrit");
    function submenu_preinscrit() {
        setsubmenu("preinscrit");
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();
        setdisplay_detail_insc();

        document.getElementById('menu_preinscrit').style.background = "#104277";
        document.getElementById('menu_preinscrit').style.color = "#ffffff";

        document.getElementById('menu_inscrit').style.background = "#d8edfc";
        document.getElementById('menu_inscrit').style.color = "#3b3e40";

        document.getElementById('menu_emarge').style.background = "#d8edfc";
        document.getElementById('menu_emarge').style.color = "#3b3e40";

        document.getElementById('menu_eval').style.background = "#d8edfc";
        document.getElementById('menu_eval').style.color = "#3b3e40";


    }

    const [nb_stagiaire_Preinscrit, setnb_stagiaire_Preinscrit] = useState("0");
    const [nb_stagiaire_Inscrit, setnb_stagiaire_Inscrit] = useState("0");
    function submenu_inscrit() {
        setsubmenu("inscrit");
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();


        document.getElementById('menu_inscrit').style.background = "#104277";
        document.getElementById('menu_inscrit').style.color = "#ffffff";

        document.getElementById('menu_preinscrit').style.background = "#d8edfc";
        document.getElementById('menu_preinscrit').style.color = "#3b3e40";

        document.getElementById('menu_emarge').style.background = "#d8edfc";
        document.getElementById('menu_emarge').style.color = "#3b3e40";

        document.getElementById('menu_eval').style.background = "#d8edfc";
        document.getElementById('menu_eval').style.color = "#3b3e40";


    }

    function submenu_divers() {
        setsubmenu("divers");
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();
    }

    function submenu_emargement() {
        setsubmenu("emargement");
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();
        setdisplay_detail_insc();

        document.getElementById('menu_emarge').style.background = "#104277";
        document.getElementById('menu_emarge').style.color = "#ffffff";

        document.getElementById('menu_preinscrit').style.background = "#d8edfc";
        document.getElementById('menu_preinscrit').style.color = "#3b3e40";

        document.getElementById('menu_inscrit').style.background = "#d8edfc";
        document.getElementById('menu_inscrit').style.color = "#3b3e40";

        document.getElementById('menu_eval').style.background = "#d8edfc";
        document.getElementById('menu_eval').style.color = "#3b3e40";
    }

    function submenu_evaluation() {
        setsubmenu("evaluation");
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();
        setdisplay_detail_insc();

        document.getElementById('menu_eval').style.background = "#104277";
        document.getElementById('menu_eval').style.color = "#ffffff";

        document.getElementById('menu_preinscrit').style.background = "#d8edfc";
        document.getElementById('menu_preinscrit').style.color = "#3b3e40";

        document.getElementById('menu_inscrit').style.background = "#d8edfc";
        document.getElementById('menu_inscrit').style.color = "#3b3e40";

        document.getElementById('menu_emarge').style.background = "#d8edfc";
        document.getElementById('menu_emarge').style.color = "#3b3e40";

    }



    const [UpdateStagiaireData_api, setUpdateStagiaireData_api] = useState();
    const [UpdateStagiaireData_message, setUpdateStagiaireData_message] = useState();
    const [UpdateStagiaireData_result, setUpdateStagiaireData_result] = useState();
    function UpdateStagiaireData(nom, prenom, email, status, price, opco, modefinancement, employeur, comment, object_id) {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (mysession.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", mysession);
        form.append("nom", nom);
        form.append("prenom", prenom);
        form.append("email", email);
        form.append("employeur", employeur);
        form.append("status", status);
        form.append("price", price);
        form.append("opco", opco);
        form.append("modefinancement", modefinancement);
        form.append("comment", comment);
        form.append("_id", object_id);

        form.append("_id", selected_id);
        form.append("client_rattachement_id", p_detail_client_rattachement_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdateStagiaireData_api("true");
                setUpdateStagiaireData_result(res.data.message);
                GetListePreinscrit(mysession);
                GetListeInscrit(mysession);
                GetListeEmargement(mysession);
                GetListeEvaluation(mysession);
                setretval_ch("1");
                alert(" La mise à jour été correctement faite.")
            }
            else {
                setUpdateStagiaireData_api("false");
                setUpdateStagiaireData_message(res.data.message);
                setretval_ch("-1");
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireData_api("false");
            setretval_ch("-1");
            alert(" Impossible d'ajouter le participant")

        })
    }


    //const [GetCurrentClass_session, setGetCurrentClass_session] = useState();

    //const data = [];
    //const [mydata, setmydata] = useState([]);
    const [p_detail_external_code, setp_detail_external_code] = useState("");
    const [p_detail_title, setp_detail_title] = useState("");
    const [p_detail_trainer, setp_detail_trainer] = useState("");


    const [GetCurrentClass_api, setGetCurrentClass_api] = useState();
    const [GetCurrentClass_message, setGetCurrentClass_message] = useState();
    const [GetCurrentClass_result, setGetCurrentClass_result] = useState();
    function GetCurrentClass(event) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("internal_url", internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentClass  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentClass  res.data.message r_class = " + res.data.message);
                setGetCurrentClass_api("true");
                setGetCurrentClass_result(res.data.message);
                var mylocaltraining = JSON.parse(res.data.message);

                if (mylocaltraining.external_code)
                    setp_detail_external_code(mylocaltraining.external_code);

                if (mylocaltraining.title) {
                    setp_detail_title(mylocaltraining.title);
                    setclasstitle(mylocaltraining.title);
                }


                if (mylocaltraining.trainer)
                    setp_detail_trainer(mylocaltraining.trainer);



            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetCurrentClass_api("false");
                setGetCurrentClass_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ', error);
            setGetCurrentClass_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rowss, setRows] = useState([]);
    const [GetListePreinscrit_api, setGetListePreinscrit_api] = useState();
    const [GetListePreinscrit_meassage, setGetListePreinscrit_meassage] = useState();
    const [GetListePreinscrit_result, setGetListePreinscrit_result] = useState([])
    function GetListePreinscrit(localsessionid) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", localsessionid);
        form.append("status", "0");

        /*

       /!\ : Cette fonction a besoin d'un token actif
       */

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide");
            history.push("/Connexion");
            return;
        }



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllClassStagiaire/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_result(data['message']);
                setRows(data['message']);
                if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else if (String(data['status']) === String("true")) {
                    setGetListePreinscrit_api("true");
                    setnb_stagiaire_Preinscrit(data['message'].length);
                }
                else {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_api("false");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetListePreinscrit_api("false");
            });

    }


    const [GetCurrentClass_trainingsession_api, setGetCurrentClass_trainingsession_api] = useState();
    const [GetCurrentClass_trainingsession_message, setGetCurrentClass_trainingsession_message] = useState();
    const [GetCurrentClass_trainingsession_result, setGetCurrentClass_trainingsession_result] = useState();
    function GetCurrentClass_trainingsession(event) {

        var form = new FormData();

        form.append("class_internal_url", internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionFormation_List/";

        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
                setGetCurrentClass_trainingsession_api("true");
                setGetCurrentClass_trainingsession_result(res.data.message);
            }
            else {
                setGetCurrentClass_trainingsession_api("false");
                setGetCurrentClass_trainingsession_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
            setGetCurrentClass_trainingsession_api("false");
            //setmyApimyApiMessage("")
        })
    }


    /* Envoi de l'envoi de l'email de confirmation de la validation de l'inscription */
    function handleClick_resend_conf_mail(event, cellValues) {
        var email = cellValues.row.email;
        //alert(" confirmation email = " + email);
        SendInscriptionConfirmation(email);

    }

    const [SendInscriptionCancell_api, setSendInscriptionCancell_api] = useState();
    const [SendInscriptionCancell_message, setSendInscriptionCancell_message] = useState();
    const [SendInscriptionCancell_result, setSendInscriptionCancell_result] = useState();
    function SendInscriptionCancell(email) {

        clean_old_messages();
        setUpdateStagiaireData_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (mysession.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", mysession);
        form.append("email", email);

        setLoading(true);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CancelAttendeeInscription/";

        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSendInscriptionCancell_api("true");
                setSendInscriptionCancell_result(res.data.message);

                GetListePreinscrit(mysession);
                GetListeInscrit(mysession);
                GetListeEmargement(mysession);
                GetListeEvaluation(mysession);
                submenu_inscrit();

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendInscriptionCancell_api("false");
                setSendInscriptionCancell_message(res.data.message);

            }

        }).catch((error) => {

            setLoading(false);
            console.warn('SendInscriptionCancell : Not good man :( mysearchtext = ' + error);
            setSendInscriptionCancell_api("false");

        })
    }


    const [SendInscriptionConfirmation_api, setSendInscriptionConfirmation_api] = useState();
    const [SendInscriptionConfirmation_message, setSendInscriptionConfirmation_message] = useState();
    const [SendInscriptionConfirmation_result, setSendInscriptionConfirmation_result] = useState();
    function SendInscriptionConfirmation(email) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (mysession.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", mysession);
        form.append("email", email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendInscriptionConfirmation/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSendInscriptionConfirmation_api("true");
                setSendInscriptionConfirmation_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendInscriptionConfirmation_api("false");
                setSendInscriptionConfirmation_message(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('SendInscriptionConfirmation : Not good man :( mysearchtext = ' + error);
            setSendInscriptionConfirmation_api("false");

        })
    }

    /* Recuperation de la liste des inscrits */
    const [selectionModel_insc, setSelectionModel_insc] = React.useState([]);
    const [rowss_insc, setRows_insc] = useState([]);
    const [GetListePreinscrit_insc_api, setGetListePreinscrit_insc_api] = useState();
    const [GetListePreinscrit_insc_meassage, setGetListePreinscrit_insc_meassage] = useState();
    const [GetListePreinscrit_insc_result, setGetListePreinscrit_insc_result] = useState([])
    function GetListeInscrit(localsessionid) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", localsessionid);
        form.append("status", "1");

        /*

/!\ : Cette fonction a besoin d'un token actif
*/

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllClassStagiaire/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log(' GetListeInscrit :  Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_insc_result(data['message']);
                setRows_insc(data['message']);
                if (String(data['status']) === String("true")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_insc_api("true");
                    setnb_stagiaire_Inscrit(data['message'].length);
                } else if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else {
                    setGetListePreinscrit_insc_api("false");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetListePreinscrit_insc_api("false");
            });

    }

    /* Recuperation de la liste d'emargement */
    const [selectionModel_emarg, setSelectionModel_emarg] = React.useState([]);
    const [rowss_emarg, setRows_emarg] = useState([]);
    const [GetListePreinscrit_emarg_api, setGetListePreinscrit_emarg_api] = useState();
    const [GetListePreinscrit_emarg_meassage, setGetListePreinscrit_emarg_meassage] = useState();
    const [GetListePreinscrit_emarg_result, setGetListePreinscrit_emarg_result] = useState([])
    function GetListeEmargement(localsessionid) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", localsessionid);

        /*

      /!\ : Cette fonction a besoin d'un token actif
      */

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetTableauEmargement/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_emarg_result(data['message']);
                setRows_emarg(data['message']);
                if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else if (String(data['status']) === String("true")) {
                    setGetListePreinscrit_emarg_api("true");

                } else {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_emarg_api("false");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetListePreinscrit_emarg_api("false");
            });

    }


    /*  Recuperation des evaluations */
    const [selectionModel_evaluation, setSelectionModel_evaluation] = React.useState([]);
    const [rowss_evaluation, setRows_evaluation] = useState([]);
    const [GetListePreinscrit_evaluation_api, setGetListePreinscrit_evaluation_api] = useState();
    const [GetListePreinscrit_evaluation_meassage, setGetListePreinscrit_evaluation_meassage] = useState();
    const [GetListePreinscrit_evaluation_result, setGetListePreinscrit_evaluation_result] = useState([])
    function GetListeEvaluation(localsessionid) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", localsessionid);


        /*
      /!\ : Cette fonction a besoin d'un token actif
      */

        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetListEvaluation_Session/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('GetListeEvaluation  -- Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_evaluation_result(data['message']);
                setRows_evaluation(data['message']);
                if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else if (String(data['status']) === String("true")) {
                    setGetListePreinscrit_evaluation_api("true");

                } else {
                    //console.log("GetListeEvaluation  rrrr:" + data['status'])
                    setGetListePreinscrit_evaluation_api("false");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetListePreinscrit_evaluation_api("false");
            });

    }

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);
            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    /*const [Get_Class_Data_api, setGet_Class_Data_api] = useState();
    const [Get_Class_Data_message, setGet_Class_Data_message] = useState();
    const [Get_Class_Data_result, setGet_Class_Data_result] = useState();
    function Get_Class_Data(local_internal_url) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("internal_url", local_internal_url);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Class_From_Internal_Url/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Class_Data  res.data.status = " + res.data.status);
            //console.log(" In Get_Class_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_Class_Data_api("true");
                setGet_Class_Data_message(res.data.message);

                if (JSON.parse(res.data.message).price) {


                    if (document.getElementsByName("prix_session")[0]) {
                        document.getElementsByName("prix_session")[0].value = String(JSON.parse(res.data.message).price);                      
                    }

                    if (document.getElementsByName("one_prix_session")[0]) {
                        document.getElementsByName("one_prix_session")[0].value = String(JSON.parse(res.data.message).price);                      
                    }
                }

                if (JSON.parse(res.data.message).formateur_id) {
                    setp_detail_one_formateur(JSON.parse(res.data.message).formateur_id);
                    setp_detail_formateur_id(JSON.parse(res.data.message).formateur_id);
                }


            }
            else {
                setGet_Class_Data_api("false");
                setGet_Class_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.warn('Get_Class_Data : Not good man :( Get_Class_Data = ' + error);
            setGet_Class_Data_api("false");
            alert(" Impossible de récuperer les données de la formation  ");
        })


    }*/

    const [Getall_Parter_OR_Default_Session_Steps_api, setGetall_Parter_OR_Default_Session_Steps_api] = useState();
    const [Getall_Parter_OR_Default_Session_Steps_message, setGetall_Parter_OR_Default_Session_Steps_message] = useState();
    const [Getall_Parter_OR_Default_Session_Steps_result, setGetall_Parter_OR_Default_Session_Steps_result] = useState();
    function Getall_Parter_OR_Default_Session_Steps(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Or_Default_session_step/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_OR_Default_Session_Steps  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_OR_Default_Session_Steps  res.data.message r_class = " + res.data.message);
                setGetall_Parter_OR_Default_Session_Steps_api("true");
                setGetall_Parter_OR_Default_Session_Steps_result(res.data.message);

            }
            else {
                setGetall_Parter_OR_Default_Session_Steps_api("false");
                setGetall_Parter_OR_Default_Session_Steps_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_OR_Default_Session_Steps = ', error);
            setGetall_Parter_OR_Default_Session_Steps_api("false");
            alert(" Impossible de recuperer la liste des étapes de session");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {

        Get_Partner_Object_Specific_Fields_session();
        Get_Partner_Object_Specific_Fields_stagiaire();

        Getall_Parter_OR_Default_Session_Steps();

        Getall_Training_Employee_No_Filter();
        GetCurrentClass();
        GetCurrentClass_trainingsession();
        GetAttestation_Certif();
        Get_List_Partner_Clients();


        desableSessionFields();
        setCreateListEmargement_api();


        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, []);


    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [p_one_detail_client_rattachement_nom, setp_one_detail_client_rattachement_nom] = useState();
    const [p_one_detail_client_rattachement_id, setp_one_detail_client_rattachement_id] = useState();

    const [p_one_titre, setp_one_titre] = useState("");
    const [p_one_location_type, setp_one_location_type] = useState("");
    const [p_one_is_bpf, setp_one_is_bpf] = useState("");


    const [selected_code_session, setselected_code_session] = useState("");
    const [mysession, setmysession] = useState("");
    const [allmysession_info, setallmysession_info] = useState("");
    const [selectedsession_date_du, setselectedsession_date_du] = useState(new Date());
    const [selectedsession_date_au, setselectedsession_date_au] = useState(new Date());
    function handleChangeSession(event) {


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        setselected_session_id(event.target.value);


        const div1 = document.getElementById("mysy_code_session");
        const code_session = div1.getAttribute('mysy_code_session');
        setselected_code_session(code_session);



        setreduction_session("0");
        setsessionChanged(false);
        clearSessionFields();
        setselectedCertif();
        seturlpreview_certif();
        setdisplay_detail_insc();
        setCreateSessionFormation_api();
        setSendInscriptionConfirmation_api();
        setreduction_session("0");


        var tab_date = [];

        setp_detail_session_class(event.target.value);
        setmysession(event.target.value);
        GetListePreinscrit(event.target.value);
        GetListeInscrit(event.target.value);
        GetListeEmargement(event.target.value);
        GetListeEvaluation(event.target.value);

        var el = document.getElementById('session_liste');
        var text = el.options[el.selectedIndex].innerHTML;
        setallmysession_info(text);

        // Recuperation de l'adresse
        const words_adresse = text.split(' à');
        if (words_adresse.length === 2) {
            var regex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
            const words = text.split(' ');
            //Iterate array
            words.forEach(
                function (date) {

                    if (regex.test(date))
                        //console.log(date + " => "+regex.test(date));
                        tab_date.push(date)
                });

            //alert("words_adresse[1] = "+words_adresse[1].trim());
            const words_adresse2 = String(words_adresse[1]).trim().split(' ');
            //console.log("words_adresse2  = ",words_adresse2);
            var taille = words_adresse2.length;
            var CP = words_adresse2[words_adresse2.length - 1]
            var ville = words_adresse2[words_adresse2.length - 2]

            var local_adress = ""
            var i = 0;
            while (i < words_adresse2.length - 2) {
                local_adress = local_adress + " " + words_adresse2[i]
                i = i + 1;

            }
            //console.log(" CP = ",CP+"  ville = ",ville+"  local_adress = ",local_adress);
            document.getElementsByName("adresse")[0].value = local_adress;
            document.getElementsByName("ville")[0].value = ville;
            document.getElementsByName("code_postal")[0].value = CP;

        }

        if (tab_date.length === 2) {
            var date_du = new Date(moment(tab_date[0], "DD/MM/YYYY")).setHours(8, 0, 0);
            var date_au = new Date(moment(tab_date[1], "DD/MM/YYYY")).setHours(18, 0, 0);
            setSessionstartDate(date_du);
            setSessionendDate(date_au)
        }

        GetCurrentSession(event.target.value);

        submenu_inscrit();
    };


    function handleChangeSession_v2(event) {

        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        if (String(stored_cookie) === String("")) {
            alert(" La session n'est plus valide ");
            history.push("/Connexion");
            return;
        }

        setnb_stagiaire_Preinscrit("0");
        setnb_stagiaire_Inscrit("0");
        setSelectionModel([]);
        setSelectionModel_insc([]);
        setSelectionModel_emarg([]);
        setSelectionModel_evaluation([]);



        // A present l'utilisateur a choisi une session, il n'est donc plus necessaire d'afficher le 'gif' qui l'alerte
        // sur le faite qu'il y a une nouvelle session
        setshow_new_session_gif("");

        setselected_session_id(event.target.value);

        const div1 = document.getElementById("mysy_code_session");
        const code_session = div1.getAttribute('mysy_code_session');
        setselected_code_session(code_session);

        setreduction_session("0");
        setsessionChanged(false);
        clearSessionFields();
        setselectedCertif();
        seturlpreview_certif();
        setdisplay_detail_insc();
        setCreateSessionFormation_api();
        setSendInscriptionConfirmation_api();
        setreduction_session("0");


        var tab_date = [];

        setp_detail_session_class(event.target.value);
        setmysession(event.target.value);
        GetListePreinscrit(event.target.value);
        GetListeInscrit(event.target.value);
        GetListeEmargement(event.target.value);
        GetListeEvaluation(event.target.value);

        var el = document.getElementById('session_liste_v2');
        var text = el.innerText;

        setallmysession_info(text);

        // Recuperation de l'adresse
        const words_adresse = text.split(' à');

        if (words_adresse.length === 2) {
            var regex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
            const words = text.split(' ');
            //Iterate array
            words.forEach(
                function (date) {

                    if (regex.test(date))
                        //console.log(date + " => "+regex.test(date));
                        tab_date.push(date)
                });

            //alert("words_adresse[1] = "+words_adresse[1].trim());
            const words_adresse2 = String(words_adresse[1]).trim().split(' ');
            //console.log("words_adresse2  = ",words_adresse2);
            var taille = words_adresse2.length;
            var CP = words_adresse2[words_adresse2.length - 1]
            var ville = words_adresse2[words_adresse2.length - 2]

            var local_adress = ""
            var i = 0;
            while (i < words_adresse2.length - 2) {
                local_adress = local_adress + " " + words_adresse2[i]
                i = i + 1;

            }
            //console.log(" CP = ",CP+"  ville = ",ville+"  local_adress = ",local_adress);
            document.getElementsByName("adresse")[0].value = local_adress;
            document.getElementsByName("ville")[0].value = ville;
            document.getElementsByName("code_postal")[0].value = CP;

        }

        if (tab_date.length === 2) {
            var date_du = new Date(moment(tab_date[0], "DD/MM/YYYY")).setHours(8, 0, 0);
            var date_au = new Date(moment(tab_date[1], "DD/MM/YYYY")).setHours(18, 0, 0);
            setSessionstartDate(date_du);
            setSessionendDate(date_au)
        }


        GetCurrentSession(event.target.value);

        submenu_inscrit();
    };


    const [CreateListEmargement_api, setCreateListEmargement_api] = useState();
    const [CreateListEmargement_message, setCreateListEmargement_message] = useState();
    const [CreateListEmargement_result, setCreateListEmargement_result] = useState();
    function CreateListEmargement() {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", selected_session_id);

        //alert(" mysession = "+mysession);

        //var myurl = process.env.REACT_APP_API_URL + "myclass/api/CreateTableauEmargement/";

        // Update du 23/11/2023 : Les emargements sont basé sur les séquence déjà créées
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CreateTableauEmargement_From_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreateListEmargement_api("true");
                setCreateListEmargement_result(res.data.message);
                GetListeEmargement(selected_session_id);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreateListEmargement_api("false");
                setCreateListEmargement_message(res.data.message);
                alert(res.data.messag);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('CreateListEmargement : Not good man :( mysearchtext = ' + error);
            setCreateListEmargement_api("false");
            alert(" Impossible d'initialiser la liste d'émargement");

        })
    }


    const [is_lms_class_code, setis_lms_class_code] = useState();

    const [CreateSessionFormation_api, setCreateSessionFormation_api] = useState();
    const [CreateSessionFormation_message, setCreateSessionFormation_message] = useState();
    const [CreateSessionFormation_result, setCreateSessionFormation_result] = useState();
    function CreateSessionFormation() {
        clean_old_messages();
        var form = new FormData();
        var nb_participant = "0";
        var prix_session;

        if (mysession.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }

        nb_participant = ""
        if (document.getElementById("nb_participant") && document.getElementById("nb_participant").value != "") {
            nb_participant = document.getElementById("nb_participant").value;
        }
        if (nb_participant.trim() == "") {
            alert(" Le nombre de participants est obligatoire");
            return;
        }

        prix_session = ""
        if (document.getElementById("prix_session") && document.getElementById("prix_session").value != "") {
            prix_session = document.getElementById("prix_session").value;
        }
        if (prix_session.trim() == "") {
            alert(" Le prix de la session est vide");
            return;
        }

        var session_ondemande = "0"
        session_ondemande = ""
        if (p_detail_session_ondemande) {
            session_ondemande = p_detail_session_ondemande;
        }

        if (String(session_ondemande) === "1")
            setp_detail_session_ondemande_label("Oui");
        else
            setp_detail_session_ondemande_label("Non");


        var session_etape = "0"

        if (p_session_etape) {
            session_etape = p_session_etape;
            setp_session_etape_label(p_session_etape);
        }



        var presentiel = "0"
        if (p_detail_presentiel) {
            presentiel = p_detail_presentiel;
            if (String(presentiel) === "1")
                setp_detail_presentiel_label("Oui");
            else
                setp_detail_presentiel_label("Non");

        }
        if (presentiel.trim() == "") {
            alert(" Vous devez préciser si la session est en présentiel");
            return;
        }

        var distantiel = "0"
        if (p_detail_distantiel) {

            distantiel = p_detail_distantiel;
            if (String(distantiel) === "1")
                setp_detail_distantiel_label("Oui");
            else
                setp_detail_distantiel_label("Non");
        }
        if (distantiel.trim() == "") {
            alert(" Vous devez préciser si la session est en distantiel");
            return;
        }



        if (String(document.getElementById("sessiondatedebutinscription").value).trim() == "") {
            alert(" La date de début des inscriptions est obligatoire");
            return;
        }

        if (String(document.getElementById("sessiondatefininscription").value).trim() == "") {
            alert(" La date de fin des inscriptions est obligatoire");
            return;
        }

        var adress = "";
        if (document.getElementById("adresse") && document.getElementById("adresse").value != "")
            adress = document.getElementById("adresse").value;

        var codepostal = "";
        if (document.getElementById("code_postal") && document.getElementById("code_postal").value != "")
            codepostal = document.getElementById("code_postal").value;


        var ville = "";
        if (document.getElementById("ville") && document.getElementById("ville").value != "")
            ville = document.getElementById("ville").value;

        var code_session = "";
        if (document.getElementById("code_session") && document.getElementById("code_session").value != "")
            code_session = document.getElementById("code_session").value;



        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("session_etape", p_session_etape);
        form.append("class_internal_url", internal_url);
        form.append("date_debut", format(SessionstartDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("date_fin", format(SessionendDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("prix_session", prix_session);
        form.append("nb_participant", nb_participant);
        form.append("session_ondemande", session_ondemande);
        form.append("presentiel", presentiel);
        form.append("distantiel", distantiel);
        form.append("adresse", adress);
        form.append("code_postal", codepostal);
        form.append("ville", ville);
        form.append("code_session", code_session);
        form.append("formateur_id", p_detail_formateur_id);

        form.append("titre", p_detail_titre);
        form.append("location_type", p_detail_location_type);
        form.append("is_bpf", p_detail_is_bpf);



        if (sessionStatus)
            form.append("session_status", "1");
        else
            form.append("session_status", "0");

        form.append("date_debut_inscription", format(SessionstartDateInscription, 'dd/MM/yyyy kk:mm:ss'));
        form.append("date_fin_inscription", format(SessionendDateInscription, 'dd/MM/yyyy kk:mm:ss'));
        if (selectedCertif)
            form.append("attestation_certif", selectedCertif);
        else
            form.append("attestation_certif", "");

        var contenu_ftion = "";
        if (editorRef_contenu_ftion.current) {
            contenu_ftion = editorRef_contenu_ftion.current.getContent();

            if (parse(contenu_ftion.replace(/(<([^>]+)>)/ig, '')).length > editorRef_contenu_ftion_limite) {
                alert(" Champ 'Contenu de la formation', limite depassée !")
                return;
            }
        }
        form.append("contenu_ftion", contenu_ftion);

        /*
                 Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
               */
        for (let i = 0; i < spec_field_updated_values_hooks_session.length; i++) {
            var local_value = String(spec_field_updated_values_hooks_session[i].field_value);

            if (String(spec_field_updated_values_hooks_session[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks_session[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks_session[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks_session[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks_session[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks_session[i].field_name), local_value);
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation/";

        setLoading(true);
        //console.log(" ### CreateSessionFormation form = ", form);

        //return;

        axios.post(myurl, form).then(res => {
            //console.log(" In CreateSessionFormation  res.data.status = " + res.data.status);
            //console.log(" In CreateSessionFormation  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setCreateSessionFormation_api("true");
                setCreateSessionFormation_result(res.data.message);
                setsessionChanged(false);
                GetCurrentClass_trainingsession();
                desableSessionFields();
                alert(" La mise à jour a été correctement faite");

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreateSessionFormation_api("false");
                setCreateSessionFormation_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('CreateSessionFormation : Not good man :( mysearchtext = ' + error);
            setCreateSessionFormation_api("false");
            alert(" Impossible d'ajouter ou mettre à jour la sessions");

        })
    }

    const [p_detail_one_session_ondemande, setp_detail_one_session_ondemande] = useState();
    const [p_detail_session_ondemande, setp_detail_session_ondemande] = useState();
    const [p_detail_session_ondemande_label, setp_detail_session_ondemande_label] = useState();

    const [p_session_etape, setp_session_etape] = useState();
    const [p_session_etape_label, setp_session_etape_label] = useState();

    const [p_session_status, setp_session_status] = useState();
    const [p_session_status_label, setp_session_status_label] = useState();

    const [p_session_code_session, setp_session_code_session] = useState();



    const [p_detail_one_presentiel, setp_detail_one_presentiel] = useState();
    const [p_detail_presentiel, setp_detail_presentiel] = useState();
    const [p_detail_presentiel_label, setp_detail_presentiel_label] = useState();

    const [p_detail_one_distantiel, setp_detail_one_distantiel] = useState();
    const [p_detail_distantiel, setp_detail_distantiel] = useState();
    const [p_detail_distantiel_label, setp_detail_distantiel_label] = useState();


    const [p_detail_one_formateur, setp_detail_one_formateur] = useState();
    const [p_detail_formateur_id, setp_detail_formateur_id] = useState();
    const [p_detail_formateur_label, setp_detail_formateur_label] = useState();
    const [p_detail_titre, setp_detail_titre] = useState();
    const [p_detail_location_type, setp_detail_location_type] = useState();
    const [p_detail_is_bpf, setp_detail_is_bpf] = useState();
    const [p_detail_is_bpf_label, setp_detail_is_bpf_label] = useState();


    const [p_formateur_id, setp_formateur_id] = useState("");
    const [p_formateur_label, setp_formateur_label] = useState("");


    const [show_new_session_gif, setshow_new_session_gif] = useState();

    const [one_CreateSessionFormation_api, setone_CreateSessionFormation_api] = useState();
    const [one_CreateSessionFormation_message, setone_CreateSessionFormation_message] = useState();
    const [one_CreateSessionFormation_result, setone_CreateSessionFormation_result] = useState();
    function One_CreateSessionFormation() {
        clean_old_messages();
        var form = new FormData();
        var nb_participant = "0";
        var prix_session;


        code_session = ""
        if (document.getElementById("one_code_session") && document.getElementById("one_code_session").value != "") {
            code_session = document.getElementById("one_code_session").value;
        }
        if (code_session.trim() == "") {
            alert(" Vous devez saisir un code session");
            return;
        }

        nb_participant = ""
        if (document.getElementById("one_nb_participant") && document.getElementById("one_nb_participant").value != "") {
            nb_participant = document.getElementById("one_nb_participant").value;
        }
        if (nb_participant.trim() == "") {
            const response = window.confirm("Le nombre de participants est vide. Par defaut, 1. Continuer ?");
            if (!response) {
                return;
            }
            nb_participant = "1"
        }

        prix_session = ""
        if (document.getElementById("one_prix_session") && document.getElementById("one_prix_session").value != "") {
            prix_session = document.getElementById("one_prix_session").value;
        }


        var one_session_ondemande = "0"
        if (p_detail_one_session_ondemande) {
            one_session_ondemande = p_detail_one_session_ondemande;
        }

        var one_session_status = "0"
        if (p_session_status) {
            one_session_status = p_session_status;
        }


        var presentiel = "0"
        presentiel = ""
        if (p_detail_one_presentiel) {
            presentiel = p_detail_one_presentiel;
        }
        if (presentiel.trim() == "") {

            const response = window.confirm(" Vous devez préciser si la session est en présentiel. Continuer ?");
            if (!response) {
                return;
            }
        }

        var distantiel = "0"
        distantiel = ""
        if (p_detail_one_distantiel) {
            distantiel = p_detail_one_distantiel;
        }
        if (distantiel.trim() == "") {

            const response = window.confirm(" Vous devez préciser si la session est en distantiel. Continuer ?");
            if (!response) {
                return;
            }

        }

        if (String(document.getElementById("one_sessiondatedebut").value).trim() == "") {
            window.alert("La date de début de session est obligatoire.");
            return;
        }

        if (String(document.getElementById("one_sessiondatefin").value).trim() == "") {
            window.alert(" La date de fin de session est obligatoire.");
            return;
        }


        if (String(document.getElementById("one_sessiondatedebutinscription").value).trim() == "") {

            window.alert("La date de début des inscriptions est obligatoire. Continuer ?");
            return;
        }

        if (String(document.getElementById("one_sessiondatefininscription").value).trim() == "") {
            window.alert(" La date de fin des inscriptions est obligatoire");
            return;
        }

        var adress = "";
        if (document.getElementById("one_adresse") && document.getElementById("one_adresse").value != "")
            adress = document.getElementById("one_adresse").value;

        var codepostal = "";
        if (document.getElementById("one_code_postal") && document.getElementById("one_code_postal").value != "")
            codepostal = document.getElementById("one_code_postal").value;


        var ville = "";
        if (document.getElementById("one_ville") && document.getElementById("one_ville").value != "")
            ville = document.getElementById("one_ville").value;

        var code_session = "";
        if (document.getElementById("one_code_session") && document.getElementById("one_code_session").value != "")
            code_session = document.getElementById("one_code_session").value;

        var one_formateur_id = "";
        if (document.getElementById("one_formateur_id") && document.getElementById("one_formateur_id").value != "")
            one_formateur_id = document.getElementById("one_formateur_id").value;



        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_status", one_session_status);
        form.append("code_session", code_session);
        form.append("date_debut", format(one_SessionstartDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("date_fin", format(one_SessionendDate, 'dd/MM/yyyy kk:mm:ss'));
        form.append("prix_session", prix_session);
        form.append("nb_participant", nb_participant);
        form.append("session_ondemande", one_session_ondemande);
        form.append("presentiel", presentiel);
        form.append("distantiel", distantiel);
        form.append("adresse", adress);
        form.append("code_postal", codepostal);
        form.append("ville", ville);
        form.append("code_session", code_session);
        form.append("formateur_id", p_detail_one_formateur);

        form.append("titre", p_one_titre);
        form.append("location_type", p_one_location_type);
        form.append("is_bpf", p_one_is_bpf);


        if (sessionStatus)
            form.append("session_status", "1");
        else
            form.append("session_status", "0");

        form.append("date_debut_inscription", format(one_SessionstartDateInscription, 'dd/MM/yyyy kk:mm:ss'));
        form.append("date_fin_inscription", format(one_SessionendDateInscription, 'dd/MM/yyyy kk:mm:ss'));
        form.append("attestation_certif", one_selectedCertif);

        var contenu_ftion = "";
        if (one_editorRef_contenu_ftion.current) {
            contenu_ftion = one_editorRef_contenu_ftion.current.getContent();

            if (parse(contenu_ftion.replace(/(<([^>]+)>)/ig, '')).length > one_editorRef_contenu_ftion_limite) {
                alert(" Champ 'Contenu de la formation', limite depassée !")
                return;
            }
        }
        form.append("contenu_ftion", contenu_ftion);

        /*
          Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
        */
        for (let i = 0; i < spec_field_updated_values_hooks_session.length; i++) {
            var local_value = String(spec_field_updated_values_hooks_session[i].field_value);

            if (String(spec_field_updated_values_hooks_session[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks_session[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks_session[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks_session[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks_session[i].field_label + " est obligatoire.")
                    return;
                }

            }


            form.append(String(spec_field_updated_values_hooks_session[i].field_name), local_value);
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation/";

        axios.post(myurl, form).then(res => {
            //console.log(" In One_CreateSessionFormation  res.data.status = " + res.data.status);
            //console.log(" In One_CreateSessionFormation  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setone_CreateSessionFormation_api("true");
                setone_CreateSessionFormation_result(res.data.message);
                setsessionChanged(false);
                GetCurrentClass_trainingsession();
                One_clearSessionFields();
                setaddOneSession();
                setreduction_session("1");
                setshow_new_session_gif('1');

                alert(" La session a été correctement ajoutée");
                if (document.getElementById('ancre_ref_new_gif')) {
                    //ancre_ref_new_gif.current.scrollIntoView({ behavior: "smooth" });

                    var divh = document.getElementById('ancre_ref_new_gif').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setone_CreateSessionFormation_api("false");
                setone_CreateSessionFormation_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {

            console.warn('One_CreateSessionFormation : Not good man :(  = ' + error);
            setone_CreateSessionFormation_api("false");
            alert(" Impossible d'ajouter la session de formation");

        })
    }




    const [selected_session_id, setselected_session_id] = useState();

    const [GetCurrentSession_api, setGetCurrentSession_api] = useState();
    const [GetCurrentSession_message, setGetCurrentSession_message] = useState();
    const [GetCurrentSession_result, setGetCurrentSession_result] = useState();
    function GetCurrentSession(localsession_id) {
        clean_old_messages();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", localsession_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetSessionFormation/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === "true") {
                //console.log(" In GetCurrentSession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentSession  res.data.message r_class = " + res.data.message);
                //console.log(" In GetCurrentSession  res.data.message len = " + String(res.data.message).length);

                setLoading(false);

                setGetCurrentSession_api("true");
                if (String(res.data.message).length > 0) {
                    setGetCurrentSession_result(res.data.message);
                    var mylocaltraining = JSON.parse(res.data.message);

                    if (mylocaltraining._id) {
                        setselected_session_id(mylocaltraining._id);
                    }



                    if (mylocaltraining.date_debut) {
                        var date_du = new Date(moment(mylocaltraining.date_debut, "DD/MM/YYYY HH:mm:ss"));
                        setSessionstartDate(date_du);
                    }

                    if (mylocaltraining.date_fin) {
                        var date_fin = new Date(moment(mylocaltraining.date_fin, "DD/MM/YYYY HH:mm:ss"));
                        setSessionendDate(date_fin);
                    }

                    if (mylocaltraining.date_debut_inscription) {
                        var date_du_inscript = new Date(moment(mylocaltraining.date_debut_inscription, "DD/MM/YYYY HH:mm:ss"));
                        setSessionstartDateInscription(date_du_inscript);

                    }


                    if (mylocaltraining.date_fin_inscription) {
                        var date_fin_inscript = new Date(moment(mylocaltraining.date_fin_inscription, "DD/MM/YYYY HH:mm:ss"));
                        setSessionendDateInscription(date_fin_inscript);
                    }

                    if (mylocaltraining.attestation_certif) {

                        setselectedCertif(mylocaltraining.attestation_certif);
                        GetSpecificAttestation_Certif(mylocaltraining.attestation_certif);

                    }


                    document.getElementsByName("code_session")[0].value = "";
                    if (mylocaltraining.code_session)
                        document.getElementsByName("code_session")[0].value = mylocaltraining.code_session;

                    document.getElementsByName("nb_participant")[0].value = "";
                    if (mylocaltraining.nb_participant)
                        document.getElementsByName("nb_participant")[0].value = mylocaltraining.nb_participant;

                    document.getElementsByName("prix_session")[0].value = "";
                    if (mylocaltraining.prix_session)
                        document.getElementsByName("prix_session")[0].value = mylocaltraining.prix_session;



                    if (mylocaltraining.presentiel) {
                        setp_detail_presentiel(mylocaltraining.presentiel);
                        if (String(mylocaltraining.presentiel) === "1")
                            setp_detail_presentiel_label("Oui");
                        else
                            setp_detail_presentiel_label("Non");

                    }
                    else {
                        setp_detail_presentiel("0");
                        setp_detail_presentiel_label("Non");
                    }


                    if (mylocaltraining.distantiel) {
                        setp_detail_distantiel(mylocaltraining.distantiel);

                        if (String(mylocaltraining.distantiel) === "1") {
                            setp_detail_distantiel_label("Oui");

                        }
                        else
                            setp_detail_distantiel_label("Non");

                    }
                    else {
                        setp_detail_distantiel("0");
                        setp_detail_distantiel_label("Non");
                    }



                    if (mylocaltraining.session_ondemande) {
                        setp_detail_session_ondemande(mylocaltraining.session_ondemande);

                        if (String(mylocaltraining.session_ondemande) === "1")
                            setp_detail_session_ondemande_label("Oui");
                        else
                            setp_detail_session_ondemande_label("Non");

                    }
                    else {
                        setp_detail_session_ondemande("0");
                        setp_detail_session_ondemande_label("Non");
                    }

                    if (mylocaltraining.session_etape) {
                        setp_session_etape(mylocaltraining.session_etape);
                        setp_session_etape_label(mylocaltraining.session_etape);

                    } else {
                        setp_session_etape_label("");
                        setp_session_etape("");
                    }


                    if (mylocaltraining.contenu_ftion)
                        setfield_contenu_ftion(mylocaltraining.contenu_ftion);


                    document.getElementsByName("adresse")[0].value = "";
                    if (mylocaltraining.adresse)
                        document.getElementsByName("adresse")[0].value = mylocaltraining.adresse;

                    document.getElementsByName("ville")[0].value = "";
                    if (mylocaltraining.ville) {
                        document.getElementsByName("ville")[0].value = mylocaltraining.ville;
                    }


                    if (mylocaltraining.formateur_id) {
                        setp_detail_formateur_id(mylocaltraining.formateur_id);
                        setp_detail_formateur_label(mylocaltraining.formateur_nom_prenom);

                    } else {
                        setp_detail_formateur_id("");
                        setp_detail_formateur_label("");

                    }


                    if (mylocaltraining.lms_class_code) {
                        setis_lms_class_code(mylocaltraining.lms_class_code);
                    }

                    if (mylocaltraining.titre) {
                        setp_detail_titre(mylocaltraining.titre);
                    }

                    if (mylocaltraining.location_type) {
                        setp_detail_location_type(mylocaltraining.location_type);
                    }

                    if (mylocaltraining.is_bpf) {
                        setp_detail_is_bpf(mylocaltraining.is_bpf);
                        if (String(mylocaltraining.is_bpf) === "1")
                            setp_detail_is_bpf_label("Oui");
                        else
                            setp_detail_is_bpf_label("Non");
                    }

                    document.getElementsByName("code_postal")[0].value = "";
                    if (mylocaltraining.code_postal)
                        document.getElementsByName("code_postal")[0].value = mylocaltraining.code_postal;


                    if (String(mylocaltraining.session_status) === "1")
                        setsessionStatus(true);
                    else if (String(mylocaltraining.session_status) === "0")
                        setsessionStatus(false);

                    /*
           * Update 22/10/2023 :
           Gestion des champs spécifiques. ils commencent tous par 'my_'
           */


                    for (let i = 0; i < rows_champs_specifics_session.length; i++) {

                        var field_name = JSON.parse(rows_champs_specifics_session[i]).field_name;
                        var field_type = JSON.parse(rows_champs_specifics_session[i]).field_type;
                        var field_label = JSON.parse(rows_champs_specifics_session[i]).field_name;
                        var is_mandatory = JSON.parse(rows_champs_specifics_session[i]).is_mandatory;


                        if (mylocaltraining.hasOwnProperty(field_name)) {
                            var local_valeur = mylocaltraining[String(field_name)]

                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = local_valeur;

                            var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                            var johnIndex = findIndexByProperty(spec_field_updated_values_session, 'field_name', field_name);

                            if (johnIndex > -1) {
                                spec_field_updated_values_session[johnIndex] = new_val;

                            } else {
                                spec_field_updated_values_session.push(new_val);
                            }

                        } else {

                        }
                    }



                    setspec_field_updated_values_hooks_session(spec_field_updated_values_session);
                    /*
                                       *  end Update 22/10/2023 :
                                       */

                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetCurrentSession_api("false");
                setGetCurrentSession_message(res.data.message);
                alert("Erreur : " + res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetCurrentSession = ', error);
            setGetCurrentSession_api("false");
            alert(" Impossible de recuperer les données de la session")

        })
    }


    function annuleSessionUpdate() {
        clearSessionFields();
        GetCurrentSession(mysession);
        setsessionChanged(false);
        desableSessionFields();
        setCreateSessionFormation_api();
        setCreateSessionFormation_message();
        setone_CreateSessionFormation_api();
        setone_CreateSessionFormation_message();
        setone_CreateSessionFormation_result()
    }


    function One_annuleSessionUpdate() {
        One_clearSessionFields();
        GetCurrentSession(mysession);
        setsessionChanged(false);
        setCreateSessionFormation_api();
        setCreateSessionFormation_message();
        setone_CreateSessionFormation_api();
        setone_CreateSessionFormation_message();
        setone_CreateSessionFormation_result()
        setaddOneSession();
    }


    function One_annuleAdd_Participant() {
        setaddOneParticipant("0");
        var myelement;

        if (document.getElementById('participant_menu_tab')) {
            myelement = document.getElementById('participant_menu_tab');
        }

        if (document.getElementById('participant_menu_tab')) {
            //myelement.scrollIntoView({ behavior: 'smooth' });
            var divh = document.getElementById('participant_menu_tab').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }


    }


    const [SessionstartDate, setSessionstartDate] = useState();

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    const [SessionendDate, setSessionendDate] = useState();

    const filterPassedTime_end = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const [detailuser_date_inscription, setdetailuser_date_inscription] = useState();
    const [detailuser_date_evaluation, setdetailuser_date_evaluation] = useState();
    const [detailuser_date_certification, setdetailuser_date_certification] = useState();
    const [SessionstartDateInscription, setSessionstartDateInscription] = useState();



    const filterPassedTime_start_Inscription = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    const [SessionendDateInscription, setSessionendDateInscription] = useState();
    const filterPassedTime_end_Inscription = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    function toggleSessionStatus() {

        if (sessionStatus === true)
            setsessionStatus(false);
        else
            setsessionStatus(true);

        enableSessionFields();
        setsessionChanged(true);
    }

    let [sessionStatus, setsessionStatus] = useState(false);

    let [sessionChanged, setsessionChanged] = useState(false);

    function IssessionChanged() {
        setsessionChanged(true);
    }

    let [fields1desabled, setfields1desabled] = useState(true);


    function desableAttendeeDetailFields() {
        document.getElementsByName("date_evaluation")[0].disabled = true;
        document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_incr_date")[0].disabled = true;
        document.getElementsByName("detail_incr_date")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("certif_date")[0].disabled = true;
        document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";
    }





    function desableSessionFields() {
        setfields1desabled(true);

        if (document.getElementsByName("code_session")[0]) {
            document.getElementsByName("code_session")[0].disabled = true;
            document.getElementsByName("code_session")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("session_etape")[0]) {
            document.getElementsByName("session_etape")[0].disabled = true;
            document.getElementsByName("session_etape")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = true;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = true;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("adresse")[0]) {
            document.getElementsByName("adresse")[0].disabled = true;
            document.getElementsByName("adresse")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("code_postal")[0]) {
            document.getElementsByName("code_postal")[0].disabled = true;
            document.getElementsByName("code_postal")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("ville")[0]) {
            document.getElementsByName("ville")[0].disabled = true;
            document.getElementsByName("ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("formateur_label")[0]) {
            document.getElementsByName("formateur_label")[0].disabled = true;
            document.getElementsByName("formateur_label")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("formateur_id")[0]) {
            document.getElementsByName("formateur_id")[0].disabled = true;
            document.getElementsByName("formateur_id")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("nb_participant")[0]) {
            document.getElementsByName("nb_participant")[0].disabled = true;
            document.getElementsByName("nb_participant")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("prix_session")[0]) {
            document.getElementsByName("prix_session")[0].disabled = true;
            document.getElementsByName("prix_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_ondemande") && document.getElementsByName("session_ondemande")[0]) {
            //console.log(" GRRR ocument.getElementsByName('session_ondemande')[0] = ", document.getElementsByName("session_ondemande")[0].value);
            document.getElementsByName("session_ondemande")[0].disabled = true;
            document.getElementsByName("session_ondemande")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("presentiel")[0]) {
            document.getElementsByName("presentiel")[0].disabled = true;
            document.getElementsByName("presentiel")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("titre_session")[0]) {
            document.getElementsByName("titre_session")[0].disabled = true;
            document.getElementsByName("titre_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("emplacement_session")[0]) {
            document.getElementsByName("emplacement_session")[0].disabled = true;
            document.getElementsByName("emplacement_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("is_bpf_session")[0]) {
            document.getElementsByName("is_bpf_session")[0].disabled = true;
            document.getElementsByName("is_bpf_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("distantiel")[0]) {
            document.getElementsByName("distantiel")[0].disabled = true;
            document.getElementsByName("distantiel")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("sessiondatedebutinscription")[0]) {
            document.getElementsByName("sessiondatedebutinscription")[0].disabled = true;
            document.getElementsByName("sessiondatedebutinscription")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("sessiondatefininscription")[0]) {
            document.getElementsByName("sessiondatefininscription")[0].disabled = true;
            document.getElementsByName("sessiondatefininscription")[0].style.backgroundColor = "#ECEFF1";
        }

        // Pour les champs spécifiques pour les sessions

        for (let i = 0; i < rows_champs_specifics_session.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_session[i]).field_name;


            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = true;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
            }
        }


    }

    function enableSessionFields() {


        //setfields1desabled(true);

        if (document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = false;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = false;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("session_etape")[0]) {
            document.getElementsByName("session_etape")[0].disabled = false;
            document.getElementsByName("session_etape")[0].style.backgroundColor = "#FFFFFF";
        }



        if (document.getElementsByName("adresse")[0]) {
            document.getElementsByName("adresse")[0].disabled = false;
            document.getElementsByName("adresse")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("code_postal")[0]) {
            document.getElementsByName("code_postal")[0].disabled = false;
            document.getElementsByName("code_postal")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("ville")[0]) {
            document.getElementsByName("ville")[0].disabled = false;
            document.getElementsByName("ville")[0].style.backgroundColor = "#FFFFFF";
        }



        if (document.getElementsByName("formateur_label")[0]) {
            document.getElementsByName("formateur_label")[0].disabled = false;
            document.getElementsByName("formateur_label")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("formateur_id")[0]) {
            document.getElementsByName("formateur_id")[0].disabled = false;
            document.getElementsByName("formateur_id")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("nb_participant")[0]) {
            document.getElementsByName("nb_participant")[0].disabled = false;
            document.getElementsByName("nb_participant")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("prix_session")[0]) {
            document.getElementsByName("prix_session")[0].disabled = false;
            document.getElementsByName("prix_session")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("session_ondemande")[0]) {
            document.getElementsByName("session_ondemande")[0].disabled = false;
            document.getElementsByName("session_ondemande")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("presentiel")[0]) {
            document.getElementsByName("presentiel")[0].disabled = false;
            document.getElementsByName("presentiel")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("distantiel")[0]) {
            document.getElementsByName("distantiel")[0].disabled = false;
            document.getElementsByName("distantiel")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("sessiondatedebutinscription")[0]) {
            document.getElementsByName("sessiondatedebutinscription")[0].disabled = false;
            document.getElementsByName("sessiondatedebutinscription")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("sessiondatefininscription")[0]) {
            document.getElementsByName("sessiondatefininscription")[0].disabled = false;
            document.getElementsByName("sessiondatefininscription")[0].style.backgroundColor = "#FFFFFF ";
        }

        if (document.getElementsByName("titre_session")[0]) {
            document.getElementsByName("titre_session")[0].disabled = false;
            document.getElementsByName("titre_session")[0].style.backgroundColor = "#FFFFFF ";
        }


        if (document.getElementsByName("emplacement_session")[0]) {
            document.getElementsByName("emplacement_session")[0].disabled = false;
            document.getElementsByName("emplacement_session")[0].style.backgroundColor = "#FFFFFF ";
        }


        if (document.getElementsByName("is_bpf_session")[0]) {
            document.getElementsByName("is_bpf_session")[0].disabled = false;
            document.getElementsByName("is_bpf_session")[0].style.backgroundColor = "#FFFFFF ";
        }



        // Pour les champs spécifiques session
        for (let i = 0; i < rows_champs_specifics_session.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_session[i]).field_name;
            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = false;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#FFFFFF";
            }
        }


        setfields1desabled(false);
    }

    function clearSessionFields() {
        document.getElementsByName("code_session")[0].value = "";
        document.getElementsByName("session_etape")[0].value = "";
        document.getElementsByName("sessiondatedebut")[0].value = "";
        document.getElementsByName("sessiondatefin")[0].value = "";
        if (document.getElementsByName("adresse")[0])
            document.getElementsByName("adresse")[0].value = "";

        if (document.getElementsByName("code_postal")[0])
            document.getElementsByName("code_postal")[0].value = "";

        if (document.getElementsByName("ville")[0])
            document.getElementsByName("ville")[0].value = "";

        if (document.getElementsByName("nb_participant")[0])
            document.getElementsByName("nb_participant")[0].value = "";

        if (document.getElementsByName("prix_session")[0])
            document.getElementsByName("prix_session")[0].value = "";

        if (document.getElementsByName("presentiel")[0])
            document.getElementsByName("presentiel")[0].value = "";

        if (document.getElementsByName("session_ondemande")[0])
            document.getElementsByName("session_ondemande")[0].value = "";

        if (document.getElementsByName("distantiel")[0])
            document.getElementsByName("distantiel")[0].value = "";

        if (document.getElementsByName("titre_session")[0])
            document.getElementsByName("titre_session")[0].value = "";

        if (document.getElementsByName("emplacement_session")[0])
            document.getElementsByName("emplacement_session")[0].value = "";

        if (document.getElementsByName("is_bpf_session")[0])
            document.getElementsByName("is_bpf_session")[0].value = "";

        if (document.getElementsByName("sessiondatedebutinscription")[0])
            document.getElementsByName("sessiondatedebutinscription")[0].value = "";

        if (document.getElementsByName("sessiondatefininscription")[0])
            document.getElementsByName("sessiondatefininscription")[0].value = "";

        setSessionstartDateInscription();
        setSessionendDateInscription();
        setSessionendDate();
        setSessionstartDate();
        seturlpreview_certif();
        setfield_contenu_ftion();
        setp_session_etape();
        setp_session_etape_label();
    }


    function One_clearSessionFields() {
        document.getElementsByName("one_code_session")[0].value = "";
        document.getElementsByName("one_sessiondatedebut")[0].value = "";
        document.getElementsByName("one_sessiondatefin")[0].value = "";

        if (document.getElementsByName("one_adresse")[0])
            document.getElementsByName("one_adresse")[0].value = "";

        if (document.getElementsByName("one_code_postal")[0])
            document.getElementsByName("one_code_postal")[0].value = "";

        if (document.getElementsByName("one_ville")[0])
            document.getElementsByName("one_ville")[0].value = "";

        if (document.getElementsByName("one_nb_participant")[0])
            document.getElementsByName("one_nb_participant")[0].value = "";

        if (document.getElementsByName("one_prix_session")[0])
            document.getElementsByName("one_prix_session")[0].value = "";

        if (document.getElementsByName("one_presentiel")[0])
            document.getElementsByName("one_presentiel")[0].value = "";

        if (document.getElementsByName("one_session_ondemande")[0])
            document.getElementsByName("one_session_ondemande")[0].value = "";

        if (document.getElementsByName("one_distantiel")[0])
            document.getElementsByName("one_distantiel")[0].value = "";

        if (document.getElementsByName("one_sessiondatedebutinscription")[0])
            document.getElementsByName("one_sessiondatedebutinscription")[0].value = "";

        if (document.getElementsByName("one_sessiondatefininscription")[0])
            document.getElementsByName("one_sessiondatefininscription")[0].value = "";

        if (document.getElementsByName("one_session_status")[0])
            document.getElementsByName("one_session_status")[0].value = "";

        if (document.getElementsByName("one_titre_session")[0])
            document.getElementsByName("one_titre_session")[0].value = "";

        if (document.getElementsByName("one_emplacement_session")[0])
            document.getElementsByName("one_emplacement_session")[0].value = "";

        if (document.getElementsByName("one_is_bpf_session")[0])
            document.getElementsByName("one_is_bpf_session")[0].value = "";



        setone_SessionstartDateInscription();
        setone_SessionendDateInscription();
        setone_SessionendDate();
        setone_SessionstartDate();
        setone_urlpreview_certif();
        setone_field_contenu_ftion();
        setp_session_status();
        setp_session_code_session();
    }

    const [one_SessionstartDateInscription, setone_SessionstartDateInscription] = useState();
    const [one_SessionendDateInscription, setone_SessionendDateInscription] = useState();
    const [one_SessionendDate, setone_SessionendDate] = useState();
    const [one_SessionstartDate, setone_SessionstartDate] = useState();
    const [one_urlpreview_certif, setone_urlpreview_certif] = useState();
    const [one_field_contenu_ftion, setone_field_contenu_ftion] = useState("");




    function clearDetailAttendeFields() {

        if (document.getElementsByName("detail_nom") && document.getElementsByName("detail_nom")[0])
            document.getElementsByName("detail_nom")[0].value = "";

        if (document.getElementsByName("detail_prenom") && document.getElementsByName("detail_prenom")[0])
            document.getElementsByName("detail_prenom")[0].value = "";

        if (document.getElementsByName("detail_mail") && document.getElementsByName("detail_mail")[0])
            document.getElementsByName("detail_mail")[0].value = "";

        if (document.getElementsByName("detail_tel") && document.getElementsByName("detail_tel")[0])
            document.getElementsByName("detail_tel")[0].value = "";

        if (document.getElementsByName("detail_adresse") && document.getElementsByName("detail_adresse")[0])
            document.getElementsByName("detail_adresse")[0].value = "";

        if (document.getElementsByName("detail_code_postal") && document.getElementsByName("detail_code_postal")[0])
            document.getElementsByName("detail_code_postal")[0].value = "";

        if (document.getElementsByName("detail_ville") && document.getElementsByName("detail_ville")[0])
            document.getElementsByName("detail_ville")[0].value = "";

        if (document.getElementsByName("detail_incr_date") && document.getElementsByName("detail_incr_date")[0])
            document.getElementsByName("detail_incr_date")[0].value = "";

        if (document.getElementsByName("detail_cout") && document.getElementsByName("detail_cout")[0])
            document.getElementsByName("detail_cout")[0].value = "";

        if (document.getElementsByName("detail_mode_fin") && document.getElementsByName("detail_mode_fin")[0])
            document.getElementsByName("detail_mode_fin")[0].value = "";

        if (document.getElementsByName("detail_employeur") && document.getElementsByName("detail_employeur")[0])
            document.getElementsByName("detail_employeur")[0].value = "";

        if (document.getElementsByName("date_evaluation") && document.getElementsByName("date_evaluation")[0])
            document.getElementsByName("date_evaluation")[0].value = "";

        if (document.getElementsByName("note_eval") && document.getElementsByName("note_eval")[0])
            document.getElementsByName("note_eval")[0].value = "";

        if (document.getElementsByName("certif_date") && document.getElementsByName("certif_date")[0])
            document.getElementsByName("certif_date")[0].value = "";


        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].value = "";
            }
        }

        setfield_evaluation();
        setdetailuser_date_certification();
        setdetailuser_date_evaluation();


    }


    function DownloadListEmargement(event) {




        const stored_cookie = getCookie('tokenmysypart');

        var nom_liste_emarge = "Liste_Emargement.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/GerneratePDFEmargementList/" + stored_cookie + "/" + selected_session_id + "/" + internal_url;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_liste_emarge)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    const [selectedattendeeemail, setselectedattendeeemail] = useState([])
    function DownloadAttendeeDetail(event) {



        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeDetail_perSession/" + stored_cookie + "/" + mysession + "/" + selectedattendeeemail + "/" + internal_url;


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    function DownloadAttendeeDetail_one(addendeeEmail) {



        if (addendeeEmail == "") {
            alert(" Aucun utilisateur choisi ");
            return;
        }

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeDetail_perSession/" + stored_cookie + "/" + mysession + "/" + addendeeEmail + "/" + internal_url;


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    function DownloadAttendeeAttestation(event) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Attestation.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeCertification/" + stored_cookie + "/" + mysession + "/" + selectedattendeeemail + "/" + internal_url;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            })
    }

    /*
        useEffect(() => {
            desableSessionFields();
            setCreateListEmargement_api();
        }, [])
        */

    const [selectedemargement, setselectedemargement] = useState([])
    function GetSelectedRowsEmargement() {

        var tab_tmp = []


        for (var i = 0; i < selectionModel_emarg.length; i++) {
            var myid = parseInt(String(selectionModel_emarg[i]));
            tab_tmp.push(JSON.parse(rowss_emarg[myid])._id);

        }
        setselectedemargement(tab_tmp);
        return tab_tmp;
    }

    const [ActionMassListEmargement_api, setActionMassListEmargement_api] = useState();
    const [ActionMassListEmargement_message, setActionMassListEmargement_message] = useState();
    const [ActionMassListEmargement_result, setActionMassListEmargement_result] = useState();
    function ActionMassListEmargement(e) {

        clean_old_messages();
        if (selectionModel_emarg.length <= 0) {
            alert(" Selectionnez un enregistrement pour envoyer la demande d'emargement");
            return;
        }

        var actionenmasse = ""
        if (document.getElementById("actionmass_emarge")) {
            actionenmasse = document.getElementById("actionmass_emarge").value;
        }

        var liste_emargement_id = GetSelectedRowsEmargement();

        //console.log("liste_formation = " + liste_emargement_id);

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_ids", liste_emargement_id);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/EmargementMail/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
               
                setActionMassListEmargement_api("true");
                setActionMassListEmargement_result(res.data.message);
            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setActionMassListEmargement_api("false");
                setActionMassListEmargement_message(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('ActionMassListEmargement : Not good man :( mysearchtext = ' + error);
            setActionMassListEmargement_api("false");

        })



        if (String(actionenmasse) === String("depublier")) {

            alert("depublier");
        }

        else if (String(actionenmasse) === String("publier")) {
            alert("publier");
        }

        else if (String(actionenmasse) === String("supprimer")) {
            alert("supprimer");
        }
    }

    function ConfirmInitListEmargement() {
        confirmAlert({
            message: "Confirmez la (re)initialisation de la liste d'emargement ? Toutes les confirmations seront perdues",

            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { CreateListEmargement() }
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }


    function PrintSesssionDetail() {

        var filedetail = "detail_session.xlsx"
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');



        var url = process.env.REACT_APP_API_URL + "myclass/api/DownloadParticipantsList/" + stored_cookie + "/" + mysession + "/" + internal_url;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, filedetail)
            })
    }

    function PrintEvaluationDetail() {

        var filedetail = "detail_evaluation.xlsx"

        const stored_cookie = getCookie('tokenmysypart');



        var url = process.env.REACT_APP_API_URL + "myclass/api/DownloadEvaluationList/" + stored_cookie + "/" + mysession + "/" + internal_url;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, filedetail)
            })
    }

    function ConfirmEnvoiListEmargement() {
        confirmAlert({
            message: "Confirmez l'envoi des demandes d'emargement aux participants",

            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { ActionMassListEmargement() }
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }


    const [participant_file_name, setparticipant_file_name] = useState();
    function submenu_import_participant() {
        setparticipant_file_name();
        setliste_participants_file_change_api();
        hiddenFileInput.current.click();
    }

    const hiddenFileInput = React.useRef(null);

    const [liste_participants_file_change_api, setliste_participants_file_change_api] = useState();
    const [liste_participants_file_change_result, setliste_participants_file_change_result] = useState();
    const [liste_participants_file_change_message, setliste_participants_file_change_message] = useState();
    const liste_participants_file_change = event => {
        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setparticipant_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("session_id", mysession);
        formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                if (String(result['status']) === String("true")) {
                    setLoading(false);
                    //console.log('Success:', result['message']);
                    setliste_participants_file_change_result(result['message']);
                    setliste_participants_file_change_api("true");
                    GetListePreinscrit(mysession);
                    GetListeInscrit(mysession);
                    GetListeEmargement(mysession);
                    GetListeEvaluation(mysession);
                    alert(" Les stagiaires ont été correctement importés");
                    submenu_inscrit();
                } else if (String(result['status']) === String("Err_Connexion")) {
                    setLoading(false);
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setLoading(false);
                    setliste_participants_file_change_message(result['message']);
                    setliste_participants_file_change_api("false");
                    alert(result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_participants_file_change_api("false");
                alert(" Impossible d'importer le fichier");
            });

    };


    const [Resend_LMS_Credentials_api, setResend_LMS_Credentials_api] = useState();
    const [Resend_LMS_Credentials_result, setResend_LMS_Credentials_result] = useState();
    const [Resend_LMS_Credentials_message, setResend_LMS_Credentials_message] = useState();
    const Resend_LMS_Credentials = event => {

        clean_old_messages();
        const formData = new FormData();

        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("session_id", mysession);

        var local_mail = ""
        if (document.getElementsByName("detail_mail") && document.getElementsByName("detail_mail")[0])
            local_mail = document.getElementsByName("detail_mail")[0].value;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!local_mail.match(validRegex)) {
            alert("L'adresse email est invalide. Impossible de renvoyer les identifiants");
            return;
        }
        formData.append("email", local_mail);
        formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/LMS_Credential_Sending_mail/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setResend_LMS_Credentials_result(result['message']);
                    setResend_LMS_Credentials_api("true");
                    alert(" Les identifiants de connexion ont été correctement envoyés")
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setResend_LMS_Credentials_message(result['message']);
                    setResend_LMS_Credentials_api("false");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setResend_LMS_Credentials_api("false");
            });

    };


    const [Resend_LMS_Credentials_With_Email_api, setResend_LMS_Credentials_With_Email_api] = useState();
    const [Resend_LMS_Credentials_With_Email_result, setResend_LMS_Credentials_With_Email_result] = useState();
    const [Resend_LMS_Credentials_With_Email_message, setResend_LMS_Credentials_With_Email_message] = useState();
    function Resend_LMS_Credentials_With_Email(myemail) {

        clean_old_messages();
        const formData = new FormData();

        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("session_id", mysession);

        var local_mail = myemail;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!local_mail.match(validRegex)) {
            alert("L'adresse email est invalide. Impossible de renvoyer les identifiants");
            return;
        }
        formData.append("email", local_mail);
        formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/LMS_Credential_Sending_mail/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setResend_LMS_Credentials_With_Email_result(result['message']);
                    setResend_LMS_Credentials_With_Email_api("true");
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setResend_LMS_Credentials_With_Email_message(result['message']);
                    setResend_LMS_Credentials_With_Email_api("false");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setResend_LMS_Credentials_With_Email_api("false");
            });

    };


    // Cette fonction nettoye les messages avant nouvelle action
    function clean_old_messages() {
        setResend_LMS_Credentials_api();
        setResend_LMS_Credentials_message();
        setliste_sessions_file_change_api();
        setCreateSessionFormation_api();
        setUpdateStagiaireData_api();
        setSendInscriptionCancell_api();
        setCreateListEmargement_api();
        setActionMassListEmargement_api();
        setliste_participants_file_change_api();
        setSendEvaluationEmail_api();

    }




    const [session_file_name, setsession_file_name] = useState();
    function submenu_import_session() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }

    const [addOneSession, setaddOneSession] = useState();
    function submenu_add_one_session() {
        setaddOneSession("1");
    }

    const [addOneParticipant, setaddOneParticipant] = useState();
    function submenu_add_one_participant() {
        setaddOneParticipant("1");

        var myelement;

        if (document.getElementById('ajout_participant')) {
            myelement = document.getElementById('ajout_participant');
        }

        if (document.getElementById('ajout_participant')) {
            var divh = document.getElementById('ajout_participant').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
            // myelement.scrollIntoView({ behavior: 'smooth' });
        }

    }

    const hiddenFileInput_session = React.useRef(null);

    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {
        clean_old_messages();

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    alert(result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");
                    GetCurrentClass_trainingsession();
                    clearSessionFields();
                    alert(" Les sessions ont été correctement importées");

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert(result['message']);

                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
            });

    };


    const [print_detail_insc, setprint_detail_insc] = React.useState();
    function Print_Detail_Inscription(event, cellValues) {

        setprint_detail_insc("1")

        var email = cellValues.row.email;
        setselectedattendeeemail(email);
        DownloadAttendeeDetail();

    }

    const [selected_id, setselected_id] = useState("");
    const [display_detail_insc, setdisplay_detail_insc] = React.useState();

    function Display_Detail_Inscription(event, cellValues) {
        clearDetailAttendeFields();
        setdisplay_detail_insc("1")
        var montant = String(cellValues.row.amount);
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        setselected_id(cellValues.row._id);
        setselectedattendeeemail(email);
        var prenom = cellValues.row.prenom;
        GetAttendee_Data(email, mysession);
        getStagiaireImages(internal_url, mysession, email,);

        Get_List_Of_All_PJ(cellValues.row._id);

    }



    const [attendee_lms_pwd, setattendee_lms_pwd] = useState();
    const [attendee_lms_login, setattendee_lms_login] = useState();

    // Cette fonction remplit la zone detail de la personne inscrite
    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function GetAttendee_Data(attendee_email, session_id) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", session_id);
        form.append("attendee_email", attendee_email);
        form.append("internal_url", internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAttendeeDetail_perSession/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                console.log(" In GetAttendee  res.data.status = " + res.data.status);
                console.log(" In GetAttendee  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);



                var mylocalattendee = JSON.parse(res.data.message);


                if (mylocalattendee.inscription_validation_date) {
                    var date_du = new Date(moment(mylocalattendee.inscription_validation_date, "DD/MM/YYYY HH:mm:ss"));
                    setdetailuser_date_inscription(date_du);
                }


                document.getElementsByName("detail_session_class")[0].value = "";
                if (mylocalattendee.code_session)
                    setp_detail_session_class(mylocalattendee.code_session);

                if (mylocalattendee.session_id)
                    setp_detail_session_class_id(mylocalattendee.session_id);


                document.getElementsByName("detail_nom")[0].value = "";
                if (mylocalattendee.nom)
                    setp_detail_nom(mylocalattendee.nom);

                document.getElementsByName("detail_prenom")[0].value = "";
                if (mylocalattendee.prenom)
                    setp_detail_prenom(mylocalattendee.prenom);

                document.getElementsByName("detail_mail")[0].value = "";
                if (mylocalattendee.email) {
                    setp_detail_mail(mylocalattendee.email);
                    setattendee_lms_login(mylocalattendee.email);
                }

                document.getElementsByName("detail_client_rattachement")[0].value = "";
                if (mylocalattendee.client_rattachement_id) {
                    setp_detail_client_rattachement_id(mylocalattendee.client_rattachement_id);
                    setp_detail_client_rattachement_nom(mylocalattendee.client_rattachement_nom);

                }

                document.getElementsByName("detail_status_inscript")[0].value = "";
                if (mylocalattendee.status) {
                    setp_detail_status_inscrit(mylocalattendee.status);
                    if (String(mylocalattendee.status) === "1")
                        setp_detail_status_inscrit_label("Inscrit");
                    else if (String(mylocalattendee.status) === "0")
                        setp_detail_status_inscrit_label("Preinsc.");
                    else if (String(mylocalattendee.status) === "-1")
                        setp_detail_status_inscrit_label("Annulé");
                }


                document.getElementsByName("detail_opco")[0].value = "";
                if (mylocalattendee.opco)
                    setp_detail_opco(mylocalattendee.opco);

                document.getElementsByName("detail_tel")[0].value = "";
                if (mylocalattendee.telephone) {
                    setp_detail_tel(mylocalattendee.telephone);
                }

                document.getElementsByName("detail_adresse")[0].value = "";
                if (mylocalattendee.adresse) {
                    setp_detail_adresse(mylocalattendee.adresse);
                }

                document.getElementsByName("detail_code_postal")[0].value = "";
                if (mylocalattendee.code_postal) {
                    setp_detail_code_postal(mylocalattendee.code_postal);
                }

                document.getElementsByName("detail_ville")[0].value = "";
                if (mylocalattendee.ville) {
                    setp_detail_ville(mylocalattendee.ville);
                }


                document.getElementsByName("detail_cout")[0].value = "";
                if (mylocalattendee.price) {
                    setp_detail_cout(mylocalattendee.price);
                }


                document.getElementsByName("detail_mode_fin")[0].value = "";
                if (mylocalattendee.modefinancement) {
                    setp_detail_mode_fin(mylocalattendee.modefinancement);
                }


                document.getElementsByName("detail_employeur")[0].value = "";
                if (mylocalattendee.employeur) {
                    setp_detail_employeur(mylocalattendee.employeur);
                }

                document.getElementsByName("date_evaluation")[0].value = "";
                if (mylocalattendee.eval_date) {

                    var date_eval = new Date(moment(mylocalattendee.eval_date, "YYYY/MM/DD"));
                    setdetailuser_date_evaluation(date_eval);
                }

                document.getElementsByName("certif_date")[0].value = "";
                if (mylocalattendee.certification_send_date) {
                    //alert(" certification_send_date = ", mylocalattendee.certification_send_date);

                    var date_eval = new Date(moment(mylocalattendee.certification_send_date, "DD/MM/YYYY"));
                    setdetailuser_date_certification(date_eval);
                }



                document.getElementsByName("note_eval")[0].value = "";
                if (mylocalattendee.eval_note) {
                    document.getElementsByName("note_eval")[0].value = mylocalattendee.eval_note;
                }


                if (mylocalattendee.eval_eval) {
                    setfield_evaluation(mylocalattendee.eval_eval);

                }

                if (mylocalattendee.lms_pwd) {
                    setattendee_lms_pwd("xxxxxx");
                }

                /*
                  * Update 22/10/2023 :
                  Gestion des champs spécifiques. ils commencent tous par 'my_'
                  */
                for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

                    var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
                    var field_type = JSON.parse(rows_champs_specifics_stagiaire[i]).field_type;
                    var field_label = JSON.parse(rows_champs_specifics_stagiaire[i]).field_label;
                    var is_mandatory = JSON.parse(rows_champs_specifics_stagiaire[i]).is_mandatory;


                    if (mylocalattendee.hasOwnProperty(field_name)) {
                        var local_valeur = mylocalattendee[String(field_name)]

                        if (document.getElementById(String(field_name)))
                            document.getElementById(String(field_name)).value = local_valeur;

                        var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                        var johnIndex = findIndexByProperty(spec_field_updated_values_stagiaire, 'field_name', field_name);

                        if (johnIndex > -1) {
                            spec_field_updated_values_stagiaire[johnIndex] = new_val;

                        } else {
                            spec_field_updated_values_stagiaire.push(new_val);
                        }

                    } else {

                    }
                }

                setspec_field_updated_values_hooks_stagiaire(spec_field_updated_values_stagiaire);
                /*
                                   *  end Update 22/10/2023 :
                                   */


                var myelement;

                if (document.getElementById('ajout_participant')) {
                    myelement = document.getElementById('ajout_participant');
                }

                if (document.getElementById('ajout_participant')) {
                    //myelement.scrollIntoView({ behavior: 'smooth' });
                    var divh = document.getElementById('ajout_participant').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

                disableAttendeeDetailFields();

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetAttendee = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de l'apprenant");
        })
    }


    // Cette fonction remplit la zone detail de la personne inscrite
    const [GetAttendee_Data_from_line_id_api, setGetAttendee_Data_from_line_id_api] = useState();
    const [GetAttendee_Data_from_line_id_message, setGetAttendee_Data_from_line_id_message] = useState();
    const [GetAttendee_Data_from_line_id_result, setGetAttendee_Data_from_line_id_result] = useState();
    function GetAttendee_Data_from_line_id(participant_line_id) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", participant_line_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAttendeeDetail_perSession_from_line_id/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetAttendee_Data_from_line_id  res.data.status = " + res.data.status);
                //console.log(" In GetAttendee_Data_from_line_id  res.data.message r_class = " + res.data.message);


                setGetAttendee_Data_from_line_id_api("true");
                setGetAttendee_Data_from_line_id_result(res.data.message);
                if (res.data.message.length <= 0) {
                    alert(" Aucune information recuperée");
                } else {


                    var mylocalattendee = JSON.parse(res.data.message);


                    if (mylocalattendee.inscription_validation_date) {
                        var date_du = new Date(moment(mylocalattendee.inscription_validation_date, "DD/MM/YYYY HH:mm:ss"));
                        setdetailuser_date_inscription(date_du);
                    }


                    document.getElementsByName("detail_session_class")[0].value = "";
                    if (mylocalattendee.code_session)
                        setp_detail_session_class(mylocalattendee.code_session);

                    if (mylocalattendee.session_id)
                        setp_detail_session_class_id(mylocalattendee.session_id);


                    document.getElementsByName("detail_nom")[0].value = "";
                    if (mylocalattendee.nom)
                        setp_detail_nom(mylocalattendee.nom);

                    document.getElementsByName("detail_prenom")[0].value = "";
                    if (mylocalattendee.prenom)
                        setp_detail_prenom(mylocalattendee.prenom);

                    document.getElementsByName("detail_mail")[0].value = "";
                    if (mylocalattendee.email) {
                        setp_detail_mail(mylocalattendee.email);
                        setattendee_lms_login(mylocalattendee.email);
                    }

                    document.getElementsByName("detail_client_rattachement")[0].value = "";
                    if (mylocalattendee.client_rattachement_id) {
                        setp_detail_client_rattachement_id(mylocalattendee.client_rattachement_id);
                        setp_detail_client_rattachement_nom(mylocalattendee.client_rattachement_nom);

                    }

                    document.getElementsByName("detail_status_inscript")[0].value = "";
                    if (mylocalattendee.status) {
                        setp_detail_status_inscrit(mylocalattendee.status);
                        if (String(mylocalattendee.status) === "1")
                            setp_detail_status_inscrit_label("Inscrit");
                        else if (String(mylocalattendee.status) === "0")
                            setp_detail_status_inscrit_label("Preinsc.");
                        else if (String(mylocalattendee.status) === "-1")
                            setp_detail_status_inscrit_label("Annulé");
                    }


                    document.getElementsByName("detail_opco")[0].value = "";
                    if (mylocalattendee.opco)
                        setp_detail_opco(mylocalattendee.opco);

                    document.getElementsByName("detail_tel")[0].value = "";
                    if (mylocalattendee.telephone) {
                        setp_detail_tel(mylocalattendee.telephone);
                    }

                    document.getElementsByName("detail_adresse")[0].value = "";
                    if (mylocalattendee.adresse) {
                        setp_detail_adresse(mylocalattendee.adresse);
                    }

                    document.getElementsByName("detail_code_postal")[0].value = "";
                    if (mylocalattendee.code_postal) {
                        setp_detail_code_postal(mylocalattendee.code_postal);
                    }

                    document.getElementsByName("detail_ville")[0].value = "";
                    if (mylocalattendee.ville) {
                        setp_detail_ville(mylocalattendee.ville);
                    }


                    document.getElementsByName("detail_cout")[0].value = "";
                    if (mylocalattendee.price) {
                        setp_detail_cout(mylocalattendee.price);
                    }


                    document.getElementsByName("detail_mode_fin")[0].value = "";
                    if (mylocalattendee.modefinancement) {
                        setp_detail_mode_fin(mylocalattendee.modefinancement);
                    }


                    document.getElementsByName("detail_employeur")[0].value = "";
                    if (mylocalattendee.employeur) {
                        setp_detail_employeur(mylocalattendee.employeur);
                    }

                    document.getElementsByName("date_evaluation")[0].value = "";
                    if (mylocalattendee.eval_date) {

                        var date_eval = new Date(moment(mylocalattendee.eval_date, "YYYY/MM/DD"));
                        setdetailuser_date_evaluation(date_eval);
                    }

                    document.getElementsByName("certif_date")[0].value = "";
                    if (mylocalattendee.certification_send_date) {
                        //alert(" certification_send_date = ", mylocalattendee.certification_send_date);

                        var date_eval = new Date(moment(mylocalattendee.certification_send_date, "DD/MM/YYYY"));
                        setdetailuser_date_certification(date_eval);
                    }



                    document.getElementsByName("note_eval")[0].value = "";
                    if (mylocalattendee.eval_note) {
                        document.getElementsByName("note_eval")[0].value = mylocalattendee.eval_note;
                    }


                    if (mylocalattendee.eval_eval) {
                        setfield_evaluation(mylocalattendee.eval_eval);

                    }

                    if (mylocalattendee.lms_pwd) {
                        setattendee_lms_pwd("xxxxxx");
                    }

                    /*
                  * Update 22/10/2023 :
                  Gestion des champs spécifiques. ils commencent tous par 'my_'
                  */
                    for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

                        var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
                        var field_type = JSON.parse(rows_champs_specifics_stagiaire[i]).field_type;
                        var field_label = JSON.parse(rows_champs_specifics_stagiaire[i]).field_label;
                        var is_mandatory = JSON.parse(rows_champs_specifics_stagiaire[i]).is_mandatory;


                        if (mylocalattendee.hasOwnProperty(field_name)) {
                            var local_valeur = mylocalattendee[String(field_name)]

                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = local_valeur;

                            var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                            var johnIndex = findIndexByProperty(spec_field_updated_values_stagiaire, 'field_name', field_name);

                            if (johnIndex > -1) {
                                spec_field_updated_values_stagiaire[johnIndex] = new_val;

                            } else {
                                spec_field_updated_values_stagiaire.push(new_val);
                            }

                        } else {

                        }
                    }

                    setspec_field_updated_values_hooks_stagiaire(spec_field_updated_values_stagiaire);
                    /*
                                       *  end Update 22/10/2023 :
                                       */


                    var myelement;

                    if (document.getElementById('ajout_participant')) {
                        myelement = document.getElementById('ajout_participant');
                    }

                    if (document.getElementById('ajout_participant')) {
                        var divh = document.getElementById('ajout_participant').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });

                        // myelement.scrollIntoView({ behavior: 'smooth' });
                    }

                }
                disableAttendeeDetailFields();

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetAttendee_Data_from_line_id_api("false");
                setGetAttendee_Data_from_line_id_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetAttendee_Data_from_line_id = ', error);
            setGetAttendee_Data_from_line_id_api("false");
            alert(" Impossible de recuperer les données de l'apprenant");
        })
    }

    const [selectedCertif, setselectedCertif] = useState();
    const [one_selectedCertif, setone_selectedCertif] = useState();

    const [urlpreview_certif, seturlpreview_certif] = useState();
    function handleChangeselectedCertif(event) {

        var val = event.target.value
        //console.log(val);
        GetSpecificAttestation_Certif(val)

        setselectedCertif(val);

        //seturlpreview_certif(val[1]);
    }

    function handleChangeone_selectedCertif(event) {

        var val = event.target.value
        console.log(val);
        GetSpecificAttestation_Certif(val)

        setone_selectedCertif(val);

        //seturlpreview_certif(val[1]);
    }



    const [GetAttestation_Certif_api, setGetAttestation_Certif_api] = useState();
    const [GetAttestation_Certif_message, setGetAttestation_Certif_message] = useState();
    const [GetAttestation_Certif_result, setGetAttestation_Certif_result] = useState();
    function GetAttestation_Certif(event) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetPartnerAttestation_Certificat/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetAttestation_Certif  res.data.status = " + res.data.status);
                //console.log(" In GetAttestation_Certif  res.data.message r_class = " + res.data.message);
                setGetAttestation_Certif_api("true");
                setGetAttestation_Certif_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetAttestation_Certif_api("false");
                setGetAttestation_Certif_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetAttestation_Certif = ', error);
            setGetAttestation_Certif_api("false");

        })
    }


    const [GetSpecificAttestation_Certif_api, setGetSpecificAttestation_Certif_api] = useState();
    const [GetSpecificAttestation_Certif_message, setGetSpecificAttestation_Certif_message] = useState();
    const [GetSpecificAttestation_Certif_result, setGetSpecificAttestation_Certif_result] = useState();
    function GetSpecificAttestation_Certif(nom) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("nom", nom);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetSpecificPartnerAttestation_Certificat/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetSpecificAttestation_Certif  res.data.status = " + res.data.status);
                //console.log(" In GetSpecificAttestation_Certif  res.data.message r_class = " + res.data.message);
                setGetSpecificAttestation_Certif_api("true");
                setGetSpecificAttestation_Certif_result(res.data.message);

                var mylocal = JSON.parse(res.data.message);
                //console.log(" ####  (mylocal.preview_url = ", mylocal.preview_url);
                seturlpreview_certif(mylocal.preview_url);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetSpecificAttestation_Certif_api("false");
                setGetSpecificAttestation_Certif_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetSpecificAttestation_Certif = ', error);
            setGetSpecificAttestation_Certif_api("false");

        })
    }


    const editorRef_evaluation = useRef(null);
    const [field_evaluation, setfield_evaluation] = useState("");
    function editor_keyup() {
    }

    const editorRef_contenu_ftion = useRef(null);
    const [editorRef_contenu_ftion_limite, seteditorRef_description_limite] = useState(800);

    const one_editorRef_contenu_ftion = useRef(null);
    const [one_editorRef_contenu_ftion_limite, setone_editorRef_description_limite] = useState(800);

    const [field_contenu_ftion, setfield_contenu_ftion] = useState("");
    function editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }


    function one_editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }

    const [SendEvaluationEmail_api, setSendEvaluationEmail_api] = useState();
    const [SendEvaluationEmail_message, setSendEvaluationEmail_message] = useState();
    const [SendEvaluationEmail_result, setSendEvaluationEmail_result] = useState();
    function SendEvaluationEmail() {
        clean_old_messages();
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", mysession);
        form.append("attendee_email", selectedattendeeemail);
        form.append("class_internal_url", internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendTrainingEvaluationEmail/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendEvaluationEmail  res.data.status = " + res.data.status);
                //console.log(" In SendEvaluationEmail  res.data.message r_class = " + res.data.message);
                setSendEvaluationEmail_api("true");
                setSendEvaluationEmail_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendEvaluationEmail_api("false");
                setSendEvaluationEmail_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendEvaluationEmail = ', error);
            setSendEvaluationEmail_api("false");

        })
    }

    function SendEvaluationEmail_One(email) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", mysession);
        form.append("attendee_email", email);
        form.append("class_internal_url", internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendTrainingEvaluationEmail/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendEvaluationEmail  res.data.status = " + res.data.status);
                //console.log(" In SendEvaluationEmail  res.data.message r_class = " + res.data.message);
                setSendEvaluationEmail_api("true");
                setSendEvaluationEmail_result(res.data.message);
                alert(" La demande a été correctement envoyée");

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendEvaluationEmail_api("false");
                setSendEvaluationEmail_message(res.data.message);
                alert(" Erreur : " + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendEvaluationEmail = ', error);
            setSendEvaluationEmail_api("false");

        })
    }

    const [SendAttendeeAttestation_api, setSendAttendeeAttestation_api] = useState();
    const [SendAttendeeAttestation_message, setSendAttendeeAttestation_message] = useState();
    const [SendAttendeeAttestation_result, setSendAttendeeAttestation_result] = useState();
    function SendAttendeeAttestation() {
        clean_old_messages();
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", mysession);
        form.append("attendee_email", selectedattendeeemail);
        form.append("class_internal_url", internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendAttendeeCertification/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendAttendeeAttestation  res.data.status = " + res.data.status);
                //console.log(" In SendAttendeeAttestation  res.data.message r_class = " + res.data.message);
                setSendAttendeeAttestation_api("true");
                setSendAttendeeAttestation_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendAttendeeAttestation_api("false");
                setSendAttendeeAttestation_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendAttendeeAttestation = ', error);
            setSendAttendeeAttestation_api("false");

        })

    }

    const [sessionpresence, setsessionpresence] = useState("");
    const [sessiondistance, setsessiondistance] = useState("");
    const [sessionondemande, setsessionondemande] = useState("");

    const handleChangeSessionPresentiel = (event) => {
        const value = event.target.value;
        setsessionpresence(value);

    }

    const handleChangeSessionDistanciel = (event) => {
        const value = event.target.value;
        setsessiondistance(value);

    }

    const [actionmass_preinsc_val, setactionmass_preinsc_val] = useState();
    const actionmass_preinsc = (event) => {
        const value = event.target.value;

        if (String(value) === "valider" || String(value) === "refuser") {
            setactionmass_preinsc_val(value);
        }
        else {
            setactionmass_preinsc_val();
        }
    }

    function GetSelectedRows_PresInsc() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss[myid]);
            tab_tmp.push(line.email);
        }
        return tab_tmp;

    }

    function GetSelectedRows_PresInsc_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    const [retval_ch, setretval_ch] = useState();
    async function actionmass_preinsc_Traitemet() {

        var liste_formation = GetSelectedRows_PresInsc_Ids();
        for (var i = 0; i < liste_formation.length; i++) {
            var line = JSON.parse(rowss[liste_formation[i]]);

            var email = line.email;
            var montant = String(line.price);
            //console.log("line = ", line);
            //console.log(" montant = ", montant);
            var status;
            var motif = "";
            if (String(actionmass_preinsc_val) === "valider") {
                status = "1";

                /*
                Aupdate du 25/03/23 : le montant > 0 n'est plus obligatoire.
                */

                if (String(montant) === "undefined" || String(montant).trim() === "") {
                    montant = 0
                }

                if (parseFloat(montant).toFixed(2) <= 0) {
                    alert(" Verifier les montants. Ils sont incorrectes ");
                    return;

                }
            } else if (String(actionmass_preinsc_val) === "refuser") {
                status = "-1";
            }


            var nom = line.nom;
            var prenom = line.prenom;
            var object_id = line.row._id;


            var opco = line.opco;
            if (String(opco) === "undefined") {
                opco = ""
            }

            var modefinancement = line.modefinancement;
            if (String(modefinancement) === "undefined") {
                modefinancement = ""
            }



            var employeur = line.employeur;
            if (String(employeur) === "undefined") {
                employeur = ""
            }

            if (String(actionmass_preinsc_val) === "valider") {
                UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, "", object_id);
            }
            else if (String(actionmass_preinsc_val) === "refuser") {
                UpdateStagiaireData(nom, prenom, email, status, montant, opco, modefinancement, employeur, motif, object_id);
            }
            //console.log(" retval de ", email + "  =  retval_ch =", retval_ch);

            await sleep(100);
        }
        setSelectionModel([]);
        setactionmass_preinsc_val();

        GetListePreinscrit(mysession);
        GetListeInscrit(mysession);
        GetListeEmargement(mysession);
        GetListeEvaluation(mysession);

    }


    const [actionmass_insc_val, setactionmass_insc_val] = useState();
    const actionmass_inscrit = (event) => {
        const value = event.target.value;


        if (String(value) === "confirmation" || String(value) === "impression" || String(value) === "annule inscription") {
            setactionmass_insc_val(value);
        }
        else {
            setactionmass_insc_val();
        }
    }


    function GetSelectedRows_Insc_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_insc.length; i++) {
            //console.log(" ### selectionModel_insc[i] = ", selectionModel_insc[i]);
            var myid = parseInt(String(selectionModel_insc[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_insc_Traitemet() {

        var liste_formation = GetSelectedRows_Insc_Ids();
        for (var i = 0; i < liste_formation.length; i++) {


            var line = JSON.parse(rowss_insc[liste_formation[i]]);
            //console.log(" line = ", line);
            var email = line.email;
            //console.log(" Traitement de email = ", email);
            if (String(actionmass_insc_val) === "confirmation") {

                SendInscriptionConfirmation(email);
            } else if (String(actionmass_insc_val) === "impression") {

                //console.log(" impress mail = ", email);
                DownloadAttendeeDetail_one(email);

            } else if (String(actionmass_insc_val) === "annule inscription") {

                //console.log(" impress mail = ", email);
                SendInscriptionCancell(email);

            }

            await sleep(100);

        }

        setSelectionModel_insc([]);
        setactionmass_insc_val();
        GetListePreinscrit(mysession);
        GetListeInscrit(mysession);
        GetListeEmargement(mysession);
        GetListeEvaluation(mysession);
    }

    const [actionmass_emarg_val, setactionmass_emarg_val] = useState();
    const actionmass_emarg = (event) => {
        const value = event.target.value;


        if (String(value) === "valider" || String(value) === "demande emargement") {
            setactionmass_emarg_val(value);
        }
        else {
            setactionmass_emarg_val();
        }
    }

    function GetSelectedRows_Emarg_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_emarg.length; i++) {
            var myid = parseInt(String(selectionModel_emarg[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_emarge_Traitemet() {
        var liste_formation = GetSelectedRows_Emarg_Ids();
        for (var i = 0; i < liste_formation.length; i++) {
            var line = JSON.parse(rowss_emarg[liste_formation[i]]);
            //console.log(" line = ", line);
            var email = line.email;
            //console.log(" Traitement de email = ", email);

            if (String(actionmass_emarg_val) === "demande emargement") {
                ActionMassListEmargement();
            }
            await sleep(50)
        }
        setSelectionModel_emarg([]);
        setactionmass_emarg_val();

        GetListeEmargement(selected_session_id);

    }


    const [actionmass_eval_val, setactionmass_eval_val] = useState();
    const actionmass_evaluation = (event) => {
        const value = event.target.value;

        //console.log(" ### actionmass_evaluation =  ", value);

        if (String(value) === "demande evaluation") {
            setactionmass_eval_val(value);
            //console.log(" ### LAAA =  ", value);
        }

        else {
            setactionmass_eval_val();
        }
    }

    function GetSelectedRows_Evaluation_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_evaluation.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async function actionmass_Evaluation_Traitemet() {
        var liste_formation = GetSelectedRows_Evaluation_Ids();
        for (var i = 0; i < liste_formation.length; i++) {
            var line = JSON.parse(rowss_evaluation[liste_formation[i]]);
            //console.log(" line = ", line);
            var email = line.email;

            if (String(actionmass_eval_val) === "demande evaluation") {

                SendEvaluationEmail_One(email);
            }
            await sleep(100)
        }
        setSelectionModel_evaluation([]);
        setactionmass_eval_val();
        GetListePreinscrit(mysession);
        GetListeInscrit(mysession);
        GetListeEmargement(mysession);
        GetListeEvaluation(mysession);
    }

    const [reduction_session, setreduction_session] = useState("0");
    function Session_small() {
        if (String(reduction_session) === "1") {
            setreduction_session("0");
        }
        else if (String(reduction_session) === "0") {
            setreduction_session("1");
        }

    }


    const [One_Create_Participant_api, setOne_Create_Participant_api] = useState();
    const [One_Create_Participant_message, setOne_Create_Participant_message] = useState();
    const [One_Create_Participant_result, setOne_Create_Participant_result] = useState();
    function One_Create_Participant() {
        clean_old_messages();
        var form = new FormData();
        var nb_participant = "0";
        var prix_session;


        var one_nom_part = ""
        if (document.getElementById("one_nom_part") && document.getElementById("one_nom_part").value != "") {
            one_nom_part = document.getElementById("one_nom_part").value;
        }
        if (one_nom_part.trim() == "") {
            alert(" Vous devez saisir le nom du participant");
            return;
        }

        var one_prenom_part = ""
        if (document.getElementById("one_prenom_part") && document.getElementById("one_prenom_part").value != "") {
            one_prenom_part = document.getElementById("one_prenom_part").value;
        }
        if (one_prenom_part.trim() == "") {
            alert(" Vous devez saisir le prenom du participant");
            return;
        }

        var one_email_part = ""
        if (document.getElementById("one_email_part") && document.getElementById("one_email_part").value != "") {
            one_email_part = document.getElementById("one_email_part").value;
        }
        if (one_email_part.trim() == "") {
            alert(" Vous devez saisir l'email du participant");
            return;
        }


        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!one_email_part.match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }


        var one_phone_part = ""
        if (document.getElementById("one_phone_part") && document.getElementById("one_phone_part").value != "") {
            one_phone_part = document.getElementById("one_phone_part").value;
        }
        if (one_phone_part.trim() == "") {
            alert(" Vous devez saisir le téléphone du participant");
            return;
        }


        var code_session = "";
        if (document.getElementById("code_session") && document.getElementById("code_session").value != "")
            code_session = document.getElementById("code_session").value;

        if (code_session.trim() == "") {
            alert(" La session est invalide");
            return;
        }


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", selected_session_id);
        form.append("nom", one_nom_part);
        form.append("prenom", one_prenom_part);
        form.append("email", one_email_part);
        form.append("telephone", one_phone_part);
        form.append("status", myinscript_status);
        form.append("modefinancement", "");
        if (p_one_detail_client_rattachement_id)
            form.append("client_rattachement_id", p_one_detail_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");

        /*
                Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
              */
        for (let i = 0; i < spec_field_updated_values_hooks_stagiaire.length; i++) {
            var local_value = String(spec_field_updated_values_hooks_stagiaire[i].field_value);

            if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks_stagiaire[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " est obligatoire.")
                    return;
                }

            }


            form.append(String(spec_field_updated_values_hooks_stagiaire[i].field_name), local_value);
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass/";



        axios.post(myurl, form).then(res => {
            //console.log(" In One_Create_Participant  res.data.status = " + res.data.status);
            //console.log(" In One_Create_Participant  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setOne_Create_Participant_api("true");
                setOne_Create_Participant_result(res.data.message);
                setaddOneParticipant("0");
                setreduction_session("1");
                GetListePreinscrit(mysession);
                GetListeInscrit(mysession);
                GetListeEmargement(mysession);
                GetListeEvaluation(mysession);
                submenu_inscrit();
                alert(" Le participant a été correctement ajouté");

                var divh = document.getElementById('myRef_detail_inscrit').offsetTop;
                window.scrollTo({
                    top: divh,
                    behavior: "smooth",
                });


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setOne_Create_Participant_api("false");
                setOne_Create_Participant_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {

            console.warn('One_Create_Participant : Not good man :(  = ' + error);
            setOne_Create_Participant_api("false");
            alert(" Imposible d'ajouter l'apprenant")

        })
    }


    const [myinscript_status, setmyinscript_status] = useState("");



    const handleChangeInscription_Status = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setmyinscript_status(value);
    }


    const ancre_ref_new_gif = useRef(null)

    const [Delete_Session_Formation_api, setDelete_Session_Formation_api] = useState();
    const [Delete_Session_Formation_message, setDelete_Session_Formation_message] = useState();
    const [Delete_Session_Formation_result, setDelete_Session_Formation_result] = useState();
    function Delete_Session_Formation() {

        setSendInscriptionCancell_api();
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("session_id", selected_session_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_SessionFormation/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Session_Formation  res.data.status = " + res.data.status);
            //console.log(" In Delete_Session_Formation  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Session_Formation_api("true");
                setDelete_Session_Formation_result(res.data.message);
                alert(" La session a été correctement supprimée.")

                window.location.reload();
                return;
            }
            else {
                setDelete_Session_Formation_api("false");
                setDelete_Session_Formation_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_Session_Formation : Not good man :( mysearchtext = ' + error);
            setDelete_Session_Formation_api("false");
            alert(" Impossible de supprimer la session");
        })
    }

    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


    const myRef_detail_inscrit = useRef(null)


    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };


    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
    const [userformedited, setuserformedited] = useState();

    const [getPartnerImages_api, setgetPartnerImages_api] = useState();
    const [getPartnerImages_message, setgetPartnerImages_message] = useState();
    const [getPartnerImages_result, setgetPartnerImages_result] = useState();
    function getStagiaireImages(local_internal_url, local_mysession, local_email) {
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedStagiaireImage_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", local_internal_url);
        form.append("session_id", local_mysession);
        form.append("email", local_email);


        axios.post(myurl, form).then(res => {

            //console.log(" getStagiaireImages : In test  res.data.status = " + res.data.status);
            // console.log(" getStagiaireImages: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setgetPartnerImages_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setgetPartnerImages_api("false");
                setgetPartnerImages_message(res.data.message)

            }
        }).catch((error) => {
            console.warn('getStagiaireImages ee: Not good man :(  = ', error);
            setgetPartnerImages_api("false");
            setgetPartnerImages_message(" Impossible de recuperer les images/médias du partenaire")
        })

    }


    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/DeleteImage_Stagiaire_v2/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {

                console.log(' removeRecodedClassImage : status:', result['status']);
                console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");
                    //getStagiaireImages();

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }

    const [record_Stagiaire_Image_api, setrecord_Stagiaire_Image_api] = useState();
    const [record_Stagiaire_Image_message, setrecord_Stagiaire_Image_message] = useState();
    const [record_Stagiaire_Image_result, setrecord_Stagiaire_Image_result] = useState();
    function record_Stagiaire_Image(l) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Update_Stagiaire_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("class_internal_url", internal_url);
        formData.append("session_id", mysession);
        formData.append("email", selectedattendeeemail);

        console.log(" ### userimgclassprofilchanged =", userimgclassprofilchanged)


        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);


        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_Stagiaire_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_Stagiaire_Image_api("false");
                    alert(" Erreur : " + result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setrecord_Stagiaire_Image_api("false");
                alert(" Erreur : Impossible d'enregistrer l'image ");

            });


    };


    const [p_detail_nom, setp_detail_nom] = useState();
    const [p_detail_prenom, setp_detail_prenom] = useState();
    const [p_detail_mail, setp_detail_mail] = useState();
    const [p_detail_tel, setp_detail_tel] = useState();
    const [p_detail_adresse, setp_detail_adresse] = useState();
    const [p_detail_code_postal, setp_detail_code_postal] = useState();
    const [p_detail_ville, setp_detail_ville] = useState();
    const [p_detail_cout, setp_detail_cout] = useState();
    const [p_detail_mode_fin, setp_detail_mode_fin] = useState();
    const [p_detail_employeur, setp_detail_employeur] = useState();
    const [p_date_evaluation, setp_date_evaluation] = useState();
    const [p_note_eval, setp_note_eval] = useState();
    const [p_detail_session_class, setp_detail_session_class] = useState();
    const [p_detail_session_class_id, setp_detail_session_class_id] = useState();
    const [p_detail_status_inscrit, setp_detail_status_inscrit] = useState();
    const [p_detail_status_inscrit_label, setp_detail_status_inscrit_label] = useState();
    const [p_detail_opco, setp_detail_opco] = useState();
    const [p_detail_client_rattachement_nom, setp_detail_client_rattachement_nom] = useState();
    const [p_detail_client_rattachement_id, setp_detail_client_rattachement_id] = useState();

    let [participant_data_changed, setparticipant_data_changed] = useState();
    let [participant_data_edit_mode, setparticipant_data_edit_mode] = useState();

    function EnableAttendeeDetailFields() {
        setparticipant_data_edit_mode("1");

        document.getElementsByName("detail_session_class")[0].disabled = false;
        document.getElementsByName("detail_session_class")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_client_rattachement")[0].disabled = false;
        document.getElementsByName("detail_client_rattachement")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_nom")[0].disabled = false;
        document.getElementsByName("detail_nom")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_status_inscript")[0].disabled = false;
        document.getElementsByName("detail_status_inscript")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_opco")[0].disabled = false;
        document.getElementsByName("detail_opco")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_prenom")[0].disabled = false;
        document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_mail")[0].disabled = false;
        document.getElementsByName("detail_mail")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_tel")[0].disabled = false;
        document.getElementsByName("detail_tel")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_adresse")[0].disabled = false;
        document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_code_postal")[0].disabled = false;
        document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_ville")[0].disabled = false;
        document.getElementsByName("detail_ville")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_cout")[0].disabled = false;
        document.getElementsByName("detail_cout")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_mode_fin")[0].disabled = false;
        document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_employeur")[0].disabled = false;
        document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#FFFFFF";


        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = false;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#FFFFFF";
            }
        }

    }

    function disableAttendeeDetailFields() {
        setparticipant_data_edit_mode("0");


        document.getElementsByName("detail_session_class")[0].disabled = true;
        document.getElementsByName("detail_session_class")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_client_rattachement")[0].disabled = true;
        document.getElementsByName("detail_client_rattachement")[0].style.backgroundColor = "#ECEFF1";



        document.getElementsByName("detail_nom")[0].disabled = true;
        document.getElementsByName("detail_nom")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_status_inscript")[0].disabled = true;
        document.getElementsByName("detail_status_inscript")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_opco")[0].disabled = true;
        document.getElementsByName("detail_opco")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_prenom")[0].disabled = true;
        document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_mail")[0].disabled = true;
        document.getElementsByName("detail_mail")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_tel")[0].disabled = true;
        document.getElementsByName("detail_tel")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_adresse")[0].disabled = true;
        document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_code_postal")[0].disabled = true;
        document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_ville")[0].disabled = true;
        document.getElementsByName("detail_ville")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_cout")[0].disabled = true;
        document.getElementsByName("detail_cout")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_mode_fin")[0].disabled = true;
        document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("detail_employeur")[0].disabled = true;
        document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("date_evaluation")[0].disabled = true;
        document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("detail_incr_date")[0].disabled = true;
        document.getElementsByName("detail_incr_date")[0].style.backgroundColor = "#ECEFF1";


        document.getElementsByName("certif_date")[0].disabled = true;
        document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = true;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
            }
        }
    }

    function AnnuleAttendeeDetailFields() {
        GetAttendee_Data(selectedattendeeemail, mysession);
        disableAttendeeDetailFields();
        setparticipant_data_changed();
        setparticipant_data_edit_mode();
    }

    const [Update_One_StagiaireData_api, setUpdate_One_StagiaireData_api] = useState();
    const [Update_One_StagiaireData_message, setUpdate_One_StagiaireData_message] = useState();
    const [Update_One_StagiaireData_result, setUpdate_One_StagiaireData_result] = useState();
    function Update_One_StagiaireData() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (p_detail_session_class.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", internal_url);
        form.append("session_id", p_detail_session_class_id);
        form.append("nom", p_detail_nom);
        form.append("prenom", p_detail_prenom);
        form.append("email", p_detail_mail);
        form.append("employeur", p_detail_employeur);
        form.append("status", p_detail_status_inscrit);
        form.append("price", p_detail_cout);
        form.append("modefinancement", p_detail_mode_fin);
        form.append("opco", p_detail_opco);

        form.append("_id", selected_id);
        form.append("client_rattachement_id", p_detail_client_rattachement_id);



        //console.log(" ### form = ", form);
        /*
Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
*/
        for (let i = 0; i < spec_field_updated_values_hooks_stagiaire.length; i++) {

            var local_value = String(spec_field_updated_values_hooks_stagiaire[i].field_value);

            if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks_stagiaire[i].field_type) === "string") {

            }

            if (String(spec_field_updated_values_hooks_stagiaire[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks_stagiaire[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks_stagiaire[i].field_name), local_value);
        }
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass/";

        axios.post(myurl, form).then(res => {
            //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_StagiaireData_api("true");
                setUpdate_One_StagiaireData_result(res.data.message);
                disableAttendeeDetailFields();
                setparticipant_data_changed();
                setparticipant_data_edit_mode();
                GetListePreinscrit(p_detail_session_class_id);
                GetListeInscrit(p_detail_session_class_id);

                GetAttendee_Data_from_line_id(selected_id);
                GetCurrentClass_trainingsession()

                alert(" La mise à jour été correctement faite.");

            }
            else {
                setUpdate_One_StagiaireData_api("false");
                setUpdate_One_StagiaireData_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setUpdate_One_StagiaireData_api("false");
            alert(" Impossible de mettre à jour le participant");

        })
    }

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [isLoading, setLoading] = useState();

    const status_inscription = [

        {
            value: '0',
            label: 'PréInscription',
        },
        {
            value: '1',
            label: 'Inscription',
        },
        {
            value: '2',
            label: 'En Cours',
        },
        {
            value: '-1',
            label: 'Annulation',
        },

    ];

    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();

    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

       


        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }
        setfile_1_name(event.target.files[0]);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setsessions_file_change_1_result(result['message']);
                    setsessions_file_change_1_api("true");
                    //GetCurrentClass_trainingsession();

                }
                else {
                    setsessions_file_change_1_message(result['message']);
                    setsessions_file_change_1_api("false");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setsessions_file_change_1_api("false");
            });
    };


    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

       


        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }
        setfile_2_name(event.target.files[0]);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setsessions_file_change_2_result(result['message']);
                    setsessions_file_change_2_api("true");
                    //GetCurrentClass_trainingsession();

                }
                else {
                    setsessions_file_change_2_message(result['message']);
                    setsessions_file_change_2_api("false");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setsessions_file_change_2_api("false");
            });
    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();

    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "inscription");
            formData.append("object_owner_id", selected_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);

            setLoading(true);
            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    setLoading(false);
                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(selected_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();

    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(selected_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();

    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "inscription");
        formData.append("object_owner_id", local_employee_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);
        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes



    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
    
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
    
    */


    const list_nom_champs_spec_session = []
    const [spec_champs_valeur_session, setspec_champs_valeur_session] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_session_api, setGet_Partner_Object_Specific_Fields_session_api] = useState();
    const [Get_Partner_Object_Specific_Fields_session_message, setGet_Partner_Object_Specific_Fields_session_message] = useState();
    const [Get_Partner_Object_Specific_Fields_session_result, setGet_Partner_Object_Specific_Fields_session_result] = useState();
    function Get_Partner_Object_Specific_Fields_session() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", "session_formation");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_session_api("true");
                setGet_Partner_Object_Specific_Fields_session_result(res.data.message);


                setrows_champs_specifics_session(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_session_api("false");
                setGet_Partner_Object_Specific_Fields_session_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_session_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics_session, setrows_champs_specifics_session] = useState([]);
    const [display_champs_specifics_session, setdisplay_champs_specifics_session] = useState("0");

    function afficher_champs_spec_session() {

        setdisplay_champs_specifics_session("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values_session = []
    const [spec_field_updated_values_hooks_session, setspec_field_updated_values_hooks_session] = useState([]);


    function change_champs_spec_handle_session() {

        for (let i = 0; i < rows_champs_specifics_session.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_session[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics_session[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics_session[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics_session[i]).is_mandatory;

            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values_session, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values_session[johnIndex] = new_val;

                } else {
                    spec_field_updated_values_session.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks_session(spec_field_updated_values_session)
    }


    const list_nom_champs_spec_stagiaire = []
    const [spec_champs_valeur_stagiaire, setspec_champs_valeur_stagiaire] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_stagiaire_api, setGet_Partner_Object_Specific_Fields_stagiaire_api] = useState();
    const [Get_Partner_Object_Specific_Fields_stagiaire_message, setGet_Partner_Object_Specific_Fields_stagiaire_message] = useState();
    const [Get_Partner_Object_Specific_Fields_stagiaire_result, setGet_Partner_Object_Specific_Fields_stagiaire_result] = useState();
    function Get_Partner_Object_Specific_Fields_stagiaire() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", "inscription");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_stagiaire_api("true");
                setGet_Partner_Object_Specific_Fields_stagiaire_result(res.data.message);


                setrows_champs_specifics_stagiaire(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_stagiaire_api("false");
                setGet_Partner_Object_Specific_Fields_stagiaire_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_stagiaire_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics_stagiaire, setrows_champs_specifics_stagiaire] = useState([]);
    const [display_champs_specifics_stagiaire, setdisplay_champs_specifics_stagiaire] = useState("0");

    function afficher_champs_spec_stagiaire() {

        setdisplay_champs_specifics_stagiaire("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }


    const spec_field_updated_values_stagiaire = []
    const [spec_field_updated_values_hooks_stagiaire, setspec_field_updated_values_hooks_stagiaire] = useState([]);


    function change_champs_spec_handle_stagiaire() {

        for (let i = 0; i < rows_champs_specifics_stagiaire.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics_stagiaire[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics_stagiaire[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics_stagiaire[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics_stagiaire[i]).is_mandatory;

            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values_stagiaire, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values_stagiaire[johnIndex] = new_val;

                } else {
                    spec_field_updated_values_stagiaire.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks_stagiaire(spec_field_updated_values_stagiaire)
    }



    // -- end champs specifiques 





    return (
        <div className='gestionadministrative'>


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>
            <Helmet>
                <title>Partenaires editeurs formations</title>
                <meta name="description" content="backoffice partenaire editeurs formation" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />

            <div className="div_mobile">

                {isLoading && <div className="loader-container">
                    <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
                </div>}


                <div className="zone_data">
                    <h4> Gestion Administrative de : <i><u> {classtitle} </u>  </i>
                    </h4>
                    isLoading = {isLoading} <br />

                    <div className="div_row session_data" style={{ "border": "None" }}>
                        <div className="training_data">
                            <div className="training_caract ">
                                Code <br />

                                <nav className="disabled_style_hilight"> {p_detail_external_code} </nav>
                            </div>

                            <div className="training_caract">

                                Titre <br />
                                <nav className="disabled_style_hilight"> {p_detail_title} </nav>
                            </div>

                            <div className="training_caract">

                                Formateur <br />
                                <nav className="disabled_style_hilight"> {p_detail_trainer} </nav>
                            </div>

                        </div>
                    </div>
                    <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row" style={{ "border": "None" }}>

                                <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>


                                    <Button variant="outlined" onClick={submenu_import_session} className="detail_class_submenu bton_import_excel"
                                        id='menu_import_participant' name='menu_import_participant'>Importer des Sessions Excel &nbsp;
                                        <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                                    </Button>


                                    <Button variant="outlined" onClick={submenu_add_one_session}
                                        className="detail_class_submenu bton_add_session"
                                        id='menu_import_participant' name='menu_import_participant'>Ajout 1 Session &nbsp;
                                        <img src={add_plus} alt="ajout session" className="icon_plus" />
                                    </Button>

                                    <br />
                                    {session_file_name && <nav><i>{session_file_name}</i></nav>}
                                    {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les Sessions ont été correctement importés</nav>}
                                    {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                                    <input type="file"
                                        accept=".csv"
                                        ref={hiddenFileInput_session}
                                        style={{ display: 'none' }}
                                        name="liste_sessions_file"
                                        onChange={liste_sessions_file_change}
                                    />

                                    <a href='/sample/template_import_session_formation.csv' download>Télécharger un fichier modèle</a>
                                </div>



                            </div>
                            {String(addOneSession) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>
                                <h5> Ajout d'une session de formation</h5>

                                <div className="session_caract"> Code Session *<br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_code_session"
                                        id="one_code_session"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"

                                        value={p_session_code_session}
                                        onChange={(e) => {
                                            setp_session_code_session(e.target.value);
                                        }
                                        }

                                    />
                                </div>

                                <div className="session_caract"> Titre Session<br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_titre_session"
                                        id="one_titre_session"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_one_titre}
                                        onChange={(e) => setp_one_titre(e.target.value)}

                                    />
                                </div>

                                <div className="session_caract">Emplacement <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_emplacement_session"
                                        id="one_emplacement_session"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_one_location_type}
                                        onChange={(e) => setp_one_location_type(e.target.value)}

                                    >
                                        <MenuItem value="intra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Intra &nbsp;</MenuItem>
                                        <MenuItem value="extra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Extra &nbsp;</MenuItem>
                                        <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Autre &nbsp;</MenuItem>
                                    </TextField>
                                </div>


                                <div className="session_caract"> Inclus au BPF <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_is_bpf_session"
                                        id="one_is_bpf_session"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_one_is_bpf}
                                        onChange={(e) => setp_one_is_bpf(e.target.value)}

                                    >
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                    </TextField>
                                </div>


                                <div className="session_caract">Statut* <br />
                                    <TextField
                                        name="one_session_status"
                                        id="one_session_status"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "0", max: "1", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_session_status}
                                        onChange={(e) => {
                                            setp_session_status(e.target.value);
                                        }
                                        }
                                    >
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "background": "orange" }}>Inactif &nbsp;&nbsp;</MenuItem>
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "background": "green" }}>Actif &nbsp;</MenuItem>
                                    </TextField>
                                </div>

                                <div className="session_caract">Session à la demande*  <br />
                                    <TextField
                                        name="one_session_ondemande"
                                        id="one_session_ondemande"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "0", max: "1", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_session_ondemande}
                                        onChange={(e) => {
                                            setp_detail_one_session_ondemande(e.target.value);

                                        }}
                                    >
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>

                                    </TextField>
                                </div>

                                <div className="session_caract">
                                    Date début *
                                    <DatePicker
                                        name="one_sessiondatedebut"
                                        id="one_sessiondatedebut"
                                        selected={one_SessionstartDate}
                                        onChange={(date) => {
                                            setone_SessionstartDate(date);
                                            setsessionChanged(true);
                                        }
                                        }
                                        showTimeSelect={false}
                                        filterTime={filterPassedTime_start}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                        locale='fr-FR'

                                    />
                                </div>


                                <div className="session_caract"> Date Fin *
                                    <DatePicker
                                        name="one_sessiondatefin"
                                        id="one_sessiondatefin"
                                        selected={one_SessionendDate}
                                        onChange={(date) => {
                                            setone_SessionendDate(date);
                                            setsessionChanged(true);
                                        }
                                        }
                                        showTimeSelect={false}
                                        filterTime={filterPassedTime_end}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                    />
                                </div>


                                <div className="session_caract"> Date début des inscriptions *
                                    <DatePicker
                                        required
                                        name="one_sessiondatedebutinscription"
                                        id="one_sessiondatedebutinscription"
                                        selected={one_SessionstartDateInscription}
                                        onChange={(date) => {
                                            setone_SessionstartDateInscription(date);
                                            setsessionChanged(true);
                                        }

                                        }
                                        showTimeSelect={false}
                                        //filterTime={filterPassedTime_start}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                        locale='fr-FR'

                                    />
                                </div>


                                <div className="session_caract"> Date Fin des inscriptions *
                                    <DatePicker
                                        required
                                        name="one_sessiondatefininscription"
                                        id="one_sessiondatefininscription"
                                        selected={one_SessionendDateInscription}
                                        onChange={(date) => {
                                            setone_SessionendDateInscription(date);
                                            setsessionChanged(true);
                                        }
                                        }
                                        showTimeSelect={false}
                                        //filterTime={filterPassedTime_end}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style enable_style"
                                    />
                                </div>


                                <div className="session_caract">Présentiel* <br />
                                    <TextField
                                        name="one_presentiel"
                                        id="one_presentiel"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "0", max: "1", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_presentiel}
                                        onChange={(e) => {
                                            setp_detail_one_presentiel(e.target.value);

                                        }}
                                    >
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                    </TextField>
                                </div>

                                <div className="session_caract">A distance* <br />
                                    <TextField
                                        name="one_distantiel"
                                        id="one_distantiel"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "0", max: "1", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_distantiel}
                                        onChange={(e) => {
                                            setp_detail_one_distantiel(e.target.value);

                                        }}
                                    >
                                        <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                        <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                    </TextField>
                                </div>

                                <div className="session_caract"> Adresse<br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_adresse"
                                        id="one_adresse"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"

                                    />
                                </div>
                                <div className="session_caract"> Code postal<br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_code_postal"
                                        id="one_code_postal"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"

                                    />
                                </div>
                                <div className="session_caract"> Ville<br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_ville"
                                        id="one_ville"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"

                                    />
                                </div>



                                <div className="session_caract"> Formateur<br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_formateur_id"
                                        id="one_formateur_id"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_formateur}
                                        onChange={(e) => {
                                            setp_detail_one_formateur(e.target.value);
                                        }
                                        }

                                    >
                                        {Getall_Training_Employee_No_Filter_result &&
                                            Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                                <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                    style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                                    {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </div>



                                <div className="session_caract"> Nombre participants *<br />
                                    <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_nb_participant"
                                        id="one_nb_participant"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "1", max: "1000", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                    />
                                </div>

                                <div className="session_caract"> Prix session <br />
                                    <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="one_prix_session"
                                        id="one_prix_session"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{ min: "1", max: "1000", step: "1" }}
                                        disabled={false}
                                        className="disabled_style enable_style"
                                    />
                                </div>




                                <div className="div_row" style={{ "border": "None" }}>
                                    {/*<div className="session_caract">Choisir un modèle d'attestation :

                                        <select id="one_certif_liste" name="one_certif_liste" value={one_selectedCertif} onChange={handleChangeone_selectedCertif} className="selectsession enable_style">
                                            <option value="">Choisir un modèle </option>
                                            {GetAttestation_Certif_result &&
                                                GetAttestation_Certif_result.map((certificat) => (
                                                    <option value={JSON.parse(certificat).nom}>
                                                        {JSON.parse(certificat).nom}

                                                    </option>
                                                ))}
                                        </select>
                                        <img className="preview_certif" src={urlpreview_certif} />

                                    </div>*/}


                                    <div className="session_caract"> Contenu de la formation (max 800 caractères)<br />
                                        <i> (à afficher sur l'attestation) </i>
                                        {!fields1desabled && <br />}
                                        {!fields1desabled && <br />}

                                        <Editor
                                            onInit={(evt, editor) => one_editorRef_contenu_ftion.current = editor}
                                            initialValue={one_field_contenu_ftion}
                                            onKeyUp={one_editor_contenu_ftion_keyup}
                                            disabled={false}

                                            init={{
                                                resize: false,

                                                height: 200,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: false,
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px; }'
                                            }}
                                        />
                                    </div>
                                    {/* -- début   champs specifiques **/}

                                    <div className="div_row">
                                        <hr />
                                    </div>
                                    <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques <br />

                                        {rows_champs_specifics_session &&
                                            rows_champs_specifics_session.map((champ_spec) => (

                                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                                    <br />
                                                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}
                                                        type="number"
                                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle_session(e.target.value);
                                                            // setEmployee_data_changed("1");
                                                        }}
                                                    />}

                                                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle_session(e.target.value);
                                                            // setEmployee_data_changed("1");
                                                        }}
                                                    />}

                                                </div>
                                            ))}

                                        <br />

                                    </div>

                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>
                                    {/* -- end  champs specifiques **/}

                                </div>


                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                        <Button variant="contained" className="bton_enreg" onClick={One_CreateSessionFormation}>Enregistrer la session
                                        </Button>
                                    </div>

                                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                        <Button variant="contained" className="bton_annule" onClick={One_annuleSessionUpdate} style={{ "textAlign": "center" }}>Annuler
                                        </Button>

                                    </div>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                            </div>
                            }

                            {String(addOneSession) !== "1" && <div>
                                <div className="div_row_gauche" style={{ "textAlign": "left", "paddingLeft": "5px" }} ref={ancre_ref_new_gif}>
                                    <br />

                                    {String(show_new_session_gif) !== "1" && <nav style={{ "width": '100%', "float": 'left' }}>
                                        Choisir une session
                                    </nav>
                                    }
                                    {String(show_new_session_gif) === "1" && <nav style={{ "width": '100%', "float": 'left' }}>
                                        Choisir la nouvelle session  &nbsp; <FiberNewIcon color="success" fontSize="large" />  &nbsp;<img src={gif_new} alt="Nouvelle session" className="gif_new_css" />
                                    </nav>
                                    }
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="session_liste_v2"
                                        id="session_liste_v2"
                                        //label="Choisir une session"
                                        select
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style selectsession"
                                        style={{ "border": "solid 1px", "borderRadius": "5px" }}
                                        //value={p_one_detail_client_rattachement_nom}
                                        onChange={handleChangeSession_v2}

                                    >
                                        {GetCurrentClass_trainingsession_result &&
                                            GetCurrentClass_trainingsession_result.map((session) => (
                                                <MenuItem key={(JSON.parse(session)._id)}
                                                    style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }} className="selectsession" id="mysy_code_session"
                                                    value={(JSON.parse(session)._id)} mysy_code_session={(JSON.parse(session).code_session)}>
                                                    {(JSON.parse(session).code_session) && (JSON.parse(session).code_session) + " - "}
                                                    {(JSON.parse(session).session_ondemande) === "1" && " A la Demande - "}
                                                    {(!JSON.parse(session).session_ondemande || JSON.parse(session).session_ondemande === "0") &&
                                                        (JSON.parse(session).date_debut).substring(0, 10) + " au " + (JSON.parse(session).date_fin).substring(0, 10)}


                                                    {/*(JSON.parse(session).date_debut).substring(0, 10)} au {(JSON.parse(session).date_fin).substring(0, 10) */} -
                                                    {(JSON.parse(session).distantiel) === "1" && " A Distance - "}
                                                    {(JSON.parse(session).presentiel) === "1" && (JSON.parse(session).adresse) && " En Présentiel - " + (JSON.parse(session).adresse)}
                                                    {(JSON.parse(session).presentiel) === "1" && (!JSON.parse(session).adresse) && " En Présentiel - "}
                                                    {(JSON.parse(session).code_postal) && " " + (JSON.parse(session).code_postal)}

                                                </MenuItem>
                                            ))}
                                    </TextField>



                                </div>

                                <div className="div_row_droite">


                                    <div className="field_droite">
                                        {/* <select id="session_liste" value={mysession} onChange={handleChangeSession} className="selectsession">
                                            <option value="" className="select_option_css">Choisir</option>


                                            {GetCurrentClass_trainingsession_result &&
                                                GetCurrentClass_trainingsession_result.map((session) => (


                                                    <option value={(JSON.parse(session).code_session)} className="select_option_css">
                                                        {(JSON.parse(session).session_ondemande) === "1" && " A la Demande - "}
                                                        {(!JSON.parse(session).session_ondemande || JSON.parse(session).session_ondemande === "0") &&
                                                            (JSON.parse(session).date_debut).substring(0, 10) + " au " + (JSON.parse(session).date_fin).substring(0, 10)}


                                                        {/*(JSON.parse(session).date_debut).substring(0, 10)} au {(JSON.parse(session).date_fin).substring(0, 10) * /} -
                                                        {(JSON.parse(session).distantiel) === "1" && " A Distance - "}
                                                        {(JSON.parse(session).presentiel) === "1" && (JSON.parse(session).adresse) && " En Présentiel - " + (JSON.parse(session).adresse)}
                                                        {(JSON.parse(session).presentiel) === "1" && (!JSON.parse(session).adresse) && " En Présentiel - "}
                                                        {(JSON.parse(session).code_postal) && " " + (JSON.parse(session).code_postal)}

                                                    </option>
                                                ))}
                                        </select>*/}
                                    </div>

                                </div>

                            </div>}

                            {String(addOneSession) !== "1" && <div className="div_row" style={{ "border": "None" }}>

                                <div className="session_data" onChange={IssessionChanged}>

                                    {mysession && String(reduction_session) === "0" && <div className="div_row" style={{ "border": "None", "height": "1rem" }}>
                                        <div onClick={Session_small} className="session_open_close"> Reduire la fenetre  <AiFillMinusSquare /></div>
                                    </div>
                                    }

                                    {mysession && String(reduction_session) === "1" && <div className="div_row" style={{ "border": "None", "height": "1rem" }}>
                                        <div onClick={Session_small} className="session_open_close"> Ouvrir la fenetre  <AiFillFolderOpen /></div>
                                    </div>
                                    }

                                    {mysession && <div className="div_row" style={{ "border": "None" }}>

                                        <div className="session_caract" style={{ "float": "right", "fontSize": "12px", 'fontWeight': 'bolder', }}>
                                            <nav onChange={toggleSessionStatus} id="toggleSwitchNav" name="toggleSwitchNav">
                                                <ToggleSwitch label="Activation / Deactivation session? " id="toggleSwitch" name="toggleSwitch" checked={sessionStatus} />
                                            </nav>

                                        </div>

                                        <div className="session_caract" style={{ "float": "right", }}>
                                            <Button className="bton_envoyer" onClick={PrintSesssionDetail}> Télécharger les détails </Button>

                                        </div>
                                        <div className="session_caract" style={{ "float": "right", }}> &nbsp; </div>

                                        <div className="div_row" > <hr className="mysy_hr_text" />
                                        </div>
                                    </div>}

                                    <div className="session_caract"> Code Session<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="code_session"
                                            id="code_session"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"

                                        />
                                    </div>

                                    <div className="session_caract"> Titre Session<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="titre_session"
                                            id="titre_session"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //disabled={true}
                                            className="disabled_style"
                                            value={p_detail_titre}
                                            onChange={(e) => {
                                                setp_detail_titre(e.target.value);
                                                IssessionChanged();

                                            }}


                                        />
                                    </div>

                                    {!fields1desabled && <div className="session_caract">Emplacement <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="emplacement_session"
                                            id="emplacement_session"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //disabled={true}
                                            className="disabled_style"
                                            value={p_detail_location_type}
                                            onChange={(e) => {
                                                setp_detail_location_type(e.target.value);
                                                IssessionChanged();

                                            }}

                                        >
                                            <MenuItem value="intra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Intra &nbsp;&nbsp;</MenuItem>
                                            <MenuItem value="extra" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Extra &nbsp;</MenuItem>
                                            <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Autre &nbsp;</MenuItem>
                                        </TextField>
                                    </div>}


                                    {fields1desabled && <div className="session_caract"> Emplacement <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="emplacement_session"
                                            id="emplacement_session"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //disabled={true}
                                            className="disabled_style"
                                            value={p_detail_location_type}

                                        />
                                    </div>}


                                    {!fields1desabled && <div className="session_caract"> Inclus au BPF <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="is_bpf_session"
                                            id="is_bpf_session"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //disabled={true}
                                            className="disabled_style"
                                            value={p_detail_is_bpf}
                                            onChange={(e) => {
                                                setp_detail_is_bpf(e.target.value);
                                                IssessionChanged();

                                            }}



                                        >
                                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                        </TextField>
                                    </div>}


                                    {fields1desabled && <div className="session_caract"> Inclus au BPF<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="is_bpf_session"
                                            id="is_bpf_session"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            //disabled={true}
                                            className="disabled_style"

                                            value={p_detail_is_bpf_label}

                                        />

                                    </div>}


                                    {fields1desabled && <div className="session_caract">Session à la demande * <br />
                                        <TextField
                                            name="session_ondemande"
                                            id="session_ondemande"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_detail_session_ondemande_label}

                                        />

                                    </div>
                                    }

                                    {!fields1desabled && <div className="session_caract">Session à la demande * <br />
                                        <TextField
                                            name="session_ondemande"
                                            id="session_ondemande"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_detail_session_ondemande}
                                            onChange={(e) => {
                                                setp_detail_session_ondemande(e.target.value);
                                                IssessionChanged();

                                            }}
                                        >
                                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                        </TextField>
                                    </div>
                                    }

                                    {!fields1desabled && <div className="session_caract">Etape <br />
                                        <TextField
                                            name="session_etape"
                                            id="session_etape"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_session_etape}
                                            onChange={(e) => {
                                                setp_session_etape(e.target.value);
                                                IssessionChanged();
                                            }}
                                        >
                                            {Getall_Parter_OR_Default_Session_Steps_result &&
                                                Getall_Parter_OR_Default_Session_Steps_result.map((step) => (
                                                    <MenuItem key={JSON.parse(step)._id} value={JSON.parse(step).session_step_name}
                                                        style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem", "width": '100%' }}>
                                                        {JSON.parse(step).session_step_name}&nbsp;

                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </div>}

                                    {fields1desabled && <div className="session_caract">Etape *<br />

                                        <TextField
                                            name="session_etape"
                                            id="session_etape"
                                            text
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_session_etape_label}

                                        />

                                    </div>}


                                    <div className="session_caract">
                                        Date Debut
                                        <DatePicker
                                            name="sessiondatedebut"
                                            id="sessiondatedebut"
                                            selected={SessionstartDate}
                                            onChange={(date) => {
                                                setSessionstartDate(date);
                                                setsessionChanged(true);
                                            }
                                            }
                                            showTimeSelect={false}
                                            filterTime={filterPassedTime_start}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'

                                        />
                                    </div>


                                    <div className="session_caract"> Date Fin
                                        <DatePicker
                                            name="sessiondatefin"
                                            id="sessiondatefin"
                                            selected={SessionendDate}
                                            onChange={(date) => {
                                                setSessionendDate(date);
                                                setsessionChanged(true);
                                            }
                                            }
                                            showTimeSelect={false}
                                            filterTime={filterPassedTime_end}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                        />
                                    </div>


                                    <div className="session_caract"> Date début des inscriptions *
                                        <DatePicker
                                            required
                                            name="sessiondatedebutinscription"
                                            id="sessiondatedebutinscription"
                                            selected={SessionstartDateInscription}
                                            onChange={(date) => {
                                                setSessionstartDateInscription(date);
                                                setsessionChanged(true);
                                            }

                                            }
                                            showTimeSelect={false}
                                            //filterTime={filterPassedTime_start}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'

                                        />
                                    </div>


                                    <div className="session_caract"> Date Fin des inscriptions *
                                        <DatePicker
                                            required
                                            name="sessiondatefininscription"
                                            id="sessiondatefininscription"
                                            selected={SessionendDateInscription}
                                            onChange={(date) => {
                                                setSessionendDateInscription(date);
                                                setsessionChanged(true);
                                            }
                                            }
                                            showTimeSelect={false}
                                            //filterTime={filterPassedTime_end}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                        />
                                    </div>


                                    {fields1desabled && <div className="session_caract">Présentiel *<br />
                                        <TextField
                                            name="presentiel"
                                            id="presentiel"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_presentiel_label}
                                        >

                                        </TextField>
                                    </div>}

                                    {!fields1desabled && <div className="session_caract">Présentiel *<br />
                                        <TextField
                                            name="presentiel"
                                            id="presentiel"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_presentiel}
                                            onChange={(e) => {
                                                setp_detail_presentiel(e.target.value);
                                                IssessionChanged();

                                            }}
                                        >
                                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                        </TextField>
                                    </div>}






                                    {fields1desabled && <div className="session_caract">A distance *<br />
                                        <TextField
                                            name="distantiel"
                                            id="distantiel"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_distantiel_label}
                                        >

                                        </TextField>
                                    </div>}

                                    {!fields1desabled && <div className="session_caract">A distance *<br />
                                        <TextField
                                            name="distantiel"
                                            id="distantiel"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "1", step: "1" }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_distantiel}
                                            onChange={(e) => {
                                                setp_detail_distantiel(e.target.value);
                                                IssessionChanged();

                                            }}
                                        >
                                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Oui &nbsp;&nbsp;</MenuItem>
                                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left" }}>Non &nbsp;</MenuItem>
                                        </TextField>
                                    </div>}


                                    {String(reduction_session) === "0" && <div>
                                        <div className="session_caract"> Adresse<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="adresse"
                                                id="adresse"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"

                                            />
                                        </div>
                                        <div className="session_caract"> Code postal<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="code_postal"
                                                id="code_postal"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"

                                            />
                                        </div>
                                        <div className="session_caract"> Ville<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="ville"
                                                id="ville"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"

                                            />
                                        </div>



                                        {fields1desabled && <div className="session_caract"> Formateur<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="formateur_label"
                                                id="formateur_label"
                                                value={p_detail_formateur_label}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"

                                            />
                                        </div>}

                                        {!fields1desabled && <div className="session_caract"> Formateur<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="formateur_id"
                                                id="formateur_id"
                                                select
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_formateur_id}
                                                onChange={(e) => {
                                                    setp_detail_formateur_id(e.target.value);
                                                    IssessionChanged();

                                                }
                                                }

                                            >
                                                {Getall_Training_Employee_No_Filter_result &&
                                                    Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                                        <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                            style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem", "width": '100%' }}>
                                                            {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                                        </MenuItem>
                                                    ))}

                                            </TextField>
                                        </div>}




                                        <div className="session_caract"> Nombre participants *<br />
                                            <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="nb_participant"
                                                id="nb_participant"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: "1", max: "1000", step: "1" }}
                                                disabled={false}
                                                className="disabled_style"
                                            />
                                        </div>

                                        <div className="session_caract"> Prix session <br />
                                            <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="prix_session"
                                                id="prix_session"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: "1", max: "1000", step: "1" }}
                                                disabled={false}
                                                className="disabled_style"
                                            />
                                        </div>





                                        <div className="div_row" style={{ "border": "None" }}>
                                            {/*{!fields1desabled && <div className="session_caract">Choisir un modèle d'attestation :

                                                <select id="certif_liste" name="certif_liste" value={selectedCertif} onChange={handleChangeselectedCertif} className="selectsession">
                                                    <option value="">Choisir un modèle </option>
                                                    {GetAttestation_Certif_result &&
                                                        GetAttestation_Certif_result.map((certificat) => (
                                                            <option value={JSON.parse(certificat).nom}>
                                                                {JSON.parse(certificat).nom}

                                                            </option>
                                                        ))}
                                                </select>
                                                <img className="preview_certif" src={urlpreview_certif} />

                                            </div>}
                                            {fields1desabled && <div className="session_caract">Le modèle d'attestation :

                                                <img className="preview_certif" src={urlpreview_certif} />

                                            </div>}
                                            */}

                                            <div className="session_caract"> Contenu de la formation (max 800 caractères)<br />
                                                <i> (à afficher sur l'attestation) </i>
                                                {!fields1desabled && <br />}
                                                {!fields1desabled && <br />}

                                                <Editor
                                                    onInit={(evt, editor) => editorRef_contenu_ftion.current = editor}
                                                    initialValue={field_contenu_ftion}
                                                    onKeyUp={editor_contenu_ftion_keyup}
                                                    disabled={fields1desabled}

                                                    init={{
                                                        resize: false,

                                                        height: 200,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: false,
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px; }'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="div_row" style={{ "border": "None" }}>
                                            &nbsp;
                                        </div>

                                        {/* -- début   champs specifiques **/}

                                        <div className="div_row">
                                            <hr />
                                        </div>
                                        <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques<br />

                                            {rows_champs_specifics_session &&
                                                rows_champs_specifics_session.map((champ_spec) => (

                                                    <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                                        <br />
                                                        {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name={JSON.parse(champ_spec).field_name}
                                                            id={JSON.parse(champ_spec).field_name}
                                                            type="number"
                                                            inputProps={{ min: "1", max: "999999", step: "1" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}


                                                            className="disabled_style"
                                                            onChange={(e) => {
                                                                change_champs_spec_handle_session(e.target.value);
                                                                // setEmployee_data_changed("1");
                                                            }}
                                                        />}

                                                        {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name={JSON.parse(champ_spec).field_name}
                                                            id={JSON.parse(champ_spec).field_name}

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}


                                                            className="disabled_style"
                                                            onChange={(e) => {
                                                                change_champs_spec_handle_session(e.target.value);
                                                                // setEmployee_data_changed("1");
                                                            }}
                                                        />}

                                                    </div>
                                                ))}

                                            <br />

                                        </div>

                                        <div className="div_row" style={{ "border": "None" }}>
                                            &nbsp;
                                        </div>
                                        {/* -- end  champs specifiques **/}


                                        {sessionChanged && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                            /!\ Pensez à enregistrer les modifications
                                        </div>}

                                        {!sessionChanged && String(CreateSessionFormation_api) === "true" &&
                                            <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                La session de formation à été bien mise à jour.
                                            </div>}

                                        {String(CreateSessionFormation_api) === "false" &&
                                            <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                {CreateSessionFormation_message}
                                            </div>}


                                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                                {!sessionChanged && mysession && fields1desabled &&
                                                    <Popup
                                                        trigger={<Button variant="contained" className="bton_suppr" onClick={Delete_Session_Formation} style={{ "textAlign": "center" }}>Supprimer
                                                        </Button>
                                                        }
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >

                                                        {close => (<div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}
                                                                En confirmant cette opération, la session de formation sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                                                <div className="gest_actions">


                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                                            Delete_Session_Formation();
                                                                            close();
                                                                        }}> Valider
                                                                        </button>

                                                                    </div>

                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                        <button
                                                                            className="gest_bton_popup"
                                                                            onClick={() => {

                                                                                close();
                                                                            }}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                    </div>

                                                                </div>

                                                            </div>


                                                        </div>)}
                                                    </Popup>


                                                }


                                                {sessionChanged && mysession &&
                                                    <Button variant="contained" className="bton_enreg" onClick={CreateSessionFormation}>Enregistrer les modifications
                                                    </Button>}
                                            </div>

                                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                                {!sessionChanged && mysession && fields1desabled &&
                                                    <Button variant="contained" className="bton_edit" onClick={enableSessionFields} style={{ "textAlign": "center" }}>Editer
                                                    </Button>}

                                                {((sessionChanged && mysession) || !fields1desabled) &&
                                                    <Button variant="contained" className="bton_annule" onClick={annuleSessionUpdate} style={{ "textAlign": "center" }}>Annuler les modifications
                                                    </Button>}

                                            </div>

                                        </div>}

                                    </div>}

                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>

                                </div>

                            </div>}

                        </div>
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        {String(UpdateStagiaireData_api) === String("true") && <div className="okUpdateData"> la mise à jour a été correctement faite</div>}

                        {String(UpdateStagiaireData_api) === String("false") && <div className="koUpdateData"> {UpdateStagiaireData_message}</div>}

                        {String(SendInscriptionCancell_api) === String("true") && <div className="okUpdateData"> la mise à jour a été correctement faite</div>}

                        {String(SendInscriptionCancell_api) === String("false") && <div className="koUpdateData"> {SendInscriptionCancell_message}</div>}



                        <div style={{ "textAlign": "left", "float": "left", "width": "100%" }} id="participant_menu_tab" ref={myRef_detail_inscrit}>
                            <Button variant="outlined" onClick={submenu_preinscrit} className="detail_class_submenu" id='menu_preinscrit' name='menu_preinscrit'>Preinscription ({String(nb_stagiaire_Preinscrit)})</Button>
                            <Button variant="outlined" onClick={submenu_inscrit} className="detail_class_submenu" id='menu_inscrit' name='menu_inscrit'>Inscription ({String(nb_stagiaire_Inscrit)})</Button>
                            <Button variant="outlined" onClick={submenu_emargement} className="detail_class_submenu" id='menu_emarge' name='menu_emarge'>Liste émargement</Button>
                            <Button variant="outlined" onClick={submenu_evaluation} className="detail_class_submenu" id='menu_eval' name='menu_eval'>Les avis</Button>

                            {/*<hr className="hr_height" />*/}

                            <div className="session_data">
                                {String(submenu) === String("preinscrit") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>

                                    <div style={{ height: 500, width: '100%' }}>
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel(newSelectionModel);
                                                //console.log("ch selected--" + newSelectionModel);
                                            }}
                                            selectionModel={selectionModel}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rowss.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    employeur: JSON.parse(item).employeur,
                                                    email: JSON.parse(item).email,
                                                    amount: JSON.parse(item).price,
                                                    opco: JSON.parse(item).opco,
                                                    modefinancement: JSON.parse(item).modefinancement,


                                                }
                                            ))}

                                            columns={columns_preinscrit}
                                            pageSize={10}
                                            className="datagridclass"

                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}


                                        />
                                        <br />


                                        {selectionModel && selectionModel.length >= 1 &&
                                            <div className="div_row" style={{ "border": "none" }}>
                                                <div className="block_en_mass">
                                                    <nav className='traitement_mass'>Traitement en masse</nav>
                                                    &nbsp;<select
                                                        id={"actionmass_preinsc"} name={"actionmass_preinsc"}
                                                        onChange={actionmass_preinsc}
                                                        className="action_mass">
                                                        <option selected value="n/a">Choisir une action</option>
                                                        <option value="valider">Valider</option>
                                                        <option value="refuser">Refuser</option>

                                                    </select> &nbsp;
                                                    {actionmass_preinsc_val &&
                                                        <Popup
                                                            trigger={<Button className="bton_traiter_en_mass" >
                                                                <FcAcceptDatabase /> Traiter

                                                            </Button>}
                                                            modal
                                                            nested
                                                            position="center center"
                                                        >

                                                            {close => (
                                                                <div>
                                                                    <button className="gest_close" onClick={close}>
                                                                        &times;
                                                                    </button>
                                                                    <div className="gest_header"> MySy Information </div>
                                                                    <div className="gest_content">
                                                                        {' '}

                                                                        Confirmer l'action <b> {actionmass_preinsc_val} </b> en masse.
                                                                    </div>
                                                                    <div className="gest_actions">
                                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                                            <button className="gest_bton_popup" onClick={(event) => {
                                                                                actionmass_preinsc_Traitemet();
                                                                                close();
                                                                            }}> Valider </button>

                                                                        </div>
                                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                                            <button
                                                                                className="gest_bton_popup"
                                                                                onClick={() => {
                                                                                    //console.log('modal closed ');
                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Annuler
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popup>
                                                    }
                                                    <br />

                                                </div>

                                            </div>}


                                        <br />
                                        {/* <Button variant="contained" onClick={GetAllClass_new}>Contained</Button>*/}
                                    </div>

                                </div>}

                                {String(submenu) === String("inscrit") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>

                                    <div style={{ height: 500, width: '100%' }}>
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel_insc) => {
                                                setSelectionModel_insc(newSelectionModel_insc);
                                                //console.log("ch selected--" + newSelectionModel_insc);
                                            }}
                                            selectionModel={selectionModel_insc}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rowss_insc.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    employeur: JSON.parse(item).employeur,
                                                    email: JSON.parse(item).email,
                                                    amount: JSON.parse(item).price,
                                                    opco: JSON.parse(item).opco,
                                                    modefinancement: JSON.parse(item).modefinancement,


                                                }
                                            ))}

                                            columns={columns_inscrit}
                                            pageSize={10}
                                            className="datagridclass"

                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}


                                        />
                                        <br />
                                        {selectionModel_insc && selectionModel_insc.length >= 1 &&
                                            <div className="block_en_mass">
                                                <nav className='traitement_mass'>Traitement en masse / Inscrit</nav>
                                                &nbsp;<select
                                                    id={"actionmassinscr"} name={"actionmassinscr"}
                                                    onChange={actionmass_inscrit}
                                                    className="action_mass">
                                                    <option selected value="n/a">Choisir une action</option>
                                                    <option value="confirmation">Renvoyer une confirmation</option>
                                                    <option value="impression">Imprimer fiches</option>
                                                    <option value="annule inscription">Annuler inscription</option>

                                                </select> &nbsp;

                                                {actionmass_insc_val &&
                                                    <Popup
                                                        trigger={<Button className="bton_traiter_en_mass" >
                                                            <FcAcceptDatabase /> Traiter

                                                        </Button>}
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >

                                                        {close => (
                                                            <div>
                                                                <button className="gest_close" onClick={close}>
                                                                    &times;
                                                                </button>
                                                                <div className="gest_header"> MySy Information </div>
                                                                <div className="gest_content">
                                                                    {' '}

                                                                    Confirmer l'action <b> {actionmass_insc_val} </b> en masse.
                                                                </div>
                                                                <div className="gest_actions">
                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                                            actionmass_insc_Traitemet();
                                                                            close();
                                                                        }}> Valider </button>

                                                                    </div>
                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                        <button
                                                                            className="gest_bton_popup"
                                                                            onClick={() => {
                                                                                //console.log('modal closed ');
                                                                                close();
                                                                            }}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                }
                                                <br />


                                            </div>

                                        }

                                        <br />
                                        {/* <Button variant="contained" onClick={GetAllClass_new}>Contained</Button>*/}
                                    </div>
                                </div>}


                                {String(submenu) === String("emargement") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>


                                    <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                        <Button className="bton_emarge" onClick={ConfirmInitListEmargement}>Initialiser la liste émargement</Button>
                                        <Button className="bton_emarge" onClick={DownloadListEmargement}>Imprimer la liste émargement</Button>
                                        <Button className="bton_emarge" onClick={ConfirmEnvoiListEmargement}>Envoyer les demandes d'émargement</Button><br />

                                    </div>

                                    {String(CreateListEmargement_api) === "true" && <div className="okUpdateData">
                                        La liste d'emargement a été créée
                                    </div>}

                                    {String(CreateListEmargement_api) === "false" && <div className="koUpdateData">
                                        {CreateListEmargement_message}
                                    </div>}

                                    {String(ActionMassListEmargement_api) === "true" && <div className="okUpdateData">
                                        Les demandes d'emargement ont bien été envoyées
                                    </div>}

                                    {String(ActionMassListEmargement_api) === "false" && <div className="koUpdateData">
                                        {CreateListEmargement_message}
                                    </div>}



                                    <div style={{ height: 500, width: '100%' }}>
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel_emarg) => {
                                                setSelectionModel_emarg(newSelectionModel_emarg);
                                                //console.log("ch selected--" + newSelectionModel_emarg);

                                            }}

                                            selectionModel={selectionModel_emarg}
                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rowss_emarg.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    email: JSON.parse(item).email,
                                                    date: JSON.parse(item).date,
                                                    matin: JSON.parse(item).matin,
                                                    apresmidi: JSON.parse(item).apresmidi,
                                                    
                                                    sequence_start: JSON.parse(item).sequence_start,
                                                    sequence_end: JSON.parse(item).sequence_end,
                                                    is_present: JSON.parse(item).is_present,


                                                }
                                            ))}

                                            columns={columns_list_emargement}
                                            pageSize={10}
                                            className="datagridclass"

                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}


                                        />
                                        {selectionModel_emarg && selectionModel_emarg.length >= 1 &&
                                            <div className="block_en_mass">
                                                <nav className='traitement_mass'>Traitement en masse / Emarge</nav>
                                                &nbsp;<select
                                                    id={"actionmassemarg"} name={"actionmassemarg"}
                                                    onChange={actionmass_emarg}
                                                    className="action_mass">
                                                    <option selected value="n/a">Choisir une action</option>
                                                    <option value="demande emargement">Demande emargement</option>

                                                </select> &nbsp;

                                                {actionmass_emarg_val &&
                                                    <Popup
                                                        trigger={<Button className="bton_traiter_en_mass" >
                                                            <FcAcceptDatabase /> Traiter

                                                        </Button>}
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >

                                                        {close => (
                                                            <div>
                                                                <button className="gest_close" onClick={close}>
                                                                    &times;
                                                                </button>
                                                                <div className="gest_header"> MySy Information </div>
                                                                <div className="gest_content">
                                                                    {' '}

                                                                    Confirmer l'action <b> {actionmass_emarg_val} </b> en masse.
                                                                </div>
                                                                <div className="gest_actions">
                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                                            actionmass_emarge_Traitemet();
                                                                            close();
                                                                        }}> Valider </button>

                                                                    </div>
                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                        <button
                                                                            className="gest_bton_popup"
                                                                            onClick={() => {
                                                                                //console.log('modal closed ');
                                                                                close();
                                                                            }}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                }
                                                <br />


                                            </div>
                                        }

                                        <br />
                                        {/*<Button className="bton_emarge" onClick={ActionMassListEmargement}>Envoyer les demandes d'emargement</Button><br />
*/}


                                    </div>
                                </div>}


                                {String(submenu) === String("evaluation") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>


                                    <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                        <Button className="bton_emarge" onClick={PrintEvaluationDetail}>Exporter (excel)</Button>


                                    </div>





                                    <div style={{ height: 500, width: '100%' }}>
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel_evaluation) => {
                                                setSelectionModel_evaluation(newSelectionModel_evaluation);
                                                //console.log("ch selected--" + newSelectionModel_emarg);

                                            }}

                                            selectionModel={selectionModel_evaluation}
                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rowss_evaluation.map((item, index) => (
                                                {
                                                    id: index,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    email: JSON.parse(item).email,
                                                    eval_date: String(JSON.parse(item).eval_date).substring(0, 10),
                                                    eval_eval: parse(String(JSON.parse(item).eval_eval).replace(/(<([^>]+)>)/ig, '')),
                                                    eval_note: JSON.parse(item).eval_note,
                                                    eval_status: JSON.parse(item).eval_status,
                                                    eval_pedagogie: JSON.parse(item).eval_pedagogie,

                                                }
                                            ))}

                                            columns={columns_list_evaluation}
                                            pageSize={10}
                                            className="datagridclass"

                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}


                                        />

                                        {selectionModel_evaluation && selectionModel_evaluation.length >= 1 &&
                                            <div className="block_en_mass">
                                                <nav className='traitement_mass'>Traitement en masse / Evaluation</nav>
                                                &nbsp;<select
                                                    id={"actionmassemarg"} name={"actionmassemarg"}
                                                    onChange={actionmass_evaluation}
                                                    className="action_mass">
                                                    <option selected value="n/a">Choisir une action</option>
                                                    <option value="demande evaluation">Demande évaluation</option>

                                                </select> &nbsp;

                                                {actionmass_eval_val &&
                                                    <Popup
                                                        trigger={<Button className="bton_traiter_en_mass" >
                                                            <FcAcceptDatabase /> Traiter

                                                        </Button>}
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >

                                                        {close => (
                                                            <div>
                                                                <button className="gest_close" onClick={close}>
                                                                    &times;
                                                                </button>
                                                                <div className="gest_header"> MySy Information </div>
                                                                <div className="gest_content">
                                                                    {' '}

                                                                    Confirmer l'action <b> {actionmass_eval_val} </b> en masse.
                                                                </div>
                                                                <div className="gest_actions">
                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                                            actionmass_Evaluation_Traitemet();
                                                                            close();
                                                                        }}> Valider </button>

                                                                    </div>
                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                        <button
                                                                            className="gest_bton_popup"
                                                                            onClick={() => {
                                                                                //console.log('modal closed ');
                                                                                close();
                                                                            }}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                }
                                                <br />


                                            </div>
                                        }

                                        <br />




                                    </div>
                                </div>}

                                {String(submenu) === String("divers") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>

                                </div>}
                            </div>



                        </div>
                        {mysession && (String(submenu) === "preinscrit" || String(submenu) === "inscrit") && <div id='ajout_participant' style={{ "textAlign": "left", "float": "left", "width": "100%" }}>

                            <Button variant="outlined" onClick={submenu_import_participant} className="detail_class_submenu bton_import_excel" style={{
                                "background": "#c8cfd5",
                                "color": "black", "width": "auto"
                            }}
                                id='menu_import_participant' name='menu_import_participant'>Importer des participants excel &nbsp;
                                <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                            </Button>
                            <Button variant="outlined" onClick={submenu_add_one_participant}
                                className="detail_class_submenu bton_add_session"
                                id='menu_one_participant' name='menu_one_participant'>Ajouter 1 participant &nbsp;
                                <img src={participants} alt="ajout un participant" className="icon_plus" />
                            </Button>

                            <br />
                            <a href='/sample/template_import_stagiaires.csv' download>Télécharger un fichier modèle</a>
                            <br />

                            <i>{participant_file_name}</i><br />
                            {String(liste_participants_file_change_api) === String("true") && <nav className="okUpdateData"> Les participants ont été correctement importés</nav>}
                            {String(liste_participants_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_participants_file_change_message} </nav>}

                            <input type="file"
                                accept=".csv"
                                ref={hiddenFileInput}
                                style={{ display: 'none' }}
                                name="liste_participants_file"
                                onChange={liste_participants_file_change}
                            />


                        </div>}
                    </div>

                    {String(addOneParticipant) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>
                        <h5> Ajout d'un nouveau participant</h5>

                        <div className="session_caract"> Nom *<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="one_nom_part"
                                id="one_nom_part"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"

                            />
                        </div>


                        <div className="session_caract"> Prénom *<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="one_prenom_part"
                                id="one_prenom_part"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"

                            />
                        </div>

                        <div className="session_caract"> Email *<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="one_email_part"
                                id="one_email_part"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"

                            />
                        </div>

                        <div className="session_caract"> téléphone *<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="one_phone_part"
                                id="one_phone_part"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"

                            />
                        </div>

                        <div className="session_caract"> Statut Inscription *<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                select
                                value={myinscript_status}
                                name="one_status_part"
                                id="one_status_part"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChangeInscription_Status}
                                disabled={false}
                                className="disabled_style enable_style"
                            >
                                {status_inscription.map((option) => (
                                    <MenuItem key={option.value} value={option.value} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                        {option.label} &nbsp; <br />
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className="session_caract"> Client <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="one_detail_client_rattachement"
                                id="one_detail_client_rattachement"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_one_detail_client_rattachement_nom}
                                onChange={(e) => {
                                    setp_one_detail_client_rattachement_id(e.target.value);
                                    setp_one_detail_client_rattachement_nom(e.target.value);

                                }
                                }

                            >
                                {Get_List_Partner_Clients_result &&
                                    Get_List_Partner_Clients_result.map((myclient) => (
                                        <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                            style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                            {JSON.parse(myclient).nom}&nbsp;&nbsp;

                                        </MenuItem>
                                    ))}
                            </TextField>
                        </div>


                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                <Button variant="contained" className="bton_enreg" onClick={One_Create_Participant}>Enregistrer le participant
                                </Button>
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                <Button variant="contained" className="bton_annule" onClick={One_annuleAdd_Participant} style={{ "textAlign": "center" }}>Annuler
                                </Button>

                            </div>

                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                    </div>
                    }

                    {String(display_detail_insc) === "1" && mysession && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                        <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail inscrit </nav>
                        {String(GetAttendee_api) === "true" && <div className="div_row" style={{ "border": "None" }}>

                            {String(Resend_LMS_Credentials_api) === "true" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                Les identifiants de connexion ont été correctement envoyés</div>}


                            {String(Resend_LMS_Credentials_api) === "false" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                {Resend_LMS_Credentials_message}</div>}

                            <div className="div_row" style={{ "border": "None" }}>

                                <div className="div_row" style={{ "border": "None" }}>
                                    <div className="div_row_gauche">

                                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                                            <div className="div_row_gauche_image">
                                                <img class="img_class_logo" src={userimgclassprofil} /><br />

                                            </div>
                                            <div className="div_row_droite_image">
                                                <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                                {<nav>

                                                    <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                                    {String(userimgclassprofilchanged) === "1" &&
                                                        <nav className="bton_image_class" onClick={record_Stagiaire_Image}>Enregistrer la photo<br />  </nav>
                                                    }

                                                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                                        <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                                    }
                                                    {String(userimgclassprofilchanged) === "1" &&
                                                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                                        </div>}




                                                </nav>}

                                                {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                                {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                                {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                                {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                                            </div>

                                        </div>
                                        <div className="div_row_bis tips_img_class">
                                            <li>
                                                L'image doit etre carrée.
                                            </li>
                                            <li>
                                                Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                                            </li>
                                            <li>
                                                Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                                Les dimensions maximales sont de 256pixels X 256pixels
                                            </li>
                                            <li>
                                                La taille de l'image ne doit pas dépasser 1 mega octet
                                            </li>
                                        </div>

                                    </div>
                                </div>

                                <div className="session_data" >

                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Session <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_session_class"
                                            id="detail_session_class"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_session_class}


                                        />

                                    </div>}

                                    {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Session <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_session_class"
                                            id="detail_session_class"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"

                                            value={p_detail_session_class_id}
                                            onChange={(e) => {
                                                setp_detail_session_class(e.target.value);
                                                setparticipant_data_changed("1");
                                                setp_detail_session_class_id(e.target.value);
                                                Dialog_1_handle_change_participant_session(" Attention, vous allez modifier la session d'affectation du participant");
                                            }
                                            }

                                        >
                                            {GetCurrentClass_trainingsession_result &&
                                                GetCurrentClass_trainingsession_result.map((mysession) => (
                                                    <MenuItem key={JSON.parse(mysession)._id} value={JSON.parse(mysession)._id}
                                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                        {JSON.parse(mysession).code_session}&nbsp;&nbsp;

                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </div>}


                                    <div className="session_caract"> Nom<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_nom"
                                            id="detail_nom"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_nom}
                                            onChange={(e) => {
                                                setp_detail_nom(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}

                                        />
                                    </div>

                                    <div className="session_caract"> Prénom<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_prenom"
                                            id="detail_prenom"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_prenom}
                                            onChange={(e) => {
                                                setp_detail_prenom(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>

                                    <div className="session_caract"> Email<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_mail"
                                            id="detail_mail"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_mail}
                                            onChange={(e) => {
                                                setp_detail_mail(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>

                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Client<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_client_rattachement"
                                            id="detail_client_rattachement"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_client_rattachement_nom}
                                        />

                                    </div>}

                                    {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Client <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_client_rattachement"
                                            id="detail_client_rattachement"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_client_rattachement_nom}
                                            onChange={(e) => {
                                                setp_detail_client_rattachement_id(e.target.value);
                                                setp_detail_client_rattachement_nom(e.target.value);
                                                setparticipant_data_changed("1");
                                                Dialog_1_handle_change_participant_session(" Attention, vous allez modifier le client de rattachement du participant");
                                            }
                                            }

                                        >
                                            {Get_List_Partner_Clients_result &&
                                                Get_List_Partner_Clients_result.map((myclient) => (
                                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                        {JSON.parse(myclient).nom}&nbsp;&nbsp;
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </div>}

                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Statut<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_status_inscript"
                                            id="detail_status_inscript"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_status_inscrit_label}
                                        />

                                    </div>}

                                    {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Statut<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_status_inscript"
                                            id="detail_status_inscript"
                                            select
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_status_inscrit}
                                            onChange={(e) => {
                                                setp_detail_status_inscrit(e.target.value);
                                                setparticipant_data_changed("1");
                                            }
                                            }

                                        >
                                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Inscrit &nbsp;&nbsp;</MenuItem>
                                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Preinscrit &nbsp;</MenuItem>
                                            <MenuItem value="-1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Annule &nbsp;</MenuItem>
                                        </TextField>
                                    </div>}

                                    <div className="session_caract"> Téléphone <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_tel"
                                            id="detail_tel"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_tel}
                                            onChange={(e) => {
                                                setp_detail_tel(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>


                                    <div className="session_caract"> Adresse<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_adresse"
                                            id="detail_adresse"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_adresse}
                                            onChange={(e) => {
                                                setp_detail_adresse(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>
                                    <div className="session_caract"> Code postal<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_code_postal"
                                            id="detail_code_postal"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_code_postal}
                                            onChange={(e) => {
                                                setp_detail_code_postal(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>
                                    <div className="session_caract"> Ville<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_ville"
                                            id="detail_ville"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_ville}
                                            onChange={(e) => {
                                                setp_detail_ville(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>


                                    <div className="session_caract">
                                        Date Inscription
                                        <DatePicker
                                            name="detail_incr_date"
                                            id="detail_incr_date"
                                            selected={detailuser_date_inscription}

                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'

                                        />
                                    </div>


                                    <div className="session_caract"> Cout <br />
                                        <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_cout"
                                            id="detail_cout"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "1", max: "999999", step: "1" }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_cout}
                                            onChange={(e) => {
                                                setp_detail_cout(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>


                                    <div className="session_caract"> Opco <br />
                                        <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_opco"
                                            id="detail_opco"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_opco}
                                            onChange={(e) => {
                                                setp_detail_opco(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>

                                    <div className="session_caract"> Mode Financement<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_mode_fin"
                                            id="detail_mode_fin"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_mode_fin}
                                            onChange={(e) => {
                                                setp_detail_mode_fin(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>


                                    <div className="session_caract"> Employeur<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_employeur"
                                            id="detail_employeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_employeur}
                                            onChange={(e) => {
                                                setp_detail_employeur(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>



                                    {/* -- début champs specifiques **/}
                                    <div className="div_row" style={{ "border": 'none' }}>
                                        <hr />
                                    </div>
                                    <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

                                        {rows_champs_specifics_stagiaire &&
                                            rows_champs_specifics_stagiaire.map((champ_spec) => (

                                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}

                                                        type="number"
                                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle_stagiaire(e.target.value);
                                                            setparticipant_data_changed("1");
                                                        }}
                                                    //onChange={change_champs_spec_handle}


                                                    />}

                                                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle_stagiaire(e.target.value);
                                                            setparticipant_data_changed("1");
                                                        }}
                                                    //onChange={change_champs_spec_handle}


                                                    />}

                                                </div>
                                            ))}

                                        <br />

                                    </div>
                                    {/* -- end  champs specifiques **/}


                                    <div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <hr className="hr_break" />
                                        </div>

                                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                            Pièces jointes

                                        </div>

                                        <div className="div_row" style={{ "padding": "5px", }}>

                                            <div style={{ "fontSize": "12px" }}>
                                                <label htmlFor="upload-photo">
                                                    <input
                                                        style={{ display: "none" }}
                                                        id="upload-photo"
                                                        name="upload-photo"
                                                        type="file"
                                                        onChange={sessions_file_change_1}
                                                    />

                                                    <Fab
                                                        color="secondary"
                                                        size="small"
                                                        component="span"
                                                        aria-label="add"
                                                        variant="extended"
                                                    >
                                                        <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                                    </Fab>

                                                </label>
                                            </div>
                                            {file_1_name && file_1_name.name &&
                                                <nav style={{ "fontSize": "12px" }}>
                                                    <div className="session_caract">Nom du fichier <br />
                                                        <TextField
                                                            name="file_to_download_type_name"
                                                            id="file_to_download_type_name"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}

                                                            disabled={false}
                                                            className="disabled_style enable_style"
                                                            value={p_detail_one_file_to_download_type_name}
                                                            onChange={(e) => {
                                                                setp_detail_one_file_to_download_type_name(e.target.value);

                                                            }}
                                                        />

                                                    </div>
                                                    <br />
                                                    <br />
                                                    Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                                        onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                                                </nav>}

                                            {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                                            {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                                        </div>


                                        {file_1_name && file_1_name.name && <div className="div_row">

                                            <div className="div_row_gauche">
                                                <Button variant="contained" onClick={Record_All_PJ}
                                                    startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                                            </div>


                                        </div>}

                                        {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                                            <div className="div_row">
                                                Liste des pièces jointes <br />
                                                <div className="div_row">
                                                    {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                                        <div className="div_row_list_pj"  >
                                                            <nav style={{ "color": "green", "cursor": "pointer" }} onClick={Download_one_attached_document} name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}> Télécharger &nbsp;<i> {(JSON.parse(val).file_business_object)}</i> </nav> <br />
                                                            <Popup
                                                                trigger={
                                                                    <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                                                }
                                                                modal
                                                                nested
                                                                position="center center"
                                                            >
                                                                {close => (
                                                                    <div>
                                                                        <button className="gest_close" onClick={close}>
                                                                            &times;
                                                                        </button>
                                                                        <div className="gest_header"> MySy Information </div>
                                                                        <div className="gest_content">
                                                                            {' '}

                                                                            En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                                        </div>
                                                                        <div className="gest_actions">
                                                                            <div style={{ "width": "45%", "float": "left" }}>
                                                                                <button className="gest_bton_popup" onClick={(event) => {

                                                                                    Delete_one_attached_document(event);
                                                                                    close();
                                                                                }}
                                                                                    name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                                                > Valider </button>

                                                                            </div>
                                                                            <div style={{ "width": "45%", "float": "right" }}>
                                                                                <button
                                                                                    className="gest_bton_popup"
                                                                                    onClick={() => {
                                                                                        //console.log('modal closed ');
                                                                                        close();
                                                                                    }}
                                                                                >
                                                                                    Annuler
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Popup>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>}



                                    </div>

                                    {is_lms_class_code && <div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <hr className="hr_break" />
                                        </div>
                                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>
                                            Acces LMS

                                        </div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <div className="session_caract"> login LMS<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                                    name="login_lms"
                                                    id="login_lms"
                                                    value={attendee_lms_login}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={true}
                                                    className="disabled_style"

                                                />
                                            </div>

                                            <div className="session_caract"> Mot de passe LMS<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    name="lms_pwd"
                                                    id="lms_pwd"
                                                    value="xxxxx"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={true}
                                                    className="disabled_style"

                                                />
                                            </div>

                                            <div className="session_caract" style={{ "paddingTop": "25px" }}> &nbsp;<br />

                                                <Button variant="contained" className="bton_fermer" onClick={Resend_LMS_Credentials}> (R)envoyer les Identifiants
                                                </Button>

                                            </div>
                                        </div>
                                    </div>}


                                    {String(participant_data_changed) === "1" && String(participant_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                        /!\ Pensez à enregistrer les modifications
                                    </div>}


                                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                        {String(participant_data_changed) === "1" && String(participant_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                            <Button variant="contained" className="bton_enreg" onClick={Update_One_StagiaireData}>Enregistrer les modifications
                                            </Button>

                                        </div>}

                                        {String(participant_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} onClick={AnnuleAttendeeDetailFields}>
                                            <Button variant="contained" className="bton_annule" onClick={AnnuleAttendeeDetailFields} style={{ "textAlign": "center" }}>Annuler
                                            </Button>
                                        </div>}
                                    </div>


                                    {String(participant_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                            &nbsp;
                                        </div>

                                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                            <Button variant="contained" className="bton_fermer" onClick={EnableAttendeeDetailFields} style={{ "textAlign": "center" }}>Editer
                                            </Button>
                                        </div>

                                    </div>}

                                    <div className="div_row" style={{ "border": "None" }}>
                                        <hr className="hr_break" />
                                    </div>

                                    <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>
                                        Evaluation

                                    </div>

                                    <div className="div_row" style={{ "border": "None" }}>
                                        <div className="session_caract">
                                            Date Evaluation
                                            <DatePicker
                                                name="date_evaluation"
                                                id="date_evaluation"
                                                selected={detailuser_date_evaluation}


                                                dateFormat="dd/MM/yyyy"
                                                className="disabled_style"
                                                locale='fr-FR'


                                            />
                                        </div>

                                        <div className="session_caract"> Note Evaluation<br />
                                            <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="note_eval"
                                                id="note_eval"
                                                type="number"


                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: "0", max: "5", step: "1", readOnly: true, }}

                                                className="disabled_style"
                                            />
                                        </div>

                                        <div className="session_caract"> Evaluation<br />
                                            <Editor
                                                onInit={(evt, editor) => editorRef_evaluation.current = editor}
                                                initialValue={field_evaluation}
                                                onKeyUp={editor_keyup}
                                                disabled={true}

                                                init={{
                                                    resize: false,

                                                    height: 100,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: false,
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                                }}
                                            />



                                        </div>


                                    </div>


                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>

                                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                            {String(SendEvaluationEmail_api) === "false" && <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                {SendEvaluationEmail_message}</div>}


                                            {String(SendEvaluationEmail_api) === "true" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                La demande d'evaluation ete envoyée par email </div>}


                                            <Button variant="contained" className="bton_envoyer" onClick={SendEvaluationEmail}>Envoi demande evaluation
                                            </Button>

                                        </div>

                                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                            <Button variant="contained" className="bton_fermer" onClick={DownloadAttendeeDetail}>Imprimer fiche detaillée
                                            </Button>

                                        </div>

                                    </div>}
                                    <div className="div_row" style={{ "border": "None" }}>
                                        <hr className="hr_break" />
                                    </div>

                                    <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>
                                        Attestation

                                    </div>
                                    <div className="div_row" style={{ "border": "None" }}>
                                        {detailuser_date_certification && <div className="session_caract">
                                            Attestation delivrée le
                                            <DatePicker
                                                name="certif_date"
                                                id="certif_date"
                                                selected={detailuser_date_certification}

                                                dateFormat="dd/MM/yyyy"
                                                className="disabled_style"
                                                locale='fr-FR'


                                            />
                                        </div>}

                                        {!detailuser_date_certification && <div className="session_caract">
                                            Attestation NON délivrée encore
                                            <nav style={{ "visibility": "hidden" }}> <DatePicker
                                                name="certif_date"
                                                id="certif_date"
                                                selected={detailuser_date_certification}

                                                dateFormat="dd/MM/yyyy"
                                                className="disabled_style"
                                                locale='fr-FR'
                                                style={{ "visibility": "hidden" }}

                                            />
                                            </nav>
                                        </div>}

                                    </div>
                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>

                                    {sessionChanged && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                        /!\ Pensez à enregistrer les modifications
                                    </div>}

                                    {!sessionChanged && String(CreateSessionFormation_api) === "true" &&
                                        <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                            La session de formation à été bien mise à jour.
                                        </div>}

                                    {!sessionChanged && String(CreateSessionFormation_api) === "false" &&
                                        <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                            {CreateSessionFormation_message}
                                        </div>}


                                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>


                                            {String(SendAttendeeAttestation_api) === "true" &&
                                                <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                    L'attestation a été bien envoyée par email
                                                </div>}

                                            {String(SendAttendeeAttestation_api) === "false" &&
                                                <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                    {SendAttendeeAttestation_message}
                                                </div>}


                                            {!detailuser_date_certification && <Button variant="contained" className="bton_envoyer" onClick={SendAttendeeAttestation}>Delivrer l'attestation de formation
                                            </Button>}
                                        </div>

                                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                            <Button variant="contained" className="bton_fermer" onClick={DownloadAttendeeAttestation}>Imprimer l'attestation de formation
                                            </Button>




                                        </div>

                                    </div>}
                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>


                                </div>


                            </div>

                        </div>}

                        {String(GetAttendee_api) === "false" && <div className="div_row koUpdateData">
                            {GetAttendee_message}
                        </div>}


                    </div>}
                </div>
                <div className="pieddepage">
                    <Footer />
                </div>


            </div>
        </div >
    );
}
export default GestionAdministrative;
