import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';

const Config_Champs_Personnalise = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'field_name', headerName: 'Nom champ', hideable: false, flex: 1 },
        { field: 'field_label', headerName: 'label', hideable: false, flex: 1 },
        { field: 'related_collection', headerName: 'Entité metier', width: 150, hideable: false, },
        {
            field: 'is_displayed', headerName: 'Affichable', width: 150, hide: false, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";

                else
                    return "?";
            },
        },
        {
            field: 'is_public', headerName: 'Champ Public', width: 150, hide: true, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";

                else
                    return "?";
            },
        },
        {
            field: 'field_type', headerName: 'Type', width: 150, hide: false, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "string")
                    return "Alphanum";
                else if (String(params.value) === "float")
                    return "Num";

                else
                    return "?";
            },
        },
        {
            field: 'is_mandatory', headerName: 'Obligatoire', width: 150, hide: false, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";

                else
                    return "?";
            },
        },


        /*{
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Display_Detail_Document(event, cellValues);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },*/
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]
    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const myRef = useRef(null)
    const myRef_head = useRef(null);

    const [selected_id, setselected_id] = useState();

    const [selected_field_name, setselected_field_name] = useState();

    function handleClick_display_personal_field(selected_row_id) {

        setdisplay_personalised_field("1");
        //submenu_detail_employe();

        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var document_id = line._id;
        setselected_id(document_id);

        setselected_field_name(line.field_name);

        //console.log(" ### line.ref_interne  = ", line.ref_interne);
        Get_Given_Specific_Fields(document_id);

        if (document.getElementById('myRef_detail')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef_detail').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });

        }

    }


    const [Get_Given_Specific_Fields_api, setGet_Given_Specific_Fields_api] = useState();
    const [Get_Given_Specific_Fields_message, setGet_Given_Specific_Fields_message] = useState();
    const [Get_Given_Specific_Fields_result, setGet_Given_Specific_Fields_result] = useState();
    function Get_Given_Specific_Fields(local_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_given_specific_field/";


        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Given_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Given_Specific_Fields_api("true");
                setGet_Given_Specific_Fields_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_spec_field = JSON.parse(res.data.message);

                    if (mylocal_spec_field.field_name)
                        setp_detail_field_name(mylocal_spec_field.field_name);

                    if (mylocal_spec_field.field_label)
                        setp_detail_field_label(mylocal_spec_field.field_label);

                    if (mylocal_spec_field.related_collection) {
                        setp_detail_related_collection(mylocal_spec_field.related_collection);
                        setp_detail_related_collection_name(mylocal_spec_field.related_collection_name);

                    }

                    if (mylocal_spec_field.is_displayed) {
                        setp_detail_is_displayed(mylocal_spec_field.is_displayed);
                        if (String(mylocal_spec_field.is_displayed) === "1")
                            setp_detail_is_displayed_label("Oui");
                        else
                            setp_detail_is_displayed_label("Non");
                    }

                    if (mylocal_spec_field.is_public) {
                        setp_detail_is_public(mylocal_spec_field.is_public);
                        if (String(mylocal_spec_field.is_public) === "1")
                            setp_detail_is_public_label("Oui");
                        else
                            setp_detail_is_public_label("Non");
                    }

                    if (mylocal_spec_field.field_type) {
                        setp_detail_field_type(mylocal_spec_field.field_type);
                        if (String(mylocal_spec_field.field_type) === "string")
                            setp_detail_field_type_label("Alphanumérique");
                        else if (String(mylocal_spec_field.field_type) === "float")
                            setp_detail_field_type_label("Numérique");

                    }



                    if (mylocal_spec_field.is_mandatory) {
                        setp_detail_is_mandatory(mylocal_spec_field.is_mandatory);
                        if (String(mylocal_spec_field.is_mandatory) === "1")
                            setp_detail_is_mandatory_label("Oui");
                        else
                            setp_detail_is_mandatory_label("Non");

                    }


                    disable_Partner_personalFields();
                }
                else {
                    alert("Aucune information récuperée");
                }

            }
            else {
                setGet_Given_Specific_Fields_api("false");
                setGet_Given_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Specific_Fields = ', error);
            setGet_Given_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables de l'objet");
            //setmyApimyApiMessage("")
        })
    }



    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };




    function handleClick_delete(event, cellValues) {
        // Recuperation du motif du refus :
        var local_ref_interne = cellValues.row.ref_interne;
        var local_id = cellValues.row._id;


        Delete_Personalise_Field_Data(local_id, local_ref_interne);
    }


    const [display_personalised_field, setdisplay_personalised_field] = React.useState();


    const [Delete_Personalise_Field_Data_api, setDelete_Personalise_Field_Data_api] = useState();
    const [Delete_Personalise_Field_Data_message, setDelete_Personalise_Field_Data_message] = useState();
    const [Delete_Personalise_Field_Data_result, setDelete_Personalise_Field_Data_result] = useState();
    function Delete_Personalise_Field_Data(local_id, local_ref_interne) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_given_specific_field/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Personalise_Field_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Personalise_Field_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Personalise_Field_Data_api("true");
                setDelete_Personalise_Field_Data_result(res.data.message);

                Getall_Partner_Personalised_Fields_No_filter();

                setpersonal_field_data_edit_mode();
                setpersonal_field_data_changed();
                clear_detail_fields();

                setdisplay_personalised_field();

                setSelectionModel([]);

                alert(" Le document a été correctement supprimé.");
                //myRef_head.current.scrollIntoView({ behavior: "smooth" });
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setDelete_Personalise_Field_Data_api("false");
                setDelete_Personalise_Field_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_Personalise_Field_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Personalise_Field_Data_api("false");
            alert(" Impossible de supprimer le document");
        })
    }


    const [Getall_Partner_Personalised_Fields_No_filter_api, setGetall_Partner_Personalised_Fields_No_filter_api] = useState();
    const [Getall_Partner_Personalised_Fields_No_filter_message, setGetall_Partner_Personalised_Fields_No_filter_message] = useState();
    const [Getall_Partner_Personalised_Fields_No_filter_result, setGetall_Partner_Personalised_Fields_No_filter_result] = useState();
    function Getall_Partner_Personalised_Fields_No_filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_list_specific_field_partner_no_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Personalised_Fields_No_filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Personalised_Fields_No_filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Personalised_Fields_No_filter_api("true");
                setGetall_Partner_Personalised_Fields_No_filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Personalised_Fields_No_filter_api("false");
                setGetall_Partner_Personalised_Fields_No_filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Personalised_Fields_No_filter = ', error);
            setGetall_Partner_Personalised_Fields_No_filter_api("false");
            alert(" Impossible de recuperer la liste des champs");
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Partner_Personalised_Fields_with_filter_api, setGetall_Partner_Personalised_Fields_with_filter_api] = useState();
    const [Getall_Partner_Personalised_Fields_with_filter_message, setGetall_Partner_Personalised_Fields_with_filter_message] = useState();
    const [Getall_Partner_Personalised_Fields_with_filter_result, setGetall_Partner_Personalised_Fields_with_filter_result] = useState();
    function Getall_Partner_Personalised_Fields_with_filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_list_specific_field_partner_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Personalised_Fields_with_filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Personalised_Fields_with_filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Personalised_Fields_with_filter_api("true");
                setGetall_Partner_Personalised_Fields_with_filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Personalised_Fields_with_filter_api("false");
                setGetall_Partner_Personalised_Fields_with_filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Personalised_Fields_with_filter = ', error);
            setGetall_Partner_Personalised_Fields_with_filter_api("false");
            alert(" Impossible de recuperer la liste des champs");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Obj_Metier_Eligible_Cham_Spe_api, setGet_Obj_Metier_Eligible_Cham_Spe_api] = useState();
    const [Get_Obj_Metier_Eligible_Cham_Spe_message, setGet_Obj_Metier_Eligible_Cham_Spe_message] = useState();
    const [Get_Obj_Metier_Eligible_Cham_Spe_result, setGet_Obj_Metier_Eligible_Cham_Spe_result] = useState();
    function Get_Obj_Metier_Eligible_Cham_Spe() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Collection_Eligible_Champ_Specific/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Obj_Metier_Eligible_Cham_Spe  res.data.status = " + res.data.status);
                //console.log(" In Get_Obj_Metier_Eligible_Cham_Spe  res.data.message r_class = " + res.data.message);

                setGet_Obj_Metier_Eligible_Cham_Spe_api("true");
                setGet_Obj_Metier_Eligible_Cham_Spe_result(res.data.message);

            }
            else {
                setGet_Obj_Metier_Eligible_Cham_Spe_api("false");
                setGet_Obj_Metier_Eligible_Cham_Spe_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Obj_Metier_Eligible_Cham_Spe = ', error);
            setGet_Obj_Metier_Eligible_Cham_Spe_api("false");
            alert(" Impossible de recuperer la liste des objets métiers éligibles pour les champs spécifiques");
            //setmyApimyApiMessage("")
        })
    }


    const myRef_detail = useRef(null);

    useEffect(() => {
        Get_Obj_Metier_Eligible_Cham_Spe();

        Getall_Partner_Personalised_Fields_No_filter();
        // myRef_head.current.scrollIntoView({ behavior: "smooth" });



        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });


    }, [])


    function clean_all_filters() {
        setp_filtre1();
        setp_filtre1_value();

        setp_filtre2();
        setp_filtre2_value();

        setp_filtre3();
        setp_filtre3_value();

        setp_filtre4();
        setp_filtre4_value();

        /*etdisplay_affectation("");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
        setSelectionModel_affectations([]);
        setSelectionModel([]);
        setsubmenu("");
*/
        Getall_Partner_Personalised_Fields_No_filter();
    }

    function disable_Partner_personalFields() {
        setpersonal_field_data_edit_mode("0");

        if (document.getElementsByName("field_name")[0]) {
            document.getElementsByName("field_name")[0].disabled = true;
            document.getElementsByName("field_name")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_label")[0]) {
            document.getElementsByName("field_label")[0].disabled = true;
            document.getElementsByName("field_label")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("related_collection")[0]) {
            document.getElementsByName("related_collection")[0].disabled = true;
            document.getElementsByName("related_collection")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("field_type")[0]) {
            document.getElementsByName("field_type")[0].disabled = true;
            document.getElementsByName("field_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("is_mandatory")[0]) {
            document.getElementsByName("is_mandatory")[0].disabled = true;
            document.getElementsByName("is_mandatory")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("is_displayed")[0]) {
            document.getElementsByName("is_displayed")[0].disabled = true;
            document.getElementsByName("is_displayed")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("is_public")[0]) {
            document.getElementsByName("is_public")[0].disabled = true;
            document.getElementsByName("is_public")[0].style.backgroundColor = "#ECEFF1";
        }


    }


    function Edit_personal_Data_Fields() {
        Enable_Partner_PersonalFields();
        //Get_Given_Personnalisable_Collection_and_Fields(selected_template_ref_interne);
    }

    function Enable_Partner_PersonalFields() {
        setpersonal_field_data_edit_mode("1");

        if (document.getElementsByName("field_name")[0]) {
            document.getElementsByName("field_name")[0].disabled = false;
            document.getElementsByName("field_name")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_label")[0]) {
            document.getElementsByName("field_label")[0].disabled = false;
            document.getElementsByName("field_label")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("related_collection")[0]) {
            document.getElementsByName("related_collection")[0].disabled = false;
            document.getElementsByName("related_collection")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("field_type")[0]) {
            document.getElementsByName("field_type")[0].disabled = false;
            document.getElementsByName("field_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("is_mandatory")[0]) {
            document.getElementsByName("is_mandatory")[0].disabled = false;
            document.getElementsByName("is_mandatory")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("is_displayed")[0]) {
            document.getElementsByName("is_displayed")[0].disabled = false;
            document.getElementsByName("is_displayed")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("is_public")[0]) {
            document.getElementsByName("is_public")[0].disabled = false;
            document.getElementsByName("is_public")[0].style.backgroundColor = "#FFFFFF";
        }


    }



    function Annule_edit_specific_Fields() {
        clear_detail_fields();
        setpersonal_field_data_changed();
        setpersonal_field_data_edit_mode();
        Get_Given_Specific_Fields(selected_id);
    }

    const [personal_field_data_changed, setpersonal_field_data_changed] = useState("");
    const [personal_field_data_edit_mode, setpersonal_field_data_edit_mode] = useState("");



    const [add_one_personal_field, setadd_one_personal_field] = React.useState();
    function submenu_add_one_personal_field() {
        setSelectionModel([]);
        clear_one_detail_fields();
        setpersonal_field_data_edit_mode();
        setpersonal_field_data_changed();
        clear_detail_fields();
        setdisplay_personalised_field();


        //Get_List_Personnalisable_Default_Document();
        setdisplay_personalised_field();
        setadd_one_personal_field("1");


    }

    const [p_detail_field_name, setp_detail_field_name] = useState("");
    const [p_detail_field_label, setp_detail_field_label] = useState("");
    const [p_detail_related_collection, setp_detail_related_collection] = useState("");
    const [p_detail_related_collection_name, setp_detail_related_collection_name] = useState("");
    const [p_detail_is_displayed, setp_detail_is_displayed] = useState("");
    const [p_detail_is_displayed_label, setp_detail_is_displayed_label] = useState("");

    const [p_detail_is_public, setp_detail_is_public] = useState("");
    const [p_detail_is_public_label, setp_detail_is_public_label] = useState("");


    const [p_detail_field_type, setp_detail_field_type] = useState("");
    const [p_detail_field_type_label, setp_detail_field_type_label] = useState("");
    const [p_detail_is_mandatory, setp_detail_is_mandatory] = useState("");
    const [p_detail_is_mandatory_label, setp_detail_is_mandatory_label] = useState("");


    const [p_one_detail_field_name, setp_one_detail_field_name] = useState("");
    const [p_one_detail_field_label, setp_one_detail_field_label] = useState("");
    const [p_one_detail_related_collection, setp_one_detail_related_collection] = useState("");
    const [p_one_detail_is_displayed, setp_one_detail_is_displayed] = useState("");
    const [p_one_detail_is_public, setp_one_detail_is_public] = useState("");
    const [p_one_detail_field_type, setp_one_detail_field_type] = useState("");
    const [p_one_detail_is_mandatory, setp_one_detail_is_mandatory] = useState("");

    function clear_detail_fields() {
        setp_detail_field_name("");
        setp_detail_field_label();
        setp_detail_related_collection("");
        setp_detail_related_collection_name("");
        setp_detail_is_displayed("");
        setp_detail_is_public("");
        setp_detail_field_type("");
        setp_detail_field_type_label("");
        setp_detail_is_mandatory("");
        setp_detail_is_mandatory_label("");
        setp_detail_is_displayed_label("");
        setp_detail_is_public_label("");


    }


    function clear_one_detail_fields() {
        setp_one_detail_field_name("");
        setp_one_detail_field_label();
        setp_one_detail_related_collection("");
        setp_one_detail_is_displayed("");
        setp_one_detail_is_public("");
        setp_one_detail_field_type("");
        setp_one_detail_is_mandatory("");
    }

    function Fermer_specific_Fields() {
        setdisplay_personalised_field();
        clear_detail_fields();
        clear_one_detail_fields();
        setSelectionModel([]);

    }



    const [Update_One_Specific_field_Data_api, setUpdate_One_Specific_field_Data_api] = useState();
    const [Update_One_Specific_field_Data_message, setUpdate_One_Specific_field_Data_message] = useState();
    const [Update_One_Specific_field_Data_result, setUpdate_One_Specific_field_Data_result] = useState();
    function Update_One_Specific_field_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_id);

        if (p_detail_field_name) {
            form.append("field_name", p_detail_field_name);
        }
        else {
            alert(" Vous devez definir le technique du champ. Il doit commencer impérativement par 'my_'");
            return;
        }
        if (!p_detail_field_name.startsWith('my_')) {
            alert(" Le nom technique du champ doit commencer impérativement par 'my_'");
            return;
        }
        if (String(p_detail_field_name).length < 6) {
            alert(" Le nom technique du champ doit au moins 6 caractères");
            return;
        }


        var tmp_val = String(p_detail_field_name).replaceAll("my_", "");
        if (!tmp_val.match(/^[0-9a-z]+$/)) {
            alert(" Sur le nom technique, les information apres le 'my_' doit être alphanumerique. Vous avez saisi : " + String(tmp_val));
            return;
        }


        if (p_detail_field_label)
            form.append("field_label", p_detail_field_label);
        else {
            alert(" Vous devez definir le label du champ ");
            return;
        }
        if (String(p_detail_field_label).length < 3) {
            alert(" Le label du champ doit faire au moins 3 caractères");
            return;
        }


        if (p_detail_related_collection)
            form.append("related_collection", p_detail_related_collection);
        else {
            alert(" Vous devez definir l'entité");
            return;
        }


        if (p_detail_is_displayed)
            form.append("is_displayed", p_detail_is_displayed);
        else {
            alert(" Vous devez definir si le champ est affichable");
            return;
        }

        if (p_detail_is_public)
            form.append("is_public", p_detail_is_public);
        else {
            form.append("is_public", "0");
        }


        if (p_detail_field_type)
            form.append("field_type", p_detail_field_type);
        else {
            alert(" Vous devez definir le type du champ");
            return;
        }


        if (p_detail_is_mandatory)
            form.append("is_mandatory", p_detail_is_mandatory);
        else {
            alert(" Vous devez definir si le champ est obligatoire");
            return;
        }


        //console.log(" Update_One_Specific_field_Data affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_specific_field/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Specific_field_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Specific_field_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_Specific_field_Data_api("true");
                setUpdate_One_Specific_field_Data_result(res.data.message);
                if (myRef.current) {
                    myRef.current.scrollIntoView({ behavior: "smooth" });
                }

                disable_Partner_personalFields();
                setpersonal_field_data_edit_mode("0");
                setpersonal_field_data_changed("0");
                Getall_Partner_Personalised_Fields_No_filter();
                Get_Given_Specific_Fields(selected_id);
                alert(res.data.message);

            }
            else {
                setUpdate_One_Specific_field_Data_api("false");
                setUpdate_One_Specific_field_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Update_One_Specific_field_Data = ' + error);
            setUpdate_One_Specific_field_Data_api("false");
            alert(" Impossible de mettre à jour le document");

        })
    }


    const [Add_One_Specific_field_Data_api, setAdd_One_Specific_field_Data_api] = useState();
    const [Add_One_Specific_field_Data_message, setAdd_One_Specific_field_Data_message] = useState();
    const [Add_One_Specific_field_Data_result, setAdd_One_Specific_field_Data_result] = useState();
    function Add_One_Specific_field_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        if (p_one_detail_field_name) {
            form.append("field_name", p_one_detail_field_name);
        }
        else {
            alert(" Vous devez definir le technique du champ. Il doit commencer impérativement par 'my_'");
            return;
        }
        if (!p_one_detail_field_name.startsWith('my_')) {
            alert(" Le nom technique du champ doit commencer impérativement par 'my_'");
            return;
        }
        if (String(p_one_detail_field_name).length < 6) {
            alert(" Le nom technique du champ doit au moins 6 caractères");
            return;
        }


        if (p_one_detail_field_label)
            form.append("field_label", p_one_detail_field_label);
        else {
            alert(" Vous devez definir le label du champ ");
            return;
        }
        if (String(p_one_detail_field_label).length < 3) {
            alert(" Le label du champ doit faire au moins 3 caractères");
            return;
        }


        if (p_one_detail_related_collection)
            form.append("related_collection", p_one_detail_related_collection);
        else {
            alert(" Vous devez definir l'entité");
            return;
        }


        if (p_one_detail_is_displayed)
            form.append("is_displayed", p_one_detail_is_displayed);
        else {
            alert(" Vous devez definir si le champ est affichable");
            return;
        }

        if (p_one_detail_is_public)
            form.append("is_public", p_one_detail_is_public);
        else {
            form.append("is_public", "0");
        }


        if (p_one_detail_field_type)
            form.append("field_type", p_one_detail_field_type);
        else {
            alert(" Vous devez definir le type du champ");
            return;
        }


        if (p_one_detail_is_mandatory)
            form.append("is_mandatory", p_one_detail_is_mandatory);
        else {
            alert(" Vous devez definir si le champ est obligatoire");
            return;
        }


        //console.log(" Add_One_Specific_field_Data affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_specific_field/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Specific_field_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Specific_field_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_One_Specific_field_Data_api("true");
                setAdd_One_Specific_field_Data_result(res.data.message);


                disable_Partner_personalFields();
                setpersonal_field_data_edit_mode("0");
                setpersonal_field_data_changed("0");
                Getall_Partner_Personalised_Fields_No_filter();
                clear_one_detail_fields();
                setdisplay_personalised_field();
                setadd_one_personal_field();
                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_One_Specific_field_Data_api("false");
                setAdd_One_Specific_field_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Add_One_Specific_field_Data = ' + error);
            setAdd_One_Specific_field_Data_api("false");
            alert(" Impossible d'ajouter le document");

        })
    }



    return (
        <div className="config_champs_personnalise">
            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            <h3> Configuration documents </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="object_name" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Entité métier &nbsp;</MenuItem>
                            <MenuItem value="field_name" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom du champ  &nbsp;</MenuItem>


                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value);

                                }}
                            >
                                <MenuItem value="object_name" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Entité métier &nbsp;</MenuItem>
                                <MenuItem value="field_name" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom du champ  &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }





                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Partner_Personalised_Fields_with_filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row" style={{paddingRight: '10px' }}>
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },

                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        if (newSelectionModel.length === 1)
                                            handleClick_display_personal_field(newSelectionModel);
                                        if (newSelectionModel.length !== 1) {
                                            setpersonal_field_data_edit_mode();
                                            setpersonal_field_data_changed();
                                            clear_detail_fields();
                                            setsubmenu();
                                            setdisplay_personalised_field();

                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            field_name: JSON.parse(item).field_name,
                                            field_label: JSON.parse(item).field_label,
                                            related_collection: JSON.parse(item).related_collection,
                                            is_displayed: JSON.parse(item).is_displayed,
                                            field_type: JSON.parse(item).field_type,
                                            is_mandatory: JSON.parse(item).is_mandatory,
                                            is_public: JSON.parse(item).is_public,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "statut"
                                        if (params.field === "status" && String(params.value) == "0") {
                                            return 'cell--statut--preinscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "1") {
                                            return 'cell--statut--inscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "-1") {
                                            return 'cell--statut--annule';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        <div className="div_row_gauche" style={{ "border": "None" }}>
                            <Button variant="outlined" onClick={submenu_add_one_personal_field}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 champ &nbsp;
                                <img src={add_plus} alt="ajout session" className="icon_plus" />
                            </Button>
                        </div>
                    </div>

                </div>
            </div>

            {String(display_personalised_field) === "1" && String(add_one_personal_field) !== "1" && <div className="div_row">
                <h3> Détail champ personnalisé <i> {selected_field_name} </i></h3>

                <div className="session_data" >


                    {String(personal_field_data_edit_mode) !== "1" &&

                        <div className="session_caract">
                            Entité métier <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="related_collection"
                                id="related_collection"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_related_collection_name}
                            />

                        </div>}

                    {String(personal_field_data_edit_mode) === "1" && <div className="session_caract"> Entité métier <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="related_collection"
                            id="related_collection"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_related_collection}
                            onChange={(e) => {
                                setp_detail_related_collection(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            {Get_Obj_Metier_Eligible_Cham_Spe_result &&
                                Get_Obj_Metier_Eligible_Cham_Spe_result.map((mycollection) => (
                                    <MenuItem key={JSON.parse(mycollection).objet_metier}
                                        value={JSON.parse(mycollection).objet_metier}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(mycollection).nom}&nbsp;&nbsp;

                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>}


                    <div className="session_caract">
                        <Tooltip className="tooltip_css" id="my-related_collection" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-related_collection" data-tooltip-html="Le champ commence par 'my_' et finit par une chaine alphanumerique de 3 à 50 caractères">
                            Nom Technique (commence par <i>my_</i>)<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="field_name"
                                id="field_name"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_field_name}
                                onChange={(e) => {
                                    setp_detail_field_name(e.target.value);
                                    setpersonal_field_data_changed("1");
                                }}
                            />
                        </a>
                    </div>


                    <div className="session_caract"> Label<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="field_label"
                            id="field_label"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_field_label}
                            onChange={(e) => {
                                setp_detail_field_label(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        />
                    </div>

                    {String(personal_field_data_edit_mode) !== "1" && <div className="session_caract"> Type <i> (numérique / Alphanumérique)</i><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="field_type"
                            id="field_type"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_field_type_label}
                        />
                    </div>}

                    {String(personal_field_data_edit_mode) === "1" && <div className="session_caract"> Type <i> (numérique / Alphanumérique)</i><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="field_type"
                            id="field_type"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_field_type}
                            onChange={(e) => {
                                setp_detail_field_type(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="float" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>numérique &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="string" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Alphanumérique &nbsp;</MenuItem>

                        </TextField>
                    </div>}


                    {String(personal_field_data_edit_mode) !== "1" && <div className="session_caract"> Obligatoire <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="is_mandatory"
                            id="is_mandatory"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_is_mandatory_label}
                        />
                    </div>}

                    {String(personal_field_data_edit_mode) === "1" && <div className="session_caract"> Obligatoire <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="is_mandatory"
                            id="is_mandatory"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_is_mandatory}
                            onChange={(e) => {
                                setp_detail_is_mandatory(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>

                        </TextField>
                    </div>}

                    {String(personal_field_data_edit_mode) !== "1" && <div className="session_caract"> Affichable <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="is_displayed"
                            id="is_displayed"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_is_displayed_label}
                        />
                    </div>}

                    {String(personal_field_data_edit_mode) !== "1" && <div className="session_caract">
                        <Tooltip className="tooltip_css" id="my-related_collection_02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-related_collection_02" data-tooltip-html=" Si, oui, le champ est visible par tous les utilisateurs">
                            Champ public<br />
                        </a>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="is_public"
                            id="is_public"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_is_public_label}
                        />

                    </div>}

                    {String(personal_field_data_edit_mode) === "1" && <div className="session_caract"> Affichable  <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="is_displayed"
                            id="is_displayed"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_is_displayed}
                            onChange={(e) => {
                                setp_detail_is_displayed(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>
                        </TextField>
                    </div>}

                    {String(personal_field_data_edit_mode) === "1" && <div className="session_caract">
                        <Tooltip className="tooltip_css" id="my-related_collection_02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-related_collection_02" data-tooltip-html=" Si, oui, le champ est visible par tous les utilisateurs">
                            Champ public <br />
                        </a>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="is_public"
                            id="is_public"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_is_public}
                            onChange={(e) => {
                                setp_detail_is_public(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>
                        </TextField>
                    </div>}


                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    {String(personal_field_data_changed) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}

                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(personal_field_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Update_One_Specific_field_Data}>Enregistrer les modifications
                            </Button>

                        </div>}

                        {String(personal_field_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_edit_specific_Fields}>Annuler
                            </Button>
                        </div>}
                    </div>
                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(personal_field_data_edit_mode) !== "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_annule" onClick={Fermer_specific_Fields}>Fermer
                            </Button>

                        </div>}


                        {String(personal_field_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                            <Button variant="contained" className="bton_edit" onClick={Edit_personal_Data_Fields}>Editer
                            </Button>

                        </div>}
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>

            </div>}


            <div className="div_row" ref={myRef_detail} id="myRef_detail"> &nbsp; </div>
            {String(display_personalised_field) !== "1" && String(add_one_personal_field) === "1" && <div className="div_row">
                <h3> Ajout d'un nouveau champ spécifique</h3>

                <div className="session_data" >

                    <div className="session_caract"> Entité métier <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_related_collection"
                            id="one_related_collection"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_related_collection}
                            onChange={(e) => {
                                setp_one_detail_related_collection(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            {Get_Obj_Metier_Eligible_Cham_Spe_result &&
                                Get_Obj_Metier_Eligible_Cham_Spe_result.map((mycollection) => (
                                    <MenuItem key={JSON.parse(mycollection).objet_metier}
                                        value={JSON.parse(mycollection).objet_metier}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(mycollection).nom}&nbsp;&nbsp;

                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>


                    <div className="session_caract">
                        <Tooltip className="tooltip_css" id="my-related_collection" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-related_collection" data-tooltip-html="Le champ commence par 'my_' et finit par une chaine alphanumerique de 3 à 50 caractères">
                            Nom Technique  (commence par <i>my_</i>)<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="one_field_name"
                                id="one_one_field_name"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_one_detail_field_name}
                                onChange={(e) => {
                                    setp_one_detail_field_name(e.target.value);
                                    setpersonal_field_data_changed("1");
                                }}
                            />
                        </a>
                    </div>


                    <div className="session_caract"> Label<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_field_label"
                            id="one_field_label"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_field_label}
                            onChange={(e) => {
                                setp_one_detail_field_label(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        />
                    </div>


                    <div className="session_caract"> Type <i> (numérique / Alphanumérique)</i><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_field_type"
                            id="one_field_type"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_field_type}
                            onChange={(e) => {
                                setp_one_detail_field_type(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="float" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>numérique &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="string" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Alphanumérique &nbsp;</MenuItem>

                        </TextField>
                    </div>


                    <div className="session_caract"> Obligatoire <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_is_mandatory"
                            id="one_is_mandatory"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_is_mandatory}
                            onChange={(e) => {
                                setp_one_detail_is_mandatory(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>

                        </TextField>
                    </div>



                    <div className="session_caract"> Affichable <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_is_displayed"
                            id="one_is_displayed"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_is_displayed}
                            onChange={(e) => {
                                setp_one_detail_is_displayed(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>
                        </TextField>
                    </div>

                    <div className="session_caract">
                        <Tooltip className="tooltip_css" id="my-related_collection_02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-related_collection_02" data-tooltip-html=" Si, oui, le champ est visible par tous les utilisateurs">
                            Champ public <br />
                        </a>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_is_public"
                            id="one_is_public"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            select
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_is_public}
                            onChange={(e) => {
                                setp_one_detail_is_public(e.target.value);
                                setpersonal_field_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>
                        </TextField>
                    </div>




                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>


                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_One_Specific_field_Data}>Enregistrer les modifications
                            </Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_edit_specific_Fields}>Annuler
                            </Button>
                        </div>
                    </div>


                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>
            </div>}

        </div>


    );
}

export default Config_Champs_Personnalise;
