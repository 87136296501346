import React, { useState, useEffect } from 'react';
import { MdCancel } from "react-icons/md";
import { AiOutlineEdit } from "react-icons/ai";
import TrainingForm from "./AddClassManual"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from 'react-select'
import Autocomplete from '@mui/material/Autocomplete';
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import excel_icone from "../mysy_img/excel_icone.png";
import Popup from 'reactjs-popup';
import fileDownload from 'js-file-download'
import 'reactjs-popup/dist/index.css';
import { AiFillCloseCircle, } from "react-icons/ai";
import parse from 'html-react-parser'
import { BsEye, BsFileText } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CiCoffeeBean, CiDesktop, CiFileOff } from "react-icons/ci";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import { LuScreenShare, LuScreenShareOff } from "react-icons/lu";
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import { CiTrash } from "react-icons/ci";
import add_plus from "../mysy_img/plus.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ToggleSwitch from "./ToggleSwitch";
import SendIcon from '@mui/icons-material/Send';
import { GrDocumentCsv } from "react-icons/gr";

import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

const DistplayPartnerTraningsPage = (props) => {

    const history = useHistory();
    const [myApiResponse, setmyApiResponse] = useState("false");
    const filters = [
        { value: 'external_code', label: 'Code Formation' },
        { value: 'title', label: 'Titre' },
        { value: 'certif', label: 'Certification' }
    ]
    const [selectedfilt1, setselectedfilt1] = useState("");
    const [selectedfilt2, setselectedfilt2] = useState("");
    const [selectedfilt3, setselectedfilt3] = useState("");
    const [reftrainingtoupdate, setreftrainingtoupdate] = useState();
    const [externalcodetraining_toupdate, setexternalcodetraining_toupdate] = useState();
    const [updatetraining, setupdatetraining] = useState(false);
    const [createtraining, setcreatetraining] = useState(false);


    const changeHandler2 = (event) => {
        Closecreatetraining();
        setapiexcelimportmessage();
        setformation_file_name();
        sethandleSubmission_api();
        hiddenFileInput_formation.current.click();
    };

    const [formation_file_name, setformation_file_name] = useState();

    const hiddenFileInput_formation = React.useRef(null);

    const [excelimportmessage, setexcelimportmessage] = useState("");
    const [apiexcelimportmessage, setapiexcelimportmessage] = useState("");

    const [handleSubmission_api, sethandleSubmission_api] = useState();
    const [handleSubmission_result, sethandleSubmission_result] = useState();
    const [handleSubmission_message, sethandleSubmission_message] = useState();

    const handleSubmission = event => {

        const formData = new FormData();

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setformation_file_name(event.target.files[0].name);

        //console.log(" import du ficier ", event.target.files[0].name);


        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');


        formData.append("token", stored_cookie);

        //console.log("token = " + stored_cookie); theme_name
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/add_class_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);
                setexcelimportmessage(result['message']);
                setmyApiResponse("true");
                setapiexcelimportmessage(result['status']);
                setLoading(false);
                if (String(result['status']) === String("true")) {

                    sethandleSubmission_api("true");
                    GetAllClass_new();
                    var local_message = result['message']; // + " Vous allez être redirigé vers la liste des formations"
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });

                    alert(local_message);
                    //history.push("/Partner/affichage");
                    //window.location.reload();
                    //return;

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    sethandleSubmission_api("false");
                    sethandleSubmission_message(result['message']);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    alert(result['message']);
                    console.log(" 000000000000000&")


                }
                //alert("result['status'] " + result['status']);
            })
            .catch((error) => {
                setLoading(false);
                setmyApiResponse("false");
                setapiexcelimportmessage("-1");
                sethandleSubmission_api("false");
                alert(" Impossible d'importer/mettre à jour les formations");
            });
    };


    function handleClick(event, cellValues) {

        var url = process.env.REACT_APP_API_URL + "myclass/api/check_partner_token_validity_v2/";

        var formData = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(' isValidToken ==> Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) !== String("true")) {
                    alert("La session de connexion n'est pas valide");
                    history.push("/Connexion");
                }

                if (String(result['status']) === String("true")) {
                    Edittraining(event, cellValues);
                    //console.log(cellValues);
                }


            })
            .catch((error) => {
                console.error('Error:', error);


            });


    }

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rowss, setRows] = useState([]);
    const [tableData, setTableData] = useState([])

    const [refreshgrid, setrefreshgrid] = useState("")
    function GetAllClass_WOfilter(event) {
        var form = new FormData();

        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

        //alert(" laaa");
        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('GetAllClass_WOfilter : Success:', data['message'], "data['status'] = ", data['status']);
                setTableData(data['message']);
                setRows(data['message']);

                //alert(" GetAllClass_WOfilter OKK "+refreshgrid);
                setrefreshgrid("1");


            }).catch((error) => {
                console.error('GetAllClass_WOfilter Error:', error);
                setmyApiResponse("false");
            });

    }

    function GetAllClass_new(event) {

        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";

        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, filtre3_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/find_partner_class_like/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setTableData(data['message']);
                setRows(data['message']);
                if (String(data['status']) === String("false")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setmyApiResponse("False");

                }
                else {
                    setmyApiResponse("True");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("false");
            });

    }

    function GetAllClass_new_no_filter(event) {

        var form = new FormData();


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/find_partner_class_like/";
        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setTableData(data['message']);
                setRows(data['message']);
                if (String(data['status']) === String("false")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setmyApiResponse("False");

                }
                else {
                    setmyApiResponse("True");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("false");
            });

    }

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                {expanded ? value : value.slice(0, 50)}&nbsp;
                {value.length > 50 && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                        type="button"
                        component="button"
                        sx={{ fontSize: 'inherit' }}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                    </Link>
                )}
            </div>
        );
    }


    const [ismobilephone_columns_hide, setismobilephone_columns_hide] = useState(false);

    const [datagrid_columns_size_model1, setdatagrid_columns_size_model1] = useState(200);
    const [datagrid_columns_size_model2, setdatagrid_columns_size_model2] = useState(120);

    const columns = [
        { field: 'id', headerName: 'id', hide: true, maxWidth: 1, },
        { field: '_id', headerName: '_id', hide: true, maxWidth: 1, },
        { field: 'lms_class_code', headerName: 'lms_class_code', hide: true, Width: 0, },
        { field: 'duration', headerName: 'duration', hide: true, Width: 0, },
        { field: 'presentiel', headerName: 'Présentiel', hide: true, Width: 0, },
        { field: 'distanciel', headerName: 'Distanciel', hide: true, Width: 0, },
        { field: 'cible', headerName: 'Cible', hide: true, Width: 0, },
        { field: 'duration_unit', headerName: 'duration_unit', hide: true, Width: 0,editable: false,  },
        { field: 'duration_in_hour', headerName: 'duration_in_hour', hide: true, Width: 0,editable: false,  },
        { field: 'currency', headerName: 'currency', hide: true, Width: 0, editable: false, },


        { field: 'zone_diffusion', headerName: 'zone_diffusion', hide: true, maxWidth: 1, editable: false, },
        { field: 'internal_url', headerName: 'internal_url', hide: true, editable: false,  },
        { field: 'external_code', headerName: 'Code Formation', hideable: false, minWidth: 150, flex: 1, maxWidth: 250, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'title', headerName: 'Titre', flex: 1, hideable: false, minWidth: 250, flex: 1, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'domain', headerName: 'Domaine', minWidth: 50, flex: 1, maxWidth: 150, hideable: true, editable: false, },
        { field: 'metier', headerName: 'Métier', minWidth: 50, flex: 1, maxWidth: 150, hideable: true, hide: true, editable: false,  },
        { field: 'nb_session_formation', headerName: 'Nb Session', minWidth: 50, flex: 1, maxWidth: 100, hideable: true, hide: false , editable: false, align: "center",},
        { field: 'url', headerName: 'lien', minWidth: 20, hideable: true, hide: true, editable: false,  },
        { field: 'description', headerName: 'Description', hide: true, resizable: true, minWidth: 250, maxWidth: 300, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        { field: 'price', headerName: 'Prix', minWidth: 50, flex: 1, maxWidth: 100, align: "center", resizable: true, hide: ismobilephone_columns_hide, editable: false,  },
        {
            field: 'published', headerName: 'Publié', minWidth: 50, flex: 1, maxWidth: 100, align: "center", resizable: true, editable: false, 
            valueFormatter: (params) => { 
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";
                else
                    return "?";
            },
        },
        {
            field: "management", headerName: 'Gestion', minWidth: 50, flex: 1, maxWidth: 100, 
            renderCell: (cellValues) => {
                return (

                    <Button style={{ "marginLeft": "auto", "marginRight": "auto", "cursor": "pointer" }}

                        onClick={(event) => {
                            handleClickManagement(event, cellValues);
                        }}
                    >
                        <BsFileText />

                    </Button>

                );
            }
        },
        {
            field: "visual", headerName: 'Visualiser', minWidth: 50, flex: 1, maxWidth: 100,
            renderCell: (cellValues) => {
                return (

                    <Button style={{ "marginLeft": "auto", "marginRight": "auto", "cursor": "pointer" }}

                        onClick={(event) => {
                            Visualiser(event, cellValues);
                        }}
                    >
                        <BsEye />

                    </Button>

                );
            }
        },
        {
            field: "Print", headerName: 'Editer', minWidth: 50, flex: 1, maxWidth: 100,
            renderCell: (cellValues) => {
                return (

                    <Button style={{ "marginLeft": "auto", "marginRight": "auto", "cursor": "pointer" }}

                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}
                    >
                        <AiOutlineEdit />

                    </Button>

                );
            }
        },

        {
            field: "push_to_lms", headerName: 'E-learning Creation', minWidth: 100, flex: 1, maxWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <nav style={{ "textAlign": "center" }}>
                        <Popup
                            trigger={
                                <Button style={{ "marginLeft": "auto", "marginRight": "auto", "cursor": "pointer", "textAlign": "center" }}

                                    onClick={(event) => {
                                        handlepush_to_lms(event, cellValues);
                                    }}
                                >

                                    {cellValues.row.lms_class_code && String(cellValues.row.lms_class_code).length > 2 && <CiFileOff />}
                                    {cellValues.row.lms_class_code && String(cellValues.row.lms_class_code).length <= 2 && <CiDesktop />}
                                    {!cellValues.row.lms_class_code && <CiDesktop />}

                                </Button>}

                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        /!\ Important : Confirmer la publication de la formation dans l'eapce E-Learning.
                                        Vous allez écraser d'eventuelles informations déjà présente dans le e-Learning, pour cette formation.
                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                handlepush_to_lms(event, cellValues);
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    //console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </nav>

                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer', minWidth: 50, flex: 1, maxWidth: 100,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la formation sera <i><font color="red"> définitivement supprimée</font></i>. <br />
                                    Avant, assurez-vous de n'avoir aucune session rattachée à la formation.

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    function Visualiser(event, cellValues) {


        var url = process.env.REACT_APP_API_URL + "myclass/api/check_partner_token_validity_v2/";

        var formData = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(' isValidToken ==> Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) !== String("true")) {
                    alert("La session de connexion n'est pas valide");
                    history.push("/Connexion");
                }
                if (String(result['status']) === String("true")) {
                    // On ne visualise que les formations publiées
                    if (cellValues.row.published !== "1") {
                        alert(" Vous devez publier la formation pour la visualiser ");
                        return;
                    }
                    window.open(
                        "/Display-Detail-formation/" + cellValues.row.internal_url,
                        '_blank'
                    );
                }


            })
            .catch((error) => {
                console.error('Error:', error);


            });

    }




    function handleClickManagement(event, cellValues) {

        // Verification de la validité du token avant action.
        var url = process.env.REACT_APP_API_URL + "myclass/api/check_partner_token_validity_v2/";

        var formData = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(' isValidToken ==> Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) !== String("true")) {
                    alert("La session de connexion n'est pas valide");
                    history.push("/Connexion");
                }
                else if (String(result['status']) === String("true")) {
                    window.open(
                        "/GestionAdministrative/" + cellValues.row.internal_url,
                        '_blank'
                    );
                }


            })
            .catch((error) => {
                console.error('Error:', error);


            });

        // Fin verification


    }


    function Edittraining(event, cellValues) {
        setreftrainingtoupdate(cellValues.row.internal_url);
        setexternalcodetraining_toupdate(cellValues.row.external_code);
        setupdatetraining(true);
    }




    function selectedFilter1(event) {
        setselectedfilt1(event.value);
    }

    function selectedFilter2(event) {
        setselectedfilt2(event.value);
    }

    function selectedFilter3(event) {
        setselectedfilt3(event.value);
    }

    function CancelFiltre2(event) {
        setselectedfilt2("");
    }

    function CancelFiltre3(event) {
        setselectedfilt3("");
    }

    function closeTrainingForm() {
        setupdatetraining(false);
    }

    function Closecreatetraining() {
        setcreatetraining(false);
    }

    function Createtraining() {
        setcreatetraining(true);
    }

    const [selectedtraining, setselectedtraining] = useState([])

    function GetSelectedRowsTraining() {
        ///alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length);
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);
        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss[myid]);
            tab_tmp.push(line.internal_url);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining(tab_tmp);
        return tab_tmp;

    }

    function GetSelectedRowsTraining_id_values() {
        ///alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length);
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);
        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss[myid]);
            tab_tmp.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining(tab_tmp);
        return tab_tmp;

    }


    // Fonction de verification de la validité d'un token.
    function isValidToken() {

        var url = process.env.REACT_APP_API_URL + "myclass/api/check_partner_token_validity_v2/";

        var formData = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log(' isValidToken ==> Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) !== String("true")) {
                    alert("La session de connexion n'est pas valide");
                    history.push("/Connexion");
                }


            })
            .catch((error) => {
                console.error('Error:', error);


            });

    }



    function Unpublish_class(tab_class) {

        var url = process.env.REACT_APP_API_URL + "myclass/api/unpublish_class/";


        for (var i = 0; i < tab_class.length; i++) {
            //console.log(" position = "+i+"  val = "+tab_class[i]);
            //alert(" début desactivation de la formation " + tab_class[i]);
            var formData = new FormData();
            const stored_cookie = getCookie('tokenmysypart');
            formData.append("token", stored_cookie);
            formData.append("internal_url", tab_class[i]);
            fetch(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                    if (String(result['status']) === String("true")) {
                        //alert(" OK pour la formation :" + tab_class[i]);
                    }

                })
                .catch((error) => {
                    console.error('Error:', error);
                    //alert(" KOO pour la formation :" + tab_class[i]);

                });

        }
    }

    function Publish_class(tab_class) {

        var url = process.env.REACT_APP_API_URL + "myclass/api/pusblish_class/";

        for (var i = 0; i < tab_class.length; i++) {

            //alert(" début desactivation de la formation " + tab_class[i]);
            //console.log(" NB = "+i+"  id = "+tab_class[i]);
            var formData = new FormData();
            const stored_cookie = getCookie('tokenmysypart');
            formData.append("token", stored_cookie);
            formData.append("internal_url", tab_class[i]);
            fetch(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                    if (String(result['status']) === String("true")) {
                        //alert(" OK pour la formation :" + tab_class[i]);
                    }

                })
                .catch((error) => {
                    console.error('Error:', error);
                    //alert(" KOO pour la formation :" + tab_class[i]);

                });

        }
    }

    function Disable_class(tab_class) {

        var url = process.env.REACT_APP_API_URL + "myclass/api/disable_class/";

        for (var i = 0; i < tab_class.length; i++) {

            //alert(" début desactivation de la formation " + tab_class[i]);
            var formData = new FormData();
            const stored_cookie = getCookie('tokenmysypart');
            formData.append("token", stored_cookie);
            formData.append("internal_url", tab_class[i]);
            fetch(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                    if (String(result['status']) === String("true")) {
                        //alert(" OK pour la formation :" + tab_class[i]);
                    }

                })
                .catch((error) => {
                    console.error('Error:', error);
                    //alert(" KOO pour la formation :" + tab_class[i]);

                });

        }
    }


    function Duplicate_class(tab_class) {

        var url = process.env.REACT_APP_API_URL + "myclass/api/Duplicate_Class_from_internal_url/";

        setLoading(true);
        for (var i = 0; i < tab_class.length; i++) {

            //alert(" début desactivation de la formation " + tab_class[i]);
            var formData = new FormData();
            const stored_cookie = getCookie('tokenmysypart');
            formData.append("token", stored_cookie);
            formData.append("internal_url", tab_class[i]);
            fetch(
                url,
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {
                    //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                    if (String(result['status']) === String("true")) {
                        alert(String(result['message']))
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error:', error);
                    //alert(" KOO pour la formation :" + tab_class[i]);

                });

        }
        setLoading(false);
    }



    function Export_class(tab_class) {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_formation.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_Class_To_Excel_From_from_List_Id/" + stored_cookie + "/" + tab_class;


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les formations (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les formations ")

            });


        setLoading(false);
    }


    function ActionMass(e) {

        // On vide le datagrid pour faire un refresj
        setRows([]);
        //return;

        var actionenmasse = ""
        if (document.getElementById("actionmass")) {
            actionenmasse = document.getElementById("actionmass").value;
        }

        var liste_formation = GetSelectedRowsTraining();
        var liste_formation_ids_values = GetSelectedRowsTraining_id_values();



        if (String(actionenmasse) === String("depublier")) {

            Unpublish_class(liste_formation);
            GetAllClass_WOfilter();
        }

        else if (String(actionenmasse) === String("publier")) {
            Publish_class(liste_formation);
            GetAllClass_WOfilter();
        }

        else if (String(actionenmasse) === String("supprimer")) {
            Disable_class(liste_formation_ids_values);
            GetAllClass_WOfilter();
        }

        else if (String(actionenmasse) === String("dupliquer")) {
            Duplicate_class(liste_formation_ids_values);
            GetAllClass_WOfilter();
        }

        else if (String(actionenmasse) === String("exporter")) {
            Export_class(liste_formation_ids_values);
            GetAllClass_WOfilter();
        }

    }

    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [ismobilephone, setismobilephone] = useState();
    useEffect(() => {
        Get_Partner_Object_Specific_Fields("myclass");

        //GetAllClass();
        GetAllClass_new();


        if (props.class_internal_url && props.class_external_code &&
            String(props.class_internal_url).length > 3 && String(props.class_external_code).length > 3) {

            //console.log(" ## props.class_internal_url  = ", props.class_internal_url);
            //console.log(" ## props.class_external_code  = ", props.class_external_code);
            setLoading(true);
            setreftrainingtoupdate(props.class_internal_url);
            setexternalcodetraining_toupdate(props.class_external_code);
            setupdatetraining(true);

            setLoading(false);
        } else {

            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });

            let windowWidth = window.innerWidth;
            if (windowWidth < 1001) {
                setismobilephone_columns_hide(true);
                setdatagrid_columns_size_model2(10);
            }
        }
    }, []);

    function Clearselectedfilt1() {
        setselectedfilt1("");
    }





    function handleClick_delete(event, cellValues) {

        var local_class_id = cellValues.row._id;

        Delete_Class(local_class_id);
    }


    const [Delete_Class_api, setDelete_Class_api] = useState();
    const [Delete_Class_message, setDelete_Class_message] = useState();
    const [Delete_Class_result, setDelete_Class_result] = useState();
    function Delete_Class(local_class_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);




        var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_Class/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Class  res.data.status = " + res.data.status);
            //console.log(" In Delete_Class  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Class_api("true");
                setDelete_Class_result(res.data.message);

                GetAllClass_new();
                alert(res.data.message)
            }
            else {
                setDelete_Class_api("false");
                setDelete_Class_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.log('Delete_Class : Not good man :( Delete_Class = ' + error);
            setDelete_Class_api("false");
            alert(" Impossible de supprimer la formation");
        })
    }



    const [isLoading, setLoading] = useState();


    const [handlepush_to_lms_api, sethandlepush_to_lms_api] = useState("");
    const [handlepush_to_lms_result, sethandlepush_to_lms_result] = useState("");
    const [handlepush_to_lms_message, sethandlepush_to_lms_message] = useState("");

    function handlepush_to_lms(event, cellValues) {

        if (cellValues.row.lms_class_code && String(cellValues.row.lms_class_code).length > 2) {
            alert(" Cette formation est déjà publiée sur la plateforme de E-Learning");
            return;
        }

        setLoading(true);

        //console.log(" ### event.target.value = ", event.target.value);
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Push_Class_To_MySy_LMS/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append('class_internal_url', cellValues.row.internal_url);

        axios.post(url, formData).then(res => {
            //console.log(" In getCurrentUserData  res.data.status = " + res.data.status);
            //console.log(" In getCurrentUserData  res.data.message = " + res.data.message);
            if (String(res.data.status) === "true") {

                sethandlepush_to_lms_api("true");
                sethandlepush_to_lms_result(res.data.message);

                setLoading(false);
                GetAllClass_new();
                alert(res.data.message);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                sethandlepush_to_lms_api("false");
                sethandlepush_to_lms_message(res.data.message);
                setLoading(false);
                alert("Erreur : " + res.data.message);
            }

        }).catch((error) => {
            console.warn('Erro handlepush_to_lms = ', error);
            sethandlepush_to_lms_api("false");
            setLoading(false);
        })
    }

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    function clean_all_filters() {
        setp_filtre1();
        setp_filtre1_value();
        setp_filtre2();
        setp_filtre2_value();

        setp_filtre3();
        setp_filtre3_value();
        GetAllClass_new_no_filter();
    }



    const datagridSx = {
        borderRadius: 2,
        marginRight: 1,
        marginLeft: 1,
        border: 0,
        overflowX: 'scroll',
        "& .MuiDataGrid-main": { borderRadius: 0 },
        /*'& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
          color: "blue",
          fontSize: 18,
          //risky
          minHeight: "60px !important",
          height: 60,
          "& div": {
            minHeight: "60px !important",
            height: 60,
            lineHeight: "59px !important"
          }
        },*/
        "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
            }
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#c8cfd5",
            color: "black",
            fontSize: 16
        },
        "& .MuiDataGrid-cellContent": {
            minHeight: 50,
            
        }
    };


    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();
    const actionmass_ftion = (event) => {
        const value = event.target.value;


        if (String(value) === "publier" || String(value) === "depublier" ||
            String(value) === "desactiver" || String(value) === "supprimer"
            || String(value) === "dupliquer" || String(value) === "exporter") {
            setactionmass_ftion_val(value);
        }
        else {
            setactionmass_ftion_val();
        }
    }


    function GetSelectedRows_ftion_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            //console.log(" ### selectionModel_insc[i] = ", selectionModel_insc[i]);
            var myid = parseInt(String(selectionModel[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_ftion_Traitemet() {


        var liste_formation = GetSelectedRowsTraining();
        var liste_formation_ids_values = GetSelectedRowsTraining_id_values();

        //console.log(" liste_formation_ids_values = ", liste_formation_ids_values);
        //console.log(" actionmass_ftion_val = ", actionmass_ftion_val);

        if (String(actionmass_ftion_val) === String("depublier")) {

            Unpublish_class(liste_formation);
            GetAllClass_WOfilter();
            window.location.reload();
        }

        else if (String(actionmass_ftion_val) === String("publier")) {
            Publish_class(liste_formation);
            GetAllClass_WOfilter();
            window.location.reload();
        }

        else if (String(actionmass_ftion_val) === String("desactiver")) {
            Disable_class(liste_formation);
            GetAllClass_WOfilter();
            window.location.reload();
        }

        else if (String(actionmass_ftion_val) === String("dupliquer")) {
            Duplicate_class(liste_formation);
            GetAllClass_WOfilter();
            window.location.reload();
        }

        else if (String(actionmass_ftion_val) === String("supprimer")) {
            Delete_LIST_Formations(liste_formation_ids_values);
            GetAllClass_WOfilter();
            //window.location.reload();
        }


        else if (String(actionmass_ftion_val) === String("exporter")) {
            Export_class(liste_formation_ids_values);
        }

    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const [Delete_LIST_Formations_api, setDelete_LIST_Formations_api] = useState();
    const [Delete_LIST_Formations_message, setDelete_LIST_Formations_message] = useState();
    const [Delete_LIST_Formations_result, setDelete_LIST_Formations_result] = useState();
    function Delete_LIST_Formations(local_liste_class) {


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        // console.log(" #### local_liste_class = ", local_liste_class)

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_class_id", local_liste_class);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_list_Class/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_LIST_Formations  res.data.status = " + res.data.status);
            //console.log(" In Delete_LIST_Formations  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_LIST_Formations_api("true");
                setDelete_LIST_Formations_result(res.data.message);
                setSelectionModel([]);
                GetAllClass_new();

                alert(res.data.message);
            }
            else {
                setDelete_LIST_Formations_api("false");
                setDelete_LIST_Formations_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_LIST_Formations : Not good man :( mysearchtext = ' + error);
            setDelete_LIST_Formations_api("false");
            alert(" Impossible de supprimer la liste des sessions de formation");
        })
    }


    const [Dialog_formation_message, setDialog_formation_message] = React.useState(false);
    const [Dialog_formation_open, setDialog_formation_open] = React.useState(false);
    function Dialog_formation_handle_change_participant_session(message) {
        setDialog_formation_message(message);
        setDialog_formation_open(true);
    }

    const Dialog_formation_handleClose = () => {

    };

    const Dialog_formation_handleClose_buton = () => {
        setDialog_formation_open(false);
    };


    const [gridline_id, setgridline_id] = useState("");

    function Show_Detailled_formation_Data() {
        setupdatetraining(true);
        Dialog_formation_handleClose_buton();
    }


    const [selected_row_data_json, setselected_row_data_json] = useState([]);


    const New_Option = [
        { "id": "0", "label": "Publier", "value": "publier" },
        { "id": "1", "label": "Depublier", "value": "depublier" },
        { "id": "2", "label": "Dupliquer", "value": "dupliquer" },
        { "id": "3", "label": "Exporter", "value": "exporter" },
        { "id": "4", "label": "Supprimer", "value": "supprimer" },
    ]

    return (

        <div className='displaypartnertrainingpagination'>
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Dialog
                open={Dialog_formation_open}
                onClose={Dialog_formation_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>Info Formation</DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Code
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {<nav> {selected_row_data_json.external_code} </nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Titre
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {<nav> {selected_row_data_json.title} </nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Domaine
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {<nav> {selected_row_data_json.domaine} </nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Coût
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav> {selected_row_data_json.price} {selected_row_data_json.currecny}</nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Durée
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {selected_row_data_json.duration_in_hour && String(selected_row_data_json.duration_unit) === "jour" &&
                                <nav> {selected_row_data_json.duration} -  {selected_row_data_json.duration_unit}(s)  soit  <b><i> {selected_row_data_json.duration_in_hour}  heure(s)</i></b> </nav>}

                            {String(selected_row_data_json.duration_unit) !== "jour" &&
                                <nav> {selected_row_data_json.duration} -  {selected_row_data_json.duration_unit}(s)  </nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Présentiel
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>


                            {selected_row_data_json.published && <nav style={{ "textAlign": 'right', }}>
                                <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={parseInt(selected_row_data_json.presentiel.presentiel)} />
                            </nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Distanciel
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>


                            {selected_row_data_json.published && <nav style={{ "textAlign": 'right', }}>
                                <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={parseInt(selected_row_data_json.presentiel.distantiel)} />
                            </nav>}

                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Publié Web
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {selected_row_data_json.published && <nav style={{ "textAlign": 'right', }}>
                                <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={parseInt(selected_row_data_json.published)} />
                            </nav>}

                        </div>
                    </div>
                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Cible
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {<nav>   {String(parse(String(selected_row_data_json.pourqui).replace(/(<([^>]+)>)/ig, ''))).substring(0, 100)} </nav>}
                        </div>
                    </div>


                    <div className="div_row" > &nbsp;

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Show_Detailled_formation_Data} className="bton_enreg_dialog">Voir detail</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_formation_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            {updatetraining && <div>

                <div className='detail_training'>
                    <TrainingForm mytrainingclass={{
                        'ref': reftrainingtoupdate, 'class_external_code': externalcodetraining_toupdate
                    }} ispending={props.ispending} currentpack={props.currentpack} closeTrainingForm={closeTrainingForm} rows_champs_specifics={rows_champs_specifics} />
                </div>


                <hr />
                <div onClick={closeTrainingForm} className="parter_div_filtrer_center">
                    <AiFillCloseCircle alt="Fermer detail formation" />FERMER
                </div>

            </div>
            }

            {createtraining && <div>

                <div className="parter_div_droite_bas">
                    <div className="titre1_bis"> Création manuelle d'une formation </div>
                    <TrainingForm mytrainingclass={{ 'ref': '', 'dist': 'Oui', 'plus_produit': '0' }} ispending={props.ispending}
                        currentpack={String(props.currentpack).toUpperCase()} closeTrainingForm={Closecreatetraining} rows_champs_specifics={rows_champs_specifics} />
                </div>

                <hr />

                <div onClick={Closecreatetraining} className="parter_div_filtrer_center">
                    <AiFillCloseCircle alt="Fermer detail formation" />FERMER
                </div>

            </div>
            }

            <div className="titre1">  Utilisez les filtres !</div>
            <div className="div_row" style={{ "marginBottom": "5px" }}>
                <div className="div_row_gauche texte_area_filter" >
                    <TextField
                        name="filtre1"
                        label="Choisir un champ"
                        select
                        sx={{ m: 1, width: '100%' }}
                        value={p_filtre1}
                        onChange={(e) => {
                            setp_filtre1(e.target.value)
                        }}
                    >
                        <MenuItem value="external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code externe formation &nbsp;&nbsp;</MenuItem>
                        <MenuItem value="title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                        <MenuItem value="certif" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Certifiée &nbsp;</MenuItem>

                    </TextField>
                </div>
                <div className="div_row_droite texte_area_filter_value" >
                    {p_filtre1 &&
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="filtre1_value"
                            id="filtre1_value"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_filtre1_value}
                            onChange={(e) => { setp_filtre1_value(e.target.value); }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <AiFillCloseCircle
                                            style={{ 'cursor': "pointer", "color": "orangered" }}
                                            onClick={(e) => {
                                                setp_filtre1_value("");
                                            }} />
                                    </InputAdornment>
                                ),
                            }}

                        />
                    }

                </div>
                {p_filtre1 && <div className='filter_bton_add'>
                    <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                        &nbsp; <MdAddCircleOutline onClick={(e) => {
                            setp_filtre2("1");
                        }} />
                    </a>
                </div>}
                {p_filtre1 && <div className='filter_bton_add'>
                    <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                        &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                            setp_filtre1();
                            setp_filtre1_value();
                        }}
                        />
                    </a>
                </div>}


            </div>

            {p_filtre2 &&
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter">
                        <TextField
                            name="filtre2"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            options={filters}
                            value={p_filtre2}
                            onChange={(e) => {
                                setp_filtre2(e.target.value)
                            }}
                        >

                            <MenuItem value="external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code externe formation &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                            <MenuItem value="certif" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Certifiée &nbsp;</MenuItem>

                        </TextField>
                    </div>
                    <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                        {String(p_filtre2).length > 3 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre2_value"
                                id="filtre2_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre2_value}
                                onChange={(e) => setp_filtre2_value(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre2_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />}
                    </div>

                    {String(p_filtre2).length > 3 && <div className='filter_bton_add' >
                        <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline
                                onClick={(e) => {
                                    setp_filtre3("1");
                                }}
                            />
                        </a>
                    </div>}

                    {String(p_filtre2).length > 3 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline
                                onClick={(e) => {
                                    setp_filtre2();
                                    setp_filtre2_value();
                                }}
                            />
                        </a>
                    </div>}

                </div>
            }


            {p_filtre3 &&
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter">
                        <TextField
                            name="filtre3"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            options={filters}
                            value={p_filtre3}
                            onChange={(e) => {
                                setp_filtre3(e.target.value)
                            }}
                        >

                            <MenuItem value="external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code externe formation &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                            <MenuItem value="certif" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Certifiée &nbsp;</MenuItem>

                        </TextField>
                    </div>
                    <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                        {String(p_filtre3).length > 3 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre3_value"
                                id="filtre3_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre3_value}
                                onChange={(e) => setp_filtre3_value(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre3_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />}
                    </div>

                    {String(p_filtre3).length > 3 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre3();
                                setp_filtre3_value();
                            }} />
                        </a>
                    </div>}
                </div>
            }



            {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                <div className="div_row_gauche">
                    <Button variant="contained" className="bton_enreg" onClick={GetAllClass_new}>Rechercher
                    </Button>
                </div>

                <div className="div_row_droite">
                    <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                    </Button>

                </div>

            </div>}

            <div style={{ height: "850 px", "width": "100%" }}>

                {String(myApiResponse) === "False" && <div className='koUpdateData'>
                    Impossible de traiter la demande
                </div>}



                <div style={{ height: "800px", width: '100%', paddingRight: '10px' }}>
                    &nbsp;
                    {/*!createtraining && <div className='div_row' >

                            <Button variant="outlined" onClick={Createtraining}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant' style={{ "float": "right", "marginRight": "10px" }}>Formation &nbsp;
                                <img src={add_plus} alt="ajout session" className="icon_plus" />
                            </Button>

                        </div>*/}
                    <div className="session_data">
                        <div style={{ "border": "None", paddingRight: '10px' }}>
                            <Box
                                sx={{
                                    height: "500px",
                                    width: '100%',
                                    paddingRight: '2px',
                                    '& .cell--published': {
                                        backgroundColor: '#a2cf6e',
                                        color: '#1a3e72',
                                        fontWeight: 'bold',
                                    },
                                    '& .cell--unpublished': {
                                        backgroundColor: '#ffac33',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .line--published': {
                                        backgroundColor: '#E6F7C8',
                                        color: 'black',
                                    },
                                    '& .line--unpublished': {
                                        backgroundColor: '#FAD7A0',
                                        color: 'black',
                                    },


                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        //console.log("ch selected--" + newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (

                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            internal_url: JSON.parse(item).internal_url,
                                            zone_diffusion: JSON.parse(item).zone_diffusion_str,
                                            external_code: JSON.parse(item).external_code,
                                            title: parse((JSON.parse(item).title).replace(/(<([^>]+)>)/ig, '')),
                                            domain: JSON.parse(item).domaine,
                                            metier: JSON.parse(item).metier,
                                            url: JSON.parse(item).url,
                                            price: JSON.parse(item).price,
                                            description: parse((JSON.parse(item).description).replace(/(<([^>]+)>)/ig, '')),
                                            published: JSON.parse(item).published,
                                            lms_class_code: JSON.parse(item).lms_class_code,
                                            duration: JSON.parse(item).duration,
                                            duration_unit: JSON.parse(item).duration_unit,
                                            duration_in_hour: JSON.parse(item).duration_in_hour,
                                            presentiel: JSON.parse(item).presentiel.presentiel,
                                            distanciel: JSON.parse(item).presentiel.distantiel,
                                            cible: JSON.parse(item).pourqui,
                                            currecny: JSON.parse(item).currecny,
                                            nb_session_formation: JSON.parse(item).nb_session_formation,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        setgridline_id(newSelectionModel.row.id);
                                        Closecreatetraining();
                                        closeTrainingForm();

                                        var al = JSON.parse(rowss[newSelectionModel.row.id])

                                        setselected_row_data_json(JSON.parse(rowss[newSelectionModel.row.id]));


                                        setreftrainingtoupdate(newSelectionModel.row.internal_url);
                                        setexternalcodetraining_toupdate(newSelectionModel.row.external_code);
                                        setDialog_formation_open(true);

                                    }
                                    }

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                   
                                    getCellClassName={(params) => {
                                        if (params.field === 'published' && String(params.value) === "1") {
                                            return 'cell--published';
                                        }

                                        if (params.field === "published" && String(params.value) !== "1") {
                                            return 'cell--unpublished'
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }

                                        if (String(params.row.published) === "1") {
                                            return 'line--published';
                                        }

                                        if (String(params.row.published) !== "1") {
                                            return 'line--unpublished';
                                        }

                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={datagridSx}
                                   

                                />
                            </Box>
                        </div>
                    </div>
                    <div className="div_row"> &nbsp; </div>
                    <div className="div_row">
                        {selectionModel && selectionModel.length >= 1 && <div className="block_en_mass">
                            <nav >Traitement en masse </nav>
                            &nbsp;
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className="block_en_mass_select"
                                fullWidth
                                options={New_Option}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        if (String(value.value) === "publier" || String(value.value) === "depublier" ||
                                            String(value.value) === "desactiver" || String(value.value) === "supprimer"
                                            || String(value.value) === "dupliquer" || String(value.value) === "exporter") {
                                            setactionmass_ftion_val(value.value);
                                        }
                                        else {
                                            setactionmass_ftion_val();
                                        }

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" />
                                }
                            />


                            {/* <TextField

                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: "0", max: "1", step: "1" }}
                                disabled={false}
                                className="block_en_mass_select"

                                value={actionmass_ftion_val}
                                onChange={actionmass_ftion}
                            >
                                <MenuItem value="" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Action &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="publier" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Publier &nbsp;</MenuItem>
                                <MenuItem value="depublier" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Dépublier &nbsp;</MenuItem>
                                <MenuItem value="dupliquer" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Dupliquer &nbsp;</MenuItem>
                                <MenuItem value="supprimer" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Supprimer &nbsp;</MenuItem>
                                <MenuItem value="exporter" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Exporter &nbsp;</MenuItem>

                            </TextField>*/}

                            {actionmass_ftion_val && <nav className='block_en_mass_bton_action'>
                                <Popup
                                    trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                        <FcAcceptDatabase /> Traiter

                                    </Button>}
                                    modal
                                    nested
                                    position="center center"

                                >

                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}
                                                {String(actionmass_ftion_val) === "supprimer" &&
                                                    <font color="red">  Confirmer la <b> suppression </b> en masse pour {selectionModel.length} formations.
                                                    </font>}

                                                {String(actionmass_ftion_val) === "dupliquer" &&
                                                    <font color="red">  Confirmer la <b> duplication </b> en masse pour {selectionModel.length} formations.
                                                    </font>}

                                                {String(actionmass_ftion_val) === "exporter" &&
                                                    <font>  Confirmer l' <b> export </b> en masse pour {selectionModel.length} formations.
                                                    </font>}

                                                {String(actionmass_ftion_val) === "publier" &&
                                                    <font>  Confirmer la <b> publication </b> en masse pour {selectionModel.length} formations.
                                                    </font>}

                                                {String(actionmass_ftion_val) === "depublier" &&
                                                    <font>  Confirmer la <b> dépublication </b> en masse pour {selectionModel.length} formations.
                                                    </font>}




                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                        actionmass_ftion_Traitemet();
                                                        close();
                                                    }}> Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </nav>
                            }


                        </div>}
                    </div>
                    {selectionModel && selectionModel.length >= 1 && <div className="div_row" style={{ "border": "None" }}>
                        <hr className="hr_break" />
                    </div>}

                    &nbsp;

                    <div className='div_row' >

                        <Button variant="outlined" onClick={changeHandler2} className="detail_class_submenu bton_add_session_long"
                            id='menu_import_formation' name='menu_import_formation'>Choisir un fichier et importer  &nbsp;<GrDocumentCsv style={{ "fontSize": "large" }} /> <SendIcon />
                        </Button>

                        <input type="file"
                            accept=".csv"
                            ref={hiddenFileInput_formation}
                            style={{ display: 'none' }}
                            name="liste_formation_file"
                            onChange={handleSubmission}
                        />
                        &nbsp;
                        <Button variant="outlined" onClick={Createtraining}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant' style={{ "marginRight": "10px" }}>Ajout 1 Formation &nbsp;
                            <img src={add_plus} alt="ajout session" className="icon_plus" />
                        </Button>

                        <br />
                        {formation_file_name && <nav className="okUpdateData"><i>{formation_file_name}</i></nav>}

                        <a href='/sample/template_import_formations.csv' download><nav style={{ "fontSize": "14px" }}>Télécharger un fichier modèle</nav></a>


                    </div>

                    <div className="import_excel_text">

                        <nav className="titre1">  Comment utiliser le fichier d'import des formations ? </nav>
                        <nav style={{ "color": "orange" }}>
                            Dans le fichier à importer, seules les colonnes suivantes sont obligatoires  :
                            <ul>
                                <li>external_code : C'est le code de la formation chez vous, editeur de la formation </li>
                                <li> titre : Le titre de la formation </li>
                                <li> domaine : Le domaine au quel est rattaché la formation</li>
                                <li> description : La descriptin de la formation</li>
                            </ul>
                        </nav>
                        Pour le reste, <br />
                        <ul>
                            <li> Il s'agit d'un fichier csv. Les colonnes sont separées par des ";"</li>
                            <li> A l'interieur d'une même colonne, les informations sont separées par des ";"     </li>
                            <li> Champ 'Metier' :  les valeurs acceptées sont : IT, RH, vente, dev_perso, Graphisme, Management, Digital, Office, Autre</li>
                            <li> ...</li>
                        </ul>
                        <a href='/sample/MySy_Import_formation_csv.pdf' download>Télécharger le guide complet pour plus d'informations</a><br />
                        <br />
                    </div>
                    <div style={{ "width": "50%" }}> &nbsp; </div>

                </div>
            </div>
            <br />

        </div>


    )

}

export default DistplayPartnerTraningsPage;