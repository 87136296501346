import RechercheArticle from "../components/RechercheArticles";
import Navigation from "../components/Navigation";

export default function displaydetailclass() {
    return (
        <div>
           <div >
            <Navigation />
            </div>
         
            <RechercheArticle />
        
            
        </div>
    );
}