import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import Autocomplete from '@mui/material/Autocomplete';
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Global } from "recharts";


const Partner_Commande = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_order_lines, setrows_order_lines] = useState([]);
    const [selectionModel_order_lines, setselectionModel_order_lines] = React.useState([]);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();
    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'order_header_ref_interne', headerName: 'Reference', width: 100, hide: false, editable: false },
        { field: 'order_header_type', headerName: 'Type', width: 100, hide: false, editable: false },
        {
            field: 'order_header_status', headerName: 'Statut', width: 100, hide: false, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "-1")
                    return "Annulé";
                else if (String(params.value) === "0")
                    return "Brouillon";
                else if (String(params.value) === "1")
                    return "En cours";
                else if (String(params.value) === "2")
                    return "Traité";
                else if (String(params.value) === "3")
                    return "Facturé";
                else
                    return "?";

            },
        },
        { field: 'order_header_client_id', headerName: 'Client_Id', width: 150, hide: true, editable: false },
        { field: 'order_header_client_nom', headerName: 'Nom Client', width: 150, hideable: false, flex: 1 },
        { field: 'order_header_vendeur_id', headerName: 'Vendeur Id', width: 150, hide: true, editable: false },
        { field: 'order_header_vendeur_nom_prenom', headerName: 'Vendeur', width: 150, hideable: false, flex: 1, editable: false },
        { field: 'order_header_date_cmd', headerName: 'Date', width: 150, hide: false, editable: false },
        { field: 'order_header_date_expiration', headerName: 'Date Expiration', width: 150, hide: false, editable: false },


        /* {
             field: "Detail", headerName: 'Voir detail',
             renderCell: (cellValues) => {
                 return (
 
                     <Button
 
                         onClick={(event) => {
                             Display_Detail_Employe(event, cellValues);
                         }}
                     >
                         <FcViewDetails />
 
                     </Button>
 
                 );
             }
         },*/
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]


    const columns_order_lines = [
        { field: '_id', headerName: '_id', hide: true, editable: false },
        { field: 'id', headerName: 'id', hide: true, editable: false },
        { field: 'order_line_formation', headerName: 'intenal_url', width: 0, hide: true, editable: false },
        { field: 'order_line_formation_title', headerName: 'Titre', width: 250, hide: false, editable: false, flex: 1 },
        { field: 'order_line_formation_external_code', headerName: 'Code Formation', width: 150, hide: false, editable: false },
        { field: 'order_line_formation_duration', headerName: 'Durée', width: 80, hide: false, editable: false },

        { field: 'order_line_qty', headerName: 'Qte', width: 100, hide: false, editable: false },
        { field: 'order_line_prix_unitaire', headerName: 'Prix Unit.', width: 100, hide: false, editable: false },
        { field: 'order_header_id', headerName: 'Id_Entete', hide: true, editable: false },
        { field: 'order_header_ref_interne', headerName: 'Ref_Interne', hide: true, editable: false },
        { field: 'order_line_type', headerName: 'Type', width: 100, hide: false, editable: false },
        {
            field: 'order_line_status', headerName: 'Statut', width: 150, hide: false, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "-1")
                    return "Annulé";
                else if (String(params.value) === "0")
                    return "Brouillon";
                else if (String(params.value) === "1")
                    return "En cours";
                else if (String(params.value) === "2")
                    return "Traité";
                else if (String(params.value) === "3")
                    return "Facturé";
                else
                    return "?";

            },
        },
        {
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Display_Detail_Order_Line(event, cellValues);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ligne sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Order_line(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },
        { field: 'order_line_comment', headerName: 'Commentaire', width: 150, hide: true, editable: false, },

    ]



    function handleClick_delete(event, cellValues) {

        var local_order_header_id = cellValues.row._id;
        var local_order_type = cellValues.row.order_header_type;



        Delete_Order_Header_AndLine_Data(local_order_header_id, local_order_type);
    }


    const [Delete_Order_Header_AndLine_Data_api, setDelete_Order_Header_AndLine_Data_api] = useState();
    const [Delete_Order_Header_AndLine_Data_message, setDelete_Order_Header_AndLine_Data_message] = useState();
    const [Delete_Order_Header_AndLine_Data_result, setDelete_Order_Header_AndLine_Data_result] = useState();
    function Delete_Order_Header_AndLine_Data(local_order_header_id, local_order_type) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_id", local_order_header_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Partner_Order_Header_And_Lines/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Order_Header_AndLine_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Order_Header_AndLine_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_Order_Header_AndLine_Data_api("true");
                setDelete_Order_Header_AndLine_Data_result(res.data.message);

                clean_order_detail_fields();
                setsubmenu();
                setdisplay_detail_order();
                setadd_One_Order();
                setSelectionModel([]);

                Getall_Parter_Orders_No_Filter();

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }
                alert(res.data.message);
            }
            else {
                setDelete_Order_Header_AndLine_Data_api("false");
                setDelete_Order_Header_AndLine_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('Delete_Order_Header_AndLine_Data : Not good man :( Delete_Order_Header_AndLine_Data = ' + error);
            setDelete_Order_Header_AndLine_Data_api("false");
            if (local_order_type == "devis")
                alert(" Impossible de supprimer le devis ");
            else
                alert(" Impossible de supprimer la commande ");
        })
    }



    function Display_Detail_Order_Line(event, cellValues) {

        var order_line_id = String(cellValues.row._id);

        var order_line_formation = String(cellValues.row.order_line_formation);
        var order_line_qty = String(cellValues.row.order_line_qty);
        var order_line_prix_unitaire = String(cellValues.row.order_line_prix_unitaire);

        setselected_order_line_id(order_line_id);

        setp_add_line_item(order_line_formation);
        setp_add_line_item_qty(order_line_qty);
        setp_add_line_item_price(order_line_prix_unitaire);
        setp_add_line_item_status(String(cellValues.row.order_line_status));
        setp_add_line_item_type(String(cellValues.row.order_line_type));
        setp_add_line_item_comment(String(cellValues.row.order_line_comment));


        Get_Given_Line_Of_Partner_Order_Line_Data(order_line_id, order_internal_ref);



        setDialog_Detail_Article_open(true);
        //alert(" afficher le detail de la ligne " + String(order_line_id));

    }


    function clean_all_filters() {
        setp_filtre1();
        setp_filtre1_value();

        setp_filtre2();
        setp_filtre2_value();

        setp_filtre3();
        setp_filtre3_value();

        setp_filtre4();
        setp_filtre4_value();

        clean_order_detail_fields();
        setsubmenu();
        setorder_data_edit_mode();
        setorder_data_edit_mode();
        setdisplay_detail_order();
        setadd_One_Order();
        setSelectionModel([]);
        setselectionModel_order_lines([]);

        Getall_Parter_Orders_No_Filter();
    }


    function handleClick_delete_Order_line(event, cellValues) {

        var local_order_line_id = cellValues.row._id;

        Delete_Order_Line_Data(local_order_line_id);
    }

    const [Delete_Order_Line_Data_api, setDelete_Order_Line_Data_api] = useState();
    const [Delete_Order_Line_Data_message, setDelete_Order_Line_Data_message] = useState();
    const [Delete_Order_Line_Data_result, setDelete_Order_Line_Data_result] = useState();
    function Delete_Order_Line_Data(local_order_line_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_line_id", local_order_line_id);
        form.append("order_header_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Partner_Order_Line/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_Order_Line_Data_api("true");
                setDelete_Order_Line_Data_result(res.data.message);

                Display_Detail_Order_data(selected_id);

                alert(res.data.message);
            }
            else {
                setDelete_Order_Line_Data_api("false");
                setDelete_Order_Line_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            setLoading(false);
            console.log('Delete_Order_Line_Data : Not good man :( Delete_Order_Line_Data = ' + error);
            setDelete_Order_Line_Data_api("false");
            alert(" Impossible de supprimer la ligne");
        })
    }


    const myRef = useRef(null)

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [New_GetCurrentPartnerClass_result, setNew_GetCurrentPartnerClass_result] = useState([]);

    const [GetCurrentPartnerClass_api, setGetCurrentPartnerClass_api] = useState();
    const [GetCurrentPartnerClass_message, setGetCurrentPartnerClass_message] = useState();
    const [GetCurrentPartnerClass_result, setGetCurrentPartnerClass_result] = useState();
    function GetCurrentPartnerClass(event) {
        var form = new FormData();



        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentPartnerClass  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentPartnerClass  res.data.message r_class = " + res.data.message);
                setGetCurrentPartnerClass_api("true");
                setGetCurrentPartnerClass_result(res.data.message);

                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_internal_url = JSON.parse(x).internal_url;
                    var local_title = JSON.parse(x).title;
                    var local_is_published = JSON.parse(x).published;


                    //---
                    var node = {
                        "_id": local_id, "id": localid,
                        "label": local_title, "internal_url": local_internal_url,
                        "title": local_title,
                        "ispublished": local_is_published,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });


                if (new_data2.length > 0)
                    setNew_GetCurrentPartnerClass_result(new_data2);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetCurrentPartnerClass_api("false");
                setGetCurrentPartnerClass_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ', error);
            setGetCurrentPartnerClass_api("false");
            alert(" Impossible de recuperer la liste des formations")
        })
    }



    useEffect(() => {

        Get_List_Partner_Clients();
        Getall_Training_Employee_No_Filter();
        Getall_Parter_Orders_No_Filter();
        GetCurrentPartnerClass();
        Get_Given_Partner_Config_Point_Taux_TVA();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }


    }, [])

    const [Getall_Parter_Orders_No_Filter_api, setGetall_Parter_Orders_No_Filter_api] = useState();
    const [Getall_Parter_Orders_No_Filter_message, setGetall_Parter_Orders_No_Filter_message] = useState();
    const [Getall_Parter_Orders_No_Filter_result, setGetall_Parter_Orders_No_Filter_result] = useState();
    function Getall_Parter_Orders_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Order_no_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Orders_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Orders_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Orders_No_Filter_api("true");
                setGetall_Parter_Orders_No_Filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Orders_No_Filter_api("false");
                setGetall_Parter_Orders_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Orders_No_Filter = ', error);
            setGetall_Parter_Orders_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des commandes et devis");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Parter_Orders_With_Filter_api, setGetall_Parter_Orders_With_Filter_api] = useState();
    const [Getall_Parter_Orders_With_Filter_message, setGetall_Parter_Orders_With_Filter_message] = useState();
    const [Getall_Parter_Orders_With_Filter_result, setGetall_Parter_Orders_With_Filter_result] = useState();
    function Getall_Parter_Orders_With_Filter(event) {

        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Order_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Orders_With_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Orders_With_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Orders_With_Filter_api("true");
                setGetall_Parter_Orders_With_Filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Orders_With_Filter_api("false");
                setGetall_Parter_Orders_With_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Orders_With_Filter = ', error);
            setGetall_Parter_Orders_With_Filter_api("false");
            alert(" Impossible de recuperer la liste des commandes et devis");
            //setmyApimyApiMessage("")
        })
    }


    const [session_file_name, setsession_file_name] = useState();
    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);

        fetch(
            // ici a modifier process.env.REACT_APP_API_URL + "myclass/api/Add_Ressource_Humaine_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");

                    //Getall_Training_Employee();
                    alert(" Les employés ont été correctement importés");

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer les employés ");
            });
    }

    const myRef_head = useRef(null);
    const [selected_id, setselected_id] = useState("");
    const [selected_order_line_id, setselected_order_line_id] = useState("");
    const [selected_order_header_type, setselected_order_header_type] = useState();

    const [order_internal_ref, setorder_internal_ref] = useState("");
    function handleClick_edit_order_From_Line(selected_row_id) {

        //submenu_detail_employe();

        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var order_id = line._id;
        setselected_id(order_id);

        var order_ref = line.order_header_ref_interne;
        setorder_internal_ref(order_ref);

        var order_header_type = line.order_header_type;
        setselected_order_header_type(order_header_type);

        //console.log(" ### employe_id  = ", employe_id);
        Display_Detail_Order_data(order_id);


        var divh = document.getElementById('myRef').offsetTop;
        window.scrollTo({
            top: divh,
            behavior: "smooth",
        });



    }

    function Display_Detail_Order_data(order_id) {
        //clearDetailAttendeFields();
        setadd_One_Order();
        setdisplay_detail_order("1");
        setsubmenu("detail_order");
        clean_order_detail_fields();
        setselectionModel_order_lines([]);

        setLoading(true);
        Get_Order_Data(order_id);
        Get_Partner_Orders_Lines_from_header_id(order_id);

    }

    const [Order_data_changed, setOrder_data_changed] = useState();
    const [order_data_edit_mode, setorder_data_edit_mode] = useState();


    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Order_Data(order_id) {
        setOrder_data_changed("");
        setorder_data_edit_mode("");


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", order_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Order/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In Get_Order_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Order_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_order = JSON.parse(res.data.message);


                    //document.getElementsByName("detail_header_type")[0].value = "";
                    if (mylocal_order.order_header_type)
                        setp_detail_header_type(mylocal_order.order_header_type);

                    //document.getElementsByName("detail_header_status")[0].value = "";
                    if (mylocal_order.order_header_status) {
                        setp_detail_header_statut(mylocal_order.order_header_status);
                        if (String(mylocal_order.order_header_status) === "0") {
                            setp_detail_header_statut_label("Brouillon");
                        }
                        else if (String(mylocal_order.order_header_status) === "1") {
                            setp_detail_header_statut_label("En cours");
                        }
                        else if (String(mylocal_order.order_header_status) === "2") {
                            setp_detail_header_statut_label("Traité");
                        }
                        else if (String(mylocal_order.order_header_status) === "3") {
                            setp_detail_header_statut_label("Facturé");
                        }
                        else if (String(mylocal_order.order_header_status) === "-1") {
                            setp_detail_header_statut_label("Annulé");
                        }

                    }
                    else {
                        setp_detail_header_statut("0");
                        setp_detail_header_statut_label("Brouillon");
                    }

                    //document.getElementsByName("detail_header_ref_interne")[0].value = "";
                    if (mylocal_order.order_header_ref_interne) {
                        setp_detail_header_ref_interne(mylocal_order.order_header_ref_interne);
                    }

                    if (mylocal_order.order_header_condition_paiement) {
                        setp_detail_header_condition_paiement(mylocal_order.order_header_condition_paiement);
                    }


                    //document.getElementsByName("detail_header_ref_cient")[0].value = "";
                    if (mylocal_order.order_header_ref_client) {
                        setp_detail_header_ref_client(mylocal_order.order_header_ref_client);
                    }

                    if (mylocal_order.order_header_email_client) {
                        setp_detail_header_email_client(mylocal_order.order_header_email_client);
                    }


                    //document.getElementsByName("detail_header_date_cmd")[0].value = "";
                    if (mylocal_order.order_header_date_cmd) {
                        setp_detail_header_date_cmd(mylocal_order.order_header_date_cmd);
                        setp_detail_header_date_cmd_val(mylocal_order.order_header_date_cmd);
                    }

                    //document.getElementsByName("detail_header_date_expiration")[0].value = "";
                    if (mylocal_order.order_header_date_expiration) {
                        setp_detail_header_date_expiration(mylocal_order.order_header_date_expiration);
                        setp_detail_header_date_expiration_val(mylocal_order.order_header_date_cmd);
                    }


                    //document.getElementsByName("detail_nom_client")[0].value = "";
                    if (mylocal_order.order_header_client_nom) {
                        setp_detail_header_client_nom(mylocal_order.order_header_client_nom);
                        setp_detail_header_client_nom_id(mylocal_order.order_header_client_id);
                    }


                    //document.getElementsByName("detail_header_description")[0].value = "";
                    if (mylocal_order.order_header_description) {
                        setp_detail_header_description(mylocal_order.order_header_description);
                    }

                    //document.getElementsByName("detail_header_comment")[0].value = "";
                    if (mylocal_order.order_header_comment) {
                        setp_detail_header_comment(mylocal_order.order_header_comment);
                    }


                    // document.getElementsByName("detail_header_vendeur_nom_prenom")[0].value = "";
                    if (mylocal_order.order_header_vendeur_nom_prenom) {
                        setp_detail_header_vendeur_nom(mylocal_order.order_header_vendeur_nom_prenom);
                        setp_detail_header_vendeur_nom_id(mylocal_order.order_header_vendeur_id);
                    }


                    // Recuperation des adresses (livraison / lieu execution - Facturation ) 
                    //document.getElementsByName("detail_header_fact_adresse")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_adresse) {
                        setp_detail_header_fact_adr(mylocal_order.order_header_adr_fact_adresse);
                    }

                    //document.getElementsByName("detail_header_fact_code_postal")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_code_postal) {
                        setp_detail_header_fact_code_postal(mylocal_order.order_header_adr_fact_code_postal);
                    }

                    //document.getElementsByName("detail_header_fact_ville")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_ville) {
                        setp_detail_header_fact_ville(mylocal_order.order_header_adr_fact_ville);
                    }

                    //document.getElementsByName("detail_header_fact_pays")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_pays) {
                        setp_detail_header_fact_pays(mylocal_order.order_header_adr_fact_pays);
                    }

                    //document.getElementsByName("detail_header_liv_adresse")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_adresse) {
                        setp_detail_header_liv_adr(mylocal_order.order_header_adr_liv_adresse);
                    }

                    //document.getElementsByName("detail_header_liv_code_postal")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_code_postal) {
                        setp_detail_header_liv_code_postal(mylocal_order.order_header_adr_liv_code_postal);
                    }

                    //document.getElementsByName("detail_header_liv_ville")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_ville) {
                        sep_detail_header_liv_ville(mylocal_order.order_header_adr_liv_ville);
                    }

                    //document.getElementsByName("detail_header_liv_pays")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_pays) {
                        setp_detail_header_liv_pays(mylocal_order.order_header_adr_liv_pays);
                    }


                    // Recuperation des montant, taxes et autres reductions
                    //document.getElementsByName("detail_header_total_ht")[0].value = "";
                    if (mylocal_order.order_header_total_ht) {
                        setp_detail_header_total_ht(mylocal_order.order_header_total_ht);
                    }

                    //document.getElementsByName("detail_header_total_tax")[0].value = "";
                    if (mylocal_order.order_header_tax_amount) {
                        setp_detail_header_total_tax(mylocal_order.order_header_tax_amount);
                    }


                    //document.getElementsByName("detail_header_total_ttc")[0].value = "";
                    if (mylocal_order.order_header_total_ttc) {
                        setp_detail_header_total_ttc(mylocal_order.order_header_total_ttc);
                    }

                    //document.getElementsByName("detail_header_type_reduction")[0].value = "";
                    if (mylocal_order.order_header_type_reduction) {
                        setp_detail_header_type_reduction(mylocal_order.order_header_type_reduction);
                    }

                    //document.getElementsByName("detail_header_type_reduction_valeur")[0].value = "";
                    if (mylocal_order.order_header_type_reduction_valeur) {
                        setp_detail_header_type_reduction_valeur(mylocal_order.order_header_type_reduction_valeur);
                    }


                    if (mylocal_order.order_header_montant_reduction) {
                        setp_detail_header_total_reduction_amount(mylocal_order.order_header_montant_reduction);
                    }


                    if (mylocal_order.total_lines_montant_reduction) {
                        setp_detail_header_montant_reduction(mylocal_order.total_lines_montant_reduction);
                    }

                    if (mylocal_order.total_header_hors_taxe_before_header_reduction) {
                        setp_detail_total_header_hors_taxe_before_header_reduction(mylocal_order.total_header_hors_taxe_before_header_reduction);
                    }

                    if (mylocal_order.total_header_hors_taxe_after_header_reduction) {
                        setp_detail_total_header_hors_taxe_after_header_reduction(mylocal_order.total_header_hors_taxe_after_header_reduction);
                    }

                    if (mylocal_order.order_header_tax_amount) {
                        setp_detail_order_header_tax_amount(mylocal_order.order_header_tax_amount);
                    }


                    if (mylocal_order.total_header_toutes_taxes) {
                        setp_detail_total_header_toutes_taxes(mylocal_order.total_header_toutes_taxes);
                    }

                    if (mylocal_order.total_lines_hors_taxe_before_lines_reduction) {
                        setp_detail_total_lines_hors_taxe_before_lines_reduction(mylocal_order.total_lines_hors_taxe_before_lines_reduction);
                    }

                    if (mylocal_order.total_lines_hors_taxe_after_lines_reduction) {
                        setp_detail_total_lines_hors_taxe_after_lines_reduction(mylocal_order.total_lines_hors_taxe_after_lines_reduction);
                    }



                    disable_Order_Header_DetailFields();
                    order_header_submenu_main();

                } else {
                    alert(" Aucune commande/devis recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Order_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de la commande / devis");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Partner_Orders_Lines_from_header_id_api, setGet_Partner_Orders_Lines_from_header_id_api] = useState();
    const [Get_Partner_Orders_Lines_from_header_id_message, setGet_Partner_Orders_Lines_from_header_id_message] = useState();
    const [Get_Partner_Orders_Lines_from_header_id_result, setGet_Partner_Orders_Lines_from_header_id_result] = useState();
    function Get_Partner_Orders_Lines_from_header_id(local_order_header_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_id", local_order_header_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Order_Lines/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In Get_Partner_Orders_Lines_from_header_id  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Orders_Lines_from_header_id  res.data.message r_class = " + res.data.message);
                setGet_Partner_Orders_Lines_from_header_id_api("true");
                setGet_Partner_Orders_Lines_from_header_id_result(res.data.message);
                setrows_order_lines(res.data.message);
            }
            else {
                setLoading(false);
                setGet_Partner_Orders_Lines_from_header_id_api("false");
                setGet_Partner_Orders_Lines_from_header_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Orders_Lines_from_header_id = ', error);
            setGet_Partner_Orders_Lines_from_header_id_api("false");
            alert(" Impossible de recuperer le detail de la commande / devis");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }





    const [partner_taux_tva, setpartner_taux_tva] = useState("20");

    const [Get_Given_Partner_Config_Point_Taux_TVA_api, setGet_Given_Partner_Config_Point_Taux_TVA_api] = useState();
    const [Get_Given_Partner_Config_Point_Taux_TVA_message, setGet_Given_Partner_Config_Point_Taux_TVA_message] = useState();
    const [Get_Given_Partner_Config_Point_Taux_TVA_result, setGet_Given_Partner_Config_Point_Taux_TVA_result] = useState();
    function Get_Given_Partner_Config_Point_Taux_TVA() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("config_name", "tva");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Basic_Setup/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Given_Partner_Config_Point_Taux_TVA  res.data.status = " + res.data.status);
            //console.log(" In Get_Given_Partner_Config_Point_Taux_TVA  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_Given_Partner_Config_Point_Taux_TVA_api("true");
                setGet_Given_Partner_Config_Point_Taux_TVA_result(res.data.message);

                if (res.data.message.length > 0) {
                    var point_config_tva = JSON.parse(res.data.message);

                    if (point_config_tva.config_value) {
                        setpartner_taux_tva(point_config_tva.config_value);
                        console.log(" GGG TVA PART = ", point_config_tva.config_value);
                    }

                } else {
                    alert(" Aucun taux de TVA récuperé")
                }
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Given_Partner_Config_Point_Taux_TVA_api("false");
                setGet_Given_Partner_Config_Point_Taux_TVA_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Partner_Config_Point_Taux_TVA = ', error);
            setGet_Given_Partner_Config_Point_Taux_TVA_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Givent_Partner_Client_Data_api, setGet_Givent_Partner_Client_Data_api] = useState();
    const [Get_Givent_Partner_Client_Data_message, setGet_Givent_Partner_Client_Data_message] = useState();
    const [Get_Givent_Partner_Client_Data_result, setGet_Givent_Partner_Client_Data_result] = useState();
    function Get_Givent_Partner_Client_Data(local_partner_client_id) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("_id", local_partner_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Client_From_Id/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Givent_Partner_Client_Data  res.data.status = " + res.data.status);
            //console.log(" In Get_Givent_Partner_Client_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_Givent_Partner_Client_Data_api("true");
                setGet_Givent_Partner_Client_Data_result(res.data.message);
                var mylocalclient = JSON.parse(res.data.message);


                if (mylocalclient) {
                    if (mylocalclient.email) {
                        setp_detail_header_email_client(mylocalclient.email);
                    }

                    // Recuperation de l'adresse de facturation
                    if (mylocalclient.invoice_adresse) {
                        setp_detail_header_fact_adr(mylocalclient.invoice_adresse);
                    }

                    if (mylocalclient.invoice_code_postal) {
                        setp_detail_header_fact_code_postal(mylocalclient.invoice_code_postal);
                    }

                    if (mylocalclient.invoice_ville) {
                        setp_detail_header_fact_ville(mylocalclient.invoice_ville);
                    }

                    if (mylocalclient.invoice_pays) {
                        setp_detail_header_fact_pays(mylocalclient.invoice_pays);
                    }


                    // Recuperation de l'adresse du lieu de formation (par defaut adresse de la societe)
                    if (mylocalclient.adr_adresse) {
                        setp_detail_header_liv_adr(mylocalclient.adr_adresse);
                    }

                    if (mylocalclient.adr_code_postal) {
                        setp_detail_header_liv_code_postal(mylocalclient.adr_code_postal);
                    }

                    if (mylocalclient.adr_ville) {
                        sep_detail_header_liv_ville(mylocalclient.adr_ville);
                    }

                    if (mylocalclient.adr_pays) {
                        setp_detail_header_liv_pays(mylocalclient.adr_pays);
                    }
                }
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Givent_Partner_Client_Data_api("false");
                setGet_Givent_Partner_Client_Data_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Givent_Partner_Client_Data = ', error);
            setGet_Givent_Partner_Client_Data_api("false");
            alert('Erreur: Impossible de recuperer les données du client');
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Given_Line_Of_Partner_Order_Line_Data_api, setGet_Given_Line_Of_Partner_Order_Line_Data_api] = useState();
    const [Get_Given_Line_Of_Partner_Order_Line_Data_message, setGet_Given_Line_Of_Partner_Order_Line_Data_message] = useState();
    const [Get_Given_Line_Of_Partner_Order_Line_Data_result, setGet_Given_Line_Of_Partner_Order_Line_Data_result] = useState();
    function Get_Given_Line_Of_Partner_Order_Line_Data(local_order_line_id, local_order_header_ref_interne) {
        setOrder_data_changed("");
        setorder_data_edit_mode("");


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_line_id", local_order_line_id);
        form.append("order_header_ref_interne", local_order_header_ref_interne);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Line_Of_Partner_Order_Lines/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Given_Line_Of_Partner_Order_Line_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Line_Of_Partner_Order_Line_Data  res.data.message r_class = " + res.data.message);
                setGet_Given_Line_Of_Partner_Order_Line_Data_api("true");
                setGet_Given_Line_Of_Partner_Order_Line_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_order = JSON.parse(res.data.message);


                    if (mylocal_order.order_line_formation) {
                        setp_add_line_item(mylocal_order.order_line_formation);
                    }


                    if (mylocal_order.order_line_qty) {
                        setp_add_line_item_qty(mylocal_order.order_line_qty);
                    }



                    if (mylocal_order.order_line_prix_unitaire) {
                        setp_add_line_item_price(mylocal_order.order_line_prix_unitaire);
                    }


                    /*if (mylocal_order.order_header_id) {
                        setp_detail_header_type_reduction(mylocal_order.order_header_id);
                    }

                 
                    if (mylocal_order.order_header_ref_interne) {
                        setp_detail_header_type_reduction_valeur(mylocal_order.order_header_ref_interne);
                    }*/


                    if (mylocal_order.order_line_montant_reduction) {
                        setp_add_line_item_reduction_total_amount(mylocal_order.order_line_montant_reduction);
                    }



                    if (mylocal_order.order_line_montant_toutes_taxes) {
                        setp_add_line_item_total_amount_ttc(mylocal_order.order_line_montant_toutes_taxes);

                    }


                    if (mylocal_order.order_line_tax) {
                        setp_add_line_item_taxe(mylocal_order.order_line_tax);
                    }


                    if (mylocal_order.order_line_tax_amount) {
                        setp_add_line_item_taxe_amount(mylocal_order.order_line_tax_amount);
                    }


                    if (mylocal_order.order_line_type_reduction) {
                        setp_add_line_item_type_reduction(mylocal_order.order_line_type_reduction);
                    }


                    if (mylocal_order.order_line_type_valeur) {
                        setp_add_line_item_reduction_value(mylocal_order.order_line_type_valeur);
                    }


                    if (mylocal_order.order_line_type) {
                        setp_add_line_item_type(mylocal_order.order_line_type);
                    }


                    if (mylocal_order.order_line_status) {
                        setp_add_line_item_status(mylocal_order.order_line_status);
                    }


                    if (mylocal_order.order_line_comment) {
                        setp_add_line_item_comment(mylocal_order.order_line_comment);
                    }


                    if (mylocal_order.title) {
                        setp_add_line_item_title(mylocal_order.title);
                    }

                    if (mylocal_order.order_line_montant_hors_taxes) {
                        setp_add_line_item_total_amount_ht(mylocal_order.order_line_montant_hors_taxes);

                    }


                    /*if (mylocal_order.domaine) {
                        setp_detail_header_montant_reduction(mylocal_order.domaine);
                    }*/


                    if (mylocal_order.duration_concat) {
                        setp_add_line_item_duration_concat(mylocal_order.duration_concat);
                    }


                    ;

                } else {
                    alert(" Aucune ligne commande/devis recuperée");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Given_Line_Of_Partner_Order_Line_Data_api("false");
                setGet_Given_Line_Of_Partner_Order_Line_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Line_Of_Partner_Order_Line_Data = ', error);
            setGet_Given_Line_Of_Partner_Order_Line_Data_api("false");
            alert(" Impossible de recuperer les données de la ligne ");
            //setmyApimyApiMessage("")
        })
    }



    const hiddenFileInput_session = React.useRef(null);

    function submenu_import_order() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }

    const [add_One_Order, setadd_One_Order] = useState();


    function submenu_add_one_order() {
        setp_add_cmd_header_order_type("commande");
        setDialog_Detail_Commande_open(true);

    }


    function submenu_add_one_quotation() {
        setp_add_cmd_header_order_type("devis");
        setDialog_Detail_Commande_open(true);

    }

    function Annule_add_one_Order() {
        setdisplay_detail_order();
        setsubmenu("");
        setSelectionModel([]);
    }

    const [display_detail_order, setdisplay_detail_order] = React.useState();

    const [p_detail_header_client_nom, setp_detail_header_client_nom] = useState();
    const [p_detail_header_client_nom_id, setp_detail_header_client_nom_id] = useState();

    const [p_detail_header_description, setp_detail_header_description] = useState();
    const [p_detail_header_comment, setp_detail_header_comment] = useState();
    const [p_detail_header_date_cmd, setp_detail_header_date_cmd] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_detail_header_date_expiration, setp_detail_header_date_expiration] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_detail_header_condition_paiement, setp_detail_header_condition_paiement] = useState();
    const [p_detail_header_ref_client, setp_detail_header_ref_client] = useState();
    const [p_detail_header_ref_interne, setp_detail_header_ref_interne] = useState();

    const [p_detail_header_email_client, setp_detail_header_email_client] = useState();

    const [p_detail_header_vendeur_nom, setp_detail_header_vendeur_nom] = useState();
    const [p_detail_header_vendeur_nom_id, setp_detail_header_vendeur_nom_id] = useState();

    const [p_detail_header_type, setp_detail_header_type] = useState();
    const [p_detail_header_statut, setp_detail_header_statut] = useState();
    const [p_detail_header_vendeur_id, setp_detail_header_vendeur_id] = useState();
    const [p_detail_header_client_id, setp_detail_header_client_id] = useState();
    const [p_detail_header_statut_label, setp_detail_header_statut_label] = useState();

    const [p_detail_header_date_cmd_val, setp_detail_header_date_cmd_val] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_detail_header_date_expiration_val, setp_detail_header_date_expiration_val] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_detail_header_fact_adr, setp_detail_header_fact_adr] = useState();
    const [p_detail_header_fact_code_postal, setp_detail_header_fact_code_postal] = useState();
    const [p_detail_header_fact_ville, setp_detail_header_fact_ville] = useState();
    const [p_detail_header_fact_pays, setp_detail_header_fact_pays] = useState();


    const [p_detail_header_liv_adr, setp_detail_header_liv_adr] = useState();
    const [p_detail_header_liv_code_postal, setp_detail_header_liv_code_postal] = useState();
    const [p_detail_header_liv_ville, sep_detail_header_liv_ville] = useState();
    const [p_detail_header_liv_pays, setp_detail_header_liv_pays] = useState();

    const [p_detail_header_total_ht, setp_detail_header_total_ht] = useState();
    const [p_detail_header_total_tax, setp_detail_header_total_tax] = useState();
    const [p_detail_header_total_ttc, setp_detail_header_total_ttc] = useState();
    const [p_detail_header_type_reduction, setp_detail_header_type_reduction] = useState();
    const [p_detail_header_total_reduction_amount, setp_detail_header_total_reduction_amount] = useState();
    const [p_detail_header_type_reduction_valeur, setp_detail_header_type_reduction_valeur] = useState();
    const [p_detail_header_montant_reduction, setp_detail_header_montant_reduction] = useState();
    const [p_detail_total_header_hors_taxe_before_header_reduction, setp_detail_total_header_hors_taxe_before_header_reduction] = useState();
    const [p_detail_total_header_hors_taxe_after_header_reduction, setp_detail_total_header_hors_taxe_after_header_reduction] = useState();
    const [p_detail_order_header_tax_amount, setp_detail_order_header_tax_amount] = useState();
    const [p_detail_total_header_toutes_taxes, setp_detail_total_header_toutes_taxes] = useState();



    const [p_detail_total_lines_hors_taxe_before_lines_reduction, setp_detail_total_lines_hors_taxe_before_lines_reduction] = useState();
    const [p_detail_total_lines_hors_taxe_after_lines_reduction, setp_detail_total_lines_hors_taxe_after_lines_reduction] = useState();



    function Enable_Order_Header_DetailFields() {
        setorder_data_edit_mode("1");

        if (document.getElementsByName("detail_header_type")[0]) {
            document.getElementsByName("detail_header_type")[0].disabled = false;
            document.getElementsByName("detail_header_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_status")[0]) {
            document.getElementsByName("detail_header_status")[0].disabled = false;
            document.getElementsByName("detail_header_status")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_ref_interne")[0]) {
            document.getElementsByName("detail_header_ref_interne")[0].disabled = false;
            document.getElementsByName("detail_header_ref_interne")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_order_paiement_condition")[0]) {
            document.getElementsByName("detail_header_order_paiement_condition")[0].disabled = false;
            document.getElementsByName("detail_header_order_paiement_condition")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_date_cmd")[0]) {
            document.getElementsByName("detail_header_date_cmd")[0].disabled = false;
            document.getElementsByName("detail_header_date_cmd")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_date_expiration")[0]) {
            document.getElementsByName("detail_header_date_expiration")[0].disabled = false;
            document.getElementsByName("detail_header_date_expiration")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_nom_client")[0]) {
            document.getElementsByName("detail_nom_client")[0].disabled = false;
            document.getElementsByName("detail_nom_client")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_description")[0]) {
            document.getElementsByName("detail_header_description")[0].disabled = false;
            document.getElementsByName("detail_header_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_comment")[0]) {
            document.getElementsByName("detail_header_comment")[0].disabled = false;
            document.getElementsByName("detail_header_comment")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_vendeur_nom_prenom")[0]) {
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].disabled = false;
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].style.backgroundColor = "#FFFFFF";
        }

        //----
        if (document.getElementsByName("detail_header_ref_cient")[0]) {
            document.getElementsByName("detail_header_ref_cient")[0].disabled = false;
            document.getElementsByName("detail_header_ref_cient")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_email_client")[0]) {
            document.getElementsByName("detail_header_email_client")[0].disabled = false;
            document.getElementsByName("detail_header_email_client")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_fact_adresse")[0]) {
            document.getElementsByName("detail_header_fact_adresse")[0].disabled = false;
            document.getElementsByName("detail_header_fact_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_code_postal")[0]) {
            document.getElementsByName("detail_header_fact_code_postal")[0].disabled = false;
            document.getElementsByName("detail_header_fact_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_ville")[0]) {
            document.getElementsByName("detail_header_fact_ville")[0].disabled = false;
            document.getElementsByName("detail_header_fact_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_pays")[0]) {
            document.getElementsByName("detail_header_fact_pays")[0].disabled = false;
            document.getElementsByName("detail_header_fact_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_adresse")[0]) {
            document.getElementsByName("detail_header_liv_adresse")[0].disabled = false;
            document.getElementsByName("detail_header_liv_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_code_postal")[0]) {
            document.getElementsByName("detail_header_liv_code_postal")[0].disabled = false;
            document.getElementsByName("detail_header_liv_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_ville")[0]) {
            document.getElementsByName("detail_header_liv_ville")[0].disabled = false;
            document.getElementsByName("detail_header_liv_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_pays")[0]) {
            document.getElementsByName("detail_header_liv_pays")[0].disabled = false;
            document.getElementsByName("detail_header_liv_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_ht")[0]) {
            document.getElementsByName("detail_header_total_ht")[0].disabled = false;
            document.getElementsByName("detail_header_total_ht")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_tax")[0]) {
            document.getElementsByName("detail_header_total_tax")[0].disabled = false;
            document.getElementsByName("detail_header_total_tax")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_ttc")[0]) {
            document.getElementsByName("detail_header_total_ttc")[0].disabled = false;
            document.getElementsByName("detail_header_total_ttc")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_type_reduction")[0]) {
            document.getElementsByName("detail_header_type_reduction")[0].disabled = false;
            document.getElementsByName("detail_header_type_reduction")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_type_reduction_valeur")[0]) {
            document.getElementsByName("detail_header_type_reduction_valeur")[0].disabled = false;
            document.getElementsByName("detail_header_type_reduction_valeur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_montant_reduction")[0]) {
            document.getElementsByName("detail_header_montant_reduction")[0].disabled = false;
            document.getElementsByName("detail_header_montant_reduction")[0].style.backgroundColor = "#FFFFFF";
        }


    }

    function disable_Order_Header_DetailFields() {
        setorder_data_edit_mode("0");

        if (document.getElementsByName("detail_header_type")[0]) {
            document.getElementsByName("detail_header_type")[0].disabled = true;
            document.getElementsByName("detail_header_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_status")[0]) {
            document.getElementsByName("detail_header_status")[0].disabled = true;
            document.getElementsByName("detail_header_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_ref_interne")[0]) {
            document.getElementsByName("detail_header_ref_interne")[0].disabled = true;
            document.getElementsByName("detail_header_ref_interne")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_order_paiement_condition")[0]) {
            document.getElementsByName("detail_header_order_paiement_condition")[0].disabled = true;
            document.getElementsByName("detail_header_order_paiement_condition")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_date_cmd")[0]) {
            document.getElementsByName("detail_header_date_cmd")[0].disabled = true;
            document.getElementsByName("detail_header_date_cmd")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_expiration")[0]) {
            document.getElementsByName("detail_header_date_expiration")[0].disabled = true;
            document.getElementsByName("detail_header_date_expiration")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_nom_client")[0]) {
            document.getElementsByName("detail_nom_client")[0].disabled = true;
            document.getElementsByName("detail_nom_client")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_description")[0]) {
            document.getElementsByName("detail_header_description")[0].disabled = true;
            document.getElementsByName("detail_header_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_comment")[0]) {
            document.getElementsByName("detail_header_comment")[0].disabled = true;
            document.getElementsByName("detail_header_comment")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_vendeur_nom_prenom")[0]) {
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].disabled = true;
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        //---
        if (document.getElementsByName("detail_header_ref_cient")[0]) {
            document.getElementsByName("detail_header_ref_cient")[0].disabled = true;
            document.getElementsByName("detail_header_ref_cient")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_email_client")[0]) {
            document.getElementsByName("detail_header_email_client")[0].disabled = true;
            document.getElementsByName("detail_header_email_client")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_fact_adresse")[0]) {
            document.getElementsByName("detail_header_fact_adresse")[0].disabled = true;
            document.getElementsByName("detail_header_fact_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_code_postal")[0]) {
            document.getElementsByName("detail_header_fact_code_postal")[0].disabled = true;
            document.getElementsByName("detail_header_fact_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_ville")[0]) {
            document.getElementsByName("detail_header_fact_ville")[0].disabled = true;
            document.getElementsByName("detail_header_fact_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_pays")[0]) {
            document.getElementsByName("detail_header_fact_pays")[0].disabled = true;
            document.getElementsByName("detail_header_fact_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_adresse")[0]) {
            document.getElementsByName("detail_header_liv_adresse")[0].disabled = true;
            document.getElementsByName("detail_header_liv_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_code_postal")[0]) {
            document.getElementsByName("detail_header_liv_code_postal")[0].disabled = true;
            document.getElementsByName("detail_header_liv_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_ville")[0]) {
            document.getElementsByName("detail_header_liv_ville")[0].disabled = true;
            document.getElementsByName("detail_header_liv_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_pays")[0]) {
            document.getElementsByName("detail_header_liv_pays")[0].disabled = true;
            document.getElementsByName("detail_header_liv_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_ht")[0]) {
            document.getElementsByName("detail_header_total_ht")[0].disabled = true;
            document.getElementsByName("detail_header_total_ht")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_tax")[0]) {
            document.getElementsByName("detail_header_total_tax")[0].disabled = true;
            document.getElementsByName("detail_header_total_tax")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_ttc")[0]) {
            document.getElementsByName("detail_header_total_ttc")[0].disabled = true;
            document.getElementsByName("detail_header_total_ttc")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_type_reduction")[0]) {
            document.getElementsByName("detail_header_type_reduction")[0].disabled = true;
            document.getElementsByName("detail_header_type_reduction")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_type_reduction_valeur")[0]) {
            document.getElementsByName("detail_header_type_reduction_valeur")[0].disabled = true;
            document.getElementsByName("detail_header_type_reduction_valeur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_montant_reduction")[0]) {
            document.getElementsByName("detail_header_montant_reduction")[0].disabled = true;
            document.getElementsByName("detail_header_montant_reduction")[0].style.backgroundColor = "#ECEFF1";
        }


    }

    function Annule_Order_DetailFields() {

        Get_Order_Data(selected_id);
        Get_Partner_Orders_Lines_from_header_id(selected_id);

        setOrder_data_changed("");
        setorder_data_edit_mode("");
        disable_Order_Header_DetailFields();
        order_header_submenu_main();


    }

    const [Update_One_Order_Data_api, setUpdate_One_Order_Data_api] = useState();
    const [Update_One_Order_Data_message, setUpdate_One_Order_Data_message] = useState();
    const [Update_One_Order_Data_result, setUpdate_One_Order_Data_result] = useState();
    function Update_One_Order_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_id", selected_id);
        form.append("order_header_ref_interne", p_detail_header_ref_interne);
        form.append("order_header_type", p_detail_header_type);

        if (p_detail_header_statut)
            form.append("order_header_status", p_detail_header_statut);
        else
            form.append("order_header_status", "0");

        if (p_detail_header_email_client)
            form.append("order_header_email_client", p_detail_header_email_client);
        else
            form.append("order_header_email_client", "");


        if (p_detail_header_client_nom_id)
            form.append("order_header_client_id", p_detail_header_client_nom_id);
        else
            form.append("order_header_client_id", "");


        if (p_detail_header_vendeur_nom_id)
            form.append("order_header_vendeur_id", p_detail_header_vendeur_nom_id);
        else
            form.append("order_header_vendeur_id", "");

        if (p_detail_header_condition_paiement)
            form.append("order_header_condition_paiement", p_detail_header_condition_paiement);
        else
            form.append("order_header_condition_paiement", "");

        if (p_detail_header_date_expiration_val)
            form.append("order_header_date_expiration", p_detail_header_date_expiration);
        else
            form.append("order_header_date_expiration", "");

        if (p_detail_header_date_cmd_val)
            form.append("order_header_date_cmd", p_detail_header_date_cmd_val);
        else
            form.append("order_header_date_cmd", "");

        if (p_detail_header_comment)
            form.append("order_header_comment", p_detail_header_comment);
        else
            form.append("order_header_comment", "");

        if (p_detail_header_description)
            form.append("order_header_description", p_detail_header_description);
        else
            form.append("order_header_description", "");

        if (p_detail_header_ref_client)
            form.append("order_header_ref_client", p_detail_header_ref_client);
        else
            form.append("order_header_ref_client", "");


        // ----
        if (p_detail_header_fact_adr)
            form.append("order_header_adr_fact_adresse", p_detail_header_fact_adr);
        else
            form.append("order_header_adr_fact_adresse", "");

        if (p_detail_header_fact_code_postal)
            form.append("order_header_adr_fact_code_postal", p_detail_header_fact_code_postal);
        else
            form.append("order_header_adr_fact_code_postal", "");


        if (p_detail_header_fact_ville)
            form.append("order_header_adr_fact_ville", p_detail_header_fact_ville);
        else
            form.append("order_header_adr_fact_ville", "");


        if (p_detail_header_fact_pays)
            form.append("order_header_adr_fact_pays", p_detail_header_fact_pays);
        else
            form.append("order_header_adr_fact_pays", "");


        if (p_detail_header_liv_adr)
            form.append("order_header_adr_liv_adresse", p_detail_header_liv_adr);
        else
            form.append("order_header_adr_liv_adresse", "");

        if (p_detail_header_liv_code_postal)
            form.append("order_header_adr_liv_code_postal", p_detail_header_liv_code_postal);
        else
            form.append("order_header_adr_liv_code_postal", "");

        if (p_detail_header_liv_ville)
            form.append("order_header_adr_liv_ville", p_detail_header_liv_ville);
        else
            form.append("order_header_adr_liv_ville", "");

        if (p_detail_header_liv_pays)
            form.append("order_header_adr_liv_pays", p_detail_header_liv_pays);
        else
            form.append("order_header_adr_liv_pays", "");




        //---

        if (p_detail_header_total_ht)
            form.append("order_header_total_ht", p_detail_header_total_ht);
        else
            form.append("order_header_total_ht", "");

        if (p_detail_header_total_tax)
            form.append("order_header_total_tax", p_detail_header_total_tax);
        else
            form.append("order_header_total_tax", "");

        if (p_detail_header_total_ttc)
            form.append("order_header_total_ttc", p_detail_header_total_ttc);
        else
            form.append("order_header_total_ttc", "");


        if (p_detail_header_type_reduction)
            form.append("order_header_type_reduction", p_detail_header_type_reduction);
        else
            form.append("order_header_type_reduction", "");


        if (p_detail_header_type_reduction_valeur)
            form.append("order_header_type_reduction_valeur", p_detail_header_type_reduction_valeur);
        else
            form.append("order_header_type_reduction_valeur", "");


        if (p_detail_header_montant_reduction)
            form.append("order_header_montant_reduction", p_detail_header_montant_reduction);
        else
            form.append("order_header_montant_reduction", "");


        //console.log(" form == ", form);

        var myurl = "";
        if (String(p_detail_header_type) === "commande")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Order_Header/";
        else if (String(p_detail_header_type) === "devis")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Quotation_Header/";
        else {
            alert(" Type de commande inconnu.");
            return;
        }
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Order_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Order_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_One_Order_Data_api("true");
                setUpdate_One_Order_Data_result(res.data.message);

                Getall_Parter_Orders_No_Filter();

                disable_Order_Header_DetailFields();
                setOrder_data_changed("");
                setorder_data_edit_mode("");
                Display_Detail_Order_data(selected_id);
                alert(" La mise à jour du " + String(p_detail_header_type) + " été correctement faite.");

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Order_Data_api("false");
                setUpdate_One_Order_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Order_Data = ' + error);
            setUpdate_One_Order_Data_api("false");
            alert(" Impossible de faire la mise à jour");

        })
    }

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }


    const [test_comment_dialog, settest_comment_dialog] = useState();
    const [Dialog_Detail_Article_open, setDialog_Detail_Article_open] = React.useState(false);

    const Close_Dialog_Detail_Article_open = () => {

        setDialog_Detail_Article_open(false);
    };


    const [Add_Update_One_Order_Line_Data_api, setAdd_Update_One_Order_Line_Data_api] = useState();
    const [Add_Update_One_Order_Line_Data_message, setAdd_Update_One_Order_Line_Data_message] = useState();
    const [Add_Update_One_Order_Line_Data_result, setAdd_Update_One_Order_Line_Data_result] = useState();
    function Add_Update_One_Order_Line_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_line_formation", p_add_line_item_internal_url);
        form.append("order_line_qty", p_add_line_item_qty);
        form.append("order_line_prix_unitaire", p_add_line_item_price);
        form.append("order_line_id", selected_order_line_id);
        form.append("order_header_ref_interne", order_internal_ref);
        form.append("order_header_id", selected_id);
        form.append("order_line_status", p_add_line_item_status);
        form.append("order_line_type", p_add_line_item_type);
        form.append("order_line_comment", p_add_line_item_comment);

        form.append("order_line_type_reduction", p_add_line_item_type_reduction);
        form.append("order_line_type_valeur", p_add_line_item_reduction_value);
        form.append("order_line_montant_reduction", p_add_line_item_reduction_total_amount);
        form.append("order_line_montant_hors_taxes", p_add_line_item_total_amount_ht);

        form.append("order_line_tax", p_add_line_item_taxe);
        form.append("order_line_tax_amount", p_add_line_item_taxe_amount);
        form.append("order_line_montant_toutes_taxes", p_add_line_item_total_amount_ttc);


        // Controle des champs obligatoires
        if (!p_add_line_item || String(p_add_line_item).length < 2) {
            alert(" Vous devez choisir un article ");
            return;
        }

        console.log(" ### p_add_line_item = ", p_add_line_item);

        if (!p_add_line_item_qty || parseFloat(String(p_add_line_item_qty)) <= 0) {
            alert(" La quantité choisie est incorrecte ");
            return;
        }

        if (!p_add_line_item_price || parseFloat(String(p_add_line_item_price)) < 0) {
            alert(" Le prix unitaire est incorrecte ");
            return;
        }

        if (String(p_add_line_item_type_reduction) === "percent" &&
            (parseFloat(String(p_add_line_item_reduction_value)) < 0 || parseFloat(String(p_add_line_item_reduction_value)) > 100)) {
            alert(" Pour une reduction de type 'pourcentage', la valeur doit être comprise entre 0 et 100 ");
            return;
        }

        if (String(p_add_line_item_type_reduction) === "fixe" &&
            (parseFloat(String(p_add_line_item_reduction_value)) < 0 || parseFloat(String(p_add_line_item_reduction_value)) > parseFloat(String(p_add_line_item_price)))) {
            alert(" Pour une reduction de type 'fixe', la valeur ne doit pas excéder le prix unitaire de l'article ");
            return;
        }

        //onsole.log("### form = ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Order_Line/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_One_Order_Line_Data_api("true");
                setAdd_Update_One_Order_Line_Data_result(res.data.message);
                clean_article_detail_fields();

                Display_Detail_Order_data(selected_id);
                alert(" L'article a été correctement ajouté / mis à jour.");
                setDialog_Detail_Article_open(false);

                //myRef_head.current.scrollIntoView({ behavior: "smooth" });

            }
            else {
                setAdd_Update_One_Order_Line_Data_api("false");
                setAdd_Update_One_Order_Line_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_One_Order_Line_Data = ' + error);
            setAdd_Update_One_Order_Line_Data_api("false");
            alert(" Impossible d'ajouter / mettre à jour la ligne");

        })
    }



    const Update_Dialog_Detail_Article_open = () => {
        Add_Update_One_Order_Line_Data();

    };


    const [p_add_line_item, setp_add_line_item] = useState();
    const [p_add_line_item_internal_url, setp_add_line_item_internal_url] = useState();
    const [p_add_line_item_title, setp_add_line_item_title] = useState();
    const [p_add_line_item_qty, setp_add_line_item_qty] = useState();
    const [p_add_line_item_price, setp_add_line_item_price] = useState();
    const [p_add_line_item_comment, setp_add_line_item_comment] = useState();
    const [p_add_line_item_status, setp_add_line_item_status] = useState();
    const [p_add_line_item_type, setp_add_line_item_type] = useState();


    function clean_article_detail_fields() {
        setp_add_line_item();
        setp_add_line_item_internal_url();
        setp_add_line_item_title();
        setp_add_line_item_qty();
        setp_add_line_item_price();
        setp_add_line_item_comment();
        setp_add_line_item_status();
        setp_add_line_item_type();
    }


    function Ajouter_une_ligne_article() {
        setp_add_line_item("");
        setp_add_line_item_qty("");
        setp_add_line_item_price("");
        setp_add_line_item_comment("");
        setp_add_line_item_type_reduction("");
        setp_add_line_item_reduction_value("");
        setp_add_line_item_reduction_total_amount("");
        setp_add_line_item_total_amount_ht("");
        setp_add_line_item_taxe("");
        setp_add_line_item_taxe_amount("");
        setp_add_line_item_total_amount_ttc("");



        setp_add_line_item_status(p_detail_header_statut);
        setp_add_line_item_type(p_detail_header_type);

        setDialog_Detail_Article_open(true);
    }

    const [Dialog_Detail_Commande_open, setDialog_Detail_Commande_open] = useState(false);


    function Dialog_cmd_handleClose() {

    }

    const Dialog_cmd_handleClose_buton = () => {
        setDialog_Detail_Commande_open(false);
    };




    const Close_Dialog_Detail_Order_open = () => {
        clean_order_detail_fields();
        setDialog_Detail_Commande_open(false);
    };


    const [Add_Dialog_Detail_Order_api, setAdd_Dialog_Detail_Order_api] = useState();
    const [Add_Dialog_Detail_Order_message, setAdd_Dialog_Detail_Order_message] = useState();
    const [Add_Dialog_Detail_Order_result, setAdd_Dialog_Detail_Order_result] = useState();
    function Add_Dialog_Detail_Order() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_client_id", p_add_cmd_header_client_id);
        form.append("order_header_description", p_add_cmd_header_description);
        form.append("order_header_comment", p_add_cmd_header_comment);
        form.append("order_header_date_cmd", p_add_cmd_header_date_cmd);
        form.append("order_header_date_expiration", p_add_cmd_header_date_expiration_cmd);
        form.append("order_header_condition_paiement", p_add_cmd_header_condition_paiement);
        form.append("order_header_ref_client", p_add_cmd_header_ref_client);
        form.append("order_header_vendeur_id", p_add_cmd_header_vendeur_id);
        form.append("order_header_type", p_add_cmd_header_order_type);
        form.append("order_header_email_client", p_detail_header_email_client);
        form.append("order_header_adr_fact_adresse", p_detail_header_fact_adr);
        form.append("order_header_adr_fact_code_postal", p_detail_header_fact_code_postal);
        form.append("order_header_adr_fact_ville", p_detail_header_fact_ville);
        form.append("order_header_adr_fact_pays", p_detail_header_fact_pays);
        form.append("order_header_adr_liv_adresse", p_detail_header_liv_adr);
        form.append("order_header_adr_liv_code_postal", p_detail_header_liv_code_postal);
        form.append("order_header_adr_liv_ville", p_detail_header_liv_ville);
        form.append("order_header_adr_liv_pays", p_detail_header_liv_pays);


        if (!p_add_cmd_header_client_id || String(p_add_cmd_header_client_id).length < 2) {
            alert(" Vous devez choisir un client");
            return;
        }
        //console.log("### form = ", form);


        var myurl = ""

        if (String(p_add_cmd_header_order_type) === "devis") {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Quotation/";
        } else if (String(p_add_cmd_header_order_type) === "commande") {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Order/";
        } else {
            alert(" Type de commande inconnu");
            return;
        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Dialog_Detail_Order_api("true");
                setAdd_Dialog_Detail_Order_result(res.data.message);
                clean_order_detail_fields();
                setDialog_Detail_Commande_open(false);
                Getall_Parter_Orders_No_Filter();

                if (String(p_add_cmd_header_order_type) === "devis") {
                    alert(" La devis à jour été correctement ajouté.");
                } else if (String(p_add_cmd_header_order_type) === "commande") {
                    alert(" La commande à jour été correctement ajoutée.");
                }

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Dialog_Detail_Order_api("false");
                setAdd_Dialog_Detail_Order_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Add_Dialog_Detail_Order : Not good man :( Add_Dialog_Detail_Order = ' + error);
            setAdd_Dialog_Detail_Order_api("false");

            if (String(p_add_cmd_header_order_type) === "devis") {
                alert(" Impossible d'ajouter le devis");
            } else if (String(p_add_cmd_header_order_type) === "commande") {
                alert(" La commande d'ajouter la commande.");
            }

        })


    }

    const Dialog_Order_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };


    const [p_add_cmd_header_client_id, setp_add_cmd_header_client_id] = useState("");
    const [p_add_cmd_header_description, setp_add_cmd_header_description] = useState("");
    const [p_add_cmd_header_comment, setp_add_cmd_header_comment] = useState("");
    const [p_add_cmd_header_vendeur_id, setp_add_cmd_header_vendeur_id] = useState(props.conntected_employee_id);
    const [p_add_cmd_header_vendeur_id_label, setp_add_cmd_header_vendeur_id_label] = useState();

    const [p_add_cmd_header_date_cmd, setp_add_cmd_header_date_cmd] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_add_cmd_header_date_expiration_cmd, setp_add_cmd_header_date_expiration_cmd] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_add_cmd_header_order_type, setp_add_cmd_header_order_type] = useState("");
    const [p_add_cmd_header_order_location_type, setp_add_cmd_header_order_location_type] = useState("");
    const [p_add_cmd_header_ref_client, setp_add_cmd_header_ref_client] = useState("");
    const [p_add_cmd_header_condition_paiement, setp_add_cmd_header_condition_paiement] = useState("");


    function clean_order_detail_fields() {
        setp_add_cmd_header_client_id();
        setp_add_cmd_header_description();
        setp_add_cmd_header_comment();
        setp_add_cmd_header_vendeur_id(props.conntected_employee_id);
        setp_add_cmd_header_date_cmd(new Date().toLocaleDateString('fr-FR'));
        setp_add_cmd_header_date_expiration_cmd(date_today_90j.toLocaleDateString('fr-FR'));
        setp_add_cmd_header_order_type();
        setp_add_cmd_header_order_location_type();

        setp_add_cmd_header_ref_client();
        setp_add_cmd_header_condition_paiement();

        setp_detail_header_fact_adr();
        setp_detail_header_fact_code_postal();
        setp_detail_header_fact_ville();
        setp_detail_header_fact_pays();

        setp_detail_header_liv_adr();
        setp_detail_header_liv_code_postal();
        sep_detail_header_liv_ville();
        setp_detail_header_liv_pays();

    }

    const [header_submenu, setheader_submenu] = useState("");
    async function order_header_submenu_main() {
        setheader_submenu("main");

        await sleep(5);

        if (document.getElementById("order_header_main")) {
            document.getElementById("order_header_main").style.backgroundColor = "#104277";
            document.getElementById("order_header_main").style.color = "white";
        }

        if (document.getElementById("order_header_detail")) {
            document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_detail").style.color = "black";
        }

        if (document.getElementById("order_header_facturation")) {
            document.getElementById("order_header_facturation").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_facturation").style.color = "black";
        }

        if (String(order_data_edit_mode) !== "1") {
            disable_Order_Header_DetailFields();
        }
    }

    async function order_header_submenu_detail() {
        setheader_submenu("detail");

        await sleep(5);

        if (document.getElementById("order_header_detail")) {
            document.getElementById("order_header_detail").style.backgroundColor = "#104277";
            document.getElementById("order_header_detail").style.color = "white";
        }

        if (document.getElementById("order_header_main")) {
            document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_main").style.color = "black";
        }

        if (document.getElementById("order_header_facturation")) {
            document.getElementById("order_header_facturation").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_facturation").style.color = "black";
        }
        if (String(order_data_edit_mode) !== "1") {
            disable_Order_Header_DetailFields();
        }
    }

    async function order_header_submenu_facturation() {
        setheader_submenu("facturation");

        await sleep(5);

        if (document.getElementById("order_header_facturation")) {
            document.getElementById("order_header_facturation").style.backgroundColor = "#104277";
            document.getElementById("order_header_facturation").style.color = "white";
        }

        if (document.getElementById("order_header_detail")) {
            document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_detail").style.color = "black";
        }

        if (document.getElementById("order_header_main")) {
            document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_main").style.color = "black";
        }

        if (String(order_data_edit_mode) !== "1") {
            disable_Order_Header_DetailFields();
        }
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const [p_add_line_item_type_reduction, setp_add_line_item_type_reduction] = useState("fixe");
    const [p_add_line_item_reduction_value, setp_add_line_item_reduction_value] = useState();
    const [p_add_line_item_reduction_total_amount, setp_add_line_item_reduction_total_amount] = useState();
    const [p_add_line_item_total_amount_ht, setp_add_line_item_total_amount_ht] = useState();
    const [p_add_line_item_taxe, setp_add_line_item_taxe] = useState();
    const [p_add_line_item_taxe_amount, setp_add_line_item_taxe_amount] = useState();
    const [p_add_line_item_total_amount_ttc, setp_add_line_item_total_amount_ttc] = useState();
    const [p_add_line_item_duration_concat, setp_add_line_item_duration_concat] = useState();


    function Recalcul_Item_Amounts() {
        var qty = parseFloat(document.getElementById("ref_article_qty").value);
        var unit_price = parseFloat(document.getElementById("ref_article_unit_price").value);
        var total_base1 = (unit_price * qty).toFixed(3);
        var total_reduction = 0;



        var reduct_type = document.getElementById("ref_article_type_reduction").value;
        var reduct_value = document.getElementById("ref_article_reduction_value").value;

        //var reduct_type = p_add_line_item_type_reduction;

        console.log(" reduct_type = ", reduct_type);
        console.log(" p_add_line_item_type_reduction = ", p_add_line_item_type_reduction);

        if (String(p_add_line_item_type_reduction) === "fixe") {
            console.log("fixe : p_add_line_item_reduction_value = ", reduct_value);
            total_reduction = (parseFloat(String(reduct_value)) * qty).toFixed(3);



        } if (String(p_add_line_item_type_reduction) === "percent") {
            console.log("percent p_add_line_item_reduction_value = ", reduct_value);
            total_reduction = ((unit_price * parseFloat(String(reduct_value)) * qty) / 100).toFixed(3);

        }



        console.log(" ### qty = ", qty, " ### unit_price = ", unit_price, " ### total_base1 = ", total_base1, " ### total_reduction = ",
            total_reduction, " ### reduct_value = ", reduct_value);

        var montant_taxe_tva = ((total_base1 - total_reduction) * [parseFloat(String(partner_taux_tva)) / 100]).toFixed(3);
        var montant_ttc = ((total_base1 - total_reduction) * (1 + [parseFloat(String(partner_taux_tva)) / 100])).toFixed(3); //(total_base2 + montant_taxe_tva).toFixed(2);

        setp_add_line_item_reduction_total_amount(String(total_reduction));
        setp_add_line_item_total_amount_ht(String(total_base1));
        setp_add_line_item_taxe("tva");
        setp_add_line_item_taxe_amount(String(montant_taxe_tva));
        setp_add_line_item_total_amount_ttc(String(montant_ttc));

        console.log(" ### montant tva = ", String(montant_taxe_tva), " ### montant TTC = ", String(montant_ttc));
    }



    function Compute_Order_Header_Price() {

        if (String(Order_data_changed) === "1") {
            alert(" Merci d'enregistrer les modification avant de lancer le calcul");
        }

        Compute_Partner_Order();


    }

    const [Compute_Partner_Order_api, setCompute_Partner_Order_api] = useState();
    const [Compute_Partner_Order_message, setCompute_Partner_Order_message] = useState();
    const [Compute_Partner_Order_result, setCompute_Partner_Order_result] = useState();
    function Compute_Partner_Order(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("_id", selected_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Compute_Order_Header/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Compute_Partner_Order  res.data.status = " + res.data.status);
            //console.log(" In Compute_Partner_Order  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setCompute_Partner_Order_api("true");
                setCompute_Partner_Order_result(res.data.message);
                Get_Order_Data(selected_id);
                Get_Partner_Orders_Lines_from_header_id(selected_id);

                alert(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCompute_Partner_Order_api("false");
                setCompute_Partner_Order_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Compute_Partner_Order = ', error);
            setCompute_Partner_Order_api("false");
            alert('Erreur: Impossible de mettre à jour les prix');
            //setmyApimyApiMessage("")
        })
    }



    function print_order_pdf() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Order_" + order_internal_ref + ".pdf";
        console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/GerneratePDF_Partner_Order/" + stored_cookie + "/" + selected_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
        setLoading(true);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fichier_cmd)
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);

            });
    }


    const [Send_Order_By_Email_api, setSend_Order_By_Email_api] = useState();
    const [Send_Order_By_Email_message, setSend_Order_By_Email_message] = useState();
    const [Send_Order_By_Email_result, setSend_Order_By_Email_result] = useState();
    function Send_Order_By_Email() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_id", selected_id);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Send_Partner_Order_By_Email/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setSend_Order_By_Email_api("true");
                setSend_Order_By_Email_message(res.data.message);
                alert(res.data.message);
            }
            else {
                setSend_Order_By_Email_api("false");
                setSend_Order_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Send_Order_By_Email : Not good man :( Send_Order_By_Email = ' + error);
            setSend_Order_By_Email_api("false");

            if (String(p_add_cmd_header_order_type) === "devis") {
                alert(" Impossible d'envoyer le devis par email");
            } else if (String(p_add_cmd_header_order_type) === "commande") {
                alert(" Impossible d'envoyer la commande par email");
            }

        })


    }


    const [Confirm_Order_Data_api, setConfirm_Order_Data_api] = useState();
    const [Confirm_Order_Data_message, setConfirm_Order_Data_message] = useState();
    const [Confirm_Order_Data_result, setConfirm_Order_Data_result] = useState();
    function Confirm_Order_Data() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_id", selected_id);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Confirm_Partner_Order_Header_And_Lines/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setConfirm_Order_Data_api("true");
                setConfirm_Order_Data_message(res.data.message);
                Getall_Training_Employee_No_Filter();
                Display_Detail_Order_data(selected_id);
                alert(res.data.message);
            }
            else {
                setConfirm_Order_Data_api("false");
                setConfirm_Order_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Confirm_Order_Data : Not good man :( Confirm_Order_Data = ' + error);
            setConfirm_Order_Data_api("false");

            if (String(p_add_cmd_header_order_type) === "devis") {
                alert(" Impossible de confirmer le devis");
            } else if (String(p_add_cmd_header_order_type) === "commande") {
                alert(" Impossible de confirmer la commande");
            }

        })


    }



    const [Make_Order_Ready_To_Invoice_api, setMake_Order_Ready_To_Invoice_api] = useState();
    const [Make_Order_Ready_To_Invoice_message, setMake_Order_Ready_To_Invoice_message] = useState();
    const [Make_Order_Ready_To_Invoice_result, setMake_Order_Ready_To_Invoice_result] = useState();
    function Make_Order_Ready_To_Invoice() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_id", selected_id);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Order_Ready_To_Invoice/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setMake_Order_Ready_To_Invoice_api("true");
                setMake_Order_Ready_To_Invoice_message(res.data.message);
                Getall_Training_Employee_No_Filter();
                Display_Detail_Order_data(selected_id);
                alert(res.data.message);
            }
            else {
                setMake_Order_Ready_To_Invoice_api("false");
                setMake_Order_Ready_To_Invoice_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Make_Order_Ready_To_Invoice : Not good man :( Make_Order_Ready_To_Invoice = ' + error);
            setMake_Order_Ready_To_Invoice_api("false");

            if (String(p_add_cmd_header_order_type) === "devis") {
                alert(" Impossible de confirmer le devis");
            } else if (String(p_add_cmd_header_order_type) === "commande") {
                alert(" Impossible de confirmer la commande");
            }

        })


    }

    const [Cancel_Order_Data_api, setCancel_Order_Data_api] = useState();
    const [Cancel_Order_Data_message, setCancel_Order_Data_message] = useState();
    const [Cancel_Order_Data_result, setCancel_Order_Data_result] = useState();
    function Cancel_Order_Data() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_id", selected_id);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Annule_Partner_Order_Header_And_Lines/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setCancel_Order_Data_api("true");
                setCancel_Order_Data_message(res.data.message);
                Getall_Training_Employee_No_Filter();
                Display_Detail_Order_data(selected_id);
                alert(res.data.message);
            }
            else {
                setCancel_Order_Data_api("false");
                setCancel_Order_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Cancel_Order_Data : Not good man :( Cancel_Order_Data = ' + error);
            setCancel_Order_Data_api("false");

            if (String(p_add_cmd_header_order_type) === "devis") {
                alert(" Impossible d'annuler le devis par email");
            } else if (String(p_add_cmd_header_order_type) === "commande") {
                alert(" Impossible d'annler la commande ");
            }

        })


    }

    const [Convert_Quotation_To_Order_Data_api, setConvert_Quotation_To_Order_Data_api] = useState();
    const [Convert_Quotation_To_Order_Data_message, setConvert_Quotation_To_Order_Data_message] = useState();
    const [Convert_Quotation_To_Order_Data_result, setConvert_Quotation_To_Order_Data_result] = useState();
    function Convert_Quotation_To_Order_Data() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_id", selected_id);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Convert_Quotation_to_Order/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setConvert_Quotation_To_Order_Data_api("true");
                setConvert_Quotation_To_Order_Data_message(res.data.message);
                clean_all_filters();
                Getall_Parter_Orders_No_Filter();

                Display_Detail_Order_data(selected_id);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


                alert(res.data.message);
            }
            else {
                setConvert_Quotation_To_Order_Data_api("false");
                setConvert_Quotation_To_Order_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.warn('Convert_Quotation_To_Order_Data : Not good man :( Convert_Quotation_To_Order_Data = ' + error);
            setConvert_Quotation_To_Order_Data_api("false");
            alert(" Impossible de convertir le devis en commande  ");
        })


    }

    const [Invoice_Order_Data_api, setInvoice_Order_Data_api] = useState();
    const [Invoice_Order_Data_message, setInvoice_Order_Data_message] = useState();
    const [Invoice_Order_Data_result, setInvoice_Order_Data_result] = useState();
    function Invoice_Order_Data() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_id", selected_id);
        form.append("order_ref_interne", order_internal_ref);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Invoice_Partner_Order/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setInvoice_Order_Data_api("true");
                setInvoice_Order_Data_message(res.data.message);
                clean_all_filters();
                Getall_Parter_Orders_No_Filter();

                Display_Detail_Order_data(selected_id);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


                alert(res.data.message);
            }
            else {
                setInvoice_Order_Data_api("false");
                setInvoice_Order_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.warn('Invoice_Order_Data : Not good man :( Invoice_Order_Data = ' + error);
            setInvoice_Order_Data_api("false");
            alert(" Impossible de facturer la commande  ");
        })


    }

    const [Get_Class_Data_api, setGet_Class_Data_api] = useState();
    const [Get_Class_Data_message, setGet_Class_Data_message] = useState();
    const [Get_Class_Data_result, setGet_Class_Data_result] = useState();
    function Get_Class_Data(local_internal_url) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("internal_url", local_internal_url);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Class_From_Internal_Url/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_Class_Data_api("true");
                setGet_Class_Data_message(res.data.message);

                if (JSON.parse(res.data.message).price) {
                    setp_add_line_item_price(String(JSON.parse(res.data.message).price));
                    document.getElementsByName("ref_article_unit_price")[0].value = String(JSON.parse(res.data.message).price);
                }


            }
            else {
                setGet_Class_Data_api("false");
                setGet_Class_Data_message(res.data.message);

                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.warn('Get_Class_Data : Not good man :( Get_Class_Data = ' + error);
            setGet_Class_Data_api("false");
            alert(" Impossible de récuperer les données de la formation  ");
        })


    }

    const [isLoading, setLoading] = useState();
    const [gridline_id, setgridline_id] = useState("");


    return (

        <div className="partner_commande">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>



            <Dialog
                open={Dialog_Detail_Article_open}
                onClose={Dialog_1_handleClose}
                className="partner_commande"
            >

                <DialogTitle>Détail ligne</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edition d'un ligne de commande / devis

                    </DialogContentText>

                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="session_caract_Dialog" > Article

                        <Autocomplete
                            disablePortal
                            name="ref_article"
                            id="ref_article"
                            fullWidth
                            options={New_GetCurrentPartnerClass_result}
                            onChange={(event, value) => {
                                // setp_champ_table(value.nom_champ_technique);
                                setp_add_line_item(value.internal_url);
                                setp_add_line_item_internal_url(value.internal_url);
                                Get_Class_Data(value.internal_url);
                                setp_add_line_item_title(value.title);
                            }}


                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />

                        {/*<TextField
                            required
                            autoFocus
                            margin="dense"
                            id="ref_article"
                            //label="Article"
                            select
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item}
                            onChange={(e) => {
                                setp_add_line_item(e.target.value);
                                setp_add_line_item_internal_url(e.target.value);
                                Get_Class_Data(e.target.value);

                                const div1 = document.getElementById("mysy_item_class");

                                const mysy_item_class_title = div1.getAttribute('mysy_item_class_title');
                                setp_add_line_item_title(mysy_item_class_title);

                                //console.log(" Selected title = ", mysy_item_class_title);
                            }
                            }
                        >
                            {GetCurrentPartnerClass_result &&
                                GetCurrentPartnerClass_result.map((myclass) => (
                                    <MenuItem key={JSON.parse(myclass).internal_url} value={JSON.parse(myclass).internal_url} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}
                                        id="mysy_item_class"
                                        mysy_item_class_title={JSON.parse(myclass).title}
                                        mysy_item_class_default_price={JSON.parse(myclass).price}
                                    >
                                        {JSON.parse(myclass).title} - {JSON.parse(myclass).price}€

                                    </MenuItem>
                                ))}
                        </TextField>*/}

                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="session_caract_Dialog" > Article

                        <TextField
                            autoFocus
                            margin="dense"
                            id="ref_article"
                            //label="Article"
                            disabled
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item}

                        />


                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="session_caract_Dialog" > Quantité

                        <TextField

                            margin="dense"
                            id="ref_article_qty"
                            //label="Quantité"
                            //type="number"
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_qty}

                            disabled
                            type="text"

                        />

                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="session_caract_Dialog" > Quantité

                        <TextField
                            required
                            margin="dense"
                            id="ref_article_qty"
                            //label="Quantité"
                            //type="number"
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_qty}
                            onChange={(e) => {
                                setp_add_line_item_qty(e.target.value);
                                Recalcul_Item_Amounts();
                            }
                            }
                            type="number"

                        />

                    </div>}


                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="session_caract_Dialog" > Prix Unitaire
                        <TextField
                            required
                            margin="dense"
                            id="ref_article_unit_price"
                            name="ref_article_unit_price"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_price}
                            onChange={(e) => {
                                setp_add_line_item_price(e.target.value);
                                Recalcul_Item_Amounts();
                            }
                            }

                            type="number"

                        />
                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="session_caract_Dialog" > Prix Unitaire
                        <TextField

                            margin="dense"
                            id="ref_article_unit_price"
                            name="ref_article_unit_price"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_price}
                            disabled
                            type="text"
                        />
                    </div>}

                    <div className="session_caract_Dialog" > Taux TVA
                        <TextField

                            margin="dense"
                            id="ref_article_taux_tva"
                            name="ref_article_taux_tva"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={partner_taux_tva}
                            disabled
                            type="text"
                        />
                    </div>

                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="session_caract_Dialog" > Type reduction
                        <TextField

                            margin="dense"
                            id="ref_article_type_reduction"
                            //label="Type reduction"
                            select
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_type_reduction}
                            onChange={(e) => {
                                setp_add_line_item_type_reduction(e.target.value);
                                //console.log(" ## e.target.value = ", e.target.value);
                                setp_add_line_item_reduction_value("0");
                                setp_add_line_item_reduction_total_amount('0');
                                setp_add_line_item_reduction_value('0');
                                setp_add_line_item_total_amount_ht('0');

                            }
                            }

                        >
                            <MenuItem value="fixe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Montant &nbsp;</MenuItem>
                            <MenuItem value="percent" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Pourcentage &nbsp;</MenuItem>

                        </TextField>
                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="session_caract_Dialog" > Type reduction
                        <TextField

                            margin="dense"
                            id="ref_article_type_reduction"
                            //label="Type reduction"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_type_reduction}
                            disabled

                        />

                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="session_caract_Dialog" > Reduction
                        <TextField

                            margin="dense"
                            id="ref_article_reduction_value"
                            //label="Reduction"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_reduction_value}
                            onChange={(e) => {

                                setp_add_line_item_reduction_value(e.target.value);
                                Recalcul_Item_Amounts();
                            }
                            }

                            type="number"
                        />

                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="session_caract_Dialog" > Reduction
                        <TextField

                            margin="dense"
                            id="ref_article_reduction_value"
                            //label="Reduction"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_reduction_value}
                            disabled
                            type="text"
                        />

                    </div>}

                    <div className="session_caract_Dialog" > Total Reduction
                        <TextField

                            margin="dense"
                            id="ref_article_reduction_total_amount"
                            //label="Total Reduction"

                            fullWidth
                            disabled
                            //variant="standard"
                            value={p_add_line_item_reduction_total_amount}

                        />

                    </div>

                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="ref_article_comment"
                            id="ref_article_comment"

                            fullWidth

                            value={p_add_line_item_comment}
                            onChange={(e) => {
                                setp_add_line_item_comment(e.target.value);
                            }
                            }
                            //label="Commentaire"
                            //variant="standard"
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}


                        />

                    </div>}

                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="ref_article_comment"
                            id="ref_article_comment"

                            fullWidth

                            value={p_add_line_item_comment}

                            //label="Commentaire"
                            //variant="standard"
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}
                            disabled

                        />

                    </div>}

                    <div className="session_caract_Dialog" > Total HT
                        <TextField

                            margin="dense"
                            id="ref_article_total_amount_ht"
                            //label="Total HT"
                            type="text"
                            fullWidth
                            //variant="standard"
                            disabled={true}
                            value={p_add_line_item_total_amount_ht}

                        />

                    </div>

                    {/* <div className="session_caract" style={{ "width": "100%", "height": "5rem" }}> Total HT

                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="ref_article_total_amount_ht"
                            id="ref_article_total_amount_ht"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            fullWidth
                            className="disabled_style_Dialog"
                            value={p_add_line_item_total_amount_ht}
                        />
                        </div>*/}




                </DialogContent>

                <DialogActions>

                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Update_Dialog_Detail_Article_open} className="bton_enreg_dialog">Mettre à jour</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Article_open} className="bton_annule_dialog" >Annuler</Button>
                        </div>

                    </div>}
                    {String(p_detail_header_statut_label).toLowerCase() === "facturé" && <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Article_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                    </div>}

                </DialogActions>
            </Dialog>


            <Dialog
                open={Dialog_Detail_Commande_open}
                onClose={Dialog_Order_handleClose}
                className="partner_commande"
            >

                <DialogTitle>Nouvelle Commande</DialogTitle>
                <DialogContent>

                    <div className="session_caract_Dialog" > Client
                        <Autocomplete
                            disablePortal
                            id="ref_client"
                            className="session_caract_Dialog"
                            fullWidth
                            options={New_Get_List_Partner_Clients_result}
                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setp_add_cmd_header_client_id(value._id);
                                    Get_Givent_Partner_Client_Data(value._id);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />


                        {/*<TextField
                            required
                            autoFocus
                            margin="dense"
                            id="ref_client"
                            //label="Client"
                            select
                            fullWidth
                            //variant="standard"
                            value={p_add_cmd_header_client_id}
                            onChange={(e) => {
                                setp_add_cmd_header_client_id(e.target.value);
                                Get_Givent_Partner_Client_Data(e.target.value);
                            }
                            }
                        >
                            {Get_List_Partner_Clients_result &&
                                Get_List_Partner_Clients_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient).nom} value={JSON.parse(myclient)._id}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "height": "3rem" }}>
                                        {JSON.parse(myclient).nom}&nbsp;&nbsp;

                                    </MenuItem>
                                ))}
                        </TextField>*/}
                    </div>


                    <div className="div_row" style={{ "width": "100%", }}>

                        <div className="session_caract" style={{ "textAlign": "left", "width": "50%", "height": "5rem", "float": "left" }}>Date cmd

                            <DatePicker
                                name="date_order"
                                id="date_order"
                                selected={new Date(moment(p_add_cmd_header_date_cmd, "DD/MM/YYYY HH:mm:ss"))}
                                onChange={(date) => {
                                    setp_add_cmd_header_date_cmd(format(date, 'dd/MM/yyyy'));

                                }
                                }
                                showTimeSelect={false}
                                //filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style enable_style"
                                style={{ "width": "100%" }}
                                locale='fr-FR'
                                fullWidth
                            />
                        </div>

                        <div className="session_caract" style={{ "textAlign": "left", "width": "50%", "height": "5rem", "float": "right" }}>Date expiration <br />

                            <DatePicker
                                name="date_order_exp"
                                id="date_order_exp"
                                label="expidate"
                                selected={new Date(moment(p_add_cmd_header_date_expiration_cmd, "DD/MM/YYYY HH:mm:ss"))}
                                onChange={(date) => {
                                    setp_add_cmd_header_date_expiration_cmd(format(date, 'dd/MM/yyyy'));

                                }
                                }
                                showTimeSelect={false}
                                //filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style enable_style"

                                locale='fr-FR'
                                fullWidth
                            />
                        </div>
                    </div>

                    <div className="session_caract_Dialog" > Description <br />
                        <TextField

                            margin="dense"
                            id="order_description"

                            type="text"
                            fullWidth
                            //variant="standard"
                            value={p_add_cmd_header_description}
                            onChange={(e) => {
                                setp_add_cmd_header_description(e.target.value);

                            }
                            }
                        />
                    </div>

                    <div className="session_caract_Dialog" > Ref. externe <br />
                        <TextField

                            margin="dense"
                            id="order_ref_client"

                            type="text"
                            fullWidth

                            value={p_add_cmd_header_ref_client}
                            onChange={(e) => {
                                setp_add_cmd_header_ref_client(e.target.value);

                            }
                            }
                        />
                    </div>

                    <div className="session_caract_Dialog" > Vendeur <br />

                        <Autocomplete
                            disablePortal
                            name="ref_vendeur"
                            id="ref_vendeur"
                            fullWidth
                            options={New_Getall_Training_Employee_No_Filter_result}
                            value={p_add_cmd_header_vendeur_id_label}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_add_cmd_header_vendeur_id(value._id);
                                    setp_add_cmd_header_vendeur_id_label(value.label);


                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />

                        {/*<TextField

                            margin="dense"
                            id="ref_vendeur"

                            select
                            fullWidth

                            value={p_add_cmd_header_vendeur_id}
                            onChange={(e) => {
                                setp_add_cmd_header_vendeur_id(e.target.value);

                            }
                            }
                        >
                            {Getall_Training_Employee_No_Filter_result &&
                                Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                        {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                    </MenuItem>
                                ))}
                        </TextField>*/}
                    </div>

                    <div className="session_caract_Dialog" > Conditions Paiement <br />

                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            name="order_paiement_condition"
                            id="order_paiement_condition"

                            fullWidth
                            value={p_add_cmd_header_condition_paiement}
                            onChange={(e) => {
                                setp_add_cmd_header_condition_paiement(e.target.value);

                            }
                            }


                        />

                    </div>

                    <div className="session_caract_Dialog" > Type <br />

                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            name="order_type"
                            id="order_type"

                            fullWidth
                            value={p_add_cmd_header_order_type}
                            disabled

                        />


                    </div>
                    <div className="session_caract_Dialog" > Emplacement <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            name="order_header_location_type"
                            id="order_header_location_type"

                            fullWidth
                            value={p_add_cmd_header_order_location_type}
                            onChange={(e) => {
                                setp_add_cmd_header_order_location_type(e.target.value);

                            }
                            }

                            select

                        >
                            <MenuItem value="inter" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Inter &nbsp;</MenuItem>
                            <MenuItem value="intra" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Intra  &nbsp;</MenuItem>
                            <MenuItem value="" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Autre  &nbsp;</MenuItem>

                        </TextField>

                    </div>
                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            name="order_commentaire"
                            id="order_commentaire"

                            fullWidth
                            multiline
                            rows={2}
                            value={p_add_cmd_header_comment}
                            onChange={(e) => {
                                setp_add_cmd_header_comment(e.target.value);

                            }
                            }


                        />
                    </div>
                    <div className="div_row"> &nbsp;</div>

                </DialogContent>

                <DialogActions>

                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Add_Dialog_Detail_Order} className="bton_enreg_dialog">Ajouter</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Order_open} className="bton_annule_dialog" >Annuler</Button>
                        </div>

                    </div>

                </DialogActions>
            </Dialog>


            <h3> Vos Commandes et Devis </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="ref_interne" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Interne &nbsp;</MenuItem>
                            <MenuItem value="ref_externe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Externe (chez le client) &nbsp;</MenuItem>
                            <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom Client  &nbsp;</MenuItem>
                            <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>


                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value);

                                }}
                            >
                                <MenuItem value="ref_interne" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Interne &nbsp;</MenuItem>
                                <MenuItem value="ref_externe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Externe (chez le client) &nbsp;</MenuItem>
                                <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom Client  &nbsp;</MenuItem>
                                <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre3"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre3}
                                onChange={(e) => {
                                    setp_filtre3(e.target.value)
                                }}
                            >
                                <MenuItem value="ref_interne" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Interne &nbsp;</MenuItem>
                                <MenuItem value="ref_externe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Externe (chez le client) &nbsp;</MenuItem>
                                <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom Client  &nbsp;</MenuItem>
                                <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3();
                                    setp_filtre3_value();
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Parter_Orders_With_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule "statut"
                                    '& .cell--status--encours': {
                                        backgroundColor: '#E6F7C8',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--status--traite': {
                                        backgroundColor: '#E6CEAA',
                                        color: '#F9E79F',
                                        fontWeight: 'light',
                                    },




                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        if (newSelectionModel.length === 1)
                                            handleClick_edit_order_From_Line(newSelectionModel);
                                        if (newSelectionModel.length !== 1) {
                                            clean_order_detail_fields();
                                            setsubmenu();
                                            setdisplay_detail_order();
                                            setadd_One_Order();

                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            order_header_ref_interne: JSON.parse(item).order_header_ref_interne,
                                            order_header_type: JSON.parse(item).order_header_type,
                                            order_header_status: JSON.parse(item).order_header_status,
                                            order_header_client_id: JSON.parse(item).order_header_client_id,
                                            order_header_client_nom: JSON.parse(item).order_header_client_nom,
                                            order_header_vendeur_id: JSON.parse(item).order_header_vendeur_id,
                                            order_header_vendeur_nom_prenom: JSON.parse(item).order_header_vendeur_nom_prenom,
                                            order_header_date_cmd: JSON.parse(item).order_header_date_cmd,
                                            order_header_date_expiration: JSON.parse(item).order_header_date_expiration,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {


                                        //field === "order_header_status"
                                        if (params.field === "order_header_status" && String(params.value) == "0") {
                                            return 'cell--status--brouillon';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "1") {

                                            return 'cell--status--encours';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "2") {
                                            return 'cell--status--traite';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "3") {
                                            return 'cell--status--facture';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        {/*<Button variant="outlined" onClick={submenu_import_order} className="detail_class_submenu bton_import_excel"
                            id='menu_import_participant' name='menu_import_participant'>Importer des commande / devis Excel &nbsp;
                            <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                                </Button>*/}


                        <Button variant="outlined" onClick={submenu_add_one_order}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Commande &nbsp;
                            <img src={add_plus} alt="ajout commande" className="icon_plus" />
                        </Button>

                        <Button variant="outlined" onClick={submenu_add_one_quotation}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Devis &nbsp;
                            <img src={add_plus} alt="ajout devis" className="icon_plus" />
                        </Button>

                        <br />
                        {session_file_name && <nav><i>{session_file_name}</i></nav>}
                        {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les commandes/devis ont été correctement importés</nav>}
                        {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                        <input type="file"
                            accept=".csv"
                            ref={hiddenFileInput_session}
                            style={{ display: 'none' }}
                            name="liste_sessions_file"
                            onChange={liste_sessions_file_change}
                        />

                        <a href='/sample/template_import_employes.csv' download>Télécharger un fichier modèle</a>
                    </div>
                </div>
                <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>


                {String(submenu) === "detail_order" && String(add_One_Order) !== "1" && <div className="div_row">
                    <nav style={{ "fontSize": "22px", "fontWeight": '600' }}> {selected_order_header_type}  <i> {order_internal_ref} </i>: Affichage detaillé </nav>
                    <div>
                        <div className="session_data">

                            <div className="div_row" style={{ "textAlign": "right", "paddingTop": "10px" }}>
                                <Button variant="outlined" onClick={order_header_submenu_main} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Menu Commande</Button>
                                <Button variant="outlined" onClick={order_header_submenu_detail} className="detail_class_submenu" id='order_header_detail' name='order_header_detail'>Menu Total  </Button>
                                <Button variant="outlined" onClick={order_header_submenu_facturation} className="detail_class_submenu" id='order_header_facturation' name='order_header_facturation'>Menu Facturation  </Button>

                            </div>
                            <div style={{ "width": "100%", "float": "left" }}>
                                <div style={{ "width": "75%", "float": "left" }}>
                                    {String(header_submenu) !== "detail" && String(header_submenu) !== "facturation" && <div>
                                        <div className="session_caract"> Type<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type"
                                                id="detail_header_type"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_type}
                                                onChange={(e) => {
                                                    setp_detail_header_type(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract"> Statut<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_status"
                                                id="detail_header_status"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_statut_label}
                                                onChange={(e) => {
                                                    setp_detail_header_statut(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>}
                                        {String(order_data_edit_mode) === "1" && String(p_detail_header_type) === "commande" && <div className="session_caract"> Statut<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_status"
                                                id="detail_header_status"
                                                select
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_statut}
                                                onChange={(e) => {
                                                    setp_detail_header_statut(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            >
                                                <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Brouillon &nbsp;&nbsp;</MenuItem>
                                                <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >En cours &nbsp;</MenuItem>
                                                <MenuItem value="2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Traité &nbsp;</MenuItem>

                                                <MenuItem value="-1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Annulé &nbsp;</MenuItem>
                                            </TextField>

                                        </div>}
                                        {String(order_data_edit_mode) === "1" && String(p_detail_header_type) === "devis" && <div className="session_caract"> Statut<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_status"
                                                id="detail_header_status"
                                                select
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_statut}
                                                onChange={(e) => {
                                                    setp_detail_header_statut(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            >
                                                <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Brouillon &nbsp;&nbsp;</MenuItem>
                                                <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >En cours &nbsp;</MenuItem>
                                                <MenuItem value="2" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Fermé &nbsp;</MenuItem>
                                                <MenuItem value="-1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Annulé &nbsp;</MenuItem>
                                            </TextField>

                                        </div>}

                                        <div className="session_caract"> Ref. Interne<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_ref_interne"
                                                id="detail_header_ref_interne"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_ref_interne}
                                                onChange={(e) => {
                                                    setp_detail_header_ref_interne(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        {String(order_data_edit_mode) === "1" && <div className="session_caract" >
                                            Conditions Paiement <br />

                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_order_paiement_condition"
                                                id="detail_header_order_paiement_condition"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_condition_paiement}
                                                onChange={(e) => {
                                                    setp_detail_header_condition_paiement(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />

                                        </div>}
                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract" > Conditions Paiement <br />

                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_order_paiement_condition"
                                                id="detail_header_order_paiement_condition"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_condition_paiement}


                                            />
                                        </div>}


                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract"> Date Cmd<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_date_cmd"
                                                id="detail_header_date_cmd"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_date_cmd}


                                            />
                                        </div>}

                                        {String(order_data_edit_mode) === "1" && <div className="session_caract"> Date Cmd<br />
                                            <DatePicker
                                                name="detail_header_date_cmd"
                                                id="detail_header_date_cmd"
                                                selected={new Date(moment(p_detail_header_date_cmd_val, "DD/MM/YYYY HH:mm:ss"))}
                                                onChange={(date) => {
                                                    setp_detail_header_date_cmd_val(format(date, 'dd/MM/yyyy'));
                                                    setOrder_data_changed("1");
                                                }
                                                }
                                                showTimeSelect={false}
                                                //filterTime={filterPassedTime_start}
                                                dateFormat="dd/MM/yyyy"
                                                className="disabled_style enable_style"
                                                locale='fr-FR'
                                            />
                                        </div>}


                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract"> Date expiration<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_date_expiration"
                                                id="detail_header_date_expiration"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_date_expiration}


                                            />
                                        </div>}

                                        {String(order_data_edit_mode) === "1" && <div className="session_caract"> Date expiration <br />
                                            <DatePicker
                                                name="detail_header_date_expiration"
                                                id="detail_header_date_expiration"
                                                selected={new Date(moment(p_detail_header_date_expiration, "DD/MM/YYYY HH:mm:ss"))}
                                                onChange={(date) => {
                                                    setp_detail_header_date_expiration_val(format(date, 'dd/MM/yyyy'));
                                                    setp_detail_header_date_expiration(format(date, 'dd/MM/yyyy'));
                                                    setOrder_data_changed("1");
                                                }
                                                }
                                                showTimeSelect={false}
                                                //filterTime={filterPassedTime_start}
                                                dateFormat="dd/MM/yyyy"
                                                className="disabled_style enable_style"
                                                locale='fr-FR'
                                            />
                                        </div>}



                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract"> Nom Client<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_nom_client"
                                                id="detail_nom_client"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_client_nom}
                                                onChange={(e) => {
                                                    setp_detail_header_client_nom(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>}

                                        {String(order_data_edit_mode) === "1" && <div className="session_caract"> Nom Client<br />

                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_nom_client"
                                                id="detail_nom_client"
                                                select
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_client_nom_id}
                                                onChange={(e) => {
                                                    setp_detail_header_client_nom_id(e.target.value);
                                                    Get_Givent_Partner_Client_Data(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            >
                                                {Get_List_Partner_Clients_result &&
                                                    Get_List_Partner_Clients_result.map((myclient) => (
                                                        <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                            style={{ "paddingLeft": "5px", "textAlign": "left", }}>
                                                            {JSON.parse(myclient).nom}&nbsp;&nbsp;

                                                        </MenuItem>
                                                    ))}

                                            </TextField>
                                        </div>}


                                        <div className="session_caract"> Email Client<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_email_client"
                                                id="detail_header_email_client"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_email_client}
                                                onChange={(e) => {
                                                    setp_detail_header_email_client(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>


                                        <div className="session_caract"> Ref. Externe<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_ref_cient"
                                                id="detail_header_ref_cient"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_ref_client}
                                                onChange={(e) => {
                                                    setp_detail_header_ref_client(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Description<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_description"
                                                id="detail_header_description"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_description}
                                                onChange={(e) => {
                                                    setp_detail_header_description(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Commentaire<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_comment"
                                                id="detail_header_comment"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_comment}
                                                onChange={(e) => {
                                                    setp_detail_header_comment(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract"> Vendeur<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_vendeur_nom_prenom"
                                                id="detail_header_vendeur_nom_prenom"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_vendeur_nom}
                                                onChange={(e) => {
                                                    setp_detail_header_vendeur_nom(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>}

                                        {String(order_data_edit_mode) === "1" && <div className="session_caract"> Vendeur<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_vendeur_nom_prenom"
                                                id="detail_header_vendeur_nom_prenom"
                                                select
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_vendeur_nom_id}
                                                onChange={(e) => {
                                                    setp_detail_header_vendeur_nom_id(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            >
                                                {Getall_Training_Employee_No_Filter_result &&
                                                    Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                                        <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                                            style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem" }}>
                                                            {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom}

                                                        </MenuItem>
                                                    ))}

                                            </TextField>
                                        </div>}



                                    </div>}

                                    {String(header_submenu) === "detail" && <div>
                                        {/*<div className="div_row">
                                            <Button variant="outlined" onClick={Compute_Order_Header_Price} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Mettre a jour prix</Button>
                                            <Button variant="outlined" onClick={print_order_pdf} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Imprimer pdf</Button>
                                            <Button variant="outlined" onClick={Send_Order_By_Email} className="detail_class_submenu" id='order_header_main' name='order_header_main'>envoyer par mail</Button>
                                                    </div>*/}


                                        {String(order_data_edit_mode) === "1" && <div className="session_caract"> Type de Reduction (entete) <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction"
                                                id="detail_header_type_reduction"
                                                select
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_type_reduction}
                                                onChange={(e) => {
                                                    setp_detail_header_type_reduction(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            >
                                                <MenuItem value="fixe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Montant &nbsp;</MenuItem>
                                                <MenuItem value="percent" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Pourcentage &nbsp;</MenuItem>
                                            </TextField>
                                        </div>}

                                        {String(order_data_edit_mode) !== "1" && <div className="session_caract"> Type de Reduction (entete) <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction"
                                                id="detail_header_type_reduction"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_type_reduction}


                                            />

                                        </div>}


                                        <div className="session_caract"> Valeur reduction (entete) <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_type_reduction_valeur}
                                                onChange={(e) => {
                                                    setp_detail_header_type_reduction_valeur(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Montant Reduction (entete) <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_montant_reduction"
                                                id="detail_header_montant_reduction"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_total_reduction_amount}

                                                //value={p_detail_header_montant_reduction}
                                                onChange={(e) => {
                                                    setp_detail_header_montant_reduction(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>



                                        <div className="session_caract"> Total Ligne HT avant reduction <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_total_lines_hors_taxe_before_lines_reduction}
                                            />
                                        </div>

                                        <div className="session_caract"> Total Ligne Reduction <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_header_montant_reduction}
                                            />
                                        </div>

                                        <div className="session_caract"> Total Ligne HT apres reduction <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_total_lines_hors_taxe_after_lines_reduction}
                                            />
                                        </div>


                                        <div className="session_caract"> Total Ligne Taxes <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_order_header_tax_amount}
                                            />
                                        </div>

                                        <div className="session_caract"> Entete : Total reduction <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_header_total_reduction_amount}
                                            />
                                        </div>



                                        <div className="session_caract"> Entete : Total HT avant reduction d'entete <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_total_header_hors_taxe_before_header_reduction}
                                            />
                                        </div>

                                        <div className="session_caract"> Entete : Total HT apres reduction d'entete <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_type_reduction_valeur"
                                                id="detail_header_type_reduction_valeur"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={true}
                                                className="disabled_style"
                                                value={p_detail_total_header_hors_taxe_after_header_reduction}
                                            />
                                        </div>

                                    </div>}

                                    {String(header_submenu) === "facturation" && <div>

                                        <div className="session_caract"> Adresse Facturation <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_fact_adresse"
                                                id="detail_header_fact_adresse"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_fact_adr}
                                                onChange={(e) => {
                                                    setp_detail_header_fact_adr(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Code postal <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_fact_code_postal"
                                                id="detail_header_fact_code_postal"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_fact_code_postal}
                                                onChange={(e) => {
                                                    setp_detail_header_fact_code_postal(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Ville <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_fact_ville"
                                                id="detail_header_fact_ville"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_fact_ville}
                                                onChange={(e) => {
                                                    setp_detail_header_fact_ville(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Pays <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_fact_pays"
                                                id="detail_header_fact_pays"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_fact_pays}
                                                onChange={(e) => {
                                                    setp_detail_header_fact_pays(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>



                                        <div className="session_caract"> Adresse Livraison <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_liv_adresse"
                                                id="detail_header_liv_adresse"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_liv_adr}
                                                onChange={(e) => {
                                                    setp_detail_header_liv_adr(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Code postal <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_liv_code_postal"
                                                id="detail_header_liv_code_postal"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_liv_code_postal}
                                                onChange={(e) => {
                                                    setp_detail_header_liv_code_postal(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Ville <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_liv_ville"
                                                id="detail_header_liv_ville"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_liv_ville}
                                                onChange={(e) => {
                                                    sep_detail_header_liv_ville(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>

                                        <div className="session_caract"> Pays <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_liv_pays"
                                                id="detail_header_liv_pays"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_liv_pays}
                                                onChange={(e) => {
                                                    setp_detail_header_liv_pays(e.target.value);
                                                    setOrder_data_changed("1");
                                                }
                                                }

                                            />
                                        </div>


                                    </div>}
                                </div>
                                <div style={{ "width": "23%", "float": "left", "textAlign": "right", "paddingTop": "2rem" }}>

                                    {String(p_detail_header_statut_label).toLowerCase() === "brouillon" &&
                                        String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <Button variant="outlined" onClick={Confirm_Order_Data} className="detail_class_submenu bton_add_sous_menu">  Confirmer</Button>
                                    }

                                    {String(p_detail_header_statut_label).toLowerCase() === "en cours" &&
                                        String(p_detail_header_type).toLowerCase() === "commande" && <Button variant="outlined" onClick={Make_Order_Ready_To_Invoice} className="detail_class_submenu bton_add_sous_menu bton_put_to_invoice">  Pret pour Facturation </Button>
                                    }


                                    {String(selected_order_header_type) === "commande" &&
                                        String(p_detail_header_statut_label).toLowerCase() === "traité" &&
                                        <Button variant="outlined" onClick={Invoice_Order_Data} className="detail_class_submenu bton_add_sous_menu">  Facturer </Button>}

                                    {String(selected_order_header_type) === "devis" && <Button variant="outlined" onClick={Convert_Quotation_To_Order_Data} className="detail_class_submenu bton_add_sous_menu">  Convertir en Cmd </Button>}
                                    {String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <Button variant="outlined" onClick={Cancel_Order_Data} className="detail_class_submenu bton_add_sous_menu">  Annuler </Button>}


                                    {String(p_detail_header_statut_label).toLowerCase() !== "traité" &&
                                        String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <Popup
                                            trigger={<Button variant="outlined" className="detail_class_submenu bton_add_sous_menu">  Supprimer </Button>}

                                            modal
                                            nested
                                            position="center center"
                                        >
                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}

                                                        En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {
                                                                Delete_Order_Header_AndLine_Data(selected_id, selected_order_header_type);
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}> Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>}

                                    <br /> <br />
                                    {String(p_detail_header_statut_label).toLowerCase() === "en cours" &&

                                        <Button variant="outlined" onClick={Compute_Order_Header_Price} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Mettre a jour prix</Button>}


                                    {String(p_detail_header_statut_label).toLowerCase() !== "brouillon" && <Button variant="outlined" onClick={print_order_pdf} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Imprimer pdf</Button>}
                                    {String(p_detail_header_statut_label).toLowerCase() !== "brouillon" && <Button variant="outlined" onClick={Send_Order_By_Email} className="detail_class_submenu" id='order_header_main' name='order_header_main'>envoyer par mail</Button>}

                                </div>

                            </div>


                            <div>
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {String(Order_data_changed) === "1" && String(order_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                    /!\ Pensez à enregistrer les modifications
                                </div>}
                                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    {String(Order_data_changed) === "1" && String(order_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                        <Button variant="contained" className="bton_enreg" onClick={Update_One_Order_Data}>Enregistrer les modifications
                                        </Button>

                                    </div>}

                                    {String(order_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                        <Button variant="contained" className="bton_annule" onClick={Annule_Order_DetailFields}>Annuler
                                        </Button>
                                    </div>}
                                </div>


                                {String(order_data_edit_mode) !== "1" && String(p_detail_header_statut_label).toLowerCase() !== "facturé" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                    <Button variant="contained" className="bton_edit" onClick={Enable_Order_Header_DetailFields}>Editer
                                    </Button>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                            </div>
                        </div>

                        <div className="session_data" >

                            <div style={{ "border": "None" }}>
                                <nav onClick={Ajouter_une_ligne_article} style={{ "fontSize": "18px", "fontWeight": '600', "marginLeft": "10px", "marginTop": "10px", "cursor": "pointer" }}> <Button> Ajouter une ligne
                                    &nbsp;
                                </Button> <img src={add_plus} alt="ajout commande" className="icon_plus_line" /> </nav>
                                <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule a distance ou presentiel
                                            '& .cell--distantiel': {
                                                backgroundColor: '#AFE1AF',
                                                color: 'black',
                                            },

                                            '& .cell--presentiel': {
                                                backgroundColor: '#AFE1AF',
                                                color: 'black',
                                            },


                                            // gestion couleur des lignes avec le statut d'inscription
                                            '& .line--statut--annule': {
                                                backgroundColor: '#D8D8D8',
                                                color: 'white',
                                            },

                                            '& .line--statut--inscrit': {
                                                backgroundColor: '#CEF6D8',
                                                color: 'black',
                                            },
                                            '& .line--statut--preinscrit': {
                                                backgroundColor: '#F7F2E0',
                                                color: 'black',
                                            },

                                            /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                                "& .MuiDataGrid-row": {
                                                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                                }
                                            },*/
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_order_lines(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_order_From_Line(newSelectionModel);
                                                if (newSelectionModel.length !== 1) {
                                                    setsubmenu();
                                                    setdisplay_detail_order();
                                                    setadd_One_Order();
                                                }*/
                                            }}
                                            selectionModel={selectionModel_order_lines}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rows_order_lines.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    order_line_formation: JSON.parse(item).order_line_formation,
                                                    order_line_qty: JSON.parse(item).order_line_qty,
                                                    order_line_status: JSON.parse(item).order_line_status,
                                                    order_line_prix_unitaire: JSON.parse(item).order_line_prix_unitaire,
                                                    order_header_id: JSON.parse(item).order_header_id,
                                                    order_header_ref_interne: JSON.parse(item).order_header_ref_interne,
                                                    order_line_type: JSON.parse(item).order_line_type,
                                                    order_line_formation_title: JSON.parse(item).title,
                                                    order_line_formation_duration: JSON.parse(item).duration_concat,
                                                    order_line_comment: JSON.parse(item).order_line_comment,
                                                    order_line_formation_external_code: JSON.parse(item).order_line_formation_external_code,

                                                }
                                            ))}

                                            columns={columns_order_lines}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                handleClick_edit_order_From_Line(newSelectionModel.row.id);

                                            }}

                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>
                        </div>
                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                            {String(Order_data_changed) === "1" && String(order_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                /!\ Pensez à enregistrer les modifications
                            </div>}
                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                {String(Order_data_changed) === "1" && String(order_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Update_One_Order_Data}>Enregistrer les modifications
                                    </Button>

                                </div>}

                                {String(order_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={Annule_Order_DetailFields}>Annuler
                                    </Button>
                                </div>}
                            </div>


                            {String(order_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_Order_Header_DetailFields}>Editer
                                </Button>

                            </div>}
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>

                    </div>
                </div>}
            </div>


        </div>
    )
}


export default Partner_Commande;