import React, { useRef, useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import Navigation from "../components/Navigation";
import { Helmet } from "react-helmet";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import 'react-toastify/dist/ReactToastify.min.css';
import axios from "axios";
import { Button, } from "reactstrap";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import ousommesnousimg from "../mysy_img/mysy_training_technology_ici.png"
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import SendIcon from '@mui/icons-material/Send';

const Sedesabonner = () => {

  const [country_code, setcountry_code] = useState("");
  const [country_name, setcountry_name] = useState("");
  const [city, setcity] = useState("");
  const [postal, setpostal] = useState("");
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  const [IPv4, setIPv4] = useState("");
  const [state, setstate] = useState("");
  const [userIp, setuserIp] = useState("");
  const [mynote, setmynote] = useState(0);
  const [usermessage, setusermessage] = useState("");
  const [object, setobject] = useState("");
  const [result, setResult] = useState("");
  const [myApiResponse, setmyApiResponse] = useState("");



  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    const {  email,  } = data;

    Desabonnement();
  };

  const [Desabonnement_api, setDesabonnement_api] = useState("");
  const [Desabonnement_message, setDesabonnement_message] = useState("");
  const [Desabonnement_result, setDesabonnement_result] = useState("");
  function Desabonnement(e) {
    var mysender_email = document.getElementsByName("email")[0].value;

    var re = /\S+@\S+\.\S+/;
    if (re.test(mysender_email) == false) {
      alert("l'email est incorrecte");
      return;
    }


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Sedesabonner/";
    var form = new FormData();
    form.append("email", mysender_email);


    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("false")) {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setDesabonnement_api("false");
      }

      else if (String(res.data.status) === String("true")) {
        //console.log(" In test  res.data.status (true) = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
       
        setDesabonnement_api("true");
        setDesabonnement_message(res.data.message);
        window.setTimeout(function() {
          window.location.href = process.env.REACT_APP_BASE_URL
      }, 7000);
      
      }
      else {
        //console.log(" contact-nous  statut = " + res.data.status);
        //console.log(" contact-nous   res.data.message = " + res.data.message);
        setDesabonnement_api("false");
      }
    }).catch((error) => {
      console.warn('contact-nous  Not good man :( mysearchtext = ', error);
      setDesabonnement_api("false");

    })

  }






  return (
    <div className='ContactForm'>
      <Helmet>
        <title>MySy Training, Se désabonner </title>
        <meta name="description" content="Trouver des formations et tutoriels adapté à vos besoins" />

        <meta name="robots" content="index,follow" />
        <meta name="title" content="MySy Training, contactez-nous" />
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/ContactezNous"} />
      </Helmet>

      <Header />


      <div className="div_mobile">
        <div> &nbsp;<br /></div>
        <div className="titre"> Se désabonner </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <div className='contactForm'>
                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                  {/* Row 1 of form */}
                  <div className='row formRow'>
                 
                    <div className='col-12'>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        {...register('email', {
                          required: true,
                          pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        })}
                        className='form-control formInput'
                        placeholder='Adresse email'
                      ></input>
                      {errors.email && (
                        <span className='errorMessage'>Adresse email invalide</span>
                      )}
                    </div>
                  </div>
                  {/* Row 2 of form */}
                  
                  {/* Row 3 of form */}
                 
                  <br />

                 
                  <br />
                  <Button variant="contained" color="success" type='submit'
                    className="div_row22 btn_login button" style={{"width":"100%"}}
                  >Confirmer  &nbsp; &nbsp;  {<SendIcon />}</Button>
                </form>
                <br />
                {String(Desabonnement_api) === String("true") && <div className="okUpdateData">
                  Votre demande a bien été prise en compte. Elle sera traitée dans 24h. <br/>
                  Vous allez être redirigé vers la page d'accueil.
                  


                </div>}

                {String(Desabonnement_api) === String("false") && <div className="koUpdateData">
                  Impossible de traiter votre demande. Merci de ressayer dans un instant.
                </div>}
              </div>
            </div>
          </div>

          <br />
          
          <br/>
          <div className="div_row" >
            <div className="nom_prenom">Où nous trouver ? </div>

            <div className="div_carte">
              <a target="_blank"
                href="https://www.google.com/maps/place/MySy+Training+Technology/@48.8761591,2.3260624,15z/data=!4m5!3m4!1s0x0:0x8239ba23406c9699!8m2!3d48.8761591!4d2.3260624">
                <img src={ousommesnousimg} alt="logo MySy Technology ou sommes nous" style={{ 'width': "100%", 'height': '50%' }} />
              </a>


            </div>

            <div className="div_adresse" >
              <br />
              <div>MySy Training Technology<br />
              </div>
              <div style={{ fontSize: "small" }}>

                Adresse : 1 Cr du Havre, 75008 Paris <br />
                Adresse email :
                <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                Téléphone fixe : +331 77 00 38 57<br />
                Téléphone : +337 69 20 39 45<br />

              </div>
              <br />
              <div> Sur nos reseaux sociaux

                <div style={{ 'float': "center", "textAlign": "center" }}>
                  <div className="media_sociaux">
                    <FacebookShareButton
                      url={"https://www.facebook.com/MySy-Training-114572794561371/"}
                    >
                      <SocialIcon network="facebook" className="SocialIcon" />
                    </FacebookShareButton>
                    &nbsp;

                    <TwitterShareButton
                      url={"https://twitter.com/mysy_training"}
                      hashtag={"#MySyTraining"}
                    >
                      <SocialIcon network="twitter" className="SocialIcon" />
                    </TwitterShareButton>

                    &nbsp;
                    <LinkedinShareButton
                      url={"https://www.linkedin.com/c"}
                      summary={"ee"}
                      source={"https://www.linkedin.com/in/mysy-training-96b53a237/"}

                    >
                      <SocialIcon network="linkedin" className="SocialIcon" />
                    </LinkedinShareButton>


                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>


        <div className="pieddepage">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Sedesabonner;