import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";

import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";


const Partner_Facture = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_invoice_lines, setrows_invoice_lines] = useState([]);
    const [selectionModel_invoice_lines, setselectionModel_invoice_lines] = React.useState([]);
    const [gridline_id, setgridline_id] = useState("");
    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();
    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'invoice_header_ref_interne', headerName: 'Num Facture', width: 100, hide: false, editable: false },
        { field: 'order_header_ref_interne', headerName: 'Reference Cmd', width: 100, hide: false, editable: false },
        { field: 'invoice_header_type', headerName: 'Type', width: 100, hide: false, editable: false },
        { field: 'invoice_date', headerName: 'Date Facture', width: 150, hide: false, editable: false },

        { field: 'order_header_client_id', headerName: 'Client_Id', width: 150, hide: true, editable: false },
        { field: 'order_header_client_nom', headerName: 'Nom Client', width: 150, hideable: false, flex: 1 },
        { field: 'order_header_vendeur_id', headerName: 'Vendeur Id', width: 150, hide: true, editable: false },
        { field: 'order_header_vendeur_nom_prenom', headerName: 'Vendeur', width: 150, hideable: false, flex: 1, editable: false },
        { field: 'order_header_date_cmd', headerName: 'Date Cmd', width: 150, hide: true, editable: false },
        { field: 'total_header_hors_taxe_after_header_reduction', headerName: 'Montant HT', width: 150, hide: false, editable: false },
        { field: 'order_header_tax_amount', headerName: 'Taxe', width: 150, hide: false, editable: false },
        { field: 'total_header_toutes_taxes', headerName: 'Montant TTC', width: 150, hide: false, editable: false },




        /* {
             field: "Detail", headerName: 'Voir detail',
             renderCell: (cellValues) => {
                 return (
 
                     <Button
 
                         onClick={(event) => {
                             Display_Detail_Employe(event, cellValues);
                         }}
                     >
                         <FcViewDetails />
 
                     </Button>
 
                 );
             }
         },*/
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            //handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]


    const columns_invoice_lines = [
        { field: '_id', headerName: '_id', hide: true, editable: false },
        { field: 'id', headerName: 'id', hide: true, editable: false },
        { field: 'order_line_formation', headerName: 'intenal_url', width: 0, hide: true, editable: false },
        { field: 'order_line_formation_title', headerName: 'Titre', width: 250, hide: false, editable: false, flex: 1 },
        { field: 'order_line_formation_external_code', headerName: 'Code Formation', width: 150, hide: false, editable: false },
        { field: 'order_line_formation_duration', headerName: 'Durée', width: 80, hide: false, editable: false },

        { field: 'order_line_qty', headerName: 'Qte', width: 100, hide: false, editable: false },
        { field: 'order_line_prix_unitaire', headerName: 'Prix Unit.', width: 100, hide: false, editable: false },
        { field: 'order_header_id', headerName: 'Id_Entete', hide: true, editable: false },
        { field: 'order_header_ref_interne', headerName: 'Ref_Interne', hide: true, editable: false },
        { field: 'order_line_type', headerName: 'Type', width: 100, hide: false, editable: false },

        {
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Display_Detail_Invoice_Line(event, cellValues);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ligne sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            //handleClick_delete_Order_line(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        },
        { field: 'order_line_comment', headerName: 'Commentaire', width: 150, hide: true, editable: false, },

    ]


    const [selected_invoice_line_id, setselected_invoice_line_id] = useState();
    const [p_add_line_item, setp_add_line_item] = useState();
    const [p_add_line_item_internal_url, setp_add_line_item_internal_url] = useState();
    const [p_add_line_item_title, setp_add_line_item_title] = useState();
    const [p_add_line_item_qty, setp_add_line_item_qty] = useState();
    const [p_add_line_item_price, setp_add_line_item_price] = useState();
    const [p_add_line_item_comment, setp_add_line_item_comment] = useState();
    const [p_add_line_item_status, setp_add_line_item_status] = useState();
    const [p_add_line_item_type, setp_add_line_item_type] = useState();

    const [p_add_line_item_type_reduction, setp_add_line_item_type_reduction] = useState("fixe");
    const [p_add_line_item_reduction_value, setp_add_line_item_reduction_value] = useState();
    const [p_add_line_item_reduction_total_amount, setp_add_line_item_reduction_total_amount] = useState();
    const [p_add_line_item_total_amount_ht, setp_add_line_item_total_amount_ht] = useState();
    const [p_add_line_item_taxe, setp_add_line_item_taxe] = useState();
    const [p_add_line_item_taxe_amount, setp_add_line_item_taxe_amount] = useState();
    const [p_add_line_item_total_amount_ttc, setp_add_line_item_total_amount_ttc] = useState();
    const [p_add_line_item_duration_concat, setp_add_line_item_duration_concat] = useState();



    function Display_Detail_Invoice_Line(event, cellValues) {

        var invoice_line_id = String(cellValues.row._id);

        var order_line_formation = String(cellValues.row.order_line_formation);
        var order_line_qty = String(cellValues.row.order_line_qty);
        var order_line_prix_unitaire = String(cellValues.row.order_line_prix_unitaire);

        setselected_invoice_line_id(invoice_line_id);

        setp_add_line_item(order_line_formation);
        setp_add_line_item_qty(order_line_qty);
        setp_add_line_item_price(order_line_prix_unitaire);
        setp_add_line_item_status(String(cellValues.row.order_line_status));
        setp_add_line_item_type(String(cellValues.row.order_line_type));
        setp_add_line_item_comment(String(cellValues.row.order_line_comment));


        Get_Given_Line_Of_Partner_Invoice_Line_Data(invoice_line_id, invoice_internal_ref);



        //setDialog_Detail_Article_open(true);
        //alert(" afficher le detail de la ligne " + String(order_line_id));

    }

    const [Get_Given_Line_Of_Partner_Invoice_Line_Data_api, setGet_Given_Line_Of_Partner_Invoice_Line_Data_api] = useState();
    const [Get_Given_Line_Of_Partner_Invoice_Line_Data_message, setGet_Given_Line_Of_Partner_Invoice_Line_Data_message] = useState();
    const [Get_Given_Line_Of_Partner_Invoice_Line_Data_result, setGet_Given_Line_Of_Partner_Invoice_Line_Data_result] = useState();
    function Get_Given_Line_Of_Partner_Invoice_Line_Data(local_invoice_line_id, local_invoice_header_ref_interne) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_line_id", local_invoice_line_id);
        form.append("invoice_header_ref_interne", local_invoice_header_ref_interne);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Line_Of_Partner_Invoice_Lines/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Given_Line_Of_Partner_Invoice_Line_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Line_Of_Partner_Invoice_Line_Data  res.data.message r_class = " + res.data.message);
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_api("true");
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_order = JSON.parse(res.data.message);


                    if (mylocal_order.order_line_formation) {
                        setp_add_line_item(mylocal_order.order_line_formation);
                    }


                    if (mylocal_order.order_line_qty) {
                        setp_add_line_item_qty(mylocal_order.order_line_qty);
                    }



                    if (mylocal_order.order_line_prix_unitaire) {
                        setp_add_line_item_price(mylocal_order.order_line_prix_unitaire);
                    }


                    /*if (mylocal_order.order_header_id) {
                        setp_detail_header_type_reduction(mylocal_order.order_header_id);
                    }

                 
                    if (mylocal_order.order_header_ref_interne) {
                        setp_detail_header_type_reduction_valeur(mylocal_order.order_header_ref_interne);
                    }*/


                    if (mylocal_order.order_line_montant_reduction) {
                        setp_add_line_item_reduction_total_amount(mylocal_order.order_line_montant_reduction);
                    }



                    if (mylocal_order.order_line_montant_toutes_taxes) {
                        setp_add_line_item_total_amount_ttc(mylocal_order.order_line_montant_toutes_taxes);

                    }


                    if (mylocal_order.order_line_tax) {
                        setp_add_line_item_taxe(mylocal_order.order_line_tax);
                    }


                    if (mylocal_order.order_line_tax_amount) {
                        setp_add_line_item_taxe_amount(mylocal_order.order_line_tax_amount);
                    }


                    if (mylocal_order.order_line_type_reduction) {
                        setp_add_line_item_type_reduction(mylocal_order.order_line_type_reduction);
                    }


                    if (mylocal_order.order_line_type_valeur) {
                        setp_add_line_item_reduction_value(mylocal_order.order_line_type_valeur);
                    }


                    if (mylocal_order.order_line_type) {
                        setp_add_line_item_type(mylocal_order.order_line_type);
                    }


                    if (mylocal_order.order_line_status) {
                        setp_add_line_item_status(mylocal_order.order_line_status);
                    }


                    if (mylocal_order.order_line_comment) {
                        setp_add_line_item_comment(mylocal_order.order_line_comment);
                    }


                    if (mylocal_order.title) {
                        setp_add_line_item_title(mylocal_order.title);
                    }

                    if (mylocal_order.order_line_montant_hors_taxes) {
                        setp_add_line_item_total_amount_ht(mylocal_order.order_line_montant_hors_taxes);

                    }


                    /*if (mylocal_order.domaine) {
                        setp_detail_header_montant_reduction(mylocal_order.domaine);
                    }*/


                    if (mylocal_order.duration_concat) {
                        setp_add_line_item_duration_concat(mylocal_order.duration_concat);
                    }


                    ;

                } else {
                    alert(" Aucune ligne de facture recuperée");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_api("false");
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Line_Of_Partner_Invoice_Line_Data = ', error);
            setGet_Given_Line_Of_Partner_Invoice_Line_Data_api("false");
            alert(" Impossible de recuperer les données de la ligne de facture");
            //setmyApimyApiMessage("")
        })
    }


    const [isLoading, setLoading] = useState();

    const [p_detail_header_client_nom, setp_detail_header_client_nom] = useState();
    const [p_detail_header_email_client, setp_detail_header_email_client] = useState();
    const [p_detail_header_client_nom_id, setp_detail_header_client_nom_id] = useState();

    const [p_detail_header_description, setp_detail_header_description] = useState();
    const [p_detail_header_comment, setp_detail_header_comment] = useState();
    const [p_detail_header_date_cmd, setp_detail_header_date_cmd] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_detail_header_date_expiration, setp_detail_header_date_expiration] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_detail_header_condition_paiement, setp_detail_header_condition_paiement] = useState();
    const [p_detail_header_ref_client, setp_detail_header_ref_client] = useState();
    const [p_detail_header_ref_interne, setp_detail_header_ref_interne] = useState();

    const [p_detail_header_vendeur_nom, setp_detail_header_vendeur_nom] = useState();
    const [p_detail_header_vendeur_nom_id, setp_detail_header_vendeur_nom_id] = useState();


    const [p_detail_header_invoice_header_ref_interne, setp_detail_header_invoice_header_ref_interne] = useState();
    const [p_detail_header_invoice_header_type, setp_detail_header_invoice_header_type] = useState();
    const [p_detail_header_invoice_date, setp_detail_header_invoice_date] = useState();


    const [p_detail_header_type, setp_detail_header_type] = useState();
    const [p_detail_header_statut, setp_detail_header_statut] = useState();
    const [p_detail_header_vendeur_id, setp_detail_header_vendeur_id] = useState();
    const [p_detail_header_client_id, setp_detail_header_client_id] = useState();
    const [p_detail_header_statut_label, setp_detail_header_statut_label] = useState();

    const [p_detail_header_date_cmd_val, setp_detail_header_date_cmd_val] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_detail_header_date_expiration_val, setp_detail_header_date_expiration_val] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_detail_header_fact_adr, setp_detail_header_fact_adr] = useState();
    const [p_detail_header_fact_code_postal, setp_detail_header_fact_code_postal] = useState();
    const [p_detail_header_fact_ville, setp_detail_header_fact_ville] = useState();
    const [p_detail_header_fact_pays, setp_detail_header_fact_pays] = useState();


    const [p_detail_header_liv_adr, setp_detail_header_liv_adr] = useState();
    const [p_detail_header_liv_code_postal, setp_detail_header_liv_code_postal] = useState();
    const [p_detail_header_liv_ville, sep_detail_header_liv_ville] = useState();
    const [p_detail_header_liv_pays, setp_detail_header_liv_pays] = useState();
    const [p_detail_header_total_ht, setp_detail_header_total_ht] = useState();
    const [p_detail_header_total_tax, setp_detail_header_total_tax] = useState();
    const [p_detail_header_total_ttc, setp_detail_header_total_ttc] = useState();
    const [p_detail_header_type_reduction, setp_detail_header_type_reduction] = useState();
    const [p_detail_header_total_reduction_amount, setp_detail_header_total_reduction_amount] = useState();
    const [p_detail_header_type_reduction_valeur, setp_detail_header_type_reduction_valeur] = useState();
    const [p_detail_header_montant_reduction, setp_detail_header_montant_reduction] = useState();
    const [p_detail_total_header_hors_taxe_before_header_reduction, setp_detail_total_header_hors_taxe_before_header_reduction] = useState();
    const [p_detail_total_header_hors_taxe_after_header_reduction, setp_detail_total_header_hors_taxe_after_header_reduction] = useState();
    const [p_detail_order_header_tax_amount, setp_detail_order_header_tax_amount] = useState();
    const [p_detail_total_header_toutes_taxes, setp_detail_total_header_toutes_taxes] = useState();



    const [p_detail_total_lines_hors_taxe_before_lines_reduction, setp_detail_total_lines_hors_taxe_before_lines_reduction] = useState();
    const [p_detail_total_lines_hors_taxe_after_lines_reduction, setp_detail_total_lines_hors_taxe_after_lines_reduction] = useState();

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Parter_Invoice_No_Filter_api, setGetall_Parter_Invoice_No_Filter_api] = useState();
    const [Getall_Parter_Invoice_No_Filter_message, setGetall_Parter_Invoice_No_Filter_message] = useState();
    const [Getall_Parter_Invoice_No_Filter_result, setGetall_Parter_Invoice_No_Filter_result] = useState();
    function Getall_Parter_Invoice_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Invoice_no_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Invoice_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Invoice_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Invoice_No_Filter_api("true");
                setGetall_Parter_Invoice_No_Filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Invoice_No_Filter_api("false");
                setGetall_Parter_Invoice_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Invoice_No_Filter = ', error);
            setGetall_Parter_Invoice_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des factures");
            //setmyApimyApiMessage("")
        })
    }

    const myRef = useRef(null);

    useEffect(() => {

        Get_List_Partner_Clients();
        Getall_Parter_Invoice_No_Filter();

        if ( document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
           window.scrollTo({
               top: divh,
               behavior: "smooth",
           });
       }

    }, [])

    const [display_detail_invoice, setdisplay_detail_invoice] = React.useState();

    const myRef_head = useRef(null);
    const [selected_id, setselected_id] = useState("");
    const [selected_order_line_id, setselected_order_line_id] = useState("");
    const [selected_order_header_type, setselected_order_header_type] = useState();

    const [order_internal_ref, setorder_internal_ref] = useState("");
    const [invoice_internal_ref, setinvoice_internal_ref] = useState("");

    function handleClick_edit_invoice_From_Line(selected_row_id) {

        //submenu_detail_employe();

        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var invoice_id = line._id;
        setselected_id(invoice_id);

        var order_ref = line.order_header_ref_interne;
        setorder_internal_ref(order_ref);

        var invoice_ref = line.invoice_header_ref_interne;
        setinvoice_internal_ref(invoice_ref);


        //console.log(" ### employe_id  = ", employe_id);
        Display_Detail_Invoice_data(invoice_id);


        if ( document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
           window.scrollTo({
               top: divh,
               behavior: "smooth",
           });
       }
    }


    function Display_Detail_Invoice_data(invoice_id) {
        //clearDetailAttendeFields();

        setdisplay_detail_invoice("1");


        setLoading(true);
        Get_Invoice_Data(invoice_id);
        Get_Partner_Invoice_Lines_from_header_id(invoice_id);

    }


    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Invoice_Data(invoice_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", invoice_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Invoice/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In Get_Invoice_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Invoice_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_order = JSON.parse(res.data.message);


                    if (mylocal_order.invoice_header_ref_interne)
                        setp_detail_header_invoice_header_ref_interne(mylocal_order.invoice_header_ref_interne);


                    if (mylocal_order.invoice_header_type)
                        setp_detail_header_invoice_header_type(mylocal_order.invoice_header_type);


                    if (mylocal_order.invoice_date)
                        setp_detail_header_invoice_date(mylocal_order.invoice_date);



                    //document.getElementsByName("detail_header_type")[0].value = "";
                    if (mylocal_order.order_header_type)
                        setp_detail_header_type(mylocal_order.order_header_type);



                    //document.getElementsByName("detail_header_ref_interne")[0].value = "";
                    if (mylocal_order.order_header_ref_interne) {
                        setp_detail_header_ref_interne(mylocal_order.order_header_ref_interne);
                    }

                    //document.getElementsByName("detail_header_ref_cient")[0].value = "";
                    if (mylocal_order.order_header_ref_client) {
                        setp_detail_header_ref_interne(mylocal_order.order_header_ref_client);
                    }


                    //document.getElementsByName("detail_header_date_cmd")[0].value = "";
                    if (mylocal_order.order_header_date_cmd) {
                        setp_detail_header_date_cmd(mylocal_order.order_header_date_cmd);
                        setp_detail_header_date_cmd_val(mylocal_order.order_header_date_cmd);
                    }

                    //document.getElementsByName("detail_header_date_expiration")[0].value = "";
                    if (mylocal_order.order_header_date_expiration) {
                        setp_detail_header_date_expiration(mylocal_order.order_header_date_expiration);
                        setp_detail_header_date_expiration_val(mylocal_order.order_header_date_cmd);
                    }


                    //document.getElementsByName("detail_nom_client")[0].value = "";
                    if (mylocal_order.order_header_client_nom) {
                        setp_detail_header_client_nom(mylocal_order.order_header_client_nom);
                        setp_detail_header_client_nom_id(mylocal_order.order_header_client_id);
                    }


                    if (mylocal_order.order_header_email_client) {
                        setp_detail_header_email_client(mylocal_order.order_header_email_client);
                    }


                    //document.getElementsByName("detail_header_description")[0].value = "";
                    if (mylocal_order.order_header_description) {
                        setp_detail_header_description(mylocal_order.order_header_description);
                    }

                    //document.getElementsByName("detail_header_comment")[0].value = "";
                    if (mylocal_order.order_header_comment) {
                        setp_detail_header_comment(mylocal_order.order_header_comment);
                    }


                    // document.getElementsByName("detail_header_vendeur_nom_prenom")[0].value = "";
                    if (mylocal_order.order_header_vendeur_nom_prenom) {
                        setp_detail_header_vendeur_nom(mylocal_order.order_header_vendeur_nom_prenom);
                        setp_detail_header_vendeur_nom_id(mylocal_order.order_header_vendeur_id);
                    }


                    // Recuperation des adresses (livraison / lieu execution - Facturation ) 
                    //document.getElementsByName("detail_header_fact_adresse")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_adresse) {
                        setp_detail_header_fact_adr(mylocal_order.order_header_adr_fact_adresse);
                    }

                    //document.getElementsByName("detail_header_fact_code_postal")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_code_postal) {
                        setp_detail_header_fact_code_postal(mylocal_order.order_header_adr_fact_code_postal);
                    }

                    //document.getElementsByName("detail_header_fact_ville")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_ville) {
                        setp_detail_header_fact_ville(mylocal_order.order_header_adr_fact_ville);
                    }

                    //document.getElementsByName("detail_header_fact_pays")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_pays) {
                        setp_detail_header_fact_pays(mylocal_order.order_header_adr_fact_pays);
                    }

                    //document.getElementsByName("detail_header_liv_adresse")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_adresse) {
                        setp_detail_header_liv_adr(mylocal_order.order_header_adr_liv_adresse);
                    }

                    //document.getElementsByName("detail_header_liv_code_postal")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_code_postal) {
                        setp_detail_header_liv_code_postal(mylocal_order.order_header_adr_liv_code_postal);
                    }

                    //document.getElementsByName("detail_header_liv_ville")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_ville) {
                        sep_detail_header_liv_ville(mylocal_order.order_header_adr_liv_ville);
                    }

                    //document.getElementsByName("detail_header_liv_pays")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_pays) {
                        setp_detail_header_liv_pays(mylocal_order.order_header_adr_liv_pays);
                    }


                    // Recuperation des montant, taxes et autres reductions
                    //document.getElementsByName("detail_header_total_ht")[0].value = "";
                    if (mylocal_order.order_header_total_ht) {
                        setp_detail_header_total_ht(mylocal_order.order_header_total_ht);
                    }

                    //document.getElementsByName("detail_header_total_tax")[0].value = "";
                    if (mylocal_order.order_header_tax_amount) {
                        setp_detail_header_total_tax(mylocal_order.order_header_tax_amount);
                    }


                    //document.getElementsByName("detail_header_total_ttc")[0].value = "";
                    if (mylocal_order.order_header_total_ttc) {
                        setp_detail_header_total_ttc(mylocal_order.order_header_total_ttc);
                    }

                    //document.getElementsByName("detail_header_type_reduction")[0].value = "";
                    if (mylocal_order.order_header_type_reduction) {
                        setp_detail_header_type_reduction(mylocal_order.order_header_type_reduction);
                    }

                    //document.getElementsByName("detail_header_type_reduction_valeur")[0].value = "";
                    if (mylocal_order.order_header_type_reduction_valeur) {
                        setp_detail_header_type_reduction_valeur(mylocal_order.order_header_type_reduction_valeur);
                    }


                    if (mylocal_order.order_header_montant_reduction) {
                        setp_detail_header_total_reduction_amount(mylocal_order.order_header_montant_reduction);
                    }


                    if (mylocal_order.total_lines_montant_reduction) {
                        setp_detail_header_montant_reduction(mylocal_order.total_lines_montant_reduction);
                    }

                    if (mylocal_order.total_header_hors_taxe_before_header_reduction) {
                        setp_detail_total_header_hors_taxe_before_header_reduction(mylocal_order.total_header_hors_taxe_before_header_reduction);
                    }

                    if (mylocal_order.total_header_hors_taxe_after_header_reduction) {
                        setp_detail_total_header_hors_taxe_after_header_reduction(mylocal_order.total_header_hors_taxe_after_header_reduction);
                    }

                    if (mylocal_order.order_header_tax_amount) {
                        setp_detail_order_header_tax_amount(mylocal_order.order_header_tax_amount);
                    }


                    if (mylocal_order.total_header_toutes_taxes) {
                        setp_detail_total_header_toutes_taxes(mylocal_order.total_header_toutes_taxes);
                    }

                    if (mylocal_order.total_lines_hors_taxe_before_lines_reduction) {
                        setp_detail_total_lines_hors_taxe_before_lines_reduction(mylocal_order.total_lines_hors_taxe_before_lines_reduction);
                    }

                    if (mylocal_order.total_lines_hors_taxe_after_lines_reduction) {
                        setp_detail_total_lines_hors_taxe_after_lines_reduction(mylocal_order.total_lines_hors_taxe_after_lines_reduction);
                    }



                    disable_Order_Header_DetailFields();
                    //order_header_submenu_main();

                } else {
                    alert(" Aucune commande/devis recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Invoice_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de la facture");
            //setmyApimyApiMessage("")
        })
    }

    const [header_submenu, setheader_submenu] = useState("");
    async function order_header_submenu_main() {
        setheader_submenu("main");

        await sleep(5);

        if (document.getElementById("order_header_main")) {
            document.getElementById("order_header_main").style.backgroundColor = "#104277";
            document.getElementById("order_header_main").style.color = "white";
        }

        if (document.getElementById("order_header_detail")) {
            document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_detail").style.color = "black";
        }

        if (document.getElementById("order_header_facturation")) {
            document.getElementById("order_header_facturation").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_facturation").style.color = "black";
        }


        disable_Order_Header_DetailFields();

    }

    async function order_header_submenu_detail() {
        setheader_submenu("detail");

        await sleep(5);

        if (document.getElementById("order_header_detail")) {
            document.getElementById("order_header_detail").style.backgroundColor = "#104277";
            document.getElementById("order_header_detail").style.color = "white";
        }

        if (document.getElementById("order_header_main")) {
            document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_main").style.color = "black";
        }

        if (document.getElementById("order_header_facturation")) {
            document.getElementById("order_header_facturation").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_facturation").style.color = "black";
        }

        disable_Order_Header_DetailFields();

    }

    async function order_header_submenu_facturation() {
        setheader_submenu("facturation");

        await sleep(5);

        if (document.getElementById("order_header_facturation")) {
            document.getElementById("order_header_facturation").style.backgroundColor = "#104277";
            document.getElementById("order_header_facturation").style.color = "white";
        }

        if (document.getElementById("order_header_detail")) {
            document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_detail").style.color = "black";
        }

        if (document.getElementById("order_header_main")) {
            document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
            document.getElementById("order_header_main").style.color = "black";
        }


        disable_Order_Header_DetailFields();

    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    function print_invoice_pdf() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Facture_" + invoice_internal_ref + ".pdf";
        console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/GerneratePDF_Partner_Invoice/" + stored_cookie + "/" + selected_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fichier_cmd)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    const [Getall_Parter_Invoice_With_Filter_api, setGetall_Parter_Invoice_With_Filter_api] = useState();
    const [Getall_Parter_Invoice_With_Filter_message, setGetall_Parter_Invoice_With_Filter_message] = useState();
    const [Getall_Parter_Invoice_With_Filter_result, setGetall_Parter_Invoice_With_Filter_result] = useState();
    function Getall_Parter_Invoice_With_Filter(event) {

        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Invoice_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Invoice_With_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Invoice_With_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Invoice_With_Filter_api("true");
                setGetall_Parter_Invoice_With_Filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Invoice_With_Filter_api("false");
                setGetall_Parter_Invoice_With_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Invoice_With_Filter = ', error);
            setGetall_Parter_Invoice_With_Filter_api("false");
            alert(" Impossible de recuperer la liste des factures");
            //setmyApimyApiMessage("")
        })
    }

    const [Send_Invoice_By_Email_api, setSend_Invoice_By_Email_api] = useState();
    const [Send_Invoice_By_Email_message, setSend_Invoice_By_Email_message] = useState();
    const [Send_Invoice_By_Email_result, setSend_Invoice_By_Email_result] = useState();
    function Send_Invoice_By_Email() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_id", selected_id);


        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Send_Partner_Invoice_By_Email/";


        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setSend_Invoice_By_Email_api("true");
                setSend_Invoice_By_Email_message(res.data.message);
                alert(res.data.message);
            }
            else {
                setSend_Invoice_By_Email_api("false");
                setSend_Invoice_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('Send_Invoice_By_Email : Not good man :( Send_Invoice_By_Email = ' + error);
            setSend_Invoice_By_Email_api("false");

            alert(" Impossible d'envoyer la facture par email");


        })


    }



    const [Get_Partner_Invoice_Lines_from_header_id_api, setGet_Partner_Invoice_Lines_from_header_id_api] = useState();
    const [Get_Partner_Invoice_Lines_from_header_id_message, setGet_Partner_Invoice_Lines_from_header_id_message] = useState();
    const [Get_Partner_Invoice_Lines_from_header_id_result, setGet_Partner_Invoice_Lines_from_header_id_result] = useState();
    function Get_Partner_Invoice_Lines_from_header_id(local_invoice_header_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_header_id", local_invoice_header_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Invoice_Lines/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In Get_Partner_Invoice_Lines_from_header_id  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Invoice_Lines_from_header_id  res.data.message r_class = " + res.data.message);
                setGet_Partner_Invoice_Lines_from_header_id_api("true");
                setGet_Partner_Invoice_Lines_from_header_id_result(res.data.message);
                setrows_invoice_lines(res.data.message);
            }
            else {
                setLoading(false);
                setGet_Partner_Invoice_Lines_from_header_id_api("false");
                setGet_Partner_Invoice_Lines_from_header_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Invoice_Lines_from_header_id = ', error);
            setGet_Partner_Invoice_Lines_from_header_id_api("false");
            alert(" Impossible de recuperer le detail de la facture");
            //setmyApimyApiMessage("")
        })
    }


    function Enable_Order_Header_DetailFields() {


        if (document.getElementsByName("detail_header_type")[0]) {
            document.getElementsByName("detail_header_type")[0].disabled = false;
            document.getElementsByName("detail_header_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_status")[0]) {
            document.getElementsByName("detail_header_status")[0].disabled = false;
            document.getElementsByName("detail_header_status")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_ref_interne")[0]) {
            document.getElementsByName("detail_header_ref_interne")[0].disabled = false;
            document.getElementsByName("detail_header_ref_interne")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_date_cmd")[0]) {
            document.getElementsByName("detail_header_date_cmd")[0].disabled = false;
            document.getElementsByName("detail_header_date_cmd")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_date_expiration")[0]) {
            document.getElementsByName("detail_header_date_expiration")[0].disabled = false;
            document.getElementsByName("detail_header_date_expiration")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_nom_client")[0]) {
            document.getElementsByName("detail_nom_client")[0].disabled = false;
            document.getElementsByName("detail_nom_client")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_description")[0]) {
            document.getElementsByName("detail_header_description")[0].disabled = false;
            document.getElementsByName("detail_header_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_comment")[0]) {
            document.getElementsByName("detail_header_comment")[0].disabled = false;
            document.getElementsByName("detail_header_comment")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_vendeur_nom_prenom")[0]) {
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].disabled = false;
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].style.backgroundColor = "#FFFFFF";
        }

        //----
        if (document.getElementsByName("detail_header_ref_cient")[0]) {
            document.getElementsByName("detail_header_ref_cient")[0].disabled = false;
            document.getElementsByName("detail_header_ref_cient")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_adresse")[0]) {
            document.getElementsByName("detail_header_fact_adresse")[0].disabled = false;
            document.getElementsByName("detail_header_fact_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_code_postal")[0]) {
            document.getElementsByName("detail_header_fact_code_postal")[0].disabled = false;
            document.getElementsByName("detail_header_fact_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_ville")[0]) {
            document.getElementsByName("detail_header_fact_ville")[0].disabled = false;
            document.getElementsByName("detail_header_fact_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_pays")[0]) {
            document.getElementsByName("detail_header_fact_pays")[0].disabled = false;
            document.getElementsByName("detail_header_fact_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_adresse")[0]) {
            document.getElementsByName("detail_header_liv_adresse")[0].disabled = false;
            document.getElementsByName("detail_header_liv_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_code_postal")[0]) {
            document.getElementsByName("detail_header_liv_code_postal")[0].disabled = false;
            document.getElementsByName("detail_header_liv_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_ville")[0]) {
            document.getElementsByName("detail_header_liv_ville")[0].disabled = false;
            document.getElementsByName("detail_header_liv_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_pays")[0]) {
            document.getElementsByName("detail_header_liv_pays")[0].disabled = false;
            document.getElementsByName("detail_header_liv_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_ht")[0]) {
            document.getElementsByName("detail_header_total_ht")[0].disabled = false;
            document.getElementsByName("detail_header_total_ht")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_tax")[0]) {
            document.getElementsByName("detail_header_total_tax")[0].disabled = false;
            document.getElementsByName("detail_header_total_tax")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_ttc")[0]) {
            document.getElementsByName("detail_header_total_ttc")[0].disabled = false;
            document.getElementsByName("detail_header_total_ttc")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_type_reduction")[0]) {
            document.getElementsByName("detail_header_type_reduction")[0].disabled = false;
            document.getElementsByName("detail_header_type_reduction")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_type_reduction_valeur")[0]) {
            document.getElementsByName("detail_header_type_reduction_valeur")[0].disabled = false;
            document.getElementsByName("detail_header_type_reduction_valeur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_montant_reduction")[0]) {
            document.getElementsByName("detail_header_montant_reduction")[0].disabled = false;
            document.getElementsByName("detail_header_montant_reduction")[0].style.backgroundColor = "#FFFFFF";
        }


    }

    function disable_Order_Header_DetailFields() {


        if (document.getElementsByName("detail_header_num_facture")[0]) {
            document.getElementsByName("detail_header_num_facture")[0].disabled = true;
            document.getElementsByName("detail_header_num_facture")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_type_document")[0]) {
            document.getElementsByName("detail_header_type_document")[0].disabled = true;
            document.getElementsByName("detail_header_type_document")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_facture")[0]) {
            document.getElementsByName("detail_header_date_facture")[0].disabled = true;
            document.getElementsByName("detail_header_date_facture")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_type")[0]) {
            document.getElementsByName("detail_header_type")[0].disabled = true;
            document.getElementsByName("detail_header_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_status")[0]) {
            document.getElementsByName("detail_header_status")[0].disabled = true;
            document.getElementsByName("detail_header_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_ref_interne")[0]) {
            document.getElementsByName("detail_header_ref_interne")[0].disabled = true;
            document.getElementsByName("detail_header_ref_interne")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_cmd")[0]) {
            document.getElementsByName("detail_header_date_cmd")[0].disabled = true;
            document.getElementsByName("detail_header_date_cmd")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_expiration")[0]) {
            document.getElementsByName("detail_header_date_expiration")[0].disabled = true;
            document.getElementsByName("detail_header_date_expiration")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_nom_client")[0]) {
            document.getElementsByName("detail_nom_client")[0].disabled = true;
            document.getElementsByName("detail_nom_client")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_email_client")[0]) {
            document.getElementsByName("detail_header_email_client")[0].disabled = true;
            document.getElementsByName("detail_header_email_client")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_description")[0]) {
            document.getElementsByName("detail_header_description")[0].disabled = true;
            document.getElementsByName("detail_header_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_comment")[0]) {
            document.getElementsByName("detail_header_comment")[0].disabled = true;
            document.getElementsByName("detail_header_comment")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_vendeur_nom_prenom")[0]) {
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].disabled = true;
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        //---
        if (document.getElementsByName("detail_header_ref_cient")[0]) {
            document.getElementsByName("detail_header_ref_cient")[0].disabled = true;
            document.getElementsByName("detail_header_ref_cient")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_adresse")[0]) {
            document.getElementsByName("detail_header_fact_adresse")[0].disabled = true;
            document.getElementsByName("detail_header_fact_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_code_postal")[0]) {
            document.getElementsByName("detail_header_fact_code_postal")[0].disabled = true;
            document.getElementsByName("detail_header_fact_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_ville")[0]) {
            document.getElementsByName("detail_header_fact_ville")[0].disabled = true;
            document.getElementsByName("detail_header_fact_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_pays")[0]) {
            document.getElementsByName("detail_header_fact_pays")[0].disabled = true;
            document.getElementsByName("detail_header_fact_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_adresse")[0]) {
            document.getElementsByName("detail_header_liv_adresse")[0].disabled = true;
            document.getElementsByName("detail_header_liv_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_code_postal")[0]) {
            document.getElementsByName("detail_header_liv_code_postal")[0].disabled = true;
            document.getElementsByName("detail_header_liv_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_ville")[0]) {
            document.getElementsByName("detail_header_liv_ville")[0].disabled = true;
            document.getElementsByName("detail_header_liv_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_pays")[0]) {
            document.getElementsByName("detail_header_liv_pays")[0].disabled = true;
            document.getElementsByName("detail_header_liv_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_ht")[0]) {
            document.getElementsByName("detail_header_total_ht")[0].disabled = true;
            document.getElementsByName("detail_header_total_ht")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_tax")[0]) {
            document.getElementsByName("detail_header_total_tax")[0].disabled = true;
            document.getElementsByName("detail_header_total_tax")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_ttc")[0]) {
            document.getElementsByName("detail_header_total_ttc")[0].disabled = true;
            document.getElementsByName("detail_header_total_ttc")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_type_reduction")[0]) {
            document.getElementsByName("detail_header_type_reduction")[0].disabled = true;
            document.getElementsByName("detail_header_type_reduction")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_type_reduction_valeur")[0]) {
            document.getElementsByName("detail_header_type_reduction_valeur")[0].disabled = true;
            document.getElementsByName("detail_header_type_reduction_valeur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_montant_reduction")[0]) {
            document.getElementsByName("detail_header_montant_reduction")[0].disabled = true;
            document.getElementsByName("detail_header_montant_reduction")[0].style.backgroundColor = "#ECEFF1";
        }


    }


    function clean_all_filters() {
        setp_filtre1();
        setp_filtre1_value();

        setp_filtre2();
        setp_filtre2_value();

        setp_filtre3();
        setp_filtre3_value();

        setp_filtre4();
        setp_filtre4_value();

        clean_order_detail_fields();
        setsubmenu();

        setSelectionModel([]);
        setselectionModel_invoice_lines([]);

        Getall_Parter_Invoice_No_Filter();
        setdisplay_detail_invoice();
    }

    function clean_order_detail_fields() {

    }


    return (

        <div className="partner_facture">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Vos Factures </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="ref_interne_invoice" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Num Facture &nbsp;</MenuItem>
                            <MenuItem value="ref_interne_cmd" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Interne Cmd &nbsp;</MenuItem>
                            <MenuItem value="ref_externe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Externe Cmd (chez le client) &nbsp;</MenuItem>
                            <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom Client  &nbsp;</MenuItem>
                            <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>


                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value);

                                }}
                            >
                              <MenuItem value="ref_interne_invoice" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Num Facture &nbsp;</MenuItem>
                            <MenuItem value="ref_interne_cmd" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Interne Cmd &nbsp;</MenuItem>
                            <MenuItem value="ref_externe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Externe Cmd (chez le client) &nbsp;</MenuItem>
                            <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom Client  &nbsp;</MenuItem>
                            <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>

                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre3"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre3}
                                onChange={(e) => {
                                    setp_filtre3(e.target.value)
                                }}
                            >
                              <MenuItem value="ref_interne_invoice" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Num Facture &nbsp;</MenuItem>
                            <MenuItem value="ref_interne_cmd" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Interne Cmd &nbsp;</MenuItem>
                            <MenuItem value="ref_externe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Ref. Externe Cmd (chez le client) &nbsp;</MenuItem>
                            <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom Client  &nbsp;</MenuItem>
                            <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3();
                                    setp_filtre3_value();
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Parter_Invoice_With_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule "statut"
                                    '& .cell--status--encours': {
                                        backgroundColor: '#E6F7C8',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--status--traite': {
                                        backgroundColor: '#E6CEAA',
                                        color: '#F9E79F',
                                        fontWeight: 'light',
                                    },




                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        if (newSelectionModel.length === 1)
                                            handleClick_edit_invoice_From_Line(newSelectionModel);
                                        if (newSelectionModel.length !== 1) {
                                            //clean_order_detail_fields();
                                            //setsubmenu();
                                            setdisplay_detail_invoice();


                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            order_header_ref_interne: JSON.parse(item).order_header_ref_interne,

                                            invoice_header_ref_interne: JSON.parse(item).invoice_header_ref_interne,
                                            invoice_header_type: JSON.parse(item).invoice_header_type,
                                            order_header_date_expiration: JSON.parse(item).order_header_date_expiration,
                                            invoice_date: JSON.parse(item).invoice_date,
                                            total_header_hors_taxe_after_header_reduction: JSON.parse(item).total_header_hors_taxe_after_header_reduction,

                                            order_header_tax_amount: JSON.parse(item).order_header_tax_amount,
                                            total_header_toutes_taxes: JSON.parse(item).total_header_toutes_taxes,


                                            order_header_client_id: JSON.parse(item).order_header_client_id,
                                            order_header_client_nom: JSON.parse(item).order_header_client_nom,
                                            order_header_vendeur_id: JSON.parse(item).order_header_vendeur_id,
                                            order_header_vendeur_nom_prenom: JSON.parse(item).order_header_vendeur_nom_prenom,
                                            order_header_date_cmd: JSON.parse(item).order_header_date_cmd,



                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {


                                        //field === "order_header_status"
                                        if (params.field === "order_header_status" && String(params.value) == "0") {
                                            return 'cell--status--brouillon';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "1") {

                                            return 'cell--status--encours';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "2") {
                                            return 'cell--status--traite';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "3") {
                                            return 'cell--status--facture';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>
            </div>

            <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
            {String(display_detail_invoice) === "1" &&
                <div className="div_row">
                    <nav style={{ "fontSize": "22px", "fontWeight": '600' }}> Facture  <i> {invoice_internal_ref} </i>: Affichage detaillé </nav>

                    <div className="session_data">
                        <div className="div_row" style={{ "textAlign": "right", "paddingTop": "10px" }}>
                            <Button variant="outlined" onClick={order_header_submenu_main} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Détail</Button>
                            <Button variant="outlined" onClick={order_header_submenu_detail} className="detail_class_submenu" id='order_header_detail' name='order_header_detail'>Menu Total  </Button>
                            <Button variant="outlined" onClick={order_header_submenu_facturation} className="detail_class_submenu" id='order_header_facturation' name='order_header_facturation'>Menu Facturation  </Button>

                        </div>

                        <div style={{ "width": "100%", "float": "left" }}>
                            <div style={{ "width": "75%", "float": "left" }}>

                                {String(header_submenu) !== "detail" && String(header_submenu) !== "facturation" && <div>

                                    <div className="session_caract"> Num Facture<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_num_facture"
                                            id="detail_header_num_facture"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_invoice_header_ref_interne}


                                        />
                                    </div>

                                    <div className="session_caract"> Type Document<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_document"
                                            id="detail_header_type_document"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_invoice_header_type}


                                        />
                                    </div>

                                    <div className="session_caract"> Date Facture<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_date_facture"
                                            id="detail_header_date_facture"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_invoice_date}


                                        />
                                    </div>




                                    <div className="session_caract"> Ref. Commande<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_ref_interne"
                                            id="detail_header_ref_interne"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_ref_interne}


                                        />
                                    </div>

                                    <div className="session_caract"> Date Commande<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_date_cmd"
                                            id="detail_header_date_cmd"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_date_cmd}


                                        />
                                    </div>




                                    <div className="session_caract"> Nom Client<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_nom_client"
                                            id="detail_nom_client"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_client_nom}


                                        />
                                    </div>

                                    <div className="session_caract"> Email Client<br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_email_client"
                                                id="detail_header_email_client"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_email_client}
                                                

                                            />
                                        </div>


                                    <div className="session_caract"> Commande Ref. Externe<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_ref_cient"
                                            id="detail_header_ref_cient"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_ref_client}


                                        />
                                    </div>

                                    <div className="session_caract"> Description<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_description"
                                            id="detail_header_description"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_description}


                                        />
                                    </div>

                                    <div className="session_caract"> Commentaire<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_comment"
                                            id="detail_header_comment"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_comment}


                                        />
                                    </div>

                                    <div className="session_caract"> Vendeur<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_vendeur_nom_prenom"
                                            id="detail_header_vendeur_nom_prenom"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_vendeur_nom}


                                        />
                                    </div>

                                </div>}



                                {String(header_submenu) === "detail" && <div>
                                    {/*<div className="div_row">
                                            <Button variant="outlined" onClick={Compute_Order_Header_Price} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Mettre a jour prix</Button>
                                            <Button variant="outlined" onClick={print_order_pdf} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Imprimer pdf</Button>
                                            <Button variant="outlined" onClick={Send_Order_By_Email} className="detail_class_submenu" id='order_header_main' name='order_header_main'>envoyer par mail</Button>
                                                    </div>*/}




                                    <div className="session_caract"> Type de Reduction (entete) <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction"
                                            id="detail_header_type_reduction"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_type_reduction}


                                        />

                                    </div>


                                    <div className="session_caract"> Valeur reduction (entete) <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_type_reduction_valeur}

                                        />
                                    </div>

                                    <div className="session_caract"> Montant Reduction (entete) <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_montant_reduction"
                                            id="detail_header_montant_reduction"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_total_reduction_amount}



                                        />
                                    </div>



                                    <div className="session_caract"> Total Ligne HT avant reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_lines_hors_taxe_before_lines_reduction}
                                        />
                                    </div>

                                    <div className="session_caract"> Total Ligne Reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_header_montant_reduction}
                                        />
                                    </div>

                                    <div className="session_caract"> Total Ligne HT apres reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_lines_hors_taxe_after_lines_reduction}
                                        />
                                    </div>


                                    <div className="session_caract"> Total Ligne Taxes <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_order_header_tax_amount}
                                        />
                                    </div>

                                    <div className="session_caract"> Entete : Total reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_header_total_reduction_amount}
                                        />
                                    </div>



                                    <div className="session_caract"> Entete : Total HT avant reduction d'entete <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_header_hors_taxe_before_header_reduction}
                                        />
                                    </div>

                                    <div className="session_caract"> Entete : Total HT apres reduction d'entete <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_header_hors_taxe_after_header_reduction}
                                        />
                                    </div>

                                </div>}

                                {String(header_submenu) === "facturation" && <div>

                                    <div className="session_caract"> Adresse Facturation <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_adresse"
                                            id="detail_header_fact_adresse"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_adr}


                                        />
                                    </div>

                                    <div className="session_caract"> Code postal <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_code_postal"
                                            id="detail_header_fact_code_postal"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_code_postal}

                                        />
                                    </div>

                                    <div className="session_caract"> Ville <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_ville"
                                            id="detail_header_fact_ville"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_ville}


                                        />
                                    </div>

                                    <div className="session_caract"> Pays <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_pays"
                                            id="detail_header_fact_pays"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_pays}


                                        />
                                    </div>



                                    <div className="session_caract"> Adresse Livraison <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_adresse"
                                            id="detail_header_liv_adresse"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_adr}


                                        />
                                    </div>

                                    <div className="session_caract"> Code postal <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_code_postal"
                                            id="detail_header_liv_code_postal"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_code_postal}


                                        />
                                    </div>

                                    <div className="session_caract"> Ville <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_ville"
                                            id="detail_header_liv_ville"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_ville}


                                        />
                                    </div>

                                    <div className="session_caract"> Pays <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_pays"
                                            id="detail_header_liv_pays"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_pays}


                                        />
                                    </div>


                                </div>}

                            </div>

                            <div style={{ "width": "23%", "float": "left", "textAlign": "right", "paddingTop": "2rem" }}>
                                <Button variant="outlined" onClick={print_invoice_pdf} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Imprimer pdf</Button>
                                <Button variant="outlined" onClick={Send_Invoice_By_Email} className="detail_class_submenu" id='order_header_main' name='order_header_main'>envoyer par mail</Button>

                            </div>

                        </div>
                    </div>


                    <div className="session_data" >

                        <div style={{ "border": "None" }}>
                            <nav onClick={"Ajouter_une_ligne_article"} style={{ "fontSize": "18px", "fontWeight": '600', "marginLeft": "10px", "marginTop": "10px", "cursor": "pointer" }}> <Button> Ajouter une annotation
                                &nbsp;
                            </Button> <img src={add_plus} alt="ajout commande" className="icon_plus_line" /> </nav>
                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',

                                        // Gestion des cellule a distance ou presentiel
                                        '& .cell--distantiel': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'black',
                                        },

                                        '& .cell--presentiel': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'black',
                                        },


                                        // gestion couleur des lignes avec le statut d'inscription
                                        '& .line--statut--annule': {
                                            backgroundColor: '#D8D8D8',
                                            color: 'white',
                                        },

                                        '& .line--statut--inscrit': {
                                            backgroundColor: '#CEF6D8',
                                            color: 'black',
                                        },
                                        '& .line--statut--preinscrit': {
                                            backgroundColor: '#F7F2E0',
                                            color: 'black',
                                        },

                                        /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                            "& .MuiDataGrid-row": {
                                                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                            }
                                        },*/
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            //setselectionModel_order_lines(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_order_From_Line(newSelectionModel);
                                            if (newSelectionModel.length !== 1) {
                                                setsubmenu();
                                                setdisplay_detail_order();
                                                setadd_One_Order();
                                            }*/
                                        }}
                                        selectionModel={selectionModel_invoice_lines}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={rows_invoice_lines.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                order_line_formation: JSON.parse(item).order_line_formation,
                                                order_line_qty: JSON.parse(item).order_line_qty,
                                                order_line_status: JSON.parse(item).order_line_status,
                                                order_line_prix_unitaire: JSON.parse(item).order_line_prix_unitaire,
                                                order_header_id: JSON.parse(item).order_header_id,
                                                order_header_ref_interne: JSON.parse(item).order_header_ref_interne,
                                                order_line_type: JSON.parse(item).order_line_type,
                                                order_line_formation_title: JSON.parse(item).title,
                                                order_line_formation_duration: JSON.parse(item).duration_concat,
                                                order_line_comment: JSON.parse(item).order_line_comment,
                                                order_line_formation_external_code: JSON.parse(item).order_line_formation_external_code,

                                            }
                                        ))}

                                        columns={columns_invoice_lines}
                                        pageSize={10}
                                        className="datagridclass"

                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}


                                    />
                                </Box>
                                <br />

                            </div>

                        </div>
                    </div>
                </div>}


        </div>
    )
}


export default Partner_Facture;
