import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import partnerbannerimg from "../mysy_img/MYSY_banner_compte.png";
import profileimg from "../mysy_img/MYSY-profil-2.png";
import TrainingForm from "./AddClassManual"
import PartnerTranings from "./DisplayPartnerTrainingsPagination";
import HebergementLms from "./HebergementLms";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import PartnerInfo from "./UpdatePartnerInfo";
import PartenairStat from "./PartenairStat";
import { Helmet } from "react-helmet";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import downarrow from "../mysy_img/downarrow.png";
import uparrow from "../mysy_img/uparrow.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import Box from '@mui/material/Box';
import Footer from "./Fotter";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";
import Header from "./Header";
import Facture from "./Facture";
import Abonnement from "./Abonnement";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom'
import CheckOut from "./CheckOut";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { FaHandPointRight } from "react-icons/fa";
import OrderConfirmation from "./OrderConfirmation";
import Pricing from "./Pricing";
import {
    FcHome, FcButtingIn, FcDepartment, FcBullish, FcAddDatabase, FcFolder,
    FcList, FcGraduationCap, FcMultipleDevices, FcCurrencyExchange,
    FcMoneyTransfer, FcFeedback, FcKey, FcVideoCall, FcSettings
} from "react-icons/fc";
import { DataGrid, GridToolbar, frFR, GridCellParams } from '@mui/x-data-grid';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcDeleteDatabase } from "react-icons/fc";
import { darken, lighten, styled } from '@mui/material/styles';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import AddParnerClient from "./AddPartnerClient";
//import { FcHome} from "react-icons/fc";
import { alpha } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import { AiOutlineUserAdd } from "react-icons/ai";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


const Partner_Client = (props) => {

    const history = useHistory();

    const [rows_list_clients, setrows_list_clients] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selected_id, setselected_id] = useState("");


    const columns_clients = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'raison_sociale', headerName: 'Raison sociale', minWidth: 50, maxWidth: 100, flex: 1, hide: false, editable: false, },
        { field: 'nom', headerName: 'nom', width: 100, hide: false, editable: false, minWidth: 10, flex: 1 },
        { field: 'email', headerName: 'email', width: 100, hideable: false, minWidth: 100, flex: 1 },

        { field: 'telephone', headerName: 'Téléphone', width: 100, hide: false, editable: false, minWidth: 100, maxWidth: 150, flex: 1 },
        { field: 'adr_adresse', headerName: 'Adresse', width: 100, hide: true, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'adr_code_postal', headerName: 'Code Postal', width: 100, hide: false, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'adr_ville', headerName: 'Ville', width: 100, hide: false, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'adr_pays', headerName: 'Pays', width: 100, hide: true, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'siret', headerName: 'Siret', width: 100, hide: true, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'website', headerName: 'Siteweb', width: 100, hide: true, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },


        /*{
            field: "update", headerName: 'Modifier',
            renderCell: (cellValues) => {
                return (

                    <div style={{ "marginLeft": "auto", "marginRight": "auto", "cursor": "pointer" }} onClick={(event) => {
                        handleClick_modifier(event, cellValues);
                    }}>
                        <FcAcceptDatabase style={{ "fontSize": "x-large" }} />
                    </div>

                );
            }
        },*/
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le client sera <i><font color="red"> définitivement supprimé</font></i>. <br />
                                    Avant, assurez-vous de n'avoir aucun stagiaire, un devis ou commande rattaché au client.

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_supprimer(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>



                );
            }
        },


    ]

    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const myRef_add_client = useRef(null)
    const myRef_head = useRef(null)

    const [Edite_Client, setEdite_Client] = useState("");
    const [Client_email, setClient_email] = useState("");
    function handleClick_modifier(event, cellValues) {
        var clientmail = String(cellValues.row.email);
        setClient_email(clientmail);
        setEdite_Client("1");
        window.scrollTo({
            top: 600,
            left: 0,
            behavior: "smooth",
        });
    }

    function handleClick_add_new_client(event, cellValues) {
        setEdite_Client("2");
        window.scrollTo({
            top: 600,
            left: 0,
            behavior: "smooth",
        });
    }


    function handleClick_supprimer(event, cellValues) {
        var clientmail = String(cellValues.row.email);
        var client_id = String(cellValues.row._id);
        Delete_Client_Data(client_id);
    }



    const [Delete_Client_Data_api, setDelete_Client_Data_api] = useState();
    const [Delete_Client_Data_message, setDelete_Client_Data_message] = useState();
    const [Delete_Client_Data_result, setDelete_Client_Data_result] = useState();
    function Delete_Client_Data(local_client_id) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("_id", local_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Client_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Client_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Client_Data_api("true");
                setDelete_Client_Data_result(res.data.message);
                Get_List_Partner_Clients();
                setSelectionModel([]);
                setEdite_Client();
                alert(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert(res.data.message);
                history.push("/Connexion");
            }
            else {
                setDelete_Client_Data_api("false");
                setDelete_Client_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Delete_Client_Data = ', error);
            setDelete_Client_Data_api("false");
            alert(" Impossible de supprimer le client");
        })
    }

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);
                setrows_list_clients(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            //setmyApimyApiMessage("")
        })
    }



    function close_detail_client() {
        setSelectionModel([]);
        setselected_id("");
        setgridline_id("");
        setEdite_Client("0");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }


    useEffect(() => {
        //menu_info();
        Get_List_Partner_Clients();


        if (props.object_key && String(props.object_key).length > 3) {
            handleClick_edit_session_From_Props(props.object_key);

        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

    }, []);



    const [part_client_file_name, setpart_client_file_name] = useState();
    function submenu_import_part_client_mass() {
        setpart_client_file_name();
        setliste_part_client_file_change_api();
        hiddenFileInput.current.click();
    }

    const hiddenFileInput = React.useRef(null);

    const [liste_part_client_file_change_api, setliste_part_client_file_change_api] = useState();
    const [liste_part_client_file_change_result, setliste_part_client_file_change_result] = useState();
    const [liste_part_client_file_change_message, setliste_part_client_file_change_message] = useState();
    const liste_part_client_file_change = event => {
        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setpart_client_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);

        //console.log("token = " + stored_cookie);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Client_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_part_client_file_change_result(result['message']);
                    setliste_part_client_file_change_api("true");

                    alert(result['message']);
                    Get_List_Partner_Clients();


                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setliste_part_client_file_change_message(result['message']);
                    setliste_part_client_file_change_api("false");
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setliste_part_client_file_change_api("false");
            });

    };

    const datagridSx = {
        borderRadius: 2,
        marginRight: 1,
        marginLeft: 1,
        border: 0,
        "& .MuiDataGrid-main": { borderRadius: 0 },
        /*'& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
          color: "blue",
          fontSize: 18,
          //risky
          minHeight: "60px !important",
          height: 60,
          "& div": {
            minHeight: "60px !important",
            height: 60,
            lineHeight: "59px !important"
          }
        },
        "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
            }
        },*/
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#c8cfd5",
            color: "black",
            fontSize: 16
        }
    };


    function handleClick_edit_session_From_Line(selected_row_id) {
        if (rows_list_clients[selected_row_id]) {


            var line = JSON.parse(rows_list_clients[selected_row_id]);
            var clientmail = String(line.email);

            var client_id = String(line._id);
            setselected_id(client_id);

            setClient_email(clientmail);
            setEdite_Client("1");

            window.scrollTo({
                top: 800,
                left: 0,
                behavior: "smooth",
            });


        }
    }



    function handleClick_edit_session_From_Props(props_client_id) {
        setselected_id(props_client_id);
        setEdite_Client("1");

        window.scrollTo({
            top: 800,
            left: 0,
            behavior: "smooth",
        });


    }



    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();


    function clean_all_filters() {
        setp_filtre1();
        setp_filtre1_value();
        setp_filtre2();
        setp_filtre2_value();


    }

    function father_scroll_to_top() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    const [gridline_id, setgridline_id] = useState("");

    const myRef_detail_client = useRef(null)



    function Show_Detailled_client_Data() {

        Dialog_client_handleClose_buton();
        handleClick_edit_session_From_Line(gridline_id);
       
    }


    const [selected_row_data_json_age, setselected_row_data_json_age] = useState("");
    const [fiche_client_id, setfiche_client_id] = React.useState("");

    const [Dialog_client_message, setDialog_client_message] = React.useState(false);
    const [Dialog_client_open, setDialog_client_open] = React.useState(false);

    function Dialog_client_handle_change(message) {
        setDialog_client_message(message);
        setDialog_client_open(true);
    }


    const Dialog_client_handleClose_buton = () => {
        setDialog_client_open(false);
    };

    const Dialog_client_handleClose = () => {
        setDialog_client_open(false);
    };


    return (

        <div className="partner_client">

            <Dialog
                open={Dialog_client_open}
                onClose={Dialog_client_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>client</DialogTitle>
                <DialogContent className="DialogContent_width">
                   
                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Raison Sociale
                        </div>
                        {Get_List_Partner_Clients_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).raison_socile}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Nom
                        </div>
                        {Get_List_Partner_Clients_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).nom}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            E-mail
                        </div>
                        {Get_List_Partner_Clients_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).email}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Téléphone
                        </div>
                        {Get_List_Partner_Clients_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).telephone}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                             Adresse postale
                        </div>
                        {Get_List_Partner_Clients_result && selected_id && 
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).adr_adresse} <br/>
                                 {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).adr_code_postal} {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).adr_pays} <br/>
                                 {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).adr_pays.toUpperCase()}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Site Web 
                        </div>
                        {Get_List_Partner_Clients_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Get_List_Partner_Clients_result[gridline_id]).website}
                            </div>}
                    </div>

                    <div className="div_row" > &nbsp;

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Show_Detailled_client_Data} className="bton_enreg_dialog">Voir detail</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_client_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>


            <div className="div_row">


                <h3> Vos clients</h3>

                <div className="titre1">  Utilisez les filtres !</div>
                <div>
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter" >
                            <TextField
                                name="filtre1"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                value={p_filtre1}
                                onChange={(e) => {
                                    setp_filtre1(e.target.value)
                                }}
                            >

                                <MenuItem value="nom_client" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Nom &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="email_client" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>

                            </TextField>
                        </div>



                        <div className="div_row_droite texte_area_filter_value" >
                            {p_filtre1 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre1_value"
                                    id="filtre1_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre1_value}
                                    onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre1_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />

                            }
                        </div>
                        {p_filtre1 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline onClick={(e) => {
                                    setp_filtre2("1");
                                }} />
                            </a>
                        </div>}
                        {p_filtre1 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre1();
                                    setp_filtre1_value();
                                }}
                                />
                            </a>
                        </div>}


                    </div>

                    {p_filtre2 &&
                        <div className="div_row" style={{ "marginBottom": "5px" }}>
                            <div className="div_row_gauche texte_area_filter">
                                <TextField
                                    name="filtre2"
                                    label="Choisir un champ"
                                    select
                                    sx={{ m: 1, width: '100%' }}
                                    value={p_filtre2}
                                    onChange={(e) => {
                                        setp_filtre2(e.target.value)
                                    }}
                                >

                                    <MenuItem value="nom_client" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Nom &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="email_client" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>

                                </TextField>
                            </div>
                            <div className="div_row_droite texte_area_filter" >
                                {String(p_filtre2).length > 3 &&
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="filtre2_value"
                                        id="filtre2_value"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_filtre2_value}
                                        onChange={(e) => setp_filtre2_value(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AiFillCloseCircle
                                                        style={{ 'cursor': "pointer", "color": "orangered" }}
                                                        onClick={(e) => {
                                                            setp_filtre2_value("");
                                                        }} />
                                                </InputAdornment>
                                            ),
                                        }}

                                    />}
                            </div>


                            {String(p_filtre2).length > 3 && <div className='filter_bton_add'>
                                <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                    &nbsp; <MdRemoveCircleOutline
                                        onClick={(e) => {
                                            setp_filtre2();
                                            setp_filtre2_value();
                                        }}
                                    />
                                </a>
                            </div>}
                        </div>
                    }


                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                            <Button variant="contained" className="bton_enreg" onClick={Get_List_Partner_Clients} ref={myRef_head}>Rechercher
                            </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                            <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                            </Button>

                        </div>

                    </div>}



                </div>

                <div className="div_row" style={{ paddingRight: '10px' }}>
                    <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>

                        &nbsp;
                        <Box
                            sx={{
                                height: "500px",
                                width: '100%',
                                paddingRight: '1px',
                                '& .cell--presentiel': {
                                    backgroundColor: '#a2cf6e',
                                    color: '#1a3e72',
                                    fontWeight: 'bold',
                                },

                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#c8cfd5",
                                    color: "black",
                                    fontSize: 14
                                },

                                '& .line--statut--selected': {
                                    backgroundColor: '#FBF2EF',
                                    color: 'black',
                                },
                                '& .line--statut--pair': {
                                    backgroundColor: 'rgba(235, 235, 235, .7)',
                                    color: 'black',
                                },
                                '& .line--statut--impair': {
                                    backgroundColor: '#FFFFFF',
                                    color: 'black',
                                },

                            }}
                        >
                            <DataGrid
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={rows_list_clients.map((item, index) => (
                                    {
                                        id: index,
                                        _id: JSON.parse(item)._id,
                                        email: JSON.parse(item).email,
                                        nom: JSON.parse(item).nom,
                                        raison_sociale: JSON.parse(item).raison_sociale,
                                        telephone: JSON.parse(item).telephone,
                                        adr_adresse: JSON.parse(item).adr_adresse,
                                        adr_code_postal: JSON.parse(item).adr_code_postal,
                                        adr_ville: JSON.parse(item).adr_ville,
                                        adr_pays: JSON.parse(item).adr_pays,
                                        siret: JSON.parse(item).siret,
                                        website: JSON.parse(item).website,

                                    }
                                ))}

                                columns={columns_clients}
                                pageSize={10}
                                className="datagridclass"

                                rowsPerPageOptions={[10]}
                                //disableSelectionOnClick
                                checkboxSelection
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                //sx={datagridSx}
                                onSelectionModelChange={(newSelectionModel) => {

                                    setSelectionModel(newSelectionModel);
                                    /* if (newSelectionModel.length === 1){
                                         
                                         handleClick_edit_session_From_Line(newSelectionModel);
                                     }
 
                                     else{
                                         setEdite_Client();
                                     }*/
                                }}
                                selectionModel={selectionModel}

                                onRowDoubleClick={(newSelectionModel) => {
                                    setgridline_id(newSelectionModel.row.id);
                                    setselected_id(newSelectionModel.row._id)
                                    //handleClick_edit_session_From_Line(newSelectionModel.row.id);

                                    setDialog_client_open(true);

                                }}

                                getRowClassName={(params) => {
                                    // Pour la gestion de la couleur de zone double cliquée
                                    if (String(params.row.id) === String(gridline_id)) {
                                        return 'line--statut--selected';
                                    }
                                    else if (parseInt(String(params.row.id)) % 2 === 0) {
                                        return 'line--statut--pair';
                                    }
                                    else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                        return 'line--statut--impair';
                                    }
                                }}

                            />
                        </Box>
                        <br />


                        <br />
                        {/* <Button variant="contained" onClick={GetAllClass_new}>Contained</Button>*/}
                    </div>
                </div>



                <div className="div_row" ref={myRef_detail_client}>

                    <Button variant="outlined" onClick={submenu_import_part_client_mass} className="detail_class_submenu bton_import_excel"
                        id='menu_import_participant' name='menu_import_participant'>Choisir un fichier et Importer &nbsp;
                        <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                    </Button>

                    <Button variant="outlined" onClick={handleClick_add_new_client}
                        className="detail_class_submenu bton_add_session"
                        id='menu_one_participant' name='menu_one_participant'><img src={participants} alt="ajout un client" className="icon_plus" /> &nbsp;
                        Ajouter 1 Client

                    </Button>

                    <br />
                    <a href='/sample/template_import_client.csv' download>Télécharger un fichier modèle</a>
                    <br />

                    <i>{part_client_file_name}</i><br />
                    {String(liste_part_client_file_change_api) === String("true") && <nav className="okUpdateData"> Les client ont été correctement importés</nav>}
                    {String(liste_part_client_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_part_client_file_change_message} </nav>}

                    <input type="file"
                        accept=".csv"
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                        name="liste_participants_file"
                        onChange={liste_part_client_file_change}
                    />

                </div>

            </div>


            {String(Edite_Client) === "1" && <div className="div_row" style={{ "paddingRight": "10px" }}>

                <div className="titre1"> Detail client : {Client_email}</div>

                <AddParnerClient client_mail={Client_email} close_detail_client={close_detail_client} father_scroll_to_top={father_scroll_to_top}
                    client_id={selected_id} />

            </div>}

            {String(Edite_Client) === "2" && <div className="div_row">

                <div className="titre1"> Detail client : {Client_email}</div>
                <div ref={myRef_add_client}> &nbsp;</div>
                <AddParnerClient client_mail={Client_email} close_detail_client={close_detail_client} new_customer="1" client_id={selected_id}
                    Get_List_Partner_Clients={Get_List_Partner_Clients} father_scroll_to_top={father_scroll_to_top} />

            </div>}


        </div>
    )
}


export default Partner_Client;