import Navigation from "./Navigation";
import { Helmet } from "react-helmet";

import ousommesnousimg from "../mysy_img/mysy_training_technology_ici.png"

import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import Button from '@mui/material/Button';
import img_1_old from "../mysy_img/1.jpg";
import gestion_admin_img from "../mysy_img/gestion_admin_img.png";
import e_learning_lms from "../mysy_img/e_learning_lms.png";
import img_2 from "../mysy_img/lms.png";
import img_3_old from "../mysy_img/3.jpg";
import mysy_crm from "../mysy_img/mysy_crm.png";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
import tick_circle_ok from "../mysy_img/tick_circle_ok.png";



const QuiSommesNous_v3 = () => {

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
            >
                <nav className="contactez_nous_new">  {label} </nav>
            </Link>
        );
    };

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap

            defaultZoom={18}
            defaultCenter={{ lat: 48.866669, lng: 2.33333 }}
        >
            {props.isMarkerShown && <Marker position={{ lat: 48.866669, lng: 2.33333 }} />}
        </GoogleMap>
    ))

    function sendEmail() {
        window.location.assign("mailto:contact@mysy-training.com");

    };

    return (
        <div className="quisommesnous_v3">
            <Helmet>
                <title>MySy Training, qui sommes nous ?</title>
                <meta name="description" content="éditeur de logiciels pour établissements de formation. Nous proposons des solution de E-Learning, gestion administrative et gestion relation client" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Formations.Gestion administrative.E-Learning.CRM" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/QuiSommesNous"} />

            </Helmet>
            <Header />


            <div className="div_mobile_v3" >


                <div className="Background-header">

                </div>
                <div className="div_mobile_v3_debut">

                    <div className="Title">
                        <div className="Title_txt1">
                            Qui sommes-nous ?
                        </div>
                        <div className="Title_txt2">
                            MySy Training
                        </div>

                    </div>

                    <div className="text_seul_1">
                        MySy Training est un éditeur de logiciels dédiés à la gestion des établissements de formation : Instituts de formation, Indépendants, Ecoles, Universités, etc.
                    </div>

                    <div className="parent">
                        <div className="div1">
                            <div className="text_activite_head">
                                Gestion Administrative
                            </div>
                            <br />
                            <div className="image_activite_head">
                                <img src={gestion_admin_img} alt="MySy Training Gestion administrative" className="image_illustration" />
                            </div>

                            <button className="bton_en_savoir_plus_header" onClick={(e) => {
                                if (document.getElementById('gestion_administrative')) {
                                    var divh = document.getElementById('gestion_administrative').offsetTop;

                                    window.scrollTo({
                                        top: divh,
                                        behavior: "smooth",
                                    });
                                }

                            }}
                            > En savoir plus</button>
                        </div>

                        <div className="div2">
                            <div className="text_activite_head">
                                E-Learning (LMS)
                            </div>
                            <div className="image_activite_head">
                                <img src={e_learning_lms} alt="MySy Training E-Learning LMS" className="image_illustration" />
                            </div>
                            <br />

                            <button className="bton_en_savoir_plus_header" onClick={(e) => {
                                if (document.getElementById('gestion_elearning')) {
                                    var divh = document.getElementById('gestion_elearning').offsetTop;

                                    window.scrollTo({
                                        top: divh,
                                        behavior: "smooth",
                                    });
                                }

                            }}> En savoir plus</button>
                        </div>

                        <div className="div3">

                            <div className="text_activite_head">
                                Relation Client (CRM)
                            </div>
                            <br />
                            <div className="image_activite_head">
                                <img src={mysy_crm} alt="MySy Training gestion relation client CRM " className="image_illustration" />
                            </div>

                            <button className="bton_en_savoir_plus_header" onClick={(e) => {
                                if (document.getElementById('gestion_crm')) {
                                    var divh = document.getElementById('gestion_crm').offsetTop;

                                    window.scrollTo({
                                        top: divh,
                                        behavior: "smooth",
                                    });
                                }

                            }}> En savoir plus</button>
                        </div>

                    </div>
                    <div className="text_nous_conseillons">
                        Nous conseillons, accompagnons nos clients dans  la <b>structuration </b>, l' <b>audit </b> et l' <b>organisation</b> de leurs processus et outils . Avec une équipe d'une dizaine de personnes, nous développons des solutions axées sur l'efficacité et le gain de productivité de nos clients, sans oublier la sécurisation de leurs systèmes.
                    </div>

                    <div id="gestion_administrative"> &nbsp; </div>
                    <div className="content_gestion_admin">

                        <div className="content_gestion_admin_img">
                            <div className="image_activite_detail">
                                <img src={gestion_admin_img} alt="MySy Training Gestion administrative" className="image_illustration" />
                            </div>
                        </div>

                        <div className="content_gestion_admin_txt" >
                            <div>
                                <div className="detail_content_text_head_1">
                                    GESTION
                                </div>

                                <div className="detail_content_text_head_2" >
                                    Gestion Administrative
                                </div>

                                <div className="detail_content_text_head_3">
                                    Permet de gérer le processus d’inscription des apprenants, les émargements à distance ou en présentiel et les évaluations. Cette solution permet également de gérer les enseignants, le matériel pédagogique, le planning de formation, les agendas, etc.
                                </div>

                                <div className="detail_content_text_head_3">
                                    Elle vous permet également de produire en un clic votre Bilan Pédagogique et Financier (BPF) Un intranet intégré permet de publier et de partager les formations sur internet.   </div>
                            </div>

                        </div>
                    </div>

                    <div id="gestion_elearning"> &nbsp; </div>
                    <div className="content_gestion_elearning" >

                        <div className="content_gestion_elearning_img">
                            <div className="image_activite_detail">
                                <img src={e_learning_lms} alt="MySy Training E-Learning LMS" style={{ "width": "60%" }} className="image_illustration" />
                            </div>
                        </div>

                        <div className="content_gestion_elearning_txt" >
                            <div>
                                <div className="detail_content_text_head_1">
                                    E-LEARNING
                                </div>

                                <div className="detail_content_text_head_2" >
                                    E-Learning (LMS)
                                </div>

                                <div className="detail_content_text_head_3_gauche">
                                    Permet d’avoir votre espace de E-learning, de publier les formations en ligne. A l’aide de son login et mot de passe, chaque apprenant peut suivre la formation en ligne, interagir avec l’enseignant (email, réseaux social).    </div>

                                <div className="detail_content_text_head_3_gauche">
                                    L’enseignant peut élaborer des travaux dirigés, suivre le temps de réponses des apprenants, corriger les TD.Un tableau de bord permet de suivre de manière détaillée chaque apprenant.  </div>
                            </div>

                        </div>
                    </div>

                    <div id="gestion_crm"> &nbsp; </div>
                    <div className="content_gestion_admin">

                        <div className="content_gestion_admin_img">
                            <div className="image_activite_detail">
                                <img src={mysy_crm} alt="MySy Training gestion relation client CRM " className="image_illustration" />
                            </div>
                        </div>

                        <div className="content_gestion_admin_txt" >
                            <div>
                                <div className="detail_content_text_head_1">
                                    RELATION CLIENT (CRM)
                                </div>

                                <div className="detail_content_text_head_2">
                                    Gestion Relation Client
                                </div>

                                <div className="detail_content_text_head_3">
                                    Permet de gérer vos clients, les activités en cours, à venir, en retards. Ce module permet d’éditer, d’envoyer les devis, de les convertir en commande et de les facturer.    </div>

                                <div className="detail_content_text_head_3">
                                    Un tableau de bord vous permet de suivre vos revenus en cours, ceux à venir et de prendre les bonnes décisions de gestion
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="fonctionalite_header">
                    MySy Training Technology
                    Fonctionnalités
                </div>

                <div className="parent">
                    <div className="div1">
                        <br />

                        <div className="text_activite_head">
                            Gestion Administrative
                        </div>


                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion catalogue formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion catalogue formations (intranet / API) </nav>
                        </div>


                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion catalogue formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des inscriptions (mise en attente, motif annulation, etc) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion automatique et manuelle des séquences de formations" /></nav>
                            <nav className="tick_fonctionalite_txt">Gestion automatique et manuelle des séquences de formations </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion avis participants" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion avis participants</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt=" Gestion absences" /></nav>
                            <nav className="tick_fonctionalite_txt">  Gestion absences</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Émargement électronique" /></nav>
                            <nav className="tick_fonctionalite_txt"> Émargement électronique</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion des employés et du matériel pédagogique" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des employés et du matériel pédagogique</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Gestion des agendas & réservation de matériel (salles, projecteurs, etc)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des agendas & réservation de matériel (salles, projecteurs, etc)</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Tableau de bord de pilotage" /></nav>
                            <nav className="tick_fonctionalite_txt"> Tableau de bord de pilotage</nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">  <img src={tick_circle_ok} alt="Intranet intégré pour la publication des formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Intranet intégré pour la publication des formations</nav>
                        </div>


                    </div>

                    <div className="div2">


                        <br />
                        <div className="text_activite_head">
                            E-Learning (LMS)
                        </div>
                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Hébergement des formations" /></nav>
                            <nav className="tick_fonctionalite_txt"> Hébergement des formations </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Classe virtuelle" /></nav>
                            <nav className="tick_fonctionalite_txt"> Classe virtuelle </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des prérequis" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des prérequis </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Echange enseignants / apprenants" /></nav>
                            <nav className="tick_fonctionalite_txt"> Echange enseignants / apprenants </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Cours en ligne avec exercices" /></nav>
                            <nav className="tick_fonctionalite_txt"> Cours en ligne avec exercices </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Evaluations (notes)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Evaluations (notes) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion planning" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion planning </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Forums de discussion" /></nav>
                            <nav className="tick_fonctionalite_txt"> Forums de discussion </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Réseau Social" /></nav>
                            <nav className="tick_fonctionalite_txt"> Réseau Social </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Profil et droit d'accès par utilisateur" /></nav>
                            <nav className="tick_fonctionalite_txt"> Profil et droit d'accès par utilisateur </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Export format SCORM" /></nav>
                            <nav className="tick_fonctionalite_txt"> Export format SCORM </nav>
                        </div>
                    </div>

                    <div className="div3">
                        <br />

                        <div className="text_activite_head">
                            Gestion Relation Client
                        </div>
                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Création clients" /></nav>
                            <nav className="tick_fonctionalite_txt"> Création clients </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des différentes contacts client" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des différentes contacts client </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des activités par collaborateur (tâches en cours, en retards, à venir)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des activités par collaborateur (tâches en cours, en retards, à venir) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Gestion des devis (envoi automatique)" /></nav>
                            <nav className="tick_fonctionalite_txt"> Gestion des devis (envoi automatique) </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Validation automatique des devis" /></nav>
                            <nav className="tick_fonctionalite_txt"> Validation automatique des devis </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Conversion devis en commandes" /></nav>
                            <nav className="tick_fonctionalite_txt"> Conversion devis en commandes </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Facturation et envoi automatique" /></nav>
                            <nav className="tick_fonctionalite_txt"> Facturation et envoi automatique </nav>
                        </div>

                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Suivi des commandes en cours, des factures à venir" /></nav>
                            <nav className="tick_fonctionalite_txt"> Suivi des commandes en cours, des factures à venir </nav>
                        </div>


                        <div className="tick_fonctionalite">
                            <nav className="tick_fonctionalite_tick">   <img src={tick_circle_ok} alt="Tableau de bord de suivi client" /></nav>
                            <nav className="tick_fonctionalite_txt"> Tableau de bord de suivi client </nav>
                        </div>
                    </div>

                </div>

                <div className="fonctionalite_header">
                    Découvrez nos fonctionnalités
                </div>
                <div className="pave_video">

                    <div className="pave_video_gauche">
                        <div className="video_titre">
                            Le catalogue de formation
                        </div>




                    </div>

                    <div className="pave_video_droite">
                        <div className="video_mp4">
                            <div className="only_mobile">
                                <div className="video_titre">
                                    Le catalogue de formation
                                </div>


                            </div>

                            <ReactPlayer
                                controls={true}
                                light="https://img.mysy-training.com/video/mysy_catalog_thumb.png"
                                playing
                                url='https://img.mysy-training.com/video/MySy_Creation_Catalogue.mp4'
                                width="100%"
                            />
                        </div>
                    </div>


                </div>
                <div className="pave_video">

                    <div className="pave_video_gauche">
                        <div className="video_titre">
                            Les sessions  de formation
                        </div>




                    </div>

                    <div className="pave_video_droite">
                        <div className="video_mp4">

                            <div className="only_mobile">
                                <div className="video_sous_titre">
                                    <div className="video_titre">
                                        Les sessions  de formation
                                    </div>

                                </div>
                            </div>
                            <ReactPlayer
                                controls={true}
                                light="https://img.mysy-training.com/video/video_session/mysy_session_thumb.png"
                                playing
                                url='https://img.mysy-training.com/video/video_session/MySy_Creation_Session.mp4'
                                width="100%"
                            />
                        </div>
                    </div>


                </div>
                <div className="pave_video">

                    <div className="pave_video_gauche">
                        <div className="video_titre">
                            Les séquences de Formation
                        </div>



                    </div>

                    <div className="pave_video_droite">
                        <div className="video_mp4">

                            <div className="only_mobile">
                                <div className="video_titre">
                                    Les séquences de Formation
                                </div>

                            </div>
                            <ReactPlayer
                                controls={true}
                                light="https://img.mysy-training.com/video/video_sequence/mysy_sequence_thumb.png"
                                playing
                                url='https://img.mysy-training.com/video/video_sequence/MySy_Creation_Sequences.mp4'
                                width="100%"
                            />
                        </div>
                    </div>


                </div>

                <div className="div_row_where">

                    <div className="div_carte">
                        <a target="_blank"
                            href="https://www.google.com/maps/place/MySy+Training+Technology/@48.8761591,2.3260624,15z/data=!4m5!3m4!1s0x0:0x8239ba23406c9699!8m2!3d48.8761591!4d2.3260624">
                            <img src={ousommesnousimg} alt="logo MySy Technology ou sommes nous" style={{ 'width': "100%", 'height': '50%' }} />
                        </a>


                    </div>

                    <div className="div_adresse_new" >
                        <br />

                        <ButtonMailto label="CONTACTEZ NOUS" mailto="mailto:contact@mysy-training.com" className="contactez_nous" />

                        <div className="contactez_nous_titre">MySy Training Technology</div>

                        <div className="contactez_nous_info">

                            Adresse : 1 Cr du Havre, 75008 Paris <br />
                            Adresse email :
                            <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                            Téléphone fixe : +331 77 00 38 57<br />
                            Téléphone : +337 69 20 39 45<br />

                        </div>
                        <br />
                        <div> Sur nos reseaux sociaux

                            <div style={{ 'float': "center", "textAlign": "center" }}>
                                <div className="media_sociaux">
                                    <FacebookShareButton
                                        url={"https://www.facebook.com/MySy-Training-114572794561371/"}
                                    >
                                        <SocialIcon network="facebook" className="SocialIcon" />
                                    </FacebookShareButton>
                                    &nbsp;

                                    <TwitterShareButton
                                        url={"https://twitter.com/mysy_training"}
                                        hashtag={"#MySyTraining"}
                                    >
                                        <SocialIcon network="twitter" className="SocialIcon" />
                                    </TwitterShareButton>

                                    &nbsp;
                                    <LinkedinShareButton
                                        url={"https://www.linkedin.com/"}
                                        summary={"ee"}
                                        source={"https://www.linkedin.com/in/mysy-training-96b53a237/"}

                                    >
                                        <SocialIcon network="linkedin" className="SocialIcon" />
                                    </LinkedinShareButton>


                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="pieddepage">
                    <Footer />
                </div>

            </div>

        </div>

    )
}


export default QuiSommesNous_v3;
