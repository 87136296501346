import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { getCookie, setCookie } from 'react-use-cookie';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home'
import { Button, Form, FormGroup, Label } from "reactstrap";

import { useHistory } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from "axios";
import { ConsoleView } from "react-device-detect";
import MenuItem from '@mui/material/MenuItem';

import Partner_Securite_Data from "./Partner_Securite_Data";
import Partner_Invoicing_Data from "./Partner_Invoicing_Data";


function UpdateParnterInfo(props) {
  const history = useHistory();
  const [myApiResponse, setmyApiResponse] = useState("");
  const [result, setResult] = useState("");
  const [testval, settestval] = useState();
  const [fields1desabled, setfields1desabled] = useState(true);
  const [mailfieldsdesabled, setmailfieldsdesabled] = useState(true);
  const [pwdfieldsdesabled, setpwdfieldsdesabled] = useState(true);

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [valuesPass1, setValuesPass1] = React.useState({
    password1: '',
    showPassword1: false,
  });

  const [valuesPass2, setValuesPass2] = React.useState({
    password2: '',
    showPassword2: false,
  });

  const [valuesPass3, setValuesPass3] = React.useState({
    password3: '',
    showPassword3: false,
  });



  const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
  const [newpawdvisible, setnewpawdvisible] = useState(false);
  const [confpwdvisible, setconfpwdvisible] = useState(false);
  const [updatemainmail, setupdatemainmail] = useState();
  const [updatepwd, setupdatepwd] = useState();


  // recuperation et gestion  du token utilisateur 
  const stored_user = getCookie('tokenmysypart');
  if (!stored_user || stored_user.length <= 0) {
    history.push("/PartnerLogin");
  }


  const myserver = "127.0.0.1";
  //const myserver = "89.156.84.196";
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();


  useEffect(() => {
    getCurrentUserData();
    //desablesecurtymailfields();
    //desablesecurtypwdfields();
    //desablesecurtyInsertKeyfields();
    getPartnerImages();


  }, [])


  const [userformedited, setuserformedited] = useState();


  const [insert_key_api, setinsert_key_api] = useState("");
  const [insert_key_api_message, setinsert_key_api_message] = useState("");
  const [insert_key_updated, setinsert_key_updated] = useState();


  function UpdatePartnerInsertKey() {

    setinsert_key_updated();
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_insert_key/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/PartnerLogin");
    }

    var current_key = document.getElementsByName("current_insert_key")[0].value;
    var new_key = document.getElementsByName("new_insert_key")[0].value;
    var conf_new_key = document.getElementsByName("conf_new_insert_key")[0].value;



    document.getElementsByName("identique_insert_key")[0].style.backgroundColor = "white";
    if (new_key !== conf_new_key) {
      document.getElementsByName("identique_insert_key")[0].style.backgroundColor = "red";
      return;
    }

    document.getElementsByName("caractere_insert_key")[0].style.backgroundColor = "white";
    if (String(new_key).length < 8) {
      document.getElementsByName("caractere_insert_key")[0].style.backgroundColor = "red";
      return
    }

    var regex = /\d/g;
    document.getElementsByName("nombre_insert_key")[0].style.backgroundColor = "white";
    if (!regex.test(new_key)) {
      document.getElementsByName("nombre_insert_key")[0].style.backgroundColor = "red";
      return
    }

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    document.getElementsByName("special_insert_key")[0].style.backgroundColor = "white";
    if (!specialChars.test(new_key)) {
      document.getElementsByName("special_insert_key")[0].style.backgroundColor = "red";
      return
    }


    formData.append('current_insert_key', current_key);
    formData.append('new_insert_key', new_key);
    formData.append('conf_insert_key', conf_new_key);
    formData.append('partner_account_id', partner_account_id);



    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setinsert_key_api(result['status']);
        setinsert_key_api_message(result['message']);
        if (String(result['status']) === String("true")) {
          desablesecurtyInsertKeyfields();
          document.getElementsByName("current_insert_key")[0].value = new_key;
          setinsert_key_updated(true);
        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        }


      })
      .catch((error) => {
        console.error('Error:', error);
        setinsert_key_api("false");
        setinsert_key_updated(false);
      });
  }


  function UpdatePartnerPwd() {

    //setupdatepwd(false);
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_pwd/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/PartnerLogin");
    }

    var pwd = document.getElementsByName("current_pwd")[0].value;
    var new_pwd = document.getElementsByName("new_pwd")[0].value;
    var conf_new_pwd = document.getElementsByName("new_pwd_conf")[0].value;


    formData.append('pwd', pwd);
    formData.append('new_pwd', new_pwd);
    formData.append('conf_new_pwd', conf_new_pwd);
    formData.append('partner_account_id', partner_account_id);

    document.getElementsByName("identique")[0].style.backgroundColor = "white";
    if (new_pwd !== conf_new_pwd) {
      document.getElementsByName("identique")[0].style.backgroundColor = "red";
      return;
    }

    document.getElementsByName("caractere")[0].style.backgroundColor = "white";
    if (String(new_pwd).length < 8) {
      document.getElementsByName("caractere")[0].style.backgroundColor = "red";
      return
    }

    var regex = /\d/g;
    document.getElementsByName("nombre")[0].style.backgroundColor = "white";
    if (!regex.test(new_pwd)) {
      document.getElementsByName("nombre")[0].style.backgroundColor = "red";
      return
    }

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    document.getElementsByName("special")[0].style.backgroundColor = "white";
    if (!specialChars.test(new_pwd)) {
      document.getElementsByName("special")[0].style.backgroundColor = "red";
      return
    }

    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) == String("true")) {
          setupdatepwd(true);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        setupdatepwd(false);
      });
  }


  function UpdatePartnerMail() {

    setupdatemainmail(false);
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_main_mail/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/PartnerLogin");
    }

    var mail = document.getElementsByName("current_email")[0].value;
    var new_mail = document.getElementsByName("new_email")[0].value;
    var conf_new_mail = document.getElementsByName("conf_new_email")[0].value;


    formData.append('mail', mail);
    formData.append('new_mail', new_mail);
    formData.append('conf_new_mail', conf_new_mail);
    formData.append('partner_account_id', partner_account_id);


    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) == String("true")) {
          setupdatemainmail(true);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        setupdatemainmail(false);
      });



  }

  const [partner_account_id, setpartner_account_id] = useState("");
  const [is_partner_admin_account, setis_partner_admin_account] = useState("");

  function getCurrentUserData() {

    settestval('d'); // ==> Do not delete
    const formData = new FormData();
    const url = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
    formData.append('token', stored_user);

    axios.post(url, formData).then(res => {
      if (String(res.data.status) === "true") {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("True");
        setResult(res.data.message);

        var mylocaltraining = JSON.parse(res.data.message);
        if (mylocaltraining) {

          setpartner_account_id(mylocaltraining._id);

          if (mylocaltraining.is_partner_admin_account) {
            setis_partner_admin_account(mylocaltraining.is_partner_admin_account);
          } else {
            setis_partner_admin_account("0");
          }


          if (mylocaltraining.adr_city) {
            setp_adr_street(mylocaltraining.adr_city);
            document.getElementsByName("adr_city")[0].disabled = true;
            document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
          }


          if (mylocaltraining.adr_city) {
            setp_adr_zip(mylocaltraining.adr_zip);
            document.getElementsByName("adr_zip")[0].disabled = true;
            document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.email) {
            setp_email(mylocaltraining.email);
            document.getElementsByName("email")[0].disabled = true;
            document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";
          }


          if (mylocaltraining.website) {
            setp_website(mylocaltraining.website);
            document.getElementsByName("website")[0].disabled = true;
            document.getElementsByName("website")[0].style.backgroundColor = "#ECEFF1";
          }



          if (mylocaltraining.adr_country) {
            setp_adr_country(mylocaltraining.adr_country);
            document.getElementsByName("adr_country")[0].disabled = true;
            document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.link_facebook) {
            setp_link_facebook(mylocaltraining.link_facebook);
            document.getElementsByName("link_facebook")[0].disabled = true;
            document.getElementsByName("link_facebook")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.telephone) {
            setp_telephone(mylocaltraining.telephone);
            document.getElementsByName("telephone")[0].disabled = true;
            document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.siret) {
            setp_siret(mylocaltraining.siret);
            document.getElementsByName("siret")[0].disabled = true;
            document.getElementsByName("siret")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.num_nda) {
            setp_num_nda(mylocaltraining.num_nda);
            document.getElementsByName("num_nda")[0].disabled = true;
            document.getElementsByName("num_nda")[0].style.backgroundColor = "#ECEFF1";
          }



          if (mylocaltraining.iscertifvoltaire) {
            setmycertifvoltaire(mylocaltraining.iscertifvoltaire);
          }
          else {
            setmycertifvoltaire("0");
          }



          if (mylocaltraining.isdatadock) {
            setmydatadock(mylocaltraining.isdatadock);
          }
          else {
            setmydatadock("0");
          }

          if (mylocaltraining.isqualiopi) {
            setmyqualiopi(mylocaltraining.isqualiopi);
          }
          else {
            setmyqualiopi("0");
          }


          if (mylocaltraining.link_linkedin) {
            setp_link_linkedin(mylocaltraining.link_linkedin);
            document.getElementsByName("link_linkedin")[0].disabled = true;
            document.getElementsByName("link_linkedin")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.link_twitter) {
            setp_link_twitter(mylocaltraining.link_twitter);
            document.getElementsByName("link_twitter")[0].disabled = true;
            document.getElementsByName("link_twitter")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.adr_street) {
            setp_adr_street(mylocaltraining.adr_street);
            document.getElementsByName("adr_street")[0].disabled = true;
            document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";
          }


          if (mylocaltraining.nom) {
            setp_nom(mylocaltraining.nom);
            document.getElementsByName("nom")[0].disabled = true;
            document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.contact_tel) {
            setp_contact_tel(mylocaltraining.contact_tel);
            document.getElementsByName("contact_tel")[0].disabled = true;
            document.getElementsByName("contact_tel")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.contact_nom) {
            setp_contact_nom(mylocaltraining.contact_nom);
            document.getElementsByName("contact_nom")[0].disabled = true;
            document.getElementsByName("contact_nom")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.contact_prenom) {
            setp_contact_prenom(mylocaltraining.contact_prenom);
            document.getElementsByName("contact_prenom")[0].disabled = true;
            document.getElementsByName("contact_prenom")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.contact_mail) {
            setp_contact_mail(mylocaltraining.contact_mail);
            document.getElementsByName("contact_mail")[0].disabled = true;
            document.getElementsByName("contact_mail")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.lms_virtualhost_url) {
            setp_lms_virtualhost_url(mylocaltraining.lms_virtualhost_url);

          }

          if (mylocaltraining.lms_username) {
            setp_lms_username(mylocaltraining.lms_username);

          }

          if (mylocaltraining.mysy_lms_user_id) {
            setp_mysy_lms_user_id(mylocaltraining.mysy_lms_user_id);

          }


          desablefield1();



        }
      }
      else {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("False");
        //setmyApimyApiMessage("")
      }


    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ', error);
      setmyApiResponse("False");
      //setmyApimyApiMessage("")
    })


  }


  function annuler_change_user_data() {
    desablefield1();
    getCurrentUserData();
    setuserformedited("");
  }

  function desablefield1() {
    setdatamodification();


    if (document.getElementsByName("adr_city")[0]) {
      document.getElementsByName("adr_city")[0].disabled = true;
      document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("adr_zip")[0]) {
      document.getElementsByName("adr_zip")[0].disabled = true;
      document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
    }



    if (document.getElementsByName("adr_country")[0]) {
      document.getElementsByName("adr_country")[0].disabled = true;
      document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
    }
    if (document.getElementsByName("telephone")[0]) {
      document.getElementsByName("telephone")[0].disabled = true;
      document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("siret")[0]) {
      document.getElementsByName("siret")[0].disabled = true;
      document.getElementsByName("siret")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("num_nda")[0]) {
      document.getElementsByName("num_nda")[0].disabled = true;
      document.getElementsByName("num_nda")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("link_facebook")[0]) {
      document.getElementsByName("link_facebook")[0].disabled = true;
      document.getElementsByName("link_facebook")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("link_linkedin")[0]) {
      document.getElementsByName("link_linkedin")[0].disabled = true;
      document.getElementsByName("link_linkedin")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("link_twitter")[0]) {
      document.getElementsByName("link_twitter")[0].disabled = true;
      document.getElementsByName("link_twitter")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("adr_street")[0]) {
      document.getElementsByName("adr_street")[0].disabled = true;
      document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

    }

    if (document.getElementsByName("nom")[0]) {
      document.getElementsByName("nom")[0].disabled = true;
      document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("contact_tel")[0]) {
      document.getElementsByName("contact_tel")[0].disabled = true;
      document.getElementsByName("contact_tel")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("contact_nom")[0]) {
      document.getElementsByName("contact_nom")[0].disabled = true;
      document.getElementsByName("contact_nom")[0].style.backgroundColor = "#ECEFF1";
    }


    if (document.getElementsByName("contact_prenom")[0]) {
      document.getElementsByName("contact_prenom")[0].disabled = true;
      document.getElementsByName("contact_prenom")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("contact_mail")[0]) {
      document.getElementsByName("contact_mail")[0].disabled = true;
      document.getElementsByName("contact_mail")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("website")[0]) {
      document.getElementsByName("website")[0].disabled = true;
      document.getElementsByName("website")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("isdatadock")[0]) {
      document.getElementsByName("isdatadock")[0].disabled = true;
      document.getElementsByName("isdatadock")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("isqualiopi")[0]) {
      document.getElementsByName("isqualiopi")[0].disabled = true;
      document.getElementsByName("isqualiopi")[0].style.backgroundColor = "#ECEFF1";
    }

    if (document.getElementsByName("iscertifvoltaire")[0]) {
      document.getElementsByName("iscertifvoltaire")[0].disabled = true;
      document.getElementsByName("iscertifvoltaire")[0].style.backgroundColor = "#ECEFF1";
    }

    setfields1desabled(true);
  }


  function enablefieds1() {

    setuserformedited("1");


    if (document.getElementsByName("adr_city")[0]) {
      document.getElementsByName("adr_city")[0].disabled = false;
      document.getElementsByName("adr_city")[0].style.backgroundColor = "#FFFFFF";
    }

    if (document.getElementsByName("adr_zip")[0]) {
      document.getElementsByName("adr_zip")[0].disabled = false;
      document.getElementsByName("adr_zip")[0].style.backgroundColor = "#FFFFFF";
    }



    if (document.getElementsByName("adr_country")[0]) {
      document.getElementsByName("adr_country")[0].disabled = false;
      document.getElementsByName("adr_country")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("telephone")[0]) {
      document.getElementsByName("telephone")[0].disabled = false;
      document.getElementsByName("telephone")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("siret")[0]) {
      document.getElementsByName("siret")[0].disabled = false;
      document.getElementsByName("siret")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("num_nda")[0]) {
      document.getElementsByName("num_nda")[0].disabled = false;
      document.getElementsByName("num_nda")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("link_facebook")[0]) {
      document.getElementsByName("link_facebook")[0].disabled = false;
      document.getElementsByName("link_facebook")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("link_linkedin")[0]) {
      document.getElementsByName("link_linkedin")[0].disabled = false;
      document.getElementsByName("link_linkedin")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("link_twitter")[0]) {
      document.getElementsByName("link_twitter")[0].disabled = false;
      document.getElementsByName("link_twitter")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("adr_street")[0]) {
      document.getElementsByName("adr_street")[0].disabled = false;
      document.getElementsByName("adr_street")[0].style.backgroundColor = "#FFFFFF";
    }

    if (String(is_partner_admin_account) === "1") {
      if (document.getElementsByName("nom")[0]) {
        document.getElementsByName("nom")[0].disabled = false;
        document.getElementsByName("nom")[0].style.backgroundColor = "#FFFFFF";
      }
    }


    if (document.getElementsByName("contact_tel")[0]) {
      document.getElementsByName("contact_tel")[0].disabled = false;
      document.getElementsByName("contact_tel")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("contact_nom")[0]) {
      document.getElementsByName("contact_nom")[0].disabled = false;
      document.getElementsByName("contact_nom")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("contact_prenom")[0]) {
      document.getElementsByName("contact_prenom")[0].disabled = false;
      document.getElementsByName("contact_prenom")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("contact_mail")[0]) {
      document.getElementsByName("contact_mail")[0].disabled = false;
      document.getElementsByName("contact_mail")[0].style.backgroundColor = "#FFFFFF";
    }



    if (document.getElementsByName("website")[0]) {
      document.getElementsByName("website")[0].disabled = false;
      document.getElementsByName("website")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("isdatadock")[0]) {
      document.getElementsByName("isdatadock")[0].disabled = false;
      document.getElementsByName("isdatadock")[0].style.backgroundColor = "#FFFFFF";
    }


    if (document.getElementsByName("isqualiopi")[0]) {
      document.getElementsByName("isqualiopi")[0].disabled = false;
      document.getElementsByName("isqualiopi")[0].style.backgroundColor = "#FFFFFF";

    }

    if (document.getElementsByName("iscertifvoltaire")[0]) {
      document.getElementsByName("iscertifvoltaire")[0].disabled = false;
      document.getElementsByName("iscertifvoltaire")[0].style.backgroundColor = "#FFFFFF";
    }

    setfields1desabled(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  function desablesecurtymailfields() {/*
    document.getElementsByName("current_email")[0].disabled = true;
    document.getElementsByName("current_email")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("current_email")[0].value = "";


    document.getElementsByName("new_email")[0].disabled = true;
    document.getElementsByName("new_email")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("new_email")[0].value = "";


    document.getElementsByName("conf_new_email")[0].disabled = true;
    document.getElementsByName("conf_new_email")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("conf_new_email")[0].value = "";


    setmailfieldsdesabled(true);*/
  }



  function enablesecurtymailfields() {


    document.getElementsByName("current_email")[0].disabled = false;
    document.getElementsByName("current_email")[0].style.backgroundColor = "#FFFFFF";



    document.getElementsByName("new_email")[0].disabled = false;
    document.getElementsByName("new_email")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("conf_new_email")[0].disabled = false;
    document.getElementsByName("conf_new_email")[0].style.backgroundColor = "#FFFFFF";


    setmailfieldsdesabled(false);
  }



  function desablesecurtypwdfields() {
    /* document.getElementsByName("current_pwd")[0].disabled = true;
     document.getElementsByName("current_pwd")[0].style.backgroundColor = "#ECEFF1";
     document.getElementsByName("current_pwd")[0].value = "";
 
     document.getElementsByName("new_pwd")[0].disabled = true;
     document.getElementsByName("new_pwd")[0].style.backgroundColor = "#ECEFF1";
     document.getElementsByName("new_pwd")[0].value = "";
 
     document.getElementsByName("new_pwd_conf")[0].disabled = true;
     document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#ECEFF1";
     document.getElementsByName("new_pwd_conf")[0].value = "";
 
 
     setpwdfieldsdesabled(true);*/
  }



  function enablesecurtypwdfields() {

    document.getElementsByName("current_pwd")[0].disabled = false;
    document.getElementsByName("current_pwd")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("new_pwd")[0].disabled = false;
    document.getElementsByName("new_pwd")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("new_pwd_conf")[0].disabled = false;
    document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#FFFFFF";

    setpwdfieldsdesabled(false);
  }


  function RecordData() {


    const formData = new FormData();
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";

    var adr_city = document.getElementsByName("adr_city")[0].value
    adr_city = adr_city.replace("undefined", "");

    var adr_zip = document.getElementsByName("adr_zip")[0].value;
    adr_zip = adr_zip.replace("undefined", "");


    var adr_country = document.getElementsByName("adr_country")[0].value;
    adr_country = adr_country.replace("undefined", "");


    var telephone = document.getElementsByName("telephone")[0].value;
    telephone = telephone.replace("undefined", "");

    var siret = "";
    if (document.getElementsByName("siret")[0]) {
      siret = document.getElementsByName("siret")[0].value;
      siret = siret.replace("undefined", "");
    }

    var num_nda = "";
    if (document.getElementsByName("num_nda")[0]) {
      num_nda = document.getElementsByName("num_nda")[0].value;
      num_nda = num_nda.replace("undefined", "");
    }


    var website = document.getElementsByName("website")[0].value;
    website = website.replace("undefined", "");

    var link_facebook = document.getElementsByName("link_facebook")[0].value;
    var link_linkedin = document.getElementsByName("link_linkedin")[0].value;
    var link_twitter = document.getElementsByName("link_twitter")[0].value;
    var adr_street = document.getElementsByName("adr_street")[0].value;
    var nom = document.getElementsByName("nom")[0].value;
    var contact_nom = document.getElementsByName("contact_nom")[0].value;


    var contact_prenom = document.getElementsByName("contact_prenom")[0].value;
    var contact_tel = document.getElementsByName("contact_tel")[0].value;
    var contact_mail = document.getElementsByName("contact_mail")[0].value;

    //var mycertitrace = document.getElementsByName("iscertitrace")[0].value;
    //var mybureaucertitrace = document.getElementsByName("isbureaucertitrace")[0].value;

    var mycertifvoltaire = "0"
    if (document.getElementsByName("iscertifvoltaire")[0]) {
      mycertifvoltaire = document.getElementsByName("iscertifvoltaire")[0].value;
    }

    var mydatadock = "0";
    if (document.getElementsByName("isdatadock")[0]) {
      mydatadock = document.getElementsByName("isdatadock")[0].value;
    }

    var myqualiopi = "0";
    if (document.getElementsByName("isqualiopi")[0]) {
      var myqualiopi = document.getElementsByName("isqualiopi")[0].value;
    }


    formData.append('partner_account_id', partner_account_id);
    formData.append('token', stored_user);
    formData.append('nom', nom);

    //formData.append('iscertitrace', mycertitrace);
    formData.append('isdatadock', mydatadock);
    formData.append('isqualiopi', myqualiopi);
    // formData.append('isbureaucertitrace', mybureaucertitrace);
    formData.append('iscertifvoltaire', mycertifvoltaire);
    formData.append('website', website);

    formData.append('adr_city', adr_city);
    formData.append('adr_zip', adr_zip);
    formData.append('telephone', telephone);
    formData.append('siret', siret);
    formData.append('num_nda', num_nda);
    formData.append('adr_country', adr_country);
    formData.append('link_facebook', link_facebook);
    formData.append('link_twitter', link_twitter);
    formData.append('adr_street', adr_street);
    formData.append('contact_nom', contact_nom);
    formData.append('contact_prenom', contact_prenom);
    formData.append('link_linkedin', link_linkedin);
    formData.append('contact_tel', contact_tel);
    formData.append('contact_mail', contact_mail);


    // Recuperation de l'image logo & de l'image du cachet
    if (String(userimgclassprofilchanged) === "1") {
      formData.append('file_logo', isimgclassSelected);
      formData.append('file_logo_recid', isimgclassSelected_recid);
    }

    if (String(isimgcachetchanged) === "1") {
      formData.append('file_cachet', isimgcachetSelected);
      formData.append('file_cachet_recid', isimgcachetSelected_recid);
    }



    //console.log(" ### formData = ", formData);
    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) === String("true")) {
          settestval(true);
          desablefield1();
          setdatamodification();
          getPartnerImages();


          if (document.getElementsByName("isdatadock")[0]) {
            document.getElementsByName("isdatadock")[0].disabled = true;
            document.getElementsByName("isdatadock")[0].style.backgroundColor = "#ECEFF1";
          }

          if (document.getElementsByName("isqualiopi")[0]) {
            document.getElementsByName("isqualiopi")[0].disabled = true;
            document.getElementsByName("isqualiopi")[0].style.backgroundColor = "#ECEFF1";
          }


          if (document.getElementsByName("iscertifvoltaire")[0]) {
            document.getElementsByName("iscertifvoltaire")[0].disabled = true;
            document.getElementsByName("iscertifvoltaire")[0].style.backgroundColor = "#ECEFF1";
          }

          alert(" La mise à jour a été faite ");


        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        } else {
          alert(" Erreur : " + result['message']);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        alert(" Erreur : Impossible de mettre à jour les informations ");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const [getPartnerImages_api, setgetPartnerImages_api] = useState();
  const [getPartnerImages_message, setgetPartnerImages_message] = useState();
  const [getPartnerImages_result, setgetPartnerImages_result] = useState();
  function getPartnerImages(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedParnterImage_from_front/";

    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);



    axios.post(myurl, form).then(res => {

      //console.log(" getPartnerImages : In test  res.data.status = " + res.data.status);
      // console.log(" getPartnerImages: res.data.message.img = " + res.data.message);

      if (String(res.data.status) === "true") {
        setgetPartnerImages_api("true");

        if (JSON.parse(res.data.message).logo_img) {
          var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
          setisimgclassSelected(partner_logo_img);
          setuserimgclassprofil(partner_logo_img);
          setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
        }

        if (JSON.parse(res.data.message).cachet_img) {
          var partner_cachet_img = "data:image/png;base64," + JSON.parse(res.data.message).cachet_img;
          setisimgcachet(partner_cachet_img);
          setisimgcachetSelected(partner_cachet_img);
          setisimgcachetSelected_recid(JSON.parse(res.data.message).cachet_img_recid);
        }

      }
      else if (String(res.data.status) === String("Err_Connexion")) {
        alert('Erreur: ' + res.data.message);
        history.push("/Connexion");
        return;
      }
      else {
        setgetPartnerImages_api("false");
        setgetPartnerImages_message(res.data.message)

      }
    }).catch((error) => {
      console.warn('getPartnerImages ee: Not good man :(  = ', error);
      setgetPartnerImages_api("false");
      setgetPartnerImages_message(" Impossible de recuperer les images/médias du partenaire")
    })

  }


  function handleChange(prop) {
    return (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  }


  function handleChange1(prop) {
    return (event) => {
      setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
    };
  }

  function handleChange2(prop) {
    return (event) => {
      setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
    };
  }

  function handleChange3(prop) {
    return (event) => {
      setValuesPass3({ ...valuesPass3, [prop]: event.target.value });
    };
  }


  const handleClickShowPassword_current_pwd = () => {
    var filtre2_value = document.getElementsByName("current_pwd")[0].value;

    if (currentpawdvisible == false) {
      document.getElementsByName("current_pwd")[0].type = "text";
      setcurrentpawdvisible(true);
    }
    else if (currentpawdvisible == true) {
      document.getElementsByName("current_pwd")[0].type = "password";
      setcurrentpawdvisible(false);
    }

  };


  const handleClickShowPassword_new_pwd = () => {
    if (newpawdvisible == false) {
      document.getElementsByName("new_pwd")[0].type = "text";
      setnewpawdvisible(true);
    }
    else if (newpawdvisible == true) {
      document.getElementsByName("new_pwd")[0].type = "password";
      setnewpawdvisible(false);
    }
  };


  const handleClickShowPassword_new_pwd_conf = () => {
    if (confpwdvisible == false) {
      document.getElementsByName("new_pwd_conf")[0].type = "text";
      setconfpwdvisible(true);
    }
    else if (confpwdvisible == true) {
      document.getElementsByName("new_pwd_conf")[0].type = "password";
      setconfpwdvisible(false);
    }

  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [insert_keyfieldsdesabled, setinsert_keyfieldsdesabled] = useState(true);

  // Gestion champs caché : Current_Insertion_Key
  const [values_current_insert_key, setvalues_current_insert_key] = React.useState({
    current_insert_key: '',
    showCurrent_insert_key: false,
  });


  const [valuescurrent_insert_key, setvaluescurrent_insert_key] = React.useState({
    password_insert_current_key: '',
    showpassword_insert_current_key: false,
  });

  const [current_insert_key_visible, setcurrent_insert_key_visible] = useState(false);

  function handleChange_current_insert_key(prop) {
    return (event) => {
      setvaluescurrent_insert_key({ ...current_insert_key_visible, [prop]: event.target.value });
    };
  }

  const handleClickcurrent_insert_key = () => {
    if (current_insert_key_visible == false) {
      document.getElementsByName("current_insert_key")[0].type = "text";
      setcurrent_insert_key_visible(true);
    }
    else if (current_insert_key_visible == true) {
      document.getElementsByName("current_insert_key")[0].type = "password";
      setcurrent_insert_key_visible(false);
    }
  };
  // FIN Gestion champs caché : Current_Insertion_Key

  // Gestion champs caché : new_Insertion_Key
  const [values_new_insert_key, setvalues_new_insert_key] = React.useState({
    new_insert_key: '',
    shownew_insert_key: false,
  });


  const [valuesnew_insert_key, setvaluesnew_insert_key] = React.useState({
    password_insert_new_key: '',
    showpassword_insert_new_key: false,
  });

  const [new_insert_key_visible, setnew_insert_key_visible] = useState(false);

  function handleChange_new_insert_key(prop) {
    return (event) => {
      setvaluesnew_insert_key({ ...new_insert_key_visible, [prop]: event.target.value });
    };
  }

  const handleClicknew_insert_key = () => {
    if (new_insert_key_visible == false) {
      document.getElementsByName("new_insert_key")[0].type = "text";
      setnew_insert_key_visible(true);
    }
    else if (new_insert_key_visible == true) {
      document.getElementsByName("new_insert_key")[0].type = "password";
      setnew_insert_key_visible(false);
    }
  };
  // FIN Gestion new champs caché : new_insertion_Key

  // Gestion champs caché : conf_new_Insertion_Key
  const [values_conf_new_insert_key, setvalues_conf_new_insert_key] = React.useState({
    conf_new_insert_key: '',
    showconf_new_insert_key: false,
  });


  const [valuesconf_new_insert_key, setvaluesconf_new_insert_key] = React.useState({
    password_insertconf_new_key: '',
    showpassword_insertconf_new_key: false,
  });

  const [conf_new_insert_key_visible, setconf_new_insert_key_visible] = useState(false);

  function handleChangeconf_new_insert_key(prop) {
    return (event) => {
      setvaluesconf_new_insert_key({ ...conf_new_insert_key_visible, [prop]: event.target.value });
    };
  }

  const handleClickconf_new_insert_key = () => {
    if (conf_new_insert_key_visible == false) {
      document.getElementsByName("conf_new_insert_key")[0].type = "text";
      setconf_new_insert_key_visible(true);
    }
    else if (conf_new_insert_key_visible == true) {
      document.getElementsByName("conf_new_insert_key")[0].type = "password";
      setconf_new_insert_key_visible(false);
    }
  };
  // FIN Gestion new champs caché : conf_new_insertion_Key

  function desablesecurtyInsertKeyfields() {

    setinsert_key_updated();
    document.getElementsByName("current_insert_key")[0].disabled = true;
    document.getElementsByName("current_insert_key")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("current_insert_key")[0].value = "";

    document.getElementsByName("new_insert_key")[0].disabled = true;
    document.getElementsByName("new_insert_key")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("new_insert_key")[0].value = "";

    document.getElementsByName("conf_new_insert_key")[0].disabled = true;
    document.getElementsByName("conf_new_insert_key")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("conf_new_insert_key")[0].value = "";


    setinsert_keyfieldsdesabled(true);
  }




  function enablesecurtyInsertKeyfields() {

    setinsert_key_updated();
    document.getElementsByName("current_insert_key")[0].disabled = false;
    document.getElementsByName("current_insert_key")[0].style.backgroundColor = "#FFFFFF";


    document.getElementsByName("new_insert_key")[0].disabled = false;
    document.getElementsByName("new_insert_key")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("conf_new_insert_key")[0].disabled = false;
    document.getElementsByName("conf_new_insert_key")[0].style.backgroundColor = "#FFFFFF";

    setinsert_keyfieldsdesabled(false);
  }

  const qualiopi = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const certitrace = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const datadock = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const bureaucertitrace = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const certifvoltaire = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];



  const [myqualiopi, setmyqualiopi] = useState("");
  const handleChangequaliopi = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmyqualiopi(value);
  }

  const [mydatadock, setmydatadock] = useState("");
  const handleChangedatadock = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmydatadock(value);
  }


  const [mycertitrace, setmycertitrace] = useState("");
  const handleChangecertitrace = (event) => {

    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmycertitrace(value);
  }

  const [mybureaucertitrace, setmybureaucertitrace] = useState("");
  const handleChangebureaucertitrace = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmybureaucertitrace(value);
  }


  const [mycertifvoltaire, setmycertifvoltaire] = useState("");
  const handleChangecertifvoltaire = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmycertifvoltaire(value);
  }



  const [datamodification, setdatamodification] = useState("0");
  function DataUpdated() {
    setdatamodification("1");
  }

  const [isimgclassdeleted, setisimgclassdeleted] = useState("");
  const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");

  function removeRecodedClassImage() {
    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("class_external_code", class_external_code);
    form.append("type", "partner");

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteRecodedClassImage/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {

        //console.log(' removeRecodedClassImage : status:', result['status']);
        //console.log('removeRecodedClassImage : Success:', result['message']);

        setisimgclassSelected(false);

        if (String(result['status']) === String("true")) {
          setisimgclassdeleted("1");
          setuserimgclassprofil("");
        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          setisimgclassdeleted("0");
          setisimgclassdeleted_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassdeleted("0");
        setisimgclassdeleted_message(result['message']);
      });

  }



  function removeRecodedLogoImage() {
    if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
      alert(" Image incorrecte. Impossible de la supprimer ");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("image_recid", isimgclassSelected_recid);

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteClassImage_v2/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {

        console.log(' removeRecodedClassImage : status:', result['status']);
        console.log('removeRecodedClassImage : Success:', result['message']);


        if (String(result['status']) === String("true")) {
          setisimgclassSelected("");
          setisimgclassSelected_recid("");
          setisimgclassSelectedfile("");
          setuserimgclassprofil("");
          getPartnerImages();

        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          alert(result['message']);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        alert(" Impossible de supprimer l'image ");
      });

  }

  function removeRecodedCachetImage() {
    if (!isimgcachetSelected_recid || String(isimgcachetSelected_recid).length < 3) {
      alert(" Image incorrecte. Impossible de la supprimer ");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("image_recid", isimgcachetSelected_recid);

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteClassImage_v2/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {


        if (String(result['status']) === String("true")) {
          console.log(' removeRecodedClassImage : status:', result['status']);
          console.log('removeRecodedClassImage : Success:', result['message']);
          setisimgcachetSelected("");
          setisimgcachetSelected_recid("");
          setisimgcachetSelectedfile("");
          setisimgcachet("");
          getPartnerImages();

        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          alert(result['message']);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        alert(" Impossible de supprimer l'image ");
      });


  }

  const [isimgclassSaved, setisimgclassSaved] = useState("");
  const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
  function sendClassImage(e) {

    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var formData = new FormData();
    formData.append('File', isimgclassSelected);
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append("class_external_code", class_external_code);

    formData.append("type", "partner");
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/recordClassImage/",
      {
        method: 'POST',
        body: formData,
      }
    ).then((response) => response.json())
      .then((result) => {
        //console.log('status:', result['status']);
        //console.log('Success:', result['message']);
        setisimgclassSelected(false);

        if (String(result['status']) == String("true")) {
          setisimgclassSaved("1");
        }
        else {
          setisimgclassSaved("0");
          setisimgclassSaved_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassSaved("0");
        setisimgclassSaved_message(result['message']);
      });
  };

  const [isimgclassSelected, setisimgclassSelected] = useState(false);
  const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
  const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


  const [userimgclassprofil, setuserimgclassprofil] = useState();
  const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");
  const imglogoclasschangeHandler = (event) => {


    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    //console.log(event.target.files);

    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;



    //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
    if (file_size > 1000000) {
      alert("L'image ne doit pas dépasser un 1 Méga octets");
      return;
    }
    setisimgclassSelected(event.target.files[0]);
    setisimgclassSelectedfile(true);

    setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
    setuserimgclassprofilchanged("1");
  };

  const [isimgcachetSelected, setisimgcachetSelected] = useState(false);
  const [isimgcachetSelected_recid, setisimgcachetSelected_recid] = useState("");
  const [isimgcachetSelectedfile, setisimgcachetSelectedfile] = useState(false);


  const [isimgcachet, setisimgcachet] = useState();
  const [isimgcachetchanged, setisimgcachetchanged] = useState("");
  const imgcachetchangeHandler = (event) => {



    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    //console.log(event.target.files);

    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;



    //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
    if (file_size > 1000000) {
      alert("L'image ne doit pas dépasser un 1 Méga octets");
      return;
    }
    setisimgcachetSelected(event.target.files[0]);
    setisimgcachetSelectedfile(true);

    setisimgcachet(URL.createObjectURL(event.target.files[0]));
    setisimgcachetchanged("1");
  };


  const [isimgcachetdeleted, setisimgcachetdeleted] = useState("");
  const [isimgcachetdeleted_message, setisimgcachetdeleted_message] = useState("");

  const [isimgcachetSaved, setisimgcachetSaved] = useState("");
  const [isimgcachetSaved_message, setisimgcachetSaved_message] = useState("");

  const [submenu, setsubmenu] = useState("submenu_donnee_entreprise");

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function submenu_donnee_entreprise() {
    setsubmenu("submenu_donnee_entreprise");
    await sleep(5);
    if (fields1desabled == true) {
      desablefield1();
    }
    else {
      enablefieds1();
    }
  }

  async function submenu_donnee_facturation() {
    setsubmenu("submenu_donnee_facturation");
  }

  async function submenu_donnee_securite() {

    //var security_retval = props.check_user_acces_right("account_securite", "read");

    setsubmenu("submenu_donnee_securite");

  }

  async function submenu_contact() {
    setsubmenu("submenu_contact");
  }


  const [p_nom, setp_nom] = useState("");
  const [p_siret, setp_siret] = useState("");
  const [p_telephone, setp_telephone] = useState("");
  const [p_email, setp_email] = useState("");
  const [p_website, setp_website] = useState("");
  const [p_link_linkedin, setp_link_linkedin] = useState("");
  const [p_link_facebook, setp_link_facebook] = useState("");
  const [p_link_twitter, setp_link_twitter] = useState("");
  const [p_adr_street, setp_adr_street] = useState("");
  const [p_adr_zip, setp_adr_zip] = useState("");
  const [p_adr_city, setp_adr_city] = useState("");
  const [p_adr_country, setp_adr_country] = useState("");
  const [p_num_nda, setp_num_nda] = useState("");
  const [p_isqualiopi, setp_isqualiopi] = useState("");
  const [p_isdatadock, setp_isdatadock] = useState("");
  const [p_iscertifvoltaire, setp_iscertifvoltaire] = useState("");
  const [p_contact_nom, setp_contact_nom] = useState("");
  const [p_contact_prenom, setp_contact_prenom] = useState("");
  const [p_contact_tel, setp_contact_tel] = useState("");
  const [p_contact_mail, setp_contact_mail] = useState("");

  const [p_lms_virtualhost_url, setp_lms_virtualhost_url] = useState("");
  const [p_lms_username, setp_lms_username] = useState("");
  const [p_mysy_lms_user_id, setp_mysy_lms_user_id] = useState("");




  return (
    <div className='updateparnterinfo'>

      <div className="div_row">
        {String(is_partner_admin_account) !== "1" && <Button variant="outlined" onClick={submenu_donnee_entreprise} className="detail_submenu" id='submenu_donnee_entreprise' name='submenu_donnee_entreprise'>DONNEES PERSONNELLES</Button>}

        {String(is_partner_admin_account) === "1" && <Button variant="outlined" onClick={submenu_donnee_entreprise} className="detail_submenu" id='submenu_donnee_entreprise' name='submenu_donnee_entreprise'>DONNEES ENTREPRISE</Button>}
        {String(is_partner_admin_account) === "1" && <Button variant="outlined" onClick={submenu_donnee_facturation} className="detail_submenu" id='submenu_donnee_facturation' name='submenu_donnee_facturation'>DONNEES FACTURATION</Button>}
        <Button variant="outlined" onClick={submenu_donnee_securite} className="detail_submenu" id='submenu_donnee_securite' name='submenu_donnee_securite'>SECURITE</Button>
        {/*<Button variant="outlined" onClick={submenu_contact} className="detail_submenu" id='submenu_contact' name='submenu_contact'>Contact & Adresses</Button>*/}
      </div>

      {String(submenu).trim() === "submenu_donnee_securite" && <div className="training_data">

        <Partner_Securite_Data partner_account_id={partner_account_id} />
      </div>}

      {String(submenu).trim() === "submenu_donnee_facturation" && <div className="training_data">
        <Partner_Invoicing_Data partner_account_id={partner_account_id} />
      </div>}

      {String(submenu).trim() === "submenu_contact" && <div className="training_data">

      </div>}


      {String(submenu).trim() === "submenu_donnee_entreprise" && <div className="training_data">

        <div className="titre1"> Mes Informations </div>


        {testval == true && <div className="okUpdateData">
          Les données ont été mises à jour.
        </div>
        }

        {testval == false && <div className="koUpdateData">
          Erreur mise à jour.
        </div>
        }


        &nbsp;
        <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }} onChange={DataUpdated}>

          {String(is_partner_admin_account) === "1" && <TextField
            required
            label="Raison sociale"
            name="nom"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_nom}
            onChange={(e) => setp_nom(e.target.value)}
          />}

          {String(is_partner_admin_account) !== "1" && <TextField
            required
            label="Structure de rattachement"
            name="nom"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_nom}

          />}

          {String(is_partner_admin_account) === "1" && <TextField
            name="siret"
            label="SIRET"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_siret}
            onChange={(e) => setp_siret(e.target.value)}
          />
          }



          <TextField
            name="telephone"
            label="Telephone"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            value={p_telephone}
            onChange={(e) => setp_telephone(e.target.value)}
          />


          <TextField
            name="email"
            label="Adresse mail"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            value={p_email}
            onChange={(e) => setp_email(e.target.value)}
          />

          <TextField
            name="website"
            label="Site Web"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon />
                </InputAdornment>
              ),
            }}
            value={p_website}
            onChange={(e) => setp_website(e.target.value)}
          />

          <TextField
            name="link_linkedin"
            label="LinkedIn"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkedInIcon />
                </InputAdornment>
              ),
            }}
            value={p_link_linkedin}
            onChange={(e) => setp_link_linkedin(e.target.value)}
          />


          <TextField
            name="link_facebook"
            label="Facebook"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon />
                </InputAdornment>
              ),
            }}
            value={p_link_facebook}
            onChange={(e) => setp_link_facebook(e.target.value)}
          />


          <TextField
            name="link_twitter"
            label="Twitter"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TwitterIcon />
                </InputAdornment>
              ),
            }}
            value={p_link_twitter}
            onChange={(e) => setp_link_twitter(e.target.value)}
          />

          <TextField
            name="adr_street"
            label="Adresse"
            className="texte_area_adress"
            sx={{ m: 1, width: '90%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_street}
            onChange={(e) => setp_adr_street(e.target.value)}
          />

          <TextField
            name="adr_zip"
            label="Code Postal"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_zip}
            onChange={(e) => setp_adr_zip(e.target.value)}
          />

          <TextField
            name="adr_city"
            label="Ville"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_city}
            onChange={(e) => setp_adr_city(e.target.value)}
          />

          <TextField
            name="adr_country"
            label="Pays"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_country}
            onChange={(e) => setp_adr_country(e.target.value)}
          />
          {String(is_partner_admin_account) === "1" && <div>
            <div style={{ "width": "10%" }}> &nbsp;</div>
            <div className="div_row_gauche">
              <div className="titre1"> Votre logo </div>
              <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                <div className="div_row_gauche_image">
                  <img class="img_class_logo" src={userimgclassprofil} /><br />

                </div>
                <div className="div_row_droite_image">
                  <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                  {String(userformedited) === "1" && <nav> <label for="files_img_class" className="bton_image_class">Charger un logo</label><br />

                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                      <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer le logo<br />  </nav>
                    }

                  </nav>}

                  {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                  {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                  {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                  {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                </div>

              </div>
              <div className="div_row_bis tips_img_class">
                <li>
                  L'image doit etre carrée.
                </li>
                <li>
                  Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                </li>
                <li>
                  Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                  Les dimensions maximales sont de 256pixels X 256pixels
                </li>
                <li>
                  La taille de l'image ne doit pas dépasser 1 mega octet
                </li>
              </div>

            </div>

            <div className="div_row_droite">
              <div className="titre1"> Votre cachet </div>
              <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                <div className="div_row_gauche_image">
                  <img class="img_class_logo" src={isimgcachet} /><br />

                </div>
                <div className="div_row_droite_image">
                  <input type="file" id="files_img_cachet_class" accept="image/*" onChange={imgcachetchangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                  {String(userformedited) === "1" && <nav>  <label for="files_img_cachet_class" className="bton_image_class">Charger un cachet</label><br />


                    {String(isimgcachetSelected_recid) && String(isimgcachetSelected_recid).length > 3 &&
                      <nav className="bton_supprime_image_class" onClick={removeRecodedCachetImage}>Supprimer le cachet </nav>}

                    <br /> </nav>}
                  {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                  {isimgcachetdeleted && String(isimgcachetdeleted) === "1" && <nav className="koUpdateData"> {isimgcachetdeleted_message}</nav>}
                  {isimgcachetdeleted && String(isimgcachetdeleted) === "0" && <nav className="okUpdateData"> {isimgcachetdeleted_message}</nav>}
                  {isimgcachetSelected && String(isimgcachetSaved) === "0" && <nav className="koUpdateData"> {isimgcachetSaved_message}</nav>}
                </div>

              </div>
              <div className="div_row_bis tips_img_class">
                <li>
                  L'image doit etre carrée.
                </li>
                <li>
                  Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                </li>
                <li>
                  Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                  Les dimensions maximales sont de 256pixels X 256pixels
                </li>
                <li>
                  La taille de l'image ne doit pas dépasser 1 mega octet
                </li>
              </div>


            </div>


            <div style={{ "width": "10%" }}> &nbsp;</div>
            <div className="titre1"> Certification </div>
            <div className="div_row">


              <TextField
                required
                name="num_nda"
                label="Numéro NDA"
                className="texte_area"
                sx={{ m: 1, width: '48%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                value={p_num_nda}
                onChange={(e) => setp_num_nda(e.target.value)}
              />

              {fields1desabled == true && String(myqualiopi) !== "1" &&

                <TextField
                  name="isqualiopi"
                  label="Qualiopi"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Non"

                />

              }

              {fields1desabled == true && String(myqualiopi) === "1" &&

                <TextField
                  name="isqualiopi"
                  label="Qualiopi"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Oui"


                />

              }

              {fields1desabled !== true &&
                <TextField
                  name="isqualiopi"
                  label="Qualiopi"
                  select
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}

                  value={myqualiopi}

                  onChange={handleChangequaliopi}

                >{qualiopi.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} &nbsp; <br />
                  </MenuItem>
                ))}
                </TextField>
              }
            </div>
            <div className="div_row">




              {fields1desabled == true && String(mydatadock) !== "1" &&

                <TextField
                  name="isdatadock"
                  label="Datadock"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Non"


                />

              }

              {fields1desabled == true && String(mydatadock) === "1" &&

                <TextField
                  name="isdatadock"
                  label="Datadock"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Oui"


                />

              }

              {fields1desabled !== true &&
                <TextField
                  name="isdatadock"
                  label="Datadock"
                  select
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}

                  value={mydatadock}

                  onChange={handleChangedatadock}

                >{datadock.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} &nbsp; <br />
                  </MenuItem>
                ))}
                </TextField>
              }

              {fields1desabled == true && String(mycertifvoltaire) !== "1" &&

                <TextField
                  name="iscertifvoltaire"
                  label="Certificat Voltaire"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Non"


                />

              }

              {fields1desabled == true && String(mycertifvoltaire) === "1" &&

                <TextField
                  name="iscertifvoltaire"
                  label="Certificat Voltaire"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Oui"


                />

              }


              {fields1desabled !== true &&
                <TextField
                  name="iscertifvoltaire"
                  label="Certificat Voltaire"
                  select
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}

                  value={mycertifvoltaire}

                  onChange={handleChangecertifvoltaire}


                >{certifvoltaire.map((option) => (
                  <MenuItem key={option.value} value={option.value} >
                    {option.label} &nbsp; <br />
                  </MenuItem>
                ))}
                </TextField>
              }
            </div>
          </div>}


          <div style={{ "width": "10%" }}> &nbsp;</div>
          <div className="titre1"> Info Contact </div>

          <TextField
            name="contact_nom"
            label="Nom Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_contact_nom}
            onChange={(e) => setp_contact_nom(e.target.value)}
          />

          <TextField
            name="contact_prenom"
            label="Prénom Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_contact_prenom}
            onChange={(e) => setp_contact_prenom(e.target.value)}
          />

          <TextField
            name="contact_tel"
            label="Tel Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            value={p_contact_tel}
            onChange={(e) => setp_contact_tel(e.target.value)}
          />
          <TextField
            name="contact_mail"
            label="Mail Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            value={p_contact_mail}
            onChange={(e) => setp_contact_mail(e.target.value)}
          />



          {fields1desabled == false && String(datamodification) === String("1") && <div className="div_row">
            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
          </div>}

          <div className="div_row22">
            {fields1desabled == false && <div className="div_row">
              <div className="div_row_gauche">
                <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
              </div>
              <div className="div_row_droite">
                <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={annuler_change_user_data}>Annuler</Button>
              </div>
            </div>

            }
            {fields1desabled == true &&
              <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablefieds1}>Editer</Button>
            }

          </div>

          <div style={{ "width": "10%" }}> &nbsp;</div>
          <div className="titre1"> Accès E-Learning </div>
          <TextField
            name="contact_login_elearning"
            label="E-Learning Login"
            className="texte_area"
          

            sx={{ m: 1, width: '48%', background:"#ECEFF1", color:"black"}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_lms_username}

          />

          <TextField
            name="contact_login_elearning"
            label="Url plateforme"
           
            sx={{ m: 1, width: '48%', background:"#ECEFF1", color:"black"}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_lms_virtualhost_url}

          />


        </Box>

        {/*<div style={{ "width": "10%" }}> &nbsp;</div>

        <div className="div_row_gauche">
          <div className="titre1"> Mot de passe </div>
          {updatepwd == false && <div className="koUpdateData">
            Erreur mise à jour.
          </div>
          }

          {updatepwd == true && <div className="okUpdateData">
            La mise à jour été faite
          </div>
          }
           &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 } }}>

            <FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
              <Label>Mot de passe actuel</Label>
              <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
              <OutlinedInput
                id="current_pwd"
                name="current_pwd"
                type={values.showPassword1 ? 'text' : 'password'}
                value={values.password1}
                onChange={handleChange1('password1')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword_current_pwd}
                      edge="end"
                    >
                      {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }

              />
            </FormControl>


            <FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
              <Label>Nouveau mot de passe</Label>
              <InputLabel htmlFor="outlined-adornment-password2"></InputLabel>
              <OutlinedInput
                id="new_pwd"
                name="new_pwd"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password2}
                onChange={handleChange2('password2')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword_new_pwd}
                      edge="end"
                    >
                      {newpawdvisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }

              />
            </FormControl>

            <FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
              <Label>Confirmation du nouveau mot de passe</Label>
              <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
              <OutlinedInput
                id="new_pwd_conf"
                name="new_pwd_conf"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password3}
                onChange={handleChange3('password3')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword_new_pwd_conf}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }

              />
            </FormControl>
            <div className="text_pwd_crit">
              Créez un mot de passe contenant au moins :
              <ul>
                <li name="caractere">
                  8 caractères
                </li>
                <li name="nombre">
                  1 nombre
                </li>
                <li name="special">
                  1 caractère spécial p. ex., $, !, @, %, &
                </li>

                <li name="identique">
                  Identiques
                </li>
              </ul>
            </div>

            <div className="div_row22" style={{ "textAlign": "left" }}>

              {pwdfieldsdesabled == false && <div className="div_row" style={{ "textAlign": "left" }} >
                <div className="div_row_gauche">
                  <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={UpdatePartnerPwd}>Enregistrer</Button>
                </div>
                <div className="div_row_droite">
                  <Button variant="contained" color="success" className="div_row22 btn_modif " onClick={desablesecurtypwdfields}>Annuler</Button>
                </div>
              </div>
              }
              {pwdfieldsdesabled == true &&
                <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablesecurtypwdfields}>Editer</Button>
              }
            </div>


          </Box>
        </div>

        <div className="div_row_droite">
          <div className="titre1"> Emails contact </div>
          {updatemainmail === false && <div className="koUpdateData">
            Erreur mise à jour.
          </div>
          }

          {updatemainmail === true && <div className="okUpdateData">
            La mise à jour été faite
          </div>
          }

          <TextField
            name="current_email"
            label="Adresse mail"
            sx={{ m: 1, width: '90%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <br />
          <TextField
            name="new_email"
            label="Nouvelle Adresse mail"
            sx={{ m: 1, width: '90%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <br />
          <TextField
            name="conf_new_email"
            label="Confirmation mail"
            sx={{ m: 1, width: '90%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          <div className="text_pwd_crit">
            &nbsp;
            <ul style={{ "listStyleType": "none" }}>
              <li>
                &nbsp;
              </li>
              <li>
                &nbsp;
              </li>
              <li>
                &nbsp;
              </li>

              <li>
                &nbsp;
              </li>
            </ul>
          </div>
          {mailfieldsdesabled == false && <div className="div_row"  >
            <div className="div_row_gauche" >
              <Button variant="contained" color="success" className="div_row22 btn_enreg " style={{ "marginTop": "0px" }} onClick={UpdatePartnerMail}>Enregistrer</Button>
            </div>
            <div className="div_row_droite">
              <Button variant="contained" color="success" className="div_row22 btn_modif" style={{ "marginTop": "0px" }} onClick={desablesecurtymailfields}>Annuler</Button>
            </div>
          </div>
          }
          {mailfieldsdesabled == true && <div className="div_row22" style={{ "textAlign": "right" }}>
            <Button variant="contained" color="success" className="div_row22 btn_modif" style={{ "marginTop": "0px", }} onClick={enablesecurtymailfields}>Editer</Button>
          </div>
          }




        </div>
        <div style={{ "width": "10%" }}> &nbsp;</div>*/}





      </div>}

      <div className="div_row">
        <div className="div_row22">
          <div className="titre1"> Supppression de compte </div>
          <div style={{ "marginTop": "1rem", "textAlign": "left" }}>
            Pour supprimer votre compte, veuillez vous rapprocher de votre contact commercial ou nous écrire à contact@mysy-training.com.
          </div>
        </div>
      </div>
    </div>
  );
}
export default UpdateParnterInfo;