import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import { FcPodiumWithAudience } from "react-icons/fc";
import { FcInfo } from "react-icons/fc";
import { LiaDharmachakraSolid } from "react-icons/lia";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import Autocomplete from '@mui/material/Autocomplete';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";

import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

const Employes = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_affectations, setrows_affectations] = useState([]);
    const [selectionModel_affectations, setSelectionModel_affectations] = React.useState([]);

    const [selectionModel_contrat, setselectionModel_contrat] = React.useState([]);


    const [rows_acces_right, setrows_acces_right] = useState([]);
    const [selectionModel_acces_right, setselectionModel_acces_right] = React.useState([]);


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                {expanded ? value : value.slice(0, 50)}&nbsp;
                {value.length > 50 && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                        type="button"
                        component="button"
                        sx={{ fontSize: 'inherit' }}
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                    </Link>
                )}
            </div>
        );
    }

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);


    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();





    const columns = [
        { field: '_id', headerName: '_id', hide: true, Width: 0 },
        { field: 'id', headerName: 'id', hide: true, Width: 0 },
        { field: 'date_naissance', headerName: 'date_naissance', hide: true, Width: 0 },
        {
            field: 'civilite', headerName: 'Civilité', minWidth: 50, flex: 1, maxWidth: 100, hide: false,

        },

        { field: 'nom', headerName: 'Nom', flex: 1, hide: false, minWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'Prénom', flex: 1, hide: false, minWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'Email', minWidth: 300, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: 'ismanager', headerName: 'Manager', width: 150, hide: false,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";

                else
                    return String(params.value);
            },
        },
        { field: 'telephone_mobile', headerName: 'Mobile', width: 150, hide: false, },

        { field: 'adr_adresse', headerName: 'Adresse', width: 100, hide: true, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'adr_code_postal', headerName: 'Code Postal', width: 100, hide: false, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'adr_ville', headerName: 'Ville', width: 100, hide: false, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },
        { field: 'adr_pays', headerName: 'Pays', width: 100, hide: true, editable: false, minWidth: 50, maxWidth: 70, flex: 1 },



        { field: 'profil', headerName: 'profil', width: 150, hide: false, },
        { field: 'fonction', headerName: 'Fonction', width: 150, hide: false, },

        {
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            handleClick_edit_employee_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'employé sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]

    const columns_employee_affectation = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'poste', headerName: 'Poste', flex: 1, hideable: false, },
        { field: 'date_du', headerName: 'Du', width: 150, hide: false, },
        { field: 'date_au', headerName: 'Au', width: 150, hide: false, },
        { field: 'related_target_collection_object', headerName: 'Cible', flex: 1, hide: false, },
        { field: 'related_target_collection_id_nom', headerName: 'Cible Nom', flex: 1, hide: false, },
        { field: 'target_related_collection', headerName: 'Type', hide: true },
        { field: 'target_related_collection_id', headerName: 'Type ID', hide: true },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                //handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la fonction sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_affection(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_employee_acces_right = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'module', headerName: 'Module', flex: 1, hideable: false, },
        {
            field: 'read', headerName: 'Lecture', width: 150, hide: false, editable: true, type: 'boolean',
        },
        {
            field: 'write', headerName: 'Modification', width: 150, hide: false, editable: true, type: 'boolean',

        },

    ]

    const columns_employee_acces_right_v2 = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'module', headerName: 'Module', flex: 1, hideable: false, },
        {
            field: 'read', headerName: 'Lecture', width: 150, hide: false, editable: true, type: 'boolean',

        },

        {
            field: "write",
            headername: "write",
            rendercell: (params) => (
                <checkbox
                    checked={params.rows?.confirmed}
                    onchange={() => handleconfirmchange(params.row.rowid)}
                />
            )
        }

    ]

    const columns_employee_contrat = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'rh_id', headerName: 'rh_id', flex: 1, hideable: false, hide: true, },
        { field: 'date_debut', headerName: 'Du', width: 150, hide: false, },
        { field: 'date_fin', headerName: 'Au', width: 150, hide: false, },
        { field: 'type_contrat', headerName: 'Type Contrat', flex: 1, hide: false, width: 150, },
        { field: 'type_employe', headerName: 'Type Employé', flex: 1, hide: false, width: 150, },
        { field: 'qte', headerName: 'Quanité', flex: 1, hide: true, width: 150, },
        { field: 'cout', headerName: 'Prix', flex: 1, hide: true, width: 150, },
        { field: 'periodicite', headerName: 'Périodicité', flex: 1, minWidth: 150, hide: false, editable: false },
        { field: 'comment', headerName: 'Commentaire', flex: 1, hide: true, width: 150, },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le contrat sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_employee_contrat(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const [tabledata, settabledata] = useState([]);
    const [rows_acces_right_v2, setrows_acces_right_v2] = useState([]);


    function handleconfirmchange(clickedrow) {
        const updateddata = rows_acces_right_v2.map((x) => {
            if (x.rowid === clickedrow) {
                return {
                    ...x,
                    confirmed: true
                };
            }
            return x;
        });
        setrows_acces_right_v2(updateddata);
    }



    const columns_employee_acces_right_disabled = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'module', headerName: 'Module', flex: 1, hideable: false, },
        {
            field: 'read', headerName: 'Lecture', width: 150, hide: false, editable: false, type: 'boolean',

        },
        {
            field: 'write', headerName: 'Modification', width: 150, hide: false, editable: false, type: 'boolean',

        },

    ]


    const [Getall_Employee_Acces_Right_api, setGetall_Employee_Acces_Right_api] = useState();
    const [Getall_Employee_Acces_Right_message, setGetall_Employee_Acces_Right_message] = useState();
    const [Getall_Employee_Acces_Right_result, setGetall_Employee_Acces_Right_result] = useState();
    function Getall_Employee_Acces_Right(local_employe_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("user_id", local_employe_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Matrix_Acces_Right/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Employee_Acces_Right  res.data.status = " + res.data.status);
                //console.log(" In Getall_Employee_Acces_Right  res.data.message r_class = " + res.data.message);
                setGetall_Employee_Acces_Right_api("true");
                setGetall_Employee_Acces_Right_result(res.data.message);
                setrows_acces_right(res.data.message);

            }
            else {
                setGetall_Employee_Acces_Right_api("false");
                setGetall_Employee_Acces_Right_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Employee_Acces_Right = ', error);
            setGetall_Employee_Acces_Right_api("false");
            alert(" Impossible de recuperer la matrice des Droits d'accès de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Profil_Acces_Right_api, setGetall_Profil_Acces_Right_api] = useState();
    const [Getall_Profil_Acces_Right_message, setGetall_Profil_Acces_Right_message] = useState();
    const [Getall_Profil_Acces_Right_result, setGetall_Profil_Acces_Right_result] = useState();
    function Getall_Profil_Acces_Right(local_profile_name) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("profile_name", local_profile_name);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Matrix_Acces_Right_By_Profil/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Profil_Acces_Right  res.data.status = " + res.data.status);
                //console.log(" In Getall_Profil_Acces_Right  res.data.message r_class = " + res.data.message);
                setGetall_Profil_Acces_Right_api("true");
                setGetall_Profil_Acces_Right_result(res.data.message);
                setrows_acces_right(res.data.message);

            }
            else {
                setGetall_Profil_Acces_Right_api("false");
                setGetall_Profil_Acces_Right_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Profil_Acces_Right = ', error);
            setGetall_Profil_Acces_Right_api("false");
            alert(" Impossible de recuperer la matrice des Droits d'accès du profile");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Employee_Contrat_api, setGetall_Employee_Contrat_api] = useState();
    const [Getall_Employee_Contrat_message, setGetall_Employee_Contrat_message] = useState();
    const [Getall_Employee_Contrat_result, setGetall_Employee_Contrat_result] = useState([]);
    function Getall_Employee_Contrat(local_employe_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", local_employe_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Employee_Contrat/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In Getall_Employee_Contrat  res.data.status = " + res.data.status);
                //console.log(" In Getall_Employee_Contrat  res.data.message r_class = " + res.data.message);
                setGetall_Employee_Contrat_api("true");
                setGetall_Employee_Contrat_result(res.data.message);

            }
            else {
                setGetall_Employee_Contrat_api("false");
                setGetall_Employee_Contrat_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Employee_Contrat = ', error);
            setGetall_Employee_Contrat_api("false");
            alert(" Impossible de recuperer les contrat de l'employé");
            //setmyApimyApiMessage("")
        })
    }




    const [Getall_Employee_Type_Contrat_api, setGetall_Employee_Type_Contrat_api] = useState();
    const [Getall_Employee_Type_Contrat_message, setGetall_Employee_Type_Contrat_message] = useState();
    const [Getall_Employee_Type_Contrat_result, setGetall_Employee_Type_Contrat_result] = useState([]);
    function Getall_Employee_Type_Contrat() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Type_Contrat_List/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In Getall_Employee_Type_Contrat  res.data.status = " + res.data.status);
                //console.log(" In Getall_Employee_Type_Contrat  res.data.message r_class = " + res.data.message);
                setGetall_Employee_Type_Contrat_api("true");
                setGetall_Employee_Type_Contrat_result(res.data.message);

            }
            else {
                setGetall_Employee_Type_Contrat_api("false");
                setGetall_Employee_Type_Contrat_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Employee_Type_Contrat = ', error);
            setGetall_Employee_Type_Contrat_api("false");
            alert(" Impossible de recuperer les contrat de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    function clean_all_filters() {
        setgridline_id('');
        setp_filtre1();
        setp_filtre1_value();

        setp_filtre2();
        setp_filtre2_value();

        setp_filtre3();
        setp_filtre3_value();

        setp_filtre4();
        setp_filtre4_value();

        setdisplay_affectation("");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
        setSelectionModel_affectations([]);
        setSelectionModel([]);
        setsubmenu("");

        Getall_Training_Employee_No_Filter();
    }

    const myRef = useRef(null)

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };



    const [Dialog_1_message_profil_access_right, setDialog_1_message_profil_access_right] = React.useState(false);
    const [Dialog_1_open_profil_access_right, setDialog_1_open_profil_access_right] = React.useState(false);
    function Dialog_1_handle_change_profil_access_right(message, profile_name) {
        setDialog_1_message_profil_access_right(message);
        setDialog_1_open_profil_access_right(true);
    }

    const Dialog_1_handleClose_buton_profil_access_right = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handle_OK_buton_profil_access_right = () => {
        setp_user_profil(p_user_profil_tmp);
        setp_user_profil_changed("1");
        Getall_Profil_Acces_Right(p_user_profil_tmp);
        Enable_acces_right_datagrid();
        setacces_right_data_changed("1");
        setDialog_1_open_profil_access_right(false);
    };


    const Dialog_1_handleCancel_buton_profil_access_right = () => {
        document.getElementsByName("one_detail_profil")[0].value = "";
        setDialog_1_open_profil_access_right(false);
        Disable_acces_right_datagrid();
    };


    const [Check_User_Access_Right_api, setCheck_User_Access_Right_api] = useState();
    const [Check_User_Access_Right_message, setCheck_User_Access_Right_message] = useState();
    const [Check_User_Access_Right_result, setCheck_User_Access_Right_result] = useState();
    async function Check_User_Access_Right(local_module_name, local_action) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("module_name", local_module_name);
        form.append("action", local_action);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Is_User_Has_Right_To_Action/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Check_User_Access_Right  res.data.status = " + res.data.status);
                //console.log(" In Check_User_Access_Right  res.data.message r_class = " + res.data.message);
                setCheck_User_Access_Right_api("true");
                setCheck_User_Access_Right_result(res.data.message);
                return 1;

            }
            else {
                setCheck_User_Access_Right_api("false");
                setCheck_User_Access_Right_message(res.data.message);
                alert(res.data.message)
                return 0;
            }

        }).catch((error) => {
            console.warn('Not good man :( Check_User_Access_Right = ', error);
            setCheck_User_Access_Right_api("false");
            alert(" Impossible de recuperer la matrice des Droits d'accès de l'employé");
            return 0;
            //setmyApimyApiMessage("")
        })
    }




    useEffect(() => {
        Getall_Training_Employee_No_Filter();
        Get_List_Managers();
        Get_List_RH_Profils();
        Get_Employee_Related_Target_Collection_Data();

        Get_Partner_Object_Specific_Fields("ressource_humaine");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])


    const [display_detail_employe, setdisplay_detail_employe] = React.useState();

    const [selected_id, setselected_id] = useState("");

    const [gridline_id, setgridline_id] = useState("");


    const [selected_employe_email, setselected_employe_email] = useState("");
    function handleClick_edit_employee_From_Line(selected_row_id) {

        submenu_detail_employe();
        setEmployee_data_changed();
        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var employe_id = line._id;
        setselected_id(employe_id);

        var employe_email = line.email;
        setselected_employe_email(employe_email);

        setgridline_id(selected_row_id);

        setAffectation_data_changed();
        //console.log(" ### employe_id  = ", employe_id);
        Display_Detail_Employe(employe_id);

        Get_List_Managers();
        Get_List_RH_Profils();
        Get_Employee_Related_Target_Collection_Data();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        Getall_Employee_Affectation(employe_id);
        Getall_Employee_Acces_Right(employe_id);
        Getall_Employee_Contrat(employe_id);
        Get_Partner_Purchase_Prices();

        Get_List_Of_All_PJ(employe_id);


    }



    function handleClick_edit_acces_right_From_Line(selected_row_id) {
    }

    const [Add_new_affectation, setAdd_new_affectation] = useState();

    const [selected_affectation_id, set_selected_affectation_id] = useState();
    function handleClick_edit_affectation_From_Line(selected_row_id) {
        setdisplay_affectation("1");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();


        var line = JSON.parse(rows_affectations[selected_row_id]);

        //console.log("### line = ", line);
        var affectation_id = line._id;
        set_selected_affectation_id(affectation_id);

        if (document.getElementById('myRef_affectation')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef_affectation').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        Get_Given_Employee_Affectation(affectation_id);

    }


    function Display_Detail_Employe(employee_id) {


        clearEmployeeFields()
        setadd_One_Employee();
        setdisplay_detail_employe("1");

        Get_Employee_Data(employee_id);
        get_Employee_Images(employee_id);
    }


    function clearEmployeeFields() {

        setp_one_detail_nom('');
        setp_one_detail_prenom('');
        setp_one_detail_mail('');
        setp_one_detail_naissance(new Date().toLocaleDateString('fr-FR'));
        setp_one_detail_tel('');
        setp_one_detail_adresse('');
        setp_one_detail_code_postal('');
        setp_one_detail_ville('');
        setp_one_detail_resp_hierarchie_id('');
        setp_one_detail_civilite('');
        setp_one_detail_fonction('');
        setp_one_detail_profil('');
        setp_one_detail_profil_label('');

        setp_one_detail_pays('');
        setp_one_detail_tel_mobile('');
        setp_one_detail_linkedIn('');
        setp_one_detail_facebook('');
        setp_one_detail_twitter('');
        setp_one_detail_ismanager('0');

        setp_one_detail_ismanager_label('');
        setp_one_detail_resp_hierarchie_nom('');
        setp_one_detail_resp_hierarchie_prenom('');

        setp_detail_lms_login('');

        setp_detail_user_login('');
        setp_detail_user_login_locked('');

        setp_detail_lms_virtualhost_url('');
        setp_detail_mysy_lms_user_id('');
        setp_detail_lms_account_disable('');


    }

    function handleClick_delete(event, cellValues) {

        var local_rh_id = cellValues.row._id;

        Delete_Employee(local_rh_id);
    }


    function handleClick_delete_affection(event, cellValues) {

        var local_affectation_id = cellValues.row._id;

        Delete_affection(selected_id, local_affectation_id);
    }



    const [p_detail_nom, setp_detail_nom] = useState();
    const [p_detail_prenom, setp_detail_prenom] = useState();
    const [p_detail_mail, setp_detail_mail] = useState();

    const [p_detail_naissance, setp_detail_naissance] = useState(new Date().toLocaleDateString('fr-FR'));


    const [p_detail_tel, setp_detail_tel] = useState();
    const [p_detail_adresse, setp_detail_adresse] = useState();
    const [p_detail_code_postal, setp_detail_code_postal] = useState();
    const [p_detail_ville, setp_detail_ville] = useState();
    const [p_detail_resp_hierarchie_id, setp_detail_resp_hierarchie_id] = useState();
    const [p_detail_civilite, setp_detail_civilite] = useState();
    const [p_detail_civilite_label, setp_detail_civilite_label] = useState();
    const [p_detail_fonction, setp_detail_fonction] = useState();
    const [p_detail_profil, setp_detail_profil] = useState();
    const [p_detail_pays, setp_detail_pays] = useState();
    const [p_detail_tel_mobile, setp_detail_tel_mobile] = useState();
    const [p_detail_linkedIn, setp_detail_linkedIn] = useState();
    const [p_detail_facebook, setp_detail_facebook] = useState();
    const [p_detail_twitter, setp_detail_twitter] = useState();
    const [p_detail_ismanager, setp_detail_ismanager] = useState("0");
    const [p_detail_ismanager_label, setp_detail_ismanager_label] = useState();
    const [p_detail_resp_hierarchie_nom, setp_detail_resp_hierarchie_nom] = useState("");
    const [p_detail_resp_hierarchie_prenom, setp_detail_resp_hierarchie_prenom] = useState("");


    const [p_detail_lms_login, setp_detail_lms_login] = useState("");

    const [p_detail_user_login_locked, setp_detail_user_login_locked] = useState("");

    const [p_detail_user_login, setp_detail_user_login] = useState("");
    const [p_detail_lms_virtualhost_url, setp_detail_lms_virtualhost_url] = useState("");
    const [p_detail_mysy_lms_user_id, setp_detail_mysy_lms_user_id] = useState("");

    const [p_detail_lms_account_disable, setp_detail_lms_account_disable] = useState("");

    const [p_detail_user_pass, setp_detail_user_pass] = useState("");


    const [p_one_detail_nom, setp_one_detail_nom] = useState();
    const [p_one_detail_prenom, setp_one_detail_prenom] = useState();
    const [p_one_detail_mail, setp_one_detail_mail] = useState();

    const [p_one_detail_naissance, setp_one_detail_naissance] = useState(new Date().toLocaleDateString('fr-FR'));



    const [p_one_detail_tel, setp_one_detail_tel] = useState();
    const [p_one_detail_adresse, setp_one_detail_adresse] = useState();
    const [p_one_detail_code_postal, setp_one_detail_code_postal] = useState();
    const [p_one_detail_ville, setp_one_detail_ville] = useState();
    const [p_one_detail_resp_hierarchie_id, setp_one_detail_resp_hierarchie_id] = useState();
    const [p_one_detail_civilite, setp_one_detail_civilite] = useState();
    const [p_one_detail_fonction, setp_one_detail_fonction] = useState();
    const [p_one_detail_profil, setp_one_detail_profil] = useState();
    const [p_one_detail_profil_label, setp_one_detail_profil_label] = useState();
    const [p_one_detail_pays, setp_one_detail_pays] = useState();
    const [p_one_detail_tel_mobile, setp_one_detail_tel_mobile] = useState();
    const [p_one_detail_linkedIn, setp_one_detail_linkedIn] = useState();
    const [p_one_detail_facebook, setp_one_detail_facebook] = useState();
    const [p_one_detail_twitter, setp_one_detail_twitter] = useState();
    const [p_one_detail_ismanager, setp_one_detail_ismanager] = useState();
    const [p_one_detail_ismanager_label, setp_one_detail_ismanager_label] = useState("0");
    const [p_one_detail_resp_hierarchie_nom, setp_one_detail_resp_hierarchie_nom] = useState();
    const [p_one_detail_resp_hierarchie_prenom, setp_one_detail_resp_hierarchie_prenom] = useState();


    const [employee_data_changed, setemployee_data_changed] = useState("");
    const [employee_data_edit_mode, setemployee_data_edit_mode] = useState("");


    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Employee_Data(employe_id) {
        setemployee_data_changed("");
        setemployee_data_edit_mode("");
        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", employe_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Ressource_Humaine/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In Get_Employee_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Employee_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_employe = JSON.parse(res.data.message);

                    if (mylocal_employe.nom)
                        setp_detail_nom(mylocal_employe.nom);


                    if (mylocal_employe.prenom)
                        setp_detail_prenom(mylocal_employe.prenom);


                    if (mylocal_employe.email) {
                        setp_detail_mail(mylocal_employe.email);
                    }

                    if (mylocal_employe.date_naissance) {
                        setp_detail_naissance(mylocal_employe.date_naissance);
                    }


                    if (mylocal_employe.telephone_mobile) {
                        setp_detail_tel_mobile(mylocal_employe.telephone_mobile);
                    }


                    if (mylocal_employe.civilite) {
                        setp_detail_civilite(mylocal_employe.civilite);
                    }


                    if (mylocal_employe.telephone) {
                        setp_detail_tel(mylocal_employe.telephone);
                    }

                    if (mylocal_employe.adr_adresse) {
                        setp_detail_adresse(mylocal_employe.adr_adresse);
                    }

                    if (mylocal_employe.adr_code_postal) {
                        setp_detail_code_postal(mylocal_employe.adr_code_postal);
                    }

                    if (mylocal_employe.adr_ville) {
                        setp_detail_ville(mylocal_employe.adr_ville);
                    }


                    if (mylocal_employe.adr_pays) {
                        setp_detail_pays(mylocal_employe.adr_pays);
                    }

                    if (mylocal_employe.linkedin) {
                        setp_detail_linkedIn(mylocal_employe.linkedin);
                    }


                    if (mylocal_employe.facebook) {
                        setp_detail_facebook(mylocal_employe.facebook);
                    }


                    if (mylocal_employe.twitter) {
                        setp_detail_twitter(mylocal_employe.twitter);
                    }


                    if (mylocal_employe.ville) {
                        setp_detail_profil(mylocal_employe.profil);
                    }


                    if (mylocal_employe.fonction) {
                        setp_detail_fonction(mylocal_employe.fonction);
                    }

                    if (mylocal_employe.lms_username) {
                        setp_detail_lms_login(mylocal_employe.lms_username);

                    } else {

                        setp_detail_lms_login("");
                    }


                    if (mylocal_employe.user_login) {
                        setp_detail_user_login(mylocal_employe.user_login);
                        setp_new_login(mylocal_employe.user_login)
                    } else {
                        setp_new_login(mylocal_employe.email);
                        setp_detail_user_login("");
                    }

                    if (mylocal_employe.lms_virtualhost_url) {
                        setp_detail_lms_virtualhost_url(mylocal_employe.lms_virtualhost_url);

                    } else {
                        setp_detail_lms_virtualhost_url('');
                    }

                    if (mylocal_employe.locked) {
                        setp_detail_user_login_locked(mylocal_employe.locked);

                    } else {
                        setp_detail_user_login_locked('');
                    }

                    if (mylocal_employe.mysy_lms_user_id) {
                        setp_detail_mysy_lms_user_id(mylocal_employe.mysy_lms_user_id);

                    } else {
                        setp_detail_mysy_lms_user_id('');
                    }

                    if (mylocal_employe.lms_account_disable) {
                        setp_detail_lms_account_disable(mylocal_employe.lms_account_disable);

                    } else {
                        setp_detail_lms_account_disable('');
                    }




                    setp_detail_ismanager("0");
                    if (mylocal_employe.ismanager) {
                        setp_detail_ismanager(mylocal_employe.ismanager);
                        if (String(mylocal_employe.ismanager) === "1")
                            setp_detail_ismanager_label("Oui");
                        else
                            setp_detail_ismanager_label("Non");
                    }


                    if (mylocal_employe.superieur_hierarchie_id && String(mylocal_employe.superieur_hierarchie_id).length > 2) {
                        setp_detail_resp_hierarchie_id(mylocal_employe.superieur_hierarchie_id);
                        setp_detail_resp_hierarchie_nom(mylocal_employe.superieur_hierarchie_nom);
                        setp_detail_resp_hierarchie_prenom(mylocal_employe.superieur_hierarchie_prenom);
                    } else {
                        setp_detail_resp_hierarchie_nom("");
                        setp_detail_resp_hierarchie_prenom("");
                    }

                    /*
                    * Update 22/10/2023 :
                    Gestion des champs spécifiques. ils commencent tous par 'my_'
                    */


                    for (let i = 0; i < rows_champs_specifics.length; i++) {

                        var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
                        var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
                        var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
                        var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


                        if (mylocal_employe.hasOwnProperty(field_name)) {
                            var local_valeur = mylocal_employe[String(field_name)]

                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = local_valeur;

                            var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type }
                            var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);

                            if (johnIndex > -1) {
                                spec_field_updated_values[johnIndex] = new_val;

                            } else {
                                spec_field_updated_values.push(new_val);
                            }

                        } else {

                        }
                    }

                    setspec_field_updated_values_hooks(spec_field_updated_values);
                    /*
                                       *  end Update 22/10/2023 :
                                       */



                    disable_Employee_DetailFields();
                } else {
                    alert(" Aucun employé recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Employee_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de l'employé");
            //setmyApimyApiMessage("")
        })
    }



    const [Getall_Training_Employee_api, setGetall_Training_Employee_api] = useState();
    const [Getall_Training_Employee_message, setGetall_Training_Employee_message] = useState();
    const [Getall_Training_Employee_result, setGetall_Training_Employee_result] = useState();
    function Getall_Training_Employee(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_with_filter/";


        setdisplay_affectation("");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
        setSelectionModel_affectations([]);
        setSelectionModel([]);
        setsubmenu("");
        setSelectionModel([]);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_api("true");
                setGetall_Training_Employee_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Training_Employee_api("false");
                setGetall_Training_Employee_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee = ', error);
            setGetall_Training_Employee_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [session_file_name, setsession_file_name] = useState();

    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Ressource_Humaine_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                setLoading(false);
                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");
                    clean_all_filters();
                    Getall_Training_Employee();
                    alert(" Les employés ont été correctement importés");

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer les employés ");
            });
    }


    const [Get_Given_Employee_Affectation_api, setGet_Given_Employee_Affectation_api] = useState();
    const [Get_Given_Employee_Affectation_message, setGet_Given_Employee_Affectation_message] = useState();
    const [Get_Given_Employee_Affectation_result, setGet_Given_Employee_Affectation_result] = useState();
    function Get_Given_Employee_Affectation(local_affectation_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("affectation_id", local_affectation_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Affectation_Ressource_Humaine_Poste/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                console.log(" In Get_Given_Employee_Affectation  res.data.status = " + res.data.status);
                console.log(" In Get_Given_Employee_Affectation  res.data.message r_class = " + res.data.message);
                setGet_Given_Employee_Affectation_api("true");



                if (String(res.data.message).length > 0) {
                    setGet_Given_Employee_Affectation_result(res.data.message);
                    var mylocal_affectation = JSON.parse(res.data.message);

                    if (mylocal_affectation.poste) {
                        setp_affect_poste(mylocal_affectation.poste);
                    }

                    if (mylocal_affectation.date_du) {
                        setp_affect_date_du(mylocal_affectation.date_du);

                    }

                    if (mylocal_affectation.date_au) {
                        setp_affect_date_au(mylocal_affectation.date_au);
                    }

                    if (mylocal_affectation.related_target_collection) {
                        setp_affect_cible(mylocal_affectation.related_target_collection);
                        if (String(mylocal_affectation.related_target_collection) === "myclass")
                            setp_affect_cible_label("Formation");
                        else if (String(mylocal_affectation.related_target_collection) === "session_formation")
                            setp_affect_cible_label("Session formation");
                    }


                    if (mylocal_affectation.related_target_collection_id) {
                        setp_affect_cible_nom(mylocal_affectation.related_target_collection_id);
                        setp_affect_cible_nom_label(mylocal_affectation.related_target_collection_id_nom);
                    }

                    if (mylocal_affectation.comment) {
                        setp_affect_comment(mylocal_affectation.comment);
                    }


                } else {
                    alert(" Aucune donnée recuperée");
                }

                Disable_Detail_Affectation_Fields();
            }
            else {
                setGet_Given_Employee_Affectation_api("false");
                setGet_Given_Employee_Affectation_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Employee_Affectation = ', error);
            setGet_Given_Employee_Affectation_api("false");
            alert(" Impossible de recuperer l'affectation de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Employee_Affectation_api, setGetall_Employee_Affectation_api] = useState();
    const [Getall_Employee_Affectation_message, setGetall_Employee_Affectation_message] = useState();
    const [Getall_Employee_Affectation_result, setGetall_Employee_Affectation_result] = useState();
    function Getall_Employee_Affectation(local_employe_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", local_employe_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_Affectation/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Employee_Affectation  res.data.status = " + res.data.status);
                //console.log(" In Getall_Employee_Affectation  res.data.message r_class = " + res.data.message);
                setGetall_Employee_Affectation_api("true");
                setGetall_Employee_Affectation_result(res.data.message);
                setrows_affectations(res.data.message);
            }
            else {
                setGetall_Employee_Affectation_api("false");
                setGetall_Employee_Affectation_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Employee_Affectation = ', error);
            setGetall_Employee_Affectation_api("false");
            alert(" Impossible de recuperer les affectations de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Employee_Related_Target_Collection_Data_api, setGet_Employee_Related_Target_Collection_Data_api] = useState();
    const [Get_Employee_Related_Target_Collection_Data_message, setGet_Employee_Related_Target_Collection_Data_message] = useState();
    const [Get_Employee_Related_Target_Collection_Data_result, setGet_Employee_Related_Target_Collection_Data_result] = useState();
    function Get_Employee_Related_Target_Collection_Data() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Related_Target_Collection_Data/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Employee_Related_Target_Collection_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Employee_Related_Target_Collection_Data  res.data.message r_class = " + res.data.message);
                setGet_Employee_Related_Target_Collection_Data_api("true");
                setGet_Employee_Related_Target_Collection_Data_result(res.data.message);

            }
            else {
                setGet_Employee_Related_Target_Collection_Data_api("false");
                setGet_Employee_Related_Target_Collection_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Employee_Related_Target_Collection_Data = ', error);
            setGet_Employee_Related_Target_Collection_Data_api("false");
            alert(" Impossible de recuperer les cibles d'affectation");
            //setmyApimyApiMessage("")
        })
    }

    function disable_Employee_DetailFields() {
        setemployee_data_edit_mode("0");

        if (document.getElementsByName("detail_lms_login")[0]) {
            document.getElementsByName("detail_lms_login")[0].disabled = true;
            document.getElementsByName("detail_lms_login")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_nom")[0]) {
            document.getElementsByName("detail_nom")[0].disabled = true;
            document.getElementsByName("detail_nom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_prenom")[0]) {
            document.getElementsByName("detail_prenom")[0].disabled = true;
            document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_mail")[0]) {
            document.getElementsByName("detail_mail")[0].disabled = true;
            document.getElementsByName("detail_mail")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_naissance")[0]) {
            document.getElementsByName("detail_naissance")[0].disabled = true;
            document.getElementsByName("detail_naissance")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_tel")[0]) {
            document.getElementsByName("detail_tel")[0].disabled = true;
            document.getElementsByName("detail_tel")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_adresse")[0]) {
            document.getElementsByName("detail_adresse")[0].disabled = true;
            document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_code_postal")[0]) {
            document.getElementsByName("detail_code_postal")[0].disabled = true;
            document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_ville")[0]) {
            document.getElementsByName("detail_ville")[0].disabled = true;
            document.getElementsByName("detail_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_civilite")[0]) {
            document.getElementsByName("detail_civilite")[0].disabled = true;
            document.getElementsByName("detail_civilite")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tel_mobile")[0]) {
            document.getElementsByName("detail_tel_mobile")[0].disabled = true;
            document.getElementsByName("detail_tel_mobile")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_pays")[0]) {
            document.getElementsByName("detail_pays")[0].disabled = true;
            document.getElementsByName("detail_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_linkedin")[0]) {
            document.getElementsByName("detail_linkedin")[0].disabled = true;
            document.getElementsByName("detail_linkedin")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_facebook")[0]) {
            document.getElementsByName("detail_facebook")[0].disabled = true;
            document.getElementsByName("detail_facebook")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_twitter")[0]) {
            document.getElementsByName("detail_twitter")[0].disabled = true;
            document.getElementsByName("detail_twitter")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_profil")[0]) {
            document.getElementsByName("detail_profil")[0].disabled = true;
            document.getElementsByName("detail_profil")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_fonction")[0]) {
            document.getElementsByName("detail_fonction")[0].disabled = true;
            document.getElementsByName("detail_fonction")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_ismanager")[0]) {
            document.getElementsByName("detail_ismanager")[0].disabled = true;
            document.getElementsByName("detail_ismanager")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_user_login")[0]) {
            document.getElementsByName("detail_user_login")[0].disabled = true;
            document.getElementsByName("detail_user_login")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_resp_hierarchie_id")[0]) {
            document.getElementsByName("detail_resp_hierarchie_id")[0].disabled = true;
            document.getElementsByName("detail_resp_hierarchie_id")[0].style.backgroundColor = "#ECEFF1";
        }

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = true;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
            }
        }

    }

    function Enable_Employee_DetailFields() {
        setemployee_data_edit_mode("1");


        document.getElementsByName("detail_nom")[0].disabled = false;
        document.getElementsByName("detail_nom")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_prenom")[0].disabled = false;
        document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_mail")[0].disabled = false;
        document.getElementsByName("detail_mail")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_naissance")[0].disabled = false;
        document.getElementsByName("detail_naissance")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_tel")[0].disabled = false;
        document.getElementsByName("detail_tel")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_adresse")[0].disabled = false;
        document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_code_postal")[0].disabled = false;
        document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_ville")[0].disabled = false;
        document.getElementsByName("detail_ville")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_civilite")[0].disabled = false;
        document.getElementsByName("detail_civilite")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_tel_mobile")[0].disabled = false;
        document.getElementsByName("detail_tel_mobile")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_pays")[0].disabled = false;
        document.getElementsByName("detail_pays")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_linkedin")[0].disabled = false;
        document.getElementsByName("detail_linkedin")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_facebook")[0].disabled = false;
        document.getElementsByName("detail_facebook")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_twitter")[0].disabled = false;
        document.getElementsByName("detail_twitter")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_profil")[0].disabled = false;
        document.getElementsByName("detail_profil")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_fonction")[0].disabled = false;
        document.getElementsByName("detail_fonction")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_ismanager")[0].disabled = false;
        document.getElementsByName("detail_ismanager")[0].style.backgroundColor = "#FFFFFF";

        //document.getElementsByName("detail_user_login")[0].disabled = false;
        //document.getElementsByName("detail_user_login")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_resp_hierarchie_id")[0].disabled = false;
        document.getElementsByName("detail_resp_hierarchie_id")[0].style.backgroundColor = "#FFFFFF";

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = false;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#FFFFFF";
            }
        }



    }


    function Annule_Employee_DetailFields() {

        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        Get_Employee_Data(selected_id);
        get_Employee_Images(selected_id);

        setemployee_data_changed("");
        setemployee_data_edit_mode("");

    }


    const [Update_One_Employee_Data_api, setUpdate_One_Employee_Data_api] = useState();
    const [Update_One_Employee_Data_message, setUpdate_One_Employee_Data_message] = useState();
    const [Update_One_Employee_Data_result, setUpdate_One_Employee_Data_result] = useState();
    function Update_One_Employee_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("nom", p_detail_nom);
        form.append("email", p_detail_mail);
        form.append("date_naissance", p_detail_naissance);

        if (p_detail_prenom)
            form.append("prenom", p_detail_prenom);
        else
            form.append("prenom", "");


        if (p_detail_tel)
            form.append("telephone", p_detail_tel);
        else
            form.append("telephone", "");


        if (p_detail_tel_mobile)
            form.append("telephone_mobile", p_detail_tel_mobile);
        else
            form.append("telephone_mobile", "");

        if (p_detail_adresse)
            form.append("adr_adresse", p_detail_adresse);
        else
            form.append("adr_adresse", "");


        if (p_detail_code_postal)
            form.append("adr_code_postal", p_detail_code_postal);
        else
            form.append("adr_code_postal", "");

        if (p_detail_ville)
            form.append("adr_ville", p_detail_ville);
        else
            form.append("adr_ville", "");

        if (p_detail_pays)
            form.append("adr_pays", p_detail_pays);
        else
            form.append("adr_pays", "");

        if (p_detail_profil)
            form.append("profil", p_detail_profil);
        else
            form.append("profil", "");


        if (p_detail_fonction)
            form.append("fonction", p_detail_fonction);
        else
            form.append("fonction", "");


        if (p_detail_civilite)
            form.append("civilite", p_detail_civilite);
        else
            form.append("civilite", "");


        if (p_detail_resp_hierarchie_id)
            form.append("superieur_hierarchie_id", p_detail_resp_hierarchie_id);
        else
            form.append("superieur_hierarchie_id", "");


        if (p_detail_linkedIn)
            form.append("linkedin", p_detail_linkedIn);
        else
            form.append("linkedin", "");

        if (p_detail_facebook)
            form.append("facebook", p_detail_facebook);
        else
            form.append("facebook", "");


        if (p_detail_twitter)
            form.append("twitter", p_detail_twitter);
        else
            form.append("twitter", "");



        if (p_detail_ismanager)
            form.append("ismanager", p_detail_ismanager);
        else
            form.append("ismanager", "0");

        form.append("_id", selected_id);


        /*
          Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
        */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            var local_value = String(spec_field_updated_values_hooks[i].field_value);


            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }


            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Ressource_Humaine/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Employee_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Employee_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_One_Employee_Data_api("true");
                setUpdate_One_Employee_Data_result(res.data.message);

                Get_List_Managers();
                Getall_Training_Employee();

                disable_Employee_DetailFields();
                setemployee_data_changed("");
                setemployee_data_edit_mode("");
                Get_Employee_Data(selected_id);
                setgridline_id('');
                alert(" La mise à jour été correctement faite.");


                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Employee_Data_api("false");
                setUpdate_One_Employee_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Employee_Data = ' + error);
            setUpdate_One_Employee_Data_api("false");
            alert(" Impossible de mettre à jour l'employé");

        })
    }


    const [record_employee_Image_api, setrecord_employee_Image_api] = useState();
    const [record_employee_Image_message, setrecord_employee_Image_message] = useState();
    const [record_employee_Image_result, setrecord_employee_Image_result] = useState();
    function record_employee_Image(l) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Update_Ressource_Humaine_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("rh_id", selected_id);

        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);

        setLoading(true);
        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_employee_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_employee_Image_api("false");
                    alert(" Erreur : " + result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setrecord_employee_Image_api("false");
                alert(" Erreur : Impossible d'enregistrer l'image ");

            });


    };


    const [get_Employee_Images_api, setget_Employee_Images_api] = useState();
    const [get_Employee_Images_message, setget_Employee_Images_message] = useState();
    const [get_Employee_Images_result, setget_Employee_Images_result] = useState();
    function get_Employee_Images(employee_id) {

        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecoded_Employee_Image_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", employee_id);


        axios.post(myurl, form).then(res => {

            //console.log(" get_Employee_Images : In test  res.data.status = " + res.data.status);
            // console.log(" get_Employee_Images: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setget_Employee_Images_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setget_Employee_Images_api("false");
                get_Employee_Images_message(res.data.message)
                alert(res.data.message);

            }
        }).catch((error) => {
            console.warn('get_Employee_Images ee: Not good man :(  = ', error);
            setget_Employee_Images_api("false");
            get_Employee_Images_message(" Impossible de recuperer l'image de l'employé")
        })

    }


    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/DeleteImage_Stagiaire_v2/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log(' removeRecodedClassImage : status:', result['status']);
                //console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");
                    get_Employee_Images();

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }


    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };


    const [Get_List_Managers_api, setGet_List_Managers_api] = useState();
    const [Get_List_Managers_message, setGet_List_Managers_message] = useState();
    const [Get_List_Managers_result, setGet_List_Managers_result] = useState();
    function Get_List_Managers(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Manager_Ressource_Humaine/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Managers  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Managers  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Managers_api("true");
                setGet_List_Managers_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Managers_api("false");
                setGet_List_Managers_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Managers = ', error);
            setGet_List_Managers_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


    const [Get_List_RH_Profils_api, setGet_List_RH_Profils_api] = useState();
    const [Get_List_RH_Profils_message, setGet_List_RH_Profils_message] = useState();
    const [Get_List_RH_Profils_result, setGet_List_RH_Profils_result] = useState();
    function Get_List_RH_Profils(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Profil_Ressource_Humaine/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_RH_Profils  res.data.status = " + res.data.status);
            //console.log(" In Get_List_RH_Profils  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_RH_Profils_api("true");
                setGet_List_RH_Profils_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_RH_Profils_api("false");
                setGet_List_RH_Profils_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_RH_Profils = ', error);
            setGet_List_RH_Profils_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Delete_Employee_api, setDelete_Employee_api] = useState();
    const [Delete_Employee_message, setDelete_Employee_message] = useState();
    const [Delete_Employee_result, setDelete_Employee_result] = useState();
    function Delete_Employee(local_rh_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_rh_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Ressource_Humaine/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Employee  res.data.status = " + res.data.status);
            //console.log(" In Delete_Employee  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_Employee_api("true");
                setDelete_Employee_result(res.data.message);
                clean_all_filters();
                clearEmployeeFields();
                alert(res.data.message);
            }
            else {
                setDelete_Employee_api("false");
                setDelete_Employee_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('Delete_Employee : Not good man :( Delete_Employee = ' + error);
            setDelete_Employee_api("false");
            alert(" Impossible de supprimer l'employee");
        })
    }


    const [Delete_affection_api, setDelete_affection_api] = useState();
    const [Delete_affection_message, setDelete_affection_message] = useState();
    const [Delete_affection_result, setDelete_affection_result] = useState();
    function Delete_affection(local_rh_id, local_affectation_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", local_rh_id);
        form.append("_id", local_affectation_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Affectation_Ressource_Humaine_Poste/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_affection  res.data.status = " + res.data.status);
            //console.log(" In Delete_affection  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_affection_api("true");
                setDelete_affection_result(res.data.message);
                clean_affectation_fields();
                setAffectation_data_changed();
                setedite_affectation_form();
                setdisplay_affectation();
                Getall_Employee_Affectation(local_rh_id);
                alert(" La fonction a été correctement supprimée.")
            }
            else {
                setDelete_affection_api("false");
                setDelete_affection_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('Delete_affection : Not good man :( Delete_affection = ' + error);
            setDelete_affection_api("false");
            alert(" Impossible de supprimer la fonction");
        })
    }


    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");

    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

    const myRef_head = useRef(null);
    const myRef_affectation = useRef(null);
    const myRef_new_affectation = useRef(null);
    const hiddenFileInput_session = React.useRef(null);
    const [Employee_data_changed, setEmployee_data_changed] = useState();

    const [add_One_Employee, setadd_One_Employee] = useState();

    const myRef_new_employee = useRef(null)

    function submenu_add_one_employee() {
        setdisplay_detail_employe();
        clearEmployeeFields();
        setadd_One_Employee("1");
        setsubmenu("detail_employe");

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }

    function Annule_add_one_Employee() {
        setdisplay_detail_employe();
        setadd_One_Employee();
        setsubmenu("");
        setgridline_id('');
        setSelectionModel([]);
    }


    const [Add_One_Employee_Data_api, setAdd_One_Employee_Data_api] = useState();
    const [Add_One_Employee_Data_message, setAdd_One_Employee_Data_message] = useState();
    const [Add_One_Employee_Data_result, setAdd_One_Employee_Data_result] = useState();
    function Add_One_Employee_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        // Controle sur les champs

        if (p_one_detail_civilite.trim() == "") {
            alert(" Vous devez saisir la civilité de l'employé");
            return;
        }

        if (p_one_detail_nom.trim() == "") {
            alert(" Vous devez saisir le nom de l'employé");
            return;
        }


        if (p_one_detail_prenom.trim() == "") {
            alert(" Vous devez saisir le prenom de l'employé");
            return;
        }


        if (p_one_detail_naissance.trim() == "") {
            alert(" Vous devez saisir la date de naissance l'employé");
            return;
        }



        if (p_one_detail_mail.trim() == "") {
            alert(" Vous devez saisir l'email de l'employé");
            return;
        }



        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_one_detail_mail.trim().match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }



        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("nom", p_one_detail_nom);
        form.append("email", p_one_detail_mail);
        form.append("prenom", p_one_detail_prenom);
        form.append("civilite", p_one_detail_civilite);
        form.append("date_naissance", p_one_detail_naissance);



        if (p_one_detail_tel)
            form.append("telephone", p_one_detail_tel);
        else
            form.append("telephone", "");


        if (p_one_detail_tel_mobile)
            form.append("telephone_mobile", p_one_detail_tel_mobile);
        else
            form.append("telephone_mobile", "");

        if (p_one_detail_adresse)
            form.append("adr_adresse", p_one_detail_adresse);
        else
            form.append("adr_adresse", "");


        if (p_one_detail_code_postal)
            form.append("adr_code_postal", p_one_detail_code_postal);
        else
            form.append("adr_code_postal", "");

        if (p_one_detail_ville)
            form.append("adr_ville", p_one_detail_ville);
        else
            form.append("adr_ville", "");

        if (p_one_detail_pays)
            form.append("adr_pays", p_one_detail_pays);
        else
            form.append("adr_pays", "");

        if (p_one_detail_profil)
            form.append("profil", p_one_detail_profil);
        else
            form.append("profil", "");


        if (p_one_detail_fonction)
            form.append("fonction", p_one_detail_fonction);
        else
            form.append("fonction", "");





        if (p_one_detail_resp_hierarchie_id)
            form.append("superieur_hierarchie_id", p_one_detail_resp_hierarchie_id);
        else
            form.append("superieur_hierarchie_id", "");


        if (p_one_detail_linkedIn)
            form.append("linkedin", p_one_detail_linkedIn);
        else
            form.append("linkedin", "");

        if (p_one_detail_facebook)
            form.append("facebook", p_one_detail_facebook);
        else
            form.append("facebook", "");


        if (p_one_detail_twitter)
            form.append("twitter", p_one_detail_twitter);
        else
            form.append("twitter", "");

        if (p_one_detail_ismanager)
            form.append("ismanager", p_one_detail_ismanager);
        else
            form.append("ismanager", "0");


        /*
      Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
    */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
            var local_value = String(spec_field_updated_values_hooks[i].field_value);

            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }
            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
        }


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Ressource_Humaine/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Employee_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Employee_Data  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Employee_Data_api("true");
                setAdd_One_Employee_Data_result(res.data.message);

                Getall_Training_Employee_No_Filter();
                Get_List_Managers();


                setemployee_data_changed("");
                setemployee_data_edit_mode("");
                clearEmployeeFields();
                setadd_One_Employee("");
                setselected_id('');
                setgridline_id('');
                setsubmenu('');

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_One_Employee_Data_api("false");
                setAdd_One_Employee_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Employee_Data = ' + error);
            setAdd_One_Employee_Data_api("false");
            alert(" Impossible d'ajouter l'employé");

        })
    }


    function submenu_import_employee() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }


    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }




    async function submenu_detail_employe() {
        setsubmenu("detail_employe");

        await sleep(5);
        /*if (!edit_session_form) {
            desableSessionFields();
        }*/

        if (document.getElementById("detail_employe")) {
            document.getElementById("detail_employe").style.backgroundColor = "#104277";
            document.getElementById("detail_employe").style.color = "white";
        }

        if (document.getElementById("droit_acces")) {
            document.getElementById("droit_acces").style.backgroundColor = "#d8edfc";
            document.getElementById("droit_acces").style.color = "black";
        }

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#d8edfc";
            document.getElementById("affectation").style.color = "black";
        }

        if (document.getElementById("contrat")) {
            document.getElementById("contrat").style.backgroundColor = "#d8edfc";
            document.getElementById("contrat").style.color = "black";
        }

        //console.log(" ### employee_data_edit_mode = ", employee_data_edit_mode);
        if (String(employee_data_edit_mode) !== "1")
            disable_Employee_DetailFields();

        setSelectionModel_affectations([]);
        setdisplay_affectation("0");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();

    }


    async function submenu_affectation() {
        setsubmenu("affectation");

        await sleep(5);
        /*if (!edit_session_form) {
            desableSessionFields();
        }*/

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#104277";
            document.getElementById("affectation").style.color = "white";
        }

        if (document.getElementById("detail_employe")) {
            document.getElementById("detail_employe").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_employe").style.color = "black";
        }

        if (document.getElementById("droit_acces")) {
            document.getElementById("droit_acces").style.backgroundColor = "#d8edfc";
            document.getElementById("droit_acces").style.color = "black";
        }

        if (document.getElementById("contrat")) {
            document.getElementById("contrat").style.backgroundColor = "#d8edfc";
            document.getElementById("contrat").style.color = "black";
        }


    }

    async function submenu_contrat() {
        setsubmenu("contrat");

        await sleep(5);
        if (document.getElementById("contrat")) {
            document.getElementById("contrat").style.backgroundColor = "#104277";
            document.getElementById("contrat").style.color = "white";
        }

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#d8edfc";
            document.getElementById("affectation").style.color = "black";
        }

        if (document.getElementById("detail_employe")) {
            document.getElementById("detail_employe").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_employe").style.color = "black";
        }

        if (document.getElementById("droit_acces")) {
            document.getElementById("droit_acces").style.backgroundColor = "#d8edfc";
            document.getElementById("droit_acces").style.color = "black";
        }

    }


    async function submenu_droit_acces() {

        setsubmenu("droit_acces");

        sleep(5);

        if (document.getElementById("droit_acces")) {
            document.getElementById("droit_acces").style.backgroundColor = "#104277";
            document.getElementById("droit_acces").style.color = "white";
        }

        if (document.getElementById("detail_employe")) {
            document.getElementById("detail_employe").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_employe").style.color = "black";
        }

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#d8edfc";
            document.getElementById("affectation").style.color = "black";
        }

        if (document.getElementById("contrat")) {
            document.getElementById("contrat").style.backgroundColor = "#d8edfc";
            document.getElementById("contrat").style.color = "black";
        }

        setSelectionModel_affectations([]);
        setdisplay_affectation("0");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
    }


    async function submenu_droit_acces_orig() {

        /***
         * Verifier que le user à acces a la lecture à minima de ce sous menu
         */

        let val = await Check_User_Access_Right('employe', 'read');
        await sleep(50);

        if (String(Check_User_Access_Right_api) === "true")
            setsubmenu("droit_acces");
        else {
            console.log(" IICheck_User_Access_Right_api = ", Check_User_Access_Right_api);
            return;
        }

        await sleep(5);
        console.log(" IICheck_User_Access_Right_api = ", Check_User_Access_Right_api);
        /*if (!edit_session_form) {
            desableSessionFields();
        }*/

        if (document.getElementById("droit_acces")) {
            document.getElementById("droit_acces").style.backgroundColor = "#104277";
            document.getElementById("droit_acces").style.color = "white";
        }

        if (document.getElementById("detail_employe")) {
            document.getElementById("detail_employe").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_employe").style.color = "black";
        }

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#d8edfc";
            document.getElementById("affectation").style.color = "black";
        }
        setSelectionModel_affectations([]);
        setdisplay_affectation("0");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();

    }


    const New_Option_Oui_Non = [
        {
            value: '1',
            label: 'Oui',
        },
        {
            value: '0',
            label: 'Non',
        },

    ];


    const New_Option_Civilite = [
        {
            value: 'M',
            label: 'M',
        },
        {
            value: 'Mme',
            label: 'Mme',
        },
        {
            value: 'Neutre',
            label: 'Neutre',
        },
    ];

    const [display_affectation, setdisplay_affectation] = useState();
    const [p_affect_poste, setp_affect_poste] = useState();
    const [p_affect_date_du, setp_affect_date_du] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_affect_date_au, setp_affect_date_au] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_affect_cible, setp_affect_cible] = useState();
    const [p_affect_cible_label, setp_affect_cible_label] = useState();

    const [p_affect_cible_nom, setp_affect_cible_nom] = useState();
    const [p_affect_cible_nom_label, setp_affect_cible_nom_label] = useState();


    const [edite_affectation_form, setedite_affectation_form] = useState();
    const [p_affect_comment, setp_affect_comment] = useState();
    const [p_related_target_collection, setp_related_target_collection] = useState();
    const [p_related_target_collection_id, setp_related_target_collection_id] = useState();


    function clean_affectation_fields() {
        setp_affect_poste();
        setp_affect_date_du(new Date().toLocaleDateString('fr-FR'));
        setp_affect_date_au(date_today_90j.toLocaleDateString('fr-FR'));
        setp_affect_cible();
        setp_affect_cible_label();
        setp_affect_cible_nom();
        setp_affect_cible_nom_label();
        setp_affect_comment();

        if (document.getElementsByName("affect_poste")[0])
            document.getElementsByName("affect_poste")[0].value = "";

        if (document.getElementsByName("affect_cible")[0])
            document.getElementsByName("affect_cible")[0].value = "";

        if (document.getElementsByName("affect_cible_nom")[0])
            document.getElementsByName("affect_cible_nom")[0].value = "";

        if (document.getElementsByName("affect_date_du")[0])
            document.getElementsByName("affect_date_du")[0].value = "";

        if (document.getElementsByName("affect_date_au")[0])
            document.getElementsByName("affect_date_au")[0].value = "";

        if (document.getElementsByName("affect_comment")[0])
            document.getElementsByName("affect_comment")[0].value = "";


    }


    const [p_one_affect_poste, setp_one_affect_poste] = useState();
    const [p_one_affect_date_du, setp_one_affect_date_du] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_one_affect_date_au, setp_one_affect_date_au] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_one_affect_cible, setp_one_affect_cible] = useState();
    const [p_one_affect_cible_nom, setp_one_affect_cible_nom] = useState();
    const [p_one_affect_comment, setp_one_affect_comment] = useState();

    function clean_nouvelle_affectation_fields() {
        setp_one_affect_poste();
        setp_one_affect_date_du(new Date().toLocaleDateString('fr-FR'));
        setp_one_affect_date_au(date_today_90j.toLocaleDateString('fr-FR'));
        setp_one_affect_cible();
        setp_one_affect_cible_nom();

        setp_one_affect_comment();

        if (document.getElementsByName("one_affect_poste")[0])
            document.getElementsByName("one_affect_poste")[0].value = "";

        if (document.getElementsByName("one_affect_cible")[0])
            document.getElementsByName("one_affect_cible")[0].value = "";

        if (document.getElementsByName("one_affect_cible_nom")[0])
            document.getElementsByName("one_affect_cible_nom")[0].value = "";

        if (document.getElementsByName("one_affect_date_du")[0])
            document.getElementsByName("one_affect_date_du")[0].value = "";

        if (document.getElementsByName("one_affect_date_au")[0])
            document.getElementsByName("one_affect_date_au")[0].value = "";

        if (document.getElementsByName("one_affect_comment")[0])
            document.getElementsByName("one_affect_comment")[0].value = "";
    }


    const [Affectation_data_changed, setAffectation_data_changed] = useState();


    function Disable_Detail_Affectation_Fields() {
        setedite_affectation_form("0");

        if (document.getElementsByName("affect_poste")[0]) {
            document.getElementsByName("affect_poste")[0].disabled = true;
            document.getElementsByName("affect_poste")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_cible")[0]) {
            document.getElementsByName("affect_cible")[0].disabled = true;
            document.getElementsByName("affect_cible")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_cible_nom")[0]) {
            document.getElementsByName("affect_cible_nom")[0].disabled = true;
            document.getElementsByName("affect_cible_nom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_date_du")[0]) {
            document.getElementsByName("affect_date_du")[0].disabled = true;
            document.getElementsByName("affect_date_du")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_date_au")[0]) {
            document.getElementsByName("affect_date_au")[0].disabled = true;
            document.getElementsByName("affect_date_au")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("affect_comment")[0]) {
            document.getElementsByName("affect_comment")[0].disabled = true;
            document.getElementsByName("affect_comment")[0].style.backgroundColor = "#ECEFF1";
        }

    }


    function Enable_Detail_Affectation_Fields() {

        setedite_affectation_form("1");

        if (document.getElementsByName("affect_poste")[0]) {
            document.getElementsByName("affect_poste")[0].disabled = false;
            document.getElementsByName("affect_poste")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_cible")[0]) {
            document.getElementsByName("affect_cible")[0].disabled = false;
            document.getElementsByName("affect_cible")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_cible_nom")[0]) {
            document.getElementsByName("affect_cible_nom")[0].disabled = false;
            document.getElementsByName("affect_cible_nom")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_date_du")[0]) {
            document.getElementsByName("affect_date_du")[0].disabled = false;
            document.getElementsByName("affect_date_du")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_date_au")[0]) {
            document.getElementsByName("affect_date_au")[0].disabled = false;
            document.getElementsByName("affect_date_au")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("affect_comment")[0]) {
            document.getElementsByName("affect_comment")[0].disabled = false;
            document.getElementsByName("affect_comment")[0].style.backgroundColor = "#FFFFFF";
        }
    }


    function Annule_Affectation_DetailFields() {
        clean_affectation_fields();
        Get_Given_Employee_Affectation(selected_affectation_id);
        setAffectation_data_changed("");
        setedite_affectation_form("");

    }


    function Fermer_Affectation_Fields() {
        clean_affectation_fields();
        setedite_affectation_form();
        setdisplay_affectation();
        setSelectionModel_affectations([]);
    }

    function Fermer_nouvelle_Affectation_Fields() {
        clean_nouvelle_affectation_fields();
        setedite_affectation_form();
        setdisplay_affectation();
        setAdd_new_affectation();
        setSelectionModel_affectations([]);
    }




    const [Update_One_Affectation_Data_api, setUpdate_One_Affectation_Data_api] = useState();
    const [Update_One_Affectation_Data_message, setUpdate_One_Affectation_Data_message] = useState();
    const [Update_One_Affectation_Data_result, setUpdate_One_Affectation_Data_result] = useState();
    function Update_One_Affectation_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_affectation_id);
        form.append("rh_id", selected_id);

        if (p_affect_poste)
            form.append("poste", p_affect_poste);
        else {
            alert(" Vous devez definir une poste pour cette affectation");
            return;
        }

        if (p_affect_date_du)
            form.append("date_du", p_affect_date_du);
        else {
            alert(" Vous devez definir une date de début pour cette affectation");
            return;
        }

        if (p_affect_date_au)
            form.append("date_au", p_affect_date_au);
        else
            form.append("date_au", "");


        if (p_affect_comment)
            form.append("comment", p_affect_comment);
        else
            form.append("comment", "");


        if (p_affect_cible)
            form.append("related_target_collection", p_affect_cible);
        else
            form.append("related_target_collection", "");

        if (p_affect_cible_nom)
            form.append("related_target_collection_id", p_affect_cible_nom);
        else
            form.append("related_target_collection_id", "");



        //console.log(" affectation form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Affectation_Ressource_Humaine_Poste/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Affectation_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Affectation_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_Affectation_Data_api("true");
                setUpdate_One_Affectation_Data_result(res.data.message);

                Disable_Detail_Affectation_Fields();
                setAffectation_data_changed("");
                setedite_affectation_form("");
                Getall_Employee_Affectation(selected_id);
                setSelectionModel_affectations([]);

                alert(" La mise à jour été correctement faite.");

                if (document.getElementById('myRef_affectation')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef_affectation').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Affectation_Data_api("false");
                setUpdate_One_Affectation_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.log('UpdateStagiaireData : Not good man :( Update_One_Affectation_Data = ' + error);
            setUpdate_One_Affectation_Data_api("false");
            alert(" Impossible de mettre à jour l'affectation");

        })
    }


    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    function add_one_affectation_block() {

        setdisplay_affectation("");

        setAdd_new_affectation("1");

        if (document.getElementById('myRef_new_affectation')) {
            //myRef_new_affectation.current.scrollIntoView({ behavior: "smooth" });
            if (document.getElementById('myRef_new_affectation')) {
                //myRef.current.scrollIntoView({ behavior: "smooth" });
                var divh = document.getElementById('myRef_new_affectation').offsetTop;
                window.scrollTo({
                    top: divh,
                    behavior: "smooth",
                });
            }
        }

        clean_affectation_fields();

    }


    const [Add_one_affectation_api, setAdd_one_affectation_api] = useState();
    const [Add_one_affectation_message, setAdd_one_affectation_message] = useState();
    const [Add_one_affectation_result, setAdd_one_affectation_result] = useState();
    function Add_one_affectation() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", selected_id);

        if (p_one_affect_poste)
            form.append("poste", p_one_affect_poste);
        else {
            alert(" Vous devez definir une poste pour cette affectation");
            return;
        }

        if (p_one_affect_date_du)
            form.append("date_du", p_one_affect_date_du);
        else {
            alert(" Vous devez definir une date de début pour cette affectation");
            return;
        }


        if (p_one_affect_date_au)
            form.append("date_au", p_one_affect_date_au);
        else
            form.append("date_au", "");


        if (p_one_affect_comment)
            form.append("comment", p_one_affect_comment);
        else
            form.append("comment", "");


        if (p_one_affect_cible)
            form.append("related_target_collection", p_one_affect_cible);
        else
            form.append("related_target_collection", "");

        if (p_one_affect_cible_nom)
            form.append("related_target_collection_id", p_one_affect_cible_nom);
        else
            form.append("related_target_collection_id", "");


        //console.log(" Add_one_affectation affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Affectation_Ressource_Humaine_Poste/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_one_affectation  res.data.status = " + res.data.status);
            //console.log(" In Add_one_affectation  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setAdd_one_affectation_api("true");
                setAdd_one_affectation_result(res.data.message);

                Disable_Detail_Affectation_Fields();
                setAffectation_data_changed("");
                setedite_affectation_form("");
                setAdd_new_affectation("");
                Getall_Employee_Affectation(selected_id);
                setSelectionModel_affectations([]);
                alert(" L'affectation a été ajoutée.");

                if (document.getElementById('myRef_affectation')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef_affectation').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setLoading(false);
                setAdd_one_affectation_api("false");
                setAdd_one_affectation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Add_one_affectation = ' + error);
            setAdd_one_affectation_api("false");
            alert(" Impossible d'ajouter l'affectation");

        })
    }

    const [p_user_profil, setp_user_profil] = useState();
    const [p_user_profil_tmp, setp_user_profil_tmp] = useState();
    const [p_user_profil_changed, setp_user_profil_changed] = useState();

    const [acces_right_data_changed, setacces_right_data_changed] = useState();
    const [acces_right_data_edit_mode, setacces_right_data_edit_mode] = useState();
    const [acces_right_data_edit_mode_boolean, setacces_right_data_edit_mode_boolean] = useState(false);



    const [warning_bg_color, setwarning_bg_color] = useState('');


    function Enable_acces_right_datagrid() {
        setacces_right_data_edit_mode("1");
        setacces_right_data_edit_mode_boolean(true);
        setacces_right_data_changed();
        setwarning_bg_color('#fffff0 ');
    }

    function Disable_acces_right_datagrid() {
        setacces_right_data_edit_mode();
        setacces_right_data_edit_mode_boolean(false);
        setacces_right_data_changed();
        setwarning_bg_color('');
    }

    const [Update_user_access_right_Data_api, setUpdate_user_access_right_Data_api] = useState();
    const [Update_user_access_right_Data_message, setUpdate_user_access_right_Data_message] = useState();
    const [Update_user_access_right_Data_result, setUpdate_user_access_right_Data_result] = useState();
    function Update_user_access_right_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("user_id", selected_id);
        form.append("tab_access", rows_acces_right);



        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_User_Access_Right/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_user_access_right_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_user_access_right_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setUpdate_user_access_right_Data_api("true");
                setUpdate_user_access_right_Data_result(res.data.message);
                setacces_right_data_edit_mode();
                alert(" La mise à jour été correctement faite.");

            }
            else {
                setUpdate_user_access_right_Data_api("false");
                setUpdate_user_access_right_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_user_access_right_Data = ' + error);
            setUpdate_user_access_right_Data_api("false");
            alert(" Impossible de mettre à jour les Droits d'accès de l'employé");

        })
    }


    const [acces_right_data_tmp_data, setacces_right_data_tmp_data] = useState([]);
    const [tableData, setTableData] = useState();

    const access_right_datagrid_changed = (event) => {

        var myid = String(event.target.id);

        var tab = myid.split('_');
        var module_nom = tab[0];
        var module_acces = tab[1]


        //console.log(" #### myid=", myid, " module_nom = ", module_nom, " module_acces = ", module_acces);

        const updatedData = rows_acces_right.map((x) => {


            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_write = JSON.parse(x).write;
            var local_read = JSON.parse(x).read


            if (JSON.parse(x).module_name === module_nom && module_acces === "read") {
                var new_valeur_read = "false";
                if (String(local_read) === "false")
                    new_valeur_read = "true";


                return '{"_id": "' + local_id + '", "module_name": "' + module_nom + '", "valide": "1", "locked": "0", "id": "' + localid + '", "read": "' + new_valeur_read + '", "write": "' + local_write + '"}';

            }
            if (JSON.parse(x).module_name === module_nom && module_acces === "write") {
                var new_valeur_write = "false";
                if (String(local_write) === "false")
                    new_valeur_write = "true";
                return '{"_id": "' + local_id + '", "module_name": "' + module_nom + '", "valide": "1", "locked": "0", "id": "' + localid + '", "read": ' + local_read + ', "write": "' + new_valeur_write + '"}';

            }

            return x;
        });


        //console.log(" #### changed  updatedData=", updatedData);
        //console.log(" #### changed  rows_acces_right=", rows_acces_right);

        setrows_acces_right(updatedData);

    };



    const [Dialog_1_message_create_update_login_pwd, setDialog_1_message_create_update_login_pwd] = React.useState(false);
    const [Dialog_1_open_create_update_login_pwd, setDialog_1_open_create_update_login_pwd] = React.useState(false);

    function Dialog_1_handle_change_login_pwd() {
        setDialog_1_message_create_update_login_pwd("Souhaitez-vous modifier les accès (login & pwd) de l'utilisateur ?");
        setDialog_1_open_create_update_login_pwd(true);
    }

    const Dialog_1_handleClose_buton_create_update_login_pwd = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handle_OK_buton_create_update_login_pwd = () => {

        setDialog_1_open_create_update_login_pwd(false);
        setDialog_1_open_create_update_login_pwd_update(true);
    };


    const Dialog_1_handleCancel_buton_create_update_login_pwd = () => {
        setDialog_1_open_create_update_login_pwd(false);
    };

    const [Dialog_1_message_create_update_login_pwd_update, setDialog_1_message_create_update_login_pwd_update] = React.useState(false);
    const [Dialog_1_open_create_update_login_pwd_update, setDialog_1_open_create_update_login_pwd_update] = React.useState(false);

    function Dialog_1_handle_change_login_pwd_update() {
        setDialog_1_message_create_update_login_pwd_update("Souhaitez-vous modifier les accès (login & pwd) de l'utilisateur ?");
        setDialog_1_open_create_update_login_pwd_update(true);
    }

    const Dialog_1_handleClose_buton_create_update_login_pwd_update = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handle_OK_buton_create_update_login_pwd_update = () => {

        setDialog_1_open_create_update_login_pwd_update(false);
    };


    const Dialog_1_handleCancel_buton_create_update_login_pwd_update = () => {
        setDialog_1_open_create_update_login_pwd_update(false);
        //setp_new_login('');
        setp_new_pwd('');
        setp_conf_new_login('');

    };


    const [p_new_login, setp_new_login] = React.useState("");
    const [p_new_pwd, setp_new_pwd] = React.useState("");
    const [p_conf_new_login, setp_conf_new_login] = React.useState("");

    function OnchangeCellDataGrid(params) {


        var num_line = params.id;
        var colomn = params.field;
        var new_value = params.value;

        //console.log(" €€€  rows_acces_right[num_line]", rows_acces_right[num_line]);

        var module_nom = JSON.parse(rows_acces_right[num_line]).module_name;
        var module_acces = colomn;

        //console.log(" la ligne qui nous interresse est : module_nom = ", module_nom, "  module_acces =  ", module_acces);


        const updatedData = rows_acces_right.map((x) => {

            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;


            var local_write_txt = JSON.parse(x).write;
            var local_write = false;
            if (String(local_write_txt) === "true")
                local_write = true;


            var local_read_txt = JSON.parse(x).read
            var local_read = false;
            if (String(local_read_txt) === "true")
                local_read = true;


            if (JSON.parse(x).module_name === module_nom && module_acces === "read") {
                return '{"_id": "' + local_id + '", "module_name": "' + module_nom + '", "valide": "1", "locked": "0", "id": "' + localid + '", "read": ' + new_value + ', "write": ' + local_write + '}';
            }
            if (JSON.parse(x).module_name === module_nom && module_acces === "write") {
                return '{"_id": "' + local_id + '", "module_name": "' + module_nom + '", "valide": "1", "locked": "0", "id": "' + localid + '", "read": ' + local_read + ', "write": ' + new_value + '}';
            }

            return x;
        });

        //console.log(" #### changed  updatedData=", updatedData);
        //console.log(" #### changed  rows_acces_right=", rows_acces_right);


        setrows_acces_right(updatedData);
        setacces_right_data_changed("1");

    }


    const [Add_Update_Employee_Login_Pass_Data_api, setAdd_Update_Employee_Login_Pass_Data_api] = useState();
    const [Add_Update_Employee_Login_Pass_Data_message, setAdd_Update_Employee_Login_Pass_Data_message] = useState();
    const [Add_Update_Employee_Login_Pass_Data_result, setAdd_Update_Employee_Login_Pass_Data_result] = useState();
    function Add_Update_Employee_Login_Pass_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        // Controle sur les champs

        if (p_new_login.trim() == "") {
            alert(" Vous devez saisir le mail de login");
            return;
        }


        if (p_new_pwd.trim() == "") {
            alert(" Vous devez saisir un mot de passe");
            return;
        }


        if (p_conf_new_login.trim() == "") {
            alert(" Vous devez confirmer le mot de passe");
            return;
        }


        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_new_login.match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }



        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("email", p_new_login);
        form.append("pwd", p_new_pwd);
        form.append("ressource_humaine_id", selected_id);



        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Employee_Login_Pass/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_Update_Employee_Login_Pass_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Employee_Login_Pass_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_Update_Employee_Login_Pass_Data_api("true");
                setAdd_Update_Employee_Login_Pass_Data_result(res.data.message);

                setp_detail_user_login(p_new_login);

                setDialog_1_open_create_update_login_pwd_update(false);
                alert(res.data.message);
                setp_new_login('');
                setp_new_pwd('');
                setp_conf_new_login('');

            }
            else {
                setAdd_Update_Employee_Login_Pass_Data_api("false");
                setAdd_Update_Employee_Login_Pass_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_Employee_Login_Pass_Data = ' + error);
            setAdd_Update_Employee_Login_Pass_Data_api("false");
            alert(" Impossible de créer ou mettre à jour le compte l'employé");

        })
    }

    function Generate_Pwd() {
        var randomstring = Math.random().toString(36).slice(-8);
        setp_new_pwd(randomstring);
        setp_conf_new_login(randomstring);
    }

    const [currentpawdvisible, setcurrentpawdvisible] = useState(false);


    const handleClickShowPassword = () => {
        if (currentpawdvisible == false) {
            document.getElementsByName("new_pwd")[0].type = "text";
            document.getElementsByName("conf_new_login")[0].type = "text";
            setcurrentpawdvisible(true);
        }
        else if (currentpawdvisible == true) {
            document.getElementsByName("new_pwd")[0].type = "password";
            document.getElementsByName("conf_new_login")[0].type = "password";
            setcurrentpawdvisible(false);
        }
    };

    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();
    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;
        setLoading(true);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };


    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_2_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setsessions_file_change_2_result(result['message']);
                    setsessions_file_change_2_api("true");
                    //GetCurrentClass_trainingsession();

                }
                else {
                    setsessions_file_change_2_message(result['message']);
                    setsessions_file_change_2_api("false");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setsessions_file_change_2_api("false");
            });
    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();
    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "ressource_humaine");
            formData.append("object_owner_id", selected_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);


            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(selected_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();
    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(selected_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();
    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "ressource_humaine");
        formData.append("object_owner_id", local_employee_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes


    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
    
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
    
    */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values = []
    const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);


    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;



            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks(spec_field_updated_values)
    }
    // -- end champs specifiques 

    const [Dialog_employe_contrat_open, setDialog_employe_contrat_open] = React.useState(false);

    function func_Dialog_employe_contrat_open(message) {
        setDialog_employe_contrat_open(true);
    }

    const Dialog_employe_contrat_open_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_employe_contrat_handleClose_buton = () => {

        setp_employe_contrat_type_contrat("");
        setp_employe_contrat_type_employe("");
        setp_employe_contrat_cout("");
        setp_employe_contrat_comment("");
        setp_employe_contrat_periodicite("");
        setp_employe_contrat_groupe_prix_achat_id("");
        setp_employe_contrat_quantite("");
        setp_employe_contrat_date_debut(new Date().toLocaleDateString('fr-FR'));
        setp_employe_contrat_date_fin(date_today_90j.toLocaleDateString('fr-FR'));
        setselected_contrat_id("");

        setDialog_employe_contrat_open(false);
    };

    const Dialog_employe_contrat_handleClose = () => {
        setDialog_employe_contrat_open(false);
    };

    const [handleClick_delete_employee_contrat_api, sethandleClick_delete_employee_contrat_api] = useState();
    const [handleClick_delete_employee_contrat_message, sethandleClick_delete_employee_contrat_message] = useState();
    const [handleClick_delete_employee_contrat_result, sethandleClick_delete_employee_contrat_result] = useState();
    function handleClick_delete_employee_contrat(event, cellValues) {

        var local_employe_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_employe_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Employee_Contrat/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_employee_contrat  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_employee_contrat  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_employee_contrat_api("true");
                sethandleClick_delete_employee_contrat_result(res.data.message);
                Dialog_employe_contrat_handleClose_buton();
                Dialog_employe_contrat_handleClose_buton();
                Getall_Employee_Contrat(selected_id);
                Get_Partner_Purchase_Prices();
                setselectionModel_contrat([]);
                if (document.getElementById('myRef_contrat')) {
                    var divh = document.getElementById('myRef_contrat').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

                alert(res.data.message);

            }
            else {
                sethandleClick_delete_employee_contrat_api("false");
                sethandleClick_delete_employee_contrat_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_employee_contrat = ', error);
            sethandleClick_delete_employee_contrat_api("false");
            alert(" Impossible de supprimer le contrat");
            //setmyApimyApiMessage("")
        })
    }

    const [selected_contrat_id, setselected_contrat_id] = useState();

    const [p_employe_contrat_type_contrat, setp_employe_contrat_type_contrat] = useState("");
    const [p_employe_contrat_type_employe, setp_employe_contrat_type_employe] = useState("");
    const [p_employe_contrat_cout, setp_employe_contrat_cout] = useState("");
    const [p_employe_contrat_groupe_prix_achat_id, setp_employe_contrat_groupe_prix_achat_id] = useState("");
    const [p_employe_contrat_quantite, setp_employe_contrat_quantite] = useState("");
    const [p_employe_contrat_date_debut, setp_employe_contrat_date_debut] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_employe_contrat_date_fin, setp_employe_contrat_date_fin] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_employe_contrat_comment, setp_employe_contrat_comment] = useState("");
    const [p_employe_contrat_periodicite, setp_employe_contrat_periodicite] = useState("");

    const [Get_Partner_Purchase_Prices_api, setGet_Partner_Purchase_Prices_api] = useState();
    const [Get_Partner_Purchase_Prices_message, setGet_Partner_Purchase_Prices_message] = useState();
    const [Get_Partner_Purchase_Prices_result, setGet_Partner_Purchase_Prices_result] = useState([]);
    function Get_Partner_Purchase_Prices(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Group_Purchase_Price_List/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Purchase_Prices  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Purchase_Prices  res.data.message r_class = " + res.data.message);
                setGet_Partner_Purchase_Prices_api("true");
                setGet_Partner_Purchase_Prices_result(res.data.message);
            }
            else {
                setGet_Partner_Purchase_Prices_api("false");
                setGet_Partner_Purchase_Prices_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Purchase_Prices = ', error);
            setGet_Partner_Purchase_Prices_api("false");
            alert(" Impossible de recuperer la liste des prix d'achat ");
            //setmyApimyApiMessage("")
        })
    }

    const [isLoading, setLoading] = useState();

    const [dialog_contrat_data_changed, setdialog_contrat_data_changed] = useState();

    function handleClick_edit_employee_contrat_From_Line(row_id) {

        var line = JSON.parse(Getall_Employee_Contrat_result[row_id]);

        //console.log("### line = ", line);
        var contrat_id = line._id;
        setselected_contrat_id(contrat_id);

        setp_employe_contrat_type_contrat(line.type_contrat);
        setp_employe_contrat_type_employe(line.type_employe);
        setp_employe_contrat_cout(line.cout);

        setp_employe_contrat_comment(line.comment);

        setp_employe_contrat_periodicite(line.periodicite);


        setp_employe_contrat_groupe_prix_achat_id(line.groupe_prix_achat_id);

        setp_employe_contrat_quantite(line.qte);

        if (String(line.qte) !== "")
            settype_contrat_qte("1");

        setp_employe_contrat_date_debut(line.date_debut);
        setp_employe_contrat_date_fin(line.date_fin);

        Getall_Employee_Type_Contrat();
        setDialog_employe_contrat_open(true);

    }

    const [Add_Update_Employee_Contrat_api, setAdd_Update_Employee_Contrat_api] = useState();
    const [Add_Update_Employee_Contrat_message, setAdd_Update_Employee_Contrat_message] = useState();
    const [Add_Update_Employee_Contrat_result, setAdd_Update_Employee_Contrat_result] = useState();
    function Add_Update_Employee_Contrat(event) {

        var form = new FormData();

        if (!p_employe_contrat_type_contrat || String(p_employe_contrat_type_contrat).trim() === "") {
            alert(" Le type de contrat est obigatoire ");
            return;
        }

        if (!p_employe_contrat_type_employe || String(p_employe_contrat_type_employe).trim() === "") {
            alert(" Le type d'employé est obigatoire ");
            return;
        }



        if (!p_employe_contrat_date_debut || String(p_employe_contrat_date_debut).trim() === "") {
            alert(" Le début du contrat est obigatoire ");
            return;
        }


        if (!p_employe_contrat_groupe_prix_achat_id || String(p_employe_contrat_groupe_prix_achat_id).trim() === "") {
            if (!p_employe_contrat_cout || String(p_employe_contrat_cout).trim() === "") {
                alert(" Le prix est obigatoire ");
                return;
            }

            if (!p_employe_contrat_periodicite || String(p_employe_contrat_periodicite).trim() === "") {
                alert(" La périodicité  est obigatoire ");
                return;
            }

        }




        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        if (String(p_employe_contrat_comment).length > 255) {
            alert(" Le commentaire du contrat ne doit pas depasser 255 caractères")
        }

        var myurl = "";

        if (String(selected_contrat_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Employee_Contrat/";
            form.append("_id", selected_contrat_id);
            form.append("rh_id", selected_id);
            form.append("date_debut", p_employe_contrat_date_debut);
            form.append("date_fin", p_employe_contrat_date_fin);
            form.append("type_contrat", p_employe_contrat_type_contrat);
            form.append("type_employe", p_employe_contrat_type_employe);
            form.append("cout", p_employe_contrat_cout);
            form.append("periodicite", p_employe_contrat_periodicite);
            form.append("quantite", p_employe_contrat_quantite);
            form.append("groupe_prix_achat_id", p_employe_contrat_groupe_prix_achat_id);
            form.append("comment", p_employe_contrat_comment);



        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Employee_Contrat/";

            form.append("rh_id", selected_id);
            form.append("date_debut", p_employe_contrat_date_debut);
            form.append("date_fin", p_employe_contrat_date_fin);
            form.append("type_contrat", p_employe_contrat_type_contrat);
            form.append("type_employe", p_employe_contrat_type_employe);
            form.append("cout", p_employe_contrat_cout);
            form.append("periodicite", p_employe_contrat_periodicite);
            form.append("quantite", p_employe_contrat_quantite);
            form.append("groupe_prix_achat_id", p_employe_contrat_groupe_prix_achat_id);
            form.append("comment", p_employe_contrat_comment);
        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_Employee_Contrat  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_Employee_Contrat  res.data.message r_class = " + res.data.message);
                setAdd_Update_Employee_Contrat_api("true");
                setAdd_Update_Employee_Contrat_result(res.data.message);
                Dialog_employe_contrat_handleClose_buton();
                Getall_Employee_Contrat(selected_id);
                Get_Partner_Purchase_Prices();
                setselectionModel_contrat([]);
                if (document.getElementById('myRef_contrat')) {
                    var divh = document.getElementById('myRef_contrat').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

                alert(res.data.message);
            }
            else {
                setAdd_Update_Employee_Contrat_api("false");
                setAdd_Update_Employee_Contrat_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_Employee_Contrat = ', error);
            setAdd_Update_Employee_Contrat_api("false");
            alert(" Impossible d'ajouter / mettre à jour le contrat");
            //setmyApimyApiMessage("")
        })
    }

    const myRef_contrat = useRef(null)
    const [type_contrat_qte, settype_contrat_qte] = useState();


    const [selected_row_data_json_age, setselected_row_data_json_age] = useState("");
    const [Dialog_employe_message, setDialog_employe_message] = React.useState(false);
    const [Dialog_employe_open, setDialog_employe_open] = React.useState(false);
    function Dialog_employe_handle_change_participant_session(message) {
        setDialog_employe_message(message);
        setDialog_employe_open(true);
    }

    const Dialog_employe_handleClose = () => {

    };

    const Dialog_employe_handleClose_buton = () => {

        setuserimgclassprofil("");
        setselected_row_data_json_age("");

        setDialog_employe_open(false);
        setgridline_id("");

    };

    function Show_Detailled_employe_Data() {

        setselected_row_data_json_age("");
        setDialog_employe_open(false);
        handleClick_edit_employee_From_Line(gridline_id);
    }


    const [Create_Lms_Trainer_Account_api, setCreate_Lms_Trainer_Account_api] = useState();
    const [Create_Lms_Trainer_Account_message, setCreate_Lms_Trainer_Account_message] = useState();
    const [Create_Lms_Trainer_Account_result, setCreate_Lms_Trainer_Account_result] = useState();
    function Create_Lms_Trainer_Account() {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/HR_Create_LMS_Trainer_Account/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Create_Lms_Trainer_Account  res.data.status = " + res.data.status);
                //console.log(" In Create_Lms_Trainer_Account  res.data.message r_class = " + res.data.message);
                setCreate_Lms_Trainer_Account_api("true");
                setCreate_Lms_Trainer_Account_result(res.data.message);

                handleClick_edit_employee_From_Line(gridline_id);

                alert(res.data.message);
            }
            else {
                setCreate_Lms_Trainer_Account_api("false");
                setCreate_Lms_Trainer_Account_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Create_Lms_Trainer_Account = ', error);
            setCreate_Lms_Trainer_Account_api("false");
            alert(" Impossible de créer les acces LMS pour  Enseignant");
            //setmyApimyApiMessage("")
        })
    }


    const [Locked_MySy_Trainer_Account_api, setLocked_MySy_Trainer_Account_api] = useState();
    const [Locked_MySy_Trainer_Account_message, setLocked_MySy_Trainer_Account_message] = useState();
    const [Locked_MySy_Trainer_Account_result, setLocked_MySy_Trainer_Account_result] = useState();
    function Locked_MySy_Trainer_Account() {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Lock_partner_account_From_Rh_Id/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Locked_MySy_Trainer_Account  res.data.status = " + res.data.status);
                //console.log(" In Locked_MySy_Trainer_Account  res.data.message r_class = " + res.data.message);
                setLocked_MySy_Trainer_Account_api("true");
                setLocked_MySy_Trainer_Account_result(res.data.message);
                handleClick_edit_employee_From_Line(gridline_id);
                alert(res.data.message);
            }
            else {
                setLocked_MySy_Trainer_Account_api("false");
                setLocked_MySy_Trainer_Account_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Locked_MySy_Trainer_Account = ', error);
            setLocked_MySy_Trainer_Account_api("false");
            alert(" Impossible de désactiver le compte utilisateur de l'employé");
            //setmyApimyApiMessage("")
        })
    }

    const [UnLocked_MySy_Trainer_Account_api, setUnLocked_MySy_Trainer_Account_api] = useState();
    const [UnLocked_MySy_Trainer_Account_message, setUnLocked_MySy_Trainer_Account_message] = useState();
    const [UnLocked_MySy_Trainer_Account_result, setUnLocked_MySy_Trainer_Account_result] = useState();
    function UnLocked_MySy_Trainer_Account() {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Unlock_partner_account_From_Rh_Id/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UnLocked_MySy_Trainer_Account  res.data.status = " + res.data.status);
                //console.log(" In UnLocked_MySy_Trainer_Account  res.data.message r_class = " + res.data.message);
                setUnLocked_MySy_Trainer_Account_api("true");
                setUnLocked_MySy_Trainer_Account_result(res.data.message);
                handleClick_edit_employee_From_Line(gridline_id);
                alert(res.data.message);
            }
            else {
                setUnLocked_MySy_Trainer_Account_api("false");
                setUnLocked_MySy_Trainer_Account_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( UnLocked_MySy_Trainer_Account = ', error);
            setUnLocked_MySy_Trainer_Account_api("false");
            alert(" Impossible de réactiver le compte utilisateur de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    const [Disable_Lms_Trainer_Account_api, setDisable_Lms_Trainer_Account_api] = useState();
    const [Disable_Lms_Trainer_Account_message, setDisable_Lms_Trainer_Account_message] = useState();
    const [Disable_Lms_Trainer_Account_result, setDisable_Lms_Trainer_Account_result] = useState();
    function Disable_Lms_Trainer_Account() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rh_id_to_disable", selected_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Disable_MySy_LMS_Account/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {

                //console.log(" In Disable_Lms_Trainer_Account  res.data.status = " + res.data.status);
                //console.log(" In Disable_Lms_Trainer_Account  res.data.message r_class = " + res.data.message);
                setDisable_Lms_Trainer_Account_api("true");
                setDisable_Lms_Trainer_Account_result(res.data.message);

                handleClick_edit_employee_From_Line(gridline_id);

                alert(res.data.message);
            }
            else {
                setDisable_Lms_Trainer_Account_api("false");
                setDisable_Lms_Trainer_Account_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Disable_Lms_Trainer_Account = ', error);
            setDisable_Lms_Trainer_Account_api("false");
            alert(" Impossible de créer les acces LMS pour  Enseignant");
            //setmyApimyApiMessage("")
        })
    }


    return (
        <div className="employes">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Dialog
                open={Dialog_employe_open}
                onClose={Dialog_employe_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>Employé</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <div>

                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                            <div className="div_row_gauche_image" style={{ "float": "right" }} >
                                <img class="img_class_logo_mini" src={userimgclassprofil} /><br />

                            </div>

                        </div>


                    </div>
                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Civilité
                        </div>
                        {String(gridline_id) !== "" && Getall_Training_Employee_No_Filter_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).civilite}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Nom & Prénom
                        </div>
                        {String(gridline_id) !== "" && Getall_Training_Employee_No_Filter_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).nom.toUpperCase()}   {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).prenom.charAt(0).toUpperCase() + JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).prenom.slice(1)}


                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            E-mail
                        </div>
                        {String(gridline_id) !== "" && Getall_Training_Employee_No_Filter_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).email}
                            </div>}
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Téléphone
                        </div>
                        {String(gridline_id) !== "" && Getall_Training_Employee_No_Filter_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).telephone}
                            </div>}
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Age
                        </div>
                        {String(gridline_id) !== "" && Getall_Training_Employee_No_Filter_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {selected_row_data_json_age} ans - {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).date_naissance}
                            </div>}

                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Adresse postale
                        </div>
                        {String(gridline_id) !== "" && Getall_Training_Employee_No_Filter_result && selected_id &&
                            String(selected_id).length > 0 && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).adr_adresse} <br />
                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).adr_code_postal} {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).adr_ville} <br />
                                {JSON.parse(Getall_Training_Employee_No_Filter_result[gridline_id]).adr_pays.toUpperCase()}
                            </div>}
                    </div>

                    <div className="div_row" > &nbsp;

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Show_Detailled_employe_Data} className="bton_enreg_dialog">Voir detail</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_employe_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>


            <Dialog
                open={Dialog_1_open_profil_access_right}
                onClose={Dialog_1_handleClose_buton_profil_access_right}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message_profil_access_right}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handle_OK_buton_profil_access_right}>OK</Button>
                    <Button onClick={Dialog_1_handleCancel_buton_profil_access_right}>Annuler</Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={Dialog_1_open_create_update_login_pwd}
                onClose={Dialog_1_handleClose_buton_create_update_login_pwd}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message_create_update_login_pwd}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handle_OK_buton_create_update_login_pwd}>OK</Button>
                    <Button onClick={Dialog_1_handleCancel_buton_create_update_login_pwd}>Annuler</Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={Dialog_1_open_create_update_login_pwd_update}
                onClose={Dialog_1_handleClose_buton_create_update_login_pwd_update}
                className="employes"


            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent >

                    <DialogContentText>
                        Mise à jour Login & Mot de passe
                    </DialogContentText>



                    {String(p_detail_user_login).length > 2 && <div className="session_caract_Dialog" > Login
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new_login"
                            disabled
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_new_login}

                        />
                    </div>}
                    {String(p_detail_user_login).length < 2 && <div className="session_caract_Dialog" > Login
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new_login"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_new_login}
                            onChange={(e) => {
                                setp_new_login(e.target.value);

                            }
                            }
                        />
                    </div>}

                    <div className="session_caract_Dialog" > Mot de passe
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new_pwd"
                            name="new_pwd"
                            type="password"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_new_pwd}
                            onChange={(e) => {
                                setp_new_pwd(e.target.value);

                            }
                            }
                        />
                        <nav className="show_pwd_div" onClick={handleClickShowPassword}>
                            {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                        </nav>

                    </div>
                    <div className="session_caract_Dialog" > Confirmer Mot de passe
                        <TextField
                            autoFocus
                            margin="dense"
                            id="conf_new_login"
                            name="conf_new_login"
                            type="password"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_conf_new_login}
                            onChange={(e) => {
                                setp_conf_new_login(e.target.value);

                            }
                            }
                        />
                        <Button onClick={Generate_Pwd} className="bton_enreg_dialog" style={{ "width": "100%", "margin": "0px", "background": "orange" }}>Generer automatiquement un mot de passe &nbsp; <LiaDharmachakraSolid style={{ "fontSize": "medium" }} /> </Button>
                    </div>




                </DialogContent>

                <DialogActions>


                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="L'utilisateur sera notifié par email. Il recevra le login et le nouveau mot de passe.">

                                <Button onClick={Add_Update_Employee_Login_Pass_Data} className="bton_enreg_dialog">Mettre à jour <FcInfo /></Button>
                            </a>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_1_handleCancel_buton_create_update_login_pwd_update} className="bton_annule_dialog" >Annuler</Button>
                        </div>

                    </div>



                </DialogActions>
            </Dialog>

            <Dialog
                open={Dialog_employe_contrat_open}
                onClose={Dialog_employe_contrat_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Contrat employé </DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="session_caract_Dialog" > <b>Type de contrat </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_code_groupe_prix"
                            id="event_dialog_code_groupe_prix"

                            fullWidth
                            select

                            value={p_employe_contrat_type_contrat}
                            onChange={(e) => {
                                setp_employe_contrat_type_contrat(e.target.value);
                                setdialog_contrat_data_changed("1");
                                var result2 = Getall_Employee_Type_Contrat_result.filter((val) => JSON.parse(val).code === String(e.target.value));
                                settype_contrat_qte(JSON.parse(result2[0]).quantitatif);

                            }
                            }
                        >
                            {Getall_Employee_Type_Contrat_result &&
                                Getall_Employee_Type_Contrat_result.map((step) => (
                                    <MenuItem key={JSON.parse(step).code} value={JSON.parse(step).code}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "height": "2rem", "width": '100%' }}>
                                        {JSON.parse(step).description}&nbsp;

                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>


                    <div className="session_caract_Dialog" > <b>Type d'employé</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_type_employer"
                            id="event_dialog_type_employer"

                            fullWidth
                            select

                            value={p_employe_contrat_type_employe}
                            onChange={(e) => {
                                setp_employe_contrat_type_employe(e.target.value);
                                setdialog_contrat_data_changed("1");
                            }
                            }
                        >
                            <MenuItem value="cadre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Cadre &nbsp;</MenuItem>
                            <MenuItem value="employe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Employé &nbsp;</MenuItem>
                            <MenuItem value="stagiaire" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Stagiaire &nbsp;</MenuItem>
                            <MenuItem value="independant" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Independant &nbsp;</MenuItem>
                            <MenuItem value="contractuel" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Contractuel &nbsp;</MenuItem>
                            <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Autre &nbsp;</MenuItem>


                        </TextField>
                    </div>


                    <div className="session_caract_Dialog" > <b>Debut </b>
                        <DatePicker
                            name="event_dialog_date_debut"
                            id="event_dialog_date_debut"

                            selected={new Date(moment(p_employe_contrat_date_debut, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_employe_contrat_date_debut(format(date, 'd/MM/yyyy'));
                                setdialog_contrat_data_changed("1");

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'

                        />

                    </div>


                    <div className="session_caract_Dialog" > Fin
                        <DatePicker
                            name="event_dialog_date_fin"
                            id="event_dialog_date_fin"
                            selected={new Date(moment(p_employe_contrat_date_fin, "DD/MM/YYYY"))}
                            onChange={(date) => {

                                setp_employe_contrat_date_fin(format(date, 'd/MM/yyyy'));
                                setdialog_contrat_data_changed("1");

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                        />

                    </div>

                    <Tooltip className="tooltip_css" id="tooltip_id_1" />
                    <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Si vous souhaitez lier le tarif à un groupe de prix déjà existant ">
                        <div className="session_caract_Dialog" > Groupe de Prix d'Achat
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_grp_fournisseur"
                                id="event_dialog_grp_fournisseur"

                                fullWidth
                                select

                                value={p_employe_contrat_groupe_prix_achat_id}
                                onChange={(e) => {
                                    setp_employe_contrat_groupe_prix_achat_id(e.target.value);
                                    setdialog_contrat_data_changed("1");
                                }
                                }
                            >
                                <MenuItem key="" value=""
                                    style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                    &nbsp;
                                </MenuItem>

                                {Get_Partner_Purchase_Prices_result &&
                                    Get_Partner_Purchase_Prices_result.map((myclient) => (
                                        <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                            style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>
                                            {JSON.parse(myclient).code_groupe_prix}&nbsp;

                                        </MenuItem>

                                    ))}


                            </TextField>
                        </div>
                    </a>



                    {String(type_contrat_qte) === "1" && <div className="session_caract_Dialog" > Quantité
                        <Tooltip className="tooltip_css" id="tooltip_id_2" />
                        <a data-tooltip-id="tooltip_id_2" data-tooltip-html=" Il s'agit d'un contrat en quantités comme : Le nombre le copies à corriger ">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_qte"
                                id="event_dialog_qte"
                                type="number"
                                step="any"
                                fullWidth

                                value={p_employe_contrat_quantite}
                                onChange={(e) => {
                                    setp_employe_contrat_quantite(e.target.value);
                                    setdialog_contrat_data_changed("1");
                                }
                                }
                            />
                        </a>
                    </div>
                    }


                    {String(p_employe_contrat_groupe_prix_achat_id) === "" && <div className="session_caract_Dialog" > <b> Taux Horaire Brut (HT) </b>
                        <Tooltip className="tooltip_css" id="tooltip_id_3" />
                        <a data-tooltip-id="tooltip_id_3" data-tooltip-html=" Il s'agit du montant de rémunération (HT) du contrat par heure ">

                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_price"
                                id="event_dialog_qte"
                                type="number"
                                step="any"
                                fullWidth

                                value={p_employe_contrat_cout}
                                onChange={(e) => {
                                    setp_employe_contrat_cout(e.target.value);
                                    setdialog_contrat_data_changed("1");
                                }
                                }
                            />
                        </a>
                    </div>
                    }

                    {String(p_employe_contrat_groupe_prix_achat_id) === "" && <div className="session_caract_Dialog" > <b> Périodicité </b>
                        <Tooltip className="tooltip_css" id="tooltip_id_perodicite" />
                        <a data-tooltip-id="tooltip_id_perodicite" data-tooltip-html=" A quel péridicité est appliqué le montant HT <br/> Exemple : Ce montant est appliqué : <br/>
                        <li> Par heure ?</li>
                        <li> En fixe, quelque soit le nombre d'heure (ex : la correction des copies) </li>
                       
                         ">

                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_periodicite"
                                id="event_dialog_periodicite"

                                select
                                fullWidth

                                value={p_employe_contrat_periodicite}
                                onChange={(e) => {
                                    setp_employe_contrat_periodicite(e.target.value);
                                    setdialog_contrat_data_changed("1");
                                }
                                }
                            >
                                <MenuItem value="fixe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Fixe &nbsp;</MenuItem>
                                <MenuItem value="heure" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par heure &nbsp;</MenuItem>
                                {/*<MenuItem value="jour" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par jour &nbsp;</MenuItem>
                                <MenuItem value="mois" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par mois &nbsp;</MenuItem>
                                <MenuItem value="annee" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par année &nbsp;</MenuItem>*/}
                            </TextField>
                        </a>
                    </div>
                    }

                    <div className="session_caract_Dialog" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_price"
                            id="event_dialog_qte"
                            type="text"


                            fullWidth

                            value={p_employe_contrat_comment}
                            onChange={(e) => {
                                if (String(e.target.value).length <= 255) {
                                    setp_employe_contrat_comment(e.target.value);
                                    setdialog_contrat_data_changed("1");
                                }
                            }
                            }
                        />
                    </div>


                    {String(dialog_contrat_data_changed) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center", "width": "100%" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}



                </DialogContent>


                <DialogActions>


                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_contrat_id).trim().length > 2 && <Button onClick={Add_Update_Employee_Contrat} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_contrat_id).trim() === "" && <Button onClick={Add_Update_Employee_Contrat} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_employe_contrat_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            <h3> Vos employés </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                            <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>
                            <MenuItem value="session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Session (code session) &nbsp;</MenuItem>


                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value);

                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>
                                <MenuItem value="session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Session (code session) &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre3"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre3}
                                onChange={(e) => {
                                    setp_filtre3(e.target.value)
                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>
                                <MenuItem value="session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Session (code session) &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3();
                                    setp_filtre3_value();
                                }} />
                            </a>
                        </div>}
                    </div>
                }

                {/*
                {p_filtre4 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre4"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre4}
                                onChange={(e) => {
                                    setp_filtre4(e.target.value)
                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre4).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre4_value"
                                    id="filtre4_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre4_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre4_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>



                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4();
                                        setp_filtre4_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }
                */}
                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Training_Employee}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>



            <div className="div_row" style={{ paddingRight: '10px' }}>
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },


                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },


                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_employee_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            setsubmenu();
                                            setdisplay_detail_employe();
                                            setadd_One_Employee();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            email: JSON.parse(item).email,
                                            nom: JSON.parse(item).nom,
                                            prenom: JSON.parse(item).prenom,
                                            telephone_mobile: JSON.parse(item).telephone_mobile,
                                            civilite: JSON.parse(item).civilite,
                                            profil: JSON.parse(item).profil,
                                            fonction: JSON.parse(item).fonction,
                                            ismanager: JSON.parse(item).ismanager,
                                            adr_adresse: JSON.parse(item).adr_adresse,
                                            adr_code_postal: JSON.parse(item).adr_code_postal,
                                            adr_ville: JSON.parse(item).adr_ville,
                                            adr_pays: JSON.parse(item).adr_pays,
                                            date_naissance: JSON.parse(item).date_naissance,


                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        setselected_id(newSelectionModel.row._id);
                                        setuserimgclassprofil("");
                                        setselected_row_data_json_age("");
                                        get_Employee_Images(newSelectionModel.row._id);
                                        setgridline_id(newSelectionModel.row.id);

                                        if (newSelectionModel.row.date_naissance) {
                                            var local_date_naissance = newSelectionModel.row.date_naissance;
                                            var date_eval = new Date(moment(local_date_naissance, "DD/MM/YYYY"));
                                            var diff = Date.now() - date_eval.getTime();
                                            var age = new Date(diff);
                                            var reel_age = Math.abs(age.getUTCFullYear() - 1970);
                                            setselected_row_data_json_age(reel_age)
                                        }
                                        setDialog_employe_open(true);


                                        //handleClick_edit_employee_From_Line(newSelectionModel.row.id);

                                    }}

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'


                                        //console.log(" ### params.field = ", params.field)



                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "statut"
                                        if (params.field === "status" && String(params.value) == "0") {
                                            return 'cell--statut--preinscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "1") {
                                            return 'cell--statut--inscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "-1") {
                                            return 'cell--statut--annule';
                                        }

                                    }}
                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}



                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        <Button variant="outlined" onClick={submenu_import_employee} className="detail_class_submenu bton_import_excel"
                            id='menu_import_participant' name='menu_import_participant'>Importer des employes Excel &nbsp;
                            <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                        </Button>


                        <Button variant="outlined" onClick={submenu_add_one_employee}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 employé &nbsp;
                            <img src={add_plus} alt="ajout session" className="icon_plus" />
                        </Button>

                        <br />
                        {session_file_name && <nav><i>{session_file_name}</i></nav>}
                        {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les employés ont été correctement importés</nav>}
                        {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                        <input type="file"
                            accept=".csv"
                            ref={hiddenFileInput_session}
                            style={{ display: 'none' }}
                            name="liste_sessions_file"
                            onChange={liste_sessions_file_change}
                        />

                        <a href='/sample/template_import_employes.csv' download>Télécharger un fichier modèle</a>
                    </div>
                </div>
                <div className="div_row"> &nbsp;</div>


            </div>

            <div className="div_row" ref={myRef} id="myRef"> &nbsp; </div>

            {String(submenu) === "detail_employe" && String(add_One_Employee) !== "1" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_employe} className="detail_class_submenu" id='detail_employe' name='detail_employe'>Détail employé</Button>
                    <Button variant="outlined" onClick={submenu_droit_acces} className="detail_class_submenu" id='droit_acces' name='droit_acces'>Droits d'accès  </Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_contrat} className="detail_class_submenu" id='affectation' name='contrat'>Contrat  </Button>
                </div>

                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail Employé </nav>
                <div className="div_row" style={{ "border": "None" }}>
                    <div className="div_row_gauche">

                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                            <div className="div_row_gauche_image">
                                <img class="img_class_logo" src={userimgclassprofil} /><br />

                            </div>
                            <div className="div_row_droite_image">
                                <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                {<nav>

                                    <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                    {String(userimgclassprofilchanged) === "1" &&
                                        <nav className="bton_image_class" onClick={record_employee_Image}>Enregistrer la photo<br />  </nav>
                                    }

                                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                        <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                    }
                                    {String(userimgclassprofilchanged) === "1" &&
                                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                        </div>}


                                </nav>}

                                {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                            </div>

                        </div>
                        <div className="div_row_bis tips_img_class">
                            <li>
                                L'image doit etre carrée.
                            </li>
                            <li>
                                Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                            </li>
                            <li>
                                Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                Les dimensions maximales sont de 256pixels X 256pixels
                            </li>
                            <li>
                                La taille de l'image ne doit pas dépasser 1 mega octet
                            </li>
                        </div>

                    </div>
                </div>

                <div className="session_data" >

                    {String(employee_data_edit_mode) !== "1" && <div className="session_caract"> <b>Civilité </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_civilite"
                            id="detail_civilite"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_civilite}

                        />
                    </div>}
                    {String(employee_data_edit_mode) === "1" && <div className="session_caract"> <b>Civilité </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_civilite"
                            id="detail_civilite"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_civilite}
                            onChange={(e) => {
                                setp_detail_civilite(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            <MenuItem value="M" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>M. &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="Mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                            <MenuItem value="Neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Neutre &nbsp;</MenuItem>

                        </TextField>
                    </div>}

                    <div className="session_caract"> <b> Nom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_nom"
                            id="detail_nom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_nom}
                            onChange={(e) => {
                                setp_detail_nom(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b>Prénom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_prenom"
                            id="detail_prenom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_prenom}
                            onChange={(e) => {
                                setp_detail_prenom(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>



                    <div className="session_caract"> <b> Email </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_mail"
                            id="detail_mail"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_mail}
                            onChange={(e) => {
                                setp_detail_mail(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }

                        />
                    </div>


                    <div className="session_caract"> <b> Date Naissance </b><br />
                        <DatePicker
                            name="detail_naissance"
                            id="detail_naissance"
                            selected={new Date(moment(p_detail_naissance, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_detail_naissance(format(date, 'd/MM/yyyy'));
                                setEmployee_data_changed("1");
                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'

                        />


                    </div>



                    <div className="session_caract"> Téléphone Mobile<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_tel_mobile"
                            id="detail_tel_mobile"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_tel_mobile}
                            onChange={(e) => {
                                setp_detail_tel_mobile(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> Téléphone <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_tel"
                            id="detail_tel"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_tel}
                            onChange={(e) => {
                                setp_detail_tel(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> Adresse<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_adresse"
                            id="detail_adresse"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_adresse}
                            onChange={(e) => {
                                setp_detail_adresse(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>
                    <div className="session_caract"> Code postal<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_code_postal"
                            id="detail_code_postal"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_code_postal}
                            onChange={(e) => {
                                setp_detail_code_postal(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>
                    <div className="session_caract"> Ville<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_ville"
                            id="detail_ville"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_ville}
                            onChange={(e) => {
                                setp_detail_ville(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Pays<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_pays"
                            id="detail_pays"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_pays}
                            onChange={(e) => {
                                setp_detail_pays(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Linkedin<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_linkedin"
                            id="detail_linkedin"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_linkedIn}
                            onChange={(e) => {
                                setp_detail_linkedIn(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Facebook<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_facebook"
                            id="detail_facebook"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_facebook}
                            onChange={(e) => {
                                setp_detail_facebook(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Twitter<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_twitter"
                            id="detail_twitter"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_twitter}
                            onChange={(e) => {
                                setp_detail_twitter(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    {String(employee_data_edit_mode) !== "1" && <div className="session_caract"> Profil<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_profil"
                            id="detail_profil"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_profil}

                        />
                    </div>}

                    {String(employee_data_edit_mode) === "1" && <div className="session_caract"> Profil<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_profil"
                            id="detail_profil"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_profil}
                            onChange={(e) => {
                                setp_detail_profil(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            {Get_List_RH_Profils_result &&
                                Get_List_RH_Profils_result.map((myprofil) => (
                                    <MenuItem key={JSON.parse(myprofil).profil_nom} value={JSON.parse(myprofil).profil_nom}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(myprofil).profil_nom}&nbsp;&nbsp;

                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>}

                    <div className="session_caract"> Fonction <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_fonction"
                            id="detail_fonction"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_fonction}
                            onChange={(e) => {
                                setp_detail_fonction(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>


                    {String(employee_data_edit_mode) !== "1" && <div className="session_caract"> Manager<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_ismanager"
                            id="detail_ismanager"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_ismanager_label}

                        />
                    </div>}

                    {String(employee_data_edit_mode) === "1" && <div className="session_caract"> Manager<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_ismanager"
                            id="detail_ismanager"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_ismanager}
                            onChange={(e) => {
                                setp_detail_ismanager(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>

                        </TextField>
                    </div>}

                    {String(employee_data_edit_mode) !== "1" && <div className="session_caract"> Responsable (n+1) <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_resp_hierarchie_id"
                            id="detail_resp_hierarchie_id"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_resp_hierarchie_nom + " " + p_detail_resp_hierarchie_prenom}

                        />
                    </div>}
                    {String(employee_data_edit_mode) === "1" && <div className="session_caract"> Responsable (n+1) <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_resp_hierarchie_id"
                            id="detail_resp_hierarchie_id"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_resp_hierarchie_id}
                            onChange={(e) => {
                                setp_detail_resp_hierarchie_id(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            {Get_List_Managers_result &&
                                Get_List_Managers_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id !== selected_id).map((mymanager) => (
                                    <MenuItem key={JSON.parse(mymanager)._id} value={JSON.parse(mymanager)._id}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(mymanager).nom}&nbsp;&nbsp;{JSON.parse(mymanager).prenom}

                                    </MenuItem>
                                ))}

                        </TextField>
                    </div>}

                    {/* -- début champs specifiques **/}
                    <div className="div_row">
                        <hr />
                    </div>
                    <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques<br />

                        {rows_champs_specifics &&
                            rows_champs_specifics.map((champ_spec) => (

                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                    <br />
                                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}
                                        type="number"
                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}


                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            setEmployee_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />}

                                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}


                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            setEmployee_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />}

                                </div>
                            ))}

                        <br />

                    </div>
                    {/* -- end  champs specifiques **/}

                    <div className="div_row">
                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                        </div>

                    </div>
                    <div style={{ "width": "45%", "float": "left", "borderRight": "thick double #32a1ce" }}>
                        <div className="div_row" style={{ "border": "None", "fontSize": "20px", "fontWeight": "600", "padding": "5px" }}>
                            Compte utilisateur
                        </div>
                        {String(p_detail_user_login) === "" &&
                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                <Tooltip className="tooltip_css" id="deb_inscription" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="deb_inscription" data-tooltip-html="Pour créer un login & pwd pour cet utilisateur, cliquez sur 'Editer.<br/> 
                            Pensez également lui accorder les niveaux d'accès  nécessaires à partir du bouton  <i>'DROITS D'ACCES'</i> ">

                                    /!\  Cet utilisateur n'a pas de login & mot de passe &nbsp; <FcInfo />
                                </a>
                            </div>}
                        <div style={{ "paddingLeft": "10px" }}>
                            <Tooltip className="tooltip_css" id="deb_inscription" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="deb_inscription" data-tooltip-html=" Après avoir créé le compte utilisateur, pensez également lui accorder les niveaux d'accès  nécessaires à partir du bouton  <i>'DROITS D'ACCES'</i> ">
                                Login &nbsp; <FcInfo /><br />
                            </a>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, background: "#ECEFF1", color: "black" }}

                                required
                                name="detail_user_login"
                                id="detail_user_login"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_user_login}
                            />
                        </div>



                        { String(p_detail_user_login) === "" && <div style={{ "paddingLeft": "10px", "width": "30%", "float": "left" }}>
                            <Button variant="contained" className="bton_action1" onClick={Dialog_1_handle_change_login_pwd}> Créer login & pwd
                            </Button>
                        </div>
                        }

                        { String(p_detail_user_login) !== "" && <div style={{ "paddingLeft": "10px", "width": "30%", "float": "left" }}>
                            <Button variant="contained" className="bton_action1" onClick={Dialog_1_handle_change_login_pwd}> M.A.J PWD &nbsp;
                            </Button>
                        </div>
                        }

                        { String(p_detail_user_login) !== "" && String(p_detail_user_login_locked) !== "1"
                            && <div style={{ "paddingLeft": "10px", "width": "30%", "float": "left" }}>
                                <Popup
                                    trigger={
                                        <Button variant="contained" className="bton_action1" style={{ "background": "gray", "color": "black" }} > Désactiver
                                        </Button>


                                    }
                                    modal
                                    nested
                                    position="center center"
                                >
                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}

                                                En confirmant cette opération,<i><font color="red"> l'utilisateur ne pourra plus se connecter au système </font></i>. <br />


                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {

                                                        Locked_MySy_Trainer_Account(event);
                                                        close();
                                                    }}

                                                    > Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        }

                        { String(p_detail_user_login) !== "" && String(p_detail_user_login_locked) === "1"
                            && <div style={{ "paddingLeft": "10px", "width": "30%", "float": "left" }}>
                                <Popup
                                    trigger={
                                        <Button variant="contained" className="bton_action1" > Réactiver
                                        </Button>


                                    }
                                    modal
                                    nested
                                    position="center center"
                                >
                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}

                                                En confirmant cette opération,<i><font color="red"> le compte utilisateur sera réactivé </font></i>. <br />


                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {

                                                        UnLocked_MySy_Trainer_Account(event);
                                                        close();
                                                    }}

                                                    > Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>
                        }
                    </div>

                    <div style={{ "width": "45%", "float": "right" }}>

                        <div className="div_row" style={{ "border": "None", "fontSize": "20px", "fontWeight": "600", "padding": "5px" }}>
                            Accès E-Learning
                        </div>
                        <div style={{ "paddingLeft": "10px" }}>
                            {String(p_detail_user_login) === "" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                &nbsp;
                            </div>}
                            <Tooltip className="tooltip_css" id="deb_inscription_lms" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="deb_inscription_lms" data-tooltip-html="Pour créer un accès E-Learning, cliquez sur 'Editer.<br/> 
                            L'utilisateur doit avoir un compte utilisateur valide. Assuez-vous qu'il a bien un login et un mot de passe. ">
                            Login E-Learning <FcInfo /> <br />
                            </a>

                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: "100%" }}
                                required
                                name="detail_lms_login"
                                id="detail_lms_login"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_lms_login}
                            />

                        </div>
                      

                        {String(p_detail_mysy_lms_user_id) === ""
                            && String(p_detail_user_login) !== "" && String(p_detail_user_login_locked) !== "1" &&

                            <div style={{ "paddingLeft": "10px", "width": "30%", "float": "left" }}>
                                <Popup
                                    trigger={
                                        <Button variant="contained" className="bton_action1"> Créer accès &nbsp;
                                        </Button>

                                    }
                                    modal
                                    nested
                                    position="center center"
                                >
                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}

                                                En confirmant cette opération,<i><font color="blue"> un compte utilisateur va être créé sur la plateforme E-Learning pour cet utilisateur  </font></i>. <br />


                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {

                                                        Create_Lms_Trainer_Account(event);
                                                        close();
                                                    }}

                                                    > Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>}



                        {String(p_detail_lms_account_disable) !== "1" &&
                            String(p_detail_mysy_lms_user_id) !== "" &&
                            String(p_detail_user_login) !== "" &&
                            <div style={{ "paddingLeft": "10px", "width": "30%", "float": "left" }}>
                                <Popup
                                    trigger={
                                        <Button variant="contained" className="bton_action1" style={{ "background": "gray", "color": "black" }}> Désactiver &nbsp;
                                        </Button>

                                    }
                                    modal
                                    nested
                                    position="center center"
                                >
                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}

                                                En confirmant cette opération,<i><font color="red"> le compte utilisateur sur la platforme E-Learning sera désactivé pour cet employé  </font></i>. <br />


                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {

                                                        Disable_Lms_Trainer_Account(event);
                                                        close();
                                                    }}

                                                    > Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            </div>}
                    </div>

                    <div className="div_row">
                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                        </div>
                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                            Pièces jointes
                        </div>
                    </div>
                    <div className="div_row">
                        <div className="div_row" style={{ "padding": "5px" }}>

                            <div style={{ "fontSize": "12px" }}>
                                <label htmlFor="upload-photo">
                                    <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={sessions_file_change_1}
                                    />

                                    <Fab
                                        color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"
                                    >
                                        <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                    </Fab>

                                </label>
                            </div>
                            {file_1_name && file_1_name.name &&
                                <nav style={{ "fontSize": "12px" }}>
                                    <div className="session_caract">Nom du fichier <br />
                                        <TextField
                                            name="file_to_download_type_name"
                                            id="file_to_download_type_name"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_detail_one_file_to_download_type_name}
                                            onChange={(e) => {
                                                setp_detail_one_file_to_download_type_name(e.target.value);

                                            }}
                                        />

                                    </div>
                                    <br />
                                    <br />
                                    Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                        onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                                </nav>}

                            {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                            {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                        </div>



                        {file_1_name && file_1_name.name && <div className="div_row">

                            <div className="div_row_gauche">
                                <Button variant="contained" onClick={Record_All_PJ}
                                    startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                            </div>


                        </div>}

                        {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                            <div className="div_row">
                                Liste des pièces jointes <br />
                                <div className="div_row">
                                    {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                        <div className="div_row_list_pj"  >
                                            <nav style={{ "color": "green", "cursor": "pointer" }} onClick={Download_one_attached_document} name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}> Télécharger &nbsp;<i> {(JSON.parse(val).file_business_object)}</i> </nav> <br />
                                            <Popup
                                                trigger={
                                                    <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                                }
                                                modal
                                                nested
                                                position="center center"
                                            >
                                                {close => (
                                                    <div>
                                                        <button className="gest_close" onClick={close}>
                                                            &times;
                                                        </button>
                                                        <div className="gest_header"> MySy Information </div>
                                                        <div className="gest_content">
                                                            {' '}

                                                            En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                        </div>
                                                        <div className="gest_actions">
                                                            <div style={{ "width": "45%", "float": "left" }}>
                                                                <button className="gest_bton_popup" onClick={(event) => {

                                                                    Delete_one_attached_document(event);
                                                                    close();
                                                                }}
                                                                    name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                                > Valider </button>

                                                            </div>
                                                            <div style={{ "width": "45%", "float": "right" }}>
                                                                <button
                                                                    className="gest_bton_popup"
                                                                    onClick={() => {
                                                                        //console.log('modal closed ');
                                                                        close();
                                                                    }}
                                                                >
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(Employee_data_changed) === "1" && String(employee_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}


                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(Employee_data_changed) === "1" && String(employee_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Update_One_Employee_Data}>Enregistrer les modifications
                            </Button>

                        </div>}

                        {String(employee_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_Employee_DetailFields}>Annuler
                            </Button>
                        </div>}
                    </div>


                    {String(employee_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                        <Button variant="contained" className="bton_edit" onClick={Enable_Employee_DetailFields}>Editer
                        </Button>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>
            </div>}


            <nav id="myRef_new_employee" ref={myRef_new_employee}> &nbsp;</nav>
            {String(submenu) === "detail_employe" && String(add_One_Employee) === "1" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_employe} className="detail_class_submenu" id='detail_employe' name='detail_employe'>Détail employé</Button>
                    <Button variant="outlined" onClick={submenu_droit_acces} className="detail_class_submenu" id='droit_acces' name='droit_acces'>Droits d'accès  </Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_contrat} className="detail_class_submenu" id='affectation' name='contrat'>Contrat  </Button>
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Ajouter un  Employé </nav>

                <div className="session_data" >

                    <div className="session_caract"> <b> Civilité </b> <br />
                        <Autocomplete
                            disablePortal
                            name="one_detail_civilite"
                            id="one_detail_civilite"
                            className="disabled_style"
                            options={New_Option_Civilite}

                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_one_detail_civilite(value.value);
                                    setEmployee_data_changed("1");

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />

                        {/* <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_civilite"
                            id="one_detail_civilite"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_civilite}
                            onChange={(e) => {
                                setp_one_detail_civilite(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            <MenuItem value="M" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>M. &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="Mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                            <MenuItem value="Neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Neutre &nbsp;</MenuItem>

                        </TextField>*/}
                    </div>

                    <div className="session_caract"> <b>Nom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_nom"
                            id="one_detail_nom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_nom}
                            onChange={(e) => {
                                setp_one_detail_nom(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b>Prénom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_prenom"
                            id="one_detail_prenom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_prenom}
                            onChange={(e) => {
                                setp_one_detail_prenom(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>



                    <div className="session_caract"> <b>Email </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_mail"
                            id="one_detail_mail"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_mail}
                            onChange={(e) => {
                                setp_one_detail_mail(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b>Date Naissance </b> <br />

                        <DatePicker
                            name="one_detail_naissance"
                            id="one_detail_naissance"
                            selected={new Date(moment(p_one_detail_naissance, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_one_detail_naissance(format(date, 'd/MM/yyyy'));
                                setEmployee_data_changed("1");
                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'

                        />


                    </div>


                    <div className="session_caract"> Téléphone Mobile<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_tel_mobile"
                            id="one_detail_tel_mobile"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_tel_mobile}
                            onChange={(e) => {
                                setp_one_detail_tel_mobile(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> Téléphone <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_tel"
                            id="one_detail_tel"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_tel}
                            onChange={(e) => {
                                setp_one_detail_tel(e.target.value);
                                setEmployee_data_changed("1");
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> Adresse<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_adresse"
                            id="one_detail_adresse"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_adresse}
                            onChange={(e) => {
                                setp_one_detail_adresse(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>
                    <div className="session_caract"> Code postal<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_code_postal"
                            id="one_detail_code_postal"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_code_postal}
                            onChange={(e) => {
                                setp_one_detail_code_postal(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>
                    <div className="session_caract"> Ville<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_ville"
                            id="one_detail_ville"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_ville}
                            onChange={(e) => {
                                setp_one_detail_ville(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Pays<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_pays"
                            id="one_detail_pays"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_pays}
                            onChange={(e) => {
                                setp_one_detail_pays(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Linkedin<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_linkedin"
                            id="one_detail_linkedin"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_linkedIn}
                            onChange={(e) => {
                                setp_one_detail_linkedIn(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Facebook<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_facebook"
                            id="one_detail_facebook"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_facebook}
                            onChange={(e) => {
                                setp_one_detail_facebook(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Twitter<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_twitter"
                            id="one_detail_twitter"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_twitter}
                            onChange={(e) => {
                                setp_one_detail_twitter(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>

                    <div className="session_caract"> Profil<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_profil"
                            id="one_detail_profil"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_profil}
                            onChange={(e) => {
                                setp_one_detail_profil(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            {Get_List_RH_Profils_result &&
                                Get_List_RH_Profils_result.map((myprofil) => (
                                    <MenuItem key={JSON.parse(myprofil).profil_nom} value={JSON.parse(myprofil).profil_nom}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(myprofil).profil_nom}&nbsp;&nbsp;

                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>

                    <div className="session_caract"> Fonction <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_fonction"
                            id="one_detail_fonction"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_fonction}
                            onChange={(e) => {
                                setp_one_detail_fonction(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        />
                    </div>


                    <div className="session_caract"> Manager<br />

                        <Autocomplete
                            disablePortal
                            name="one_detail_ismanager"
                            id="one_detail_ismanager"
                            className="disabled_style"
                            options={New_Option_Oui_Non}

                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_one_detail_ismanager(value.value);
                                    setEmployee_data_changed("1");

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />

                        {/*<TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_ismanager"
                            id="one_detail_ismanager"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_ismanager}
                            onChange={(e) => {
                                setp_one_detail_ismanager(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            <MenuItem value="1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Oui &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Non &nbsp;</MenuItem>

                        </TextField>*/}
                    </div>

                    <div className="session_caract"> Responsable (n+1) <br />
                        <Autocomplete
                            disablePortal
                            name="one_detail_resp_hierarchie_id"
                            id="one_detail_resp_hierarchie_id"
                            className="disabled_style"
                            options={New_Getall_Training_Employee_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setp_one_detail_resp_hierarchie_id(value._id);
                                    setEmployee_data_changed("1");

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" />
                            }
                        />



                        {/*<TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_resp_hierarchie_id"
                            id="one_detail_resp_hierarchie_id"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_resp_hierarchie_id}
                            onChange={(e) => {
                                setp_one_detail_resp_hierarchie_id(e.target.value);
                                setEmployee_data_changed("1");
                            }}
                        >
                            {Get_List_Managers_result &&
                                Get_List_Managers_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id !== selected_id).map((mymanager) => (
                                    <MenuItem key={JSON.parse(mymanager)._id} value={JSON.parse(mymanager)._id}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(mymanager).nom}&nbsp;&nbsp;{JSON.parse(mymanager).prenom}

                                    </MenuItem>
                                ))}

                        </TextField>*/}


                    </div>


                    {/* -- début   champs specifiques **/}

                    <div className="div_row">
                        <hr />
                    </div>
                    <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques<br />

                        {rows_champs_specifics &&
                            rows_champs_specifics.map((champ_spec) => (

                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />
                                    <br />
                                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}
                                        type="number"
                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}


                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            setEmployee_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />}

                                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}


                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            setEmployee_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />}

                                </div>
                            ))}

                        <br />

                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {/* -- end  champs specifiques **/}
                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {<div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_One_Employee_Data}>Enregistrer
                            </Button>

                        </div>}

                        {<div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_add_one_Employee}>Annuler
                            </Button>
                        </div>}
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>
            </div>}



            {String(submenu) === "affectation" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_employe} className="detail_class_submenu" id='detail_employe' name='detail_employe'>Détail employé</Button>
                    <Button variant="outlined" onClick={submenu_droit_acces} className="detail_class_submenu" id='droit_acces' name='droit_acces'>Droits d'accès  </Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_contrat} className="detail_class_submenu" id='affectation' name='contrat'>Contrat  </Button>
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Liste Affectations </nav>
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 350, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    paddingRight: '1px',
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },


                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel_affectations(newSelectionModel);
                                        if (newSelectionModel.length === 1)
                                            handleClick_edit_affectation_From_Line(newSelectionModel);

                                        if (newSelectionModel.length !== 1) {
                                            setedite_affectation_form();
                                            setdisplay_affectation();
                                        }
                                    }}
                                    selectionModel={selectionModel_affectations}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rows_affectations.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            poste: JSON.parse(item).poste,
                                            date_du: JSON.parse(item).date_du,
                                            date_au: JSON.parse(item).date_au,
                                            target_related_collection: JSON.parse(item).target_related_collection,
                                            target_related_collection_id: JSON.parse(item).target_related_collection_id,
                                            related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                            related_target_collection_object: JSON.parse(item).related_target_collection_object,


                                        }
                                    ))}

                                    columns={columns_employee_affectation}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                /*//sx={datagridSx}
                                getCellClassName={(params) => {
                                    //field === 'distantiel'
                                    if (params.field === 'distantiel' && String(params.value) === "1") {
                                        return 'cell--distantiel';
                                    }
                                    if (params.field === "presentiel" && String(params.value) == "1") {
                                        return 'cell--presentiel';
                                    }


                                    //field === "statut"
                                    if (params.field === "status" && String(params.value) == "0") {
                                        return 'cell--statut--preinscrit';
                                    }

                                    if (params.field === "status" && String(params.value) == "1") {
                                        return 'cell--statut--inscrit';
                                    }

                                    if (params.field === "status" && String(params.value) == "-1") {
                                        return 'cell--statut--annule';
                                    }

                                }}
                                getRowClassName={(params) => {

                                    if (String(params.row.status) === "-1") {
                                        return 'line--statut--annule';
                                    }
                                    if (String(params.row.status) === "0") {
                                        return 'line--statut--preinscrit';
                                    }
                                    if (String(params.row.status) === "1") {
                                        return 'line--statut--inscrit';
                                    }
                                }}*/


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        <Button variant="outlined" onClick={add_one_affectation_block}
                            className="detail_class_submenu bton_add_session"
                            id='menu_add_participant' name='menu_add_participant'>Ajout 1 affectation &nbsp;
                            <img src={add_plus} alt="ajout affectation" className="icon_plus" />
                        </Button>

                        <br />

                    </div>
                </div>


                <nav ref={myRef_affectation} id="myRef_affectation"> &nbsp; </nav>
                {String(display_affectation) === "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail Affectation </nav>

                    <div className="session_data" >
                        <div className="session_caract">Poste Occupé <br />
                            <TextField
                                name="affect_poste"
                                id="affect_poste"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_poste}
                                onChange={(e) => {
                                    setp_affect_poste(e.target.value);
                                    setAffectation_data_changed("1");
                                }}

                            />

                        </div>


                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Cible <br />
                            <TextField
                                name="affect_cible"
                                id="affect_cible"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible_label}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Cible <br />
                            <TextField
                                name="affect_cible"
                                id="affect_cible"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible}
                                onChange={(e) => {
                                    setp_affect_cible(e.target.value);
                                    setAffectation_data_changed("1");
                                }}


                            >
                                <MenuItem value="myclass" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Formation &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="session_formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Session de formation &nbsp;</MenuItem>
                                <MenuItem value="" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Aucun &nbsp;</MenuItem>

                            </TextField>

                        </div>}


                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Cible Nom <br />
                            <TextField
                                name="affect_cible_nom"
                                id="affect_cible_nom"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible_nom_label}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Cible Nom <br />
                            <TextField
                                name="affect_cible_nom"
                                id="affect_cible_nom"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible_nom}
                                onChange={(e) => {
                                    setp_affect_cible_nom(e.target.value);
                                    setAffectation_data_changed("1");
                                }}
                            >

                                {Get_Employee_Related_Target_Collection_Data_result &&
                                    Get_Employee_Related_Target_Collection_Data_result.filter((local_mymanager) => JSON.parse(local_mymanager).related_target_collection === p_affect_cible).map((mymanager) => (
                                        <MenuItem key={JSON.parse(mymanager).related_target_collection_id} value={JSON.parse(mymanager).related_target_collection_id}
                                            style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                            {JSON.parse(mymanager).related_target_collection_id_nom}&nbsp;&nbsp;

                                        </MenuItem>
                                    ))}


                            </TextField>

                        </div>}



                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Date du <br />
                            <TextField
                                name="affect_date_du"
                                id="affect_date_du"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_date_du}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Date du <br />

                            <DatePicker
                                name="affect_date_du"
                                id="affect_date_du"
                                selected={new Date(moment(p_affect_date_du, "DD/MM/YYYY HH:mm:ss"))}
                                onChange={(date) => {
                                    setp_affect_date_du(format(date, 'dd/MM/yyyy'));
                                    setAffectation_data_changed("1");
                                }
                                }
                                showTimeSelect={false}
                                //filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style enable_style"
                                locale='fr-FR'
                            />

                        </div>}


                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Date au <br />
                            <TextField
                                name="affect_date_au"
                                id="affect_date_au"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_date_au}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Date au <br />
                            <DatePicker
                                name="affect_date_au"
                                id="affect_date_au"
                                selected={new Date(moment(p_affect_date_au, "DD/MM/YYYY HH:mm:ss"))}
                                onChange={(date) => {
                                    setp_affect_date_au(format(date, 'dd/MM/yyyy'));
                                    setAffectation_data_changed("1");
                                }
                                }
                                showTimeSelect={false}
                                //filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style enable_style"
                                locale='fr-FR'
                            />

                        </div>}

                        <div className="session_caract">Commentaire <br />
                            <TextField
                                name="affect_comment"
                                id="affect_comment"
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                rows={1}
                                maxRows={1}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_comment}
                                onChange={(e) => {
                                    setp_affect_comment(e.target.value);

                                }}

                            />

                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {String(Affectation_data_changed) === "1" && String(edite_affectation_form) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}


                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(Affectation_data_changed) === "1" && String(edite_affectation_form) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Update_One_Affectation_Data}>Enregistrer les modifications
                                </Button>

                            </div>}

                            {String(edite_affectation_form) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Annule_Affectation_DetailFields}>Annuler
                                </Button>
                            </div>}
                        </div>
                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(edite_affectation_form) !== "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_annule" onClick={Fermer_Affectation_Fields}>Fermer
                                </Button>

                            </div>}


                            {String(edite_affectation_form) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_Detail_Affectation_Fields}>Editer
                                </Button>

                            </div>}
                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                    </div>
                </div>}

                <nav ref={myRef_new_affectation} id="myRef_new_affectation"> &nbsp; </nav>
                {String(Add_new_affectation) === "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>

                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Nouvelle affectation </nav>
                    <div className="session_data" >
                        <div>
                            <div className="session_caract">Poste Occupé <br />
                                <TextField
                                    name="one_affect_poste"
                                    id="one_affect_poste"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_poste}
                                    onChange={(e) => {
                                        setp_one_affect_poste(e.target.value);

                                    }}

                                />

                            </div>

                            <div className="session_caract">Cible <br />
                                <TextField
                                    name="one_affect_cible"
                                    id="one_affect_cible"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_cible}
                                    onChange={(e) => {
                                        setp_one_affect_cible(e.target.value);
                                    }}

                                >
                                    <MenuItem value="myclass" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Formation &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="session_formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Session de formation &nbsp;</MenuItem>
                                    <MenuItem value="" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Aucun &nbsp;</MenuItem>

                                </TextField>

                            </div>

                            <div className="session_caract">Cible Nom  <br />
                                <TextField
                                    name="one_affect_cible_nom"
                                    id="one_affect_cible_nom"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_cible_nom}
                                    onChange={(e) => {
                                        setp_one_affect_cible_nom(e.target.value);
                                    }}
                                >

                                    {Get_Employee_Related_Target_Collection_Data_result &&
                                        Get_Employee_Related_Target_Collection_Data_result.filter((local_mymanager) => JSON.parse(local_mymanager).related_target_collection === p_one_affect_cible).map((mymanager) => (
                                            <MenuItem key={JSON.parse(mymanager).related_target_collection_id} value={JSON.parse(mymanager).related_target_collection_id}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mymanager).related_target_collection_id_nom}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}


                                </TextField>

                            </div>



                            <div className="session_caract">Date du <br />
                                <DatePicker
                                    name="one_affect_date_du"
                                    id="one_affect_date_du"
                                    selected={new Date(moment(p_one_affect_date_du, "DD/MM/YYYY HH:mm:ss"))}
                                    onChange={(date) => {
                                        setp_one_affect_date_du(format(date, 'dd/MM/yyyy'));
                                    }
                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                />

                            </div>


                            <div className="session_caract">Date au <br />
                                <DatePicker
                                    name="one_affect_date_au"
                                    id="one_affect_date_au"
                                    selected={new Date(moment(p_one_affect_date_au, "DD/MM/YYYY HH:mm:ss"))}
                                    onChange={(date) => {
                                        setp_one_affect_date_au(format(date, 'dd/MM/yyyy'));
                                    }
                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                />

                            </div>

                            <div className="session_caract">Commentaire <br />
                                <TextField
                                    name="one_affect_comment"
                                    id="one_affect_comment"
                                    multiline
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    rows={1}
                                    maxRows={1}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_comment}
                                    onChange={(e) => {
                                        setp_one_affect_comment(e.target.value);

                                    }}

                                />

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Add_one_affectation}>Enregistrer
                                </Button>

                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Fermer_nouvelle_Affectation_Fields}>Annuler
                                </Button>
                            </div>
                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                </div>}

            </div>}

            {String(submenu) === "droit_acces" && <div className="div_row">
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Droits accès </nav>
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_employe} className="detail_class_submenu" id='detail_employe' name='detail_employe'>Détail employé</Button>
                    <Button variant="outlined" onClick={submenu_droit_acces} className="detail_class_submenu" id='droit_acces' name='droit_acces'>Droits d'accès  </Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_contrat} className="detail_class_submenu" id='affectation' name='contrat'>Contrat  </Button>
                </div>

                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Droits d'accès système </nav>

                <div className="session_data" style={{ "background": warning_bg_color }}>

                    <div className="session_caract"> Profil<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_profil"
                            id="one_detail_profil"
                            select
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_user_profil}
                            onChange={(e) => {
                                setp_user_profil_tmp(e.target.value);
                                Dialog_1_handle_change_profil_access_right(" Vous êtes sur le point de mettre à jour les droits de l'utilisateur. Pensez à enregistrer pour finaliser l'opération. ", e.target.value);

                            }}
                        >
                            {Get_List_RH_Profils_result &&
                                Get_List_RH_Profils_result.map((myprofil) => (
                                    <MenuItem key={JSON.parse(myprofil).profil_nom} value={JSON.parse(myprofil).profil_nom}
                                        style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                        {JSON.parse(myprofil).profil_nom}&nbsp;&nbsp;

                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>

                    <div className="session_data" style={{ "marginLeft": "10px", "marginRight": "10px", width: '98%', }}>
                        <div style={{ "border": "None", "marginLeft": "10px", "marginRight": "10px", width: '98%', }}>


                            {String(acces_right_data_edit_mode) === "1" &&
                                <div style={{ height: 550, width: '98%', paddingRight: '1px', "marginLeft": "10px", "marginRight": "10px", }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            //checkboxSelection


                                            onCellEditCommit={OnchangeCellDataGrid}
                                            editMode="cell"


                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_acces_right(newSelectionModel);
                                                if (newSelectionModel.length === 1)
                                                    handleClick_edit_acces_right_From_Line(newSelectionModel);

                                                if (newSelectionModel.length !== 1) {
                                                    setedite_affectation_form();
                                                    setdisplay_affectation();
                                                }
                                            }}



                                            selectionModel={selectionModel_acces_right}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rows_acces_right.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    poste: JSON.parse(item).poste,
                                                    module: JSON.parse(item).module_name,
                                                    read: JSON.parse(item).read,
                                                    write: JSON.parse(item).write,

                                                }
                                            ))}


                                            columns={columns_employee_acces_right}
                                            pageSize={10}
                                            className="datagridclass"
                                            disabled={true}

                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}

                                        />
                                    </Box>
                                    <br />

                                </div>

                            }

                            {String(acces_right_data_edit_mode) !== "1" &&
                                <div style={{ height: 550, width: '100%', paddingRight: '1px', "marginLeft": "10px", "marginRight": "10px", }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },
                                        }}
                                    >
                                        <DataGrid
                                            //checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_acces_right(newSelectionModel);
                                                if (newSelectionModel.length === 1)
                                                    handleClick_edit_acces_right_From_Line(newSelectionModel);

                                                if (newSelectionModel.length !== 1) {
                                                    setedite_affectation_form();
                                                    setdisplay_affectation();
                                                }
                                            }}



                                            selectionModel={selectionModel_acces_right}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rows_acces_right.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    poste: JSON.parse(item).poste,
                                                    module: JSON.parse(item).module_name,
                                                    read: JSON.parse(item).read,
                                                    write: JSON.parse(item).write,

                                                }
                                            ))}


                                            columns={columns_employee_acces_right_disabled}
                                            pageSize={10}
                                            className="datagridclass"
                                            disabled={true}

                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}

                                        />
                                    </Box>
                                    <br />

                                </div>}


                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {String(acces_right_data_changed) === "1" && String(acces_right_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}


                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(acces_right_data_changed) === "1" && String(acces_right_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Update_user_access_right_Data}>Enregistrer les modifications
                                </Button>

                            </div>}

                            {String(acces_right_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Disable_acces_right_datagrid}>Annuler
                                </Button>
                            </div>}
                        </div>
                        {String(acces_right_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                            <Button variant="contained" className="bton_edit" onClick={Enable_acces_right_datagrid}>Editer
                            </Button>

                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>


                    </div>
                </div>
            </div>}

            {String(submenu) === "contrat" && <div className="div_row" style={{ "paddingRight": "10px" }} ref={myRef_contrat}>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Detail contrat(s)  </nav>
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_employe} className="detail_class_submenu" id='detail_employe' name='detail_employe'>Détail employé</Button>
                    <Button variant="outlined" onClick={submenu_droit_acces} className="detail_class_submenu" id='droit_acces' name='droit_acces'>Droits d'accès  </Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_contrat} className="detail_class_submenu" id='affectation' name='contrat'>Contrat  </Button>
                </div>
                &nbsp;
                <div style={{ height: 550, width: '100%', paddingRight: '1px', "marginLeft": "10px", "marginRight": "10px", }}>
                    &nbsp;
                    <Box
                        sx={{
                            height: 500,
                            width: '100%',
                            paddingRight: '1px',
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#c8cfd5",
                                color: "black",
                                fontSize: 14
                            },
                        }}
                    >
                        <DataGrid
                            //checkboxSelection

                            onSelectionModelChange={(newSelectionModel) => {
                                setselectionModel_contrat(newSelectionModel);

                            }}


                            selectionModel={selectionModel_contrat}

                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            rows={Getall_Employee_Contrat_result.map((item, index) => (
                                {
                                    id: index,
                                    _id: JSON.parse(item)._id,
                                    rh_id: JSON.parse(item).rh_id,
                                    date_debut: JSON.parse(item).date_debut,
                                    date_fin: JSON.parse(item).date_fin,
                                    type_contrat: JSON.parse(item).type_contrat,
                                    type_employe: JSON.parse(item).type_employe,
                                    qte: JSON.parse(item).quantite,
                                    cout: JSON.parse(item).cout,
                                    comment: JSON.parse(item).comment,
                                    periodicite: JSON.parse(item).periodicite,


                                }
                            ))}


                            columns={columns_employee_contrat}
                            pageSize={10}
                            className="datagridclass"
                            disabled={true}

                            onRowDoubleClick={(newSelectionModel) => {

                                setselected_contrat_id(newSelectionModel.row._id)
                                handleClick_edit_employee_contrat_From_Line(newSelectionModel.row.id);

                            }}

                            rowsPerPageOptions={[10]}
                            //disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Box>
                    <br />

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                        <Button variant="outlined" onClick={() => {

                            setp_employe_contrat_type_contrat("");
                            setp_employe_contrat_type_employe("");
                            setp_employe_contrat_cout("");
                            setp_employe_contrat_comment("");
                            setp_employe_contrat_groupe_prix_achat_id("");
                            setp_employe_contrat_quantite("");
                            setp_employe_contrat_date_debut(new Date().toLocaleDateString('fr-FR'));
                            setp_employe_contrat_date_fin(date_today_90j.toLocaleDateString('fr-FR'));
                            setselected_contrat_id("");

                            Getall_Employee_Type_Contrat();

                            setDialog_employe_contrat_open(true);


                        }}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Contrat &nbsp;
                            <img src={add_plus} alt="ajout commande" className="icon_plus" />
                        </Button>
                    </div>
                </div>
            </div>
            }


        </div >
    );
}

export default Employes;

