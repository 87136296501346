import React, { useState, useEffect } from "react";
import UpdateUserInfo from "../components/UpdateUserInfo";
import Button from '@mui/material/Button';

import { AiOutlineEdit } from "react-icons/ai";
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { Button2, Form, FormGroup, Label } from "reactstrap";
import { faBullseye } from "@fortawesome/fontawesome-free-solid";
import tick_ok from "../mysy_img/tick_OK.png";
import tick_ko from "../mysy_img/tick_KO.png";
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import PaymentMethod from "./PaymentMethode";
import { IoAddCircleOutline } from "react-icons/io5";
import fileDownload from 'js-file-download'

function Facture(props) {

    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [menu, setmenu] = useState("default");
    const [fields1desabled, setfields1desabled] = useState(true);
    const [periode, setperiode] = useState("Mensuel");
    const [testval, settestval] = useState();
    const [gotocheckout, setgotocheckout] = useState("0");
    const [selectedpacks, setselectedpacks] = useState("");
    const [currentpack, setcurrentpack] = useState(props.currentpack);


    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'date_facture', headerName: 'Date Facture', width: 150, flex: 1 },
        { field: 'num_facture', headerName: 'Num Facture', width: 200, hideable: false, flex: 1 },
        { field: 'client_nom', headerName: 'Client', width: 200, hideable: false, flex: 1, hide: true },
        { field: 'montant_ht', headerName: 'Montant HT €', width: 200, hideable: false, flex: 1 },
        { field: 'montant_ttc', headerName: 'Montant TTC €', width: 100, hideable: false, flex: 1 },
        { field: 'reduction', headerName: 'Discount', width: 150, flex: 1, hide: true },
        { field: 'mode_payement', headerName: 'Mode paiement', width: 150, flex: 1 },
        { field: 'date_payement', headerName: 'Date échéance', width: 150, flex: 1 },
        {
            field: "lien_pdf", headerName: 'Télécharger',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}
                    >
                        <AiOutlineEdit />

                    </Button>

                );
            }
        }

    ]

    function handleClick(event, cellValues) {

        var nom_facture = cellValues.row.num_facture + ".pdf";
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');


        var num_facture = cellValues.row.num_facture;
        var url = process.env.REACT_APP_API_URL + "myclass/api/GetCustomerInvoice/" + stored_cookie + "/" + num_facture;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_facture)
            })
    }



    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rowss, setRows] = useState([]);
    const [tableData, setTableData] = useState([])

    const [GetInvoices_api, setGetInvoices_api] = useState()
    const [GetInvoices_api_message, setGetInvoices_api_message] = useState()

    function GetInvoices(event) {
        var form = new FormData();


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_invoice_by_customer/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
              

                if (String(data['status']) === String("false")) {
                    
                    //console.log("erreur rrrr:" + data['status'])
                    setGetInvoices_api("false");

                }
                else {
                    setTableData(data['message']);
                    setRows(data['message']);
                    setGetInvoices_api("true");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setGetInvoices_api("false");
            });

    }

    const [paymentmode, setpaymentmode] = useState([]);
    const [paymentmodeapi, setpaymentmodeapi] = useState([]);


    function GetPayementMode(event) {

        var form = new FormData();


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_payement_mode/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setpaymentmode(data['message']);
                if (String(data['status']) === String("false")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setpaymentmodeapi("False");

                }
                else {
                    setpaymentmodeapi("True");
                    if (data['message'] && data['message'][0] &&
                        Object.keys(data['message'][0]).length > 0) {
                        setpaymentmode(data['message']);
                        //setnew_mode_payement("0");
                    } else {
                        setpaymentmode([]);
                    }

                }

            }).catch((error) => {
                console.error('Error:', error);
                setpaymentmodeapi("False");
            });

    }


    function menu_defaut() {
        setmenu("default");
    }

    function menu_paymentmethode() {
        setmenu("paymentmethode");
    }

    function menu_info() {
        setmenu("info");
        getCurrentUserData();
        GetPayementMode();
    }

    function Clik_bton_mensuel(e) {
        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel")[0].style.opacity = "80%";

        document.getElementsByName("bton_mensuel2")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel2")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel2")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel2")[0].style.opacity = "80%";



        setperiode("Mensuel");
    }

    function Clik_bton_annuel(e) {
        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "80%";


        document.getElementsByName("bton_annuel2")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel2")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel2")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel2")[0].style.opacity = "80%";



        setperiode("Annuel");
    }


    function menu_plan() {
        setmenu("plan");
    }


    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }

    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";

        var nom = document.getElementsByName("nom")[0].value;
        var vat_num = document.getElementsByName("vat_num")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;

        formData.append('partner_account_id', partner_account_id);
        formData.append('token', stored_part);
        formData.append('invoice_vat_num', vat_num);
        formData.append('invoice_nom', nom);
        formData.append('invoice_adr_street', adr_street);
        formData.append('invoice_adr_city', adr_city);
        formData.append('invoice_adr_zip', adr_zip);
        formData.append('invoice_adr_country', adr_country);
        formData.append('invoice_email', email);
        formData.append('invoice_telephone', telephone);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setmyApiResponse(result['status']);
                setResult(result['message']);
                if (String(result['status']) == String("true")) {
                    settestval(true);
                    desablefield1();
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("false");
            });


    };

    function enablefieds1() {
        setfields1desabled(false);
        document.getElementsByName("nom")[0].disabled = false;
        document.getElementsByName("nom")[0].style.backgroundColor = "";

        document.getElementsByName("telephone")[0].disabled = false;
        document.getElementsByName("telephone")[0].style.backgroundColor = "";

        document.getElementsByName("email")[0].disabled = false;
        document.getElementsByName("email")[0].style.backgroundColor = "";

        document.getElementsByName("adr_street")[0].disabled = false;
        document.getElementsByName("adr_street")[0].style.backgroundColor = "";

        document.getElementsByName("adr_zip")[0].disabled = false;
        document.getElementsByName("adr_zip")[0].style.backgroundColor = "";

        document.getElementsByName("adr_city")[0].disabled = false;
        document.getElementsByName("adr_city")[0].style.backgroundColor = "";

        document.getElementsByName("adr_country")[0].disabled = false;
        document.getElementsByName("adr_country")[0].style.backgroundColor = "";

        document.getElementsByName("vat_num")[0].disabled = false;
        document.getElementsByName("vat_num")[0].style.backgroundColor = "";
    }

    function desablefield1() {
        setfields1desabled(true);
        document.getElementsByName("nom")[0].disabled = true;
        //document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("telephone")[0].disabled = true;
        //document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("email")[0].disabled = true;
        // document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_street")[0].disabled = true;
        //document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_zip")[0].disabled = true;
        //document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_city")[0].disabled = true;
        //document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_country")[0].disabled = true;
        //document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("vat_num")[0].disabled = true;
        //document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
    }

    const [partner_account_id, setpartner_account_id] = useState("");
    const [is_partner_admin_account, setis_partner_admin_account] = useState("");

    function getCurrentUserData() {

        settestval('d'); // ==> Do not delete
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
        formData.append('token', stored_part);

        axios.post(url, formData).then(res => {
            if (res.data.status != "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message);

                var mylocaltraining = JSON.parse(res.data.message);
                if (mylocaltraining) {

                    setpartner_account_id(mylocaltraining._id);

                    if (mylocaltraining.is_partner_admin_account) {
                        setis_partner_admin_account(mylocaltraining.is_partner_admin_account);
                    }else{
                      setis_partner_admin_account("0");
                    }

                    
                    if (mylocaltraining.invoice_vat_num) {
                        document.getElementsByName("vat_num")[0].value = mylocaltraining.invoice_vat_num;
                        document.getElementsByName("vat_num")[0].disabled = true;
                        document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
                    }


                    if (mylocaltraining.invoice_nom) {
                        document.getElementsByName("nom")[0].value = mylocaltraining.invoice_nom;
                        document.getElementsByName("nom")[0].disabled = true;
                        document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_street) {
                        document.getElementsByName("adr_street")[0].value = mylocaltraining.invoice_adr_street;
                        document.getElementsByName("adr_street")[0].disabled = true;
                        document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_city) {
                        document.getElementsByName("adr_city")[0].value = mylocaltraining.invoice_adr_city;
                        document.getElementsByName("adr_city")[0].disabled = true;
                        document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_zip) {
                        document.getElementsByName("adr_zip")[0].value = mylocaltraining.invoice_adr_zip;
                        document.getElementsByName("adr_zip")[0].disabled = true;
                        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_country) {
                        document.getElementsByName("adr_country")[0].value = mylocaltraining.invoice_adr_country;
                        document.getElementsByName("adr_country")[0].disabled = true;
                        document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_email) {
                        document.getElementsByName("email")[0].value = mylocaltraining.invoice_email;
                        document.getElementsByName("email")[0].disabled = true;
                        document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_telephone) {

                        document.getElementsByName("telephone")[0].value = mylocaltraining.invoice_telephone;
                        document.getElementsByName("telephone")[0].disabled = true;
                        document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";
                    }

                    desablefield1();

                }
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })



    }


    function menu_facture() {
        setmenu("facture");
        getCurrentUserData();
        GetPayementMode();
        GetInvoices();

    }

    useEffect(() => {

        menu_facture();
        getCurrentUserData();
        GetPayementMode();
        setpackprice();

    }, []);



    function confirmAbonnement(packs) {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });

        if (String(packs).toLowerCase() === String("decouverte")) {

            if (String(periode) === String("Mensuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("50");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("90");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

           if (String(periode) === String("Annuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("600");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("1080");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            setgotocheckout("1");
            setselectedpacks(packs);
            window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
            return;
        }

        if (!paymentmode || String(paymentmode).length <= 0) {
            alert(" Vous devez ajouter un mode de Paiement pour activer de ce pack")
            return;

        } else {


            if (String(periode) === String("Mensuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("50");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("90");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            if (String(periode) === String("Annuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("600");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("1080");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            setgotocheckout("1");
            setselectedpacks(packs);
            window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
    }

    function returntoorder() {
        setgotocheckout("0");

    }

    const [packprice, setpackprice] = useState();
    return (
        <div className="facture">

            {String(gotocheckout) === String("1") && <div>
                <Button onClick={returntoorder} className="btn_retour_ps"> Retour aux produits & Services </Button>
                <CheckOut packs={selectedpacks} periodicity={periode} mypackprice={packprice} /> </div>

            }

            {String(gotocheckout) === String("0") && <div>

                <Button variant="outlined" onClick={menu_facture} className="facture_menu">Factures</Button>
                {/* <Button variant="outlined" onClick={menu_paymentmethode} className="facture_menu">Methode paiement</Button>
                <Button variant="outlined" onClick={menu_defaut} className="facture_menu">Tableau de bord</Button>
        */}
                <hr />


                <div>
                    {String(menu) === String("default") && <div>
                        default
                    </div>}


                    {String(menu) === String("paymentmethode") && <div>

                        <PaymentMethod mypaymentmode={paymentmode} />
                    </div>}


                    {String(menu) === String("facture") && <div>
                        <div className="titre1">
                            Vos  factures
                        </div>
                        {String(GetInvoices_api) === "false" && <div className='koUpdateData'>
                            Impossible de traiter la demande
                        </div>}
                        {String(GetInvoices_api) === "true" &&
                            <div style={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        //console.log("ch selected--" + newSelectionModel);
                                    }}
                                    selectionModel={selectionModel}


                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            num_facture: JSON.parse(item).invoice_id,
                                            client_nom: JSON.parse(item).invoice_nom,
                                            montant_ht: JSON.parse(item).total_ht,
                                            montant_ttc: JSON.parse(item).total_ttc,
                                            reduction: JSON.parse(item).reduction,
                                            date_facture: JSON.parse(item).invoice_date,
                                            mode_payement: JSON.parse(item).mode_payement,
                                            date_payement: JSON.parse(item).due_date,
                                            lien_pdf: JSON.parse(item).lien_pdf,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}


                                />


                                <br />
                                {/* <Button variant="contained" onClick={GetAllClass_new}>Contained</Button>*/}
                            </div>}

                    </div>}

                    {String(menu) === String("plan") && <div>
                        plan
                    </div>}




                </div>

            </div>}
        </div>
    );

}

export default Facture;