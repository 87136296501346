import React, { useRef, useState, useEffect } from "react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import 'reactjs-popup/dist/index.css';

import "react-datepicker/dist/react-datepicker.css";


import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";

import Dashbord_Session_Par_Fteur from "./Dashbord_Session_Par_Fteur";
import Dashbord_Session_Taux_Remplissage from "./Dashbord_Session_Taux_Remplissage";
import Dashbord_Session_Repartition_Fteur from "./Dashbord_Session_Repartition_Fteur";
import Dashbord_Formation_Evol_Session from "./Dashbord_Formation_Evol_Session";
import Dashbord_Ressources_Humaines_Tache_by_rhid from "./Dashbord_Ressources_Humaines_Tache_by_rhid";
import Dashbord_Ressources_Humaines_Tache_Couts from "./Dashbord_Ressources_Humaines_Tache_Couts";
import Dashbord_Inscriptions_all_inscriptions from "./Dashbord_Inscription_All_Inscription";

import { TbColumns2, TbColumns1 } from "react-icons/tb";


const Mon_Tableau_De_Bord = (props) => {


    const history = useHistory();
    const [isLoading, setLoading] = useState();


    const [Get_Connected_User_Dashbord_api, setGet_Connected_User_Dashbord_api] = useState();
    const [Get_Connected_User_Dashbord_message, setGet_Connected_User_Dashbord_message] = useState();
    const [Get_Connected_User_Dashbord_result, setGet_Connected_User_Dashbord_result] = useState([]);

    function Get_Connected_User_Dashbord() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');


        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Connected_User_List_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Connected_User_Dashbord  res.data.status = " + res.data.status);
                //console.log(" In Get_Connected_User_Dashbord  res.data.message r_class = " + res.data.message);

                setGet_Connected_User_Dashbord_api("true");
                setGet_Connected_User_Dashbord_result(res.data.message);

            }
            else {
                setGet_Connected_User_Dashbord_api("false");
                setGet_Connected_User_Dashbord_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Connected_User_Dashbord = ', error);
            setGet_Connected_User_Dashbord_api("false");
            alert("Impossible de récupérer la liste des tableaux de bord");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {


        Get_Connected_User_Dashbord();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])


    const [display_nb_column, setdisplay_nb_column] = useState("2");
    const [display_column_size, setdisplay_column_size] = useState("49%");

    return (
        <div className="mon_tableau_de_bord">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Mon tableau de bord </h3>

            <div className="div_row"> &nbsp;</div>
            <div className="div_row" style={{ "textAlign": "right", "paddingRight": "20px" }}> Affichage &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_1_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_1_col" data-tooltip-html="Affichage sur une colonne">
                    <TbColumns1 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("1");
                        setdisplay_column_size("95%");
                    }} />
                </a>  &nbsp; &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_2_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_2_col" data-tooltip-html="Affichage sur deux colonnes">
                    <TbColumns2 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("2");
                        setdisplay_column_size("49%");
                    }} />
                </a> </div>

            {display_nb_column && <div>
                {Get_Connected_User_Dashbord_result &&
                    Get_Connected_User_Dashbord_result.map((step) => (

                        <div className="div_row" style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>

                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_session_01" &&

                                <Dashbord_Session_Taux_Remplissage user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                            }

                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_session_02" &&

                                <Dashbord_Session_Par_Fteur user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)} />

                            }


                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_session_03" &&

                                <Dashbord_Session_Repartition_Fteur user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                            }

                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_formation_01" &&

                                <Dashbord_Formation_Evol_Session user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                            }


                        </div>

                    ))}
            </div>}

            {display_nb_column && <div>
                {Get_Connected_User_Dashbord_result &&
                    Get_Connected_User_Dashbord_result.map((step) => (

                        <div className="div_row" style={{ "width": "95%", "float": "left", "paddingRight": "2px" }}>



                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_rh_01" &&
                                <Dashbord_Ressources_Humaines_Tache_by_rhid user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)}
                                    user_dashbord_filter_type_envent={String(JSON.parse(step).type_event)}

                                />
                            }

                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_rh_02" &&
                                <Dashbord_Ressources_Humaines_Tache_Couts user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)}
                                    user_dashbord_filter_type_envent={String(JSON.parse(step).type_event)}

                                />
                            }

                            {String(JSON.parse(step).dashbord_internal_code) === "tbd_inscription_01" &&
                                
                                
                                <Dashbord_Inscriptions_all_inscriptions user_dashbord_id={String(JSON.parse(step)._id)}
                                    user_dashbord_title={String(JSON.parse(step).title)}
                                    user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                    user_dashbord_filter_end={String(JSON.parse(step).end_date)}
                                  

                                />
                            }


                        </div>

                    ))}
            </div>}

            {!Get_Connected_User_Dashbord_result || Get_Connected_User_Dashbord_result.length <= 0 && <div>


                <div className="div_row">
                    <p className="text_tbd_vide"> Pour utiliser votre tableau de bord, Merci de le configurer à partir du menu : "Configuration" == &gt; "Tableau de bord"</p>

                </div>

            </div>}



        </div>
    )
}


export default Mon_Tableau_De_Bord;